import React, { useEffect } from 'react';
import '../../Assets/style/conformPopup.css';
import CloseIcon from '@mui/icons-material/Close';
import { translate } from '../../Language/Translate';
import { slashFormatinDDMMYYYY } from '../../Utils/DateFormatter/Date';
import CheckCircleOutlineIcon from 'Assets/img/icons/green-tick-circle.svg';
import FocusTrap from 'focus-trap-react'
const AppointmentSuccess = (props) => {
  const { onAnotherBooking, onClose, doctor, name, slot, AppintmentId, date } =
    props;

  useEffect(() => {
    setTimeout(() => document.body.classList.add('modal-overflow'), 0);
    return () => document.body.classList.remove('modal-overflow');
  }, []);

  return (
    <>
     <FocusTrap
     focusTrapOptions={{
      clickOutsideDeactivates:true
    }}>
      <div className="AppointmentModal d-flex">
        <div className="modalContent  modal_sm">
          <div className="modalHeader">
            <div className="container">
              <div className="row">
                <div className="col-md-12 px-0">
                  <CloseIcon
                    style={{
                      float: 'right',
                      cursor: 'pointer',
                      marginRight: '-6px',
                      marginTop: '-6px',
                      color: '#75808E'
                    }}
                    onClick={onClose}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modalContentarea-popup mt-2">
            <div
              className="col-12"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <img src={CheckCircleOutlineIcon} />
            </div>
            <div className="row justify-content-center ">
              <div
                className="col-lg-12 mb-3"
                style={{ marginBottom: 5, marginTop: 5 }}
              >
                <h5 className="fw-600" style={{ textAlign: 'center' }}>
                  {translate(
                    'appointments.appointmentSuccess',
                    'Your appointment has been booked successfully'
                  )}
                  !
                </h5>
              </div>
             <div className="col-12">
             <div className="border-on-responsive row mx-0">
              <div className="col-lg-3 col-6 text-center">
                <div style={{ fontWeight: 'bold', fontSize: 12 }}>
                  {translate('appointments.appointmentId', 'Appointment Id')}
                </div>
                <div style={{ fontSize: 12 }}>{AppintmentId}</div>
              </div>
              <div className="col-lg-3 col-6 lineAppointment text-center">
                <div style={{ fontWeight: 'bold', fontSize: 12 }}>
                  {translate('appointments.doctorName', 'Doctor Name')}
                </div>
                <div style={{ fontSize: 12 }}>{doctor}</div>
              </div>
              <div className="col-lg-3 col-6 lineAppointment text-center">
                <div style={{ fontWeight: 'bold', fontSize: 12 }}>
                  {translate('appointments.bookedSlot', 'Doctor Name')}
                </div>
                <div style={{ fontSize: 12 }}>
                  {slashFormatinDDMMYYYY(date)} {slot}
                </div>
              </div>
              <div className="col-lg-3 col-6 lineAppointment text-center">
                <div style={{ fontWeight: 'bold', fontSize: 12 }}>
                  {translate('appointments.patientName', 'Patient Name')}
                </div>
                <div style={{ fontSize: 12 }}>{name}</div>
              </div>
              </div>
             </div>
            </div>
          </div>
          <div className="modal_footer  deleteFooter mt-1">
            <div className="container">
              <div className="row mt-1">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-primary"
                    id="save"
                    onClick={onAnotherBooking}
                  >
                    {' '}
                    {translate(
                      'appointments.bookAnother',
                      'Book Another Appointment'
                    )}{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </FocusTrap>
    </>
  );
};
export default AppointmentSuccess;
