import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import 'Assets/style/doctor-view.css';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import svgIc from 'Assets/img/icons/doctor-placeholder-avatar.svg';
import { DoctorsApi } from '../../Utils/Api/DoctorsApi';
import { FetchOverrideDetails } from '../../Actions/doctorsAction';
import Loader from '../Common/Loader';
import { CardMedia, Tooltip } from '@mui/material';
import { translate } from '../../Language/Translate';
import withPermissionCheck from '../../Utils/WithUtils/WithPermission';
import { APPOINTMENT, DOCTOR } from '../../Constants/feature';
import { GENDER_LABEL } from 'Constants/constant';
import slotIcon from 'Assets/img/icons/manage-slots-icon.svg';
import {
  appendTime, timeZoneDate,
} from '../../Utils/DateFormatter/Date';
import historyIcon from '../../Assets/img/icons/history-icon.svg';
import { receiveSearchDoctorDetails } from 'Actions/appointmentAction';
import { CREATE, SCHEDULE_READ, UPDATE } from 'Constants/scopes';
import { USER_TYPE } from 'Constants/userTypes';
import { formatDateto_D_M_Y } from 'Utils/DateFormatter/Date';
import NoResultFound from 'Components/Common/NoResult';


const DoctorDetails = (props) => {
  const {
    ACCESS,
    ACCESSOF,
    userType,
    language_Code,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { from } = location.state || {};
  const [doctorDetails, updateDoctorDetails] = useState({});
  const [department, updateDepartment] = useState([]);
  const [opSchedule, updateOpSchedule] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showQualify, setShowQualify] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [feeHistory, setFeeHistory] = useState([]);
  const params = useParams();
  const { id: docId, userId } = params || {};
  const ref = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = (event) => {
    if (ref?.current && !ref?.current?.contains(event.target)) {
      setShowHistory(false); // Close on outside click
    }
  };

  useEffect(() => {
    if (docId) {
      viewDoctorDetails(docId);
    }
  }, []);

  const directTOSlotManage = () => {
    history.push({
      pathname: '/admin/slot-management',
      state: {
        doctorId: {
          label: `${doctorDetails.name} ${doctorDetails?.last_name}`,
          value: doctorDetails.doctor_id,
        },
      },
    });
  };

  const viewDoctorDetails = (id) => {
    setIsloading(true);
    DoctorsApi.getDoctorDetails(id, { fee_history: true }).then((res) => {
      if (res.error) {
        setIsloading(false);
        return;
      } else {
        updateDoctorDetails(res.response.doctor);
        updateDepartment(res.response.departments);
        updateOpSchedule(res.response.op_schedule);
        setFeeHistory(res?.response?.doctor_fee_history?.op_fee_history);
        setIsloading(false);
      }
    });
  };

  // handle edit profile click
  const onEdit = () => {
    history.push({
      pathname: `/admin/doctorslisting/editdoctor/${docId}/${userId}`,
    });
  };

  // handle bookappointment click
  const handleBookAppointment = () => {
    const date = timeZoneDate();
    const data = {
      label: doctorDetails.name + ' ' + doctorDetails.last_name,
      value: doctorDetails.doctor_id,
      type: 'doctor',
    };
    dispatch(receiveSearchDoctorDetails({ date, data }));
    history.push('/admin/appointments/addAppointment');
  };

  // handle about show more toggle
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  // handle qulaification show more toggle
  const toggleQualifyShowMore = () => {
    setShowQualify(!showQualify);
  };

  // on feehistory view click
  const handleFeehistory = () => {
    setShowHistory(!showHistory);
  };

  // on fee history close click
  const handleHistoryClose = () => {
    setShowHistory(false);
  };

  const handleAddAppointment = () => {
    history.push({
      pathname: '/admin/appointments/addAppointment',
      state: { currentPage: location.state.currentPage},
    });
  }

  const timeToSec=(time)=>{
    const [hour,min,sec]=time.split(':').map(Number)
    return hour*3600 + min*60 + sec
  }

  const sortFunction=(val)=>{
  return val.sort((a,b)=>(timeToSec(a.start_time)-timeToSec(b.start_time)))
  }
  
  return (
    <div className="doctor-details-page">
      {isLoading ? (
        <div
          className="list-user"
          style={{ minHeight: isLoading ? "55vh" : "" }}
        >
          <Loader />
        </div>
      ) : (
        <div className="content outer-wrapper doctor-view details-doctor">
          <div className="min-height-wrapper">
            {ACCESSOF(APPOINTMENT, CREATE) && userType !== USER_TYPE.doctor && (
              <div className="page-title">
                <div className="bread-crumbs">
                  {from == "appointment" ? (
                    <>
                      <a className="previous" href="#/admin/appointments">
                        {translate("appointments.title", "Appointments")}
                      </a>
                      <span> {">"} </span>
                      <a className="previous" onClick={handleAddAppointment}>
                        {" "}
                        {translate(
                          "appointments.addAppointment",
                          "Add Appointment"
                        )}{" "}
                      </a>
                      <span>{">"}</span>{" "}
                        {translate("doctors.doctorDetails", "Doctor Details")}
                    </>
                  ) : (
                    <>
                      <a href="#/admin/doctorslisting">
                        {translate("doctors.title", "Doctors")}
                      </a>
                      <span>{">"}</span>{" "}
                      {translate("doctors.doctorDetails", "Doctor Details")}
                    </>
                  )}
                </div>
              </div>
            )}
            <div
              className={`row ${
                ACCESSOF(APPOINTMENT, CREATE) &&
                userType !== USER_TYPE.doctor &&
                "mt-3 pt-1"
              }`}
            >
              <div className="col-lg-8 no-transition">
                <div className="personal-info">
                  {doctorDetails && doctorDetails.profile_img ? (
                    <CardMedia
                      style={{
                        width: 90,
                        height: 90,
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      component="img"
                      image={doctorDetails.profile_img}
                    />
                  ) : (
                    <img src={svgIc} alt="user " />
                  )}
                  <div className="info">
                    <div className="d-flex align-items-end gap-2 flex-wrap flex-mobile">
                      <h5 className="name mb-0 text-capitalize">
                        {translate("doctors.doc", "Dr")}.{" "}
                        {doctorDetails && doctorDetails.name}{" "}
                        {doctorDetails && doctorDetails.last_name}
                      </h5>
                      <span className="small ps-2">
                        {GENDER_LABEL[doctorDetails?.gender]}
                        {doctorDetails && doctorDetails.experience
                          ? `${
                              GENDER_LABEL[doctorDetails?.gender] ? "," : ""
                            } ${doctorDetails.experience} ${
                              doctorDetails.experience === 1 ? "Yr" : "Yrs"
                            } Exp.`
                          : ""}
                      </span>
                    </div>
                    <div className="pt-1 pb-2 mb-1">
                        {doctorDetails?.designation
                          ? <span className="speciality pe-1">{doctorDetails.designation}</span>
                          : ""}
                      <span
                        className={`${doctorDetails?.doctor_status} activity-badge`}
                      >
                        <span className="dot">●</span>{" "}
                        {doctorDetails &&
                        doctorDetails.doctor_status === "active"
                          ? translate("common.active", "Active")
                          : translate("common.inactive", "Inactive")}
                      </span>
                    </div>
                    <div className="d-flex align-items-center gap-2 flex-wrap badge-group mob-center">
                      {department?.map((i, index) => {
                        if (index < 2)
                          return (
                            <span
                              className="department text-capitalize"
                              key={index}
                            >
                              {i.dept_name}
                            </span>
                          );
                      })}
                      <div>
                        <Tooltip
                          title={department?.slice(2).map((x, i) => <p className='text-capitalize' key={i}>{x.dept_name}</p>)}
                          placement='bottom'
                        >
                          {department.length > 2 ? (
                            <span
                              className="department extra-details ms-0"
                              style={{ cursor: "pointer" }}
                            >
                              {department.length - 2}+
                            </span>
                          ) : null}
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailed-info">
                  <div className="type-and-fees">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-suitcase-lg"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2h3.5A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5H14a.5.5 0 0 1-1 0H3a.5.5 0 0 1-1 0h-.5A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2zm1 0h4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1M1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5H3V3zM15 12.5v-9a.5.5 0 0 0-.5-.5H13v10h1.5a.5.5 0 0 0 .5-.5m-3 .5V3H4v10z" />
                      </svg>
                      {translate("doctors.appointmenttype", "Appointment Type")}{" "}
                      :{" "}
                      <span className="grey">
                        {doctorDetails?.op_appointment ? "OP" : ""}
                      </span>
                    </span>
                    <span className="grey-line"></span>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-coin"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z" />
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                      </svg>{" "}
                      {translate("doctors.fees", "Fee")} :{" "}
                      <span className="grey">
                        {doctorDetails?.op_fees ? doctorDetails.op_fees : "0"}
                      </span>{" "}
                      {feeHistory?.length && (
                        <div className="popover-container v2">
                          <span onClick={handleFeehistory}>
                            <a>
                              <img className="ps-1" src={historyIcon} alt="" />
                            </a>
                          </span>
                          <div
                            className={`custom-popover ${
                              showHistory ? "show" : ""
                            }`}
                            ref={ref}
                          >
                            <div className="popover-header">
                              <h6>
                                {translate("doctors.feeHistory", "Fee History")}
                              </h6>
                              <button
                                className="btn-close"
                                onClick={handleHistoryClose}
                              ></button>
                            </div>
                            <div className="popover-body">
                              {feeHistory.map((val) => {
                                return (
                                  <>
                                    <div className="single-history">
                                      <span>
                                        {formatDateto_D_M_Y(
                                          new Date(val?.created_at),
                                          language_Code
                                        )}
                                        {/* {dateInFormat2(
                                        formatDate(val?.created_at)
                                      )} */}
                                        ,
                                        <small>
                                          {appendTime(
                                            new Date(val?.created_at),
                                            language_Code
                                          )}
                                          {/* {timeIn12HourfromDate(val?.created_at)} */}
                                        </small>
                                      </span>
                                      <span className="amount">
                                        ₹ {val.fee}
                                      </span>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </span>
                  </div>
                  <p className="description">
                    {doctorDetails?.about
                      ? doctorDetails.about?.slice(
                          0,
                          showMore
                            ? doctorDetails && doctorDetails.about.length
                            : 150
                        )
                      : translate(
                          "doctors.noInfo",
                          "No personal information added."
                        )}{" "}
                    {doctorDetails.about?.length > 150 ? (
                      <a className="more text-primary" onClick={toggleShowMore}>
                        {showMore ? " ...less" : "more..."}
                      </a>
                    ) : null}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 ps-xl-3">
                <div className="lets-connect">
                  <h6>
                    {userType !== USER_TYPE.doctor
                      ? translate("doctors.connect", "Let’s Connect")
                      : "Contact Info"}
                  </h6>
                  {doctorDetails && doctorDetails.email ? (
                    <div className="icon-detail">
                      <i className="bi bi-envelope"></i>
                      <span>{doctorDetails.email}</span>
                    </div>
                  ) : null}
                  {doctorDetails &&
                  doctorDetails.phone &&
                  doctorDetails.tele_country_code ? (
                    <div className="icon-detail">
                      <i className="bi bi-telephone"></i>
                      <span>
                        +{doctorDetails.tele_country_code} {doctorDetails.phone}
                      </span>
                    </div>
                  ) : null}
                    {doctorDetails &&
                      doctorDetails.alternate_phone ? (
                      <div className="icon-detail">
                        <i className="bi bi-telephone"></i>
                        <span>
                          {doctorDetails.alternate_phone}
                        </span>
                      </div>
                    ) : null}

                  <div className="button-wrap">
                    {/* //apointment create */}
                    {ACCESSOF(APPOINTMENT, CREATE) &&
                      userType !== USER_TYPE.doctor && 
                      doctorDetails.doctor_status === "active" &&
                      (
                        <button
                          className="btn btn-primary"
                          onClick={handleBookAppointment}
                        >
                          {translate(
                            "patientPortal.bookAppointment",
                            "Book Appointment"
                          )}
                        </button>
                      )}
                    {ACCESS(UPDATE) &&
                      userType !== USER_TYPE.appointmentCordinator && (
                        <button
                          className={`${
                            ACCESSOF(APPOINTMENT, CREATE) &&
                            userType !== USER_TYPE.doctor
                              ? "btn-outlined"
                              : "btn-primary"
                          } btn`}
                          onClick={onEdit}
                        >
                          {translate("doctors.editProfile", "EDIT PROFILE")}
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h6 className="sub-heading">
                {translate("doctors.qualify", "Qualifications")}
              </h6>
              {doctorDetails && doctorDetails.qualification_details ? (
                <ol className="mb-5">
                  {doctorDetails.qualification_details
                    .split("\n")
                    .filter((x) => !!x)
                    .map((x, index) => {
                      if (showQualify || (!showQualify && index < 4))
                        return (
                          <li key={index + 1}>
                            {x}{" "}
                            {(!showQualify &&
                              index === 3 &&
                              doctorDetails.qualification_details
                                .split("\n")
                                .filter((x) => !!x).length > 4) ||
                            (showQualify &&
                              index ===
                                doctorDetails.qualification_details
                                  .split("\n")
                                  .filter((x) => !!x).length -
                                  1) ? (
                              <a
                                className="more text-primary"
                                onClick={toggleQualifyShowMore}
                              >
                                {showQualify ? " ...less" : "more..."}
                              </a>
                            ) : null}
                          </li>
                        );
                    })}
                </ol>
              ) : (
                <div className="patient-portal">
                  <NoResultFound
                    text={translate(
                      "doctors.noQual",
                      "No qualifications added"
                    )}
                    heightClass="nodata-height"
                  />
                </div>
              )}
            </div>
            <div className="d-flex align-items-center gap-3 mt-3 mb-1 pb-1">
              <h6 className="sub-heading">
                {translate("patientPortal.availability", "Availability")}
              </h6>
              {ACCESS(SCHEDULE_READ) ? (
                <a
                  className="more text-primary manage-slots-btn mb-0"
                  onClick={directTOSlotManage}
                >
                  <img src={slotIcon} alt="" />{" "}
                  {translate("appointments.manageSlots", "Manage Slots")}
                </a>
              ) : null}
            </div>
            {opSchedule && Object.entries(opSchedule).length ? (
              <>
                <div className="slots">
                  {opSchedule &&
                    Object.entries(opSchedule)
                      ?.sort((a, b) => {
                        const order = {
                          sun: 0,
                          mon: 1,
                          tue: 2,
                          wed: 3,
                          thu: 4,
                          fri: 5,
                          sat: 6,
                        };
                        return order?.[a[0]] - order?.[b[0]];
                      })
                      ?.map(([key, value], index) => (
                        <div className="single-slot" key={index + 1}>
                          <span className="day d-block">
                            {key === "mon"
                              ? translate("common.monday", "Monday")
                              : key === "tue"
                              ? translate("common.tuesday", "Tuesday")
                              : key === "wed"
                              ? translate("common.wednesday", "Wednesday")
                              : key === "thu"
                              ? translate("common.thursday", "Thursday")
                              : key === "fri"
                              ? translate("common.friday", "Friday")
                              : key === "sat"
                              ? translate("common.saturday", "Saturday")
                              : key === "sun"
                              ? translate("common.sunday", "Sunday")
                              : null}
                          </span>
                          {sortFunction(value)?.map((i, ind) => {
                            if (ind < 2) {
                              return (
                                <span className="time d-block" key={ind + 1}>
                                  {appendTime(i.start_time, language_Code)} -{" "}
                                  {appendTime(i.end_time, language_Code)}
                                </span>
                              );
                            }
                          })}
                          {value?.length > 2 ? (
                            <Tooltip
                              classes={{ tooltip: "tooltip-padding max-height" }}
                              title={
                                <div className='tooltip-body with-max-height'>
                                  {
                                    sortFunction(value)?.map((i, ind) => {
                                      if (ind >= 2) {
                                        return (
                                             <span
                                            className="time d-block text-center"
                                            key={ind + 1}
                                          >
                                            {appendTime(i.start_time, language_Code)}{" "}
                                            - {appendTime(i.end_time, language_Code)}
                                          </span>
                                        );
                                      }
                                    })
                                  }
                                </div>
                              }
                              placement="bottom"
                            >
                              <span className="time d-block nothing-to-show">
                                <a className='td-none"'>
                                  +{value?.length - 2} more...
                                </a>
                              </span>
                            </Tooltip>
                          ) : null}
                        </div>
                      ))}
                </div>
              </>
            ) : (
              <div className="patient-portal doctor-details-design">
                <NoResultFound
                  text={translate("doctors.noSlots", "No slots added")}
                  heightClass="nodata-height"
                />
              </div>
            )}
          </div>
          {/* appointmrnt create for execute */}
          <div className="back-to-btn">
            {ACCESSOF(APPOINTMENT, CREATE) && userType !== USER_TYPE.doctor && (
              <button
                className="btn btn-outlined"
                onClick={() => history.goBack()}
              >
                {translate("cancelAppt.backToAppt", "Back to listing.")}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  overrideList: state.LeaveReducer.overrideList,
  // userDetails: state.LoginReducer.loginDetails,
  userType: state.LoginReducer.user_type,
  language_Code: state.SettingsReducer.locale,
});
const mapDispatchToProps = (dispatch) => ({
  getOverrideList: (id) => dispatch(FetchOverrideDetails(id)),
});

export default compose(
  withPermissionCheck(DOCTOR),
  connect(mapStateToProps, mapDispatchToProps)
)(DoctorDetails);
