import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import {
  getLocalStorageItem,
} from '../../Utils/LocalStorage/local-storage';
import Login from './Login';
// import ResetPassword from './ResetPassword';
import NotFound from '../NotFound/NotFound';
import { PORTAL_TYPES } from 'Constants/constant';
import { timeZoneDate } from 'Utils/DateFormatter/Date';
// import ForgotPassword from './ForgotPassword';

const Authentication = () => {
  let { path } = useRouteMatch();

  useEffect(() => {
    document.body.classList.add('admin_portal');

    return () => document.body.classList.remove('admin_portal');
  }, []);

  const authRoutes = [
    {
      url: 'login',
      component: Login,
    },
    // {
    //   url: 'reset-password/:id',
    //   component: ResetPassword,
    // },
    // {
    //   url: 'forgot-password',
    //   component: ForgotPassword,
    // },
  ];

  const isLoggedIn = () => {
    const accessToken = getLocalStorageItem('access_token');
    const refreshTokenExp = getLocalStorageItem('refresh_token_exp');
    const currentDate = timeZoneDate();
    const refreshExpiryTime = new Date(refreshTokenExp * 1000);
    const portalType = getLocalStorageItem('portalType');
    if (!accessToken || accessToken === '') {
      return false;
    } else if (portalType === PORTAL_TYPES.patient) {
      return false;
    } else if (accessToken && currentDate > refreshExpiryTime) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="admin_portal">
      <Switch>
        {authRoutes.map((route, index) =>
          isLoggedIn() ? (
            <Redirect key={index} to="/admin" />
          ) : (
            <Route
              path={`${path}/${route.url}`}
              key={route.url}
              component={route.component}
              exact
            />
          )
        )}
        <Route exact path={path}>
          <Redirect to="/admin/auth/login" />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default Authentication;
