import React from 'react';
import { Route } from 'react-router-dom';
import AddDoctor from './AddDoctor';
import DoctorDetails from './DoctorDetails';
import DoctorListing from './DoctorListing';
import { useSelector } from 'react-redux';
import Unauthorized from 'Components/NotFound/UnauthorisedPage';
import { DOCTOR } from 'Constants/feature';
import { CREATE, READ, UPDATE } from 'Constants/scopes';
import { USER_TYPE } from 'Constants/userTypes';

const DoctortMain = (props) => {
  const permissions = useSelector(state => state.LoginReducer.userPermissions.ResourceNameScope)
  const userType = useSelector((state) => state.LoginReducer.user_type);
  const { match } = props;

  const checkpermission=(feature,scope)=>{
    const permission = permissions?.[feature];
    if (!permission || !permission?.length ) {
      return false
    }
    else{
      return permission.includes(scope)
    }
}
  return (
    <>
    <Route path={`${match.path}/adddoctor`} exact component={checkpermission(DOCTOR,CREATE)?AddDoctor:Unauthorized} />
      <Route exact path={`${match.path}/editdoctor/:id/:userId`} component={checkpermission(DOCTOR,UPDATE)?AddDoctor:Unauthorized} />
      <Route
        exact
        path={`${match.path}/doctordetails/:id/:userId`}
        component={DoctorDetails}
      />
      <Route path={match.path} exact component={checkpermission(DOCTOR,READ)&& userType !== USER_TYPE.doctor ?DoctorListing:Unauthorized} /> 
    </>
  );
};

export default DoctortMain;
