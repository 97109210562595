import { ALL_VALUE } from 'Constants/constant';
import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { useIntl } from 'react-intl';
import { translate } from 'Language/Translate';

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    cursor: 'text',
    textTransform: 'capitalize',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: state.isDisabled ? 'no-drop' : 'pointer',
    fontSize: '13px',
    textTransform: 'capitalize',
  }),
  dropdownIndicator: (defaultStyles) => {
    return {
      ...defaultStyles,
      cursor: 'pointer',
    };
  },
  clearIndicator: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
};

const errorStyle = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    cursor: 'text',
    border: '1px solid red',
    textTransform: 'capitalize',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: state.isDisabled ? 'no-drop' : 'pointer',
    textTransform: 'capitalize',
  }),
  dropdownIndicator: (defaultStyles) => {
    return {
      ...defaultStyles,
      cursor: 'pointer',
    };
  },
  clearIndicator: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
};

const InputOption = ({
  getStyles,
  // Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = 'transparent';
  if (isFocused) bg = '#eee';
  if (isActive) bg = '#B2D4FF';

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex ',
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} className="me-2" />
      {children}
    </components.Option>
  );
};

function MultipleSelect({
  value,
  setSelectDeptValue = () => {},
  selectedDeptValue = [],
  custom,
  isDisabled = false,
  isLoading = false,
  maxToShow = 2,
  isClearable = true,
  reference=null,
  noOptionsMessage = () => (
    <div className="noOption">
      {translate('common.noData', 'No data found')}
    </div>
  ),
}) {
  const intl = useIntl();
  const handleChange = (values, options, actionMeta) => {
    setSelectDeptValue(values, options, actionMeta);
  };
  // Check if the select value is "all"
  const isAllSelected =
    Array.isArray(selectedDeptValue) &&
    selectedDeptValue.some((option) => option.value === ALL_VALUE.value);

  // If "all" is selected, set the selectedDeptValue to all options
  if (isAllSelected) {
    selectedDeptValue = value;
  }
  return (
    <div>
      <Select
        closeMenuOnSelect={false}
        // menuIsOpen
        isMulti
        isDisabled={isDisabled}
        options={value}
        onChange={(e, actionMeta) => handleChange(e, value, actionMeta)}
        hideSelectedOptions={false}
        value={selectedDeptValue}
        styles={custom ? errorStyle : customStyles}
        menuPortalTarget={document.body}
        maxMenuHeight={150}
        components={{
          MultiValue: ({ children, ...rest }) => (
            <MultiValue isAllSelected={isAllSelected} maxToShow={isAllSelected ? 1 : maxToShow} {...rest}>
              {children}
            </MultiValue>
          ), Option: InputOption,
          MultiValueRemove: (props)=> isClearable ? <components.MultiValueRemove {...props} /> : null
        }}
        placeholder={`${intl.formatMessage({
          id: 'common.select',
          defaultMessage: 'select',
        })}...`}
        tabSelectsValue={false}
        isLoading={isLoading}
        ref={reference}
        noOptionsMessage={noOptionsMessage}
      />
    </div>
  );
}

export default MultipleSelect;

const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: 'auto',
    // border: '1px solid red',
    background: '#d4eefa',
    borderRadius: '4px',
    fontFamily: 'Open Sans',
    fontSize: '11px',
    padding: '3px 5px',
    order: 99,
  };

  const title = items.join(', ');
  const length = items.length;
  const label = `+ ${length} `;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({
  index,
  getValue,
  isAllSelected,
  maxToShow,
  ...props
}) => {
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow && !isAllSelected ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
