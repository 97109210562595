/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';
import { translate } from '../../Language/Translate';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import '../../Assets/style/table.css';
import {
  APPOINTMENT_HEADERS,
  APPOINTMENT_STATUS,
  APPOINTMENT_STATUS_NAMES,
  APPOINTMENT_STATUS_OPTIONS_ALL,
  PAID,
} from '../../Constants/constant';
// import HeaderFields from '../Common/HeaderFeilds';
import greenTick from 'Assets/img/icons/green-tick-badge.svg'
import ReactSelectDropDown from '../Common/ReactSelectDropDown';
import Loader from '../Common/Loader';
import Pagination from '../Common/Pagination';
import ConformPopup from '../Common/ConformPopup';
import EditAppointments from './EditAppointment';
import PopupWrapeer from '../Common/PopupWrapeer';
import network from '../../Utils/Api/Network';
import {
  CancelAppointment,
  clearAppointmentReducer,
  clearAppointmentExceptFilter,
  clearDoctorSlots,
  clearSearchDoctorDetails,
  DeleteAppointment,
  FetchAppointmentList,
  FetchDepartmentData,
  // FetchDoctorSlots,
  updateSearchData,
  UpdateAppointment,
  updateCurrentPage,
  updatePerPage,
  updateColumnSort,
  paymentHandle,
  receiveSearchDoctorDetails,
} from '../../Actions/appointmentAction';
import {
  appendTime,
  dateinFormatDDMMMYYYY,
  formatDate,
  // formatDateForRoaster,
  formatDateto_D_WD_M_Y,
  formatDateInDDMMYYYY,
  timeZoneDate,
} from '../../Utils/DateFormatter/Date';
import { clearDepartmentData } from '../../Actions/departmentAction';
import CancelPopup from './CancelPopup';
import TableColumnHr from 'Components/Common/TableColumnHr';
import AntMultiDatePicker from 'Components/Patient_Portal/Common/AntMultiDatePicker';
import usePrevious from 'Hooks/usePrevious';
import useDidMountEffect from 'Hooks/useDidMountEffect';
import { isEqual } from 'lodash';
import ViewSwitch from 'Components/Common/ViewSwitch';
import PaymentInfoPopUp from './PaymentInfoPopUp';
import { compose } from 'redux';
import withPermissionCheck from 'Utils/WithUtils/WithPermission';
import { APPOINTMENT } from 'Constants/feature';
import { CREATE, DELETE, STATUS_UPDATE, UPDATE } from 'Constants/scopes';
import telephone from 'Assets/img/icons/telephone.svg'
import envelope from 'Assets/img/icons/envelope.svg'
import NoResultFound from 'Components/Common/NoResult';
import { removeDrPrefix } from 'Utils/StringFormatter/String';
import { USER_TYPE } from 'Constants/userTypes';
import TableWrapper from 'Components/Common/TableWrpper';
// import CoinIcon from 'Assets/img/icons/coin.svg';


const AddIcon = <svg width="44px" height="44px" viewBox="0 0 44 44" version="1.1">
    <title>Group 13</title>
    <g id="Client-Portal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Doctors-Appointments---Own-View-2" transform="translate(-1263, -790)">
            <g id="Group-13" transform="translate(1263, 790)">
                <circle id="Oval" fill="#5875FF" cx="22" cy="22" r="22"></circle>
                <g id="Group-12" transform="translate(14, 14.5)" stroke="#FFFFFF" strokeLinecap="square" strokeWidth="2">
                    <line x1="15.6520462" y1="7.8260231" x2="0" y2="7.8260231" id="Line-8-Copy"></line>
                    <line x1="7.8260231" y1="15.6520462" x2="7.8260231" y2="3.28626015e-14" id="Line-8-Copy-2"></line>
                </g>
            </g>
        </g>
    </g>
</svg>


const blockIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_377_499)">
<path d="M7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.05 13.75 7.5 13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25ZM7.5 12.5C4.7375 12.5 2.5 10.2625 2.5 7.5C2.5 6.34375 2.89375 5.28125 3.55625 4.4375L10.5625 11.4437C9.71875 12.1062 8.65625 12.5 7.5 12.5ZM11.4437 10.5625L4.4375 3.55625C5.28125 2.89375 6.34375 2.5 7.5 2.5C10.2625 2.5 12.5 4.7375 12.5 7.5C12.5 8.65625 12.1062 9.71875 11.4437 10.5625Z" fill="#505968"/>
</g>
<defs>
<clipPath id="clip0_377_499">
<rect width="15" height="15" fill="white"/>
</clipPath>
</defs>
</svg>

const moreIcon = <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.76221 8.5C1.76221 4.74494 4.83214 1.675 8.58721 1.675C12.3423 1.675 15.4122 4.74494 15.4122 8.5C15.4122 12.2551 12.3423 15.325 8.58721 15.325C4.83214 15.325 1.76221 12.2551 1.76221 8.5ZM8.58721 2.825C5.46727 2.825 2.91221 5.38007 2.91221 8.5C2.91221 11.6199 5.46727 14.175 8.58721 14.175C11.7071 14.175 14.2622 11.6199 14.2622 8.5C14.2622 5.38007 11.7071 2.825 8.58721 2.825Z" fill="#505968"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.51 8.5C10.51 8.18244 10.7674 7.925 11.085 7.925H11.0906C11.4082 7.925 11.6656 8.18244 11.6656 8.5C11.6656 8.81757 11.4082 9.075 11.0906 9.075H11.085C10.7674 9.075 10.51 8.81757 10.51 8.5Z" fill="#505968"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.00938 8.5C8.00938 8.18244 8.26682 7.925 8.58438 7.925H8.59C8.90756 7.925 9.165 8.18244 9.165 8.5C9.165 8.81757 8.90756 9.075 8.59 9.075H8.58438C8.26682 9.075 8.00938 8.81757 8.00938 8.5Z" fill="#505968"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.50877 8.5C5.50877 8.18244 5.76621 7.925 6.08377 7.925H6.08939C6.40695 7.925 6.66439 8.18244 6.66439 8.5C6.66439 8.81757 6.40695 9.075 6.08939 9.075H6.08377C5.76621 9.075 5.50877 8.81757 5.50877 8.5Z" fill="#505968"/>
<path opacity="0.01" fill-rule="evenodd" clip-rule="evenodd" d="M16.0872 1V16H1.08716V1H16.0872Z" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const paymentIcon = <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.58716 0C3.72669 0 0.587158 3.13953 0.587158 7C0.587158 10.8605 3.72669 14 7.58716 14C11.4476 14 14.5872 10.8605 14.5872 7C14.5872 3.13953 11.4476 0 7.58716 0ZM7.58716 0.933333C10.9432 0.933333 13.6575 3.64394 13.6575 7C13.6575 10.3561 10.9432 13.0703 7.58716 13.0703C4.2311 13.0703 1.52049 10.3561 1.52049 7C1.52049 3.64394 4.2311 0.933333 7.58716 0.933333ZM7.12231 3.74154C7.11929 3.48486 7.32412 3.27392 7.58078 3.2694C7.70691 3.26719 7.82857 3.31613 7.91803 3.40507C8.00749 3.49402 8.05713 3.61539 8.05565 3.74154V4.13529C8.80849 4.27748 9.45474 4.7622 9.45474 5.60182C9.45474 5.85981 9.2456 6.06895 8.98761 6.06895C8.72963 6.06895 8.52049 5.85981 8.52049 5.60182C8.52049 4.82405 6.65747 4.82405 6.65747 5.60182C6.65747 5.95182 6.73994 6.06427 6.91177 6.19792C7.08359 6.33156 7.38573 6.44219 7.73573 6.55885C7.75575 6.56553 7.77593 6.57222 7.79625 6.57896C8.1312 6.69006 8.50471 6.81394 8.83768 7.07292C9.19085 7.34761 9.45474 7.8194 9.45474 8.40273C9.45474 9.24308 8.80871 9.72917 8.05565 9.87109V10.2621C8.0594 10.4313 7.97128 10.5893 7.82535 10.675C7.67942 10.7607 7.49854 10.7607 7.35261 10.675C7.20668 10.5893 7.11856 10.4313 7.12231 10.2621V9.87109C6.36938 9.72909 5.72414 9.24296 5.72414 8.40273C5.72038 8.23354 5.8085 8.07557 5.95443 7.98987C6.10036 7.90418 6.28125 7.90418 6.42718 7.98987C6.57311 8.07557 6.66122 8.23354 6.65747 8.40273C6.65747 9.18051 8.52049 9.18051 8.52049 8.40273C8.52049 8.05273 8.43802 7.94028 8.26619 7.80664C8.09437 7.673 7.79224 7.56146 7.44224 7.44479C7.42066 7.4376 7.3989 7.43039 7.37697 7.42312C7.04324 7.31253 6.67168 7.1894 6.34028 6.93164C5.98711 6.65695 5.72414 6.18516 5.72414 5.60182C5.72414 4.76094 6.36881 4.27573 7.12231 4.13438V3.74154Z" fill="#505968"/>
</svg>

const deleteIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.1085 3.7371C11.0299 3.5311 8.93875 3.42499 6.85388 3.42499C5.61793 3.42499 4.38199 3.48741 3.14604 3.61225L1.87264 3.7371" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.30582 3.10625L5.44315 2.2875C5.54303 1.69375 5.61793 1.25 6.67285 1.25H8.3083C9.36322 1.25 9.44437 1.71875 9.538 2.29375L9.67533 3.10625" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.7665 5.71252L11.3608 12.0063C11.2921 12.9875 11.2359 13.75 9.49435 13.75H5.48689C3.74533 13.75 3.68915 12.9875 3.62049 12.0063L3.21475 5.71252" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.44814 10.3125H8.52677" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.93004 7.8125H9.05112" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const editIcon = <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.72757 7.68029C3.53419 7.88654 3.34706 8.29279 3.30963 8.57404L3.07883 10.599C2.99774 11.3303 3.52172 11.8303 4.24531 11.7053L6.25388 11.3615C6.53458 11.3115 6.92757 11.1053 7.12094 10.8928L12.2422 5.46154C13.128 4.52404 13.5272 3.45529 12.1486 2.14904C10.7763 0.855287 9.73458 1.31154 8.84881 2.24904L3.72757 7.68029Z" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.99438 3.15625C8.26261 4.88125 9.65988 6.2 11.394 6.375" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.44946 13.75H13.6775" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


const status = {
  upcoming: 'upcoming',
  notattempted: 'notattempted',
  cancelled: 'cancelled',
  label: 'Cancelled',
  completed: 'completed',
  inprogress: 'in-progress',
  empty: 'empty',
  waiting: 'waiting',
};


let apiTimeout = null;

const Appointments = (props) => {
  const {
    getAppointments,
    // clearData,
    clearDataExceptFilter,
    appointmentList,
    //getDoctorSlots,
    doctorSlotData,
    editAppointment,
    deleteAppointment,
    selectedDepartmentData,
    getDepartmentData,
    clearDeptData,
    clearSlots,
    clearSearchDetails,
    onCancelAppointment,
    searchData,
    applySearchData,
    // currentPage,
    perPage,
    SetPerPage,
    // SetCurrentPage,
    columnSort,
    setColumnSort,
    handleViewModeChange,
    ACCESS: permission,
    paymentHandle,
    isBtnLoading,
    userType
  } = props;
  const intl = useIntl();
  const history = useHistory();
  const {
    editAppt: editApptLoading = false,
    delAppt: delApptLoading = false,
    cancelAppt: cancelApptLoading = false,
  } = isBtnLoading;

  const dispatch = useDispatch();
  // filter hanlde
  const [search, setSearch] = useState('');
  const [searchPatient, setSearchPatient] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedStatus, SetSelectedStatus] = useState('');
  const [suggestion, setSuggestion] = useState([]);
  const [patientSuggestion, setPatientSuggestion] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [isPatientSelect, setPatientIsSelect] = useState(false);
  const [searchDataDetails,setSearchDataDetails]=useState({})

  // appointment list handle
  const [appointments, setAppointments] = useState([]);
  const [isLoading, updateLoading] = useState(false);
  const [reschedView, setReschdView] = useState({});

  //Edit details
  const [showPopup, updateShowPopup] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  const [error, setError] = useState('');
  const [feildError, setFeildError] = useState({
    deptError: false,
    doctorError: false,
    dateError: false,
    slotError: false,
    scheduleError: false,
    reasonError: false,
  });
  // cancel Deatils
  const [showCancelPopup, updateCancelPopup] = useState(false);
  const [cancelId, setCancelId] = useState('');
  // Delete deatils
  const [showConformPopup, updateShowConformPopup] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  // pagination handle
  const [currentPage, SetCurrentPage] = useState(1);
  // const [perPage, SetPerPage] = useState(10);
  const [totalCount, SetTotalCount] = useState(null);
  const [openPaymentInfo, setOpenPaymentInfo] = useState(false);

  // to store payment appointment id
  const [paymentAppt, setPaymentAppt] = useState('');

  // search  laoder
  const [patientloader, setPatientloader] = useState({});

  // search loader
  const [docloader, setDocloader] = useState({});
  const [openFilter, setOpenFilter] = useState(false);

  const divRef = useRef(null)

  // handle outside close of filter
  const handleOutsideClose = () => {
    setSearch(searchData?.search ?? '');
    setSearchPatient(searchData?.searchPatient ?? '');
    setStartDate(searchData?.startDate ?? '');
    setEndDate(searchData?.endDate ?? '');
    SetSelectedStatus(searchData?.selectedStatus ?? '');
    setIsSelect(true);
    setPatientIsSelect(true);
  }

  const handleClickOutside = (event) => {
    const isAntd = Array.from(event.target.classList).some((className) => className.includes('ant-picker'))
    if (divRef.current && !divRef.current.contains(event.target) && !isAntd && openFilter) {
      setOpenFilter(false);
      handleOutsideClose()
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openFilter]);


  // get appointmeentList
  const fetchAppointments = ({
    searchName: searchPatient,
    search: searchWord,
    appStatus,
    pageNo,
    perPageCount,
    start_Date,
    end_Date,
  } = {}) => {
    const sortKey = Object.keys(columnSort)[0];
    updateLoading(true);
    const config = {
      patient_search:
        searchPatient || searchPatient === ''
          ? searchPatient || undefined
          : searchData?.searchPatient
          ? searchData?.searchPatient
          : undefined,
      dept_doc_search:
        searchWord || searchWord === ''
          ? removeDrPrefix(searchWord) || undefined
          : searchData?.search
          ? removeDrPrefix(searchData?.search)
          : undefined,
      start_date:
        start_Date || start_Date === ''
          ? start_Date || undefined
          : searchData?.startDate
          ? searchData?.startDate
          : undefined,
      end_date:
        end_Date || end_Date === ''
          ? end_Date || undefined
          : searchData?.endDate
          ? searchData?.endDate
          : undefined,
      status:
        appStatus || appStatus === ''
          ? appStatus || undefined
          : searchData?.selectedStatus?.value
          ? searchData?.selectedStatus?.value
          : undefined,
      page: pageNo || currentPage,
      limit: perPageCount || perPage,
      sort: sortKey
        ? columnSort[sortKey] === 'asc'
          ? sortKey
          : '-'+sortKey.split(',').join(',-')
        : undefined,
    };
    getAppointments(config, updateLoading);
  };
  // to Handle Change Date
  const HandleChangeDate = (e) => {
    if (e) {
      setStartDate(formatDate(e[0]) ?? '');
      setEndDate(formatDate(e[1]) ?? '');
    } else {
      setStartDate('');
      setEndDate('');
    }
  };

  useEffect(() => {
    setSearch(searchData?.search ? 'Dr.' + ' ' + searchData?.search: '');
    setSearchPatient(searchData?.searchPatient ?? '');
    setStartDate(searchData?.startDate ?? '');
    setEndDate(searchData?.endDate ?? '');

    SetSelectedStatus(searchData?.selectedStatus ?? '');
    setIsSelect(searchData?.isSelect || !!searchData?.search);
    setPatientIsSelect(searchData?.isPatientSelect || !!searchData?.searchPatient);
    fetchAppointments({
      searchName: searchData?.searchPatient,
      search: searchData?.search,
      appStatus: searchData?.selectedStatus?.value,
      start_Date: searchData?.startDate,
      end_Date: searchData?.endDate,
    });
    return () => clearDataExceptFilter();
  }, []);

  const prevSortVal = usePrevious(columnSort);

  useDidMountEffect(() => {
    if (!isEqual(prevSortVal, columnSort)) {
      fetchAppointments({
        searchName: searchData?.searchPatient,
        search: searchData?.search,
        appStatus: searchData?.selectedStatus?.value,
        start_Date: searchData?.startDate,
        end_Date: searchData?.endDate,
      });
    }
  }, [columnSort]);

  // api call with 3sec delay
  useEffect(() => {
    if (!isSelect) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadOptions(search);
      }, 300);
      apiTimeout = timeout;
    }
  }, [search, isSelect]);

  // api call with 3sec delay patient
  useEffect(() => {
    if (!isPatientSelect) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadOptionsPatient(searchPatient);
      }, 300);
      apiTimeout = timeout;
    }
  }, [searchPatient, isPatientSelect]);

  useEffect(() => {
    if (appointmentList?.appointments) {
      setAppointments(appointmentList.appointments);
      SetTotalCount(appointmentList?.page_details?.total_documents);
    } else {
      setAppointments([]);
      SetTotalCount(0);
    }
  }, [appointmentList]);

  // load options to the dropdown on typing
  const loadOptions = async (inputValue) => {
    let fetchedOptions = [];
    if (inputValue?.trim()) {
      let id = new Date().getTime()
      setDocloader((prev) => ({
          ...prev,
          [id]: true
        }));
      const config = {
          doc_with_dept: false,
          status: 'active,inactive',
          acl: userType === USER_TYPE.appointmentCordinator,
          limit: 0
      }
      await network
        .get({ url: `/search/department-doctor/${inputValue}`, data: config })
        .then((response) => {
          setDocloader((prev) => ({
              ...prev,
              [id]: false
            }))
          const { isError, result } = response;
          if (isError) {
            return;
          } else {
            let options = []
            result?.search_result?.map((item) => {
                options.push({
                  label: item.type === "doctor" ? 'Dr.' +' '+ item.name : item.name,
                  value: item.type === "doctor" ? 'Dr.'+' '+ item.name : item.name ,
                  type: item.type,
                  id: item?.id
                })
            });
            if (!options || !options?.length) {
              options.push({
                  value: '',
                  label: `${intl.formatMessage({
                    id: 'common.noData',
                    defaultMessage: 'No data found.',
                  })}`,
                  isDisabled: true,
              })
            }
            fetchedOptions = options;
          }
        })
        .catch(() => {});
    }
    setSuggestion(fetchedOptions);
  };

  // load options to the dropdown on typing patient
  const loadOptionsPatient = async (inputValue) => {
    let fetchedOptions = [];
    if (inputValue?.trim()) {
      let id = new Date().getTime()
      setPatientloader((prev) => ({
          ...prev,
          [id]: true,
        }));
      await network
        .get({ url: `/patients/search/patient-name/${inputValue}` })
        .then((response) => {
          setPatientloader((prev) => ({
              ...prev,
              [id]: false,
            }));
          const { isError, result } = response;
          if (isError) {
            return;
          } else {
            let options = result?.patients.map((item) => ({
              label: item.name,
              value: item.name,
              // type: item.type,
            }));
            if (!options || !options?.length) {
              options = [{
                  value: '',
                  label: `${intl.formatMessage({
                    id: 'common.noData',
                    defaultMessage: 'No data found.',
                  })}`,
                  isDisabled: true,
              }]
            }
            fetchedOptions = options;
          }
        })
        .catch(() => {});
    }
    setPatientSuggestion(fetchedOptions);
  };

  const isApplied = (
    (searchData?.search !== '' && searchData?.search) ||
    (searchData?.searchPatient !== '' && searchData?.searchPatient) ||
    (searchData?.startDate !== '' && searchData?.startDate) ||
    (searchData?.endDate !== '' && searchData?.endDate) ||
    (JSON.stringify(searchData?.selectedStatus) !== '[]' &&
    searchData?.selectedStatus?.value !== undefined)
  )

  // handle filter apply
  const handleApplyClick = () => {
    let searchDataNew = {
      ...searchData,
      search,
      startDate,
      endDate,
      date:startDate ?  new Date(startDate) : timeZoneDate(),
      selectedStatus,
      isSelect,
      isPatientSelect,
      searchPatient,
    };
    setOpenFilter(false)
    applySearchData(searchDataNew);
    setReschdView({});
    if (
      (search !== '' && search) ||
      (searchPatient !== '' && searchPatient) ||
      (startDate !== '' && startDate) ||
      (endDate !== '' && endDate) ||
      (JSON.stringify(selectedStatus) !== '[]' &&
        selectedStatus.value !== undefined) ||
      isApplied
    ) {
      fetchAppointments({
        pageNo: 1,
        search: search,
        searchName: searchPatient,
        appStatus: selectedStatus?.value || '',
        start_Date: startDate,
        end_Date: endDate,
      });
      SetCurrentPage(1);
    }
  };

  //handle filter reset
  const handleReset = () => {
    applySearchData({
      ...searchData,
      search: '',
      startDate: '',
      endDate: '',
      date: timeZoneDate(),
      selectedStatus: '',
      isSelect: '',
      isPatientSelect: '',
      searchPatient: '',
    });
    setOpenFilter(false)
    setColumnSort({});
    // clearData();
    setStartDate('');
    setEndDate('');
    SetSelectedStatus('');
    setSearch('');
    setSearchPatient('');
    SetPerPage(25);
    SetCurrentPage(1);
    setReschdView({});
    if (
      search ||
      searchPatient ||
      startDate ||
      endDate ||
      selectedStatus.length !== 0 ||
      currentPage !== 1 ||
      perPage !== 25 ||
      isApplied
    ) {
      fetchAppointments({
        searchName: '',
        search: '',
        appStatus: '',
        start_Date: '',
        end_Date: '',
        pageNo: 1,
        perPageCount: 25,
      });
    }
    setStartDate('');
    setEndDate('');
  };

  // handle add appointment button click
  const handleAddButton = () => {
    clearSearchDetails();
    const date = timeZoneDate();
    let data={...searchDataDetails,value:searchDataDetails?.id}
    if(searchDataDetails?.type){
      dispatch(receiveSearchDoctorDetails({ date, data}));
    }
    history.push('/admin/appointments/addAppointment');
  };

  // on pagination change
  const handleCurrentPageChange = (pageNo) => {
    SetCurrentPage(pageNo);
    fetchAppointments({ pageNo });
  };

  // handel perPage change
  const handlePerPageChange = (count) => {
    SetPerPage(count);
    SetCurrentPage(1);
    fetchAppointments({ pageNo: 1, perPageCount: count });
  };

  //handle delete click
  const onDelete = (item) => {
    updateShowConformPopup(true);
    setDeleteId(item.appointment_id);
  };

  // handle edit click
  const onEdit = (item) => {
    updateShowPopup(true);
    setDataToEdit(item);
  };

  // handle close of the popup
  const onClose = (id) => {
    const element = document.getElementById(`appointmentView-${id}`);
    const element1 = document.getElementById(`appointmentViewToggle-${id}`);
    element?.classList?.remove('show');
    element1?.classList?.remove('show');
  };

  // handle cancel Conform pop open
  const onHandleCancelOpen = (item) => {
    updateCancelPopup(true);
    setCancelId(item.appointment_id);
  };
  // handle cancel conform pop close
  const onHandleCancelClose = () => {
    updateCancelPopup(false);
    setCancelId('');
  };
  //handle cancel click
  const handleCancel = () => {
    const config = {
      data: {
        appt_status: 'canceled',
      },
    };
    onCancelAppointment(
      cancelId,
      config,
      fetchAppointments,
      onHandleCancelClose
    );
  };

  // handle edit close
  const onEditClose = () => {
    updateShowPopup(false);
    setDataToEdit({});
    setError('');
    setFeildError({
      deptError: false,
      doctorError: false,
      dateError: false,
      slotError: false,
      scheduleError: false,
      reasonError: false,
    });
  };

  // handle edit response success
  const handleEditClose = () => {
    updateShowPopup(false);
    setDataToEdit({});
    setError('');
    setFeildError({
      deptError: false,
      doctorError: false,
      dateError: false,
      slotError: false,
      scheduleError: false,
      reasonError: false,
    });
  };

  // handle edit confirmation
  const handleEditAppointment = () => {
    if (
      !dataToEdit.doctor_id ||
      !dataToEdit.dept_name ||
      !dataToEdit.appt_start_time ||
      !dataToEdit.appt_end_time ||
      !dataToEdit.appt_date ||
      !dataToEdit.reason
    ) {
      setFeildError({
        deptError: !dataToEdit.dept_name,
        doctorError: !dataToEdit.doctor_id,
        dateError: !dataToEdit.appt_date,
        slotError: !dataToEdit.appt_end_time || !dataToEdit.appt_start_time,
        scheduleError:
          !dataToEdit.session &&
          (!dataToEdit.appt_end_time || !dataToEdit.appt_start_time),
        reasonError: !dataToEdit.reason,
      });
      setError(
        translate(
          'common.requiredFields',
          'Please fill all the required fields'
        )
      );
    } else {
      // FIX-ME - fetch the data from backend, split first and last name
      const config = {
        data: {
          doctor_id: dataToEdit.doctor_id,
          dept_name: dataToEdit.dept_name,
          dept_id: dataToEdit.dept_id,
          schedule_type: 'op',
          appt_date: formatDate(dataToEdit.appt_date),
          appt_start_time: dataToEdit.appt_start_time,
          appt_end_time: dataToEdit.appt_end_time,
          appt_slot_session: dataToEdit.appt_slot_session,
          reason: dataToEdit.reason,
          patient_details: {
            patient_id: dataToEdit.patient_id,
            name: dataToEdit.patient_name,
            email: dataToEdit.patient_email,
            dob: '2023-12-12',
            gender: 'male',
          },
        },
      };
      editAppointment(
        dataToEdit.appointment_id,
        config,
        fetchAppointments,
        handleEditClose
      );
    }
  };

  // handle search value change
  const onChangeSearch = (event) => {
    const { value } = event.target;
    setIsSelect(false);
    setSearch(value);
    setReschdView({});
  };

  // handle search value change patient
  const onChangePatientSearch = (event) => {
    const { value } = event.target;
    setPatientIsSelect(false);
    setSearchPatient(value);
    setReschdView({});
  };

  //handle search Selection
  const handleSearchSelection=(item)=>{
    setSearchDataDetails(item)
    handleSearchSelect(item.value)
    if (item.type === 'doctor') {
      applySearchData({...searchData, selectedDoc: {
        label: item.label,
        value: item.id,
      }})
    }
  }
  // handle search name selection fron list
  const handleSearchSelect = (value) => {
    setSearch(value);
    setDocloader({});
    setSuggestion([]);
    setIsSelect(true);
  };

  // handle search name selection fron list patient
  const handleSearchPatientSelect = (value) => {
    setSearchPatient(value);
    setPatientSuggestion([]);
    setPatientIsSelect(true);
  };

  // handle delete success
  const handleDeleteSuccess = () => {
    updateShowConformPopup(false);
    if (appointments.length === 1 && currentPage > 1) {
      SetCurrentPage(currentPage - 1);
      fetchAppointments({ pageNo: currentPage - 1 });
    } else {
      fetchAppointments();
    }
  };

  // handle on delete confirm
  const deleteConfirm = () => {
    // updateLoading(true);
    deleteAppointment(
      deleteId,
      handleDeleteSuccess
      // , updateLoading
    );
  };

  // handle rescedule details view
  const handleRescheduleDetails = (event, id) => {
    event.stopPropagation();
    setReschdView({ [id]: !reschedView[id] });
  };

  const handleKeyPress = (e) => {
    if (e && e.key === 'Enter') {
      e.target.blur();
      if (search || selectedStatus || startDate || endDate) {
        handleApplyClick();
      }
    }
  };

  const handlepaymentInfo = (id) => {
    setOpenPaymentInfo(true);
    setPaymentAppt(id);
  };

  const handleClose = () => {
    setOpenPaymentInfo(false);
    setPaymentAppt();
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [search, selectedStatus, startDate, endDate]);

  return (
    <>
      {showConformPopup && (
        <ConformPopup
          onClose={() => updateShowConformPopup(false)}
          onConfirm={deleteConfirm}
          message={translate(
            "common.doYouWantToDeleteAppointment",
            "Are you sure you want to delete this appointment?"
          )}
          isBtnLoading={delApptLoading}
        />
      )}
      {showPopup && (
        <PopupWrapeer
          title={translate("appointments.editAppointment", "Edit Appointment")}
          onClose={onEditClose}
          onSubmit={handleEditAppointment}
          saveBtn={translate(
            "appointments.notifyAndSave",
            "NOTIFY PATIENT AND SAVE"
          )}
          isBtnLoading={editApptLoading}
          autoFocus={true}
        >
          <EditAppointments
            dataToEdit={dataToEdit}
            setDataToEdit={setDataToEdit}
            selectedDepartmentData={selectedDepartmentData}
            getDepartmentData={getDepartmentData}
            clearDeptData={clearDeptData}
            doctorSlotData={doctorSlotData}
            // getDoctorSlots={getDoctorSlots}
            error={error}
            clearSlots={clearSlots}
            feildError={feildError}
            setFeildError={setFeildError}
            setError={setError}
          />
        </PopupWrapeer>
      )}
      {showCancelPopup && (
        <CancelPopup
          onConfirm={() => handleCancel()}
          onClose={() => onHandleCancelClose()}
          isBtnLoading={cancelApptLoading}
        />
      )}
      {openPaymentInfo && (
        <PaymentInfoPopUp
          openPaymentInfo={openPaymentInfo}
          onClose={handleClose}
          paymentAppt={paymentAppt}
          fetchAppointments={fetchAppointments}
          paymentHandle={paymentHandle}
        />
      )}
     <div>
       {/* Added custom header panel as it needed different styling */}
       <div className={`appointment-filter admin-filter`}>
        {totalCount ? <div className='results-found'>{totalCount} result(s) found</div> : null}
        <div className="topFilter v2 align-right">
          <div className="filter-section v2" ref={divRef}>
            <button
              className={`filter-btn ${isApplied ? "applied" : ""}`}
              onClick={() => {setOpenFilter(!openFilter); if(openFilter) handleOutsideClose}}
            >
              <i className="bi bi-funnel"></i>
            </button>
            <div
              className={` ${
                openFilter
                  ? "open-filter listings-fiter-container"
                  : "listings-fiter-container"
              }`}
            >
              <div className="filter-body">
                <div className="row">
                  <div className="col-md-6 pe-md-0 mb-2 mb-md-3">
                    <div className="form-group mb-0">
                      <label htmlFor="">
                        {translate("appointments.patientName", "Patient Name")}
                      </label>
                      <div
                        className={`search-input ${
                          searchPatient !== "" &&
                          Object.values(patientloader).includes(true) &&
                          !isPatientSelect &&
                          "loading"
                        }`}
                      >
                        <input
                          type="search"
                          className="form-control down-arrow"
                          onChange={(event) => onChangePatientSearch(event)}
                          placeholder={intl.formatMessage({
                            id: "common.search",
                            defaultMessage: "Search",
                          })}
                          value={searchPatient}
                          maxLength="40"
                          autoComplete="off"
                          onBlur={() =>
                            setTimeout(() => setPatientSuggestion([]), 400)
                          }
                          onFocus={() => {
                            if (searchPatient)
                              loadOptionsPatient(searchPatient);
                          }}
                        />
                      </div>
                      {searchPatient !== "" &&
                      !isPatientSelect &&
                      patientSuggestion.length &&
                      !Object.values(patientloader).includes(true) ? (
                        <div className="customSearchList">
                          <ul style={{ marginBottom: 1 }}>
                            {patientSuggestion.map((item, index) => (
                              <li
                                key={index}
                                className={`suggestion-item ${item.isDisabled ? 'disabled' : ''}`}
                                onClick={() =>
                                 !item.isDisabled && handleSearchPatientSelect(item.value)
                                }
                              >
                                {item.label}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 mb-2 mb-md-3">
                    <div className="form-group mb-0">
                      <label htmlFor="">
                        {intl.formatMessage({
                          id: "appointments.doctDept",
                          defaultMessage: "Doctor / Department",
                        })}
                      </label>
                      <div
                        className={`search-input ${
                          search !== "" &&
                          !isSelect &&
                          Object.values(docloader).includes(true) &&
                          "loading"
                        }`}
                      >
                        <input
                          type="search"
                          className="form-control down-arrow text-capitalize"
                          onChange={(event) => onChangeSearch(event)}
                          placeholder={intl.formatMessage({
                            id: "common.search",
                            defaultMessage: "Search",
                          })}
                          value={search}
                          maxLength="40"
                          autoComplete="off"
                          onBlur={() =>
                            setTimeout(() => setSuggestion([]), 400)
                          }
                          onFocus={() => {
                            if (search) loadOptions(search);
                          }}
                        />
                      </div>
                      {!isSelect &&
                      search !== "" &&
                      suggestion.length &&
                      !Object.values(docloader).includes(true) ? (
                        <div className="customSearchList">
                          <ul style={{ marginBottom: 1 }}>
                            {suggestion.map((dept, index) => (
                              <li
                                key={index}
                                className={`suggestion-item ${dept.isDisabled ? 'disabled' : ''}`}
                                onClick={() => !dept.isDisabled && handleSearchSelection(dept)}
                              >
                                {dept.label}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="extra-filters"> */}
                  {/* <div> */}
                  <div className="col-md-6 pe-md-0 mb-2 mb-md-3">
                    <div className="form-group mb-0">
                      <label htmlFor="">Date</label>
                      <AntMultiDatePicker
                        start={startDate}
                        end={endDate}
                        format="DD/MM/YYYY"
                        sx={{
                          padding: "8px",
                        }}
                        onchangeDate={HandleChangeDate}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pe-2">
                    <div className="form-group mb-0 pe-1">
                      <label htmlFor="">
                        {translate("common.status", "Status")}
                      </label>
                      <ReactSelectDropDown
                        options={APPOINTMENT_STATUS_OPTIONS_ALL || []}
                        id="status"
                        // label={intl.formatMessage({
                        //   id: 'common.status',
                        //   defaultMessage: 'Status',
                        // })}
                        onInputChange={(item) => {
                          SetSelectedStatus(item);
                          setReschdView({});
                        }}
                        addValid_class={true}
                        required={true}
                        value={selectedStatus}
                        isCasePlaceholder={true}
                        isClearable
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-footer back-to-btn">
                <ul className="btnGroupright v2">
                  <li
                    onClick={handleReset}
                    id="onReset"
                    tabIndex="0"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                        handleReset();
                        e.target.blur();
                      }
                    }}
                  >
                    <button
                      tabIndex={"-1"}
                      type="button"
                      className="btn btn-outlined w-100"
                      id="reset"
                    >
                      {intl.formatMessage({
                        id: "reset.resetTooltip",
                        defaultMessage: "reset",
                      })}
                    </button>
                  </li>
                  <li onClick={handleApplyClick} id="onApply" tabIndex="0">
                    <button
                      tabIndex={"-1"}
                      type="button"
                      className="btn btn-secondary w-100"
                      id="apply"
                    >
                      {" "}
                      {translate("common.apply", "Apply")}{" "}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="right-add-btn"> */}
          <ul className="btnGrpupleft">
            <li id="onAdd" tabIndex="0">
              <div className="appointmentView">
                <ViewSwitch
                  active="list"
                  handleViewModeChange={handleViewModeChange}
                />
              </div>
            </li>
          </ul>
          {/* </div> */}
        </div>
      </div>
      <TableWrapper data={appointments}>
        <div
          className="list-user compact-table"
          style={{
            minHeight: isLoading ? "55vh" : "",
            overflow: !appointments?.length ? "hidden" : "",
          }}
        >
          {appointments && appointments.length && !isLoading ? (
            <table
              className="table departments-calendar appt-table"
              onClick={() => setReschdView({})}
            >
              <thead>
                <tr>
                  {APPOINTMENT_HEADERS.map((header, index) => (
                    <TableColumnHr
                      item={header}
                      key={index}
                      columnSort={columnSort}
                      setColumnSort={setColumnSort}
                      sortEnable={appointments.length > 1 ? true : false}
                    />
                  ))}
                </tr>
              </thead>
              <tbody>
                {appointments.map((appointment, index) => (
                  <tr key={index + 1}>
                    <td>{appointment.appointment_id}</td>
                    <td className='text-capitalize'>{appointment.patient_name}</td>
                    <td className='text-capitalize'>Dr. {removeDrPrefix(appointment.doctor_name)}</td>
                    <td>{`${dateinFormatDDMMMYYYY(
                      appointment.appt_date
                    )}  ${appendTime(appointment.appt_start_time)}`}</td>
                    <td>{appointment?.schedule_type?.toUpperCase()}</td>
                    <td
                      className="text-capitalize"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {appointment.dept_name.length > 17 ? (
                        <>
                          <Tooltip title={appointment.dept_name} placement="top">
                            <span style={{ cursor: "pointer" }}>
                              {appointment.dept_name}
                            </span>
                          </Tooltip>
                        </>
                      ) : (
                        <> {appointment.dept_name} </>
                      )}
                    </td>
                    <td className="relative">
                      <div className="d-flex gap-1">
                        <div className="status-badge text-nowrap d-flex">
                          <span
                            class={`badge-dot ${appointment.live_status}`}
                          ></span>
                          <div>
                            {APPOINTMENT_STATUS_NAMES[appointment.live_status]}{" "}
                            {appointment.rescheduled_appointments?.length &&
                            appointment.live_status ===
                              APPOINTMENT_STATUS.upcoming ? (
                              <Tooltip
                                title={
                                  APPOINTMENT_STATUS_NAMES[
                                    APPOINTMENT_STATUS.rescheduled
                                  ]
                                }
                                placement="top"
                              >
                                <span
                                  className="upcoming-span"
                                  onClick={(e) =>
                                    handleRescheduleDetails(
                                      e,
                                      appointment.appointment_id
                                    )
                                  }
                                >
                                  R
                                </span>
                              </Tooltip>
                            ) : null}
                            {appointment?.payment_details?.payment_status ===
                            PAID ? (
                              <Tooltip
                                title={translate(
                                  "appointments.paymentpaid",
                                  "Paid"
                                )}
                              >
                                <img
                                  className="table-tick"
                                  src={greenTick}
                                  alt=""
                                />
                              </Tooltip>
                            ) : null}
                          </div>
                        </div>
                        {reschedView[appointment.appointment_id] &&
                          appointment.rescheduled_appointments?.length && (
                            <div className="popover-container v2 bottom custom-tooltip-v2">
                              <div
                                className={`custom-popover show`}
                                // ref={ref}
                              >
                                <div className="popover-header">
                                  <h6>
                                    {/* {translate('doctors.feeHistory', 'Fee History')} */}
                                    Reschedule History
                                  </h6>
                                  <button
                                    className="btn-close"
                                    // onClick={handleHistoryClose}
                                  ></button>
                                </div>
                                <div className="name-and-count">
                                  <div className="name text-capitalize">
                                    Patient Name: {appointment.patient_name}
                                  </div>
                                  <span className="total-res">
                                    {translate("Total Reschedules")}:{" "}
                                    {appointment.rescheduled_appointments?.length}
                                  </span>
                                </div>
                                <div className="popover-body">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Doctor Name </th>
                                        <th>Department</th>
                                        <th>Scheduled From</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {appointment.rescheduled_appointments?.map(
                                        (item) => {
                                          return (
                                            <>
                                              <tr>
                                                {/* <div className="single-history"> */}
                                                <td>
                                                  <span>{item.doctor_name}</span>
                                                </td>
                                                <td>
                                                  <span className="text-capitalize">
                                                    {item.dept_name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <div>
                                                    {formatDateInDDMMYYYY(
                                                      item.appt_date
                                                    )}
                                                  </div>
                                                  <span className="time">
                                                    {appendTime(
                                                      item.appt_start_time
                                                    )}{" "}
                                                    -{" "}
                                                    {appendTime(
                                                      item.appt_end_time
                                                    )}
                                                  </span>
                                                </td>
                                                {/* </div> */}
                                              </tr>
                                            </>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="action-btns v2"
                      >
                        <>
                          <Tooltip
                            title={translate(
                              "common.paymentInfo",
                              "Payment Information"
                            )}
                            placement="top"
                          >
                            <span
                              className={`payment-icon ${
                                (appointment.live_status ===
                                  APPOINTMENT_STATUS.cancelled ||
                                  appointment.live_status ===
                                    APPOINTMENT_STATUS.notAttempted) &&
                                appointment?.payment_details?.payment_status !==
                                  "paid"
                                  ? "disabled"
                                  : ""
                              } `}
                              onClick={() =>
                                (appointment.live_status ===
                                  APPOINTMENT_STATUS.cancelled ||
                                  appointment.live_status ===
                                    APPOINTMENT_STATUS.notAttempted) &&
                                appointment?.payment_details?.payment_status !==
                                  "paid"
                                  ? {}
                                  : handlepaymentInfo(appointment)
                              }
                            >
                              {paymentIcon}
                            </span>
                          </Tooltip>
                        </>
                        {/* <img src={CoinIcon} /> */}
                        {permission(UPDATE) && (
                          <Tooltip
                            title={translate(
                              "appointments.editAppointment",
                              "Edit Appointment"
                            )}
                            placement="top"
                          >
                            {appointment.live_status === APPOINTMENT_STATUS.upcoming || appointment.live_status === APPOINTMENT_STATUS.waiting ? (
                              <span
                                className="edit-icon"
                                onClick={() => onEdit(appointment)}
                              >
                                {editIcon}
                              </span>
                            ) : (
                              <span className="edit-icon disabled">
                                {editIcon}
                              </span>
                            )}
                          </Tooltip>
                        )}
                        {appointment.live_status !==
                          APPOINTMENT_STATUS.completed &&
                        appointment.live_status !== APPOINTMENT_STATUS.upcoming &&
                        appointment.live_status !== APPOINTMENT_STATUS.waiting &&
                        appointment.live_status !==
                          APPOINTMENT_STATUS.inProgress &&
                        permission(DELETE) ? (
                          <Tooltip
                            title={translate(
                              "appointments.deleteAppointment",
                              "Delete Appointment"
                            )}
                            placement="top"
                          >
                            <span
                              className="delete-icon"
                              onClick={() => onDelete(appointment)}
                            >
                              {deleteIcon}
                            </span>
                          </Tooltip>
                        ) : (
                          <div className="cancel-icon">
                            {permission(STATUS_UPDATE) && (
                              <Tooltip
                                title={translate(
                                  "appointments.cancelAppointment",
                                  "Cancel Appointment"
                                )}
                                placement="left"
                              >
                                <span
                                  className={`cancel-icon ${
                                    appointment.live_status ===
                                    APPOINTMENT_STATUS.upcoming
                                      ? ""
                                      : "disabled"
                                  }`}
                                  onClick={() =>
                                    appointment.live_status ===
                                    APPOINTMENT_STATUS.upcoming
                                      ? onHandleCancelOpen(appointment)
                                      : {}
                                  }
                                >
                                  {blockIcon}
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        )}
                        <span className="data-wrapper">
                          <span
                            className="more-icon"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                            id={`appointmentView-${appointment.appointment_id}`}
                          >
                            <Tooltip
                              title={translate(
                                "patientView.apptDetails",
                                "Appointment Details"
                              )}
                              placement="left"
                            >
                              {moreIcon}
                            </Tooltip>
                          </span>
                          <ul
                            style={{cursor: 'default'}}
                            data-popper-placement="top-start"
                            className="dropdown-menu popupDoctorViewday custom-popover v2"
                            id={`appointmentViewToggle-${appointment.appointment_id}`}
                          >
                            <div className="popover-header mb-3">
                              <div className="header-id">
                                <h6>
                                  {translate(
                                    "patientView.apptDetails",
                                    "Appointment Details"
                                  )}
                                </h6>
                                <div className="d-flex gap-2 align-items-center">
                                  <span>
                                    {" "}
                                    <p className="id">
                                      {appointment?.appointment_id}
                                    </p>
                                  </span>
                                  <span
                                    className={`status ${
                                      status[appointment?.live_status]
                                    }`}
                                  >
                                    <div className="dot">•</div>
                                    {
                                      APPOINTMENT_STATUS_NAMES[
                                        appointment.live_status
                                      ]
                                    }
                                  </span>
                                </div>
                              </div>
                              <span className="d-flex align-items-center gap-1">
                                {/*  No permission for docor to edit appointment
                                <button className="btn-transparent">
                                  <i className="bi bi-pencil"></i>
                                </button> */}
                                <button
                                  className="btn-transparent"
                                  onClick={() =>
                                    onClose(appointment.appointment_id)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-x-lg"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                  </svg>
                                </button>
                              </span>
                            </div>
                            <div className="popover-body">
                              <div className="icon-block mb-3">
                                <i className="bi bi-person"></i>
                                <div>
                                  <span>
                                    {translate(
                                      "appointments.patientName",
                                      "Patient Name"
                                    )}
                                  </span>
                                  <p className="mb-0 mt-2">
                                    {appointment.patient_name}
                                  </p>
                                </div>
                              </div>
                              {appointment.patient_email && (
                                <div className="icon-block mb-3 me-4 bordred-top-bottom">
                                  <img src={envelope} alt="" />
                                  <div className="mt-1">
                                    <span>
                                      {translate(
                                        "common.emailId",
                                        "Email Address"
                                      )}
                                    </span>
                                    <p className="mb-0 mt-2 mail-text">
                                      {appointment.patient_email}
                                    </p>
                                  </div>
                                </div>
                              )}
                              <div className="items-set">
                                <div className="icon-block">
                                  <i className="bi bi-clock"></i>
                                  <div>
                                    <span>Date & Time</span>
                                    <p className="mb-0 mt-2">
                                      {formatDateto_D_WD_M_Y(
                                        new Date(appointment.appt_date)
                                        // language_Code
                                      )}
                                    </p>
                                    <p className="mb-0 mt-2">
                                      {appendTime(appointment.appt_start_time)} -{" "}
                                      {appendTime(appointment.appt_end_time)}
                                    </p>
                                  </div>
                                </div>
                                {appointment?.patient_tele_country_code && (
                                  <div className="icon-block mb-3">
                                    <img src={telephone} alt="" />
                                    <div className="mt-1">
                                      <span>
                                        {translate("users.phone", "Phone Number")}
                                      </span>
                                      <p className="mb-0 mt-2">
                                        +{appointment?.patient_tele_country_code}
                                        {appointment.patient_phone}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="popover-footer">
                              <div className="icon-block">
                                <i className="bi bi-justify-left"></i>
                                <div>
                                  <span>
                                    {translate(
                                      "patientView.reason",
                                      "Reason For Appointment"
                                    )}
                                  </span>
                                  <p className="mb-0 mt-2 first-capital">
                                    {appointment.reason}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </ul>
                          <div className="overlay"></div>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : !isLoading ? (
              <div className="patient-portal">
                <NoResultFound
                  text={isApplied ? translate('common.noResult', 'No results found.') :
                    translate("appointments.noDataAppointments", "No appointments added")}
                  label={isApplied ? translate('common.noResultMsg', 'No results match the filter criteria Remove filter or clear all filters to show results') :
                    translate('appointments.noDataAppointmentsMsg', `Looks like you haven't added any appointments yet.`)}
                  addNew={
                    permission(CREATE) && !isApplied ? handleAddButton : null
                  }
                  removeMarginBottom
                  removeMarginTop
                />
              </div>
          ) : (
            <Loader />
          )}

        </div>
      </TableWrapper>
      {!isLoading && appointments && appointments.length ? (
        <Pagination
          SetCurrentPage={handleCurrentPageChange}
          SetPerPage={handlePerPageChange}
          perPage={parseInt(perPage)}
          totalCount={totalCount}
          currentPage={currentPage}
        />
      ) : null}
     </div>
      {permission(CREATE) && (
        <button
          className="appointmentsList add-btn v2"
          onClick={() => handleAddButton()}
        > 
          {/* <img src={addbtn} alt="" /> */}
          {AddIcon}
        </button>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  appointmentList: state.AppointmentReducer.appointmentList,
  doctorSlotData: state.AppointmentReducer.doctorSlotData,
  selectedDepartmentData: state.AppointmentReducer.selectedDepartmentData,
  searchData: state.AppointmentReducer.searchData,
  currentPage: state.AppointmentReducer.paginationData.currentPage,
  perPage: state.AppointmentReducer.paginationData.perPage,
  columnSort: state.AppointmentReducer.columnSort,
  isBtnLoading: state.AppointmentReducer.isBtnLoading,
  userType:state.LoginReducer.user_type,
  // isSelect:state.AppointmentReducer.isSelect,
  // searchName:state.AppointmentReducer.searchName,
  // selectedDate:state.AppointmentReducer.selectedDate,
  // selectedStatus:state.AppointmentReducer.selectedStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getAppointments: (data, setLoader) =>
    dispatch(FetchAppointmentList(data, setLoader)),
  getDepartmentData: (id, config) => dispatch(FetchDepartmentData(id, config)),
  clearDeptData: () => dispatch(clearDepartmentData()),
  clearData: () => dispatch(clearAppointmentReducer()),
  clearDataExceptFilter: () => dispatch(clearAppointmentExceptFilter()),
  // getDoctorSlots: (id, config) => dispatch(FetchDoctorSlots(id, config)),
  editAppointment: (id, data, fetchAppointments, handleEditClose) =>
    dispatch(UpdateAppointment(id, data, fetchAppointments, handleEditClose)),
  deleteAppointment: (id, handleDeleteSuccess, updateLoading) =>
    dispatch(DeleteAppointment(id, handleDeleteSuccess, updateLoading)),
  clearSlots: () => dispatch(clearDoctorSlots()),
  clearSearchDetails: () => dispatch(clearSearchDoctorDetails()),
  onCancelAppointment: (id, data, fetchAppointments, onHandleCancelClose) =>
    dispatch(
      CancelAppointment(id, data, fetchAppointments, onHandleCancelClose)
    ),
  applySearchData: (data) => dispatch(updateSearchData(data)),
  SetCurrentPage: (data) => dispatch(updateCurrentPage(data)),
  SetPerPage: (data) => dispatch(updatePerPage(data)),
  setColumnSort: (data) => dispatch(updateColumnSort(data)),
  paymentHandle: (id, data, fetchAppointments) =>
    dispatch(paymentHandle(id, data, fetchAppointments)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissionCheck(APPOINTMENT)
)(Appointments);
