import React, { Component } from 'react';
import Error404 from './Assets/img/icons/404.png';
import { translate } from './Language/Translate';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.error('My Error', error, errorInfo);
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="page-not-found" style={{ textAlign: 'center' }}>
          <img src={Error404} alt="404" />
          <h2>{translate('common.somethingWrong', 'Something Went Wrong')}</h2>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.elementType,
};

export default ErrorBoundary;
