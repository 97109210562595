import network from '../Utils/Api/Network';
import { showToaster, ToasterTypes } from './toasterAction';

export const fetchClientDetails = (
  data,
  handleResponse = () => {},
  hanldeInvalid = () => {}
) => {
  return (dispatch) => {
    return network
      .post({ url: '/client-account/token-details', data })
      .then((response) => {
        const { isError, message, result } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
          hanldeInvalid();
        } else {
          handleResponse(result);
        }
      });
  };
};

export const clientActiavte = (data, handleResponse = () => {}) => {
  return (dispatch) => {
    return network
      .post({ url: '/client-account/activate', data })
      .then((response) => {
        const { isError, message, result } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          handleResponse(result);
        }
      });
  };
};
