import React, { useState } from "react";
import ReactSelectDropDown from "Components/Common/ReactSelectDropDown";
import { appendTime, formatDate } from "../../../Utils/DateFormatter/Date";
import { DoctorsApi } from "Utils/Api/DoctorsApi";
import useDidMountEffect from "Hooks/useDidMountEffect";
import SlotBooking from "./SlotBooking";
import { AppointmentApi } from "Utils/Api/AppointmentApi";

const AppointmentItem = ({
  item,
  deptList,
  deptLoading,
  handleSelect,
  selectedAppt,
  setUpdate,
  getAllDateAppointments,
  availableDocs,
}) => {
  const [selectedDoc, setSelectedDoc] = useState();
  const [selectedDept, setSelectedDept] = useState();
  const [docLoading, setDocLoading] = useState(false);
  const [docList, setDocList] = useState([]);
  const [availableDatesLists, setAvailableDatesLists] = useState([]);
  const [defaultDate, setDefaultDate] = useState();

  useDidMountEffect(() => {
    getAllDocList();
  }, [selectedDept]);

  // useEffect(() => {
  //     setDocLoading(docLoadingAll)
  //     setDocList(docListAll)
  // }, [docListAll])

  const onInputChange = (e, type) => {
    if (type === "dept") {
      setSelectedDept(e);
      setSelectedDoc("");
    } else {
      setSelectedDoc(e);
      getAvailableSlots(e.value);
    }
  };

  const getAvailableSlots = (value) => {
    const config = {
      doctor_id: value,
      start_date: "",
      end_date: "",
    };
    AppointmentApi.getAvailableApptDates(config).then(({ error, response }) => {
      if (error) {
        return;
      }
      if (response) {
        const availableDates = response.slot_info.availabel_date;
        const formattedDatesArray = Object.keys(availableDates).map(
          (dateString) => {
            const dateObject = new Date(dateString);
            return formatDate(dateObject);
          }
        );
        setAvailableDatesLists(formattedDatesArray);
        getDefaultDate(formattedDatesArray[0]);
      }
    });
  };

  const getDefaultDate = (val) => {
    let formatdate = new Date(val);
    let formaattedDate = formatDate(formatdate);
    setDefaultDate(formaattedDate);
  };

  const getAllDocList = () => {
    setDocLoading(true);
    const config = {
      limit: 0,
      status: "active",
      dept_id: selectedDept?.value,
      book_appointment: true,
      sort: 'name'
    };
    DoctorsApi.getDoctorsList(config).then((res) => {
      setDocLoading(false);
      if (res.error) {
        return;
      }
      if (res.response) {
        const listedDocs = res.response?.doctors?.filter((doc) =>
          availableDocs.some(
            (availableDoc) => availableDoc.doctor_id === doc.doctor_id
          )
        );
        setDocList(listedDocs);
      } else {
        setDocList([]);
      }
    });
  };

  const isSelected = (id) => {
    if (selectedAppt.indexOf(id) === -1) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div
      key={item.appointment_id}
      className={`${
        isSelected(item.appointment_id) ? "appointment-selected" : ""
      } single-row`}
    >
      <div className="d-flex">
        <div className="checkbox-container v2">
          <input
            type="checkbox"
            onClick={(e) => handleSelect(e, item.appointment_id)}
            checked={isSelected(item.appointment_id)}
            className="form-check-input"
            id="selectRow"
          />
        </div>
        <div
          className={`row pt-0 ${
            selectedDoc ? "single-row border-bottom" : ""
          }`}
        >
          <div className="col-3 ps-0">
            <p className="time">
              {" "}
              {`${appendTime(item.appt_start_time)} - ${appendTime(
                item.appt_end_time
              )}`}
            </p>
          </div>
          <div className="col-3">
            <span className="name d-block">{item.patient_name}</span>
            <div className="phno">
              {item?.patient_phone
                ? `+${item.patient_tele_country_code} ${item.patient_phone}`
                : item?.parent_details
                ? `+${item?.parent_details?.tele_country_code} ${item?.parent_details?.phone}`
                : "-"}
            </div>
          </div>
          <div className="col-3">
            <ReactSelectDropDown
              // label={translate('appointments.leaveDateConflict', 'Leave Date(s) Conflicted')}
              options={
                deptList?.map((item) => ({
                  label: item.name,
                  value: item.dept_id,
                })) || []
              }
              onInputChange={(e) => onInputChange(e, "dept")}
              value={selectedDept}
              isLoading={deptLoading}
              emptyClass
              label="Select Department"
            />
          </div>
          <div className="col-3">
            <ReactSelectDropDown
              // label={translate('appointments.leaveDateConflict', 'Leave Date(s) Conflicted')}
              options={
                docList?.map((item) => ({
                  label: `Dr. ${item.name} ${item?.last_name}`,
                  value: item.doctor_id,
                })) || []
              }
              onInputChange={(e) => onInputChange(e, "doc")}
              value={selectedDoc || ""}
              isLoading={docLoading}
              emptyClass
              label="Select Doctor"
              isDisabled={!selectedDept}
            />
          </div>
        </div>
      </div>
      {selectedDoc && (
        <SlotBooking
          selectedDoc={selectedDoc}
          selectedDept={selectedDept}
          item={item}
          setUpdate={setUpdate}
          getAllDateAppointments={getAllDateAppointments}
          availableDatesLists={availableDatesLists}
          defaultDate={defaultDate}
        />
      )}
    </div>
  );
};

export default AppointmentItem;
