// import { translate } from 'Language/Translate'
import React from 'react';
import dangericon from '../../../Assets/img/icons/danger-icon.svg'
import { useHistory } from 'react-router-dom';

const Maxappointment = (props) => {
    const history = useHistory()
    const { openModal, handleClose } = props;
    const contactus = () => {
      handleClose(false);
      window.location.href = "/#/webConnect/contact-us";
    };
    const handleCloseLimit = () => {
      handleClose(false);
      history.push("/webConnect/appointments");

    };
    return (
        <>
            <div
                className={`modal custom-modal fade modal-dialog-centered confirmation-modal ${openModal ? 'show' : ''
                    }`}
                id="rescheduleConfirmModal"
                // style={{ display: displayMode ? 'flex' : 'none' }}
                tabIndex="-1"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <button
                                type="button"
                                className="btn-close"
                                onClick={handleCloseLimit}
                            ></button>
                        </div>
                        <div className="modal-body text-center">
                        <img className="mb-3 d-block mx-auto" src={dangericon} />
                            <h5 className="fw-600">
                               Warning!
                            </h5>
                            <p className="text-black">
                               {openModal}
                            </p>
                            <div className="confirmation-btns">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={contactus}
                                    onKeyDown={(e)=>{
                                        if(e.key === "Tab") e.preventDefault()
                                    }}
                                >
                                   Contact US
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={` ${openModal && 'offcanvas-backdrop fade show'}`}
            ></div>
        </>
    )
}

export default Maxappointment