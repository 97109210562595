/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CardMedia } from '@mui/material';
import svgIc from 'Assets/img/icons/placeholder-avatar.svg'
import { translate } from '../../Language/Translate';
import {
  providerLogout,
  receiveUserDetails,
  updateUserType,
} from '../../Actions/loginAction';
import {
  clearLocalStorage,
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../Utils/LocalStorage/local-storage';
import { updateToken, updateRole } from '../../Utils/Api/Network';
import './Header.css';
import { revertFromBase64 } from '../../Utils/Services/Authenticator';
import { USER_TYPE, USER_TYPE_NAME } from '../../Constants/userTypes';
import { clearAppointmentReducer } from '../../Actions/appointmentAction';
import { cleraAllData } from '../../Actions/departmentAction';
import { clearAllLeaveData } from '../../Actions/LeaveActions';
import { clearAllUserData } from '../../Actions/usersAction';
import { clearSearchData } from '../../Actions/doctorListingAction';
import ProfilePopup from './ProfilePopup';
import { DOCTOR } from 'Constants/feature';
import { MODIFY_LEAVE } from 'Constants/scopes';
import {
  getTopPriorityRole,
  sortRolesByPriority,
} from 'Utils/Permissions/Permissions';
import keycloak from 'Utils/keyCloak/keyCloak';
import { PORTAL_TYPES } from 'Constants/constant';

const toggleIcon = <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="35" height="35" rx="6" fill="#F3F6F7"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23 22.1921C23 22.6661 22.6059 23.0503 22.1198 23.0503H15.349C14.8628 23.0503 14.4687 22.6661 14.4687 22.1921C14.4687 21.7181 14.8628 21.3339 15.349 21.3339H22.1198C22.6059 21.3339 23 21.7181 23 22.1921ZM23 17.0427C23 17.5167 22.6059 17.901 22.1198 17.901H10.8802C10.3941 17.901 10 17.5167 10 17.0427C10 16.5687 10.3941 16.1845 10.8802 16.1845H22.1198C22.6059 16.1845 23 16.5687 23 17.0427ZM23 11.8934C23 12.3674 22.6059 12.7516 22.1198 12.7516H16.5C16.0139 12.7516 15.6198 12.3674 15.6198 11.8934C15.6198 11.4194 16.0139 11.0352 16.5 11.0352H22.1198C22.6059 11.0352 23 11.4194 23 11.8934Z" fill="#2E2D2D"/>
</svg>



const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = getLocalStorageItem('access_token');
  const [selectedRole, setSelectedRole] = useState('');

  const userType = useSelector((state) => state.LoginReducer.user_type);
  const userDetails = useSelector((state) => state.LoginReducer.loginDetails);
  const permissions = useSelector(
    (state) => state.LoginReducer.userPermissions.ResourceNameScope
  );
  const userPermissions = useSelector(
    (state) => state.LoginReducer.userPermissions
  );
  const { roles } = userPermissions || {};
  const { name, last_name, profile_img, email, phone, tele_country_code } =
    userDetails;

  const profileDropdownRef = useRef(null);
  const [showProfileDropdown, updateProfileDropdown] = useState(false);
  const [showProfilelPopup, setShowProfilePopup] = useState(false);
  useEffect(() => {
    if (!userDetails?.user_id && token) {
      const userDataBase64 = getLocalStorageItem('user');
      const userData = revertFromBase64(userDataBase64);
      if (userData) {
        dispatch(receiveUserDetails(userData));
      }
    }
  }, []);

  useEffect(() => {
    const { roles } = userPermissions || {};
    const userType = getLocalStorageItem('user_type')
    if (roles && roles.includes(userType)) {
      setSelectedRole(userType);
    }
    else if (roles) {
      setSelectedRole(getTopPriorityRole(roles));
    }
  }, [userPermissions]);

  useEffect(() => {
    if (!userDetails?.user_id && !token) {
      history.push('/admin/auth/login');
      updateToken('');
    }
  }, [userDetails, token, history]);

  useEffect(() => {
    const listener = (event) => {
      if (
        !profileDropdownRef.current ||
        profileDropdownRef.current.contains(event.target) ||
        event.target.id === 'userProfileImg'
      ) {
        return;
      }
      if (event.button === 0) updateProfileDropdown(false);
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [profileDropdownRef]);

  let pathName = window.location.hash.split('/')[2]?.split('?')[0];
  let subRoutePathName = window.location.hash.split('/')[3]?.split('?')[0];

  const checkpermission = (feature, scope) => {
    const permission = permissions?.[feature];
    if (!permission || !permission?.length) {
      return false;
    } else {
      return permission.includes(scope);
    }
  };

  const setPageTitle = (value) => {
    switch (value) {
      case 'appointments':
        return translate('appointments.title', 'Appointments');
      case 'doctorslisting':
        return translate('doctors.title', 'Doctors');
      case 'leave-approval':
        return checkpermission(DOCTOR, MODIFY_LEAVE) &&
          userType === USER_TYPE.doctor
          ? translate('leaveApproval.title1', 'My Leave')
          : translate('leaveApproval.title2', 'Leaves');
      case 'doctordetails':
        return userType === USER_TYPE.doctor
          ? translate('doctors.myProfile', 'My Profile')
          : translate('doctors.doctorDetails', 'Doctor Details');

      case 'adddoctor':
        return translate('doctors.addDoctor', 'Add Doctor');
      case 'editdoctor':
        return translate('doctors.editDoctor', 'Edit Doctor');
      case 'departments':
        return translate('departments.title', 'Departments');
      case 'patients':
        return translate('patients.title', 'Patients');
      case 'users':
        return translate('users.title', 'Users');
      case 'settings':
        return translate('settings.profileSettings', 'Profile Settings');
      case 'systemSettings':
        return translate('settings.systemSettings', 'System Settings');
      case 'subscriptionSettings':
        return translate(
          'settings.subscriptionSettings',
          'Manage Subscription'
        );
      case 'override':
        return 'Override';
      case 'patientprofile':
        return 'Patient Details';
      case 'addAppointment':
        return translate('appointments.addAppointment', 'Add Appointment');
      case 'slot-management':
        return translate('doctors.slots', 'Slots');
      case 'reschedule': 
        return translate('appointments.rescheduleAppts', 'Reschedule Appointments')
      default:
        return translate('appointments.title', 'Appointments');
    }
  };
  const clearFilters = () => {
    dispatch(clearAppointmentReducer());
    dispatch(cleraAllData());
    dispatch(clearAllLeaveData());
    dispatch(clearAllUserData());
    dispatch(clearSearchData());
  };
  // handle logout action
  const handleLogout = () => {
    // clear user deatils from the localstorage
    clearLocalStorage();
    setLocalStorageItem('portalType', PORTAL_TYPES.provider);
    // clear user deatils from redux
    dispatch(providerLogout());
    clearFilters();
    // logout from keycloak
    keycloak.logout({
      redirectUri: window.location.origin + '/#/admin/auth/login',
    });
  };
  const onHandleClose = () => {
    setShowProfilePopup(false);
  };
  const onHandleOpenPopUp = () => {
    setShowProfilePopup(true);
    updateProfileDropdown(false);
  };

  const handleRoleChange = (value) => {
    setLocalStorageItem('user_type',value)
    updateProfileDropdown(false);
    dispatch(updateUserType(value));
    updateRole(value);
    setSelectedRole(value);
    history.push('/admin/appointments');
  };

  const handleProfileSettings = () => { 
    history.push('/admin/settings');
    updateProfileDropdown(false);
  }

  const noPatientRole = roles?.filter((role) => role !== USER_TYPE.patient);

  return (
    <>
      {showProfilelPopup && (
        <ProfilePopup
          onClose={() => onHandleClose()}
          profile_img={profile_img}
        />
      )}
      <div className="top-bar-header wrap">
        <div className='toggle-heading-wrap'>
        <div
          className="admin-menu-toggle fit-content"
          onClick={() => props.setShowToggle(!props.showToggle)}
        >
          <span className='toggle-img'>
            {toggleIcon}
          </span>
        </div>
        <h1>{setPageTitle(subRoutePathName || pathName)}</h1>
        </div>
      </div>
      <ul className="wraper ">
        <li>
          <a
            className="user-thumb"
            onClick={() => updateProfileDropdown(!showProfileDropdown)}
          >
               <div className="profile-pic-wrap">
                 <div className='name-and-role'>
                   <h4 className="user-name">{`${name} ${last_name}`}</h4>
                   <p className='role'>{USER_TYPE_NAME[selectedRole]}</p>
                </div>
            {profile_img ? (
              <CardMedia
                style={{
                  borderRadius: '50%',
                }}
                className="user-thumb"
                component="img"
                image={profile_img}
                id="userProfileImg"
              />
            ) : (
              <img
                style={{
                  borderRadius: '50%',
                }}
                src={svgIc}
              />
            )}
            </div>
          </a>
          {showProfileDropdown && (
            <div className="logoutWrap profile-dr" ref={profileDropdownRef}>
              <div className="profileArea" style={{ fontSize: 12 }}>
               <div className="d-flex align-items-center gap-3">
               {profile_img ? (
                  <div
                    className="user-img-container"
                    onClick={() => onHandleOpenPopUp()}
                  >
                    <i className="bi bi-upload"></i>
                    <CardMedia
                      className="user-img"
                      style={{
                        borderRadius: '50%',
                      }}
                      component="img"
                      image={profile_img}
                    />
                  </div>
                ) : (
                  <div
                    className="user-img-container"
                    onClick={() => onHandleOpenPopUp()}
                  >
                    <i className="bi bi-upload"></i>
                    <img className="user-img" src={svgIc} alt="profile" />
                  </div>
                )}
                <div>
                <h4 className="user-name">{`${name} ${last_name}`}</h4>
                <div
                  className={`role-dr ${
                    noPatientRole?.length > 1 ? 'form-select' : ''
                  } >`}
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    backgroundColor: ' #b7e5da',
                    borderRadius: 15,
                    padding: '2px 24px',
                    marginTop: 5,
                    cursor: noPatientRole?.length === 1 ? 'default' : 'pointer',
                  }}
                >
                  {USER_TYPE_NAME[selectedRole]}
                </div>
                {noPatientRole?.length > 1 && (
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {sortRolesByPriority(noPatientRole).map((role, index) => (
                      <li
                        key={index + 1}
                        onClick={() => handleRoleChange(role)}
                      >
                        <a className="dropdown-item">{USER_TYPE_NAME[role]}</a>
                      </li>
                    ))}
                  </ul>
                )}
                </div>
               </div>
              </div>
              <div className="bordered-element">
             <div className="icon-text">
                  {phone && <i className="bi bi-telephone"></i>}
                  {phone &&
                    `${tele_country_code && '+' + tele_country_code} ${phone}`}
                </div>
                <div className="icon-text mail">
                  {email && <i className="bi bi-envelope"></i>}
                  {email}
                </div>
             </div>
             <div className="dr-footer">
             <div className="contentArea">
                 <button className='btn btn-light' onClick={handleProfileSettings}>
                 <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.28198 9C6.07284 9 4.28198 7.20914 4.28198 5C4.28198 2.79086 6.07284 1 8.28198 1C10.4911 1 12.282 2.79086 12.282 5C12.282 7.20914 10.4911 9 8.28198 9Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.218 14.824C11.106 14.936 11.002 15.144 10.978 15.296L10.826 16.376C10.77 16.768 11.042 17.04 11.434 16.984L12.514 16.832C12.666 16.808 12.882 16.704 12.986 16.592L15.818 13.76C16.306 13.272 16.538 12.704 15.818 11.984C15.106 11.272 14.538 11.504 14.05 11.992L11.218 14.824Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.642 12.4C13.882 13.264 14.554 13.936 15.418 14.176" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1.40991 17C1.40991 13.904 4.48993 11.4 8.28193 11.4C9.11393 11.4 9.91391 11.52 10.6579 11.744" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                 </svg>
                  {translate('settings.profileSettings', 'Profile Settings')}
                </button>
                 </div>
              <div className="contentArea">
                <button className="btn btn-light" onClick={handleLogout}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.4399 11.62L16.9999 9.06L14.4399 6.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.76001 9.06006H16.93" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.76001 17C4.34001 17 0.76001 14 0.76001 9C0.76001 4 4.34001 1 8.76001 1" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                  {translate('common.signOut', 'Sign Out')}
                </button>
              </div>
             </div>
            </div>
          )}
        </li>
        {/* <li className="notification ">
          <a href=" ">
            <img src={notificationIcon} alt="notification " />
            <span className="count noty-count ">10</span>
          </a>
        </li> */}
      </ul>
    </>
  );
};
export default Header;
