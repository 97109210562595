import React from 'react'
import { PAGINATION_PER_PAGE } from '../../../Constants/constant';
import { translate } from '../../../Language/Translate';
import Pagination from 'rc-pagination';
import './customPaginator.css';
import './MedicalRecords.css'


function Paginator(props) {
    const { SetCurrentPage, SetPerPage, perPage, currentPage, totalCount } =props;
    const onShowSizeChange = (e) => {
        SetPerPage(parseInt(e.target.value));
      };
      const onChange = (current) => {
        SetCurrentPage(current);
      };

      const getMaxItem=() =>{
      const startingIndex=getStartingItem()
        // Calculate the maximum valid index
        const maxValidIndex = startingIndex + perPage - 1;
      
        // Ensure it doesn't exceed the total number of items
        const maxIndex = Math.min(maxValidIndex, totalCount );
      
        return maxIndex;
      }
      const getStartingItem = () => {
        return (currentPage - 1) * perPage + 1
      }
      
    return (
        <>
        <div className="paginator d-flex">
            <div className="no-of-items-selector">
                <span>{translate('medicalRecords.itemPerPage', 'Items per page:')}</span>
                <select name="" id="items" className="form-select selector"
                 onChange={(e) => onShowSizeChange(e)} >
                {PAGINATION_PER_PAGE.map((limits, index) => {
              return (
                <option
                  key={index + 1}
                  selected={perPage === limits ? 'selected' : ''}
                >
                  {limits}
                </option>
              );
            })}
                </select>
                <span className="item-indicator"> { getStartingItem()} – {getMaxItem()} {translate('common.of','of')} {totalCount}</span>
            </div>
            <div className="patient_recordpaginator">
            <Pagination 
            defaultPageSize={10}
            defaultCurrent={1}
            pageSize={perPage}
            current={currentPage}
            onChange={onChange}
            total={totalCount}
            locale="en-US"
        />
            </div>
            {/* <div className="navigatior">
                <button className="left-arrow arrow" disabled>
                    <i className="bi bi-chevron-left"></i>
                </button>
                <span className="selected indicator">1</span>
                <span className="indicator">2</span>
                <span className="indicator">3</span>
                <span className="hidden-count">...</span>
                <span className="indicator">8</span>
                <span className="indicator">9</span>
                <span className="indicator">10</span>
                <button className="right-arrow arrow">
                    <i className="bi bi-chevron-right"></i>
                </button>
            </div> */}
        </div>
        </>
    )
}

export default Paginator