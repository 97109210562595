import React, { useEffect, useRef, useState } from 'react';
import PhoneNumberInput from '../../Common/PhoneInput';
import { translate } from 'Language/Translate';
import './profileinformation.css';
import edtiIcon from 'Assets/img/icons/edit.svg';
import AntDatepicker from 'Components/Common/AntdDatePicker';
import { formatDate, slashFormatinDDMMYYYY, timeZoneDate } from 'Utils/DateFormatter/Date';
import verifiedIcon from 'Assets/img/icons/verified.svg';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import { BLOOD_GROUP_OPTIONS, PATIENT_LOGIN_TITLES } from 'Constants/constant';
import { validateEmail, validateMobile } from 'Utils/Validation/validator';
import OtpVerification from './OtpVerification';
import Modal from '../Common/Modal';
import { connect } from 'react-redux';
import {
  updateProfileDetails,
  validatePhoneMail,
} from 'Actions/settingsAction';
import moment from 'moment';
import { showToaster, ToasterTypes } from 'Actions/toasterAction';
import ButtonLoader from 'Components/Common/ButtonLoader';
import { useIntl } from 'react-intl';
import { setViewMode } from 'Actions/patientProfileAction';
import { useHistory } from 'react-router-dom';
 
function ProfileInformation(props) {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const numField = useRef();
  const focusRef = useRef();
  const {
    editmode,
    setEditmode,
    profileDetails: profileDetail,
    profileImg,
    editProfileDetails,
    isUpdateProfileImg,
    validateData,
    displaytoaster,
    applicationData,
    setIsUpdateProfileImg,
    viewMode,
    setViewMode
  } = props;
  const { patient_details: patientDetails, user_details: userDetails } =
    profileDetail;

  const [profileDetails, setProfileDetails] = useState({});
  const [invalidAlert, setInvalidAlert] = useState({});
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [resendLoader, setResendLoader] = useState(false);
  // otp data
  const [showVerify, setShowVerify] = useState(false);
  const [validateType, setValidateType] = useState('');
  const [otp_token, setOtpToken] = useState({
    token: '',
    exp: '',
    len: '',
  });

  const [previousData, setPreviousData] = useState({
    phone: '',
    countryCode: '',
    email: '',
  });

  useEffect(() => {
    setProfileDetails({
      fName: userDetails?.name,
      lName: userDetails?.last_name,
      phone: userDetails?.phone,
      dob: userDetails?.dob,
      countryCode: userDetails?.tele_country_code,
      gender: userDetails?.gender,
      email: userDetails?.email,
      bloodGrp: {
        label: patientDetails?.blood_group,
        value: patientDetails?.blood_group,
      },
      mrn: patientDetails?.mrn,
    });
    setPhoneVerified(userDetails?.phone_verified);
    setEmailVerified(userDetails?.email_verified);
    setPreviousData({
      phone: userDetails?.phone,
      countryCode: userDetails?.tele_country_code,
      email: userDetails?.email,
    });
  }, [profileDetail]);

  const {
    gender,
    dob,
    fName,
    lName,
    mrn,
    phone,
    email,
    countryCode,
    bloodGrp,
  } = profileDetails;
  const [numberLength, setNumberLength] = useState(10);
  const [code,setCode]=useState('IN')
  const genderHandle = (value) => {
    setInvalidAlert((prev) => ({
      ...prev,
      gender: false,
    }));
    setProfileDetails((state) => ({
      ...state,
      gender: value,
    }));
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const regex = /^\+?[0-9 ]*\.?[0-9 ]*$/;
    if (name === 'phone') {
      if (regex.test(value)) {
        setInvalidAlert((prev) => ({
          ...prev,
          [name]: false,
        }));
        setProfileDetails((state) => ({
          ...state,
          [name]: value,
        }));
      }
    }
    else {
      setInvalidAlert((prev) => ({
        ...prev,
        [name]: false,
      }));
      setProfileDetails((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  const handleCountryCode = (e) => {
    if (e.length <= 4) {
      setProfileDetails((state) => ({
        ...state,
        countryCode: e,
      }));
      setInvalidAlert((prev) => ({
        ...prev,
        phone: false,
      }));
    }
  };
  // handle dob change
  const handleDobChange = (date) => {
    setInvalidAlert((prev) => ({
      ...prev,
      dob: false,
    }));
    setProfileDetails((state) => ({
      ...state,
      dob: date,
    }));
  };

  const bloodHandle = (value) => {
    setInvalidAlert((prev) => ({
      ...prev,
      bloodGrp: false,
    }));
    setProfileDetails((state) => ({
      ...state,
      bloodGrp: value,
    }));
  };

  const validateFields = () => {
    let errObj = {};
    if (!fName.trim().length || !phone || !dob || !gender) {
      errObj = {
        fName: !fName.trim().length
          ? translate('common.required', 'This field is required')
          : false,
        phone: !phone
          ? translate('common.required', 'This field is required')
          : false,
        dob: !dob
          ? translate('common.required', 'This field is required')
          : false,
        gender: !gender
          ? translate('common.required', 'This field is required')
          : false,
        bloodGrp: !bloodGrp
          ? translate('common.required', 'This field is required')
          : false,
      };
    }
    if (
      phone &&
      (previousData.phone !== phone || previousData.countryCode !== countryCode)
    ) {
      if(!validateMobile(phone,code)){
        errObj = {
          ...errObj,
          phone: translate(
            'common.invalidPhone',
            'Invalid mobile number.'
          ),
        };
      } else if (!isVerified('phone')) {
        errObj = {
          ...errObj,
          phone: translate(
            'settings.verifyPhone',
            'Please verify the phone number'
          ),
        };
      }
    }
    if ((previousData.email || email) && previousData.email !== email) {
      if (previousData.email && !email) {
        errObj = {
          ...errObj,
          email: translate('common.required', 'This field is required'),
        };
      } else if (!validateEmail(email)) {
        errObj = {
          ...errObj,
          email: translate(
            'settings.validEmail',
            'Please enter a valid email id.'
          ),
        };
      } else if (!isVerified('email')) {
        errObj = {
          ...errObj,
          email: translate('settings.verifyMail', 'Please verify the Email'),
        };
      }
    }
    setInvalidAlert(errObj);
    return Object.values(errObj).some((item) => item);
  };

  const handleProfileSave = () => {
    if (!validateFields()) {
      setLoading(true);
      const config = {
        data: {
          name: fName,
          last_name: lName,
          blood_group: bloodGrp?.value,
          dob: formatDate(dob),
          gender: gender,
          profile_image_update: isUpdateProfileImg,
          profile_img: profileImg,
          mrn: mrn,
        },
      };
      editProfileDetails(config, setLoading, () => {
        // setEditmode(false);
        setViewMode(true);
        setIsUpdateProfileImg(false);
      });
    }
  };

  const isVerified = (input) => {
    if (input === 'phone') {
      if (
        previousData?.phone === phone &&
        previousData?.countryCode == countryCode &&
        phoneVerified
      ) {
        return true;
      } else if (
        previousData?.phone !== phone ||
        previousData?.countryCode !== countryCode
      ) {
        return false;
      }
    } else {
      if (previousData?.email === email && emailVerified) {
        return true;
      } else if (previousData?.email !== email) {
        return false;
      }
    }
  };

  // handle verify click
  const handleVerify = (type) => {
    setValidateType(type);
    let config = {};
    if (type === 'phone') {
      setResendLoader(true);
      const { otp_verificationphn } = JSON.parse(
        localStorage.getItem('Resend')
      );
      if (
        otp_verificationphn &&
        (otp_verificationphn - timeZoneDate().getTime()) / 1000 > 0
      ) {
        displaytoaster(
          intl.formatMessage({
            id: 'commom.otpLimitMsg',
            defaultMessage:
              'OTP send limit exceeded please try again after 5 min',
          }),
          ToasterTypes.Warning
        );
      } else if (!phone || !countryCode) {
        setInvalidAlert((prev) => ({
          ...prev,
          phone: !phone
            ? translate('common.required', 'This field is required')
            : false,
        }));
      } else if (!validateMobile(phone,code)) {
        setInvalidAlert((prev) => ({
              ...prev,
              phone: translate(
                'common.invalidPhone',
                'Invalid mobile number.'
              ),
            }));
      } else {
        config = {
          data: {
            tele_country_code: countryCode,
            phone: phone?.replace(/\s/g, ""),
          },
        };
        setInvalidAlert((prev) => ({
          ...prev,
          phone: false,
        }));
        validateData({id:profileDetail?.user_details?.user_id,type, data:config}, handleValidateSuccess);
      }
    } else {
      setResendLoader(true);
      const { otp_verificationEmail } = JSON.parse(
        localStorage.getItem('Resend')
      );
      if (
        otp_verificationEmail &&
        (otp_verificationEmail - timeZoneDate().getTime()) / 1000 > 0
      ) {
        displaytoaster(
          intl.formatMessage({
            id: 'commom.otpLimitMsg',
            defaultMessage:
              'OTP send limit exceeded please try again after 5 min',
          }),
          ToasterTypes.Warning
        );
      } else if (!email) {
        setInvalidAlert((prev) => ({
          ...prev,
          email: !email
            ? translate('common.required', 'This field is required')
            : false,
        }));
      } else if (email && !validateEmail(email)) {
        setInvalidAlert((prev) => ({
          ...prev,
          email: translate(
            'settings.validEmail',
            'Please enter a valid email id.'
          ),
        }));
      } else {
        config = {
          data: {
            email: email,
          },
        };
        setInvalidAlert((prev) => ({
          ...prev,
          email: false,
        }));
        validateData({id:profileDetail?.user_details.user_id,type, data:config}, handleValidateSuccess);
      }
    }
  };

  // handle validate success
  const handleValidateSuccess = (data) => {
    const { otp_token } = data || {};
    const { token, exp, len } = otp_token || {};
    setOtpToken({
      token,
      exp,
      len,
    });
    setShowVerify(true);
    const timer = setTimeout(() => {
      setResendLoader(false);
    }, 3000);
    return () => clearTimeout(timer);
  };

  // handle close
  const handleClose = (type = '') => {
    setShowVerify(false);
    setValidateType('');
    setOtpToken({
      token: '',
      exp: '',
      len: '',
    });
    if (type === 'phone') {
      setPhoneVerified(true);
      setPreviousData((prev) => ({
        ...prev,
        phone: phone,
        countryCode: countryCode,
      }));
    } else if (type === 'email') {
      setEmailVerified(true);
      setPreviousData((prev) => ({
        ...prev,
        email: email,
      }));
    }
  };

  // handle resend otp
  const handleResend = () => {
    handleVerify(validateType);
  };

  const handleCancel = () => {
    // setEditmode(!editmode);
    setViewMode(true);
    props?.location?.state?.edit && history.push("/webConnect/profiles");
    setProfileDetails({});
    setInvalidAlert({});
  };
  // disable futeure dates for ant datepicker
  const disableFutureDates = (current) => {
    return current && current > moment();
  };

  const handleKeyDown = (e)=>{
    const dropDownBtn = document.querySelector('.selected-flag')
    if(e.key === "Enter"){
      if(!dropDownBtn.classList.contains("open")){
        e.preventDefault()
        dropDownBtn.classList.toggle("open")
        dropDownBtn.click()
      }
      else
      {
        numField.current?.focus()
      }
    }
    if(e.key === "Tab" && dropDownBtn.classList.contains("open")){
      e.preventDefault()
      focusRef.current?.focus()
        dropDownBtn.click()
    }
  }
  // handle edit mode
  useEffect(()=>{
      setEditmode(!viewMode)
  },[viewMode]);

  return (
    <>
      {!editmode ? (
        <>
          {' '}
          <div className="section-title">
            <div>
              <h5>
                {translate('profileSetting.accDetails', 'Account Details')}
              </h5>
              <p className="small pt-1 mb-0">
                {translate(
                  'profileSetting.manageInformation',
                  'Manage your personal and contact information'
                )}
              </p>
            </div>
            <div className="btn-container">
              <a
                onClick={() =>{
                  //  setEditmode(!editmode)
                   setViewMode(false)
                 }}
                className="btn edit-btn-rounded v2"
                tabIndex='0'
                onKeyDown={(e)=>{
                  e.preventDefault()
                  if(e.key === "Enter" && !e.shiftKey){
                   setViewMode(false)
                  }
                }}
              >
                {translate('patientPortal.edit', 'Edit')}
                <img src={edtiIcon} alt="" />
              </a>
            </div>
          </div>
          <div className="card form-card mb-3">
            <div className="set">
              <div className="single-information">
                <p className="label">{translate('common.name', 'Name')}</p>
                <h6 className="value">
                  {userDetails?.name
                    ? `${userDetails?.name} ${userDetails?.last_name}`
                    : translate('common.guest', 'Guest User')}
                </h6>
              </div>
            </div>
            <div className="set">
              <div className="row">
                <div className="col-md-4">
                  <div className="single-information">
                    <p className="label">
                      {translate('patientPortal.mobileNo', 'Mobile Number')}
                    </p>
                    <h6 className="value">{userDetails?.phone || '-'}</h6>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single-information">
                    <p className="label">
                      {translate('common.emailId', 'Email Id')}
                    </p>
                    <h6 className="value">
                      {userDetails?.email ||
                        // translate('profileSetting.notAdded', 'Not Added')}
                        ' - '}
                    </h6>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single-information">
                    <p className="label">
                      {translate('patientPortal.dob', 'Date of Birth')}
                    </p>
                    <h6 className="value">
                      {userDetails?.dob
                        ? slashFormatinDDMMYYYY(userDetails?.dob)
                        : '-'}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="set">
              <div className="row">
                <div className="col-md-4">
                  <div className="single-information">
                    <p className="label">
                      {translate('patientPortal.gender', 'Gender')}
                    </p>
                    <h6 className="value text-capitalize">
                      {userDetails?.gender || '-'}
                    </h6>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single-information">
                    <p className="label">
                      {translate('common.bloodGroup', 'Blood Group')}
                    </p>
                    <h6 className="value">
                      {patientDetails?.blood_group
                        ? `${patientDetails?.blood_group}`
                        : '-'}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            {!userDetails?.name || !userDetails?.dob || !userDetails?.gender ? (
              <div className="alert-box alert-warning mt-3">
                <div className="img-container">
                  <div className="icon"></div>
                </div>
                <p>
                  {translate(
                    'patientPortal.warning',
                    'Please fill these information in your profile : Name, Gender and Date of Birth before going to book any appointments'
                  )}
                </p>
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <div className="section-title">
            <div>
              <h5>
                {translate('profileSetting.accDetails', 'Account Details')}
              </h5>
              <p className="small pt-1 mb-0">
                {translate(
                  'profileSetting.manageInformation',
                  'Manage your personal and contact information'
                )}
              </p>
            </div>
            <div className="btn-container">
              <div className="btn-container d-flex">
                <button
                  onClick={handleCancel}
                  className="btn btn-outlined-dark"
                >
                  {translate('profileSetting.cancel', 'Cancel')}
                </button>

                {/* <button onClick={loading ? () => { } : handleProfileSave} className="btn btn-primary">
                  {translate('profileSetting.update', 'Update')}
                </button> */}

                <button
                  className="px-4 btn btn-primary buttonClick loader-btn"
                  onClick={loading ? () => {} : handleProfileSave}
                >
                  {loading ? (
                    <ButtonLoader
                      text={translate('profileSetting.update', 'Update')}
                    />
                  ) : (
                    translate('profileSetting.update', 'Update')
                  )}
                </button>
              </div>
            </div>
          </div>
          <div
            id="edit-patient-info"
            className="card form-card edit-patient-info"
          >
            <div className="set">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-3 mb-md-0">
                    <label htmlFor="fName" className="mandatory">
                      {translate('common.firstName', 'First Name')}
                    </label>
                    <input
                      onChange={(e) => handleChange(e)}
                      id="fName"
                      type="text"
                      placeholder="i.e, John"
                      className={`input w-100 ${
                        invalidAlert.fName ? 'input-err' : ''
                      } `}
                      name="fName"
                      value={fName}
                      maxLength={40}
                    />
                    <span
                      className={`${invalidAlert.fName ? 'err' : 'd-none'}`}
                    >
                      {invalidAlert.fName}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3 mb-md-0">
                    <label htmlFor="lName">
                      {translate('common.lastName', 'Last Name')}
                    </label>
                    <input
                      onChange={(e) => handleChange(e)}
                      id="lName"
                      type="text"
                      placeholder="i.e, John"
                      className="input w-100"
                      name="lName"
                      value={lName}
                      maxLength={40}
                    />
                  </div>
                </div>
                {applicationData?.use_system_generated_mrn ? (
                  ''
                ) : (
                  <div className="col-md-4">
                    <div className="form-group mb-2 mb-md-0">
                      <label htmlFor="mrn">
                        {translate(
                          'patientPortal.mrnHealthId',
                          'MRN / Health ID'
                        )}
                      </label>
                      <input
                        onChange={(e) => handleChange(e)}
                        id="mrn"
                        type="text"
                        placeholder="i.e, TC90025"
                        className="input w-100"
                        name="mrn"
                        value={mrn}
                        maxLength={50}
                        disabled={patientDetails?.mrn}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="set pb-1">
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label htmlFor="phone" className="lable mandatory">
                      {translate('patientPortal.mobileNo', 'Mobile Number')}
                    </label>
                    <div className="phone-field gap-3 not-verified">
                      <span onKeyDown={(e) => {
                if (e.key === "Tab" && !e.shiftKey)
                {
                  e.preventDefault()
                  numField.current.focus()
                } 
                handleKeyDown(e)
              }}>
                      <PhoneNumberInput
                        value={countryCode}
                        onChange={handleCountryCode}
                        numberLength={numberLength}
                        setNumberLength={setNumberLength}
                        focusRef={focusRef}
                        code={code}
                        setCode={setCode}
                      />
                        </span>
                        <div>
                      <input
                        onChange={(e) => handleChange(e)}
                        type="tel"
                        id="phone"
                        placeholder="98950 98950"
                        ref={numField}
                        className={`form-control grey input ${
                          invalidAlert.phone ? 'input-err' : ''
                        } `}
                        name="phone"
                        value={phone}
                        maxLength={numberLength}
                        onKeyDown={(e)=>{
                          if(e.shiftKey && e.key === "Tab"){
                            e.preventDefault()
                            focusRef.current.focus()
                          }
                        }}
                      />
                      {/* heres */}
                      {isVerified('phone') ? (
                        <img className="verified-icon" src={verifiedIcon} />
                      ) : (
                        <button
                          onClick={() => handleVerify('phone')}
                          className="btn-verify"
                        >
                          Verify
                        </button>
                      )}
                    <span
                      className={`${invalidAlert.phone ? 'err' : 'd-none'}`}
                    >
                      {invalidAlert.phone}
                    </span>
                    </div>
                    </div>   
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label htmlFor="email">
                      {translate('common.emailId', 'Email Id')}
                    </label>
                    {/* <div className="icon-field date"> */}
                    {/* <i className="bi bi-calendar-week icon"></i> */}
                    <div className="verified">
                      <input
                        onChange={(e) => handleChange(e)}
                        id="email"
                        type="email"
                        className={`input w-100 ${
                          invalidAlert.email ? 'input-err' : ''
                        } `}
                        placeholder="i.e, johnoe@gmail.com"
                        name="email"
                        value={email}
                        maxLength={50}
                      />
                      {isVerified('email') ? (
                        <img className="verified-icon" src={verifiedIcon} />
                      ) : (
                        <button
                          onClick={() => handleVerify('email')}
                          className="btn-verify"
                        >
                          Verify
                        </button>
                      )}
                      <span
                        className={`${invalidAlert.email ? 'err' : 'd-none'}`}
                      >
                        {invalidAlert.email}
                      </span>
                    </div>

                    {/* </div> */}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-4">
                    <label htmlFor="date" className="mandatory">
                      {translate('patientPortal.dob', 'Date of Birth')}
                    </label>
                    <div className="icon-field date">
                      <AntDatepicker
                        className={`icon-field date ${
                          invalidAlert.dob ? 'input-err' : ''
                        } `}
                        value={dob}
                        onInputChange={handleDobChange}
                        placeholder="DD/MM/YYYY"
                        disabledDate={disableFutureDates}
                      />
                    </div>
                    <span className={`${invalidAlert.dob ? 'err' : 'd-none'}`}>
                      {translate('common.required', 'This field is required')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="set">
              <div className="row">
                <div className="col-md-6 col-xl-4">
                  <div className="form-group mb-4">
                    <span className="label mandatory">
                      {translate('patientPortal.gender', 'Gender')}
                    </span>
                    <div>
                      <div
                        className={`btn-group w-100 ${
                          invalidAlert.gender ? 'input-err' : ''
                        } `}
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio1"
                          autoComplete="off"
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              genderHandle('male')
                            }
                          }}
                          checked={gender === 'male'}
                        />
                        <label
                          onClick={() => genderHandle('male')}
                          className="btn btn-black"
                          htmlFor="btnradio1"
                        >
                          {translate('common.male', 'Male')}
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio2"
                          autoComplete="off"
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              genderHandle('female')
                            }
                          }}
                          checked={gender === 'female'}
                        />
                        <label
                          onClick={() => genderHandle('female')}
                          className="btn btn-black"
                          htmlFor="btnradio2"
                        >
                          {translate('common.female', 'Female')}
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio3"
                          autoComplete="off"
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              genderHandle('other')
                            }
                          }}
                          checked={gender === 'other'}
                        />
                        <label
                          onClick={() => genderHandle('other')}
                          className="btn btn-black"
                          htmlFor="btnradio3"
                        >
                          {translate('common.other', 'Others')}
                        </label>
                      </div>
                    </div>
                    <span
                      className={`${invalidAlert.gender ? 'err' : 'd-none'}`}
                    >
                      {translate('common.genderRequired', 'Please select gender')}
                    </span>
                  </div>
                </div>
                <div className="col-md-4 mb-0 mb-md-4">
                  <div className="form-group">
                    <label htmlFor="bloodGroup">
                      {translate('common.bloodGroup', 'Blood Group')}
                    </label>
                    <div
                      className={`select min-height-dr ${
                        invalidAlert.bloodGrp ? 'input-err' : ''
                      } `}
                      onKeyDown={(e)=>{
                        if(e.key=== "Tab" && !e.shiftKey) e.preventDefault()
                      }}
                    >
                      <ReactSelectDropDown
                        options={BLOOD_GROUP_OPTIONS}
                        value={bloodGrp?.value && bloodGrp}
                        onInputChange={bloodHandle}
                        label={translate('common.select', 'Select')}
                      />
                      <span
                        className={`${
                          invalidAlert.bloodGrp ? 'err' : 'd-none'
                        }`}
                      >
                        {translate('common.required', 'This field is required')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showVerify && (
            <Modal
              close={showVerify}
              setClose={handleClose}
              title={PATIENT_LOGIN_TITLES.otpVerification}
            >
              <OtpVerification
                onClose={handleClose}
                otp_token={otp_token}
                country={countryCode}
                phone={phone}
                email={email}
                userDetails={userDetails}
                handleResend={handleResend}
                validateType={validateType}
                resendLoader={resendLoader}
              />
            </Modal>
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  profileDetails: state.SettingsReducer.profileDetails,
  applicationData: state.SettingsReducer.applicationData,
  viewMode: state.ProfileReducer.viewMode,
});

const mapDispatchToProps = (dispatch) => ({
  editProfileDetails: (data, handleSuccess, handleNavigation) =>
    dispatch(updateProfileDetails(data, handleSuccess, handleNavigation)),
  validateData: (payload, handleSuccess) =>
    dispatch(validatePhoneMail(payload, handleSuccess)),
  displaytoaster: (message, type) => dispatch(showToaster(message, type)),
  setViewMode:(data)=> dispatch(setViewMode(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInformation);
