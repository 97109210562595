import React, { useEffect, useState } from 'react';
// import Calendar from './Calendar';
import { connect } from 'react-redux';
import {
  FetchAppointmentList,
  startAppointment,
} from 'Actions/appointmentAction';
import {
  // appendTime,
  formatDate,
  formatDateto_D_WD_M_Y,
  setHourAndMinute,
  getIsToday,
  getTimeDurationInMinutes,
  // formatDateto_D_M_Y,
} from 'Utils/DateFormatter/Date';
// import Avatar from '@mui/material/Avatar';
import CustomTimeline from './CustomTimeline';
import ConflictConform from './ConflictConfirm';
import Loader from 'Components/Common/Loader';
import { useHistory } from 'react-router-dom';
import NoResultFound from 'Components/Common/NoResult';
import { translate } from 'Language/Translate';
// import AddIcon from "../../Assets/img/icons/add-btn-icon.svg"

function DayRoster({
  getAppointments,
  appointmentList,
  date,
  // setDate,
  // activeDate,
  // setActiveDate,
  patientName,
  applyCounter,
  handlestartAppointment,
  sort,
  searchData,
  applySearchData,
  language_Code,
  isBtnLoading,
  patient,
  stat
}) {
  // let buffer=10;
  const [events, setEvents] = useState([]);
  const [isLoader, updateLoader] = useState(false);
  const [showConflictPopup, setshowConflictPopup] = useState({ show: false });
  // const [popupDetails,setPopupDetails]=useState({});
  // const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const history = useHistory();
  const { startAppt: startApptLoading = false } = isBtnLoading;
  const [adjustTimelineSize,setAdjustTimelineSize]=useState(false)
  useEffect(()=>{
    if(events.length){
      let durationInMinutes= events.map((interval)=>{
         return getTimeDurationInMinutes(interval?.appt_start_time,interval?.appt_end_time)
       })
       let condition =durationInMinutes.some((val)=>Number(val)<=10)
          setAdjustTimelineSize(condition)
      }
    }
    ,[events])
  useEffect(() => {
    if (applyCounter) {
      fetchAppointments({
        department: sort?.department?.value,
        search: patientName,
        appStatus: sort?.status?.value ? sort?.status?.value : [],
        start_Date:searchData.date? formatDate(searchData.date):formatDate(date),
        end_Date: searchData.date? formatDate(searchData.date):formatDate(date)
      });
    }
  }, [applyCounter]);

  useEffect(() => {
    if(applyCounter){
    applySearchData({
      ...searchData,
      date: date,
      startDate: formatDate(date),
      endDate: formatDate(date),
    });
    }
  }, [date, applyCounter]);

  const fetchAppointments = ({
    search: searchWord,
    appStatus,
    department,
    // pageNo,
    // perPageCount,
    start_Date,
    end_Date,
  } = {}) => {
    if (!isLoader) updateLoader(true);
    const config = {
      patient_search:
        searchWord || searchWord === ''
          ? searchWord || undefined
          : // : searchData?.search
            // ? searchData?.search
            undefined,
      dept_doc_search:
        department || department === '' ? department || undefined : undefined,
      start_date:
        start_Date || start_Date === ''
          ? start_Date || undefined
          : formatDate(date),
      end_date:
        end_Date || end_Date === '' ? end_Date || undefined : formatDate(date),
      status:
        appStatus || appStatus === ''
          ? appStatus || undefined
          : // : searchData?.selectedStatus?.value
            // ? searchData?.selectedStatus?.value
            undefined,
      page: 1,
      limit: 0,
      acl: true,
      sort: 'appt_start_time',
    };
    getAppointments(config, updateLoader);
  };

  useEffect(() => {
    if (appointmentList?.appointments) {
      let ar = [];
      let cancelled = [];
      let other = [];
      let upcoming = [];
      let appointmentStart;
      appointmentList.appointments.map((val, i) => {
        if (i == 0) {
          appointmentStart = new Date(setHourAndMinute(val.appt_start_time));
        }
        if (val.live_status === 'cancelled') {
          cancelled.push({
            ...val,
            id: val.appointment_id,
            title: val.patient_name,
            status: val.live_status,
            start: new Date(setHourAndMinute(val.appt_start_time)),
            end: new Date(setHourAndMinute(val.appt_end_time)),
            dayStartTime: appointmentStart,
          });
        } else {
          other.push({
            ...val,
            id: val.appointment_id,
            title: val.patient_name,
            status: val.live_status,
            start: new Date(setHourAndMinute(val.appt_start_time)),
            end: new Date(setHourAndMinute(val.appt_end_time)),
            dayStartTime: appointmentStart,
          });
        }
      });
      ar = [...cancelled, ...other];

      getIsToday(date) &&
        appointmentList.appointments.forEach((val) => {
          if (val.live_status === 'upcoming') {
            upcoming.push(val);
          }
        });
      // setUpcomingAppointments(upcoming);
      setEvents(ar);
    } else if (!appointmentList.appointments) {
      setEvents([]);
    }
    setTimeout(() => {
      let element = document.getElementsByClassName(
        'rbc-current-time-indicator'
      );
      if (element.length) {
        if (getIsToday(date)) {
          element[0].style.display = 'block';
        } else {
          element[0].style.display = 'none';
        }
      }
    }, [100]);
  }, [appointmentList, date]);

  // const onDateChange = (e) => {
  //   setDate(e);
  //   applySearchData({
  //     ...searchData,
  //     date: e,
  //     startDate: formatDate(e),
  //     endDate: formatDate(e),
  //   });
  //   const element = document.getElementById('rosterWeekViewCalender');
  //   const element1 = document.getElementById('rosterWeekViewCalenderToggle');
  //   element?.classList?.remove('show');
  //   element1?.classList?.remove('show');
  // };

  const handleInprogressAppointment = (code, payload) => {
    if (code === 409) {
      setshowConflictPopup({ show: true, item: payload });
      updateLoader(false)
      // const element = document.getElementById(`appointmentView-${payload.id}`);
      // const element1 = document.getElementById(
      //   `appointmentViewToggle-${payload.id}`
      // );
      // console.log({element:element.classList})
      // element.classList.remove('show');
      // element1.classList.remove('show');
    }
  };
  //  handle appointment start
  const handleAppointmnentStart = async (item, type) => {
    if (type === 'Start') {
      updateLoader(true)
      let payload = { id: item?.appointment_id, auto_complete: false };
      handlestartAppointment(
        payload,
        () => {
          updateLoader(false)
          history.push(`/admin/appointments/patientview/${item?.appointment_id}`);
        },
        handleInprogressAppointment
      );
      // setshowConflictPopup({show:true,item:item})
    } else {
      updateLoader(false)
      history.push(`/admin/appointments/patientview/${item.appointment_id}`);
    }
  };
  const handleProceed = () => {
    let payload = { id: showConflictPopup?.item?.id, auto_complete: true };
    handlestartAppointment(payload, () => {
      history.push(`/admin/appointments/patientview/${payload?.id}`);
    });
    setshowConflictPopup({ show: false });
  };
  const handleCancel = () => {
    setshowConflictPopup({ show: false });
  };

  return (
    <div className={`own-view-day ${adjustTimelineSize?"adjustTimelineHeight":""}`}>
      <div className="data-wrapper">
        <div className="left-area position-relative">
          <div className="list-filter">
            <h6 className="">
              {formatDateto_D_WD_M_Y(date, language_Code)}
            </h6>
          </div>
          <div>
            {appointmentList?.appointments?.length && !(startApptLoading || isLoader) ?
             <CustomTimeline
                events={isLoader ? [] : events}
                handleAppointmnentStart={handleAppointmnentStart}
                language={language_Code}
                filteredDate={searchData?.date ? searchData.date : new Date()}
                isBtnLoading={startApptLoading}
                patient={patient}
                stat={stat}
                conflictConform={showConflictPopup.show}
              /> 
              : (startApptLoading || isLoader) ?
                <div className='slot-listing-wrapper-loading'></div>
              :
                <div className='patient-portal'>
                  <NoResultFound
                    text={translate('common.noAppointments', 'No Appointments!')}
                    label={translate('common.noAppointmentsbooked',"Looks like you have no appointments booked yet.")}
                    removeMarginBottom removeMarginTop />
                </div>
            }
          </div>

          {isLoader || startApptLoading ? (
            <div className="loaderWrap">
              <Loader />
            </div>
          ) : null}
        </div>
        {/* <div className="right-area d-none">
          <div className="calander-area large-screen-only">
            <Calendar
              value={date}
              onDateChange={onDateChange}
              activeDate={activeDate}
              setActiveDate={setActiveDate}
              language={language_Code}
            />
          </div>

          {upcomingAppointments.length ? (
            <div className="upcoming-appointments upcomming_appoinments">
              <h5>Upcoming Appointments</h5>
              {upcomingAppointments.map((val, i) => {
                return (
                  <div className="upcoming-card mb-3" key={i}>
                    {val.patient_profile_image ? (
                      <img src={val.patient_profile_image} alt="" />
                    ) : (
                      <Avatar
                        sx={{ width: 40, height: 40 }}
                        src="/broken-image.jpg"
                      />
                    )}
                    <div>
                      <h6 className="schedule">{`${appendTime(
                        val.appt_start_time
                      )} - ${appendTime(val.appt_end_time)}`}</h6>
                      <span className="patient-name fw-bold">
                        {val.patient_name}
                      </span>
                      <p className="reason">{val.reason}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div> */}
        {/* <button id="add" className="add-btn">
              <img src={AddIcon} alt="Add Apointment" />
            </button> */}
      </div>
      {showConflictPopup?.show ? (
        <ConflictConform
          onConfirm={handleProceed}
          onClose={handleCancel}
          isBtnLoading={startApptLoading}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  appointmentList: state.AppointmentReducer.appointmentList,
  isBtnLoading: state.AppointmentReducer.isBtnLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getAppointments: (data, setLoader) =>
    dispatch(FetchAppointmentList(data, setLoader)),
  handlestartAppointment: (data, onSuccess, onError) =>
    dispatch(startAppointment(data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DayRoster);
