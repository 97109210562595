import React, { useEffect, useRef, useState } from 'react';
import PhoneNumberInput from '../../Common/PhoneInput';
import lock from 'Assets/img/icons/lock-circle.svg';
import { translate } from '../../../Language/Translate';
import { formatDate } from '../../../Utils/DateFormatter/Date';
import { connect, useSelector } from 'react-redux';
import ButtonLoader from 'Components/Common/ButtonLoader';

function PatientInfoModal({
  doctorData,
  appointmentsViewData,
  date,
  selectedSlot,
  editAppointment,
  fetchAppointments,
  setShowPatient,
  setOpen,
  isBtnLoading,
  setApptId,
  deptValue,
  modalShow
}) {
  const reasonRef = useRef()
  const [numberLength, setNumberLength] = useState(10);             
  const [code,setCode]=useState('IN')           
  const [reason, setReason] = useState('');
  const [error, setError] = useState(false);

  const defaultCountryCode = useSelector(
    (state) => state.SettingsReducer.subscriptionData.setting.defaultCountryCode
  );

  const { editAppt: editLoading = false } = isBtnLoading;

  useEffect(() => {
    if (appointmentsViewData?.appointment_details) {
      setReason(appointmentsViewData?.appointment_details?.reason);
    }
  }, [appointmentsViewData]);

  useEffect(()=>{
    const timer = setTimeout(()=>{
      if(reasonRef.current) reasonRef.current.focus()
    },100)
    return ()=>clearTimeout(timer)
  },[modalShow])

  const handleInputChange = (event) => {
    const { value } = event.target;
    setReason(value);
    setError(false);
  };

  const handleEditClose = (id) => {
    setOpen(true);
    setShowPatient(false);
    setApptId(id);
  };
  const handleConfirm = () => {
    if (reason) {
      const config = {
        data: {
          doctor_id: doctorData.doctor_id,
          dept_name: deptValue?.label,
          dept_id: deptValue?.value,
          schedule_type: 'op',
          appt_date: formatDate(date),
          appt_start_time: selectedSlot.start_time,
          appt_end_time: selectedSlot.end_time,
          reason: reason,
          patient_details: {
            patient_id: appointmentsViewData?.patient_details?.patient_id,
            name: appointmentsViewData?.patient_details?.name,
            last_name: appointmentsViewData?.patient_details?.last_name,
            email: appointmentsViewData?.patient_details?.patient_email,
            dob: appointmentsViewData?.patient_details?.dob,
            gender: appointmentsViewData?.patient_details?.gender,
          },
        },
      };
      editAppointment(
        appointmentsViewData?.appointment_details?.appointment_id,
        config,
        fetchAppointments,
        handleEditClose
      );
    } else {
      setError(true);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-group mb-4">
            <label htmlFor="name">{translate('common.name', 'Name')}</label>
            <input
              disabled
              type="text"
              id="name"
              className="input form-control"
              value={`${appointmentsViewData?.patient_details?.name} ${appointmentsViewData?.patient_details?.last_name}`}
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="phno" className="lable">
              {translate('patientPortal.mobileNo', 'Mobile Number')}
            </label>

            <div className="phone-field gap-3">
              <div className="dropdown country-code-picker">
                <PhoneNumberInput
                  editStatus
                  value={
                    appointmentsViewData?.patient_details?.tele_country_code ??
                    defaultCountryCode?.toString()
                  }
                  numberLength={numberLength}
                 setNumberLength={setNumberLength}
                 code={code}
                 setCode={setCode}
                />
              </div>
              <input
                disabled
                type="tel"
                id="phno"
                placeholder="i.e, 98950 98950"
                className="form-control grey input"
                value={appointmentsViewData?.patient_details?.phone}
              />
              <img src={lock} />
            </div>
          </div>
          <div className="form-group mb-4">
            <label htmlFor="email">
              {translate('patientPortal.email', 'Email Id')}
            </label>
            <input
              disabled
              type="email"
              id="email"
              className="input form-control"
              value={appointmentsViewData?.patient_details?.email}
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="reason">
              {translate('myAppointments.reason', 'Reason for Appointment')}
            </label>
            <div className="text-area-wrap">
            <textarea
              className={`input form-control ${error ? 'input-err' : ''}`}
              id="reason"
              cols="30"
              rows="3"
              value={reason}
              onChange={handleInputChange}
              maxLength="250"
              ref={reasonRef}
              onKeyDown={(e)=>{
                if(e.key === "Tab" && e.shiftKey)e.preventDefault()
              }}
            ></textarea>
                  </div>
            {error ? (
              <span className="err">
                {translate('common.required', 'This field is required.')}
              </span>
            ) : null}
          </div>

          <div>
            <button className="btn btn-primary w-100" onClick={handleConfirm} 
            disabled={editLoading}
            onKeyDown={(e)=>{
              if(e.key === "Tab" && !e.shiftKey)e.preventDefault()
              if(e.key === "Enter" && !e.shiftKey){
                e.preventDefault()
                handleConfirm()
              }
            }}
            >
              {editLoading ? (
                <ButtonLoader
                  text={translate(
                    'myAppointments.reschedule',
                    'Reschedule Appointment'
                  )}
                />
              ) : (
                translate('myAppointments.reschedule', 'Reschedule Appointment')
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isBtnLoading: state.AppointmentReducer.isBtnLoading,
});

export default connect(mapStateToProps)(PatientInfoModal);
