/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react';
import { Avatar, CardMedia, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import PasswordInput from './PasswordInput';
import PasswordStrengthBar from './passwordProgress';
import './Style/settings.css';
import './Style/admin-settings.css';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '../../Language/Translate';
import tick from '../../Assets/img/icons/verified-tick.svg'
import {
  changeSystemLanguage,
  fetchUserProfileDetails,
  updateClientDetails,
  updateProfileDetails,
  updateUserPassword,
  validatePhoneMail,
  verifyPhoneMail,
} from '../../Actions/settingsAction';
import {
  validateEmail,
  validateMobile,
} from '../../Utils/Validation/validator';
import PhoneNumberInput from '../Common/PhoneInput';
import VerifyData from './VerifyData';
import { showToaster, ToasterTypes } from 'Actions/toasterAction';
import ButtonLoader from 'Components/Common/ButtonLoader';
import Loader from 'Components/Common/Loader';
import CropImage from 'Components/Common/CropImage';
import { timeZoneDate } from 'Utils/DateFormatter/Date';
import svgIc from 'Assets/img/icons/placeholder-avatar.svg'
import deleteIicon from 'Assets/img/icons/trash-circle.svg';



let Interval = null;

const Settings = (props) => {
  const {
    changePassword,
    editProfileData,
    // userData,
    getProfileDetails,
    profileDetails,
    validateData,
    verifyData,
    defaultCountryCode,
    // appData,
    otpDetails,
    displaytoaster,
    isVerifyLoading
  } = props;
  const hiddenFileInput = useRef(null);
  const numField = useRef()
  const [loading, setLoading] = useState(false);
  // profile details
  const [personalInfo, setPersonalInfo] = useState({
    firstName: '',
    lastName: '',
    country: defaultCountryCode?.toString(),
    phoneNo: '',
    emailId: '',
    profileImg: '',
    profileImgCrop: '',
    address: '',
    gender: '',
    dob: '',
    profileImgUpdate: false,
  });
  const [previousData, setPreviousData] = useState({
    phone: '',
    country: '',
    email: '',
  });
  // profile errors
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [invalidMail, setInvalidMail] = useState(false);
  const [phoneVerifyError, setPhoneVerifyError] = useState(false);
  const [emailVerifyError, setEmailVerifyError] = useState(false);
  // otp data
  const [showVerify, setShowVerify] = useState(false);
  const [validateType, setValidateType] = useState('');
  const [otpData, setOtpData] = useState({
    otp: '',
    token: '',
    len: '',
    exp: '',
  });
  // resend Details

  const { otp_limit_duration, otp_resend_limit } = otpDetails || {};

  // Otp Time Counter
  const [countdown, setCountdown] = useState(0);
  const intl = useIntl();
  const focusRef = useRef();

  // Resend Counter
  const [resend, setResend] = useState(0);
  const [resendCounterPhn, setResendCounterPhn] = useState(0);
  const [resendCounterEmail, setResendCounterEmail] = useState(0);
  const [showResend, setShowResend] = useState(false);
  const [resendType, setResendType] = useState('');

  // common settings
  const [settingsView, setsettingsView] = useState({
    profile: true,
    password: false,
  });

  const [pageLoader, setPageLoader] = useState(false);
  // passowrd details
  const [passwordInfo, setPasswordInfo] = useState({
    // currentPassord: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  // password errors
  const [strongPassword, setStrongPassword] = useState(false);
  const [profileFeildError, setProfileFeilderror] = useState({
    firstNameError: false,
    mobileError: false,
    countryError: false,
    emailError: false,
  });
  const [passwordError, setPasswordError] = useState('');
  const [passwordFeildError, setPasswordFeildError] = useState({
    currentError: false,
    newError: false,
    confirmError: false,
  });
  // passwrod view
  const [showEye, setShowEye] = useState({
    current: false,
    newPass: false,
    confirmPass: false,
  });
  const [showPass, setShowPass] = useState({
    current: false,
    newPass: false,
    confirmPass: false,
  });

  const [key, setKey] = useState(0);

  const [showCrop, setShowCrop] = useState(false);

  const { profile } = settingsView;
  const {
    firstName,
    lastName,
    country,
    phoneNo,
    emailId,
    profileImg,
    profileImgUpdate,
    address,
    gender,
    dob,
    profileImgCrop,
  } = personalInfo;
  const { firstNameError, mobileError, countryError, emailError } =
    profileFeildError;
  const {
    // currentPassord,
    newPassword,
    confirmNewPassword,
  } = passwordInfo;
  const {
    confirmError,
    newError,
    //  currentError
  } = passwordFeildError;

  // phone and email verify loader
  const [phoneVerifyLoad,setPhoneVerifyLoad]=useState(false)
  const [emailVerifyLoad,setEmailVerifyLoad]=useState(false)
  const [resendLoader, setResendLoader] = useState(false)

  const [numberLength, setNumberLength] = useState(10);
  const [code,setCode]=useState('IN')
  useEffect(() => {
    getProfileDetails();
    setPageLoader(true);
  }, []);

  useEffect(() => {
    if (profileDetails?.user_id) {
      setPageLoader(false);
      const userData = profileDetails;
      setPersonalInfo({
        firstName: userData.name,
        lastName: userData.last_name,
        country: userData.tele_country_code || defaultCountryCode?.toString(),
        phoneNo: userData.phone,
        emailId: userData.email,
        profileImg: userData.profile_img,
        address: userData.address,
        gender: userData.gender,
        dob: userData.dob,
        profileImgUpdate: false,
      });
      setPhoneVerified(userData.phone_verified);
      setEmailVerified(userData.email_verified);
      setPreviousData({
        phone: userData.phone,
        country: userData.tele_country_code,
        email: userData.email,
      });
    }
  }, [profileDetails]);

  // handel image browse open
  const hanldeDesktopBrowse = () => {
    hiddenFileInput.current.click();
  };

  // handle profile image selection
  const handleImageSelection = (event) => {
    setKey(key + 1);
    const file = event.target.files[0];
    const reader = new FileReader();
    const maxSizeInBytes = 5 * 1024 * 1024;
    if (
      file?.type !== 'image/jpeg' &&
      file?.type !== 'image/jpg' &&
      file?.type !== 'image/png' &&
      file
    ) {
      displaytoaster(
        translate('settings.wrongfile', 'Unsupported file format'),
        ToasterTypes.Warning
      );
    } else if (file?.size > maxSizeInBytes) {
      displaytoaster(
        translate(
          'settings.verifySize',
          'File size exceeds the 5MB limit. Please select a smaller file.'
        ),
        ToasterTypes.Warning
      );
    } else {
      reader.onload = () => {
        setPersonalInfo((prev) => ({
          ...prev,
          profileImgCrop: reader.result,
          // profileImgUpdate: true,
        }));
        setShowCrop(true);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  // handle Expand of settings
  const handleViewStitch = (view) => {
    setsettingsView(() => ({
      profile: view === 'profile',
      password: view === 'password',
    }));
    if (view !== 'profile') {
      handleProfileReset();
    } else {
      hanldePasswordCancel();
    }
  };

  // handle country code channge
  const handleMobileNumberChange = (phone) => {
    if (
      phone === previousData.country &&
      phoneNo === previousData.phone &&
      profileDetails?.phone_verified
    ) {
      setPhoneVerified(true);
      setPhoneVerifyError(false);
    } else {
      setPhoneVerified(false);
    }
    setPersonalInfo((prev) => ({
      ...prev,
      country: phone,
    }));
    setProfileFeilderror((prev) => ({
      ...prev,
      countryError: false,
    }));
  };

  // handle profile info
  const handleProfileChange = (event) => {
    const { value, name } = event.target;
    if (name === 'firstName') {
      setProfileFeilderror((prev) => ({
        ...prev,
        firstNameError: false,
      }));
    } else if (name === 'emailId') {
      setEmailVerifyLoad(false)
      if (value === previousData.email && profileDetails?.email_verified) {
        setEmailVerified(true);
      } else {
        setEmailVerified(false);
      }
      setProfileFeilderror((prev) => ({
        ...prev,
        emailError: false,
      }));
      if (validateEmail(value)) {
        setInvalidMail(false);
      }
    }
    setPersonalInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // handle password info
  const handlePasswordChange = (event) => {
    const { value, name } = event.target;
    // if (name === 'currentPassord') {
    //   setPasswordFeildError((prev) => ({
    //     ...prev,
    //     currentError: false,
    //   }));
    //   if (value && newPassword && confirmNewPassword) setPasswordError('');
    // }
    if (name === 'newPassword') {
      setPasswordFeildError((prev) => ({
        ...prev,
        newError: false,
      }));
      if (
        value &&
        // currentPassord &&
        confirmNewPassword
      )
        setPasswordError('');
    } else if (name === 'confirmNewPassword') {
      setPasswordFeildError((prev) => ({
        ...prev,
        confirmError: false,
      }));
      if (
        value &&
        newPassword
        // && currentPassord
      )
        setPasswordError('');
    }
    setPasswordInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //handle profile update
  const handleProfileSave = () => {
    let isValid = true;
    if (!firstName.trim().length || !phoneNo || !country || !emailId) {
      isValid = false;
      setProfileFeilderror({
        firstNameError: !firstName.trim().length,
        mobileError: !phoneNo,
        countryError: !country,
        emailError: !emailId,
      });
      setInvalidPhone(false);
      setInvalidMail(false);
      setEmailVerifyError(false);
      setPhoneVerifyError(false);
    }
    if (phoneNo && (!validateMobile(phoneNo,code) 
    )) {
      isValid = false;
      setInvalidPhone(true);
      setPhoneVerifyError(false);
    }
    if (emailId && !validateEmail(emailId)) {
      isValid = false;
      setInvalidMail(true);
      setEmailVerifyError(false);
    }
    if (
      phoneNo &&
      validateMobile(phoneNo,code) &&
      !phoneVerified &&
      (phoneNo !== previousData.phone || country !== previousData.country)
    ) {
      isValid = false;
      setPhoneVerifyError(
        !phoneVerified &&
          (phoneNo !== previousData.phone || country !== previousData.country)
      );
    }
    if (
      emailId &&
      validateEmail(emailId) &&
      !emailVerified &&
      emailId !== previousData.email
    ) {
      isValid = false;
      setEmailVerifyError(!emailVerified && emailId !== previousData.email);
    }
    if (isValid) {
      setInvalidMail(false);
      setInvalidPhone(false);
      setProfileFeilderror({
        firstNameError: false,
        mobileError: false,
        countryError: false,
      });
      setPhoneVerifyError(false);
      setEmailVerifyError(false);
      setLoading(true);
      const config = {
        data: {
          name: firstName,
          last_name: lastName,
          address: address,
          dob: dob,
          gender: gender,
          profile_image_update: profileImgUpdate,
          profile_img: profileImg,
        },
      };
      editProfileData(config, setLoading);
    }
  };

  //handle password success
  const handlePasswordChangeSuccess = () => {
    hanldePasswordCancel();
  };

  // handle password update
  const handlePasswordSave = () => {
    if (
      // !currentPassord ||
      !newPassword ||
      !confirmNewPassword
    ) {
      setPasswordError(
        translate(
          'common.requiredFields',
          'Please Fill all the required Fields'
        )
      );
      setPasswordFeildError({
        // currentError: !currentPassord,
        newError: !newPassword,
        confirmError: !confirmNewPassword,
      });
    } else if (newPassword !== confirmNewPassword) {
      setPasswordError(
        translate(
          'settings.passwordMatch',
          'New password and confirm password does not match.'
        )
      );
      setPasswordFeildError({
        // currentError: false,
        newError: false,
        confirmError: false,
      });
    } else if (!strongPassword) {
      setPasswordError(
        translate(
          'common.criteriaMatch',
          'Password does not match the criteria.'
        )
      );
      setPasswordFeildError({
        // currentError: false,
        newError: false,
        confirmError: false,
      });
    } else {
      const config = {
        data: {
          // current_password: currentPassord,
          credentials: {
            password: newPassword,
            confirm_password: confirmNewPassword,
          },
        },
      };
      changePassword(config, handlePasswordChangeSuccess);
    }
  };

  //handle password cancel
  const hanldePasswordCancel = () => {
    setPasswordInfo({
      // currentPassord: '',
      newPassword: '',
      confirmNewPassword: '',
    });
    setPasswordError('');
    setPasswordFeildError({
      currentError: false,
      newError: false,
      confirmError: false,
    });
  };

  // handle phone number chnange
  const numberChange = (e) => {
    setPhoneVerifyLoad(false)
    const inputValue = e.target.value;
    // const regex = /^[0-9]*\.?[0-9]*$/;
    const regex = /^\+?[0-9 ]*\.?[0-9 ]*$/;
    if (regex.test(inputValue)) {
      if (
        inputValue === previousData.phone &&
        country === previousData.country &&
        profileDetails?.phone_verified
      ) {
        setPhoneVerified(true);
        setPhoneVerifyError(false);
      } else {
        setPhoneVerified(false);
      }
      setProfileFeilderror((prev) => ({
        ...prev,
        mobileError: false,
      }));
      if (inputValue && validateMobile(inputValue,code) ) {
        setInvalidPhone(false);
      }
      setPersonalInfo((prev) => ({
        ...prev,
        phoneNo: inputValue,
      }));
    }
  };

  const remainingTime = (targetTime) => {
    let currentTime = timeZoneDate().getTime();
    let leftSecond = Math.trunc((targetTime - currentTime) / 1000);
    setCountdown(leftSecond);
    if (leftSecond <= 0) {
      clearInterval(Interval);
    }
    return leftSecond;
  };

  let remainingResendTimePhn = (targetTime, Interval) => {
    let currentTime = timeZoneDate().getTime();
    let leftSecond = Math.trunc((targetTime - currentTime) / 1000);
    setResendCounterPhn(leftSecond);
    if (leftSecond <= 0) {
      clearInterval(Interval);
      setShowResend(false);
      setResend(0);
    } else if (!showResend) {
      setShowResend(true);
    }
    return leftSecond;
  };
  let remainingResendTimeEmail = (targetTime, Interval) => {
    let currentTime = timeZoneDate().getTime();
    let leftSecond = Math.trunc((targetTime - currentTime) / 1000);
    setResendCounterEmail(leftSecond);
    if (leftSecond <= 0) {
      clearInterval(Interval);
      setShowResend(false);
      setResend(0);
    } else if (!showResend) {
      setShowResend(true);
    }
    return leftSecond;
  };

  // handle validate success
  const handleValidateSuccess = (data) => {
    const { otp_token } = data;
    const { token, exp, len } = otp_token || {};
    setOtpData((prev) => ({
      ...prev,
      token,
      len,
      exp,
    }));
    let targetTime = timeZoneDate().getTime() + exp * 60 * 1000 + 1000;
    if (Interval) clearInterval(Interval);
    Interval = setInterval(() => remainingTime(targetTime), 1000);
    setShowVerify(true);
    setResendCounterPhn(0);
    setResendCounterEmail(0);
    setPhoneVerifyLoad(false)
    setEmailVerifyLoad(false)
    const timer = setTimeout(() => {
      setResendLoader(false);
    }, 3000);
    return () => clearTimeout(timer);
    
  };

  // handle verify click
  const handleVerify = (type) => {
    setValidateType(type);
    setResend(0);
    setCountdown();
    let config = {};
    if (type === 'phone') {
      setResendLoader(true)
      const resendDetails = localStorage.getItem('Resend');
      const { settingsOtpPhn } = JSON.parse(resendDetails);
      if (
        settingsOtpPhn &&
        (settingsOtpPhn - timeZoneDate().getTime()) / 1000 > 0
      ) {
        displaytoaster(
          intl.formatMessage({
            id: 'commom.otpLimitMsg',
            defaultMessage:
              'OTP send limit exceeded please try again after 5 min',
          }),
          ToasterTypes.Warning
        );
      } else if (!phoneNo || !country) {
        setProfileFeilderror((prev) => ({
          ...prev,
          mobileError: !phoneNo,
          countryError: !country,
        }));
      } else if (!validateMobile(phoneNo,code) ) {
        setInvalidPhone(true);
      } else {
        config = {
          data: {
            tele_country_code: country,
            phone: phoneNo?.replace(/\s/g, ""),
          },
        };
        setProfileFeilderror((prev) => ({
          ...prev,
          mobileError: false,
          countryError: false,
        }));
        setInvalidPhone(false);
        setPhoneVerifyError(false);
        setResendType('settingsOtpPhn');
        setResendCounterPhn(0);
        setResendCounterEmail(0);
        setPhoneVerifyLoad(true)
        if(!phoneVerifyLoad){
        validateData({id:profileDetails?.user_id,type, data:config}, handleValidateSuccess);
        }
      }
    } else {
      setResendLoader(true)
      const resendDetails = localStorage.getItem('Resend');
      const { settingsOtpEmail } = JSON.parse(resendDetails);
      if (
        settingsOtpEmail &&
        (settingsOtpEmail - timeZoneDate().getTime()) / 1000 > 0
      ) {
        displaytoaster(
          intl.formatMessage({
            id: 'commom.otpLimitMsg',
            defaultMessage:
              'OTP send limit exceeded please try again after 5 min',
          }),
          ToasterTypes.Warning
        );
      } else if (!emailId) {
        setProfileFeilderror((prev) => ({
          ...prev,
          emailError: !emailId,
        }));
      } else if (emailId && !validateEmail(emailId)) {
        setInvalidMail(true);
      } else {
        config = {
          data: {
            email: emailId,
          },
        };
        setProfileFeilderror((prev) => ({
          ...prev,
          emailError: false,
        }));
        setInvalidMail(false);
        setEmailVerifyError(false);
        setResendType('settingsOtpEmail');
        setResendCounterPhn(0);
        setResendCounterEmail(0);
        setEmailVerifyLoad(true)
        if(!emailVerifyLoad){
        validateData({id:profileDetails?.user_id,type, data:config}, handleValidateSuccess);
      }}
    }
  };
  // handle close
  const handleClose = (type = '') => {
    setResend(0);
    setCountdown();
    remainingTime(0);
    setShowVerify(false);
    setValidateType('');
    setOtpData({
      otp: '',
      token: '',
      len: '',
      exp: '',
    });
    setPhoneVerifyLoad(false)
    setEmailVerifyLoad(false)
    if (type === 'phone') {
      setPhoneVerified(true);
      setPreviousData((prev) => ({
        ...prev,
        phone: phoneNo,
        country: country,
      }));
    } else if (type === 'email') {
      setEmailVerified(true);
      setPreviousData((prev) => ({
        ...prev,
        email: emailId,
      }));
    }
  };

  // handle resend otp
  const handleResend = () => {
    handleVerify(validateType);
    setOtpData((prev) => ({
      ...prev,
      otp: '',
      token: '',
    }));
    remainingTime(0);
    setResend(resend + 1);
    if (resend + 1 >= otp_resend_limit) {
      let targetResendTime =
        timeZoneDate().getTime() + otp_limit_duration * 60 * 1000 + 1000;
      const resendlocal = JSON.parse(localStorage.getItem('Resend'));
      localStorage.setItem(
        'Resend',
        JSON.stringify({ ...resendlocal, [resendType]: targetResendTime })
      );
      if (resendType === 'settingsOtpPhn') {
        let IntervalRemaining = setInterval(
          () => remainingResendTimePhn(targetResendTime, IntervalRemaining),
          1000
        );
      } else if (resendType === 'settingsOtpEmail') {
        let IntervalRemaining = setInterval(
          () => remainingResendTimeEmail(targetResendTime, IntervalRemaining),
          1000
        );
      }
    }
  };

  // handle OTP verification
  const handleOtpVerification = () => {
    let config = {};
    if (validateType === 'phone') {
      config = {
        data: {
          update_data: {
            tele_country_code: country,
            phone: phoneNo?.replace(/\s/g, ""),
          },
          otp_verification: {
            otp: otpData.otp?.join(''),
            token: otpData.token,
          },
        },
      };
    } else {
      config = {
        data: {
          update_data: {
            email: emailId,
          },
          otp_verification: {
            otp: otpData.otp?.join(''),
            token: otpData.token,
          },
        },
      };
    }
    verifyData(profileDetails?.user_id, validateType, config, handleClose);
  };

  // handle save click
  const handleSaveClick = () => {
    if (profile) {
      handleProfileSave();
    } else {
      handlePasswordSave();
    }
  };

  // handle profile reset
  const handleProfileReset = () => {
    const userData = profileDetails;
    setPersonalInfo({
      firstName: userData.name,
      lastName: userData.last_name,
      country: userData.tele_country_code || defaultCountryCode?.toString(),
      phoneNo: userData.phone,
      emailId: userData.email,
      profileImg: userData.profile_img,
      address: userData.address,
      gender: userData.gender,
      dob: userData.dob,
      profileImgUpdate: false,
    });
    setPhoneVerified(userData.phone_verified);
    setEmailVerified(userData.email_verified);
    setInvalidMail(false);
    setInvalidPhone(false);
    setProfileFeilderror({
      firstNameError: false,
      mobileError: false,
      countryError: false,
    });
    setPhoneVerifyError(false);
    setEmailVerifyError(false);
  };

  // handle reset click
  const handleRest = () => {
    if (profile) {
      handleProfileReset();
    } else {
      hanldePasswordCancel();
    }
  };

  const setData = (data) => {
    setPersonalInfo((prev) => ({
      ...prev,
      profileImg: data,
      profileImgUpdate: true,
    }));
  };

  const removeProfilePic = () => {
    setPersonalInfo((prev) => ({
      ...prev,
      profileImg: '',
      profileImgUpdate: true,
    }));
  };



  // hanlde crop modal close
  const handleCropClose = () => {
    setPersonalInfo((prev) => ({
      ...prev,
      profileImgCrop: '',
      // profileImgUpdate: true,
    }));
    setShowCrop(false);
  };
  const handleKeyDown = (e)=>{
    const dropDownBtn = document.querySelector('.selected-flag')
    if(e.key === "Enter"){
      if(!dropDownBtn.classList.contains("open")){
        e.preventDefault()
        dropDownBtn.classList.toggle("open")
        dropDownBtn.click()
      }
      else
      {
        numField.current?.focus()
      }
    }
    if(e.key === "Tab" && dropDownBtn.classList.contains("open")){
      e.preventDefault()
      focusRef.current?.focus()
        dropDownBtn.click()
    }
  }

  return (
    <>
      {showCrop && (
        <CropImage
          imgSrc={profileImgCrop}
          setShowCrop={setShowCrop}
          setData={setData}
          onClose={handleCropClose}
          title={translate('settings.cropProfile', 'Change profile picture')}
        />
      )}
      {showVerify && (
        <VerifyData
          onClose={handleClose}
          otpData={otpData}
          countdown={countdown}
          country={country}
          phone={phoneNo}
          email={emailId}
          setOtpData={setOtpData}
          handleResend={handleResend}
          onConfirm={handleOtpVerification}
          validateType={validateType}
          resendCount={resend}
          otp_resend_limit={otp_resend_limit}
          otp_limit_duration={otp_limit_duration}
          resendCounterPhn={resendCounterPhn}
          resendCounterEmail={resendCounterEmail}
          resendType={resendType}
          counter={
            resendType === 'settingsOtpPhn'
              ? resendCounterPhn
              : resendCounterEmail
          }
          isVerifyLoading={isVerifyLoading}
          resendLoader={resendLoader}
        />
      )}

      <div className="content settings-page settings-design tab-design">
        <div className="tab-wrapper">
          <div className="tab-body">
            <ul className="nav mb-2 custom-tab" id="pills-tab" role="tablist">
              <li
                className="nav-item"
                role="presentation"
                onClick={() => handleViewStitch('profile')}
              >
                <button
                  className="active"
                  id="editProfile"
                  data-bs-toggle="pill"
                  data-bs-target="#editProfile-tab"
                  type="button"
                  role="tab"
                >
                  <FormattedMessage
                    id="settings.editProfile"
                    defaultMessage="Edit Profile"
                  />
                </button>
              </li>
              <li
                className="nav-item"
                role="presentation"
                onClick={() => handleViewStitch('password')}
              >
                <button
                  id="changePassword"
                  data-bs-toggle="pill"
                  data-bs-target="#changePassword-tab"
                  type="button"
                  role="tab"
                  aria-controls="pills-changePassword"
                  aria-selected="false"
                >
                  <FormattedMessage
                    id="settings.changePassword"
                    defaultMessage="Change Password"
                  />
                </button>
              </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="editProfile-tab"
                role="tabpanel"
                aria-labelledby="pills-editProfile"
              >
                {pageLoader ? (
                  <div className="list-user" style={{minHeight: pageLoader ? '55vh' : ''}}>
                    <Loader />
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-md-9">
                      <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="User name">
                            <FormattedMessage
                              id="common.firstName"
                              defaultMessage="First Name"
                            />{' '}
                            <span className="sup">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="userid"
                            value={firstName}
                            style={{ borderColor: firstNameError && 'red' }}
                            name="firstName"
                            maxLength="40"
                            onChange={handleProfileChange}
                          />
                          {firstNameError ? (
                            <p
                              className="fs-10 fw-500"
                              style={{ color: 'red' }}
                            >
                              {translate(
                                'common.required',
                                'This field is required'
                              )}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="User name">
                            <FormattedMessage
                              id="common.lastName"
                              defaultMessage="Last Name"
                            />
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="password"
                            value={lastName}
                            name="lastName"
                            maxLength="40"
                            onChange={handleProfileChange}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label htmlFor="User name">
                            <FormattedMessage
                              id="common.phoneNumber"
                              defaultMessage="Phone Number"
                            />{' '}
                            <span className="sup">*</span>
                          </label>
                          <div className="verify-btn-wrap d-flex gap-3">
                            <div
                              className={`${countryError && 'error'} code-area`}
                              onKeyDown={(e) => {
                                if (e.key === "Tab" && !e.shiftKey)
                                {
                                  e.preventDefault()
                                  numField.current.focus()
                                } 
                                handleKeyDown(e)
                              }}
                            >
                              <PhoneNumberInput
                                value={country}
                                onChange={handleMobileNumberChange}
                                validationErr={countryError}
                                numberLength={numberLength}
                               setNumberLength={setNumberLength}
                               focusRef={focusRef}
                               code={code}
                               setCode={setCode}
                              />
                            </div>
                            <div className='w-100'>
                         <div className='verify-input-container'>
                         <input
                              type="text"
                              className="form-control"
                              id="password"
                              value={phoneNo}
                              name="phoneNo"
                              ref={numField}
                              maxLength={numberLength}
                              style={{
                                borderColor:
                                  (mobileError ||
                                    invalidPhone ||
                                    phoneVerifyError) &&
                                  'red',
                              }}
                              onChange={numberChange}
                              onKeyDown={(e)=>{
                                if(e.shiftKey && e.key === "Tab"){
                                  e.preventDefault()
                                  focusRef.current.focus()
                                }
                              }}
                            />
                            {phoneVerified ? (
                              <div
                                className="badge-verified"
                              >
                                <img src={tick} alt="" />
                                  {translate('settings.verified', 'Verified')}
                              </div>
                            ) : (
                              <div>
                                <button
                                   disabled={!phoneNo}
                                  className="verify-btn loader-btn"
                                  onClick={() => handleVerify('phone')}
                                >
                                {
                                  phoneVerifyLoad?(
                                    <ButtonLoader text={translate('settings.verify', 'Verify')}/>
                                  ):(
                                    translate('settings.verify', 'Verify')
                                  )
                                }
                                </button>
                              </div>
                            )}  
                         </div>
                         {mobileError || countryError ? (
                            <p
                              className="fs-10 fw-500"
                              style={{ color: 'red' }}
                            >
                              {translate(
                                'common.required',
                                'This field is required'
                              )}
                            </p>
                          ) : null}
                          {invalidPhone ? (
                            <p
                              className="fs-10 fw-500"
                              style={{ color: 'red' }}
                            >
                              {translate(
                                'common.invalidPhone',
                                'Invalid mobile number.'
                              )}
                            </p>
                          ) : phoneVerifyError ? (
                            <p
                              className="mt-1 fs-10 fw-500"
                              style={{ color: 'red' }}
                            >
                              {translate(
                                'settings.verifyPhone',
                                'Please verify the phone number'
                              )}
                            </p>
                          ) : null}
                         </div>
                          </div>           
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label htmlFor="User name">
                            <FormattedMessage
                              id="common.emailId"
                              defaultMessage="Email Id"
                            />{' '}
                            <span className="sup">*</span>
                          </label>
                          <div className="verify-input-container">
                            <input
                              type="email"
                              className="form-control"
                              id="password"
                              value={emailId}
                              style={{
                                borderColor:
                                  (emailError ||
                                    invalidMail ||
                                    emailVerifyError) &&
                                  'red',
                              }}
                              name="emailId"
                              maxLength="50"
                              onChange={handleProfileChange}
                            />
                            {emailVerified ? (
                              <div
                              className="badge-verified"
                            >
                              <img src={tick} alt="" />
                                {translate('settings.verified', 'Verified')}
                            </div>
                            ) : (
                              <div >
                                <button
                                   disabled={!emailId}
                                  className="verify-btn loader-btn"
                                  onClick={() => handleVerify('email')}
                                >
                                  {emailVerifyLoad?
                                  <ButtonLoader text={translate('settings.verify', 'Verify')} />:
                                  translate('settings.verify', 'Verify')
                                }
                                </button>
                              </div>
                            )}
                          </div>
                          {emailVerifyError ? (
                            <p
                              className="mt-1 fs-10 fw-500"
                              style={{ color: 'red' }}
                            >
                              {translate(
                                'settings.verifyMail',
                                'Please verify the Email'
                              )}
                            </p>
                          ) : null}
                          {emailError ? (
                            <p
                              className="fs-10 fw-500"
                              style={{ color: 'red' }}
                            >
                              {translate(
                                'common.required',
                                'This field is required'
                              )}
                            </p>
                          ) : null}
                          {invalidMail ? (
                            <p
                              className="fs-10 fw-500"
                              style={{ color: 'red' }}
                            >
                              {translate(
                                'settings.validEmail',
                                'Please enter a valid email id.'
                              )}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 mt-2 mb-4">
                        <div
                          className="card"
                          style={{ cursor: 'pointer', padding: 12 }}
                          onClick={hanldeDesktopBrowse}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageSelection}
                            ref={hiddenFileInput}
                            style={{ display: 'none' }}
                            key={key}
                          />
                          <Stack direction="row" spacing={2}>
                            <div className="pic-delete-wrapper">
                          {
                            profileImg && 
                           <button
                          onClick={(e)=>{
                            e.stopPropagation()
                            removeProfilePic()}}
                          className="btn-transparent"
                        >
                          <img src={deleteIicon} alt="" />
                        </button>
                          }
                            {profileImg ? (
                              <CardMedia
                                style={{
                                  width: 50,
                                  height: 50,
                                  borderRadius: '50%',
                                  objectFit: 'cover',
                                }}
                                component="img"
                                image={profileImg}
                              />
                            ) : (
                              <Avatar
                                sx={{ width: 50, height: 50 }}
                                src={svgIc}
                              />
                            )}
                           </div>
                            <span
                              style={{
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                fontSize: 14,
                              }}
                            >
                              {
                                profileImg ?
                                <FormattedMessage
                                  id="settings.changeProfile"
                                  defaultMessage="Change profile picture (PNG, JPG, JPEG)"
                                />
                                :
                                <FormattedMessage
                                  id="settings.AddProfile"
                                  defaultMessage="Add profile picture (PNG, JPG, JPEG)"
                                />
                              }

                              <p className="fs-11 fw-500">
                                {translate(
                                  'settings.maxSize',
                                  '(Maximum file upload size : 5MB)'
                                )}
                              </p>
                            </span>
                          </Stack>
                        </div>
                      </div>
                    </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div
                className="tab-pane fade"
                id="changePassword-tab"
                role="tabpanel"
                aria-labelledby="pills-changePassword"
              >
                <form autoComplete="off">
                 <div className="row">
                 <div className="col-md-9">
                  <div className="row">
                        {/* <div className="col-md-5">
                          <div className="form-group">
                            <label htmlFor="User name">
                              <FormattedMessage
                                id="settings.currentPassword"
                                defaultMessage="Current Password"
                              />{' '}
                              <span className="sup">*</span>
                            </label>
                            <PasswordInput
                              showEye={showEye}
                              showPass={showPass}
                              password={currentPassord}
                              setShowEye={setShowEye}
                              setShowPass={setShowPass}
                              name="currentPassord"
                              id="current"
                              handlePasswordChange={handlePasswordChange}
                              validationErr={currentError}
                            />
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="User name">
                              <FormattedMessage
                                id="settings.newPassword"
                                defaultMessage="New Password"
                              />{' '}
                              <span className="sup">*</span> &nbsp;
                              <Tooltip
                              classes={{tooltip: 'info-tip'}}
                                title={
                                  <div style={{ fontSize: 13 }} className="p-3">
                                    <div className="mb-2">
                                    • <FormattedMessage
                                        id="settings.capitalLetter"
                                        defaultMessage="Start with a capital letter."
                                      />
                                    </div>
                                    <div className="mb-2">
                                    • <FormattedMessage
                                        id="settings.smallLetter"
                                        defaultMessage="At least one small letter."
                                      />
                                    </div>
                                    <div className="mb-2">
                                    • <FormattedMessage
                                        id="settings.eightChar"
                                        defaultMessage="At least 8 to 20 characters long."
                                      />
                                    </div>
                                    <div className="mb-2">
                                    • <FormattedMessage
                                        id="settings.specialChar"
                                        defaultMessage="At least one special character :!@#$"
                                      />
                                    </div>
                                    <div>
                                    • <FormattedMessage
                                        id="settings.oneNumber"
                                        defaultMessage="At least one number (0-9)"
                                      />
                                    </div>
                                  </div>
                                }
                                placement="top"
                              >
                                <i className="bi bi-question-circle info-icon info-icon-align"></i>
                              </Tooltip>
                            </label>
                            <PasswordInput
                              showEye={showEye}
                              showPass={showPass}
                              setShowEye={setShowEye}
                              setShowPass={setShowPass}
                              password={newPassword}
                              name="newPassword"
                              id="newPass"
                              handlePasswordChange={handlePasswordChange}
                              validationErr={newError}
                              placeholder={intl.formatMessage({
                                id: 'common.enterPassword', defaultMessage: 'Enter password'
                              })}
                            />
                            <PasswordStrengthBar
                              password={newPassword}
                              setStrongPassword={setStrongPassword}
                            />
                          </div>
                        </div>
                        <div></div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="User name">
                              <FormattedMessage
                                id="settings.confirmPassword"
                                defaultMessage="Confirm Password"
                              />{' '}
                              <span className="sup">*</span>
                            </label>
                            <PasswordInput
                              showEye={showEye}
                              showPass={showPass}
                              setShowEye={setShowEye}
                              setShowPass={setShowPass}
                              password={confirmNewPassword}
                              name="confirmNewPassword"
                              id="confirmPass"
                              handlePasswordChange={handlePasswordChange}
                              validationErr={confirmError}
                              placeholder={intl.formatMessage({
                                id: 'common.enterPassword', defaultMessage: 'Enter password'
                              })}
                            />
                          </div>
                        </div>
                        {passwordError && (
                          <div className="col-md-12 errorMessage">
                            {passwordError}
                          </div>
                        )}
                     
                  </div>
                  </div>
                 </div>
                </form>
              </div>
            </div>
          </div>

          <div className="common-tab-footer">
            <div className="button-wrapper">
              <button className="btn btn-outlined" onClick={handleRest}>
                {translate('common.reset', 'RESET')}
              </button>
              <button
                className="btn btn-primary loader-btn"
                disabled={loading}
                onClick={handleSaveClick}
              >
                {loading ? (
                  <ButtonLoader text={translate('common.save', 'Save')} />
                ) : (
                  <FormattedMessage id="common.save" defaultMessage="Save" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  profileDetails: state.LoginReducer.loginDetails,
  otpDetails: state.SettingsReducer.otpDetails,
  defaultCountryCode:
    state.SettingsReducer.subscriptionData.setting.defaultCountryCode,
  isVerifyLoading:state.UsersReducer.isVerifyLoading
});

const mapDispatchToProps = (dispatch) => ({
  getProfileDetails: () => dispatch(fetchUserProfileDetails()),
  validateData: (payload, handleSuccess) =>
    dispatch(validatePhoneMail(payload, handleSuccess)),
  verifyData: (id, type, data, handleSuccess) =>
    dispatch(verifyPhoneMail(id, type, data, handleSuccess)),
  changeLanguage: (locale) => dispatch(changeSystemLanguage(locale)),
  changePassword: (data, handleSuccess) =>
    dispatch(updateUserPassword(data, handleSuccess)),
  editProfileData: (data, handleSuccess) =>
    dispatch(updateProfileDetails(data, handleSuccess)),
  editSystemData: (data) => dispatch(updateClientDetails(data)),
  displaytoaster: (message, type) => dispatch(showToaster(message, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
