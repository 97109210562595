/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Tooltip } from '@mui/material';
import '../../Assets/style/table.css';
import Loader from '../Common/Loader';
import Pagination from '../Common/Pagination';
import withPermissionCheck from '../../Utils/WithUtils/WithPermission';
import { translate } from '../../Language/Translate';
import { PATIENT } from '../../Constants/feature';
import {
  updateUser,
  Fetch_Patients_Data,
  updatePatientSearchData,
  updatePatientPagination,
  updatePatientColumnSort
} from '../../Actions/usersAction';
import HeaderFields from '../Common/HeaderFeilds';
import { useIntl } from 'react-intl';
import AntMultiDatePicker from 'Components/Patient_Portal/Common/AntMultiDatePicker';
import { PATIENTS_HEADERS } from '../../Constants/constant';
import TableColumnHr from 'Components/Common/TableColumnHr';
import useDidMountEffect from 'Hooks/useDidMountEffect';
import { isEqual } from 'lodash';
import usePrevious from 'Hooks/usePrevious';
import { CREATE } from 'Constants/scopes';
import { formatDate, formatDateInDDMMYYYY, timeZoneDate } from 'Utils/DateFormatter/Date';
import EditPatientModal from './EditPatientModal';
import network from 'Utils/Api/Network';
import NoResultFound from 'Components/Common/NoResult';
import TableWrapper from 'Components/Common/TableWrpper';

const editIcon = <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.72757 7.68029C3.53419 7.88654 3.34706 8.29279 3.30963 8.57404L3.07883 10.599C2.99774 11.3303 3.52172 11.8303 4.24531 11.7053L6.25388 11.3615C6.53458 11.3115 6.92757 11.1053 7.12094 10.8928L12.2422 5.46154C13.128 4.52404 13.5272 3.45529 12.1486 2.14904C10.7763 0.855287 9.73458 1.31154 8.84881 2.24904L3.72757 7.68029Z" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.99438 3.15625C8.26261 4.88125 9.65988 6.2 11.394 6.375" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.44946 13.75H13.6775" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

let apiTimeout = null;
function Patients({
  usersList,
  searchData,
  applySearchData,
  currentPage,
  perPage,
  setColumnSort,
  columnSort,
  ACCESS,
  userDetails,
  getPatientsData,
  handlePagination
}) {
  const intl = useIntl();
  const [loading, updateLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // filter hanlde
  const [searchPatient, setSearchPatient] = useState('');
  const [patientSuggestion, setPatientSuggestion] = useState([]);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  // search  laoder
  const [patientloader, setPatientloader] = useState(false);

  // handle search value change patient
  const onChangePatientSearch = (event) => {
    const { value } = event.target;
    setPatientIsSelect(false);
    setSearchPatient(value);
  };
  const [isPatientSelect, setPatientIsSelect] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [users, setUsers] = useState([]);
  const [totalCount, SetTotalCount] = useState(null);

   // handle search value change phone
   const onChangePhoneSearch = (event) => {
    const { value } = event.target;
    setPhone(value);
  };

  // handle search value change email
  const onChangeEmailSearch = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  const isApplied = (
    (searchData?.search?.trim() !== '' && searchData?.search ||
      (searchData?.startDate !== '' && searchData?.startDate) ||
      (searchData?.endDate !== '' && searchData?.endDate) ||
      (searchData?.phone !== '' && searchData?.phone) ||
      (searchData?.email !== '' && searchData?.email)) 
  )


  // get users List
  const fetchUsers = (
    { search: searchWord, pageNo, perPageCount, startDate, endDate, email: emailSearch, phone: phoneSerach } = {},
    loading = true
  ) => {
    if (loading) updateLoading(true);
    let sort_keys = []
    Object.entries(columnSort).forEach(([key, val]) => {
      sort_keys.push(val === "asc" ? key : `-${key}`)
    });

    const config = {
      search:
        searchWord || searchWord === ''
          ? searchWord || undefined
          : searchData?.search
            ? searchData?.search
            : undefined,
      email:
            emailSearch || emailSearch === ''
              ? emailSearch || undefined
              : searchData?.email
                ? searchData?.email
                : undefined,
      phone:
                phoneSerach || phoneSerach === ''
                  ? phoneSerach?.replaceAll(' ', '')?.replaceAll('+', '') || undefined
                  : searchData?.phone
                    ? searchData?.phone?.replaceAll(' ', '')?.replaceAll('+', '')
                    : undefined,
      created_from:
        startDate || startDate === ''
          ? startDate || undefined
          : searchData?.startDate
            ? searchData?.startDate
            : undefined,
      created_to:
        endDate || endDate === ''
          ? endDate || undefined
          : searchData?.endDate
            ? searchData?.endDate
            : undefined,
      page: pageNo || currentPage,
      limit: perPageCount || perPage,
      sort: sort_keys.join(","),
    };
    getPatientsData(config, ()=>{updateLoading(false)});
  };

  useEffect(() => {
    setSearchPatient(searchData?.search ?? '');
    setPhone(searchData?.phone ?? '');
    setEmail(searchData?.email ?? '');
    setPatientIsSelect(true)
    fetchUsers({
      search: searchData?.search,
      filterDate: searchData?.filterDate,
      phone: searchData?.phone,
      email: searchData?.email
    });
  }, []);

  const prevSortVal = usePrevious(columnSort);

  useDidMountEffect(() => {
    if (!isEqual(prevSortVal, columnSort)) {
      fetchUsers({
        search: searchData?.search,
        endDate:searchData?.endDate,
        startDate:searchData?.startDate,
        phone: searchData?.phone,
        email: searchData?.email
      });
    }
  }, [columnSort]);

  //user value from redux for table data
  useEffect(() => {
    if (usersList.patients) {
      setUsers(usersList.patients);
      SetTotalCount(usersList?.page_details?.total_documents);
    } else {
      setUsers([]);
      SetTotalCount(0);
    }
  }, [usersList]);

  // api call with 3sec delay patient
  useEffect(() => {
    if (!isPatientSelect) {
      setPatientloader({
        ...patientloader,
        [searchPatient]: true,
      });
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadOptionsPatient(searchPatient);
      }, 300);
      apiTimeout = timeout;
    }
  }, [searchPatient, isPatientSelect]);

  // handle search name selection from suggestion list patient
  const handleSearchPatientSelect = (value) => {
    setSearchPatient(value);
    setPatientSuggestion([]);
    setPatientIsSelect(true);
  };

  // load options to the dropdown on typing patient search filter
  const loadOptionsPatient = async (inputValue) => {
    let fetchedOptions = [];
    if (inputValue?.trim()) {
      await network
        .get({ url: `/patients/search/patient-name/${inputValue}?status=active,inactive` })
        .then((response) => {
          const { isError, result } = response;
          if (isError) {
            return;
          } else {
            let options = result?.patients.map((item) => ({
              label: item.name,
              value: item.name,
            }));
            if (!options || !options?.length) {
              options = [{
                  value: '',
                  label: `${intl.formatMessage({
                    id: 'common.noData',
                    defaultMessage: 'No data found.',
                  })}`,
                  isDisabled: true,
              }]
            }
            fetchedOptions = options;
          }
        })
        .catch(() => { });
    }
    setPatientloader({
      ...patientloader,
      [inputValue]: false,
    });
    setPatientSuggestion(fetchedOptions);
  };

  // handle filter apply button
  const handleApplyClick = () => {
    let search_Data = { search: searchPatient, startDate, endDate, phone, email };
    applySearchData(search_Data);
    if (
      (searchPatient?.trim() !== '' && searchPatient ||
        (startDate !== '' && startDate) ||
        (endDate !== '' && endDate)) ||
        (phone !== '' && phone) ||
        (email?.trim() !== '' && email) || isApplied
    ) {
      //
      fetchUsers({
        pageNo: 1,
        search: searchPatient,
        startDate, 
        endDate,
        email,
        phone
      });
    handlePagination({currentPage:1})
    }
  };

  // to Handle Change Date filter
  const HandleChangeDate = (e) => {
    if (e) {
      setStartDate(formatDate(e[0]) ?? '')
      setEndDate(formatDate(e[1]) ?? '')
    } else {
      setStartDate('')
      setEndDate('')
    }
  };

  //handle filter reset
  const handleReset = () => {
    setStartDate('')
    setEndDate('')
    applySearchData({});
    setColumnSort({});
    setSearchPatient('');
    setPhone('');
    setEmail('');
    handlePagination({perPage:25,currentPage:1})
    if (
      searchPatient || endDate || startDate || phone || email || isApplied ||
      currentPage !== 1 ||
      perPage !== 25
    ) {
      fetchUsers({
        search: '',
        endDate:'',
        startDate:'',
        pageNo: 1,
        perPageCount: 25,
        phone: '',
        email: ''
      });
    }
  };

  // handle enter click
  const handleKeyPress = (e) => {
    if (e && e.key === 'Enter') {
      if(!isOpen){
        e.target.blur();
      }
      if (searchPatient  || startDate || endDate || phone || email) {
        handleApplyClick();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [searchPatient ,endDate,startDate ,isOpen, phone, email]);

  // on pagination change
  const handleCurrentPageChange = (pageNo) => {
    handlePagination({currentPage:pageNo})
    fetchUsers({ pageNo });
  };

  // handel perPage change
  const handlePerPageChange = (count) => {
    handlePagination({perPage:count,currentPage:1})
    fetchUsers({ pageNo: 1, perPageCount: count });
  };

  const getAge = (birthDate) =>
    Math.floor((timeZoneDate() - new Date(birthDate).getTime()) / 3.15576e10);
  // handle Edit click
  const handleEditClick = (item) => {
    setEditItem(item);
    setIsOpen(true)
  };

  // handle outside close of filter
  const handleOutsideClose = () => {
      setSearchPatient(searchData?.search);
      setStartDate(searchData?.startDate);
      setEndDate(searchData?.endDate);
      setPhone(searchData?.phone);
      setEmail(searchData?.email);
      setPatientIsSelect(true)
  }

  return (
    <div>
      <HeaderFields
        totalCount={totalCount}
        onApply={handleApplyClick}
        onReset={handleReset}
        // onAddBtnClick={ACCESS(CREATE) ? () => updateShowPopup(true) : null}
        childBreakPt={12}
        btnBreakPt={1}
        applyBtnCls={'col-sm-2'}
        breakPoint="lg"
        showFilter={openFilter}
        setShowFilter={setOpenFilter}
        isApplied={isApplied}
        handleOutsideClose={handleOutsideClose}
      >
        <div className="col-md-6 pe-md-0 mb-2 mb-md-3">
          <div className="form-group mb-0">
          <label htmlFor="">{intl.formatMessage({
                  id: 'appointments.patientName',
                  defaultMessage: 'Patient Name',
                })}</label>
            <div
              className={`search-input ${searchPatient !== '' &&
                patientloader &&
                patientloader[searchPatient] &&
                'loading'
                }`}
            >
              <input
                type="search"
                className="form-control down-arrow"
                onChange={(event) => onChangePatientSearch(event)}
                placeholder={intl.formatMessage({
                  id: 'common.search',
                  defaultMessage: 'Search',
                })}
                value={searchPatient}
                maxLength="40"
                autoComplete="off"
                onBlur={() => setTimeout(() => setPatientSuggestion([]), 400)}
                onFocus={() => {
                  if (searchPatient) loadOptionsPatient(searchPatient);
                }}
              />
            </div>
            {!isPatientSelect && searchPatient !== '' && patientSuggestion.length ? (
              <div className="customSearchList">
                <ul style={{ marginBottom: 1 }}>
                  {patientSuggestion.map((item, index) => (
                    <li
                      key={index}
                      className={`suggestion-item ${item.isDisabled ? 'disabled' : ''}`}
                      onClick={() => !item.isDisabled && handleSearchPatientSelect(item.value)}
                    >
                      {item.label}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-6 mb-2 mb-md-3 pe-md-3">
          <div className="form-group mb-0">
          <label htmlFor="">Email</label>
            <div
              className={`search-input`}
            >
              <input
                type="search"
                className="form-control down-arrow"
                onChange={(event) => onChangeEmailSearch(event)}
                placeholder={intl.formatMessage({
                  id: 'common.search',
                  defaultMessage: 'Search',
                })}
                value={email}
                maxLength="40"
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 pe-md-0">
          <div className="form-group react-dr-v2 mb-0">
          <label htmlFor="">Reg. Date</label>
            <AntMultiDatePicker
              start={startDate}
              end={endDate}
              format="DD/MM/YYYY"
              sx={{
                padding: '8px',
              }}
              onchangeDate={HandleChangeDate}
              // placeholder={["Reg. From", "Reg. To"]}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mb-0">
          <label htmlFor="">Phone</label>
            <div
              className={`search-input`}
            >
              <input
                type="search"
                className="form-control down-arrow"
                onChange={(event) => onChangePhoneSearch(event)}
                placeholder={intl.formatMessage({
                  id: 'common.search',
                  defaultMessage: 'Search',
                })}
                value={phone}
                maxLength="40"
                autoComplete="off"
              />
            </div>
          </div>
        </div>

      </HeaderFields>
      <div className="mt-3">
      <TableWrapper data={users}>
        <div className="list-user mt-3"
        style={{minHeight: loading ? '55vh' : '', overflow: !users?.length ? 'hidden' : ''}}
        >
          {users && users.length && !loading ? (
            <table className="table departments-calendar">
              <thead>
                <tr>
                  {PATIENTS_HEADERS.map((header, index) => (
                    <TableColumnHr
                      item={header}
                      key={index}
                      columnSort={columnSort}
                      setColumnSort={setColumnSort}
                      sortEnable={users.length > 1 ? true : false}
                    />
                  ))}
                </tr>
              </thead>
              <tbody>
                {users.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{(currentPage - 1) * perPage + (index + 1)}</td>
                    <td className='text-capitalize'>
                      {`${item?.name ? item.name : "-"} ${item?.last_name}`}{' '}
                      {item.user_id === userDetails.user_id && (
                        <span className="badge badge-me">
                          {translate('common.itsYou', `It's you`)}
                        </span>
                      )}
                    </td>
                    <td>{formatDateInDDMMYYYY(item.created_at)}</td>
                    <td>{item?.dob ? `${getAge(formatDate(item.dob))}` : "-"}</td>
                    <td>{item.tele_country_code ? `+${item.tele_country_code}` : "-"} {item.phone}</td>
                    <td>{item.email ? `${item.email}` :"-"}</td>
                    <td>{item.last_appt_date ? `${formatDateInDDMMYYYY(item.last_appt_date)}` : "-"}</td>
                    <td>
                      <div>
                        <div className="action-btns">
                          {ACCESS(CREATE) && (
                            <Tooltip
                              title={translate('users.editPatient', 'Edit Patient')}
                              placement="top"
                            >
                              <span className='edit-icon' onClick={() => handleEditClick(item)}>
                                {editIcon}
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : !loading ? (
                <div className='patient-portal'>
                  <NoResultFound
                    text={isApplied ? translate('common.noResult', 'No results found.') :
                      translate('patients.noDataPatients', 'No patients added')}
                    label={isApplied ? translate('common.noResultMsg', 'No results match the filter criteria Remove filter or clear all filters to show results') :
                      translate('patients.noDataPatientsMsg', `Looks like you haven't added any patients yet.`)}
                    removeMarginBottom
                    removeMarginTop />
                </div>
          ) : (
            <Loader />
          )}
        </div>
      </TableWrapper>
      </div>
      {!loading && users && users.length ? (
        <Pagination
          SetCurrentPage={handleCurrentPageChange}
          SetPerPage={handlePerPageChange}
          perPage={parseInt(perPage)}
          totalCount={totalCount}
          currentPage={currentPage}
        />
      ) : null}
      {isOpen && <EditPatientModal isOpen={isOpen} setIsOpen={setIsOpen}  item={editItem} fetchUsers={fetchUsers}/>}
    </div>
  )
}
const mapStateToProps = (state) => ({
  usersList: state.UsersReducer.patients_Data,
  userDetails: state.LoginReducer.loginDetails,
  searchData: state.UsersReducer.patient_searchData,
  perPage: state.UsersReducer.patientPaginationData.perPage,
  currentPage: state.UsersReducer.patientPaginationData.currentPage,
  columnSort: state.UsersReducer.patientColumnSort,
  isBtnLoading: state.UsersReducer.isBtnLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getPatientsData: (data, onSuccess, onError) =>
    dispatch(Fetch_Patients_Data(data, onSuccess, onError)),
  editUser: (id, data, handleSuccess, isMe) =>
    dispatch(updateUser(id, data, handleSuccess, isMe)),
  applySearchData: (data) => dispatch(updatePatientSearchData(data)),
  setColumnSort: (data) => dispatch(updatePatientColumnSort(data)),
  handlePagination:(data)=>dispatch(updatePatientPagination(data))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissionCheck(PATIENT)
)(Patients);