import React from 'react';
import '../../Assets/style/toaster.css';
import { HideToaster, ToasterTypes } from '../../Actions/toasterAction';
import { connect } from 'react-redux';
import '../../Assets/style/toaster.css';
import { translate } from 'Language/Translate';

const Toaster = ({ type, toasterMessage, closeToaster, toasterDisplay }) => {
  return (
    <div className='patient-portal'>
      <div
        className={`toast ${
          type === ToasterTypes.Success
            ? 'toast-success'
            : type === ToasterTypes.Failed
            ? 'toast-error'
            : type === ToasterTypes.Warning
            ? 'toast-warning'
            : type === ToasterTypes.Info
            ? 'toast-info'
            : ''
        } ${toasterDisplay ? 'show' : ''}`}
      >
        <div className="img-container"></div>
        <div className="content">
          <div>
            <h6>
              {type === ToasterTypes.Success
                ? translate('common.success', 'Success')
                : type === ToasterTypes.Failed
                ? translate('common.error', 'Error !')
                : type === ToasterTypes.Warning
                ? translate('common.warning', 'Warning !')
                : type === ToasterTypes.Info
                ? translate('common.info', 'Information')
                : ''}
            </h6>
            <p>{toasterMessage}</p>
          </div>
          <button className="btn-close" onClick={closeToaster}></button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closeToaster: () => dispatch(HideToaster()),
});

const mapStateToProps = (state) => ({
  toasterMessage: state.ToasterReducer.toasterMessage,
  type: state.ToasterReducer.toasterType,
  toasterDisplay: state.ToasterReducer.toasterDisplay,
});

export default connect(mapStateToProps, mapDispatchToProps)(Toaster);
