import React from 'react'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[3],
      textAlign: 'center',
      minWidth: 150,
      minHeight: 35,
      fontSize: 13,
      padding: 10,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#8d8d8d',
    },
  }));

export default LightTooltip;