import moment from 'moment';

const timeZone = localStorage.getItem('timeZone');

// Get date in new Date fromat to display in the datepicker yyyy-mm-dd
export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

// Get date in new Date fromat to dd-mm-yyyy
export const formatDateInDDMMYYYY = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('-');
};

// Get date in new Date fromat to dd/mm/yyyy
export const slashFormatinDDMMYYYY = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
};

// check to date is before from
export const isDateBefore = (date1, date2) => {
  const formattedDate1 = new Date(date1);
  const formattedDate2 = new Date(date2);

  return formattedDate1 < formattedDate2;
};

// check start time is before end
export const compareTimes = (time1, time2) => {
  const [hours1, minutes1] = time1.split(':');
  const [hours2, minutes2] = time2.split(':');

  const totalMinutes1 = parseInt(hours1) * 60 + parseInt(minutes1);
  const totalMinutes2 = parseInt(hours2) * 60 + parseInt(minutes2);

  return totalMinutes1 < totalMinutes2;
};
function getFullMonthName(monthNumber) {
  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];
  return months[parseInt(monthNumber) - 1];
}

function getMonthName(monthNumber) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return months[parseInt(monthNumber) - 1];
}

// format date to dd-Mon-yyyy format
export const dateInFormat = (date) => {
  const dateParts = date.split('-');
  const formattedDate = `${dateParts[2]}-${getMonthName(dateParts[1])}-${
    dateParts[0]
  }`;
  return formattedDate;
};
// format date to dd Mon yyyy format
export const dateInFormat2 = (date) => {
  const dateParts = date.split('-');
  const formattedDate = `${dateParts[2]} ${getMonthName(dateParts[1])} ${
    dateParts[0]
  }`;
  return formattedDate;
};
export const convertDateToFullDay = (date) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const dayIndex = date.getDay();
  return days[dayIndex];
};

export const convertDateToDay = (date) => {
  const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  const dayIndex = date.getDay();
  return days[dayIndex];
};

export const appendTime = (startTime, language = 'en-in') => {
  // const time = moment(startTime, 'HH:mm:ss');
  // return time.format('hh:mm A');

  const time = moment(startTime, 'HH:mm:ss');
  let date = new Date(time);
  return date.toLocaleTimeString(language, {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const setTimeOnCurrentDate = (time) => {
  const setTime = moment(time, 'HH:mm:ss');
  return new Date(setTime);
};
export const dateInFullMonthFormat = (date, intl = () => {}) => {
  const dateParts = date.split('-');
  const formattedDate = `${dateParts[2]}-${intl.formatMessage({
    id: `calender.${getFullMonthName(dateParts[1])}`,
    defaultMessage: `${getFullMonthName(dateParts[1])}`,
  })}-${dateParts[0]}`;
  return formattedDate;
};

// currently not use
export const convertDateToMonths = (date) => {
  const dateParts = date.split('-');
  const formattedMonths = getFullMonthName(dateParts[1]);
  return formattedMonths;
};
//  convert date format friday, December 20, 2023
export const dateinFormatDAYMonthDDYYYY = (date, intl = () => {}) => {
  const dateParts = date.split('-');
  const formattedDate = ` 
  ${intl.formatMessage({
    id: `calender.${convertDateToFullDay(new Date(date)).toLocaleLowerCase()}`,
    defaultMessage: `${convertDateToFullDay(new Date(date))}`,
  })}, 
  ${intl.formatMessage({
    id: `calender.${convertDateToMonths(date)}`,
    defaultMessage: `${convertDateToMonths(date)}`,
  })} ${dateParts[2].slice(0, 2)} ${dateParts[0]}`;
  return formattedDate;
};

//  convert date format 20, Dec 2023 10:00 PM
export const dateinFormatDDMonthYYYYTime = (date) => {
  return moment(date).locale('en').format('DD, MMM YYYY hh:mm A');
};

//  convert date format 20, Dec 2023
export const dateinFormatDDMonthYYYY = (date) => {
  return moment(date).locale('en').format('DD MMM YYYY');
};

export const timeIn12HourFormat = (time) => {
  return moment(time, 'HH:mm:ss').format('hh:mm A');
};
// get time format in 12:05 PM
export const timeIn12HourfromDate = (date) => {
  let d = dateinFormatDDMonthYYYYTime(date);
  let arr = d.split(' ');
  return `${arr[arr.length - 2]} ${arr[arr.length - 1]}`;
};

export const getTimeOfDay = (timeString) => {
  const hours = parseInt(timeString.split(':')[0], 10);
  if (hours >= 6 && hours < 12) {
    return 'morning';
  } else if (hours >= 12 && hours < 17) {
    return 'afternoon';
  } else if (hours >= 17 && hours < 20) {
    return 'evening';
  } else {
    return 'night';
  }
};

// 8 Friday, March 2024
export const formatDateto_D_WD_M_Y = (date, language) => {
  const day = date.getDate();
  const weekday = date.toLocaleString(language, { weekday: 'long' });
  const month = date.toLocaleString(language, { month: 'long' });
  const year = date.getFullYear();

  return `${day} ${weekday}, ${month} ${year}`;
};

// 08 March 2024
export const formatDateto_D_M_Y = (date, language, monthFormat = 'long') => {
  let day = date.getDate();
  if (day < 9) day = '0' + day;
  const month = date.toLocaleString(language, { month: monthFormat });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const getWeekBounds = (date) => {
  const dayOfWeek = date.getDay();
  const startDate = new Date(date);

  const diffToSunday = dayOfWeek;
  startDate.setDate(date.getDate() - diffToSunday);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  return { startDate, endDate };
};

export const getWeekDays = (date, language = 'en-US') => {
  const givenDate = new Date(date);
  // const today = timeZoneDate();
  const weekDates = [];
  const firstDayofWeek = givenDate.getDate() - givenDate.getDay();
  const sunday = new Date(givenDate.setDate(firstDayofWeek));

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(sunday);
    currentDate.setDate(sunday.getDate() + i);
    const active = formatDate(date) === formatDate(currentDate) ? true : false;
    const formattedDate = new Intl.DateTimeFormat(language, {
      day: 'numeric',
      weekday: 'short',
      month: 'short',
    }).format(currentDate);

    weekDates.push({
      date: formattedDate,
      active,
      formatedDate: formatDate(currentDate),
    });
  }

  return weekDates;
};

// 12 Monday, February 2024
export const formatDateForRoaster = (date = timeZoneDate(), language) => {
  const newDate = new Date(date);
  const options = {
    day: 'numeric',
    weekday: 'long',
    month: 'long',
    year: 'numeric',
  };

  return new Intl.DateTimeFormat(language, options).format(newDate);
};
//13:04:00 //duration in Minutes
export const isTimeBetween = (start, duration, time = timeZoneDate()) => {
  // debugger
  let startTime = setHourAndMinute(start);
  let endTime = addMinutes(startTime, duration);
  return time > startTime && time <= endTime;
};

//13:04:00
export const setHourAndMinute = (time) => {
  const [h, m, s] = time.split(':');
  const date = timeZoneDate();
  date.setHours(h, m, s, 0);
  return date;
};
function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

//isTodat
export const getIsToday = (date) => {
  let d = date;
  let today = timeZoneDate();
  today.setHours(0, 0, 0, 0);
  d.setHours(0, 0, 0, 0);
  today = today.getTime();
  d = d.getTime();
  return d === today;
};

export const dayMonthAndYear = (date, language) => {
  // const dateCopy = new Date(date);
  // const options = { day:'numeric', month: 'short', year: 'numeric' };
  // const formattedDate = dateCopy.toLocaleDateString(language, options)
  // return formattedDate;
  return moment(date).locale(language).format('DD MMM, YYYY');
};

//  convert date format 20-Dec-2023
export const dateinFormatDDMMMYYYY = (date) => {
  return moment(date).locale('en').format('DD-MMM-YYYY');
};

//  convert date format 15 Wednesday, March
export const dateinFormatDDddddMMMM = (date) => {
  return moment(date).locale('en').format('DD dddd, MMMM');
};

//target date and period in days
export const dateBetweenNotificationPeriod = (date, period = 30) => {
  let target = new Date(date);
  target.setHours(23, 59, 59);
  let current = timeZoneDate();
  let remainingDays = Math.trunc(
    (target.getTime() - current.getTime()) / (1000 * 60 * 60 * 24)
  );
  return { showAlert: remainingDays <= period, remainingDays };
};
//target date and period in days
export const daysBetweenPeriod = (date, endDate) => {
  let start = new Date(date);
  start.setHours(0, 0, 0);
  let target = new Date(endDate);
  target.setHours(0, 0, 0);
  let remainingDays = Math.trunc(
    (target.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
  );
  return remainingDays;
};
export const getWeekDaysUntilDate = (date, noOfDays) => {
  let obj = {};
  for (let i = noOfDays; i >= 0 && Object.keys(obj).length < 7; i--) {
    let start = new Date(date);
    let dte = start.getDate();
    start.setDate(dte + i);
    let weekday = start.toDateString('en-us', { weekday: 'short' });
    obj[weekday.split(' ')[0]] = true;
  }
  return obj;
};

export const timeZoneDate = () => {
  var date = new Date();
  if (timeZone) {
    return new Date(Date.parse(date.toLocaleString('en-US', { timeZone })));
  } else {
    return date;
  }
};

export const to2digitFormat=(digit)=>{
 return digit.toString().length==2 ?`${digit}`:`0${digit}`
}

export const getTimeDurationInMinutes=(start,end)=>{
  const startTime=new Date(`1070-01-01T${start}Z`)
  const endTime=new Date(`1070-01-01T${end}Z`)
  let duration=(endTime-startTime)/1000/60
  return duration
}

export const isEndDateGreater=(start,end)=>{
  const a=new Date(start)
  const b=new Date(end)
  return b.getTime() > a.getTime()
}