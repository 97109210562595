/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  FetchDoctorSlots,
  rescheduleAppointment,
} from "Actions/appointmentAction";
import ReactSelectDropDown from "Components/Common/ReactSelectDropDown";
import useDidMountEffect from "Hooks/useDidMountEffect";
import { translate } from "Language/Translate";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import {
  appendTime,
  formatDate,
  setTimeOnCurrentDate,
  timeZoneDate,
} from "Utils/DateFormatter/Date";
import ButtonLoader from "Components/Common/ButtonLoader";
import AntDatepicker from "Components/Common/AntdDatePicker";
import moment from "moment";
import CustomSwitch from "Components/Common/Switch";

const SlotBooking = ({
  selectedDoc,
  selectedDept,
  getDoctorSlots,
  doctorSlotData,
  rescheduleAppointment,
  item,
  setUpdate,
  isBtnLoading,
  applicationData,
  getAllDateAppointments,
  availableDatesLists,
  defaultDate,
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const [scheduleWithSlot, setScheduleWithSlot] = useState();
  const [schdeules, setSchedules] = useState([]);
  const [slotCount, setSlotCount] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [slotLoading, setSlotLoading] = useState(false);
  const [availableOnly, setAvailableOnly] = useState(false)

  const intl = useIntl();
  const { rescheduleAppt: rescheduleApptLoading = false } = isBtnLoading;

  useEffect(() => {
    if (defaultDate) {
      setSelectedDate(new Date(defaultDate));
    }
  }, [defaultDate, availableDatesLists]);

  useEffect(() => {
    setSelectedSlot("");
    setSlotCount(0);
    setSchedules([]);
    setSelectedPeriod("");
    setSlotLoading(true);
    getDoctorSlots(
      selectedDoc?.value,
      {
        start_date: formatDate(selectedDate),
        end_date: formatDate(selectedDate),
      },
      setSlotLoading,
      onSuccess
    );
  }, [selectedDate]);

  const onSuccess = (response) => {
    let doctorSlotData = response?.result;
    setAvailableOnly(false)
    if (doctorSlotData && doctorSlotData.op_schedule_details) {
      if (doctorSlotData?.op_schedule_details?.length) {
        const { doctor_schedule_details } =
          doctorSlotData?.op_schedule_details?.[0];
        setScheduleWithSlot(doctor_schedule_details);
        const schedule = [];
        doctor_schedule_details?.forEach((item) => {
          const currentTime = timeZoneDate();
          if (
            currentTime.toDateString() !== selectedDate.toDateString() ||
            (currentTime.toDateString() === selectedDate.toDateString() &&
              currentTime.getTime() <
                setTimeOnCurrentDate(item.end_time).getTime())
          ) {
            schedule.push({
              label: `${appendTime(item.start_time)} - ${appendTime(
                item.end_time
              )}`,
              value: item.schedule_id,
            });
          }
        });
        setSchedules(schedule);
        countSlots(doctor_schedule_details);
      } else {
        setScheduleWithSlot([]);
      }
    }
  };

  const handleReschedule = () => {
    const config = {
      data: {
        doctor_id: selectedDoc.value,
        dept_id: selectedDept.value,
        dept_name: selectedDept.label,
        schedule_type: "op",
        appt_date: formatDate(selectedDate),
        appt_start_time: selectedSlot?.start_time,
        appt_end_time: selectedSlot?.end_time,
        appt_slot_session: selectedSlot.session,
        reason: "for leave approval",
        patient_details: {
          patient_id: item.patient_id,
          name: item.patient_name,
          // last_name: item?.last_Name,
          blood_group: item?.patient_blood_group,
          email: item?.patient_email,
          dob: item?.patient_dob,
          gender: item?.patient_gender,
        },
      },
    };

    rescheduleAppointment(item?.appointment_id, config, (res) => {
      if (!res.isError) {
        getAllDateAppointments();
      }
      setUpdate((prev) => !prev);
    });
  };

  // calculate available stots number
  const countSlots = (slotData = []) => {
    let count = 0;
    slotData.forEach((item) => {
      const { slots: slotList } = item || {};
      slotList?.forEach((element) => {
        if (element.status === "available") count = count + 1;
      });
    });
    setSlotCount(count);
  };

  // handle appointment period selection
  const handlePeriodChange = (value) => {
    let slot = [];
    const data = scheduleWithSlot?.find(
      (item) => item.schedule_id === value?.value
    );
    if (data?.slots) {
      slot = data.slots?.map(item => ({
        ...item, session: `${appendTime(data?.start_time)} - ${appendTime(
          data?.end_time
        )}`
      }))
    }
    setSlots(slot);
    setSelectedSlot("");
    setSelectedPeriod(value);
  };

  // on slot selection
  const handleSlotselection = (slot) => {
    if (slot.status === "available") setSelectedSlot(slot);
  };

  const disabledDates = (current) => {
    let formatdate = new Date(current);
    let formaattedDate = formatDate(formatdate);
    return (
      current &&
      (current.isBefore(moment().subtract(1, "day")) ||
        current.isAfter(
          moment().add(applicationData?.booking_window_max_days - 1, "day")
        ) ||
        !availableDatesLists.includes(formaattedDate))
    );
  };

  return (
    <>
      <div className="d-flex">
        <div className="checkbox-container"></div>
        <div className="single-row row bb-none">
          <div className="form-group mb-0 col-3 ps-0">
            <label htmlFor="date">{translate("common.date", "Date")}</label>
            {/* <input type="date" name="" id="date" className="form-control" /> */}
            <AntDatepicker
              onInputChange={(date) => {
                setSelectedDate(new Date(date));
              }}
              value={defaultDate ? selectedDate : ""}
              className="form-control"
              disabledDate={disabledDates}
              isAdmin
            />
          </div>
          <div className="form-group mb-0 col-3 no-formgroup">
            <label htmlFor="slots">
              {slotLoading
                ? translate("common.loading", "Loading...")
                : slotCount
                ? `${intl.formatMessage({
                    id: "appointments.availableSlots",
                    defaultMessage: "Available Slots",
                  })}
                              - ${slotCount}`
                : intl.formatMessage({
                    id: "appointments.noAvailableSlots",
                    defaultMessage: "No slots available",
                  })}
            </label>
            <ReactSelectDropDown
              label={intl.formatMessage({
                id: "appointments.timeSlot",
                defaultMessage: "Time Slot",
              })}
              options={schdeules}
              value={selectedPeriod}
              onInputChange={handlePeriodChange}
              isLowerCase={true}
            />
          </div>
          {slotCount && slots?.length ? 
            <div className='toggle-filter'>
             Available Slots Only <CustomSwitch
            checkedProps={availableOnly} handleSwitchChange={(value) => setAvailableOnly(value)} 
          /> </div>: null}
        </div>
      </div>
      {slots?.length && selectedPeriod ? (
        <div className="d-flex">
          <div className="checkbox-container"></div>
          <div className="slots mt-2 mb-3">
            {slots.filter((x) => availableOnly ? x.status === 'available' : true).map((slot, slotIndex) => (
              <div
                key={slotIndex + 1}
                className={`slot ${
                  selectedSlot === slot ? "selected-slot" : ""
                } ${slot.status !== "available" ? "disabled" : ""}`}
                onClick={() => handleSlotselection(slot)}
              >
                {appendTime(slot.start_time)}
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div className="d-flex">
        <div className="checkbox-container"></div>
        <button
          disabled={!selectedSlot || rescheduleApptLoading}
          className="btn btn-primary text-uppercase notify-btn loader-btn d-flex justify-content-center"
          onClick={handleReschedule}
        >
          {rescheduleApptLoading ? (
            <ButtonLoader
              text={translate(
                "appointments.notifyReschedule",
                "Notify Patient & Reschedule"
              )}
            />
          ) : (
            translate(
              "appointments.notifyReschedule",
              "Notify Patient & Reschedule"
            )
          )}
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  doctorSlotData: state.AppointmentReducer.doctorSlotData,
  isBtnLoading: state.AppointmentReducer.isBtnLoading,
  applicationData: state.SettingsReducer.applicationData,
});

const mapDispatchToProps = {
  getDoctorSlots: (id, config, setLoader, onSuccess) =>
    FetchDoctorSlots(id, config, setLoader, onSuccess),
  rescheduleAppointment: (id, config, handleSuccess) =>
    rescheduleAppointment(id, config, handleSuccess),
};

export default connect(mapStateToProps, mapDispatchToProps)(SlotBooking);
