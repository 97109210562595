import React, { useState, useEffect, useRef } from 'react'
import { translate } from 'Language/Translate'
import { connect } from 'react-redux';
import ButtonLoader from 'Components/Common/ButtonLoader';

function ConfirmDeleteRecord({ openDeleteModal, setOpenDeleteModal, handleDeleteRecord, loading}) {
    const [openModal, setOpenModal] = useState(false);
    const [displayMode, setDisplayMode] = useState(false);
    let isFocused = false
    const deleteBtnRef = useRef()

    useEffect(() => {
        if (openDeleteModal) {
            setDisplayMode(true);
            setTimeout(() => {
                document.body.classList.add('modal-overflow');
            }, 0);
        } else {
            setOpenModal(false);
            document.body.classList.remove('modal-overflow');
        }
        setTimeout(() => {
            if (openDeleteModal) {
                setOpenModal(true);
            } else {
                setDisplayMode(false);
            }
        }, 100);
        return () => document.body.classList.remove('modal-overflow');
    }, [openDeleteModal]);

    const handleStopDelete = () => {
        setOpenDeleteModal(false)
    }

    const TabKeyHandle = (event) => {
        if (event.key === "Tab" && !event.shiftKey && openDeleteModal) {
            if (!isFocused) {
                isFocused = true
                deleteBtnRef?.current?.focus();
            }
        }
    }
    
    useEffect(() => {
        document.addEventListener('keydown', TabKeyHandle)
        return () => {
            if (!openDeleteModal) {
                isFocused = false
                document.removeEventListener('keydown', TabKeyHandle)
            }
        }
    }, [openDeleteModal])

    return (
        <>
            <div
                className={`modal custom-modal fade modal-dialog-centered confirmation-modal ${openModal ? 'show' : ''
                    }`}
                id="rescheduleConfirmModal"
                style={{ display: displayMode ? 'flex' : 'none' }}
                tabIndex="-1"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => setOpenDeleteModal(false)}
                                onKeyDown={(e) => {
                                    if (e.key === "Tab" && e.shiftKey) e.preventDefault()
                                }}
                            ></button>
                        </div>
                        <div className="modal-body text-center">
                            <h5 className="fw-600">
                                {translate(
                                    'medicalRecords.deleteMedicalRecord',
                                    'Delete Medical Record'
                                )}
                            </h5>
                            <p className="text-black">
                                {translate(
                                    'medicalRecords.deleteConfirm',
                                    'Are you sure you want to delete this medical record ?'
                                )}
                            </p>
                            <div className="confirmation-btns">
                                <button
                                    type="button"
                                    className={`btn btn-outlined-dark ${loading && 'loader-btn'}`}
                                    onClick={() => handleDeleteRecord()}
                                    disabled={loading}
                                    ref={deleteBtnRef}
                                >
                                    {' '}
                                    {
                                        loading ?
                                        <ButtonLoader
                                        text={translate('medicalRecords.continue', 'Yes, Delete')}
                                        />:
                                    <span
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#rescheduleAppointment"
                                        aria-controls="rescheduleAppointment"
                                    >
                                        {translate('medicalRecords.continue', 'Yes, Delete')}
                                    </span>
                                    }       
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={handleStopDelete}
                                    onKeyDown={(e) => {
                                        if (e.key === "Tab" && !e.shiftKey) e.preventDefault()
                                    }}
                                >
                                    {translate('medicalRecords.stop', 'No, Keep Record')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={` ${openDeleteModal && 'offcanvas-backdrop fade show'}`}
            ></div>
        </>
    )
}
const mapStateToProps = (state) => ({
    loading:state.MedicalRecordReducer.loadingAddRecord
  });

export default connect(mapStateToProps) (ConfirmDeleteRecord)