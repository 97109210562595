
import network from "./Network";
const FetchAllDepartmentList_sub_url = "/departments";
const DepartmentList_sub_url = "/department";
const EditDepartmentList_sub_url = "/department/";

const FetchAllDepartmentList = async (config) => {
  let response = null;
  let error = null;
  let displayMessage = "";
  await network
    .get({
      url: FetchAllDepartmentList_sub_url,
      data: config,
    })
    .then((res) => {
      const { isError, result, message } = res;
      if (isError) {
        error = message;
      } else {
        response = result;
        displayMessage = message;
      }
    });
  return { error, response, message: displayMessage };
};

const AddDepartmentList = async (config) => {
  let response = null;
  let error = null;
  let displayMessage = "";
  await network
    .post({ url: DepartmentList_sub_url, data: config })
    .then((res) => {
      const { isError, result, message } = res;
      if (isError) {
        error = message;
      } else {
        response = result;
        displayMessage = message;
      }
    });
  return { error, response, message: displayMessage };
};
const EditDepartmentList = async (config, id) => {
  let response = null;
  let error = null;
  let displayMessage = "";
  let errCode = ''
  await network
    .put({ url: EditDepartmentList_sub_url + id, data: config })
    .then((res) => {
      const { isError, result, message, code  } = res;
      if (isError) {
        error = message;
        errCode = code
        response = result;
      } else {
        response = result;
        displayMessage = message;
      }
    });
  return { error, response, message: displayMessage, code: errCode };
};
const DeleteDepartmentList = async (id) => {
  let response = null;
  let error = null;
  let displayMessage = "";
  await network.delete({ url: EditDepartmentList_sub_url + id }).then((res) => {
    const { isError, result, message } = res;
    if (isError) {
      error = message;
    } else {
      response = result;
      displayMessage = message;
    }
  });
  return { error, response, message: displayMessage };
};

const GetDepartmentDetails = async(id, data) => {
  let response = null;
  let error = null;
  await network.get({
    url: EditDepartmentList_sub_url + id,
    data
  })
  .then((res) => {
    const {result, isError} = res || {};
    if(!isError && result.department){
      response = result
    }else{
      error = isError
    }
  })
  return {error, response}
}

export const DepartmentsApi = {
  FetchAllDepartmentList,
  AddDepartmentList,
  EditDepartmentList,
  DeleteDepartmentList,
  GetDepartmentDetails
};
