import React from 'react';
import Pagination from 'rc-pagination';
import '../../Assets/style/pagination.css';
import { PAGINATION_PER_PAGE } from '../../Constants/constant';
import { translate } from '../../Language/Translate';

const TablePagination = (props) => {
  const { SetCurrentPage, SetPerPage, perPage, currentPage, totalCount } =
    props;

  const onChange = (current) => {
    SetCurrentPage(current);
  };
  const onShowSizeChange = (e) => {
    SetPerPage(parseInt(e.target.value));
  };
  return (
    <>
      <div className="paginationStyle">
        <Pagination
          // showQuickJumper
          // showSizeChanger
          defaultPageSize={25}
          defaultCurrent={1}
          pageSize={perPage}
          current={currentPage}
          onChange={onChange}
          total={totalCount}
          locale="en-US"
          showLessItems={true}
        />
        <div className="selectPage">
          <p>{translate('common.itemPerPage', 'Items per page')}</p>
          <select
            className="count-select"
            onChange={(e) => onShowSizeChange(e)}
          >
            {PAGINATION_PER_PAGE.map((limits, index) => {
              return (
                <option
                  key={index + 1}
                  selected={perPage === limits ? 'selected' : ''}
                >
                  {limits}
                </option>
              );
            })}
          </select>
          <span className='total-count'>{(currentPage - 1) * perPage + 1} - {Math.min(currentPage * perPage, totalCount)} of {totalCount}</span>
        </div>
      </div>
    </>
  );
};

export default TablePagination;
