import React, { useEffect, useRef, useState } from 'react'
import '../Layout/style/common.css'
import otpLogo from 'Assets/img/icons/enter-otp-main-image.svg'
import correct from 'Assets/img/icons/correct.svg'

import { translate } from 'Language/Translate'
import { connect } from 'react-redux'
import { verifyPhoneMail } from 'Actions/settingsAction';
import { useIntl } from 'react-intl'
import { timeZoneDate } from 'Utils/DateFormatter/Date'

function OtpVerification({ validateType, phone, country, email, onClose, verifyData, otp_token, userDetails, handleResend, otpDetails, showOtpLoader, resendLoader }) {
    const intl = useIntl()
    const inputRefs = useRef([]);
    const [otp, setOtp] = useState(['', '', '', '']);
    const [otpVerified, setOtpVerified] = useState('')
    const [otpError, setOtpError] = useState(false)
    const [errorMsg, setErrMsg] = useState('')
    const [countdown, setCountdown] = useState()
    const [showCounter, setShowCounter] = useState(false)
    // Resend Counter
    const [resend, setResend] = useState(0)
    const [resendCounter, setResendCounter] = useState(0)
    const [showResend, setShowResend] = useState(false)

    const { otp_limit_duration, otp_resend_limit } = otpDetails

    useEffect(() => {
        const resendDetails = JSON.parse(localStorage.getItem("Resend"))
        if (resendDetails) {
            if (validateType === "phone") {
                const { otp_verificationphn } = resendDetails
                if (otp_verificationphn) {
                    let ResendInterval = setInterval(() => remainingResendTime(otp_verificationphn, ResendInterval), 1000);
                }
            }
            else {
                const { otp_verificationEmail } = resendDetails
                if (otp_verificationEmail) {
                    let ResendInterval = setInterval(() => remainingResendTime(otp_verificationEmail, ResendInterval), 1000);
                }
            }
        } else {
            localStorage.setItem("Resend", JSON.stringify({}))
        }
    }, [validateType])

    useEffect(() => {
        let targetTime = timeZoneDate().getTime() + (otp_token?.exp * 60 * 1000) + 1000
        let Interval = setInterval(() => remainingTime(targetTime, Interval), 1000);
        return () => { clearInterval(Interval) }
    }, [otp_token])

    let remainingTime = (targetTime, Interval) => {
        let currentTime = timeZoneDate().getTime()
        let leftSecond = Math.trunc((targetTime - currentTime) / 1000)
        setCountdown(leftSecond)
        if (leftSecond <= 0) {
            clearInterval(Interval)
            setShowCounter(false)
        }
        else if (!showCounter) {
            setShowCounter(true)
        }
        return leftSecond
    }

    let remainingResendTime = (targetTime, Interval) => {
        let currentTime = timeZoneDate().getTime()
        let leftSecond = Math.trunc((targetTime - currentTime) / 1000)
        setResendCounter(leftSecond)
        if (leftSecond <= 0) {
            clearInterval(Interval)
            setShowResend(false)
            setResend(0)
        }
        else if (!showResend) {
            setShowResend(true)
        }
        return leftSecond
    }
    const enteredOtp = otp.join('')
    const handleChange = (e, index) => {
        const value = e.target.value
        if (isNaN(value) || value.trim() == "" || showOtpLoader) return;
        setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = value.slice(-1);
            return newOtp;
        })
        if (value !== '' && index < 3) {
            inputRefs.current[index + 1].focus();
        }
        setOtpVerified('')
    };
    useEffect(() => {
        setTimeout(() => {
            inputRefs.current[0].focus();
        }, 300)
    }, [])
    useEffect(() => {
        handleOtpVerification()
    }, [otp])

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
        else if (e.key === "Backspace" && !showOtpLoader) {
            setOtp((prevOtp) => {
                const newOtp = [...prevOtp];
                newOtp[index] = "";
                return newOtp;
            })
            if (index > 0) {
                inputRefs.current[index].focus();
            }
            setOtpVerified('')
        }
        if(index === 3 && e.key === "Tab" && !e.shiftKey && countdown !== 0) e.preventDefault()
        if(index === 0 && e.key === "Tab" && e.shiftKey ) e.preventDefault()
    };


    // OTP verfication success handler
    const otpResponseHandler = (message) => {
        if (otp.join('').length === 4 && otp.join('').trim() !== '') {
            if (message) {
                setErrMsg(message)
                setOtpVerified('error')
            } else {
                setOtpVerified('success')
                setOtpError(false)
                setErrMsg('')
            }
        }
    }


    // handle OTP verification
    const handleOtpVerification = () => {
        if (otp.join('').length === 4) {
            let config = {};
            if (validateType === 'phone') {
                config = {
                    data: {
                        update_data: {
                            tele_country_code: country,
                            phone: phone,
                        },
                        otp_verification: {
                            otp: enteredOtp,
                            token: otp_token.token,
                        },
                    },
                };
            } else {
                config = {
                    data: {
                        update_data: {
                            email: email,
                        },
                        otp_verification: {
                            otp: enteredOtp,
                            token: otp_token.token,
                        },
                    },
                };
            }
            verifyData(
                userDetails?.user_id,
                validateType,
                config,
                onClose,
                otpResponseHandler,
                true,
            );

        } else {
            setErrMsg('')
            setOtpVerified('')
        }
    };

    const handleResendOtp = (e) => {
        if(!e.key || (e.key === 'Enter')){
            if (!(otpVerified === 'success')) {
                handleResend()
            }
            setResend(resend + 1)
            inputRefs.current[0].focus()
            if ((resend + 1) >= otp_resend_limit) {
                let targetResendTime = timeZoneDate().getTime() + (otp_limit_duration * 60 * 1000) + 1000
                const resendlocal = JSON.parse(localStorage.getItem("Resend"))
        
                localStorage.setItem("Resend", JSON.stringify({ ...resendlocal, [validateType === "phone" ? "otp_verificationphn" : "otp_verificationEmail"]: targetResendTime }))
                let IntervalRemaining = setInterval(() => remainingResendTime(targetResendTime, IntervalRemaining), 1000);
            }
        }
        else if(e.key === "Tab" && !e.shiftKey) e.preventDefault()
    }


    const handlePaste = (e) => {
        e.preventDefault()
        e.stopPropagation()
        let val = e?.clipboardData?.getData('text').split("")
        if (val?.length) {
            val.push("", "", "", "")
            otp.reverse().map((item) => {
                if (item !== "") {
                    val.unshift(item)
                }
            })
            inputRefs?.current?.[val.indexOf("") < 3 ? val.indexOf("") : 3].focus()
            setOtp(val.splice(0, 4))
        }
        setOtpVerified('')
    };

    return (
      <div className="enter-otp-modal">
        <div className="modal-body">
          <img className="main-img" src={otpLogo} alt="" />
          <h6>
            {validateType === "phone"
              ? translate(
                  "settings.enterPhoneOtp",
                  "Please enter the One Time Password to verify your phone {phone}",
                  { phone: `+${country}${phone}` }
                )
              : translate(
                  "settings.enterEmailOtp",
                  "Please enter the One Time Password to verify your Email {email}",
                  { email }
                )}
          </h6>
          <p></p>
          {/* <p> {translate(
                    'patientPortal.otpSent',
                    'An OTP has been sent to'
                )}
                    {" "}
                    <span className="fw-bold">+{country}{phone}</span>
                    {" "}

                </p> */}

          <div
            className={`otp-theme ${otpVerified === "success" && "success"} ${
              otpVerified === "error" && "error"
            }`}
          >
            <div
              className={`otp-field ${showOtpLoader && "loading"}`}
              onPaste={(e) => handlePaste(e)}
            >
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  className="form-control otpinput"
                  // maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(ref) => (inputRefs.current[index] = ref)}
                  readOnly={otpVerified === "success"}
                  onFocus={(e) => e.target.select}
                  autoFocus={index === 0}
                />
              ))}
              {otpVerified === "success" ? (
                <img
                  className="status-icon correct icon-1"
                  src={correct}
                  alt=""
                />
              ) : (
                ""
              )}
            </div>
            <div>
              {resendCounter < 1 &&
                showCounter &&
                !(otpVerified === "success") && (
                  <>
                    {translate("patientPortal.otpExpire", "OTP will expire in")}{" "}
                    {showCounter && countdown}{" "}
                    {translate("appointments.sec", "sec")}
                  </>
                )}
            </div>
            {resendCounter < 1 &&
              countdown === 0 &&
              resend < otp_resend_limit && (
                <>
                  {resendLoader ? (
                    <a id="resend" tabIndex="0" className="resend-otp loading">
                      {translate("patientPortal.resend", "Resend OTP")}
                    </a>
                  ) : (
                    <a
                      id="resend"
                      tabIndex="0"
                      className="resend-otp"
                      onKeyDown={handleResendOtp}
                      onClick={handleResendOtp}
                    >
                      {translate("patientPortal.resend", "Resend OTP")}
                    </a>
                  )}
                </>
              )}
            {resendCounter > 0 && (
              <span className="exceeded-msg">
                {intl.formatMessage({
                  id: "commom.otpLimitMsg",
                  defaultMessage:
                    "OTP send limit exceeded please try again after 5 min",
                })}
              </span>
            )}
            <div className="d-flex align-items-center justify-content-center gap-2">
              {otpVerified === "success" && (
                <span className="success-msg">
                  {translate(
                    "patientPortal.otpVerified",
                    "OTP verified successfully!"
                  )}
                </span>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center gap-2">
              <img
                className="status-icon correct icon-2"
                src="./assets/images/icons/correct.svg"
                alt=""
              />
              {otpError && (
                <span className="error-msg">
                  {translate(
                    "patientPortal.inCorrectOtp",
                    "Incorrect OTP entered"
                  )}
                </span>
              )}
              {errorMsg && <span className="error-msg">{errorMsg}</span>}
            </div>
          </div>
        </div>
      </div>
    );
}
const mapStateToProps = (state) => ({
    otpDetails: state.SettingsReducer.otpDetails,
    showOtpLoader: state.SettingsReducer.showOtpLoader,
});
const mapDispatchToProps = (dispatch) => ({
    verifyData: (id, type, data, handleSuccess, otpResponseHandler, hideErrorToaster) =>
        dispatch(verifyPhoneMail(id, type, data, handleSuccess, otpResponseHandler, hideErrorToaster)),
})
export default connect(mapStateToProps, mapDispatchToProps)(OtpVerification)