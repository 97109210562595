import React, { useEffect } from 'react';
import '../../Assets/style/conformPopup.css';
import { translate } from '../../Language/Translate';
import ButtonLoader from 'Components/Common/ButtonLoader';
import closeIcon from '../../Assets/img/icons/closes.svg';
import deleteIcon from '../../Assets/img/icons/delete-popup.svg';
import  FocusTrap  from 'focus-trap-react';

const CancelPopup = (props) => {
  const { onConfirm, onClose, isBtnLoading = false } = props;

  useEffect(() => {
    document.body.classList.add('modal-overflow');
    return () => document.body.classList.remove('modal-overflow');
  }, []);

  return (
    <>
    <>
<FocusTrap
 focusTrapOptions={{
  clickOutsideDeactivates:true,
  initialFocus: false
}}
>
      <div className="AppointmentModal d-flex">
        <div className="modalContent delete_popup modal_xs p-20">
          <div className="modalHeader">
            <div className="container">
              <div className="row">
                <div className="col-md-12" onClick={onClose}>
                  <img className='postition-close-icon' src={closeIcon} alt="close" />
                </div>
              </div>
            </div>
          </div>
          <div className="modalContentarea-popup mt-2">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="imgDeleteWrap mb-2">
                    <img src={deleteIcon} alt="close" />
                  </div>
                </div>
              </div>
              <div className="row mt-2 justify-content-center">
                <div className="col-md-12 text-center">
                  <p className='label-text label'>
                  {translate(
                    'appointments.doYouWantToCancel',
                    'Are you sure you want to cancel the Appointment?'
                  )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="container">
              <div className="row mt-1">
                <div className="col-md-12">
                  <ul className="modalBtm-popup d-flex justify-content-center simple-modal-footer">
                    <li className='ps-0' onClick={isBtnLoading ? () => { } : onConfirm}>
                      {' '}
                      <button
                        type="button"
                        className="btn fit-content px-4 loader-btn btn-outlined-danger"
                        id="save"
                        disabled={isBtnLoading}
                      >
                        {isBtnLoading ? <ButtonLoader text={translate('medicalRecords.continue', 'Yes, Delete')} /> :
                          translate('common.yesCancel', 'YES, CANCEL')
                        }
                      </button>
                    </li>
                    <li onClick={onClose}>
                      <button
                        type="button"
                        className="btn btn-primary fit-content px-4"
                        id="cancel"
                      >
                        {' '}
                        {translate('common.no', 'NO')}{' '}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</FocusTrap>
    </>
  </>
  );
};
export default CancelPopup;
