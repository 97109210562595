import React, { useRef } from 'react';

const OtpForm = ({ setOtp, otpData, otp, validatinErr }) => {
  const { len = 4 } = otpData || {};
  const inputRefs = useRef([]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (value === ' ' || isNaN(value)) return;
    else if (value.slice(-1) && index < 4) {
      inputRefs?.current[index + 1]?.focus();
      setOtp(value.slice(-1), index);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      setOtp('', index);
      if (index > 0) {
        inputRefs.current[index].focus();
      }
      if (index > 0 && inputRefs.current[index].value === '') {
        inputRefs.current[index - 1].focus();
      }
    } else if (e.key === 'ArrowRight') {
      inputRefs?.current[index + 1]?.focus();
    } else if (e.key === 'ArrowLeft') {
      inputRefs?.current[index - 1]?.focus();
      if (index > 0) inputRefs.current[index - 1].selectionStart = 2;
    }
  };

  const handlePaste = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let val = e?.clipboardData?.getData('text').split("")
    const len = otp?.indexOf("") !== -1 ? 
    otp?.indexOf("") :
     otp.length;
    if (val?.length) {
      val?.slice(0, 4).map((item, index) => {
        setOtp(item, len + index)
      })
      inputRefs?.current?.[len + val.length < 3 ?
        len + val.length
        : 3]?.focus()
    }
  };

  return (
    <div>
      <div
        className={`otp otp-theme pt-0 ${validatinErr ? 'error' : ''}`}
        style={{
          padding: 8,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className="otp-field" onPaste={(e) => handlePaste(e)}>
          {Array.from({ length: len }, (v, i) => i).map((digit, index) => (
            <input
              key={index}
              type="text"
              className="form-control otpinput"
              value={otp[index] || ''}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(ref) => (inputRefs.current[index] = ref)}
              onFocus={(e) => e.target.select}
              autoFocus={index === 0}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OtpForm;
