import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import '../../Assets/style/table.css';
import '../../Assets/style/styles.css';
import Navbar from './Navbar';
import Header from './Header';
import Toaster from '../Common/Toaster';
import { Close } from '@mui/icons-material';
import { setSubscriptionData } from 'Actions/settingsAction';
import { dateBetweenNotificationPeriod } from 'Utils/DateFormatter/Date';
import { SUBSCRIPTION } from 'Constants/feature';
import { SETTINGS_UPDATE } from 'Constants/scopes';
import SunscriptionExpiry from '../Subscription/SubscriptionModal';
import { translate } from 'Language/Translate';
import { getSassBaseURL } from 'Utils/Api/ApiInstance';
import { checkAppPermission } from 'Utils/Permissions/Permissions';

let interval = null;

const HomeLayout = (props) => {
  const [showToggle, setShowToggle] = useState(false);
  const [showExpiry, setShowExpiry] = useState(false);
  const {
    // toasterDisplay,
    subsriptionData,
    updateSubcriptionData,
    subscriptionFailed,
    children
  } = props;

  const permissions = useSelector(
    (state) => state.LoginReducer.userPermissions.ResourceNameScope
  );

  const checkpermission = (feature, scope) => {
    const permission = permissions?.[feature];
    if (!permission || !permission?.length) {
      return false;
    } else {
      return permission.includes(scope);
    }
  };

  useEffect(() => {
    if (
      !checkAppPermission(subsriptionData)
    ) {
      setShowExpiry(true);
    } else {
      setShowExpiry(false);
    }
  }, [subsriptionData]);

  useEffect(() => {
    document.body.classList.add('page-transition');
    setTimeout(()=> {document.body.classList.remove('page-transition')}, 1000)
  }, [children])

  // get the subscription renewal URL
  const fetchApi = async (subId) => {
    fetch(`${getSassBaseURL()}/appnest/api/customer/subscription-session`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        subscription_id: subId,
        returnUrl: window.location.origin + '#/admin/appointments',
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        updateSubcriptionData({ ...subsriptionData, ...res.data });
      })
      .catch(() => {
        console.log('Failed');
      });
  };

  const refreshSubscriptionSettingsUrl = () => {
    clearInterval(interval);
    interval = setInterval(() => {
      fetchApi(subsriptionData.id);
    }, 5 * 60 * 1000);
  };

  useEffect(() => {
    if (subsriptionData?.id) {
      if (
        (!checkAppPermission(subsriptionData) ||
          subsriptionData.showAlert) &&
        checkpermission(SUBSCRIPTION, SETTINGS_UPDATE)
      ) {
        fetchApi(subsriptionData.id);
        refreshSubscriptionSettingsUrl();
      }
    }
  }, [permissions]);


  return (
    <>
      <div className={showToggle ? 'sideBarToggle' : 'sideBarNotToggle'}>
        <div className="content-section clearfix ">
          <Navbar showToggle={showToggle} setShowToggle={setShowToggle} />
          <div className="employee-dashboard ">
            <Header showToggle={showToggle} setShowToggle={setShowToggle} />
          </div>
          <div className="content-area clearfix" onKeyDown={subscriptionFailed||showExpiry ?(e)=>{e.preventDefault()}:null}>
            {showExpiry || subscriptionFailed ? <SunscriptionExpiry  /> : null}
            {subsriptionData?.showAlert &&
            checkpermission(SUBSCRIPTION, SETTINGS_UPDATE) ? (
              <div className=" expiryNotification info mb-3">
                <div className="d-flex gap-2 justify-content-between align-items-center">
                  <div className="content-wrap">
                    <div className="icon-block"></div>
                    <p>
                      {translate(
                        dateBetweenNotificationPeriod(
                          subsriptionData?.subscription_end_date
                        ).remainingDays >= 1
                          ? 'common.expiryNotification'
                          : 'common.expiryNotificationToday',
                        dateBetweenNotificationPeriod(
                          subsriptionData?.subscription_end_date
                        ).remainingDays >= 1
                          ? `Subscription for {appName} is expiring within {date} day(s). If you wish to renew your subscription, simply`
                          : `Subscription for {appName} is expiring Today. If you wish to renew your subscription, simply`,
                        {
                          appName: subsriptionData?.branding?.brandName,
                          date: dateBetweenNotificationPeriod(
                            subsriptionData?.subscription_end_date
                          ).remainingDays,
                        }
                      )}{' '}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={subsriptionData?.session_url}
                      >
                        {translate('common.clickLink', 'click this link')}.
                      </a>
                    </p>
                  </div>
                  <Close
                    className="cursor-pointer close-svg"
                    onClick={() => {
                      clearInterval(interval);
                      updateSubcriptionData({
                        ...subsriptionData,
                        showAlert: false,
                      });
                    }}
                  />
                </div>
              </div>
            ) : null}
            <div className="content-wrapper" tabIndex='-1'>{children}</div>
          </div>
          { <Toaster />}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateSubcriptionData: (payload) => dispatch(setSubscriptionData(payload)),
});
const mapStateToProps = (state) => ({
  subsriptionData: state.SettingsReducer.subscriptionData,
  toasterDisplay: state.ToasterReducer.toasterDisplay,
  subscriptionFailed: state.SettingsReducer.subscriptionFailed,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeLayout);
