import { DOCTOR_BTN_LOADING,OVERRIDE_SLOT_LOADING } from "Actions/doctorsAction";
import { RECEIVE_SEARCH_DATA,
  CLEAR_SEARCH_DATA,
  UPDATE_DOCTORLIST_CURRENT_PAGE,
  UPDATE_DOCTORLIST_PER_PAGE,
  SET_DOCTORLIST_COLUMN_SORT
} from "../Actions/doctorListingAction"
const initialState = {
 searchData:{
    searchValue:'',
    selectedDepartStatue:'',
 },
 paginationData:{
   perPage:25,
   currentPage:1
 },
 columnSort:{doctor_status : 'asc'},
 isBtnLoading : false,
 overrideSlotLoading: false
}

const doctorListingReducer = (state = initialState,action)=>{
    switch(action.type){
        case RECEIVE_SEARCH_DATA: {
            return {
              ...state,
              searchData: action.payload,
            };
          }
        case UPDATE_DOCTORLIST_PER_PAGE: {
            return {
              ...state,
             paginationData:{
               ...state.paginationData,
               perPage:action.payload
             }
            };
          }
        case UPDATE_DOCTORLIST_CURRENT_PAGE: {
          return {
            ...state,
           paginationData:{
             ...state.paginationData,
             currentPage:action.payload
           }
          };
          }
          case CLEAR_SEARCH_DATA: {
            return {
              ...state,
              paginationData:{
                perPage:25,
                currentPage:1
              },
              searchData: {},
            };
          }
          case SET_DOCTORLIST_COLUMN_SORT:{
            return {
              ...state,
              columnSort:action.payload
            };
          }
          case DOCTOR_BTN_LOADING: {
            return {
              ...state,
              isBtnLoading: action.payload,
            };
          }
          case OVERRIDE_SLOT_LOADING: {
            return {
              ...state,
              overrideSlotLoading: action.payload,
            };
          }
          default:{
              return {
                  ...state
              }
          }
    }
}
export default doctorListingReducer