import { translate } from 'Language/Translate';
import { getSassBaseURL } from 'Utils/Api/ApiInstance';
import network from '../Utils/Api/Network';
import { setLocalStorageItem } from '../Utils/LocalStorage/local-storage';
import { convertToBase64 } from '../Utils/Services/Authenticator';
import { receivePatientDetails, receiveUserDetails } from './loginAction';
import { showToaster, ToasterTypes } from './toasterAction';
import {verifyBtnLoading} from './usersAction';

export const CHANGE_APP_LANGUAGE = 'CHANGE_APP_LANGUAGE';
export const RECEVIE_USER_PROFILE_DETAILS = 'RECEVIE_USER_PROFILE_DETAILS';
export const RECEIVE_APPLICATION_DETAILS = 'RECEIVE_APPLICATION_DETAILS';
export const SET_SUBSCRIPTION_DATA = 'SET_SUBSCRIPTION_DATA';
export const CHANGE_LOGO = 'CHANGE_LOGO';
export const SHOW_EXPIRED_SUBSCRIPTION = 'SHOW_EXPIRED_SUBSCRIPTION';
export const SETTING_BTN_LOADING = 'SETTING_BTN_LOADING';
export const UPDATE_APPLICATION_DETAILS = 'UPDATE_APPLICATION_DETAILS';
export const PROFILE_IMG_BTN_LOADING = 'PROFILE_IMG_BTN_LOADING'
export const CHANGE_FAVICON = 'CHANGE_FAVICON'
export const OTP_LOADER='OTP_LOADER'
export const showSubscriptionExpired = () => ({
  type: SHOW_EXPIRED_SUBSCRIPTION,
});

export const changeSystemLanguage = (payload) => ({
  type: CHANGE_APP_LANGUAGE,
  payload,
});

export const storeApplicationDetails = (payload) => ({
  type: RECEIVE_APPLICATION_DETAILS,
  payload,
});

export const updateApplicationDetails = (payload) => ({
  type: UPDATE_APPLICATION_DETAILS,
  payload,
});

const receiveUserProfileDetails = (payload) => ({
  type: RECEVIE_USER_PROFILE_DETAILS,
  payload,
});
export const setSubscriptionData = (payload) => ({
  type: SET_SUBSCRIPTION_DATA,
  payload,
});

export const changeLogo = () => ({
  type: CHANGE_LOGO,
});

export const changeFavicon = () => ({
  type: CHANGE_FAVICON,
});

export const settingBtnLoading = (payload) => ({
  type: SETTING_BTN_LOADING,
  payload,
});

export const profileImgBtnLoading = (payload) => ({
  type: PROFILE_IMG_BTN_LOADING,
  payload,
});
export const showOtpLoder = (payload) => ({
  type: OTP_LOADER,
  payload,
});

export const fetchUserProfileDetails = (isUpdate = false, isLoading=()=>{}) => {
  return (dispatch) => {
    return network.get({ url: '/user/profile' }).then((response) => {
      const { isError, result } = response;
      if (isError) {
        dispatch(receiveUserProfileDetails({}));
      } else {
        dispatch(receiveUserProfileDetails(result));
        const { client_settings, user_details, patient_details } = result;
        const { client_details } = client_settings || {};
        dispatch(receiveUserDetails(user_details || {}));
        dispatch(receivePatientDetails(patient_details || {}));
        if (isUpdate) dispatch(updateApplicationDetails(client_details));
      }
      isLoading(false)
    });
  };
};

export const updateClientDetails = (data, successAction) => {
  return (dispatch) => {
    dispatch(settingBtnLoading(true));
    return network.put({ url: `/client-settings?settings_type=${data.type}`, data:data.payload }).then((response) => {
      dispatch(settingBtnLoading(false));
      const { isError, message } = response;
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        successAction();
        dispatch(showToaster(message, ToasterTypes.Success));
        dispatch(fetchUserProfileDetails(true));
      }
    });
  };
};

export const updateProfileDetails = (
  data,
  handleSuccess = () =>{},
  handleNavigation = () => {}
) => {
  return (dispatch) => {
    dispatch(profileImgBtnLoading(true))
    return network.put({ url: '/user/profile', data }).then((response) => {
      dispatch(profileImgBtnLoading(false))
      const { isError, message, result } = response;
      handleSuccess(false);
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        dispatch(showToaster(message, ToasterTypes.Success));
        dispatch(receiveUserDetails(result.user_details || {}));
        const base64 = convertToBase64(result.user_details);
        setLocalStorageItem('user', base64);
        handleNavigation();
      }
    });
  };
};

export const updateUserPassword = (data, handleSuccess = () => {}) => {
  return (dispatch) => {
    return network.put({ url: '/user/password', data }).then((response) => {
      const { isError, message } = response;
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        dispatch(showToaster(message, ToasterTypes.Success));
        handleSuccess();
      }
    });
  };
};

export const validatePhoneMail = ( payload,handleSuccess = () => {}) => {
  return (dispatch) => {
    return network
      .post({ url: `/user/${payload?.id}/update/user-identifier/${payload?.type}/validate`, data:payload?.data })
      .then((response) => {
        const { isError, message, result } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          dispatch(showToaster(message, ToasterTypes.Success));
          handleSuccess(result);
        }
      });
  };
};

export const verifyPhoneMail = (
  id,
  type,
  data,
  handleSuccess = () => {},
  otpResponseHandler = () => {},
  hideErrorToaster = false
) => {
  return (dispatch) => {
        dispatch(showOtpLoder(true))
        dispatch(verifyBtnLoading(true))
    return network
      .put({ url: `/user/${id}/update/user-identifier/${type}/verify`, data })
      .then((response) => {
        const { isError, message } = response;
        dispatch(showOtpLoder(false))
        dispatch(verifyBtnLoading(false))
        if (isError) {
          otpResponseHandler(message);
          if(!hideErrorToaster){
            dispatch(showToaster(message, ToasterTypes.Failed));
          }
        } else {
        
          dispatch(showToaster(message, ToasterTypes.Success));
          otpResponseHandler();
          handleSuccess(type);
        }
      });
  };
};

export const updateLocations = (data, successAction) => {
  return (dispatch) => {
    dispatch(settingBtnLoading(true));
    return network.put({ url: '/branch', data }).then((response) => {
      dispatch(settingBtnLoading(false));
      const { isError, message } = response;
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        successAction();
        dispatch(showToaster(message, ToasterTypes.Success));
      }
    });
  };
};

export const updateReminderDetails = (data, successAction = () => { }) => {
  return (dispatch) => {
    dispatch(settingBtnLoading(true));
    return network.put({ url: '/notification-timing', data }).then((response) => {
      dispatch(settingBtnLoading(false));
      const { isError, message } = response;
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        successAction();
        dispatch(showToaster(message, ToasterTypes.Success));
      }
    });
  };
};

export const updateSystemSettings = (
  customerId,
  subscriptionId,
  data,
  getAppnestConfiguration
) => {
  return (dispatch) =>
    fetch(
      `${getSassBaseURL()}/appnest/api/customer/${customerId}/subscription-settings/${subscriptionId}`,
      {
        method: 'POST',
        body: data,
      }
    )
      .then((res) => res.json())
      .then((res) => {
        const { message, success } = res;
        if (success) {
          // dispatch(showToaster(message, ToasterTypes.Success));
          getAppnestConfiguration();
        } else {
          dispatch(showToaster(message, ToasterTypes.Failed));
        }
      })
      .catch(() =>
        dispatch(
          showToaster(
            translate(
              'common.wentWrong',
              'There was an error processing your request. Please try again'
            ),
            ToasterTypes.Failed
          )
        )
      );
};
