/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactSelectDropDown from '../../Common/ReactSelectDropDown';
import { LEAVE_TYPES, LEAVE_TYPE_OPTIONS } from '../../../Constants/constant';
import {
  compareTimes,
  formatDate,
  isDateBefore,
  isEndDateGreater,
  timeZoneDate,
} from '../../../Utils/DateFormatter/Date';
import { FormattedMessage } from 'react-intl';
import AntDatepicker from 'Components/Common/AntdDatePicker';
import { translate } from 'Language/Translate';
import moment from 'moment';

const AddLeaves = (props) => {
  const {
    setLeaveDetails,
    leaveDetails,
    doctorList,
    deptOptions,
    isEditing,
    intl,
    feildError,
    setFeildError,
    executePermission,
  } = props;
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [selectedDept, setselectedDept] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [leaveEndDate, setLeaveEndDate] = useState('');
  const {
    doctorName,
    doctorId,
    // department,
    from,
    to,
    leaveType,
    startTime,
    endTime,
    reason,
  } = leaveDetails || {};

  useEffect(()=>{
    if(isEditing){
      setLeaveEndDate(to)
    }
  },[])

  const {
    doctorError,
    fromError,
    toError,
    leaveTypeError,
    startError,
    endError,
    reasonError,
    invalidLeave,
    invalidTime,
  } = feildError || {};

  useEffect(() => {
    if (doctorList && doctorList.length) {
      const options = doctorList.map((doctor) => ({
        label: `Dr. ${doctor.name} ${doctor.last_name}`,
        value: doctor.doctor_id,
      }));
      setDoctorOptions(options);
    }
  }, [doctorList]);

  useEffect(() => {
    if (isEditing) {
      const selectedType = LEAVE_TYPE_OPTIONS.find(
        (type) => type.value === leaveType
      );
      setSelectedType(selectedType);
      setSelectedDoctor({ label: `Dr. ${doctorName}`, value: doctorId });
    }
    if(leaveType){
      const selectedType = LEAVE_TYPE_OPTIONS.find(
        (type) => type.value === leaveType
      );
      setSelectedType(selectedType);
    }
  }, []);


  const formatTo2Digits = (val) => {
    return val.toString().length < 2 ? `0${val}` : val;
  };

  const disabledToDates= (current) => {
    return (
      current && leaveDetails.from?
      current.isBefore(moment(leaveDetails.from)):false
    )
  }

  const disabledFromDates= () => {
      if (isEditing && (leaveEndDate === leaveDetails.to)) {
        return false
      } 
      // else {
      //   return (
      //     current &&leaveDetails.to ?
      //     !current.isBefore(moment(leaveDetails.to).add(1, 'day')) :false
      //     // !(current < moment(leaveDetails.to)):false
      //   )
      // }
  }

  const getTimeOptions = () => {
    let options = [];
    let minutes = ['00', '30'];
    for (let h = 0; h <= 23; h++) {
      minutes.map((min) => {
        let formattedH = formatTo2Digits(h % 12 === 0 ? 12 : h % 12);
        let label = `${formattedH}:${min} AM`;
        if (h >= 12) {
          label = `${formattedH}:${min} PM`;
        }
        options.push({
          value: `${formatTo2Digits(h)}:${min}:00`,
          label: label,
        });
        return min;
      });
    }
    return options;
  };

  const formatToOption = (time) => {
    if (time) {
      let d = timeZoneDate().setHours(time.split(':')[0], time.split(':')[1], 0);
      let label = new Date(d).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return { value: time, label };
    } else {
      return null;
    }
  };

  // handle reason change
  const handleInputChange = (event) => {
    const { value, name } = event.target;
    setLeaveDetails((prev) => ({ ...prev, [name]: value }));
    setFeildError((prev) => ({
      ...prev,
      reasonError: false,
    }));
  };

  // onDropdown select
  const handleDropdownChange = (value, item) => {
    setLeaveDetails((prev) => ({ ...prev, [item]: value?.value || '' }));
    if (item === 'department') {
      setselectedDept(value);
      const filterdDoctors = doctorList.filter((doctor) =>
        doctor.departments.some((dept) => dept.dept_id === value?.value)
      );
      const options = value
        ? filterdDoctors.map((doctor) => ({
          label: `Dr. ${doctor.name} ${doctor.last_name}`,
          value: doctor.doctor_id,
        }))
        : doctorList.map((doctor) => ({
          label: `Dr. ${doctor.name} ${doctor.last_name}`,
          value: doctor.doctor_id,
        }));
      setDoctorOptions(options);
      setSelectedDoctor([]);
    } else if (item === 'doctorId') {
      setSelectedDoctor(value);
      setFeildError((prev) => ({
        ...prev,
        doctorError: false,
      }));
    } else if (item === 'leaveType') {
      setSelectedType(value);
      setFeildError((prev) => ({
        ...prev,
        leaveTypeError: false,
      }));
    } else if (item === 'startTime') {
      setFeildError((prev) => ({
        ...prev,
        startError: false,
      }));
      if (value.value && endTime && !compareTimes(endTime, value.value)) {
        setFeildError((prev) => ({
          ...prev,
          invalidTime: false,
        }));
      }
    } else if (item === 'endTime') {
      setFeildError((prev) => ({
        ...prev,
        endError: false,
      }));
      if (value.value && startTime && !compareTimes(value.value, startTime)) {
        setFeildError((prev) => ({
          ...prev,
          invalidTime: false,
        }));
      }
    }
  };

  return (
    <>
      <div className="container-main">
        <div className="mt-3"></div>

        <div className="row">
          {executePermission && !isEditing && (
            <div className="col-md-6">
              <div className="form-group"
              onKeyDown={(e)=>{
                if(e.key === "Tab" && e.shiftKey) e.preventDefault()
              }}
              >
                <label>
                  <FormattedMessage
                    id="departments.department"
                    defaultMessage="Department"
                  />
                </label>
                <ReactSelectDropDown
                  label={intl.formatMessage({
                    id: 'departments.department',
                    defaultMessage: 'Department',
                  })}
                  options={deptOptions}
                  value={selectedDept}
                  onInputChange={(value) =>
                    handleDropdownChange(value, 'department')
                  }
                  isClearable
                  isDisabled={isEditing}
                  isCasePlaceholder={true}
                  autoFocus
                />
              </div>
            </div>
          )}
          <div className="col-md-6">
            <div className="form-group">
              <label>
                <FormattedMessage
                  id="leaveApproval.doctorName"
                  defaultMessage="Doctor Name"
                />{' '}
                <span className="sup">*</span>
              </label>
              {executePermission ? (
                <ReactSelectDropDown
                  label={intl.formatMessage({
                    id: 'leaveApproval.doctor',
                    defaultMessage: 'Doctor',
                  })}
                  options={doctorOptions}
                  value={selectedDoctor}
                  onInputChange={(value) =>
                    handleDropdownChange(value, 'doctorId')
                  }
                  isDisabled={isEditing}
                  validationErr={doctorError}
                  isCasePlaceholder={true}
                  customClass="mb-0"
                />
              ) : (
                <input
                  className="form-control"
                  name="doctorName"
                  value={doctorName}
                  disabled
                />
              )}
              {doctorError ? (
                <p className="fs-10 fw-500 no-height" style={{ color: 'red' }}>
                  {translate('common.required', 'This field is required')}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="form-group">
              <label>
                <FormattedMessage
                  id="leaveApproval.leaveDate"
                  defaultMessage="Leave Date"
                />
                <span className="sup">*</span>
              </label>
              <div className="row">
                <div
                  className={`col-md-6 mb-4 mb-md-0 ${fromError && 'validation-error'
                    }`}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e)=>{
                    if(e.key === "Tab" && e.shiftKey && isEditing) e.preventDefault()
                  }}
                >
                  <AntDatepicker
                    onInputChange={(date) => {
                      setLeaveDetails((prev) => ({
                        ...prev,
                        from: formatDate(date),
                        ...((!to || (to  && !isEndDateGreater(formatDate(date),to)))?{to:formatDate(date)}:{})
                      }));
                      setFeildError((prev) => ({
                        ...prev,
                        fromError: false,
                        toError: false,
                      }));
                      if (
                        formatDate(date) &&
                        // to &&
                        !isDateBefore(to, formatDate(date))
                      ) {
                        setFeildError((prev) => ({
                          ...prev,
                          invalidLeave: false,
                        }));
                      }
                    }}
                    value={from}
                    placeholder={intl.formatMessage({
                      id: 'common.from',
                      defaultMessage: 'From',
                    })}
                    className="form-control"
                    disabledPastDate={!executePermission}
                    disabledDate={disabledFromDates}
                    isAdmin
                    validationErr={fromError || invalidLeave}
                    autofocus={isEditing}
                  />
                  {fromError ? (
                    <p
                      className="fs-10 fw-500 no-height"
                      style={{ color: 'red' }}
                    >
                      {translate('common.required', 'This field is required')}
                    </p>
                  ) : null}
                  {invalidLeave ? (
                    <p
                      className="fs-10 fw-500 no-height"
                      style={{ color: 'red' }}
                    >
                      {translate(
                        'leaveApproval.invalidDate',
                        'Please provide valid leave dates'
                      )}
                    </p>
                  ) : null}
                </div>
                <div
                  className={`col-md-6 date-margin v2 ${toError && 'validation-error'
                    }`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <AntDatepicker
                    onInputChange={(date) => {
                      setLeaveDetails((prev) => ({
                        ...prev,
                        to: formatDate(date),
                      }));
                      setFeildError((prev) => ({
                        ...prev,
                        toError: false,
                      }));
                      if (
                        formatDate(date) &&
                        from &&
                        !isDateBefore(formatDate(date), from)
                      ) {
                        setFeildError((prev) => ({
                          ...prev,
                          invalidLeave: false,
                        }));
                      }
                    }}
                    value={to}
                    placeholder={intl.formatMessage({
                      id: 'common.to',
                      defaultMessage: 'To',
                    })}
                    className="form-control"
                    disabledPastDate={!executePermission}
                    disabledDate={disabledToDates}
                    isAdmin
                    validationErr={toError || invalidLeave}
                  />
                  {toError ? (
                    <p
                      className="fs-10 fw-500 no-height"
                      style={{ color: 'red' }}
                    >
                      {translate('common.required', 'This field is required')}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="form-group">
              <label>
                <FormattedMessage
                  id="leaveApproval.leaveType"
                  defaultMessage="Leave Type"
                />{' '}
                <span className="sup">*</span>
              </label>
              <ReactSelectDropDown
                label={intl.formatMessage({
                  id: 'common.select',
                  defaultMessage: 'Select',
                })}
                value={selectedType}
                onInputChange={(value) =>
                  handleDropdownChange(value, 'leaveType')
                }
                options={LEAVE_TYPE_OPTIONS}
                validationErr={leaveTypeError}
                isCasePlaceholder={true}
                customClass="mb-0"
              />
              {leaveTypeError ? (
                <p className="fs-10 fw-500 no-height" style={{ color: 'red' }}>
                  {translate('common.required', 'This field is required')}
                </p>
              ) : null}
            </div>
          </div>
          {leaveType && leaveType === LEAVE_TYPES.custom && (
            <div className="col-md-7">
              <div className="form-group">
                <label>
                  <FormattedMessage id="common.time" defaultMessage="Time" />
                  <span className="sup">*</span>
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <ReactSelectDropDown
                      label="HH:MM"
                      options={getTimeOptions()}
                      excludeValueFilter
                      onInputChange={(value) =>
                        handleDropdownChange(value, 'startTime')
                      }
                      value={formatToOption(startTime)}
                      validationErr={startError || invalidTime}
                      customClass="mb-0"
                    />
                    {startError ? (
                      <p
                        className="fs-10 fw-500 no-height"
                        style={{ color: 'red' }}
                      >
                        {translate('common.required', 'This field is required')}
                      </p>
                    ) : null}
                    {invalidTime ? (
                      <p
                        className="fs-10 fw-500 no-height"
                        style={{ color: 'red' }}
                      >
                        {translate(
                          'leaveApproval.invalidTime',
                          'Please provide valid leave time'
                        )}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6 date-margin">
                    <ReactSelectDropDown
                      label="HH:MM"
                      options={getTimeOptions()}
                      excludeValueFilter
                      onInputChange={(value) =>
                        handleDropdownChange(value, 'endTime')
                      }
                      value={formatToOption(endTime)}
                      validationErr={endError || invalidTime}
                      customClass="mb-0"
                    />
                    {endError ? (
                      <p
                        className="fs-10 fw-500 no-height"
                        style={{ color: 'red' }}
                      >
                        {translate('common.required', 'This field is required')}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {leaveType !== LEAVE_TYPES.custom && <div className="row mt-1"></div>}
        <div className="row">
          <div className="col-md-12 form-group">
            <label>
              <FormattedMessage id="common.reason" defaultMessage="Reason" />
              <span className="sup">*</span>
            </label>
            <div className="text-area-wrap">
            <textarea
              className="form-control input"
              rows={leaveType === LEAVE_TYPES.custom ? '2' : '4'}
              style={{
                height: 'auto !imp',
                resize: 'none',
                borderColor: reasonError && 'red',
              }}
              name="reason"
              maxLength="100"
              value={reason}
              onChange={handleInputChange}
            />
                      </div>
            
            {reasonError ? (
              <p className="fs-10 fw-500 no-height" style={{ color: 'red' }}>
                {translate('common.required', 'This field is required')}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      {leaveType !== LEAVE_TYPES.custom && <div className="row mt-3"></div>}
    </>
  );
};
export default memo(AddLeaves) ;
