/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import Loader from 'Components/Common/Loader';
import Error404 from 'Assets/img/icons/404-img.svg';
// import subscriptionMain from '../../../Assets/img/bg/subscription-main-img.svg';
import { translate } from '../../../Language/Translate';
import { clientCode, getSassBaseURL } from 'Utils/Api/ApiInstance';
import { connect } from 'react-redux';

const subscriptionMain = <svg width="186" height="124" viewBox="0 0 186 124" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M68.2555 7.86047C68.2555 7.86047 122.93 -5.13953 139.304 3.36047C155.677 11.8605 137.553 36.2675 143.904 41.974C150.255 47.6804 190.794 43.334 185.255 66.3605C179.717 89.3869 138.456 109.429 103.356 118.86C68.2555 128.292 44.2555 118.86 44.2555 118.86C44.2555 118.86 38.2555 105.238 51.2555 91.7117C64.2555 78.1854 33.3782 72.8605 33.3782 72.8605C33.3782 72.8605 -13.2445 59.8605 5.25546 39.8605C23.7555 19.8605 68.2555 7.86047 68.2555 7.86047Z" fill="#F2F4F4"/>
<path d="M122.609 15.8235H114.107C113.517 15.8235 113.038 16.3023 113.038 16.8928C113.038 17.4833 113.517 17.9621 114.107 17.9621H122.609C124.402 17.9621 125.861 19.4209 125.861 21.2142V27.5146H58.1386V21.2142C58.1386 19.4209 59.597 17.9621 61.3894 17.9621H110.543C111.133 17.9621 111.612 17.4833 111.612 16.8928C111.612 16.3023 111.133 15.8235 110.543 15.8235H61.3894C58.4176 15.8235 56 18.2417 56 21.2142V70.7858C56 73.7583 58.4176 76.1765 61.3894 76.1765H122.609C125.582 76.1765 128 73.7583 128 70.7858V21.2142C128 18.2417 125.582 15.8235 122.609 15.8235ZM125.861 70.7855C125.861 72.5789 124.402 74.0378 122.609 74.0378H61.3894C59.597 74.0378 58.1386 72.5789 58.1386 70.7857V45.4904H60.169C61.9346 45.4904 63.5092 44.3055 63.9978 42.6088L64.6929 40.196C64.9194 39.41 65.6488 38.8609 66.4666 38.8609H71.9456C72.7635 38.8609 73.493 39.4098 73.7193 40.1959L74.4144 42.6087C74.9031 44.3053 76.4778 45.4902 78.2432 45.4902H125.861V70.7855ZM125.861 43.3518H78.2436C77.4256 43.3518 76.6962 42.8028 76.4699 42.0167L75.7748 39.6038C75.2861 37.9072 73.7114 36.7223 71.946 36.7223H66.4668C64.7013 36.7223 63.1269 37.9072 62.638 39.6038L61.9429 42.0167C61.7165 42.8026 60.9871 43.3518 60.1692 43.3518H58.1386V29.6531H125.861V43.3518Z" fill="#889BD2"/>
<path d="M92.5945 41.2026L92.1339 39.604C91.6453 37.9073 90.0706 36.7224 88.3052 36.7224H82.7487C81.0292 36.7224 79.496 37.8762 79.0198 39.5286L78.5375 41.2027C78.3741 41.77 78.7016 42.3627 79.269 42.5263C79.836 42.6894 80.429 42.3623 80.5926 41.7947L81.0749 40.1206C81.2885 39.379 81.9769 38.8612 82.7487 38.8612H88.3052C89.1232 38.8612 89.8526 39.41 90.0789 40.1961L90.5394 41.7947C90.6744 42.2634 91.102 42.5683 91.5664 42.5683C91.6645 42.5683 91.7641 42.5548 91.863 42.5263C92.4304 42.3626 92.7579 41.77 92.5945 41.2026Z" fill="#889BD2"/>
<path d="M64.4832 18.9602C62.3998 18.9602 60.705 20.6552 60.705 22.7384C60.705 24.8217 62.4 26.5165 64.4832 26.5165C66.5665 26.5165 68.2614 24.8217 68.2614 22.7384C68.2614 20.655 66.5664 18.9602 64.4832 18.9602ZM64.4832 24.3779C63.5791 24.3779 62.8436 23.6426 62.8436 22.7384C62.8436 21.8342 63.5793 21.0988 64.4832 21.0988C65.3871 21.0988 66.1227 21.8343 66.1227 22.7384C66.1227 23.6424 65.3873 24.3779 64.4832 24.3779Z" fill="#889BD2"/>
<path d="M72.9667 18.9602C70.8833 18.9602 69.1885 20.6552 69.1885 22.7384C69.1885 24.8217 70.8834 26.5165 72.9667 26.5165C75.05 26.5165 76.7448 24.8216 76.7448 22.7384C76.7448 20.655 75.0499 18.9602 72.9667 18.9602ZM72.9667 24.3779C72.0626 24.3779 71.3271 23.6423 71.3271 22.7384C71.3271 21.8344 72.0627 21.0988 72.9667 21.0988C73.8706 21.0988 74.6062 21.8343 74.6062 22.7384C74.6062 23.6424 73.8706 24.3779 72.9667 24.3779Z" fill="#889BD2"/>
</svg>


const SubscriptionSettings = ({ subscriptionData }) => {
  const [loader, setLoader] = useState(true);
  const [err, setErr] = useState('');
  const [success, setSuccess] = useState('');

  let org = window.location.host;
  // FIX-ME
  if (org.includes('localhost')) org = `${clientCode}.telmed.d.feathersoft.org`;

  const fetchApi = (subId) => {
    fetch(`${getSassBaseURL()}/appnest/api/customer/subscription-session`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        subscription_id: subId,
        returnUrl: window.location.origin + '#/admin/appointments',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const { data, success } = res || {};
        if (success && data.session_url) {
          setLoader(false);
          setSuccess(true);
          window.open(data.session_url);
        } else {
          setSuccess(false);
          setLoader(false);
          setErr(true);
        }
      })
      .catch((err) => {
        setLoader(false);
        setErr(err);
      });
  };

  useEffect(() => {
    fetchApi(subscriptionData.id);
  }, [subscriptionData]);

  return (
    <>
      {success ? (
        <>
          <div className="subscription-page-content">
            <div>
              {subscriptionMain}
              <p>
                {translate(
                  'settings.subscriptionOpened',
                  'Subscription settings is opened in a new tab (click here to retry if needed)'
                )}
              </p>
              <button
                className="btn btn-primary d-flex"
                onClick={() => {
                  fetchApi(subscriptionData.id);
                  setLoader(true);
                  setSuccess(false);
                }}
              >
                <i className="bi bi-arrow-repeat"></i>
                {translate('settings.retry', 'Retry ')}
              </button>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      {loader && (
        <div className="list-user" style={{minHeight: loader ? '55vh' : ''}}>
          <Loader />
        </div>
      )}
      {err && (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#ffffff',
          }}
          className="my-5 d-flex justify-content-center align-items-center"
        >
          <div className="page-not-found">
            <img src={Error404} alt="404" className='d-block mx-auto' />
            <h4>{translate(
                'common.unableToLoad',
                'Something went wrong, Unable to load subscription settings'
              )}
              !</h4>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  subscriptionData: state.SettingsReducer.subscriptionData,
});

export default connect(mapStateToProps)(SubscriptionSettings);
