import { SET_REF_TOASTER, TOASTER_CLOSE, TOASTER_DISPLAY } from '../Actions/toasterAction';

const initialState = {
  toasterDisplay: false,
  toasterType: '',
  toasterMessage: '',
  toasterRef: null
};

const ToasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOASTER_DISPLAY: {
      return {
        ...state,
        toasterDisplay: true,
        toasterType: action.toasterType,
        toasterMessage: action.toasterMessage,
      };
    }
    case TOASTER_CLOSE: {
      return {
        ...state,
        toasterDisplay: false,
      };
    }
    case SET_REF_TOASTER: {
      return {
        ...state,
        toasterRef: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ToasterReducer;
