import { translate } from '../Language/Translate';
// import doc1 from '../Assets/img/patient-portal/doc-1.svg';
// import doc2 from '../Assets/img/patient-portal/doc-2.svg';
// import doc3 from '../Assets/img/patient-portal/doc-3.svg';

export const API_STATUS = {
  success: 'success',
  error: 'error',
  unauthorized: 'Unauthorized',
};

export const DEPARTMENT_STATUS = [
  { label: translate('common.active', 'Active'), value: 'active' },
  { label: translate('common.inactive', 'Inactive'), value: 'inactive' },
];


export const DEPARTMENT_VIEW_HEADERS = [
  {
    width: '8%',
    heading: '#',
    value: 'slno',
    isEnableSort: false,
  },
  {
    width: '27%',
    heading: translate('doctors.doctorName', 'Doctor Name'),
    value: 'name',
    isEnableSort: true,
  },
  {
    width: '27%',
    heading: translate('doctors.designation', 'Designation'),
    value: 'designation',
    isEnableSort: true,
  },
  {
    width: '40%',
    heading: translate('common.status', 'Status'),
    value: 'doctor_status',
    isEnableSort: true,
  },
]

export const DEPARTMENT_HEADERS = [
  {
    width: '6%',
    // heading: translate('leaveApproval.slNo', 'Sl No'),
    heading: '#',
    value: 'slno',
    isEnableSort: false,
  },
  {
    width: '40%',
    heading: translate('departments.deptName', 'Department Name'),
    value: 'dept_name',
    isEnableSort: true,
  },

  {
    width: '30%',
    heading: translate('departments.deptHead', 'Department Head'),
    value: 'dept_head_name',
    isEnableSort: true,
  },
  {
    width: '23%',
    heading: translate('common.status', 'Status'),
    value: 'dept_status',
    isEnableSort: true,
  },
  {
    width: '12%',
    heading: translate('common.action', 'Action'),
    value: '',
    isEnableSort: false,
  },
];

export const USERS_HEADERS = [
  {
    width: '70',
    // heading: translate('common.slno', 'Sl No.'),
    heading: '#',
    value: 'slno',
    isEnableSort: false,
  },
  {
    width: '150',
    heading: translate('users.userName', 'User Name'),
    value: 'name',
    isEnableSort: true,
  },
  {
    width: '150',
    heading: translate('users.phone', 'Phone Number'),
    value: 'phone',
    isEnableSort: false,
  },
  {
    width: '245',
    heading: translate('common.emailId', 'Email Address'),
    value: 'email',
    isEnableSort: false,
  },
  {
    width: '200',
    heading: translate('users.roletype', 'Role Type'),
    value: 'user_type',
    isEnableSort: false,
  },
  {
    width: '125',
    heading: translate('common.status', 'Status'),
    value: 'user_status',
    isEnableSort: false,
  },
  {
    width: '100',
    heading: translate('common.action', 'Action'),
    value: '',
    isEnableSort: false,
  },
];
export const PATIENTS_HEADERS = [
  {
    width: '6%',
    // heading: translate('common.slno', 'Sl No.'),
    heading: '#',
    value: 'slno',
    isEnableSort: false,
  },
  {
    width: '20%',
    heading: translate('users.name', 'Name'),
    value: 'name',
    isEnableSort: true,
  },
  {
    width: '20%',
    heading: translate('patients.regDate', 'Reg.Date'),
    value: 'created_at',
    isEnableSort: true,
  },
  {
    width: '7%',
    heading: translate('patients.age', 'Age'),
    value: 'dob',
    isEnableSort: true,
  },
  {
    width: '17%',
    heading: translate('users.phone', 'Phone Number'),
    _value: 'phone',
    value:"tele_country_code",
    isEnableSort: true,
  },
  {
    width: '23%',
    heading: translate('patients.emailAddress', 'Email Address'),
    value: 'email',
    isEnableSort: false,
  },
  {
    width: '15%',
    heading: translate('patients.lastApptDate', 'Last Appt. date'),
    value: 'last_appt_date',
    isEnableSort: true,
  },
  {
    width: '7%',
    heading: translate('common.action', 'Action'),
    value: '',
    isEnableSort: false,
  },
];

export const LEAVE_APPROVAL_HEADERS = [
  {
    width: '6%',
    // heading: translate('leaveApproval.slNo', 'Sl No'),
    heading: '#',
    value: 'slno',
    isEnableSort: false,
  },
  // {
  //   width: '14%',
  //   heading: translate('leaveApproval.doctorId', 'Doctor ID'),
  //   value: 'doctor_id',
  //   isEnableSort: true,
  // },
  {
    width: '14%',
    heading: translate('leaveApproval.doctorName', 'Doctor Name'),
    value: 'name',
    isEnableSort: true,
  },
  {
    width: '12%',
    heading: translate('leaveApproval.reqDates', 'Requested Date'),
    value: 'created_at',
    isEnableSort: false,
  },
  {
    width: '20%',
    heading: translate('leaveApproval.leaveDates', 'Leave Date(s)'),
    value: 'start_date',
    isEnableSort: true,
  },
  {
    width: '17%',
    heading: translate('leaveApproval.leaveType', 'Leave Type'),
    value: 'leave_type',
    isEnableSort: true,
  },
  {
    width: '10%',
    heading: translate('common.reason', 'Reason'),
    value: 'reason',
    isEnableSort: false,
  },
  {
    width: '13%',
    heading: translate('common.status', 'Status'),
    value: 'leave_status',
    isEnableSort: true,
  },
  {
    width: '11%',
    heading: translate('common.action', 'Action'),
    value: '',
    isEnableSort: false,
  },
];

export const LEAVE_LIST_HEADERS = [
  {
    width: '6%',
    // heading: translate('leaveApproval.slNo', 'Sl No'),
    heading: '#',
    value: 'slno',
    isEnableSort: false,
  },
  // {
  //   width: '15%',
  //   heading: translate('leaveApproval.doctorId', 'Doctor ID'),
  //   value: 'doctor_id',
  //   isEnableSort: true,
  // },
  {
    width: '14%',
    heading: translate('leaveApproval.doctorName', 'Doctor Name'),
    value: 'name',
    isEnableSort: true,
  },
  {
    width: '12%',
    heading: translate('leaveApproval.reqDates', 'Requested Date'),
    value: 'created_at',
    isEnableSort: false,
  },
  {
    width: '20%',
    heading: translate('leaveApproval.leaveDate', 'Leave Date'),
    value: 'start_date',
    isEnableSort: true,
  },
  {
    width: '10%',
    heading: translate('leaveApproval.leaveType', 'Leave Type'),
    value: 'leave_type',
    isEnableSort: true,
  },
  {
    width: '17%',
    heading: translate('common.reason', 'Reason'),
    value: 'reason',
    isEnableSort: false,
  },
  {
    width: '13%',
    heading: translate('common.status', 'Status'),
    value: 'leave_status',
    isEnableSort: true,
  },
];

export const LEAVE_HEADERS = [
  {
    width: '6%',
    // heading: translate('leaveApproval.slNo', 'Sl No'),
    heading: '#',
    value: 'slno',
    isEnableSort: false,
  },
  {
    width: '12%',
    heading: translate('leaveApproval.reqDates', 'Requested Date'),
    value: 'created_at',
    isEnableSort: false,
  },
  {
    width: '20%',
    heading: translate('leaveApproval.leaveDate', 'Leave Date'),
    value: 'start_date',
    isEnableSort: true,
  },
  {
    width: '10%',
    heading: translate('leaveApproval.leaveType', 'Leave Type'),
    value: 'leave_type',
    isEnableSort: true,
  },
  {
    width: '15%',
    heading: translate('common.reason', 'Reason'),
    value: 'reason',
    isEnableSort: false,
  },
  {
    width: '13%',
    heading: translate('common.status', 'Status'),
    value: 'leave_status',
    isEnableSort: true,
  },
  {
    width: '11%',
    heading: translate('common.action', 'Action'),
    value: '',
    isEnableSort: false,
  },
];

export const LEAVE_STATUS = {
  all: '',
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected',
  cancelled: 'canceled',
};

export const LEAVE_STATUS_NAMES = {
  [LEAVE_STATUS.pending]: translate('leaveApproval.all', 'All'),
  [LEAVE_STATUS.pending]: translate('leaveApproval.pending', 'Pending'),
  [LEAVE_STATUS.approved]: translate('leaveApproval.approved', 'Approved'),
  [LEAVE_STATUS.rejected]: translate('leaveApproval.rejected', 'Rejected'),
  [LEAVE_STATUS.cancelled]: translate('leaveApproval.cancelled', 'Cancelled'),
};

export const LEAVE_STATUS_OPTIONS = [
  {
    label: translate('leaveApproval.all', 'All'),
    value: LEAVE_STATUS.all,
  },
  {
    label: translate('leaveApproval.pending', 'Pending'),
    value: LEAVE_STATUS.pending,
  },
  {
    label: translate('leaveApproval.approved', 'Approved'),
    value: LEAVE_STATUS.approved,
  },
  {
    label: translate('leaveApproval.rejected', 'Rejected'),
    value: LEAVE_STATUS.rejected,
  },
  {
    label: translate('leaveApproval.cancelled', 'Cancelled'),
    value: LEAVE_STATUS.cancelled,
  },
];

export const LEAVE_TYPES = {
  firstHalf: '1',
  secondHalf: '2',
  fullDay: 'f',
  custom: 'c',
};

export const LEAVE_TYPE_NAMES = {
  [LEAVE_TYPES.firstHalf]: translate('leaveApproval.firstHalf', 'First Half'),
  [LEAVE_TYPES.secondHalf]: translate(
    'leaveApproval.secondHalf',
    'Second Half'
  ),
  [LEAVE_TYPES.fullDay]: translate('leaveApproval.fullDay', 'Full Day'),
  [LEAVE_TYPES.custom]: translate('leaveApproval.custom', 'Custom'),
};

export const LEAVE_TYPE_OPTIONS = [
  {
    label: translate('leaveApproval.firstHalf', 'First Half'),
    value: LEAVE_TYPES.firstHalf,
  },
  {
    label: translate('leaveApproval.secondHalf', 'Second Half'),
    value: LEAVE_TYPES.secondHalf,
  },
  {
    label: translate('leaveApproval.fullDay', 'Full Day'),
    value: LEAVE_TYPES.fullDay,
  },
  {
    label: translate('leaveApproval.custom', 'Custom'),
    value: LEAVE_TYPES.custom,
  },
];

const TRANSACTION_TYPES = {
  cash: 'cash',
  upi: 'upi',
  card: 'card',
  netBanking: 'netbanking',
};
export const TRANSACTION_TYPE_OPTIONS = [
  {
    label: translate('appointments.cash', 'Cash'),
    value: TRANSACTION_TYPES.cash,
  },
  {
    label: translate('appointments.upi', 'UPI'),
    value: TRANSACTION_TYPES.upi,
  },
  {
    label: translate('appointments.card', 'Card'),
    value: TRANSACTION_TYPES.card,
  },
  {
    label: translate('appointments.netbanking', 'Netbanking'),
    value: TRANSACTION_TYPES.netBanking,
  },
];
export const PAGINATION_PER_PAGE = [10, 25, 50, 75, 100];

export const PAID = 'paid';

export const BOOKING_STATUS = {
  booked: translate(
    'appointments.booked',
    'Booked'
  ),
  yourSelection: translate(
    'appointments.yourSelection',
    'Your Selection'
  ),
  available: translate(
    'appointments.available',
    'Available'
  ),
  notAvailable: translate(
    'appointments.notAvailable',
    'Not Available'
  )
}

export const APPOINTMENT_STATUS = {
  inProgress: 'inprogress',
  upcoming: 'upcoming',
  rescheduled: 'rescheduled',
  cancelled: 'cancelled',
  notAttempted: 'notattempted',
  completed: 'completed',
  waiting: 'waiting',
};

export const APPOINTMENT_STATUS_NAMES = {
  [APPOINTMENT_STATUS.inProgress]: translate(
    'appointments.inProgress',
    'In Progress'
  ),
  [APPOINTMENT_STATUS.upcoming]: translate('appointments.upcoming', 'Upcoming'),
  [APPOINTMENT_STATUS.rescheduled]: translate(
    'appointments.rescheduled',
    'Rescheduled'
  ),
  [APPOINTMENT_STATUS.cancelled]: translate(
    'appointments.cancelled',
    'Cancelled'
  ),
  [APPOINTMENT_STATUS.notAttempted]: translate(
    'appointments.notAttempted',
    'Not Attempted'
  ),
  [APPOINTMENT_STATUS.completed]: translate(
    'appointments.completed',
    'Completed'
  ),
  [APPOINTMENT_STATUS.waiting]: translate('appointments.waiting', 'Waiting'),
};

export const APPOINTMENT_STATUS_OPTIONS = [
  {
    label: translate('appointments.inProgress', 'In Progress'),
    value: APPOINTMENT_STATUS.inProgress,
  },
  {
    label: translate('appointments.upcoming', 'Upcoming'),
    value: APPOINTMENT_STATUS.upcoming,
  },
  // {
  //   label: translate('appointments.rescheduled', 'Rescheduled'),
  //   value: APPOINTMENT_STATUS.rescheduled,
  // },
  {
    label: translate('appointments.cancelled', 'Cancelled'),
    value: APPOINTMENT_STATUS.cancelled,
  },
  {
    label: translate('appointments.notAttempted', 'Not Attempted'),
    value: APPOINTMENT_STATUS.notAttempted,
  },
  {
    label: translate('appointments.completed', 'Completed'),
    value: APPOINTMENT_STATUS.completed,
  },
  {
    label: translate('appointments.waiting', 'Waiting'),
    value: APPOINTMENT_STATUS.waiting,
  },
];
export const APPOINTMENT_STATUS_OPTIONS_ALL = [
  {
    label: translate('appointments.all', 'All'),
    value: '',
  },
  {
    label: translate('appointments.inProgress', 'In Progress'),
    value: APPOINTMENT_STATUS.inProgress,
  },
  {
    label: translate('appointments.upcoming', 'Upcoming'),
    value: APPOINTMENT_STATUS.upcoming,
  },
  // {
  //   label: translate('appointments.rescheduled', 'Rescheduled'),
  //   value: APPOINTMENT_STATUS.rescheduled,
  // },
  {
    label: translate('appointments.cancelled', 'Cancelled'),
    value: APPOINTMENT_STATUS.cancelled,
  },
  {
    label: translate('appointments.notAttempted', 'Not Attempted'),
    value: APPOINTMENT_STATUS.notAttempted,
  },
  {
    label: translate('appointments.completed', 'Completed'),
    value: APPOINTMENT_STATUS.completed,
  },
  {
    label: translate('appointments.waiting', 'Waiting'),
    value: APPOINTMENT_STATUS.waiting,
  },
];

export const APPOINTMENT_HEADERS = [
  // { width: '16%', heading: translate('appointments.id', 'ID'), value: 'appointment_id', isEnableSort: true },
  {
    width: '13%',
    heading: translate('appointments.apptId', 'Appointment ID'),
    value: 'slno',
    isEnableSort: false,
  },
  {
    width: '15%',
    heading: translate('appointments.patientName', 'Patient Name'),
    value: 'patient_name',
    isEnableSort: true,
  },
  {
    width: '15%',
    heading: translate('appointments.doctorName', 'Doctor Name'),
    value: 'doctor_name',
    isEnableSort: true,
  },
  // {
  //   width: '12%',
  //   heading: translate('appointments.date', 'Date'),
  //   value: 'appt_date',
  //   isEnableSort: true,
  // },
  {
    width: '17%',
    heading: translate('appointments.timeSlot', 'Time Slot'),
    value: 'appt_date,appt_start_time',
    isEnableSort: true,
  },
  {
    width: '8%',
    heading: translate('appointments.type', 'Type'),
    value: 'schedule_type',
    isEnableSort: true,
  },
  {
    width: '14%',
    heading: translate('appointments.department', 'Department'),
    value: 'dept_name',
    isEnableSort: true,
  },
  {
    width: '15.5%',
    heading: translate('common.status', 'Status'),
    value: 'appt_status',
    isEnableSort: true,
  },
  {
    width: '14%',
    heading: translate('common.action', 'Action'),
    value: '',
    isEnableSort: false,
  },
];

export const DOCTORS_HEADERS = [
  {
    width: '6%',
    // heading: translate('common.slno', 'Sl No.'),
    heading: '#',
    value: 'slno',
    isEnableSort: false,
  },
  {
    width: '30%',
    heading: translate('doctors.doctorName', 'Doctor Name'),
    value: 'name',
    isEnableSort: true,
  },
  {
    width: '30%',
    heading: translate('doctors.department', 'Department'),
    value: 'departments',
    isEnableSort: false,
  },
  {
    width: '10%',
    heading: translate('doctors.apptType', 'Appt. Type'),
    value: 'op_appointment',
    isEnableSort: false,
  },
  {
    width: '13%',
    heading: translate('common.status', 'Status'),
    value: 'doctor_status',
    isEnableSort: true,
  },
  {
    width: '13%',
    heading: translate('common.action', 'Action'),
    value: '',
    isEnableSort: false,
  },
];

export const GENDERS = {
  male: 'male',
  female: 'female',
  other: 'other',
};

export const GENDER_LABEL = {
  [GENDERS.male]: translate('common.male', 'Male'),
  [GENDERS.female]: translate('common.female', 'Female'),
  [GENDERS.other]: translate('common.other', 'Other'),
};

export const GENDER_LIST = [
  { label: translate('common.male', 'Male'), value: GENDERS.male },
  { label: translate('common.female', 'Female'), value: GENDERS.female },
  { label: translate('common.other', 'Other'), value: GENDERS.other },
];

export const DOCTOR_APPOINTMENT_HEADERS = [
  { width: '16%', heading: translate('appointments.appointmentId', 'Appointment ID') },
  {
    width: '21%',
    heading: translate('appointments.patientName', 'Patient Name'),
    value: 'patient_name',
    isEnableSort: true,
  },
  { width: '21%',
    heading: translate('appointments.department', 'Department'),
      value: 'dept_name',
      isEnableSort: true,
  },
  { width: '21%',
    heading: translate('appointments.timeSlot', 'Time'),
    value: 'appt_date',
    isEnableSort: true,
  },
  { width: '8%', heading: translate('appointments.type', 'Type') },
  { width: '15%',
    heading: translate('common.status', 'Status'),
    value: 'appt_status',
    isEnableSort: true,
  },
  { width: '10%', heading: translate('common.action', 'Action') },
];

export const PATIENT_LOGIN_TITLES = {
  signin: translate('patientPortal.signin', 'Sign In'),
  signup: translate('patientPortal.signup', 'Sign Up'),
  guest: translate('patientPortal.guest', 'Continue as Guest'),
  forgotPassword: translate('patientPortal.forgotPassword', 'Forgot Password'),
  otp: translate('patientPortal.signin', 'Sign In'),
  otpVerification: translate(
    'patientPortal.otpVerification',
    'OTP Verification'
  ),
};

export const PATIENT_LOGIN_MODAL_CLASS = {
  signin: 'login-modal',
  signup: 'register-modal',
  guest: 'guest-modal',
  otp: 'otp-modal',
  otpVerification: 'otp-verification',
  forgotPassword: 'forgot-password',
  mailSent: 'mail-sent',
};

export const DOCTOR_SPECIALITY = {
  doctor: translate('patientPortal.doctor', 'Doctor'),
  department: translate('patientPortal.speciality', 'Specialtiy'),
};

export const APPOINTMENT_TABS = [
  {
    label: translate('appointments.upcoming', 'Upcoming'),
    value: 'upcoming',
  },
  {
    label: translate('appointments.previous', 'Previous'),
    value: 'previous',
  },
  {
    label: translate('appointments.cancelled', 'Cancelled'),
    value: 'cancelled',
  },
];

export const SLOTS_TABS = [
  {
    label: translate('doctors.scheduleSettings', 'Schedule Settings'),
    value: 'default',
  },
  {
    label: translate('doctors.overrideSlots', 'Override Slots'),
    value: 'overrideSlots',
  },
];

export const ALL_VALUE = { label: 'All', value: 'all' };

export const FILE_TYPES = {
  pdf: 'pdf',
  image: 'image',
};

export const RELATIONS = {
  daughter: 'daughter',
  son: 'son',
  // sibiling: 'sibiling',
  mother: 'mother',
  father: 'father',
  husband: 'husband',
  wife: 'wife',
  brother: 'brother',
  sister: 'sister',
  grandmother: 'grandmother',
  grandfather: 'grandfather',
  stepmother: 'stepmother',
  stepfather: 'stepfather',
  aunt: 'aunt',
  uncle: 'uncle',
  niece: 'niece',
  nephew: 'nephew',
  cousin: 'cousin',
  stepson: 'stepson',
  stepdaughter: 'stepdaughter',
  motherInlaw: 'mother-in-law',
  fatherInlaw: 'father-in-law',
  brotherInlaw: 'brother-in-law',
  sisterInlaw: 'sister-in-law',
  grandson: 'grandson',
  granddaughter: 'granddaughter',
  greatGrandfather: 'great grandfather',
  greatGrandMother: 'great grandmother',
  other: 'other',
  friend: 'friend',
};

// grandson, granddaughter, great grandfather, great grandmother
export const RELATIONS_LABEL = [
  {
    label: translate('relation.daughter', 'Daughter'),
    value: RELATIONS.daughter,
  },
  { label: translate('relation.son', 'Son'), value: RELATIONS.son },
  { label: translate('relation.mother', 'Mother'), value: RELATIONS.mother },
  { label: translate('relation.father', 'Father'), value: RELATIONS.father },
  { label: translate('relation.husband', 'Husband'), value: RELATIONS.husband },
  { label: translate('relation.wife', 'Wife'), value: RELATIONS.wife },
  { label: translate('relation.brother', 'Brother'), value: RELATIONS.brother },
  { label: translate('relation.sister', 'Sister'), value: RELATIONS.sister },
  {
    label: translate('relation.grandmother', 'Grandmother'),
    value: RELATIONS.grandmother,
  },
  {
    label: translate('relation.grandfather', 'Grandfather'),
    value: RELATIONS.grandfather,
  },
  {
    label: translate('relation.stepmother', 'Stepmother'),
    value: RELATIONS.stepmother,
  },
  {
    label: translate('relation.stepfather', 'Stepfather'),
    value: RELATIONS.stepfather,
  },
  { label: translate('relation.aunt', 'Aunt'), value: RELATIONS.aunt },
  { label: translate('relation.uncle', 'Uncle'), value: RELATIONS.uncle },
  { label: translate('relation.niece', 'Niece'), value: RELATIONS.niece },
  { label: translate('relation.nephew', 'Nephew'), value: RELATIONS.nephew },
  { label: translate('relation.cousin', 'Cousin'), value: RELATIONS.cousin },
  { label: translate('relation.stepson', 'Stepson'), value: RELATIONS.stepson },
  {
    label: translate('relation.stepdaughter', 'Stepdaughter'),
    value: RELATIONS.stepdaughter,
  },
  {
    label: translate('relation.motherinlaw', 'Mother-in-law'),
    value: RELATIONS.motherInlaw,
  },
  {
    label: translate('relation.fatherinlaw', 'Father-in-law'),
    value: RELATIONS.fatherInlaw,
  },
  {
    label: translate('relation.brotherinlaw', 'Brother-in-law'),
    value: RELATIONS.brotherInlaw,
  },
  {
    label: translate('relation.sisterinlaw', 'Sister-in-law'),
    value: RELATIONS.sisterInlaw,
  },
  {
    label: translate('relation.grandson', 'Grandson'),
    value: RELATIONS.grandson,
  },
  {
    label: translate('relation.granddaughter', 'Granddaughter'),
    value: RELATIONS.granddaughter,
  },
  {
    label: translate('relation.greatgrandfather', 'Great grandfather'),
    value: RELATIONS.greatGrandfather,
  },
  {
    label: translate('relation.greatgrandmother', 'Great grandmother'),
    value: RELATIONS.greatGrandMother,
  },
  { label: translate('relation.friend', 'Friend'), value: RELATIONS.friend },
  { label: translate('relation.other', 'Other'), value: RELATIONS.other },
];

export const BLOOD_GROUPS = {
  ap: 'A+',
  an: 'A-',
  bp: 'B+',
  bn: 'B-',
  op: 'O+',
  on: 'O-',
  abp: 'AB+',
  abn: 'AB-',
};

export const BLOOD_GROUP_LABELS = {
  [BLOOD_GROUPS.ap]: 'A+',
  [BLOOD_GROUPS.an]: 'A-',
  [BLOOD_GROUPS.bp]: 'B+',
  [BLOOD_GROUPS.bn]: 'B-',
  [BLOOD_GROUPS.op]: 'O+',
  [BLOOD_GROUPS.on]: 'O-',
  [BLOOD_GROUPS.abp]: 'AB+',
  [BLOOD_GROUPS.abn]: 'AB-',
};

export const BLOOD_GROUP_OPTIONS = [
  { label: BLOOD_GROUP_LABELS[BLOOD_GROUPS.ap], value: BLOOD_GROUPS.ap },
  { label: BLOOD_GROUP_LABELS[BLOOD_GROUPS.an], value: BLOOD_GROUPS.an },
  { label: BLOOD_GROUP_LABELS[BLOOD_GROUPS.bp], value: BLOOD_GROUPS.bp },
  { label: BLOOD_GROUP_LABELS[BLOOD_GROUPS.bn], value: BLOOD_GROUPS.bn },
  { label: BLOOD_GROUP_LABELS[BLOOD_GROUPS.op], value: BLOOD_GROUPS.op },
  { label: BLOOD_GROUP_LABELS[BLOOD_GROUPS.on], value: BLOOD_GROUPS.on },
  { label: BLOOD_GROUP_LABELS[BLOOD_GROUPS.abp], value: BLOOD_GROUPS.abp },
  { label: BLOOD_GROUP_LABELS[BLOOD_GROUPS.abn], value: BLOOD_GROUPS.abn },
];

export const queryTypeList = {
  enquiry: 'enquiry',
  complaint: 'complaint',
  feedback: 'feedback',
};

export const PAYMENT_TYPE = {
  online: 'pay_online',
  clinic: 'pay_at_clinic',
  both: 'both',
};

export const PAYMENT_METHODS = [
  {
    label: translate('patientPortal.payOnline', 'Pay Online'),
    value: PAYMENT_TYPE.online,
  },
  {
    label: translate('patientPortal.payAtClinic', 'Pay at Clinic'),
    value: PAYMENT_TYPE.clinic,
  },
  {
    label: translate('patientPortal.both', 'Pay Online'),
    value: PAYMENT_TYPE.both,
  },
];

export const idOptions = {
  patientId: 'SystemGeneratedPatientID',
  mrnOrHealthId: 'MRN/HealthID',
};

export const ID_OPTIONS = [
  {
    label: translate(
      'settings.systemGeneratedPatientId',
      'System Generated Patient ID'
    ),
    value: true,
  },
  {
    label: translate('settings.mrnOrHealthId', 'MRN/HealthID'),
    value: false,
  },
];

export const PORTAL_TYPES = {
  provider: 'provider',
  patient: 'patient',
};


export const REMINDER_NOTIFICATION_CHANNEL = [
  { label: translate('settings.all', 'All'), value: 'all', disabled: true },
  { label: translate('settings.email', 'Email'), value: 'email' },
  { label: translate('settings.sms', 'SMS'), value: 'sms', disabled: true }
]

export const REMINDER_FREQ_UNIT = [
  { label: translate('settings.minutes', 'Minutes'), value: 'minute' },
  { label: translate('settings.hours', 'Hours'), value: 'hour' },
  { label: translate('settings.days', 'Days'), value: 'day' },
  { label: translate('settings.weeks', 'Weeks'), value: 'week' },
]


export const LEAVE_CONCAT_STRING = '-TEL-COMMENT-'