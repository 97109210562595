import React, { useState } from 'react';
import Select from 'react-select';
import { createFilter } from 'react-select';

const ReactSelectDropDown = (props) => {
  const {
    onInputChange,
    emptyClass,
    value,
    id,
    options,
    isDisabled,
    isClearable = false,
    components,
    validationErr,
    customClass = '',
    isLoading = false,
    isLowerCase = false,
    isCasePlaceholder = false,
    excludeValueFilter = false,
    autoFocus=false,
    reference,
    targetBody = true,
    displayWarningToaster
  } = props;

  const [inputValue, setInputValue] = useState('');

  const matchedOptions = options.filter(option =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  const customStyles = {
    placeholder: (base) => ({
      ...base,
      position: 'absolute',
      top: ' 16px',
      textTransform: isCasePlaceholder ? '' : 'uppercase',
      fontSize: '13px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontFamily: 'Poppins',
      lineHeight: 'normal',
      letterSpacing: '0.43px',
      color: '#636363',
      transition: 'all ease 0.3s',
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      boxShadow: 'none',
      cursor: 'text',
      fontSize: 13,
      textTransform: isLowerCase ? 'lowercase' : '',
      borderColor: validationErr ? 'red !important' : '#ced4da',
      '&:hover': {
        borderColor: validationErr ? 'red' : baseStyles.borderColor,
      },
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      display: matchedOptions.length === 0 ? 'none' : 'block'
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid #e7e7e7',
      color: state.isSelected ? '#fff' : state.isDisabled ? 'grey' : '#000',
      cursor: state.isDisabled ? 'no-drop' : 'pointer',
      fontFamily: 'Poppins',
      backgroundColor: state.isSelected
        ? '#007bff'
        : state.isDisabled
        ? 'rgba(206, 217, 224, 0.5)'
        : '#fff',
      ':hover': {
        backgroundColor: !state.isSelected && '#f5f5ff',
      },
      textTransform: isLowerCase ? 'lowercase' : '',
    }),
    dropdownIndicator: (defaultStyles) => {
      return {
        ...defaultStyles,
        cursor: 'pointer',
      };
    },
    clearIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    valueContainer: (provided) => ({
      ...provided,
      textTransform: isLowerCase ? 'lowercase' : '',
    }),
  };

  let className = emptyClass ? 'selectDrop' : 'form-group';
  className = className + customClass;

  const handleInputChange = (e)=> {
    if(!isNaN(e) || e.includes(':') || e.includes('am') || e.includes('pm')) {
      setInputValue(e);
    }
  }

  const handleOnBlur = ()=> {
    if(inputValue && checkTimeFormat(inputValue)) {
      onInputChange({label: inputValue, value: convertTo24Hour(inputValue)});
    } else if (inputValue) {
      displayWarningToaster()
    }
  }

  const convertTo24Hour = (input) => {
    const time = input
    const [timePart, periodPart] = time.split(' ');
    let [hour, minute] = timePart.split(':');

    if(periodPart?.toLowerCase() === 'pm' && parseInt(hour) !== 12) {
      hour = parseInt(hour) + 12
    } else if (periodPart?.toLowerCase() === 'am' && parseInt(hour) === 12) {
      hour = 0;
    }

    return `${hour.length === 1 ? `0${hour}` : hour}:${minute.length === 1 ? `0${minute}` : minute}:00`
  }
    
  const checkTimeFormat = (timeString) => {
      const timeFormatRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i;
      return timeFormatRegex.test(timeString);
  };

  return (
    <div className={`${className} ${validationErr ? `dropdown-error` : ''}`}>
      <Select
        tabIndex="0"
        value={value ? value : ''}
        onChange={(option) => {
          onInputChange(option, id);
        }}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        onBlur={handleOnBlur}
        maxMenuHeight={150}
        options={options}
        isOptionDisabled={(option) => option.disabled}
        placeholder={'hh:mm'}
        styles={customStyles}
        isDisabled={isDisabled ? isDisabled : ''}
        className={customClass}
        classNamePrefix="react-select"
        menuPortalTarget={targetBody ? document.body : ''}
        // menuIsOpen={true}
        isClearable={isClearable}
        components={components}
        isLoading={isLoading}
        tabSelectsValue={false}
        autoFocus={autoFocus}
        ref={reference}
        filterOption={createFilter(
          excludeValueFilter
            ? {
                // Ignore case for filtering
                ignoreCase: true,
                // Only consider the label property for filtering
                matchFrom: 'any',
                stringify: (option) => option.label,
              }
            : {}
        )}
      />
    </div>
  );
};

export default ReactSelectDropDown;
