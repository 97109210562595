import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import placeholdericon from 'Assets/img/patient-portal/User_Placeholder.svg';
import errorImg from '../../../Assets/img/patient-portal/error_img_placeHolder.png';
import { getMedicalRecordDetails } from '../../../Actions/medicalRecordAction';
import { translate } from '../../../Language/Translate';
import { saveAs } from 'file-saver';

import './MedicalRecords.css';
import Loader from 'Components/Common/Loader';
import { CardMedia, Tooltip } from '@mui/material';

function ViewMedicalRecord(props) {
  const {
    applicationData,
    viewClick,
    setViewclick,
    getMedicalRecordDetails,
    id,
    setSelectedPatientId,
  } = props;
  const [formData, setFormData] = useState({});
  const [isLoading,setIsLoading]= useState(false)
  const modalRef = useRef();
  const imgStatus = useRef(true)
  const closeBtnRef = useRef();
  let isFocused=false

  useEffect(() => {
    const listener = (event) => {
      if (!modalRef.current || modalRef.current.contains(event.target)) {
        return;
      }
      if (event.button === 0) setViewclick();
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [modalRef, setViewclick]);
  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getMedicalRecordDetails(id, setFormData,setIsLoading);
    }
    return () => {
      setSelectedPatientId('');
    };
  }, [id]);

  useEffect(() => {
    if (viewClick) {
      setTimeout(() => {
        document.body.classList.add('modal-overflow');
      }, 0);
    } else {
      document.body.classList.remove('modal-overflow');
    }
    return () => document.body.classList.remove('modal-overflow');
  }, [viewClick]);

  const handleDownload = (data) => {
    saveAs(data.link, `${data.display_name}.${data.extension}`, {})
  };
  
  const listener = (event)=>{
    if(event.key === "Tab" && !event.shiftKey){
      if(!isFocused){
        isFocused=true;
        closeBtnRef?.current?.focus();
      }
    }
    else if(event.key ==="Tab" && event.shiftKey && !isFocused ){
      isFocused = true
      event.preventDefault();
      closeBtnRef?.current?.focus();
    }
  }
  useEffect(()=>{
    document.addEventListener('keydown',listener)
    return ()=> {
    if(!viewClick){
      isFocused=false;
      document.removeEventListener('keydown',listener);
    }
    }
  },[viewClick])
  return (
    <>
      <div
        className={`offcanvas-backdrop fade ${viewClick ? 'show' : ''}`}
      ></div>
      <div
        className={`offcanvas offcanvas-end custom-off-canvas view-record-canvas ${
          viewClick ? 'show' : ''
        }`}
        tabIndex="-1"
        id="viewMedicalRecord"
        ref={modalRef}
        aria-labelledby="addMedicalLabel"
      >
        <div className="offcanvas-header">
          <h5 id="viewScheduleLabel">
            {translate(
              'medicalRecords.MedicalRecordDetails',
              'Medical Record Details'
            )}
          </h5>
          <button
            type="button"
            className="btn-close text-reset "
            aria-label="Close"
            ref={closeBtnRef}
            onClick={() => setViewclick(false)}
            onKeyDown={(e)=>{
              if(e.key === "Enter" && !e.shiftKey){
                e.preventDefault();
                setViewclick(false);           
              }
              else if(e.key === "Tab" && e.shiftKey) e.preventDefault()
            }}
          ></button>
        </div>
        {isLoading?
          <div className="loader-wrap mb-4"><Loader /></div>
        : <div className="offcanvas-body">
          <div className="profile">
            <CardMedia
              component="img"
              image={formData?.profileImg || placeholdericon}
              onError={(e) => {
                if (imgStatus.current) {
                  e.target.src = errorImg
                  imgStatus.current = false
                }
              }}
            />
            <div className="name-and-id">
              <p className='mb-2'>{translate('medicalRecords.MemberInfo', 'Member Info')}</p>
              <h6 className="name">{formData.patientName}</h6>
              <p className="id">
                {applicationData.use_system_generated_mrn?translate('medicalRecords.id', 'ID'):translate("patientPortal.MRN", "MRN")} : {applicationData.use_system_generated_mrn?formData.patientId:formData?.mrn?formData.mrn:"-"}
              </p>
            </div>
          </div>
          <h6 className="section-title">
            {translate('medicalRecords.fileUploaded', 'File(s) Uploaded')}
          </h6>
          <div className="file-listing mt-3">
            {formData?.file?.map((record, index) => {
              return (
                <div key={index} className="single-file ">
                  <span className="file-type-badge">{record.extension}</span>
                  <a href={record.link} target="__blank">
                    {record?.display_name}
                  </a>
                  <span className="size">{record?.size}</span>
                  <Tooltip placement="top" title="Download">
                  <button className="delete"
                    onKeyDown={(e)=>{
                      if(e.key === "Enter" && !e.shiftKey) handleDownload(record);
                      if(index === formData?.file?.length -1 && e.key === "Tab" && !e.shiftKey) e.preventDefault();
                    }}
                  >
                    <i
                      className="bi bi-download"
                      onClick={() => {
                        handleDownload(record);
                      }}
                    ></i>
                  </button>
                  </Tooltip>
                </div>
              );
            })}
          </div>
          <div className="remark">
            <h6>{translate('medicalRecords.description', 'Description')}</h6>
            <p>{formData?.remarks}</p>
          </div>
        </div>}
      </div>
    </>
  );
}
const mapStateToProps =(state)=>({
  applicationData: state.SettingsReducer.applicationData,
})

const mapDispatchToProps = (dispatch) => ({
  getMedicalRecordDetails: (data, setFormData,setLoader) =>
    dispatch(getMedicalRecordDetails(data, setFormData,setLoader)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewMedicalRecord);
