import React from 'react';
import { translate } from '../../../Language/Translate';
import './style/footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <footer>
        <div className="wrapper">
          <p>
            Copyright © 2024, Feathersoft.{' '}
            {translate(
              'patientPortal.allRightsReserved',
              'All Rights Reserved'
            )}
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
