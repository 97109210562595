import { translate } from 'Language/Translate';
import React from 'react';
import { useSelector } from 'react-redux';
import noResult_T1 from '../../../Assets/img/icons/theme-1/no-results-found.svg';
import noResult_T2 from '../../../Assets/img/icons/theme-2/no-results-found.svg';

const NoResultFound = (props) => {
  const { text = '', removeTopMargin = false, docs= '', getAvailableDate = () =>{}, availableDates, isDocAvailable } = props;
  const theme = useSelector((state) => state?.SettingsReducer?.theme);

  return (
    <div className="">
      <div className={`empty mb-5 ${removeTopMargin ? "" : "mt-3"}`}>
        <div className="no-results w-100">
          <img
            className="no-results-img"
            src={theme === "theme-2" ? noResult_T2 : noResult_T1}
            alt=""
          />
          <h6>
            {text === ""
              ? translate("common.noResultFound", "No results found.")
              : text}
          </h6>
          {(docs == "noDoc") && (availableDates.length != 0 && isDocAvailable) ? (
            <h6 className="mt-5">
              {translate(
                "common.noDocs",
                "Do you want to make an appointment for the next available date?"
              )}
              <a className='no-results-link' onClick={getAvailableDate}> {translate("common.clicknow", "Click Now")}</a>
            </h6>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NoResultFound;
