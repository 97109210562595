import network from '../Utils/Api/Network';
import { showToaster, ToasterTypes } from './toasterAction';

export const RECEIVE_LEAVE_LIST = 'RECEIVE_LEAVE_LIST';
export const UPDATE_LEAVE_DOC_NAME = 'UPDATE_LEAVE_DOC_NAME';
// export const UPDATE_LEAVE_DOC_SUGGESTION = 'UPDATE_LEAVE_DOC_SUGGESTION';
// export const UPDATE_LEAVE_DOC_IS_SELECTED = 'UPDATE_LEAVE_DOC_IS_SELECTED';
// export const UPDATE_LEAVE_SEARCH_VALUE = 'UPDATE_LEAVE_SEARCH_VALUE';
// export const UPDATE_LEAVE_STATUS = 'UPDATE_LEAVE_STATUS';
export const UPDATE_LEAVE_SEARCH_DATA = 'UPDATE_LEAVE_SEARCH_DATA';
export const CLEAR_LEAVE_DATA = 'CLEAR_LEAVE_DATA';
export const SET_LEAVE_CURRENT_PAGE = 'SET_LEAVE_CURRENT_PAGE';
export const SET_LEAVE_PER_PAGE = 'SET_LEAVE_PER_PAGE';
export const SET_LEAVE_COLUMN_SORT = 'SET_LEAVE_COLUMN_SORT';
export const LEAVE_BTN_LOADING = 'LEAVE_BTN_LOADING'

const receiveLeaveList = (payload) => ({
  type: RECEIVE_LEAVE_LIST,
  payload,
});
export const updateSearchData = (payload) => ({
  type: UPDATE_LEAVE_SEARCH_DATA,
  payload,
});
export const clearAllLeaveData = () => ({
  type: CLEAR_LEAVE_DATA,
});
export const updatePerPage = (payload) => ({
  type: SET_LEAVE_PER_PAGE,
  payload,
});
export const updateCurrentPage = (payload) => ({
  type: SET_LEAVE_CURRENT_PAGE,
  payload,
});
export const updateColumnSort = (payload) => ({
  type: SET_LEAVE_COLUMN_SORT,
  payload,
});
export const leaveBtnLoading = (payload) => ({
  type: LEAVE_BTN_LOADING,
  payload
});

export const getLeave = (data,setLoader=()=>{}) => {
  return (dispatch) => {
    return network.get({ url: '/leave/doctor', data }).then((response) => {
      const { isError, result } = response || {};
      if (isError) {
        setLoader(false)
        return;
      } else {
        dispatch(receiveLeaveList(result));
        setLoader(false)
      }
    });
  };
};

export const addLeave = (data, handleClose, fetchLeaveList, checkConflict, handleConflict) => {
  return (dispatch) => {
    dispatch(leaveBtnLoading(true))
    return network.post({ url: `/leave/doctor?check_conflicts=${checkConflict}`, data }).then((response) => {
      dispatch(leaveBtnLoading(false))
      const { isError, message, code } = response || {};
      if (isError) {
        if (code === 409) {
          handleConflict();
        } else {
          dispatch(showToaster(message, ToasterTypes.Failed));
        }
      } else {
        handleClose();
        fetchLeaveList();
        dispatch(showToaster(message, ToasterTypes.Success));
      }
    });
  };
};

export const updateLeave = (data, leaveId, handleClose, fetchLeaveList, checkConflict, handleConflict) => {
  return (dispatch) => {
    dispatch(leaveBtnLoading(true))
    return network
      .put({ url: `/leave/${leaveId}/doctor?check_conflicts=${checkConflict}`, data })
      .then((response) => {
        dispatch(leaveBtnLoading(false))
        const { isError, message, code } = response || {};
        if (isError) {
          if (code === 409) {
            handleConflict();
          } else {
            dispatch(showToaster(message, ToasterTypes.Failed));
          }
        } else {
          handleClose();
          fetchLeaveList();
          dispatch(showToaster(message, ToasterTypes.Success));
        }
      });
  };
};

export const updateLeaveStatus = (
  data,
  leaveId,
  updateLoading = () => {},
  fetchLeaveList = () => {},
  actions = {}
) => {
  const { conflictLeave = () => {}, successLeave = () => {} } = actions
  return (dispatch) => {
    dispatch(leaveBtnLoading(true))
    return network
      .patch({ url: `/leave/${leaveId}/doctor/status`, data })
      .then((response) => {
        dispatch(leaveBtnLoading(false))
        const { isError, message, code } = response || {};
        updateLoading(false);
        if (isError) {
          if (code === 409) {
            conflictLeave()
          }
          // dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          successLeave()
          fetchLeaveList();
          dispatch(showToaster(message, ToasterTypes.Success));
        }
      });
  };
};

export const deleteLeave = (
  leaveId,
  handleDeleteSuccess = () => {},
  updateLoading
) => {
  return (dispatch) => {
    return network
      .delete({ url: `/leave/${leaveId}/doctor` })
      .then((response) => {
        const { isError, message } = response || {};
        updateLoading(false);
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          handleDeleteSuccess();
          dispatch(showToaster(message, ToasterTypes.Success));
        }
      });
  };
};
