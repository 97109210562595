export const ToasterTypes = {
  Success: 'Success',
  Failed: 'Failed',
  Warning: 'Warning',
  Info: 'Info',
};

export const TOASTER_DISPLAY = 'TOASTER_DISPLAY';
export const TOASTER_CLOSE = 'TOASTER_CLOSE';
export const SET_REF_TOASTER = 'SET_REF_TOASTER';

export const showToaster = (message, type, duration = 3000) => {
  return (dispatch) => {
    dispatch({
      type: TOASTER_DISPLAY,
      toasterMessage: message,
      toasterType: type,
    });

    setTimeout(() => {
      dispatch(HideToaster());
    }, duration);
  };
};

export const HideToaster = () => {
  return {
    type: TOASTER_CLOSE,
  };
};

export const setToasterRef = (payload) => {
  return {
    type: SET_REF_TOASTER,
    payload
  };
};