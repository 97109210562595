import { useRef, useEffect } from "react";
// for previous state
const usePrevious = (value) => {

    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;

};

export default usePrevious;