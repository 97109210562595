import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { FetchUserDetails } from '../../Actions/loginAction';
import '../../Assets/style/login.css';
import {
  clearLocalStorage,
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../Utils/LocalStorage/local-storage';
import keycloak from 'Utils/keyCloak/keyCloak';
import { updateToken } from 'Utils/Api/Network';
import { PORTAL_TYPES } from 'Constants/constant';
import SunscriptionExpiry from '../Subscription/SubscriptionModal';
import Toaster from 'Components/Common/Toaster';
import { timeZoneDate } from 'Utils/DateFormatter/Date';

const LoginSetup = (props) => {
  const {
    userDetails,
    loggedIn,
    getUserDetails,
    subscriptionFailed,
    // toasterDisplay,
  } = props;
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const portalType = getLocalStorageItem('portalType');
        const accessToken = getLocalStorageItem('access_token');
        const refreshTokenExp = getLocalStorageItem('refresh_token_exp');
        const currentDate = timeZoneDate();
        const refreshExpiryTime = new Date(refreshTokenExp * 1000);
        // if user try to switch between portal from the same tab, then logout the user
        if (
          keycloak.didInitialize &&
          (portalType !== PORTAL_TYPES.provider ||
            (accessToken && currentDate > refreshExpiryTime))
        ) {
          clearLocalStorage();
          await keycloak.logout({
            redirectUri:
              window.location.origin + '/#/admin/auth/login?loginType=provider',
          });
          updateToken('');
        }
        // set portal
        setLocalStorageItem('portalType', PORTAL_TYPES.provider);

        // initialise keycloak
        keycloak
          .init({
            redirectUri:
              window.location.origin + '/#/admin/auth/login?loginType=provider',
            onLoad: 'login-required',
          })
          .then(async (authenticated) => {
            if (authenticated) {
              const tokenDetails = {
                access_token: keycloak.token,
                refresh_token: keycloak.refreshToken,
                refresh_token_exp: keycloak.refreshTokenParsed.exp,
              };
              await updateToken(keycloak.token);
              getUserDetails(tokenDetails);
            }
          });
      } catch (error) {
        console.error('Failed to initialize adapter:', error);
      }
    })();
  }, []);

  // on login success redirect to appointments
  useEffect(() => {
    if (userDetails?.user_id && loggedIn) {
      const token = getLocalStorageItem('access_token');
      if (token) history.push('/admin');
    }
  }, [userDetails, loggedIn]);

  return (
    <>
      <div
        style={{
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          backgroundColor: '#ffff',
          zIndex: 99,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className="stand_by_screen"
      >
        <span className="bubble_loader"></span>
      </div>
      {subscriptionFailed ? <SunscriptionExpiry /> : null}
      { <Toaster />}
    </>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.LoginReducer.loginDetails,
  loggedIn: state.LoginReducer.loggedIn,
  subscriptionFailed: state.SettingsReducer.subscriptionFailed,
  toasterDisplay: state.ToasterReducer.toasterDisplay,
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: (tokenDetails) => dispatch(FetchUserDetails(tokenDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginSetup);
