import React, { useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useIntl } from 'react-intl';

const PhoneNumberInput = ({
  value,
  defaultCountry = false,
  onChange,
  editStatus,
  validationErr,
  onFocus,
  containerClass = '',
  numberLength,
  setNumberLength,
  focusRef,
  setCode,
  disabled=false,
  editableInput = true,
}) => {
  let property = {};
  if (defaultCountry) {
    property['country'] = 'in';
  }
  const mobileRef = useRef(null)
  const intl = useIntl();
  const countDot = (string) => {
    let count = 0;
    string.split('').forEach((x) => { if (x === '.') count++ })
    return count
  }

const handlePhoneNumber = (phone,i)=>{
  if(editableInput){
    onChange(i.dialCode,i)
  }
}

  useEffect(() => {
    if(disabled){
      focusRef.current.disabled = true
    }
    const data = mobileRef?.current?.getCountryData()
    if (data?.format) {
      setCode(data.countryCode)
      const count = countDot(data.format) - data.dialCode.length
      if (count !== numberLength) {setNumberLength(count);}
    }
  }, [value, numberLength])
  return (
    <span
      onDragStart={(e)=>{
        e.preventDefault()
      }}
    >
      <PhoneInput
        {...property}
        // country={value?value'in':""}
        value={value}
        inputProps={{
          // autoFocus: true, 
          value:value?`+${value}`:"",
          ref:focusRef,
        }}
        disabled={editStatus}
        ref={mobileRef}
        onChange={handlePhoneNumber}
        autoFormat={false}
        inputStyle={{ width: '100%', borderColor: validationErr && 'red' }}
        dropdownStyle={{ height: 90 }}
        onFocus={onFocus}
        containerClass={containerClass}
        countryCodeEditable={false}
        enableSearch={true}
        searchNotFound={intl.formatMessage({
          id: 'common.noData',
          defaultMessage: 'No data found',
        })}
        searchPlaceholder={intl.formatMessage({
          id: 'common.search',
          defaultMessage: 'Search',
        })}
      />
    </span>

  );
};

export default PhoneNumberInput;
