import React, { useEffect } from 'react';
import '../../../Assets/style/conformPopup.css';
import { LEAVE_STATUS } from '../../../Constants/constant';
import { translate } from '../../../Language/Translate';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import ButtonLoader from 'Components/Common/ButtonLoader';
import FocusTrap from 'focus-trap-react'
import { dateinFormatDDMMMYYYY } from 'Utils/DateFormatter/Date';
const ConformLeavePopup = (props) => {
  const { onConfirm, leaveStatus, onClose, comment, setComment, isBtnLoading = false, leaveDetails } = props;
  const intl = useIntl();
  useEffect(() => {
    document.body.classList.add('modal-overflow');
    return () => document.body.classList.remove('modal-overflow');
  }, []);

  const btnText = `${intl.formatMessage({
    id: 'common.yes',
    defaultMessage: 'Yes',
  })}${', '}${leaveStatus === LEAVE_STATUS.approved
    ?
    intl.formatMessage({
      id: 'leaveApproval.accept',
      defaultMessage: 'Accept',
    })
    : leaveStatus === LEAVE_STATUS.cancelled
      ?
      intl.formatMessage({
        id: 'common.cancel',
        defaultMessage: 'CANCEL',
      })
      : intl.formatMessage({
        id: 'common.reject',
        defaultMessage: 'REJECT',
      })}`


  const leaveRange = () => {
    const { from, to } = leaveDetails
    if (from === to) {
      return dateinFormatDDMMMYYYY(from) 
    } else {
      return `${dateinFormatDDMMMYYYY(from)} ${intl.formatMessage({
        id: 'common.to',
        defaultMessage: 'to',
      })} ${dateinFormatDDMMMYYYY(to)}`
    }
  }

  return (
    <>
     <FocusTrap
     focusTrapOptions={{
      clickOutsideDeactivates:true
    }}>
      <div className="AppointmentModal d-flex">
        <div className={`modalContent leaveConfirm_popup modal_xs`}>
          <div className="modalHeader">
            <div className="row">
              <div className="col-md-12">
                <h4>
                  {leaveStatus === LEAVE_STATUS.cancelled ? 
                    translate(
                      'leaveApproval.cancelLeaveReq',
                      'Cancel Leave Request'
                    ) : leaveStatus === LEAVE_STATUS.rejected ? 
                    translate(
                      'leaveApproval.leaveReject',
                      'Reject Leave Request'
                    ) :
                    translate(
                      'leaveApproval.leaveAppr',
                      'Leave Approval'
                    )
                  }
                  <CloseIcon className='close-icon'
                    style={{ float: 'right', cursor: 'pointer', color: '#75808E' }}
                    onClick={onClose}
                  />
                </h4>
              </div>
              <div className="col-md-12">
                <div className="border_btm mt-2"></div>
                <div
                  className="modalContentarea-popup pb-0"
                  style={{
                    maxHeight: 430,
                    marginBottom: 10,
                  }}
                >
                  <div>
                    <div className="modalContentarea-popup">
                      <div className="mt-2 justify-content-center">
                          <div style={{ marginBottom: '15px' }}>
                            <p className='label'>
                              {translate(
                                'leaveApproval.leaveDates',
                                'Leave Date(s)'
                              )}{' '}
                            </p>
                            <div>
                            <p style={{ fontSize: '12px' }}>
                            {leaveRange()}
                            </p>
                            </div>
                          </div>
                        <div>
                          <p className='label'>
                            {translate(
                              'leaveApproval.areYouWantTo',
                              'Are you sure you want to'
                            )}{' '}
                            <span style={{ textTransform: 'lowercase' }}>
                              {leaveStatus === LEAVE_STATUS.approved
                                ? translate('leaveApproval.accept', 'Accept')
                                : leaveStatus === LEAVE_STATUS.cancelled
                                ? translate('leaveApproval.cancel', 'Cancel')
                                : translate('leaveApproval.reject', 'Reject')}
                            </span>{' '}
                            {translate(
                              'leaveApproval.thisLeave',
                              'these leave(s)?'
                            )}
                          </p>
                        </div>
                        <div
                          className="col-12"
                        >
            <div className="text-area-wrap">
            <textarea
                            className="form-control input"
                            id="leaveComment"
                            placeholder={intl.formatMessage({
                              id: 'leaveApproval.enterComments',
                              defaultMessage: 'Enter Comments',
                            })}
                            rows="3"
                            maxLength="250"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            style={{
                              resize: 'none',
                              marginLeft: 0,
                              marginRight: 0,
                            }}
                          />
                              </div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal_footer">
                      <div className="">
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <ul className="admin-modal-footer">
                              {!(leaveStatus === LEAVE_STATUS.rejected ||
                                    leaveStatus === LEAVE_STATUS.cancelled) &&
                                  <li onClick={onClose} className="ps-0">
                                    <button
                                      type="button"
                                      className="btn btn-outlined-secondary fit-content px-4"
                                      id="cancel"
                                    >
                                      {' '}
                                      {translate('common.no', 'NO')}
                                    </button>
                                  </li>
                               }
                              <li onClick={isBtnLoading ? () => { } : onConfirm} className="ps-0">
                                <button
                                  type="button"
                                  className={`btn fit-content px-4 loader-btn ${
                                    leaveStatus === LEAVE_STATUS.rejected ||
                                    leaveStatus === LEAVE_STATUS.cancelled
                                      ? 'btn-outlined-danger'
                                      : 'btn-primary'
                                  }`}
                                  id="save"
                                  disabled={isBtnLoading}
                                >
                                  {isBtnLoading ? <ButtonLoader text={btnText} /> :
                                     btnText
                                  }
                                </button>
                              </li>
                              {(leaveStatus === LEAVE_STATUS.rejected ||
                                leaveStatus === LEAVE_STATUS.cancelled )&&
                                <li onClick={onClose} className="ps-0">
                                  <button
                                    type="button"
                                    className="btn btn-primary fit-content px-4"
                                    id="cancel"
                                  >
                                    {' '}
                                    {translate('common.no', 'NO')}
                                  </button>
                                </li>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </FocusTrap>
    </>
  );
};

ConformLeavePopup.prototype = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  leaveStatus: PropTypes.string,
};

export default ConformLeavePopup;
