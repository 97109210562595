import network from '../Utils/Api/Network';
import { receiveDoctorDetails, receiveUserDetails, receiveUserPermissions } from './loginAction';
import { showToaster, ToasterTypes } from './toasterAction';

export const RECEIVE_USERS_LIST = 'RECEIVE_USERS_LIST';
export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const CLEAR_ALL_USER_DATA = 'CLEAR_ALL_USER_DATA';
export const UPDATE_USER_SEARCH_DATA = 'UPDATE_USER_SEARCH_DATA';
export const UPDATE_PATIENT_SEARCH_DATA = 'UPDATE_PATIENT_SEARCH_DATA';
export const UPDATE_USER_CURRENT_PAGE = 'UPDATE_USER_CURRENT_PAGE';
export const UPDATE_USER_PER_PAGE = 'UPDATE_USER_PER_PAGE';
export const UPDATE_USER_PAGINATION = 'UPDATE_USER_PAGINATION';
export const SET_USER_COLUMN_SORT = 'SET_USER_COLUMN_SORT';
export const SET_USER_PATIENT_COLUMN_SORT = 'SET_USER_PATIENT_COLUMN_SORT';
export const USER_BTN_LOADING = 'USER_BTN_LOADING';
export const GET_PATIENTS_DATA = 'GET_PATIENTS_DATA';
export const VERIFY_BTN_LOADING = 'VERIFY_BTN_LOADING'

const receiveUserList = (payload) => ({
  type: RECEIVE_USERS_LIST,
  payload,
});

const receiveUserData = (payload) => ({
  type: RECEIVE_USER_DATA,
  payload,
});

const getPatientData = (payload) => ({
  type: GET_PATIENTS_DATA,
  payload,
});

export const clearUserData = () => ({
  type: RECEIVE_USER_DATA,
});
export const clearAllUserData = () => ({
  type: CLEAR_ALL_USER_DATA,
});
export const updateSearchData = (payload) => ({
  type: UPDATE_USER_SEARCH_DATA,
  payload,
});
export const updatePatientSearchData = (payload) => ({
  type: UPDATE_PATIENT_SEARCH_DATA,
  payload,
});
export const updatePatientPagination = (payload) => ({
  type: UPDATE_USER_PAGINATION,
  payload,
});
export const updatePerPage = (payload) => ({
  type: UPDATE_USER_PER_PAGE,
  payload,
});
export const updateCurrentPage = (payload) => ({
  type: UPDATE_USER_CURRENT_PAGE,
  payload,
});
export const updateColumnSort = (payload) => ({
  type: SET_USER_COLUMN_SORT,
  payload,
});
export const updatePatientColumnSort = (payload) => ({
  type: SET_USER_PATIENT_COLUMN_SORT,
  payload,
});
export const userBtnLoading = (payload) => ({
  type: USER_BTN_LOADING,
  payload,
});
export const verifyBtnLoading = (payload) => ({
  type: VERIFY_BTN_LOADING,
  payload,
});

export const fetchUserList = (data, setLoader = () => {}) => {
  return (dispatch) => {
    return network.get({ url: '/provider/users', data }).then((response) => {
      const { isError, result } = response;
      if (isError) {
        dispatch(receiveUserList({}));
      } else {
        dispatch(receiveUserList(result));
      }
      setLoader(false);
    });
  };
};

export const fetchUserData = (id) => {
  return (dispatch) => {
    return network.get({ url: `/provider/user/${id}` }).then((response) => {
      const { isError, result } = response;
      if (isError) {
        dispatch(receiveUserData({}));
      } else {
        dispatch(receiveUserData(result));
      }
    });
  };
};

export const addNewUser = (data, handleSuccess = () => {}) => {
  return (dispatch) => {
    dispatch(userBtnLoading(true));
    return network.post({ url: '/provider/user', data }).then((response) => {
      dispatch(userBtnLoading(false));
      const { isError, message } = response;
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        dispatch(showToaster(message, ToasterTypes.Success));
        handleSuccess('add');
      }
    });
  };
};

export const FetchUpdatedUserDetails = () => {
  return (dispatch) => {
    return network.get({ url: '/user/token/info' }).then((response) => {
      const { isError, result, message } = response;
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        dispatch(receiveDoctorDetails(result?.user_role_details?.doctor_details))
        dispatch(receiveUserDetails(result?.user_details));
        dispatch(receiveUserPermissions(result?.user_role_access_permissions));
      }
    });
  };
};

export const updateUser = (
  id,
  data,
  handleSuccess = () => {},
  isMe = false
) => {
  return (dispatch) => {
    dispatch(userBtnLoading(true));
    return network
      .put({ url: `/provider/user/${id}`, data })
      .then((response) => {
        dispatch(userBtnLoading(false));
        const { isError, message } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          dispatch(showToaster(message, ToasterTypes.Success));
          handleSuccess('edit');
          if (isMe) dispatch(FetchUpdatedUserDetails());
        }
      });
  };
};

export const updateUserStatus = (id, data, handleSuccess = () => {}) => {
  return (dispatch) => {
    return network
      .patch({ url: `/provider/user/${id}/status`, data })
      .then((response) => {
        const { isError, message } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          dispatch(showToaster(message, ToasterTypes.Success));
          handleSuccess({}, false);
        }
      });
  };
};

export const removeUser = (id, handleSuccess = () => {}) => {
  return (dispatch) => {
    dispatch(userBtnLoading(true));
    return network.delete({ url: `/provider/user/${id}` }).then((response) => {
      dispatch(userBtnLoading(false));
      const { isError, message } = response;
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        dispatch(showToaster(message, ToasterTypes.Success));
        handleSuccess({}, false);
      }
    });
  };
};

export const Fetch_Patients_Data = (data,onSuccess=()=>{},onError=()=>{}) => {
  return (dispatch) => {
    return network
    .get({
      url: '/patients',
      data,
    })
      .then((response) => {
        const { result, isError } = response || {};
        if (!isError ) {
          dispatch(getPatientData({patients:result?.patients?result.patients:[],page_details:result?.page_details}));
          onSuccess(response)
        } 
        else{
          onError(response)
        }
      });
  };
}

export const verifyPhoneMail_Otp = (
  payload,
  handleSuccess = () => {},
  handleError= () => {}
) => {
  return (dispatch) => {
    dispatch(verifyBtnLoading(true));
    return network
      .put({ url: `/user/${payload.id}/update/user-identifier/${payload.type}/verify`, data:payload.data })
      .then((response) => {
        dispatch(verifyBtnLoading(false));
        const { isError, message } = response;
        if (isError) {
          handleError({response})
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          handleSuccess({response})
          dispatch(showToaster(message, ToasterTypes.Success));
        }
      });
  };
};

//updating patient from admin side
export const updatePatientDetails = (
  payload,
  handleSuccess = () =>{},
  handleError = () => {}
) => {
  return (dispatch) => {
     dispatch(userBtnLoading(true))
    return network.put({url: `/patient/${payload.patientId}`, data:{data:payload.data} }).then((response) => {
      dispatch(userBtnLoading(false))
      const { isError, message } = response;
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
        handleError(response)
      } else {
        dispatch(showToaster(message, ToasterTypes.Success));
        handleSuccess(response);
      }
    });
  };
};