export const  MANAGE= "manage"
export const  UPDATE_DOMAIN= "update_domain"
export const  SETTINGS_UPDATE= "settings_update"
export const  READ_DETAILS= "read_details"
export const  READ= "read"
export const  CREATE= "create"
export const  UPDATE= "update"
export const  DELETE= "delete"
export const  STATUS_UPDATE= "status_update"
export const  SCHEDULE_UPDATE= "schedule_update"
export const  SCHEDULE_READ= "schedule_read"
export const  COMPLETE_STATUS= "complete_status"
export const  BULK_CANCEL= "bulk_cancel"
export const  READ_PRESCRIPTIONS= "read_prescriptions"
export const  READ_RECORDS= "read_records"
export const  APPLY_LEAVE= "apply_leave"
export const  MODIFY_LEAVE= "modify_leave"
export const  READ_LEAVE= "read_leave"
export const  APPROVE_LEAVE= "approve_leave"
export const  CANCEL_LEAVE= "cancel_leave"
export const  DELETE_LEAVE= "delete_leave"
export const  LINK_PROFILE= "link_profile"
export const  DELETE_PROFILE_LINK= "delete_profile_link"
export const  READ_LINK_PROFILE= "read_link_profile"
