import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getLocalStorageItem } from '../LocalStorage/local-storage';
import PropTypes from 'prop-types';
import { timeZoneDate } from 'Utils/DateFormatter/Date';

const isAuthenticated = () => {
  const accessToken = getLocalStorageItem('access_token');
  const refreshTokenExp = getLocalStorageItem('refresh_token_exp');
  const currentDate = timeZoneDate();
  const refreshExpiryTime = new Date(refreshTokenExp * 1000);
  if (!accessToken || accessToken === '') {
    return false;
  } else if (accessToken && currentDate > refreshExpiryTime) {
    return false;
  } else return true;
};

const PrivateRoute = ({ component: Component, redirectTo, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={redirectTo} />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  redirectTo: PropTypes.string,
};

export default PrivateRoute;
