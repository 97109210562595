import React, { useState, useRef } from 'react';

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  //   Crop,
  //   PixelCrop,
  //   convertToPixelCrop,
} from 'react-image-crop';
import { canvasPreview } from './canvas';
import 'react-image-crop/dist/ReactCrop.css';
import { useDebounceEffect } from 'Hooks/useDebounceEffect';
import CloseIcon from '@mui/icons-material/Close';
import { translate } from 'Language/Translate';
import FocusTrap from 'focus-trap-react'
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function CropImage({
  imgSrc,
  setShowCrop,
  setData,
  title = '',
  onClose,
}) {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const aspect = 1;

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  async function onDownloadCropClick() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      setData(reader.result);
      setShowCrop(false);
    };
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    [completedCrop]
  );

  return (
    <FocusTrap
    focusTrapOptions={{
     clickOutsideDeactivates:true
   }}>
    <div className="AppointmentModal d-flex">
      <div className={`modalContent leaveConfirm_popup modal_xs`}>
        <div className="modalHeader">
          <div className="row">
            <div className="col-md-12">
              <h4>
                {title}
                <CloseIcon className='close-icon'
                  style={{ float: 'right', cursor: 'pointer', color: '#75808E' }}
                  onClick={onClose}
                />
              </h4>
            </div>
          </div>
        </div>
        <div className="modalContentarea-popup cropper-container pb-0" style={{ margin: 10 }}>
          {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => {
                  setCompletedCrop(c);
                }}
                aspect={aspect}
                keepSelection
                minWidth={100}
                style={{ maxWidth: '100%', objectFit: 'contain', height: 200 }}
                minHeight={100}
                circularCrop
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{
                    transform: `scale(${1}) rotate(${0}deg)`,
                    maxWidth: '100%',
                    objectFit: 'contain',
                    height: 200,
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
          )}
          {!!completedCrop && (
            <div className="d-none">
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </div>
          )}
        </div>
        <div className="col-md-12">
          <ul className="modalBtm mt-0 gap-3 d-flex justify-content-end admin-modal-footer">
            <li onClick={onClose} className="ms-0 d-block d-sm-inline">
              <button type="button" className="btn btn-light mb-0 px-4" id="cancel">
                {translate('common.cancel', 'CANCEL')}{' '}
              </button>
            </li>

            <li
              // onClick={isBtnLoading ? () => {} : onSubmit}
              className="ms-0 d-block d-sm-inline ps-0"
            >
              <button
                // disabled={isBtnLoading}
                type="button"
                onClick={onDownloadCropClick}
                className="btn btn-primary mb-0 loader-btn px-3"
                id="save"
              >
                Save Changes
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </FocusTrap>
  );
}
