/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DoctorsApi } from '../../Utils/Api/DoctorsApi';
import { DepartmentsApi } from '../../Utils/Api/DepartmentApi';
import ConformPopup from '../Common/ConformPopup';
import Loader from '../Common/Loader';
import Pagination from '../Common/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { showToaster, ToasterTypes } from '../../Actions/toasterAction';
import CustomSwitch from '../Common/Switch';
import HeaderFields from '../Common/HeaderFeilds';
// import { DEPARTMENT_STATUS } from '../../Constants/constant';
import ReactSelectDropDown from '../Common/ReactSelectDropDown';
import { useIntl } from 'react-intl';
import { translate } from '../../Language/Translate';
import {
  receiveSearchData,
  clearSearchData,
  SetCurrentPage,
  SetPerPage,
  setColumnSort,
} from '../../Actions/doctorListingAction';
import withPermissionCheck from '../../Utils/WithUtils/WithPermission';
import { DOCTOR } from '../../Constants/feature';
import { DOCTORS_HEADERS } from '../../Constants/constant';
import { USER_TYPE } from '../../Constants/userTypes';
import '../../Assets/style/doctorList.css';
import TableColumnHr from 'Components/Common/TableColumnHr';
import Tooltip from '@mui/material/Tooltip';
import usePrevious from 'Hooks/usePrevious';
import useDidMountEffect from 'Hooks/useDidMountEffect';
import { isEqual } from 'lodash';
import {
  CREATE,
  DELETE,
  SCHEDULE_UPDATE,
  STATUS_UPDATE,
  UPDATE,
} from 'Constants/scopes';
import NoResultFound from 'Components/Common/NoResult';
import AppointmentConflictPopup from 'Components/Doctors/ApptConflictPopup';
import { convertToBase64URI } from 'Utils/Services/Authenticator';
import { listAllDoc, removeDrPrefix } from 'Utils/StringFormatter/String';
import TableWrapper from 'Components/Common/TableWrpper';

let apiTimeout = null;

const deleteIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.1085 3.7371C11.0299 3.5311 8.93875 3.42499 6.85388 3.42499C5.61793 3.42499 4.38199 3.48741 3.14604 3.61225L1.87264 3.7371" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.30582 3.10625L5.44315 2.2875C5.54303 1.69375 5.61793 1.25 6.67285 1.25H8.3083C9.36322 1.25 9.44437 1.71875 9.538 2.29375L9.67533 3.10625" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.7665 5.71252L11.3608 12.0063C11.2921 12.9875 11.2359 13.75 9.49435 13.75H5.48689C3.74533 13.75 3.68915 12.9875 3.62049 12.0063L3.21475 5.71252" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.44814 10.3125H8.52677" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.93004 7.8125H9.05112" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const viewIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.50007 9.73757C6.26257 9.73757 5.26257 8.73757 5.26257 7.50007C5.26257 6.26257 6.26257 5.26257 7.50007 5.26257C8.73757 5.26257 9.73757 6.26257 9.73757 7.50007C9.73757 8.73757 8.73757 9.73757 7.50007 9.73757Z" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.1938 9.1187C13.7563 8.23745 13.7563 6.7562 13.1938 5.87495C11.7625 3.62495 9.70627 2.32495 7.50002 2.32495C5.29377 2.32495 3.23752 3.62495 1.80627 5.87495C1.24377 6.7562 1.24377 8.23745 1.80627 9.1187C3.23752 11.3687 5.29377 12.6687 7.50002 12.6687C9.70627 12.6687 11.7625 11.3687 13.1938 9.1187Z" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const editIcon = <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.72757 7.68029C3.53419 7.88654 3.34706 8.29279 3.30963 8.57404L3.07883 10.599C2.99774 11.3303 3.52172 11.8303 4.24531 11.7053L6.25388 11.3615C6.53458 11.3115 6.92757 11.1053 7.12094 10.8928L12.2422 5.46154C13.128 4.52404 13.5272 3.45529 12.1486 2.14904C10.7763 0.855287 9.73458 1.31154 8.84881 2.24904L3.72757 7.68029Z" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.99438 3.15625C8.26261 4.88125 9.65988 6.2 11.394 6.375" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.44946 13.75H13.6775" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const manageSlotIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.1397 0.582031V2.57337" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.44994 0.582031V2.57337" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.15273 5.28821H12.437" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.7688 7.71765V4.89659C12.7688 2.90526 11.7732 1.5777 9.44994 1.5777H4.13971C1.81648 1.5777 0.820811 2.90526 0.820811 4.89659V10.5387C0.820811 12.53 1.81648 13.8576 4.13971 13.8576H7.08983" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.79185 8.34827H6.79857" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.3351 8.34825H4.34106" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.3351 10.3395H4.34106" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.3828 12.9778L10.028 13.489M12.985 9.22892L12.6449 9.71883M12.3179 13.1911L12.5417 13.6872M10.4287 9.00111L10.7021 9.60755M9.46369 11.2098L8.92061 11.1737M14.0498 11.5146L13.386 11.4705M12.4675 9.97444C13.2322 10.5052 13.4218 11.5554 12.891 12.32C12.3602 13.0846 11.3101 13.2742 10.5454 12.7435C9.7808 12.2127 9.5912 11.1626 10.122 10.3979C10.6527 9.63328 11.7029 9.44368 12.4675 9.97444Z" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


const DoctorListing = (props) => {
  const { history, ACCESS } = props;
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.LoginReducer.user_type);
  const searchData = useSelector(
    (state) => state.doctorListingReducer.searchData
  );
  const paginationData = useSelector(
    (state) => state.doctorListingReducer.paginationData
  );
  const columnSort = useSelector(
    (state) => state.doctorListingReducer.columnSort
  );

  const userDetails = useSelector((state) => state.LoginReducer.loginDetails);

  const { perPage, currentPage } = paginationData;
  const intl = useIntl();
  const [DoctorsList, updateDoctorsList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [showConformPopup, updateShowConformPopup] = useState(false);
  const [deleteid, updateDeleteid] = useState('');
  const [isLoading, updateLoading] = useState(false);
  const [totalCount, SetTotalCount] = useState(null);
  const [searchValue, updateSearchValue] = useState('');
  const [isSelect, setIsSelect] = useState(false);
  const [deptSuggestion, setDeptSuggestion] = useState([]);
  const [selectedDepart, setSelectedDepart] = useState([]);
  const [deptLoading, setDeptLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [searchLoad, setSearchLoad] = useState({});
  const [showApptConflictPopup, setShowApptConflictPopup] = useState(false);
  const [conflictId, setConflictId] = useState('');

  useEffect(() => {
    getDoctorsList({
      search: searchData?.searchValue,
      dept_id: searchData?.selectedDepart?.value,
      sort: 'doctor_status'
    });
    getAllDeptList();
    updateSearchValue(searchData?.searchValue ?? '');
    setSelectedDepart(searchData?.selectedDepart ?? []);
  }, []);

  const prevSortVal = usePrevious(columnSort);

  useDidMountEffect(() => {
    if (!isEqual(prevSortVal, columnSort)) {
      getDoctorsList({
        search: searchData?.searchValue,
        dept_id: searchData?.selectedDepart?.value,
      });
    }
  }, [columnSort]);

  useEffect(() => {
    if (!isSelect) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadOptions(searchValue);
      }, 300);
      apiTimeout = timeout;
    }
  }, [searchValue, isSelect]);


  const loadOptions = async (inputValue) => {
    const fetchedOptions = [];
    if (inputValue?.trim()) {
      let id = new Date().getTime()
      setSearchLoad((prev)=>({
        ...prev,
        [id]:true
      }));
      await DoctorsApi.getDoctorsList({
        search: listAllDoc(inputValue) ? '' : removeDrPrefix(inputValue),
        limit: 0,
      }).then(({ response }) => {
        setSearchLoad((prev)=>({
          ...prev,
          [id]:false
        }));
        if (response?.doctors) {
          response.doctors.forEach((doctors) => {
            fetchedOptions.push({
              label: `Dr. ${doctors.name} ${doctors.last_name}`,
              value: `Dr. ${doctors.name} ${doctors.last_name}`,
            });
          });
        } else {
          fetchedOptions.push({
              value: '',
              label: `${intl.formatMessage({
                id: 'common.noData',
                defaultMessage: 'No data found.',
              })}`,
              isDisabled: true,
          })
        }
      });
    }
    setDeptSuggestion(fetchedOptions);
  };

  const onChangeSearch = (event) => {
    const { value } = event.target;
    updateSearchValue(value);
    setIsSelect(false);
  };

  const handleDeptNameSelect = (name) => {
    updateSearchValue(name);
    setIsSelect(true);
    setDeptSuggestion([]);
  };

  const isApplied = (
    (searchData?.searchValue?.trim() !== '' && searchData?.searchValue) ||
    (searchData?.selectedDepart !== [] && searchData?.selectedDepart?.value !== undefined)
  )

  const getDoctorsList = ({ search, dept_id, pageNo, perPageCount, sort } = {}) => {
    updateLoading(true);
    const sortKey = Object.keys(columnSort)[0];
    const config = {
      page: pageNo || currentPage,
      limit: perPageCount || perPage,
      acl: userType === USER_TYPE.appointmentCordinator,
      search:
        search || search === ''
          ? removeDrPrefix(search) || undefined
          : searchData?.searchValue
            ? removeDrPrefix(searchData?.searchValue)
            : undefined,
      dept_id:
        dept_id || dept_id === ''
          ? dept_id || undefined
          : searchData?.selectedDepart?.value
            ? searchData?.selectedDepart?.value
            : undefined,
      sort: sort ? 'doctor_status,-created_at' : 
      sortKey ? columnSort[sortKey] === 'asc'
          ? sortKey
          : `-${sortKey}`
        : undefined,
    };
    DoctorsApi.getDoctorsList(config).then((res) => {
      updateLoading(false);
      if (res.error) {
        return;
      }
      if (res.response) {
        updateDoctorsList(res.response?.doctors);
        SetTotalCount(res.response?.page_details?.total_documents);
      } else {
        updateDoctorsList([]);
      }
    });
  };

  const getAllDeptList = () => {
    setDeptLoading(true);
    const config = {
      limit: 0,
      acl: userType === USER_TYPE.appointmentCordinator,
      sort: "dept_name"
    };
    DepartmentsApi.FetchAllDepartmentList(config).then((res) => {
      setDeptLoading(false);
      if (res.error) {
        return;
      }
      if (res.response) {
        setDeptList(res.response?.departments);
      } else {
        setDeptList([]);
      }
    });
  };

  const deleteDoctorList = async (id) => {
    // updateShowConformPopup(false);
    // updateLoading(true);
    setBtnLoading(true);
    DoctorsApi.DeleteDoctorsList(id).then(({ error, message }) => {
      setBtnLoading(false);
      if (error) {
        updateShowConformPopup(false);
        updateLoading(false);
        dispatch(showToaster(error, ToasterTypes.Failed));
        return;
      } else {
        updateShowConformPopup(false);
        // updateLoading(false);
        dispatch(showToaster(message, ToasterTypes.Success));
        if (DoctorsList.length === 1 && currentPage > 1) {
          dispatch(SetCurrentPage(currentPage - 1));
          getDoctorsList({ pageNo: currentPage - 1 });
        } else {
          getDoctorsList();
        }
      }
    });
  };

  const onDelete = (data) => {
    updateShowConformPopup(true);
    updateDeleteid(data);
  };

  const handleChange = (item, id) => {
    const data = {
      data: {
        doctor_status: item == true ? 'active' : 'inactive',
      },
    };
    DoctorsApi.changeStatus(id, data).then(({ error, message, code }) => {
      if (error) {
        if (code === 409) {
          const encodedValue = convertToBase64URI({ doctor_ids: [id] })
          setConflictId(`doctor:${encodedValue}`)
          setShowApptConflictPopup(true);
        } else {
          dispatch(showToaster(error, ToasterTypes.Failed));
        }
      } else {
        updateLoading(true);
        dispatch(showToaster(message, ToasterTypes.Success));
        getDoctorsList({ sort: 'doctor_status' })
      }
    });
  };
  // on pagination change
  const handleCurrentPageChange = (pageNo) => {
    dispatch(SetCurrentPage(pageNo));
    getDoctorsList({ pageNo });
  };

  // handel perPage change
  const handlePerPageChange = (count) => {
    dispatch(SetCurrentPage(1));
    dispatch(SetPerPage(count));
    getDoctorsList({ pageNo: 1, perPageCount: count });
  };

  const handleReset = () => {
    dispatch(clearSearchData());
    updateSearchValue('');
    setSearchLoad({})
    setSelectedDepart([]);
    dispatch(SetCurrentPage(1));
    dispatch(SetPerPage(25));
    dispatch(setColumnSort({}));
    if (
      Object.keys(searchData).length ||
      searchValue ||
      selectedDepart.length !== 0 ||
      currentPage !== 1 ||
      perPage !== 25 ||
      isApplied
    ) {
      getDoctorsList({
        search: '',
        dept_id: '',
        pageNo: 1,
        perPageCount: 25,
      });
    }
  };

  const handleApplyClick = () => {
    if (
      (searchValue?.trim() !== '' && searchValue) ||
      (selectedDepart !== [] && selectedDepart.value !== undefined) ||
      isApplied
    ) {
      getDoctorsList({
        search: searchValue,
        dept_id: selectedDepart.value,
        pageNo: 1,
      });
      dispatch(
        receiveSearchData({
          searchValue,
          selectedDepart: selectedDepart || '',
        })
      );
      dispatch(SetCurrentPage(1));
    }
  };

  const AddDoctor = () => {
    history.push('/admin/doctorslisting/adddoctor');
  };
  const handleKeyPress = (e) => {
    if (e && e.key === 'Enter') {
      if (searchValue || selectedDepart) {
        handleApplyClick();
      }
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [searchValue, selectedDepart]);

  const onApptConflictConfirm = () => {
    setShowApptConflictPopup(false);
    history.push(`/admin/doctorslisting/reschedule/${conflictId}`);
  };

  // handle outside close of filter
  const handleOutsideClose = () => {
    updateSearchValue(searchData?.searchValue ?? '');
    setSelectedDepart(searchData?.selectedDepart ?? []);
    setIsSelect(true);
  }

  return (
    <>
      <div className="doctor-listing-page">
        {showConformPopup && (
          <ConformPopup
            onClose={() => updateShowConformPopup(false)}
            onConfirm={() => {
              deleteDoctorList(deleteid);
            }}
            message={translate(
              'common.doYouWantToDeleteDoctor',
              'Are you sure you want to delete this doctor?'
            )}
            isBtnLoading={btnLoading}
          />
        )}
        <HeaderFields
          totalCount={totalCount}
          onApply={handleApplyClick}
          onReset={handleReset}
          onAddBtnClick={ACCESS(CREATE) ? AddDoctor : ''}
          childBreakPt={11}
          btnBreakPt={1}
          applyBtnCls={'col-sm-2'}
          isApplied={isApplied}
          handleOutsideClose={handleOutsideClose}
        >
          <div className="col-md-6 pe-md-0">
              <div className="form-group mb-0">
              <label htmlFor="">{intl.formatMessage({
                    id: 'doctors.doctorName',
                    defaultMessage: 'Doctor Name',
                  })}</label>
              <div className={`search-input ${searchValue !== ''&& !isSelect && Object.values(searchLoad).includes(true) && 'loading'}`}>
                <input
                  type="search"
                  maxLength={25}
                  className="form-control down-arrow"
                  onChange={(event) => onChangeSearch(event)}
                  placeholder={intl.formatMessage({
                    id: 'common.search',
                    defaultMessage: 'Search',
                  })}
                  value={searchValue}
                  autoComplete="off"
                  onBlur={() => setTimeout(() => setDeptSuggestion([]), 400)}
                  onFocus={() => {
                    if (searchValue) loadOptions(searchValue);
                  }}
                />
              </div>
              {deptSuggestion.length && searchValue &&
               !isSelect &&
               !Object.values(searchLoad).includes(true) &&
                searchValue !== searchData?.searchValue ? (
                <div className="customSearchList">
                  <ul style={{ marginBottom: 1 }}>
                    {deptSuggestion.map((dept, index) => (
                      <li
                        key={index}
                        className={`suggestion-item ${dept.isDisabled ? 'disabled' : ''}`}
                        onClick={() => !dept.isDisabled && handleDeptNameSelect(dept.value)}
                      >
                        {dept.label}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-0">
              <label htmlFor="">Department</label>
              <ReactSelectDropDown
                options={
                  deptList?.map((item) => ({
                    label: item.dept_name,
                    value: item.dept_id,
                  })) || []
                }
                id="Department"
                // label={translate('doctors.selectDepartment', 'Department')}
                onInputChange={(item) => {
                  setSelectedDepart(item);
                }}
                addValid_class={true}
                required={true}
                value={selectedDepart}
                isLoading={deptLoading}
                isCasePlaceholder={true}
              />
            </div>
          </div>
        </HeaderFields>

        <TableWrapper data={DoctorsList}>
          <div className="list-user compact-table" style={{minHeight: isLoading ? '55vh' : '', overflow: !DoctorsList?.length ? 'hidden' : ''}}>
            {DoctorsList && DoctorsList.length > 0 && !isLoading ? (
              <table
                className={
                  isLoading
                    ? 'table departments-calendar tableNodata '
                    : 'table departments-calendar v2'
                }
              >
                <thead>
                  <tr>
                    {DOCTORS_HEADERS.map((header, index) => (
                      <TableColumnHr
                        item={header}
                        key={index}
                        columnSort={columnSort}
                        setColumnSort={(data) => dispatch(setColumnSort(data))}
                        sortEnable={DoctorsList.length > 1 ? true : false}
                      />
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {DoctorsList.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * perPage + (index + 1)}</td>
                      <td className='text-capitalize'>
                        Dr. {item.name ? removeDrPrefix(item.name+' '+item.last_name) : '-'}{' '}
                        {item.user_id === userDetails.user_id && (
                          <span className="badge badge-me">
                            {translate('common.itsYou', `It's you`)}
                          </span>
                        )}
                      </td>
                      <td>
                        {item.departments.length != 0 ? (
                          <div className='d-flex'>
                          <span className="text-capitalize">{item.departments[0].dept_name}</span>
                          <div className='relative '>
                          {item.departments?.length >= 2 ? (
                              <span
                                role="button"
                                // data-bs-toggle="dropdown"
                                // aria-expanded="true"
                                className="pointer extra-details"
                                style={{ paddingBottom: 'unset' }}
                              >
                                +{item.departments?.length - 1}
                              </span>
                            ) : null}
                            <ul
                              className="dropdown-menu dropdown-menu-start custom-tooltip start top"
                              data-popper-placement="top-start"
                            >
                            <div className="scroll-body">
                            {item.departments?.slice(1).map((item, index) => (
                                <li key={index}>
                                  <span
                                    className="dropdown-item small text-capitalize"
                                    // data-bs-toggle="offcanvas"
                                    data-bs-target="#editAccount"
                                    aria-controls="editAccount"
                                  >
                                    {item.dept_name}
                                  </span>
                                </li>
                              ))}
                            </div>
                            </ul>
                          </div>
                          </div>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>{item.op_appointment == true ? 'OP' : '-'}</td>
                      <td>
                        {!ACCESS(STATUS_UPDATE) || userType === USER_TYPE.appointmentCordinator ?
                          <span className={`${item.doctor_status} activity-badge`}>
                                  <span className="dot">●</span>{' '}
                                  <span className="status-column-space">
                                  {item.doctor_status === 'active'
                                      ? translate('common.active', 'Active')
                                      : translate('common.inactive', 'Inactive')}
                                  </span>
                          </span>
                          : 
                          <>
                            <CustomSwitch
                              disabled={!ACCESS(STATUS_UPDATE) || userType === USER_TYPE.appointmentCordinator }
                              checkedProps={
                                item.doctor_status === 'active' ? true : false
                              }
                              handleSwitchChange={(checked) =>
                                handleChange(checked, item.doctor_id)
                              }
                            />
                            <span className="status-column-space">
                            {item.doctor_status === 'active'
                                ? translate('common.active', 'Active')
                                : translate('common.inactive', 'Inactive')}
                            </span>
                          </>
                        }
                      </td>
                      <td>
                        <div className="action-btns v3">
                          <Link
                            to={{
                              pathname: `/admin/doctorslisting/doctordetails/${item.doctor_id}/${item.user_id}`,
                            }}
                          >
                            <Tooltip
                              title={intl.formatMessage({
                                id: 'doctors.viewDoc',
                                defaultMessage: 'View Doctor',
                              })}
                              placement="top"
                            >
                              <span className='view-icon'>
                                {viewIcon}
                              </span>
                            </Tooltip>
                          </Link>
                          {userType !== USER_TYPE.appointmentCordinator &&
                            ACCESS(UPDATE) && (
                              <Link
                                className="edit-icon"
                                to={{
                                  pathname: `/admin/doctorslisting/editdoctor/${item.doctor_id}/${item.user_id}`,
                                }}
                              >
                                <Tooltip
                                  title={intl.formatMessage({
                                    id: 'doctors.editDoc',
                                    defaultMessage: 'Edit Doctor',
                                  })}
                                  placement="top"
                                >
                                  <span className='edit-icon'>
                                    {editIcon}
                                  </span>
                                </Tooltip>
                              </Link>
                            )}
                          {userType !== USER_TYPE.appointmentCordinator &&
                            ACCESS(DELETE) && (
                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'doctors.deleteDoc',
                                  defaultMessage: 'Delete Doctor',
                                })}
                                placement="top"
                              >
                                <span className='delete-icon' onClick={() => onDelete(item.doctor_id)}>
                                  {deleteIcon}
                                </span>
                              </Tooltip>
                            )}
                          {ACCESS(SCHEDULE_UPDATE) && (
                            <Link
                              to={{
                                pathname: '/admin/slot-management',
                                state: {
                                  doctorId: {
                                    label: `${item.name} ${item?.last_name}`,
                                    value: item.doctor_id,
                                  },
                                },
                              }}
                            >
                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'doctors.slotManage',
                                  defaultMessage: 'Slot Management',
                                })}
                                placement="left"
                              >
                                <span className='schedule-icon'>
                                  {manageSlotIcon}
                                </span>
                              </Tooltip>
                            </Link>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : !isLoading ? (
                <div className='patient-portal'>
                  <NoResultFound text={isApplied ?
                    translate('common.noResult', 'No results found.') :
                    translate('doctors.noDataDoctors', 'No doctors added')}
                    label={isApplied ? translate('common.noResultMsg', 'No results match the filter criteria Remove filter or clear all filters to show results') :
                      translate('doctors.noDataDoctorsMsg', `Looks like you haven't added any doctors yet.`)}
                    addNew={
                      ACCESS(CREATE) && !isApplied ? AddDoctor : ''
                    }
                    removeMarginBottom removeMarginTop />
                </div>
            ) : (
              <Loader />
            )}
          </div>
        </TableWrapper>
        {!isLoading && DoctorsList && DoctorsList.length > 0 ? (
          <Pagination
            SetCurrentPage={handleCurrentPageChange}
            SetPerPage={handlePerPageChange}
            perPage={parseInt(perPage)}
            totalCount={totalCount}
            currentPage={currentPage}
          />
        ) : (
          ''
        )}
        {showApptConflictPopup && (
          <AppointmentConflictPopup
            onClose={() => {
              setShowApptConflictPopup(false);
            }}
            onConfirm={onApptConflictConfirm}
          />
        )}
      </div>
    </>
  );
};
export default withPermissionCheck(DOCTOR)(DoctorListing);
