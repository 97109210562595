import React from 'react';
import Error404 from '../../Assets/img/icons/server-down-img.svg';
import '../Patient_Portal/Layout/style/developer.css'

function ServerDown() {
  return (
    <div className="admin_portal">
        <div className="server-down">
        <div>
        <img src={Error404} alt="404" />
        <h1>We’ll be back soon!</h1>
        <p>Sorry for the inconvenience but we’re working on some maintenance at the moment.
            Thank you for being so patient. <a href="mailto:">Contact</a> us for more information!</p>
        </div>
    </div>
    </div>
  )
}

export default ServerDown