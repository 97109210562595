import { PORTAL_TYPES } from 'Constants/constant';
import { USER_TYPE } from 'Constants/userTypes';
import keycloak from 'Utils/keyCloak/keyCloak';
import { getLocalStorageItem } from 'Utils/LocalStorage/local-storage';
import { getTopPriorityRole } from 'Utils/Permissions/Permissions';
import network, { updateRole, updateToken } from '../Utils/Api/Network'; //  { updateToken }
import { handleKeycloakLogin } from '../Utils/Services/Authenticator';
import { showToaster, ToasterTypes } from './toasterAction';

export const RECEIVE_USER_DETAILS = 'RECEIVE_USER_DETAILS';
export const RECEIVE_PATIENT_DETAILS = 'RECEIVE_PATIENT_DETAILS';
export const RECEIVE_USER_PERMISSIONS = 'RECEIVE_USER_PERMISSIONS';
export const RECEIVE_DOCTOR_LOGIN_DETAILS = 'RECEIVE_DOCTOR_LOGIN_DETAILS';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const SET_USER_TYPE = 'SET_USER_TYPE';

export const receiveUserDetails = (payload) => ({
  type: RECEIVE_USER_DETAILS,
  payload,
});

export const receivePatientDetails = (payload) => ({
  type: RECEIVE_PATIENT_DETAILS,
  payload,
});

export const receiveUserPermissions = (payload) => ({
  type: RECEIVE_USER_PERMISSIONS,
  payload,
});

export const providerLoginUser = () => ({
  type: LOGIN_USER,
});

export const providerLogout = () => ({
  type: LOGOUT_USER,
});
export const updateUserType = (payload) => ({
  type: SET_USER_TYPE,
  payload,
});

export const receiveDoctorDetails = (payload) => ({
  type: RECEIVE_DOCTOR_LOGIN_DETAILS,
  payload,
});

export const loginWithOtp = (
  data,
  mobileResponseHandler = () => {},
  guestLogin = false,
  setModal = () => {}
) => {
  return () => {
    return network
      .post({
        url: guestLogin
          ? '/login/patient/validate?guest_signup=true'
          : '/login/patient/validate',
        data,
      })
      .then((response) => {
        const { isError, message, result } = response;
        if (isError) {
          mobileResponseHandler(message);
          setModal(true);
        } else {
          mobileResponseHandler(result, message);
        }
      });
  };
};

export const FetchUserDetails = (tokenDetails, setRefresh) => {
  return (dispatch) => {
    return network.get({ url: '/user/token/info' }).then((response) => {
      const { isError, result, message } = response;
      if (isError) {
        // Telemed BE failed to validate login, the show toaster and logout from keycloak 
        dispatch(showToaster(message, ToasterTypes.Failed));
        updateToken('');
        setTimeout(() => keycloak.logout(), 3000);
      } else {
        const portalType = getLocalStorageItem('portalType');
        // login attempt from patient portal, then set the userRole in API header to 'patient' for BE
        if (portalType === PORTAL_TYPES.patient) {
          updateRole('patient');
        }
        // for admin portal
        else {
          const { roles } = result?.user_role_access_permissions || {};
          const userType = getLocalStorageItem('user_type')
          const priorityRole = getTopPriorityRole(roles); // sort assigned roles for the user based on their priority
          if (priorityRole === USER_TYPE.patient) {
            // if the user has only 'patient' role assigned, then logout the user. (patient try to login on admin portal)
            keycloak.logout({ redirectUri: window.location.origin + '/#/webConnect?test=true' });
            updateToken('');
            return
          } else if (roles.includes(userType)) {
            // User selected any particular role before, retain that screen and role (on refresh)
            dispatch(updateUserType(userType));
            updateRole(userType);
          } else {
            // Set to the priority role to redirect to the respevtive page
            dispatch(updateUserType(priorityRole));
            updateRole(priorityRole);
          }
        }
        handleKeycloakLogin(tokenDetails, result?.user_details); // set certain values to local storage
        dispatch(receiveDoctorDetails(result?.user_role_details?.doctor_details)); // set users doctor role related details in redux
        dispatch(providerLoginUser()); // set loggedin flag to true
        dispatch(receiveUserDetails(result?.user_details)); // set user details in redux
        dispatch(receivePatientDetails(result?.user_role_details?.patient_details)); // set users patient role related details in redux
        dispatch(receiveUserPermissions(result?.user_role_access_permissions)); // set users role based permission details in redux
      }
      // Stop loader
      if (setRefresh) setRefresh(false);
    });
  };
};
