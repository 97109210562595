import '../BookAppointment/BookAppointment.css';
import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { translate } from '../../../Language/Translate';
import { appendTime, getTimeOfDay } from '../../../Utils/DateFormatter/Date';
import { CardMedia } from '@mui/material';
import { Stack } from '@mui/system';
import { formatDate } from '../../../Utils/DateFormatter/Date';
import ConfirmBooking from '../BookAppointment/ConfirmBooking';
import Modal from '../Common/Modal';
import moment from 'moment';
import AntDatepicker from 'Components/Common/AntdDatePicker';
import img from 'Assets/img/icons/doctor-placeholder-avatar.svg';
import errorImg from '../../../Assets/img/patient-portal/error_img_placeHolder.png';
import { connect } from 'react-redux';
import Loader from 'Components/Common/Loader';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import CustomSwitch from 'Components/Common/Switch';
import { BOOKING_STATUS } from 'Constants/constant';

function ReschedulePopup({
  btnClick,
  setBtnClick,
  doctorData = {},
  doctorSlotData = {},
  appointmentsViewData,
  getDoctorSlots,
  selectedSlot,
  setSelectedSlot,
  setShowPatient,
  date,
  setDate,
  open,
  setOpen,
  applicationData,
  isLoading,
  setIsLoading,
  apptId,
  deptValue,
  setDeptValue
}) {
  const intl = useIntl();
  const imgStatus = useRef(true);
  const slotbtnRef = useRef({})
  const confirmBtnRef = useRef()
  const [showAvailable, updateAvailable] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [showQualify, setShowQualify] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [totalSlots, setTotalSlots] = useState([]);
  // const [calenderShow, setCalenderShow] = useState(false);
  const [deptData, setDeptData]=useState([]);
  const [availableOnly, setAvailableOnly] = useState(false);
  const {subsequent_appt_booking : allowSubsequentSlot = false} = applicationData

  useEffect(() => {
    if (appointmentsViewData.appointment_details) {
      setSelectedSlot({
        start_time: appointmentsViewData?.appointment_details?.appt_start_time,
        end_time: appointmentsViewData?.appointment_details?.appt_end_time,
        day_type: getTimeOfDay(
          appointmentsViewData?.appointment_details?.appt_start_time
        ),
      });
      setDate(new Date(appointmentsViewData?.appointment_details?.appt_date));
      setDeptValue({
        label:appointmentsViewData?.appointment_details?.dept_name,
        value:appointmentsViewData?.appointment_details?.dept_id
      }
      )
    }
  }, [appointmentsViewData]);

  const disabledDates = (current) => {
    return (
      (current && current.isBefore(moment().subtract(1, 'day'))) ||
      current.isAfter(
        moment().add(applicationData?.booking_window_max_days - 1, 'day')
      )
    );
  };

  useEffect(() => {
    if (btnClick) {
      setTimeout(() => {
        document.body.classList.add('modal-overflow');
      }, 0);
    } else {
      setTimeout(() => {
        document.body.classList.remove('modal-overflow');
      }, 0);
      setAvailableOnly(false);
    }
    return () => document.body.classList.remove('modal-overflow');
  }, [btnClick]);

  const closeModal = () => {
    setBtnClick(false);
  };

  const bookAppointmentTab = [
    {
      label: translate('title', 'Availability'),
    },
    {
      label: translate('title', 'View Profile'),
    },
  ];
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const toggleQualifyShowMore = () => {
    setShowQualify(!showQualify);
  };

  const availableClick = () => {
    updateAvailable(true);
  };
  const profileClick = () => {
    updateAvailable(false);
  };
  const handleSelectSlots = (slot) => {
    if (slot.status === 'available') {
      setSelectedSlot(slot);
    }
  };
  //handle tab change
  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  const dateChangeHandle = (value) => {
    setIsLoading(true);
    let updatedDate;
    setSelectedSlot(false);
    setTotalSlots([]);
    if (value) {
      updatedDate = new Date(value);
      const config = {
        start_date: formatDate(updatedDate),
        end_date: formatDate(updatedDate),
      };
      getDoctorSlots(doctorData.doctor_id, config, setIsLoading);
    } else {
      updatedDate = value;
    }
    setDate(updatedDate);
  };

  useEffect(() => {
    if (doctorSlotData.op_schedule_details) {
      if (doctorSlotData?.op_schedule_details?.length) {
        const { doctor_schedule_details } =
          doctorSlotData?.op_schedule_details?.[0];
        let slots = doctor_schedule_details
          ?.map((slotDetails) => slotDetails?.slots)
          .flat();
        if (allowSubsequentSlot) {
          let yourSlotAdded = false
          slots = slots.map((item) => {
            if (!yourSlotAdded && item.status === 'available') {
              yourSlotAdded = true
              return { ...item }
            }
            else if (item.status === 'available') {
              return { ...item, status: 'notavailable' }
            }
            else {
              return { ...item }
            }
          })
        }
        setTotalSlots(slots);
        setDeptData(doctorSlotData?.departments?.map((dept) => (
          {
            value: dept.dept_id,
            label: dept.dept_name
          }
        )))
      }
    }
  }, [doctorSlotData]);

  //  function to handle department change
  const handleInputChange=(value)=>{
    setDeptValue(value)
  }
  const onkeyDownSelectSlot=(index,i)=>{
    handleSelectSlots(i)
    setTimeout(() => {
      slotbtnRef?.current[index]?.blur()
      confirmBtnRef?.current?.focus()
    }, 0);
  }

  const modalRef = useRef();
  //   useEffect(() => {
  //     const listener = (event) => {
  //       if (!modalRef.current || modalRef.current.contains(event.target)) {
  //         return;
  //       }
  //       if (event.button === 0) setBtnClick();
  //     };
  //     document.addEventListener('mousedown', listener);

  //     return () => {
  //       document.removeEventListener('mousedown', listener);
  //     };
  //   }, [modalRef, setBtnClick]);

  // handle rescedule proceed
  const handleConfirm = () => {
    setShowPatient(true);
    setBtnClick(false);
  };

  const closePatientModal = () => {
    setOpen(false);
  };

  const getSlotClassName = (item, selectedSlot) => {
    switch (item?.status) {
      case 'booked':
        return 'booked'
      //no need
      case 'yourselection':
        return 'yourselection'

      case 'available':
        if(selectedSlot?.start_time == item?.start_time){
          return 'active'
        }
        return 'available'
      case 'notavailable':
        return 'disabled'
    }
  }

  return (
    <>
      <Modal
        close={open}
        setClose={closePatientModal}
        modalClass="booking-success-modal"
        style
      >
        <ConfirmBooking
          appId={
            apptId || appointmentsViewData?.appointment_details?.appointment_id
          }
          newdate={formatDate(date)}
          selectedSlot={selectedSlot}
          rescchedule
          setOpen={setOpen}
          doctorData={doctorData}
          showMyInfo
          patientInfo={{
            myName: appointmentsViewData?.patient_details?.name,
            lastName: appointmentsViewData?.patient_details?.last_name,
          }}
          deptValue={deptValue}
        />
      </Modal>
      <div
        className={`offcanvas-backdrop fade ${btnClick ? 'show' : ''}`}
      ></div>
      <div
        className={`offcanvas offcanvas-end custom-off-canvas booking-modal booking-modal-canvas ${
          btnClick ? 'show' : ''
        }`}
        tabIndex="-1"
        ref={modalRef}
        id="newBooking"
        aria-labelledby="bookLabel"
      >
        <div className="offcanvas-header">
          <h5 id="viewScheduleLabel">
            {translate('myAppointments.reschedule', 'Reschedule Appointment')}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            tabIndex="0"
            onKeyDown={(e)=>{
              if(e.key === "Tab" && e.shiftKey) e.preventDefault()
            }}
            onClick={() => closeModal(false)}
          ></button>
        </div>
        {isLoading ? (
          <div className="loader-wrap mb-4">
            <Loader />
          </div>
        ) : (
          <>
            <div className="offcanvas-body">
              <div className="doctor-profile ">
                <Stack
                  // style={{ cursor: 'pointer' }}
                  direction="row"
                  spacing={2}
                >
                    <CardMedia
                      className="doctor-booking-img"
                      style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                      component="img"
                      image={doctorData.profile_img ?? img}
                      onError={(e) => {
                        if (imgStatus.current) {
                          e.target.src = errorImg;
                          imgStatus.current = false;
                        }
                      }}
                    />
                </Stack>
                <div className="name-and-fees row">
                  <div className="col-md-6">
                    <h6 className='text-capitalize'>
                      {doctorData?.name}{' '}
                      <span className="lastName">{doctorData?.last_name}</span>
                    </h6>
                    <p className="description">
                      <span className="text-capitalize">
                      {doctorData?.departments
                        ?.map((val) => val?.dept_name)
                        .join(', ')}{' '}
                        </span>
                      {doctorData?.experience
                        ? `/ ${doctorData?.experience ?? '0'}yrs. exp.`
                        : ''}{' '}
                    </p>
                  </div>
                  <div className="col-md-2"></div>
                  <div className={`col-md-4 d-flex gap-2 d-md-block align-items-center consultation-fee ${!doctorSlotData.op_fees ? 'no-fee-added' : ''}`}>
                    <p className="mb-0">
                      {translate(
                        'patientPortal.ConsultationFee',
                        'Consultation Fee'
                      )}
                    </p>
                    <span className="fees">{doctorSlotData.op_fees ? `${doctorSlotData.op_fees}/-` : 'No fee added'}</span>
                  </div>
                </div>
              </div>

              <ul className="nav mb-2 custom-tab" id="pills-tab">
                {bookAppointmentTab.map((tab, index) => (
                  <li
                    onClick={index == 0 ? availableClick : profileClick}
                    className=" nav-item"
                    key={index + 1}
                  >
                    <button
                      className={index === selectedTab ? 'active' : ''}
                      onClick={() => handleTabChange(index)}
                    >
                      {tab.label}
                    </button>
                  </li>
                ))}
              </ul>

              <div className="tab-content" id="pills-tabContent">
                {showAvailable ? (
                  <div className="booking-date tab-pane active">
                    <div className="d-flex filter-checkbox gap-2 flex-wrap">
                      <h5 className="">
                        {intl.formatMessage({
                          id: 'patientPortal.pickTimeSlot',
                          defaultMessage: 'Pick a time slot',
                        })}
                      </h5>
                      {/* <div className="d-flex align-items-center gap-2 form-group otp">
                        <div className="disabled-indicator"></div>
                        <label className="">
                          {intl.formatMessage({
                            id: 'patientPortal.unavailableSlot',
                            defaultMessage: 'Unavailable Slot',
                          })}
                        </label>
                      </div> */}
                    </div>
                    <div className='row'>
                    <div className="form-group col-sm-4 col-lg-3">
                      <label htmlFor="date">
                        {intl.formatMessage({
                          id: 'patientPortal.whenQuestion',
                          defaultMessage: 'When?',
                        })}
                      </label>
                      <div className="mt-2">
                        <AntDatepicker
                          placeholder={'DD/MM/YYYY'}
                          onInputChange={dateChangeHandle}
                          disabledDate={disabledDates}
                          value={date}
                          allowClearBtn={true}
                          autofocus
                        />
                      </div>
                    </div>

                    {doctorData?.departments?.length > 1 &&
                        <div className="form-group col-sm-4 col-lg-5 mt-3 mt-sm-0 ">
                          <label htmlFor="" className="mandatory">
                          {intl.formatMessage({
                          id: 'patientPortal.whichSpecialty',
                          defaultMessage: 'Which Specilality?',
                        })}
                          </label>
                          <div className="mt-2">
                            <div className="select">
                              <ReactSelectDropDown
                                id="filterbydept"
                                 options={deptData}
                                 onInputChange={handleInputChange}
                                 value={deptValue}
                                 isClearable={true}
                                 targetBody={false}
                              />
                            </div>
                          </div>
                        </div>
                      }     
                      </div>
                    <div className="timings">
                        <div className="legends">
                          {Object.keys(BOOKING_STATUS).map((item, i) => (
                            <span key={i} className={`legend ${item}`}>
                              <span className="dot"></span>{' '}
                              {BOOKING_STATUS[item]}
                            </span>
                          ))}
                        </div>
                      <div className='toggle-filter'>
                        Available Slots Only <CustomSwitch checkedProps={availableOnly} handleSwitchChange={(value) => setAvailableOnly(value)} />
                      </div>
                      {!totalSlots.length &&
                        !isLoading &&
                        translate(
                          'appointments.noAvailableSlots',
                          'No slots available'
                        )}

                      {totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).some((x) => x.day_type === 'morning') && (
                        <div className="mt-3 availablity morning">
                          <h5 className=" title">
                            {intl.formatMessage({
                              id: 'patientPortal.morning',
                              defaultMessage: 'Morning',
                            })}{' '}
                            {/* (06:00 AM - 12:00 PM) */}
                          </h5>

                          <div className=" time-container">
                            {totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).map((i, index) =>
                              i.day_type === 'morning' ? (
                                <div
                                  key={index}
                                  onClick={() => handleSelectSlots(i)}
                                  tabIndex={i.status === 'available' ? 0 : -1}
                                  ref={(ref) => (slotbtnRef.current[`mng${index}`] = ref)}
                                  className={`time ${getSlotClassName(i, selectedSlot)}`}
                                  onKeyDown={(e)=>{
                                    if(e.key ==="Enter" && !e.shiftKey){
                                      e.preventDefault()
                                      onkeyDownSelectSlot(`mng${index}`,i)
                                    }
                                  }}
                                >
                                  {appendTime(i.start_time)}
                                </div>
                              ) : (
                                ''
                              )
                            )}
                          </div>
                        </div>
                      )}
                      {totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).some((x) => x.day_type === 'afternoon') && (
                        <div className="mt-3 availablity afternoon">
                          <h5 className=" title">
                            {intl.formatMessage({
                              id: 'patientPortal.afternoon',
                              defaultMessage: 'Afternoon',
                            })}{' '}
                            {/* ( 12:00 PM : 05:00 PM) */}
                          </h5>
                          <div className=" time-container">
                            {totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).map((i, index) =>
                              i.day_type === 'afternoon' ? (
                                <div
                                  key={index}
                                  tabIndex={i.status === 'available' ? 0 : -1}
                                  onClick={() => handleSelectSlots(i)}
                                  ref={(ref) => (slotbtnRef.current[`afternon${index}`] = ref)}
                                  className={`time ${getSlotClassName(i, selectedSlot)}`}
                                  onKeyDown={(e)=>{
                                    if(e.key ==="Enter" && !e.shiftKey){
                                      e.preventDefault()
                                      onkeyDownSelectSlot(`afternon${index}`,i)
                                    }
                                  }}
                                >
                                  {appendTime(i.start_time)}
                                </div>
                              ) : (
                                ''
                              )
                            )}
                          </div>
                        </div>
                      )}
                      {totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).some((x) => x.day_type === 'evining') && (
                        <div className="mt-4 availablity evening">
                          <h5 className=" title ">
                            {intl.formatMessage({
                              id: 'patientPortal.evening',
                              defaultMessage: 'Evening',
                            })}{' '}
                            {/* (05:00 PM - 08:00 PM) */}
                          </h5>
                          <div className=" time-container">
                            {totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).map((i, index) =>
                              i.day_type === 'evining' ? (
                                <div
                                  key={index}
                                  tabIndex={i.status === 'available' ? 0 : -1}
                                  onClick={() => handleSelectSlots(i)}
                                  ref={(ref) => (slotbtnRef.current[`evng${index}`] = ref)}
                                  className={`time ${getSlotClassName(i, selectedSlot)}`}
                                  onKeyDown={(e)=>{
                                    if(e.key ==="Enter" && !e.shiftKey){
                                      e.preventDefault()
                                      onkeyDownSelectSlot(`evng${index}`,i)
                                    }
                                  }}
                                >
                                  {appendTime(i.start_time)}
                                </div>
                              ) : (
                                ''
                              )
                            )}
                          </div>
                        </div>
                      )}
                      {totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).some((x) => x.day_type === 'night') && (
                        <div className="mt-4 availablity night">
                          <h5 className=" title ">
                            {intl.formatMessage({
                              id: 'patientPortal.night',
                              defaultMessage: 'Night',
                            })}{' '}
                            {/* ( 08:00 PM - 06:00 AM) */}
                          </h5>
                          <div className=" time-container">
                            {totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).map((i, index) =>
                              i.day_type === 'night' ? (
                                <div
                                  key={index}
                                  tabIndex={i.status === 'available' ? 0 : -1}
                                  onClick={() => handleSelectSlots(i)}
                                  className={`time  ${
                                    appendTime(selectedSlot.start_time) ==
                                    appendTime(i.start_time)
                                      ? 'active'
                                      : !(i.status === 'available')
                                      ? 'disabled'
                                      : ''
                                  }`}
                                  onKeyDown={(e)=>{
                                    if(e.key ==="Enter" && !e.shiftKey){
                                      e.preventDefault()
                                      onkeyDownSelectSlot(`nyt${index}`,i)
                                    }
                                  }}
                                >
                                  {appendTime(i.start_time)}
                                </div>
                              ) : (
                                ''
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className=" profile">
                    {doctorData.about ? (
                      <div className="general-description data">
                        <p className="description">
                          {doctorData.about?.slice(
                            0,
                            showMore ? doctorData.about.length : 210
                          )}
                          {doctorData.about?.length > 150 ? (
                            <span
                              onClick={toggleShowMore}
                              className="ml-1 fs-15 fw-500"
                              style={{ color: '#4bb691', cursor: 'pointer' }}
                            >
                              {'  '}
                              {showMore ? 'less' : 'more...'}
                            </span>
                          ) : null}
                        </p>
                      </div>
                    ) : null}
                    <div>
                      <h5
                        className={`qualification data ${
                          doctorData.about ? '' : 'pb-0'
                        }`}
                      >
                        {intl.formatMessage({
                          id: 'patientPortal.doctorQualification',
                          defaultMessage: 'Doctor Qualification',
                        })}
                      </h5>
                      <p className="description">
                        {doctorData.qualification_details
                          ? doctorData.qualification_details.slice(
                              0,
                              showQualify
                                ? doctorData.qualification_details.length
                                : 210
                            )
                          : '-'}
                        {doctorData.qualification_details?.length > 150 ? (
                          <span
                            onClick={toggleQualifyShowMore}
                            className="ml-1 fs-15 fw-500"
                            style={{ color: '#4bb691', cursor: 'pointer' }}
                          >
                            {'  '}
                            {showQualify ? 'less' : 'more...'}
                          </span>
                        ) : null}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {showAvailable ? (
              <div className="offcanvas-footer">
                <button
                  data-bs-toggle="modal"
                  id="proceed"
                  type="button"
                  ref={confirmBtnRef}
                  className="confirm-btn btn btn-primary"
                  onClick={handleConfirm}
                  disabled={!selectedSlot ||deptValue===null }
                  onKeyDown={(e)=>{
                    if(e.key === "Tab" && !e.shiftKey) e.preventDefault()
                  }}
                >
                  {intl.formatMessage({
                    id: 'patientPortal.confirm',
                    defaultMessage: 'Confirm',
                  })}{' '}
                  &{' '}
                  {intl.formatMessage({
                    id: 'patientPortal.proceed',
                    defaultMessage: 'Proceed',
                  })}
                </button>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  applicationData: state.SettingsReducer.applicationData,
});

export default connect(mapStateToProps)(ReschedulePopup);
