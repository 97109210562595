import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
const AntDatepicker = (props) => {
  const {
    placeholder,
    onInputChange,
    value,
    disabled,
    className,
    disabledDate,
    disabledPastDate = false,
    allowClearBtn = false,
    popupClassName = '',
    isAdmin = false,
    validationErr = false,
    autofocus=false,
    titleValue=()=>{}
  } = props;

  const disablePastDates = (current) => {
    return (
      current &&
      current <= moment() &&
      current.isBefore(moment().subtract(1, 'day'))
    );
  };
  return (
    <div autoComplete="off" className={isAdmin ? '' : 'custom-date-picker'}>
      <DatePicker
        onChange={onInputChange}
        style={{
          width: '100%',
          height: '100%',
          borderColor: validationErr ? 'red' : '',
        }}
        // restrict alphabet character
        onKeyDown={(e) => {
          if(e.nativeEvent.code.substring(0,5) === "Digit"){
            titleValue(true);
          }
          if(e.nativeEvent.code === "Backspace" && !(e.target.value.length -1)){
            titleValue(false);
          }
          if ((e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 97 && e.keyCode <= 122)) {
            e.preventDefault()
          }
        }}
        className={className}
        onBlur={(e)=>{
          if(!(e.target.value.length)){
            titleValue(false)
          }
        }}
        placeholder={placeholder}
        format="DD/MM/YYYY"
        value={value ? dayjs(value) : ''}
        disabled={disabled}
        disabledDate={
          disabledDate
            ? disabledDate
            : disabledPastDate
            ? disablePastDates
            : null
        }
        allowClear={allowClearBtn}
        popupClassName={popupClassName}
        autoFocus={autofocus}
      />
    </div>
  );
};

export default AntDatepicker;
