/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import '../../Assets/style/signUp.css';
// import logo from '../../Assets/img/logo.svg';
import Unauthorized from '../NotFound/Unauthorised';
import InvalidToken from './InvalidToken';
import PasswordInput from '../Settings/PasswordInput';
import PasswordStrengthBar from '../Settings/passwordProgress';
import {
  clientActiavte,
  fetchClientDetails,
} from '../../Actions/activationAction';
import Toaster from '../Common/Toaster';
import ActivationSuccess from './Success';
import { mainLogoUrl } from 'Utils/Branding/logoGenerator';

const ClientActivation = (props) => {
  const { getClientDetails, toasterDisplay, clientSignup, subscriptionData } = props;
  const params = useParams();
  const intl = useIntl();

  const isActivate = window.location.hostname.includes('activate');

  const { id: token } = params || {};

  const [clientData, setClientData] = useState({
    firstName: '',
    lastName: '',
    clientCode: '',
    clientId: '',
    newPassword: '',
    confirmPassword: '',
    email: '',
    base_domain: '',
  });

  const [feildError, setFeildError] = useState({
    clientCodeError: false,
    nameError: false,
    passwordError: false,
    confirmError: false,
  });
  const [misMatchError, setMismatchError] = useState(false);
  const [misCriteria, setMisCriteria] = useState(false);
  const [invalidClient, setInvalidClient] = useState(false);

  const [showEye, setShowEye] = useState({
    newPass: false,
    confirmPass: false,
  });
  const [showPass, setShowPass] = useState({
    newPass: false,
    confirmPass: false,
  });
  const [strongPassword, setStrongPassword] = useState(false);
  const [error, setError] = useState('');

  const [success, setSuccess] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const {
    firstName,
    lastName,
    clientCode,
    clientId,
    newPassword,
    confirmPassword,
    email,
    base_domain,
  } = clientData;

  const { clientCodeError, nameError, passwordError, confirmError } =
    feildError;

  // handle api response
  const handleResponse = (data) => {
    if (data) {
      const { admin, client_code, white_label, client_id } = data || {};
      const { name, email: clientMail, last_name } = admin || {};
      setClientData((prev) => ({
        ...prev,
        firstName: name,
        lastName: last_name,
        clientCode: client_code,
        clientId: client_id,
        email: clientMail,
        base_domain: white_label?.base_domain,
      }));
    }
  };

  // handle invalid token
  const handleInvalid = () => {
    setInvalid(true);
  };

  useEffect(() => {
    const config = {
      data: {
        token: token,
      },
    };
    getClientDetails(config, handleResponse, handleInvalid);
    document.body.classList.add('admin_portal');
    return () => document.body.classList.remove('admin_portal');
  }, []);

  useEffect(() => {
    if (
      strongPassword &&
      error ===
        intl.formatMessage({
          id: 'common.criteriaMatch',
          defaultMessage: 'Password does not match the criteria.',
        })
    ) {
      setError('');
      setMisCriteria(false);
    }
  }, [strongPassword, error]);

  // handle input change
  const handleInputChange = (event) => {
    const { value, name } = event.target;
    const updatedClientData = { ...clientData };
    updatedClientData[name] = value;
    if (name === 'clientCode') {
      setFeildError((prev) => ({
        ...prev,
        clientCodeError: false,
      }));
      const clientRegex = /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)?$/;
      if (
        clientRegex.test(value) &&
        error ===
          intl.formatMessage({
            id: 'signup.clientcodeError',
            defaultMessage: 'Please enter valid client code',
          })
      ) {
        setError('');
        setInvalidClient(false);
      }
    } else if (name === 'firstName') {
      setFeildError((prev) => ({
        ...prev,
        nameError: false,
      }));
    } else if (name === 'newPassword') {
      setFeildError((prev) => ({
        ...prev,
        passwordError: false,
      }));
      if (
        value === confirmPassword &&
        error ===
          intl.formatMessage({
            id: 'common.passwordMatchErr',
            defaultMessage: 'Password and confirm password does not match.',
          })
      ) {
        setError('');
        setMismatchError(false);
      }
    } else if (name === 'confirmPassword') {
      setFeildError((prev) => ({
        ...prev,
        confirmError: false,
      }));
      if (
        value === newPassword &&
        error ===
          intl.formatMessage({
            id: 'common.passwordMatch',
            defaultMessage: 'Password and confirm password does not match.',
          })
      ) {
        setError('');
        setMismatchError(false);
      }
    }
    setClientData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Check if all fields are filled
    const allFieldsFilled =
      updatedClientData.clientCode &&
      updatedClientData.firstName &&
      updatedClientData.newPassword &&
      updatedClientData.confirmPassword;

    if (
      allFieldsFilled &&
      error ===
        intl.formatMessage({
          id: 'common.requiredFields',
          defaultMessage: 'Please Fill all the required Fields',
        })
    ) {
      setError('');
    }
  };

  // handle activation success
  const handleSuccess = () => {
    setSuccess(true);
  };

  // handle submit button click
  const handleSubmit = () => {
    const clientRegex = /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)?$/;
    if (
      !newPassword ||
      !confirmPassword ||
      !firstName ||
      !clientCode ||
      !clientCode
    ) {
      setFeildError({
        clientCodeError: !clientCode,
        nameError: !firstName,
        passwordError: !newPassword,
        confirmError: !confirmPassword,
      });
      setError(
        intl.formatMessage({
          id: 'common.requiredFields',
          defaultMessage: 'Please Fill all the required Fields',
        })
      );
    } else if (!clientRegex.test(clientCode)) {
      setError(
        intl.formatMessage({
          id: 'signup.clientcodeError',
          defaultMessage: 'Please enter valid client code',
        })
      );
      setInvalidClient(true);
    } else if (newPassword !== confirmPassword) {
      setError(
        intl.formatMessage({
          id: 'common.passwordMatch',
          defaultMessage: 'Password and confirm password does not match.',
        })
      );
      setMismatchError(true);
    } else if (!strongPassword) {
      setError(
        intl.formatMessage({
          id: 'common.criteriaMatch',
          defaultMessage: 'Password does not match the criteria.',
        })
      );
      setMisCriteria(true);
    } else {
      setError('');
      const config = {
        data: {
          client_code: clientCode,
          client_id: clientId,
          admin: {
            name: firstName,
            last_name: lastName,
            email: email,
          },
          credentials: {
            password: newPassword,
            confirm_password: confirmPassword,
          },
          token,
        },
      };
      clientSignup(config, handleSuccess);
    }
  };

  return (
    <>
      {!isActivate || !token ? (
        <span style={{ textAlign: 'center' }}>
          <Unauthorized />
        </span>
      ) : invalid ? (
        <span style={{ textAlign: 'center' }}>
          <InvalidToken />{' '}
        </span>
      ) : (
        <div className="admin_portal">
          <div className="signUpSection d-flex">
            <div className="signUpWrap">
              <div className="signUpArea">
                <div className="signUp-logo">
                  <img src={mainLogoUrl(subscriptionData)} alt="logo" />
                </div>
                {success ? (
                  <ActivationSuccess />
                ) : (
                  <>
                    <h4
                      style={{
                        marginTop: '1rem',
                        color: '#125e77',
                        marginBottom: 18,
                      }}
                    >
                      <FormattedMessage
                        id="signup.title"
                        defaultMessage="SignUp"
                      />{' '}
                      -{' '}
                      <span style={{ color: '#2bc52b', fontSize: 13 }}>
                        {email}
                      </span>
                    </h4>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="username">
                            <FormattedMessage
                              id="signup.selectDomain"
                              defaultMessage="Select your Telemedicine Domain"
                            />{' '}
                            <span className="sup">*</span>
                            <Tooltip
                              title={
                                <div style={{ fontSize: 13 }}>
                                  <div>
                                    <FormattedMessage
                                      id="signup.criteria"
                                      defaultMessage="The client code should only contain letters and numbers, with at most one hyphen that is not at the beginning or end."
                                    />
                                  </div>
                                </div>
                              }
                              placement="top"
                            >
                              <InfoIcon
                                style={{
                                  color: '#42819b',
                                  fontSize: 17,
                                  marginLeft: 4,
                                  marginBottom: 4,
                                }}
                              />
                            </Tooltip>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="clientCode"
                            style={{
                              paddingRight: '60%',
                              borderColor:
                                clientCodeError || invalidClient ? 'red' : '',
                            }}
                            name="clientCode"
                            value={clientCode}
                            autoComplete="off"
                            onChange={handleInputChange}
                          />
                          <div
                            className="px-3 d-flex align-items-center position-absolute"
                            style={{
                              backgroundColor: '#efefef',
                              border: '1px solid #c8c8c8',
                              right: 0,
                              top: 24,
                              height: '61%',
                              width: '60%',
                              overflowX: 'auto',
                            }}
                          >
                            <p style={{ color: ' #000000', fontSize: 13 }}>
                              {base_domain}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="password">
                            <FormattedMessage
                              id="common.firstName"
                              defaultMessage="First Name"
                            />{' '}
                            <span className="sup">*</span>
                          </label>
                          <div>
                            <input
                              className="form-control"
                              style={{
                                paddingRight: 40,
                                borderColor: nameError && 'red',
                              }}
                              id="firstName"
                              name="firstName"
                              value={firstName}
                              autoComplete="off"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="password">
                            <FormattedMessage
                              id="common.lastName"
                              defaultMessage="Last Name"
                            />
                          </label>
                          <div>
                            <input
                              className="form-control"
                              style={{ paddingRight: 40 }}
                              id="lastName"
                              name="lastName"
                              value={lastName}
                              autoComplete="off"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="password">
                            <FormattedMessage
                              id="login.password"
                              defaultMessage="Password"
                            />{' '}
                            <span className="sup">*</span>
                            <Tooltip
                              title={
                                <div style={{ fontSize: 13 }}>
                                  <div>
                                    <FormattedMessage
                                      id="settings.capitalLetter"
                                      defaultMessage="1. Password must start with a capital letter."
                                    />
                                  </div>
                                  <div>
                                    <FormattedMessage
                                      id="settings.eightChar"
                                      defaultMessage="2. Password must be at least 8 characters long"
                                    />
                                  </div>
                                  <div>
                                    <FormattedMessage
                                      id="settings.specialChar"
                                      defaultMessage="3. Should contain atleast one special character"
                                    />
                                  </div>
                                  <div>
                                    <FormattedMessage
                                      id="settings.oneNumber"
                                      defaultMessage="4. Password must contain atleast one number."
                                    />
                                  </div>
                                </div>
                              }
                              placement="top"
                            >
                              <InfoIcon
                                style={{
                                  color: '#42819b',
                                  fontSize: 18,
                                  margin: 4,
                                }}
                              />
                            </Tooltip>
                          </label>
                          <div>
                            <PasswordInput
                              top={30}
                              showEye={showEye}
                              showPass={showPass}
                              password={newPassword}
                              setShowEye={setShowEye}
                              setShowPass={setShowPass}
                              validationErr={
                                passwordError || misMatchError || misCriteria
                              }
                              name="newPassword"
                              id="newPass"
                              handlePasswordChange={handleInputChange}
                            />
                            <div style={{ display: 'none' }}>
                              <PasswordStrengthBar
                                password={newPassword}
                                setStrongPassword={setStrongPassword}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="password">
                            <FormattedMessage
                              id="settings.confirmPassword"
                              defaultMessage="Confirm Password"
                            />{' '}
                            <span className="sup">*</span>
                          </label>
                          <div>
                            <PasswordInput
                              top={30}
                              showEye={showEye}
                              showPass={showPass}
                              setShowEye={setShowEye}
                              setShowPass={setShowPass}
                              password={confirmPassword}
                              validationErr={
                                confirmError || misMatchError || misCriteria
                              }
                              name="confirmPassword"
                              id="confirmPass"
                              handlePasswordChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {error && (
                      <div
                        className="col-md-12 errorMessage"
                        style={{ textAlign: 'center' }}
                      >
                        {error}
                      </div>
                    )}
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-primary w-100"
                          id="login"
                          onClick={handleSubmit}
                        >
                          {' '}
                          <FormattedMessage
                            id="signup.signup"
                            defaultMessage="SIGN UP"
                          />{' '}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {toasterDisplay && <Toaster />}
    </>
  );
};

const mapStateToProps = (state) => ({
  toasterDisplay: state.ToasterReducer.toasterDisplay,
  subscriptionData: state.SettingsReducer.subscriptionData,
});

const mapDispatchToProps = (dispatch) => ({
  getClientDetails: (data, handleResponse, hanldeInvalid) =>
    dispatch(fetchClientDetails(data, handleResponse, hanldeInvalid)),
  clientSignup: (data, handleSuccess) =>
    dispatch(clientActiavte(data, handleSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientActivation);
