import Network from './Network';

const getLeaveDetails = async (data = {}) => {
  let response = null;
  let error = null;
  await Network.get({
    url: '/leave/doctor',
    data,
  }).then((res) => {
    const { isError, result, message } = res;
    if (isError) {
      error = message;
    } else {
      response = result;
    }
  });
  return { error, response };
};

export const LeavesApi = {
  getLeaveDetails,
};
