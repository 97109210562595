import React, { useEffect, useRef, useState } from 'react';
import { translate } from '../../../Language/Translate';
import ExitingPatient from './ExistingPatient';
import NewPatient from './NewPatient';
import { linkExistingProfile, linkNewProfile } from 'Actions/patientProfileAction';
import { connect } from 'react-redux';
import NewPatientOtp from './NewPatientOtp';
import { LINK_PROFILE } from 'Constants/scopes';
import withPermissionCheck from 'Utils/WithUtils/WithPermission';
import { PATIENTFAMILY } from 'Constants/feature';
import { compose } from 'redux';
import { showToaster, ToasterTypes } from 'Actions/toasterAction';
import { useIntl } from 'react-intl';
import { timeZoneDate } from 'Utils/DateFormatter/Date';
import { validateMobile } from 'Utils/Validation/validator';
import { RELATIONS } from 'Constants/constant';

const ProfileLinkModal = (props) => {
  const {
    modalShow,
    setModalShow,
    onSubmitSuccess,
    getLinkedProfileList,
    addNewProfile,
    onEditClick,
    getTokenForResend,
    defaultCountryCode,
    ACCESS,
    displaytoaster,
    isBtnLoading,
    setIsLoading
  } = props;
  const intl = useIntl()
  const firstnameRef = useRef()
  const [newPatient, setNewPatient] = useState(true);
  const [linkProfileDetails, setLinkProfileDetails] = useState({
    country: defaultCountryCode?.toString(),
    relation: { label: translate('relation.other', 'Other'), value: RELATIONS.other },
  });
  const [search, setSearch] = useState('');
  const [otpData, setOtpData] = useState({
    len: 4,
    token: '',
    exp: '',
  });

  const [showOtp, setShowOtp] = useState(false);
  const [invalidAlert, setInvalidAlert] = useState({});
  const [numberLength, setNumberLength] = useState(10);
  const [code,setCode]=useState('IN');
  const [resendLoader, setResendLoader] = useState(false);


  // handle tab change
  const handleTabChange = (tab) => {
    setNewPatient(tab === 'newPatient');
  };

  // handle close
  const handleClose = () => {
    setModalShow(false);
    setNewPatient(true);
    setShowOtp(false);
    setIsLoading(false)
  };

  useEffect(()=>{
    setTimeout(()=>{
      firstnameRef?.current?.focus()
    },100)
  },[modalShow])
  //newly saving profile
  const handleNewProfileSave = () => {
    const resendDetails = localStorage.getItem("Resend")
    const { newpatient } = JSON.parse(resendDetails)
    if (newpatient && ((newpatient - timeZoneDate().getTime()) / 1000) > 0) {
      displaytoaster(intl.formatMessage({
        id: 'commom.otpLimitMsg',
        defaultMessage: 'OTP send limit exceeded please try again after 5 min'
      }), ToasterTypes.Warning)
    }
    else if (
      !linkProfileDetails?.fname?.trim().length ||
      !linkProfileDetails?.dob ||
      // !linkProfileDetails?.relation?.value ||
      !linkProfileDetails?.checkbox ||
      !linkProfileDetails?.gender ||
      (linkProfileDetails?.phno && linkProfileDetails?.country && !validateMobile(linkProfileDetails?.phno,code))
      // !linkProfileDetails?.country
    ) {
      setInvalidAlert({
        ...invalidAlert,
        fname: !linkProfileDetails?.fname?.trim().length,
        dob: !linkProfileDetails?.dob,
        // relation: !linkProfileDetails?.relation?.value,
        // phno:( linkProfileDetails?.country && !linkProfileDetails?.phno),
        gender: !linkProfileDetails?.gender,
        checkbox: !linkProfileDetails?.checkbox ?? '',
        country: (linkProfileDetails?.phno && !linkProfileDetails?.country),
        mobileValidErr:(linkProfileDetails?.phno && !validateMobile(linkProfileDetails?.phno,code))
      });
    } else {
      let payload = {
        data: {
          first_name: linkProfileDetails.fname,
          last_name: linkProfileDetails.lName,
          dob: linkProfileDetails?.dob,
          blood_group: linkProfileDetails?.blood_group?.value,
          relation: linkProfileDetails?.relation?.value,
          gender: linkProfileDetails?.gender,
          consent: true,
          tele_country_code: linkProfileDetails?.phno ? linkProfileDetails?.country : "",
          phone: linkProfileDetails?.country ? linkProfileDetails?.phno?.replace(/\s/g, ""): '',
        },
      };
      addNewProfile(payload, (data) => {
        let { result, response } = data;
        if (response.code === 202) {
          getLinkedProfileList();
          setShowOtp(true);
          setIsLoading(true)
          setLinkProfileDetails({
            ...linkProfileDetails,
            patient_id: result?.patient_details?.patient_id, //for edit
            patientId: result?.patient_details?.patient_id,
          });
          setOtpData({
            ...otpData,
            token: result?.otp_token?.token,
            exp: result?.otp_token?.exp,
            exp_time: result?.otp_token?.exp_unix,
          });
        } else {
          onSubmitSuccess();
          getLinkedProfileList();
        }
      });
    }
  };

  const getTokenForResendOtp = (item) => {
    let payload = {
      data: {
        patient_query: item?.patient_id,
        // patient_query: item?.country+item?.phno,
      },
    };
    setResendLoader(true)
    getTokenForResend(
      payload,
      ongetTokenForResendOtpSuccess,
      ongetTokenForResendOtpError
    );
  };
  const ongetTokenForResendOtpSuccess = (result) => {
    setOtpData({
      ...otpData,
      token: result?.otp_token?.token,
      exp: result?.otp_token?.exp,
      exp_time: result?.otp_token?.exp_unix,
    });
    const timer = setTimeout(() => {
      setResendLoader(false);
    }, 3000);
    return () => clearTimeout(timer);
  }
  const ongetTokenForResendOtpError = () => {

  }

  return (
    <>
      <div
        className={`${modalShow ? 'show' : ''
          } offcanvas offcanvas-end custom-off-canvas link-profile-canvas`}
      >
        <div className="offcanvas-header">
          <h5>{translate('patientPortal.linkProfile', 'Link a Profile')}</h5>
          <button
            type="button"
            className="btn-close text-reset"
            onClick={handleClose}
          ></button>
        </div>
        <div className="offcanvas-body">
          {/* Tab */}
          {showOtp ? (
            <NewPatientOtp
              linkProfileDetails={linkProfileDetails}
              otpData={otpData}
              setShowOtp={setShowOtp}
              getLinkedProfileList={getLinkedProfileList}
              onSubmitSuccess={onSubmitSuccess}
              getTokenForResendOtp={getTokenForResendOtp}
              onEditClick={onEditClick}
              setModalShow={setModalShow}
              resendLoader={resendLoader}
              setIsLoading={setIsLoading}
            />
          ) : (
            <>
              <div className="tabs">
                <ul
                  className="nav mb-4 custom-tab"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`${newPatient ? 'active' : ''} px-4`}
                      id="new-patient"
                      type="button"
                      onClick={() => handleTabChange('newPatient')}
                      onKeyDown={(e)=>{
                        if(e.key === "Tab" && e.shiftKey) e.preventDefault()
                      }}
                    >
                      {translate('appointments.newPatient', 'New Patient')}
                    </button>
                  </li>
                  {ACCESS(LINK_PROFILE) && <li className="nav-item" role="presentation">
                    <button
                      className={`${newPatient ? '' : 'active'} px-4`}
                      id="existing-patient"
                      type="button"
                      onClick={() => handleTabChange('existingPatient')}
                    >
                      {translate(
                        'appointments.existingPatient',
                        'Existing Patient'
                      )}
                    </button>
                  </li>}
                </ul>
              </div>
              <div className="tab-content" id="pills-tabContent">
                {newPatient ? (
                  <>
                    <NewPatient
                      modalShow={modalShow}
                      linkProfileDetails={linkProfileDetails}
                      setLinkProfileDetails={setLinkProfileDetails}
                      invalidAlert={invalidAlert}
                      setInvalidAlert={setInvalidAlert}
                      showOtp={showOtp}
                      setShowOtp={setShowOtp}
                      handleProfileSave={handleNewProfileSave}
                      defaultCountryCode={defaultCountryCode}
                      isBtnLoading={isBtnLoading}
                      numberLength={numberLength}
                      setNumberLength={setNumberLength}
                      code={code}
                      setCode={setCode}
                      firstnameRef={firstnameRef}
                    />
                  </>
                ) : (
                  <>
                    <ExitingPatient
                      getLinkedProfileList={getLinkedProfileList}
                      onSubmitSuccess={onSubmitSuccess}
                      modalShow={modalShow}
                      search={search}
                      setSearch={setSearch}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div
        onClick={handleClose}
        className={` ${modalShow ? 'offcanvas-backdrop fade show' : ''}`}
      ></div>
    </>
  );
};

const mapStateToProps = (state) => ({
  otpDetails: state.SettingsReducer.otpDetails,
  isBtnLoading: state.ProfileReducer.isBtnLoading
});
const mapDispatchToProps = (dispatch) => ({
  getTokenForResend: (data, callBack, errCallBack) =>
    dispatch(linkExistingProfile(data, callBack, errCallBack)),
  addNewProfile: (data, callback) => dispatch(linkNewProfile(data, callback)),
  displaytoaster: (message, type) => dispatch(showToaster(message, type)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissionCheck(PATIENTFAMILY)
)(ProfileLinkModal);

