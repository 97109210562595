import './BookAppointment.css';
import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { translate } from '../../../Language/Translate';
import { appendTime } from '../../../Utils/DateFormatter/Date';
import { CardMedia } from '@mui/material';
import placeholdericon from 'Assets/img/icons/doctor-placeholder-avatar.svg';
import errorImg from '../../../Assets/img/patient-portal/error_img_placeHolder.png';
import { Stack } from '@mui/system';
import { formatDate } from '../../../Utils/DateFormatter/Date';
import AntDatepicker from 'Components/Common/AntdDatePicker';
import moment from 'moment';
import Loader from 'Components/Common/Loader';
import { useLocation } from 'react-router-dom';
import { DoctorsApi } from 'Utils/Api/DoctorsApi';
import { FetchDoctorSlots } from 'Actions/appointmentAction';
import { connect } from 'react-redux';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import { removeDrPrefix } from 'Utils/StringFormatter/String';
import { revertFromBase64 } from 'Utils/Services/Authenticator';
import { AppointmentApi } from 'Utils/Api/AppointmentApi';
import CustomSwitch from 'Components/Common/Switch';
import NoResultFound from 'Components/Common/NoResult';
import { BOOKING_STATUS } from 'Constants/constant';

function BookingPop({
  btnClick,
  setBtnClick,
  doctorData,
  doctorSlotData,
  newdate,
  getDoctorSlots,
  openPatientInfoModal,
  openGuestInfo,
  openPatientInfo,
  selectedSlot,
  setSelectedSlot,
  setNewDate,
  applicationData,
  // date,
  isBtnLoading,
  doc,
  setViewData,
  deptData,
  setDeptData,
  deptValue,
  setDeptValue,
  nearDateClick = false,
  setNearDateClick=()=>{},
}) {
  const intl = useIntl();
  const location = useLocation();
  const imgStatus = useRef(true);
  const slotbtnRef = useRef({})
  const confirmBtnRef = useRef()
  const [showAvailable, updateAvailable] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [showQualify, setShowQualify] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [totalSlots, setTotalSlots] = useState([]);
  const { docId, setDocId } = doc
  const { docSlots: slotLoading = false } = isBtnLoading;
  const {subsequent_appt_booking : allowSubsequentSlot = false} = applicationData
  let params = new URLSearchParams(location.search);
  const param = params.get("docId");
  const slotParam = params.get("slot");
  const dateParam = params.get("date");
  const deptParam = params.get("dept");
  const [availableDatesLists, setAvailableDatesLists] = useState([]);
  const [availableOnly, setAvailableOnly] = useState(false);

  // const [defaultDate, setDefaultDate] = useState();
  // disable previous date

  useEffect(() => {
    getAvailableSlots(doctorData.doctor_id)
  }, [doctorData])

  const getAvailableSlots = (value) => {
    const config = {
      doctor_id: value,
      start_date: "",
      end_date: "",
    };
    AppointmentApi.getAvailableApptDates(config).then(({ error, response }) => {
      if (error) {
        return;
      }
      if (response) {
        const availableDates = response.slot_info.availabel_date;
        const formattedDatesArray = Object.keys(availableDates).map(
          (dateString) => {
            const dateObject = new Date(dateString);
            return formatDate(dateObject);
          }
        );
        setAvailableDatesLists(formattedDatesArray);
      }
    });
  };

  const disabledDates = (current) => {
    let formatdate = new Date(current);
    let formattedDate = formatDate(formatdate)
    return (
      (current && current.isBefore(moment().subtract(1, 'day'))) ||
      current.isAfter(
        moment().add(applicationData?.booking_window_max_days - 1, 'day')
      ) || !availableDatesLists.includes(formattedDate)
    );
  };

  useEffect(() => {
    if (btnClick) {
      setTimeout(() => {
        document.body.classList.add('modal-overflow');
      }, 0);
      fetchDoctorDetails()
    } else {
      document.body.classList.remove('modal-overflow');
      setShowQualify(false)
      setShowMore(false)
      setAvailableOnly(false);
    }
    return () => document.body.classList.remove('modal-overflow');
  }, [btnClick]);

  useEffect(()=>{
    if((openPatientInfo||openGuestInfo) &&param){
      fetchDoctorDetails()
    }
  },[openPatientInfo,openGuestInfo])


  useEffect(() => {
    if (param && slotParam &&deptParam&&dateParam) {
      const seletedDate = new Date(localStorage?.getItem('deptDate'))
      setNewDate(seletedDate);
      setDocId(param)
      setBtnClick(true)
      setSelectedSlot(revertFromBase64(slotParam))
      setNewDate(revertFromBase64(dateParam))
      setDeptValue(revertFromBase64(deptParam))
      openPatientInfoModal()
    }
  }, [param,slotParam,deptParam,dateParam]);
  useEffect(() => {
    if(btnClick && nearDateClick){
      const doc_Id = localStorage.getItem('availableDocId');
      if (!availableDatesLists.includes(formatDate(newdate))) {
        setNewDate(availableDatesLists[0]);
        setSelectedSlot(false);
        const config = {
          start_date: formatDate(availableDatesLists[0]),
          end_date: formatDate(availableDatesLists[0]),
        };
        setTotalSlots([]);
        getDoctorSlots(doc_Id, config);
      } else {
        setNewDate(newdate);
      }
    }
  }, [availableDatesLists, btnClick,nearDateClick]);


  const fetchDoctorDetails = () =>{
    const config = {
      start_date: formatDate(newdate),
      end_date: formatDate(newdate),
    };
    getDoctorSlots(docId, config);
    DoctorsApi.getDoctorDetails(docId).then((res) => {
      if (res.error) {
        return;
      }
      if (res.response) {
        setViewData({...res.response?.doctor,...res.response})
        setDeptData(res.response?.departments?.map((dept) => (
          {
            value: dept.dept_id,
            label: dept.dept_name
          } 
          )))
      }
    });
  }

  // close booking pop modal
  const closeModal = () => {
    setSelectedSlot(false);
    setBtnClick(false);
    setTotalSlots([]);
    setSelectedTab(0);
    updateAvailable(true);
    setDeptValue('');
    setNearDateClick(false);
  };

  // tab labels
  const bookAppointmentTab = [
    {
      label: translate('appointments.availability', 'Availability'),
    },
    {
      label: translate('appointments.viewProfile', 'View Profile'),
    },
  ];

  const keyDownSlotHandle = (e,index)=>{
    if(e.key === "Tab" && !e.shiftKey && (index !== totalSlots.length -1) && !(totalSlots.toSpliced(0,index +1).some((item)=>item.status === "available")) && (!selectedSlot || deptValue===null) ){
      e.preventDefault()
    }
    else if(e.key === "Tab" && !e.shiftKey && (!selectedSlot || deptValue===null) &&( index === totalSlots.length -1 )){
      e.preventDefault()
    }
    else if (e.key === "Tab" && !e.shiftKey && availableOnly  && (!selectedSlot || deptValue===null) && !(totalSlots.filter((x)=>x.status=== 'available').toSpliced(0,index+1).some((item)=>item.status === 'available')))
    {
      e.preventDefault()
    }
  }

  // handle show more in about section
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  // handle show more in qualification section
  const toggleQualifyShowMore = () => {
    setShowQualify(!showQualify);
  };
  // switch to availability tab
  const availableClick = () => {
    updateAvailable(true);
    // updateProfile(false);
  };

  // switch to view profile tab
  const profileClick = () => {
    updateAvailable(false);
    // updateProfile(true);
  };

  // function to select a slot
  const handleSelectSlots = (slot) => {
    if (slot.status === 'available') {
      setSelectedSlot(slot);
    }
  };

  //handle tab change
  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  // to Handle Change Date
  const dateChangeHandle = (value) => {
    const updatedDate = new Date(value);
    setNewDate(updatedDate);
    setSelectedSlot(false);
    const config = {
      start_date: formatDate(updatedDate),
      end_date: formatDate(updatedDate),
    };
    setTotalSlots([]);
    getDoctorSlots(doctorData.doctor_id, config);
  };

  useEffect(() => {
    if (doctorSlotData.op_schedule_details) {
      if (doctorSlotData?.op_schedule_details?.length) {
        const { doctor_schedule_details } =
          doctorSlotData?.op_schedule_details?.[0];
        let slots = doctor_schedule_details
          ?.map((slotDetails) =>{ 
            if(slotDetails?.slots?.length) 
            {
              return slotDetails.slots.map(item => ({
                ...item, session: `${appendTime(slotDetails?.start_time)} - ${appendTime(
                  slotDetails?.end_time
                )}`
              }))
            }else{
              return []
            }
          }).flat()
        if (allowSubsequentSlot) {
          let yourSlotAdded = false
          slots = slots.map((item) => {
            if (!yourSlotAdded && item.status === 'available') {
              yourSlotAdded = true
              return { ...item}
            }
            else if (item.status === 'available') {
              return { ...item, status: 'notavailable' }
            }
            else {
              return { ...item }
            }
          })
        }
        setTotalSlots(slots);
        // setDeptData(doctorSlotData?.departments.map((dept) => (
        //   {
        //     value: dept.dept_id,
        //     label: dept.dept_name
        //   } 
        //   ))) 
      }
    }
  }, [doctorSlotData]);

  const handleInputChange=(value)=>{
    setDeptValue(value)
  }
  const modalRef = useRef();
  // useEffect(() => {
  //   const listener = (event) => {
  //     if (!modalRef.current || modalRef.current.contains(event.target)) {
  //       return;
  //     }
  //     if (event.button === 0) setBtnClick();
  //   };
  //   document.addEventListener('mousedown', listener);

  //   return () => {
  //     document.removeEventListener('mousedown', listener);
  //   };
  // }, [modalRef, setBtnClick]);

  const onkeyDownSelectSlot=(index,i)=>{
      handleSelectSlots(i)
      setTimeout(() => {
        slotbtnRef?.current[index]?.blur()
        confirmBtnRef?.current?.focus()
      }, 0);
    }

  const getSlotClassName = (item, selectedSlot) => {
    switch (item?.status) {
      case 'booked':
        return 'booked'
      //no need
      case 'yourselection':
        return 'yourselection'

      case 'available':
        if(selectedSlot?.start_time == item?.start_time){
          return 'active'
        }
        return 'available'
      case 'notavailable':
        return 'disabled'
    }
  }

  return (
    <>
      <div
        className={`offcanvas-backdrop fade ${btnClick ? 'show' : ''}`}
      ></div>
      <div
        className={`offcanvas offcanvas-end custom-off-canvas booking-modal booking-modal-canvas ${btnClick ? 'show' : ''
          }`}
        tabIndex="-1"
        ref={modalRef}
        id="newBooking"
        aria-labelledby="bookLabel"
      >
        <div className="offcanvas-header">
          <h5 id="viewScheduleLabel">
            {translate(
              'patientPortal.bookAnAppointment',
              'Book an Appointment'
            )}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            onClick={() => closeModal(false)}
             onKeyDown={(e)=>{
                      if(e.key === "Tab" && e.shiftKey)e.preventDefault()
                    }}
          ></button>
        </div>
        {slotLoading ?
          <div className="loader-wrap mb-4">
            <Loader />
          </div>
          :
          <>
            <div className="offcanvas-body">
              <div className="doctor-profile ">
                <Stack direction="row" spacing={2}>
                  <CardMedia
                    className="doctor-booking-img"
                    style={{
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                    component="img"
                    image={doctorData.profile_img || placeholdericon}
                    onError={(e) => {
                      if (imgStatus.current) {
                        e.target.src = errorImg;
                        imgStatus.current = false;
                      }
                    }}
                  />
                </Stack>
                <div className="name-and-fees row">
                  <div className="col-md-6">
                    <h6>
                      Dr. {removeDrPrefix(doctorData?.name)}{' '}
                      <span className="lastName">{doctorData?.last_name}</span>
                    </h6>
                    <p className="description">
                      <span className="text-capitalize">
                      {doctorData?.departments
                        ?.map((val) => val?.dept_name)
                        .join(', ')}{' '}
                        </span>
                      {doctorData?.experience
                        ? doctorData.experience === 1
                          ? `/ ${doctorData.experience} yr. exp.`
                          : `/ ${doctorData.experience} yrs. exp.`
                        : ''}
                    </p>
                  </div>
                  <div className="col-md-2"></div>
                  <div className={`col-md-4 d-flex gap-2 d-md-block align-items-center consultation-fee ${!doctorSlotData.op_fees ? 'no-fee-added' : ''}`}>
                    <p className="mb-0">
                      {translate(
                        'patientPortal.ConsultationFee',
                        'Consultation Fee'
                      )}
                    </p>
                    <span className="fees">{doctorSlotData.op_fees ? `${doctorSlotData.op_fees}/-` : 'No fee added'}</span>
                  </div>
                </div>
              </div>

              <ul className="nav mb-2 custom-tab" id="pills-tab">
                {bookAppointmentTab.map((tab, index) => (
                  <li
                    onClick={index == 0 ? availableClick : profileClick}
                    className=" nav-item"
                    key={index + 1}
                    onKeyDown={(e)=>{
                      if(index === 1 && selectedTab === 1 && e.key === "Tab" && !e.shiftKey && !(doctorData.about?.length > 210) && !(doctorData.qualification_details?.length > 210))e.preventDefault()
                    }}
                  >
                    <button
                      className={index === selectedTab ? 'active' : ''}
                      onClick={() => handleTabChange(index)}
                    >
                      {tab.label}
                    </button>
                  </li>
                ))}
              </ul>

              <div className="tab-content" id="pills-tabContent">
                {showAvailable ? (
                  <div className="booking-date tab-pane active">
                    <div className="d-flex filter-checkbox gap-2 flex-wrap">
                      <h5 className="">
                        {intl.formatMessage({
                          id: 'patientPortal.pickTimeSlot',
                          defaultMessage: 'Pick a time slot',
                        })}
                      </h5>
                      {/* <div className="d-flex align-items-center gap-2 form-group otp">
                        <div className="disabled-indicator"></div>
                        <label className="">
                          {intl.formatMessage({
                            id: 'patientPortal.unavailableSlot',
                            defaultMessage: 'Unavailable Slot',
                          })}
                        </label>
                      </div> */}
                    </div>
                    <div className='row'>
                      <div className="form-group col-sm-4 col-lg-3">
                      <label htmlFor="date">
                        {intl.formatMessage({
                          id: 'patientPortal.whenQuestion',
                          defaultMessage: 'When?',
                        })}
                      </label>
                      <div className="mt-2">
                        <AntDatepicker
                          onInputChange={dateChangeHandle}
                          value={newdate}
                          disabledDate={disabledDates}
                          autofocus
                        />
                      </div>
                    </div>

                      {doctorData?.departments?.length > 1 &&
                        <div className="form-group col-sm-4 col-lg-5 mt-3 mt-sm-0">
                          <label htmlFor="" className="mandatory">
                          {intl.formatMessage({
                          id: 'patientPortal.whichSpecialty',
                          defaultMessage: 'Which Specilality?',
                        })}
                          </label>
                          <div className="mt-2">
                            <div className="select"
                            onKeyDown={(e)=>{
                              if(e.key === "Tab" && !e.shiftKey && !totalSlots.length) e.preventDefault()
                            }}
                            >
                              <ReactSelectDropDown
                                id="filterbydept"
                                options={deptData}
                                onInputChange={handleInputChange}
                                value={deptValue}
                                isClearable={true}
                                targetBody={false}
                              />
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="timings">
                      <div className="legends">
                        {Object.keys(BOOKING_STATUS).map((item, i) => (
                          <span key={i} className={`legend ${item}`}>
                            <span className="dot"></span>{' '}
                            {BOOKING_STATUS[item]}
                          </span>
                        ))}
                      </div>
                    {totalSlots.length && totalSlots.some(slot=> slot.status==='available') ?
                      <div className='toggle-filter'>
                        Available Slots Only <CustomSwitch checkedProps={availableOnly} handleSwitchChange={(value) => setAvailableOnly(value)} />
                      </div>:''
                     }
                      {!totalSlots.length && !slotLoading &&
                        <NoResultFound
                        text={translate(
                            'appointments.noAvailableSlots',
                            'No slots available')}
                        />
                      }

                      {totalSlots.length && totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).some((x) => x.day_type === 'morning') ? (
                        <div className="mt-3 availablity morning">
                          <h5 className=" title">
                            {intl.formatMessage({
                              id: 'patientPortal.morning',
                              defaultMessage: 'Morning',
                            })}{' '}
                            {/* (06:00 AM - 12:00 PM) */}
                          </h5>

                          <div className=" time-container">
                            {totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).map((i, index) =>
                              i.day_type === 'morning' ? (
                                <div
                                  key={index}
                                  onClick={() => handleSelectSlots(i)}
                                  tabIndex={i.status === 'available' ? 0 : -1}
                                  ref={(ref) => (slotbtnRef.current[`mng${index}`] = ref)}
                                  onKeyDown={(e)=>{
                                    if(e.key ==="Enter" && !e.shiftKey){
                                      e.preventDefault()
                                      onkeyDownSelectSlot(`mng${index}`,i)
                                    }
                                    else{
                                      keyDownSlotHandle(e,index)
                                    }
                                  }}
                                  // className={`time ${!(i.status === 'available')
                                  //     ? 'disabled'
                                  //     : appendTime(selectedSlot.start_time) ==
                                  //       appendTime(i.start_time)
                                  //       ? 'active'
                                  //       : ''
                                  //   }`}
                                  className={`time ${getSlotClassName(i, selectedSlot)}`}
                                >
                                  {appendTime(i.start_time)}
                                </div>
                              ) : (
                                ''
                              )
                            )}
                          </div>
                        </div>
                      ) : null}
                      {totalSlots.length && totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).some((x) => x.day_type === 'afternoon') ? (
                        <div className="mt-3 availablity afternoon">
                          <h5 className=" title">
                            {intl.formatMessage({
                              id: 'patientPortal.afternoon',
                              defaultMessage: 'Afternoon',
                            })}{' '}
                            {/* ( 12:00 PM : 05:00 PM) */}
                          </h5>
                          <div className=" time-container">
                            {totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).map((i, index) =>
                              i.day_type === 'afternoon' ? (
                                <div
                                  key={index}
                                  onClick={() => handleSelectSlots(i)}
                                  tabIndex={i.status === 'available' ? 0 : -1}
                                  ref={(ref) => (slotbtnRef.current[`afternon${index}`] = ref)}
                                  onKeyDown={(e)=>{
                                    if(e.key ==="Enter" && !e.shiftKey){
                                      e.preventDefault()
                                      onkeyDownSelectSlot(`afternon${index}`,i)
                                    }
                                    else{
                                      keyDownSlotHandle(e,index)
                                    }
                                  }}
                                  className={`time ${getSlotClassName(i, selectedSlot)}`}
                                >
                                  {appendTime(i.start_time)}
                                </div>
                              ) : (
                                ''
                              )
                            )}
                          </div>
                        </div>
                      ) : null}
                      {totalSlots.length && totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).some((x) => x.day_type === 'evining') ? (
                        <div className="mt-4 availablity evening">
                          <h5 className=" title ">
                            {intl.formatMessage({
                              id: 'patientPortal.evening',
                              defaultMessage: 'Evening',
                            })}{' '}
                            {/* (05:00 PM - 08:00 PM) */}
                          </h5>
                          <div className=" time-container">
                            {totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).map((i, index) =>
                              i.day_type === 'evining' ? (
                                <div
                                  key={index}
                                  onClick={() => handleSelectSlots(i)}
                                  tabIndex={i.status === 'available' ? 0 : -1}
                                  ref={(ref) => (slotbtnRef.current[`evng${index}`] = ref)}
                                  onKeyDown={(e)=>{
                                    if(e.key ==="Enter" && !e.shiftKey){
                                      e.preventDefault()
                                      onkeyDownSelectSlot(`evng${index}`,i)
                                    }
                                    else{
                                      keyDownSlotHandle(e,index)
                                    }
                                  }}
                                  className={`time ${getSlotClassName(i, selectedSlot)}`}
                                >
                                  {appendTime(i.start_time)}
                                </div>
                              ) : (
                                ''
                              )
                            )}
                          </div>
                        </div>
                      ) : null}
                      {totalSlots.length && totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).some((x) => x.day_type === 'night') ? (
                        <div className="mt-4 availablity night">
                          <h5 className=" title ">
                            {intl.formatMessage({
                              id: 'patientPortal.night',
                              defaultMessage: 'Night',
                            })}{' '}
                            {/* ( 08:00 PM - 06:00 AM) */}
                          </h5>
                          <div className=" time-container">
                            {totalSlots.filter((x) => availableOnly ? x.status === 'available' : true).map((i, index) =>
                              i.day_type === 'night' ? (
                                <div
                                  key={index}
                                  onClick={() => handleSelectSlots(i)}
                                  tabIndex={i.status === 'available' ? 0 : -1}
                                  ref={(ref) => (slotbtnRef.current[`nyt${index}`] = ref)}
                                  onKeyDown={(e)=>{
                                    if(e.key ==="Enter" && !e.shiftKey){
                                      e.preventDefault()
                                      onkeyDownSelectSlot(`nyt${index}`,i)
                                    }
                                    else{
                                      keyDownSlotHandle(e,index)
                                    }
                                  }}
                                  className={`time  ${!(i.status === 'available')
                                      ? 'disabled'
                                      : appendTime(selectedSlot.start_time) ==
                                        appendTime(i.start_time)
                                        ? 'active'
                                        : ''
                                    }`}
                                >
                                  {appendTime(i.start_time)}
                                </div>
                              ) : (
                                ''
                              )
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className=" profile">
                    {doctorData.about ? (
                      <div className="general-description data">
                        <p className="description">
                          {doctorData.about?.slice(
                            0,
                            showMore ? doctorData.about.length : 210
                          )}{' '}
                          {doctorData.about?.length > 210 ? (
                            <span
                              onClick={toggleShowMore}
                              className="ml-1 fs-15 fw-500"
                              style={{ color: '#4bb691', cursor: 'pointer' }}
                              tabIndex="0"
                              onKeyDown={(e)=>{
                                if(e.key === "Tab" && !e.shiftKey && !(doctorData.qualification_details?.length > 210)) e.preventDefault()
                                else if(e.key === "Enter" && !e.shiftKey){
                                  e.preventDefault()
                                  toggleShowMore()
                                }
                              }}
                            >
                              {'  '}
                              {showMore ? 'less' : 'more...'}
                            </span>
                          ) : null}
                        </p>
                      </div>
                    ) : null}
                    <div>
                      <h5 className="qualification data">
                        {intl.formatMessage({
                          id: 'patientPortal.doctorQualification',
                          defaultMessage: 'Doctor Qualification',
                        })}
                      </h5>
                      <p className="description">
                        {doctorData.qualification_details
                          ? doctorData.qualification_details.slice(
                            0,
                            showQualify
                              ? doctorData.qualification_details.length
                              : 210
                          )
                          : '-'}
                        {doctorData.qualification_details?.length > 210 ? (
                          <span
                            onClick={toggleQualifyShowMore}
                            className="ml-1 fs-15 fw-500"
                            style={{ color: '#4bb691', cursor: 'pointer' }}
                            tabIndex="0"
                            onKeyDown={(e)=>{
                              if(e.key === "Enter" && !e.shiftKey){
                                e.preventDefault()
                                toggleQualifyShowMore()
                              }
                              else if(e.key==="Tab" && !e.shiftKey) e.preventDefault()
                            }}
                          >
                            {'  '}
                            {showQualify ? 'less' : 'more...'}
                          </span>
                        ) : null}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {showAvailable ? (
              <div className="offcanvas-footer">
                <button
                  data-bs-toggle="modal"
                  id="proceed"
                  type="button"
                  className="confirm-btn btn btn-primary"
                  onClick={openPatientInfoModal}
                  disabled={!selectedSlot || deptValue===null }
                  onKeyDown={(e)=>{
                    if(e.key === "Tab" && !e.shiftKey) e.preventDefault()
                  }}
                  ref={confirmBtnRef}
                >
                  {intl.formatMessage({
                    id: 'patientPortal.confirm',
                    defaultMessage: 'Confirm',
                  })}{' '}
                  &{' '}
                  {intl.formatMessage({
                    id: 'patientPortal.proceed',
                    defaultMessage: 'Proceed',
                  })}
                </button>
              </div>
            ) : null}
          </>
        }
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  doctorSlotData: state.AppointmentReducer.doctorSlotData,
  isBtnLoading: state.AppointmentReducer.isBtnLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getDoctorSlots: (id, config, setLoader) =>
    dispatch(FetchDoctorSlots(id, config, setLoader)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingPop);
