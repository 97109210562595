import React from 'react';
import './TermsOfUse.css';
import { translate } from 'Language/Translate';
function TermsOfUse() {
  return (
    <>
      <section className="bread-crumbs mb-lg-2">
        <div className="wrapper links">
          <a className="previous" href="#/webConnect">
            {translate('patientPortal.home', 'Home')}
          </a>
          <span> {'>'} </span>
          <a className="bread-crumb-cursor"> {translate('patientPortal.terms', 'Terms of Use')}</a>
        </div>
      </section>
      <section className="terms-of-use">
        <div className="wrapper">
          <div className="row">
            <div className="col-lg-9 pe-lg-5">
              <div className="heading">
                <h1>{translate('patientPortal.terms', 'Terms of Use')}</h1>
              </div>
              <div className="terms-content">
                <h5>1. Our Service</h5>
                <p>
                  Through the Platform, We provide You with the following
                  services:
                </p>
                <h6>
                  <i className="bi bi-arrow-right-short"></i> Creating and
                  maintaining user accounts:
                </h6>
                <p>
                  Users need to register on the Platform in order to use the
                  Platform or the Services provided through the Platform. In
                  order to register, You have to provide certain personal
                  details including but not limited to Your name, phone number,
                  email address, birth date, gender, etc.
                </p>
                <h6>
                  <i className="bi bi-arrow-right-short"></i> Scheduling an
                  appointment for medical consultation:
                </h6>
                <ol type="i">
                  <li>
                    You can book an appointment for a medical consultation with
                    a healthcare or wellness service provider/doctor/physician
                    (“HSP”) listed on the Platform. Medical consultations on the
                    Platform shall be available for multi specialties
                  </li>
                  <li>
                    You will receive a confirmation of appointment for medical
                    consultation with a HSP, on the Platform and/or via SMS
                    and/or email / online communication messaging services. We
                    reserve the right to reschedule or cancel an appointment
                    without any prior notice. The time provided for a
                    consultation to you is indicative and actual consultation
                    time may change depending on the consulting HSP’s discretion
                    or other circumstances, for which You shall be accordingly
                    communicated. You agree that there might be
                    technological/logistic/unforeseen circumstances that might
                    lead to delay or cancellation of the consultation for which
                    appropriate resolution either as rescheduling/ refund shall
                    be provided. For any support related to issue/s faced by You
                    pre/during / post consultation, You can reach Us through
                    various channels as set out on the Platform and Our team
                    shall help You for the same. Consultations can be booked,
                    rescheduled, or cancelled free of cost up to 15 (fifteen)
                    minutes prior to the scheduled /booked appointment time for
                    an chat-based consultation (“Online Medical Consultation”)
                    and up to 30 (thirty) minutes prior to the scheduled/booked
                    the appointment time for a physical visit based
                    consultation. You are allowed to reschedule an Online
                    Medical Consultation up to a maximum of 3 (three) times for
                    free and are allowed to reschedule a physical consultation
                    once for free.
                  </li>
                  <li>
                    Your consulting HSP reserves the right to provide a free
                    review, post-medical consultation.
                  </li>
                </ol>
                <br />
                <h6>
                  <i className="bi bi-arrow-right-short"></i> Holding medical
                  consultations:
                </h6>
                <p>
                  You can have an Online Medical Consultation via chat on the
                  Platform with the HSP selected by You /allocated to You:
                </p>
                <p>
                  Please ensure that You are equipped with proper video
                  conference equipment and internet connectivity as required for
                  the Service You want to avail. In case of inadequate technical
                  error, or defective internet connectivity, We reserve the
                  right to reschedule or cancel Your appointment without any
                  notice.
                </p>
                <p>
                  You understand and acknowledge that there will not be any
                  physical examination involved and the Online Medical
                  Consultation services shall be provided remotely in accordance
                  with these Terms and Conditions. The opinion delivered by the
                  HSP shall solely be based on the verbal communication between
                  the concerned HSP and the patient, and any test reports and
                  other information provided/uploaded by You on the Platform.
                </p>
                <p>
                  Your medical records may be viewed by the HSP on the chat
                  facility provided on the Platform in the course of medical
                  consultations and will remain accessible to You for a specific
                  number of days after a particular consultation concludes, as
                  determined by the HSP.
                </p>
                <ol type="i">
                  <li>
                    The Services are not meant in any way for emergency and
                    life-threatening conditions. It is advised to take the
                    patient to the nearest hospital in such cases or as advised
                    by HSP.
                  </li>
                  <li>
                    In case the HSP is not responding You are advised to get in
                    touch with our customer care.
                  </li>
                </ol>
                <br />
                <h6>
                  <i className="bi bi-arrow-right-short"></i>Receiving
                  e-prescription from the HSP based on the medical consultation:
                </h6>
                <p>
                  You shall receive an e-prescription on the Platform after
                  consultation with the concerned HSP
                </p>
                <br />
                <h6>
                  <i className="bi bi-arrow-right-short"></i> Accessing Your
                  medical records on the Platform:
                </h6>
                <p>You can access your medical records on the Platform.</p>
                <h6>
                  <i className="bi bi-arrow-right-short"></i> Diagnostics
                  Services:
                </h6>
                <p>
                  You may book an appointment for seeking the diagnostic tests
                  and packages offered by Tele Medicine Diagnostics on the
                  Platform. You may book diagnostic test(s) and package(s) on
                  the Platform and visit the concerned diagnostics centres / lab
                  of the Tele Medicine Diagnostics or schedule the sample pickup
                  from home by the Tele Medicine Diagnostics.
                </p>
                <h6>
                  <i className="bi bi-arrow-right-short"></i> Any other services
                </h6>
                <p></p>
              </div>

              <div className="terms-content">
                <h5 className="mb-4">2. YOUR USE OF THE PLATFORM</h5>

                <h6>
                  <i className="bi bi-arrow-right-short"></i> Due diligence
                  conditions:
                </h6>
                <ol type="a">
                  <li>
                    You are solely responsible for the medical, health, and
                    personal information you provide on the Platform, and You
                    are requested to use Your discretion in providing such
                    information.
                  </li>
                  <li>
                    The advice or the Services provided by the HSP will depend
                    upon the information You provide on the Platform. You will
                    provide accurate and complete information everywhere on the
                    Platform, based on which You will receive the Services.
                  </li>
                  <li>
                    You will be solely responsible for all access to and use of
                    this site by anyone using the password and identification
                    originally assigned to you whether or not such access to and
                    use of this site is actually authorized by you, including
                    without limitation, all communications and transmissions and
                    all obligations (including, without limitation, financial
                    obligations) incurred through such access or use. You are
                    solely responsible for protecting the security and
                    confidentiality of the password and identification assigned
                    to You.
                  </li>
                  <li>
                    The information provided by You may be used by us for the
                    purpose of Services including analysis, research, training,
                    and disclosure (where required) to our affiliates, group
                    companies, agents and government authorities, etc., as
                    stated in Our Privacy Policy
                  </li>
                  <li>
                    The information provided by You can be retained by us and
                    can be used without revealing Your identity, as per Our
                    Privacy Policy
                  </li>
                  <li>
                    We reserve Our rights to refuse Service or terminate
                    accounts at Our discretion, if we believe that You have
                    violated or are likely to violate applicable law or these
                    Terms and Conditions.{' '}
                  </li>
                </ol>
                <h6>
                  <i className="bi bi-arrow-right-short"></i> Scope of Services:
                </h6>

                <ol type="a">
                  <li>
                    The Services availed by You from a HSP via the Platform are
                    an arrangement between You and the HSP You select. The
                    Platform only facilitates connections between You and the
                    HSP and bears no responsibility for the outcome of any such
                    medical consultation or other Services obtained by You.
                  </li>
                  <li>
                    We shall not be liable for deficiency or shortfall in
                    Services/misdiagnosis / faulty judgment/interpretation
                    error/perception error / adverse events/inefficacy of
                    prescribed treatment or advice or investigation
                    reports/validity of the advice or prescription or
                    investigation reports provided by the HSP / unavailability
                    of the recommended or prescribed treatment or medication
                    under any condition or circumstances. Users are advised to
                    use their discretion for following the advice obtained
                    post-medical consultation with HSP via the Platform or post
                    Services.
                  </li>
                  <li>
                    We only facilitate the connections between You and the HSP
                    established through the Platform and does not in any way
                    facilitate, encourage, permit, or require you to contact the
                    HSP outside the Platform. Any contact between You and the
                    HSP through the Platform, will be subject to these Terms and
                    Conditions.
                  </li>
                  <li>
                    You may view and access the content available on the
                    Platform solely for the purposes of availing the Services,
                    and only as per these Terms and Conditions. You shall not
                    modify any content on the Platform or reproduce, display,
                    publicly perform, distribute, or otherwise use such content
                    in any way for any public or commercial purpose or for
                    personal gain.
                  </li>
                </ol>
                <h5>
                  <i className="bi bi-arrow-right-short"></i>Prohibitions:
                </h5>
                <ol type="a">
                  <li>
                    You may not reproduce, distribute, display, sell, lease,
                    transmit, create derivative works from, translate, modify,
                    reverse-engineer, disassemble, decompile or otherwise
                    exploit the Platform or any portion of it unless expressly
                    permitted by Tele Medicine in writing.
                  </li>
                  <li>
                    You may not make any commercial use of any of the
                    information provided on the Platform.
                  </li>
                  <li>
                    You may not impersonate any person or entity, or falsely
                    state or otherwise misrepresent your identity, age or
                    affiliation with any person or entity.
                  </li>
                  <li>
                    You may not upload any content prohibited under applicable
                    law, and/or designated{' '}
                  </li>
                  <li>
                    You may not contact or make any attempt to contact the
                    concerned HSP for a consultation, follow up to a prior
                    medical consultation or for any other reason outside the
                    Platform via email, SMS, messaging services or any other
                    mode of communication outside the authorized channels.
                  </li>
                  <li>
                    You may not assign, transfer, or sub-contract any of your
                    rights or obligations under these Terms or any related order
                    for Products to any third party, unless agreed upon in
                    writing by Tele Medicine.
                  </li>
                </ol>

                <p>
                  <h5 className="mb-4">3. PROHIBITED CONTENT</h5>
                  <h6>
                    <i className="bi bi-arrow-right-short"></i> You shall not
                    upload to, distribute, or otherwise publish through the
                    Platform, the following Prohibited Content, which includes
                    any content, information, or other material that:
                  </h6>
                  <ol type="a">
                    <li>
                      belongs to another person and which you do not own the
                      rights to;
                    </li>
                    <li>
                      is harmful, harassing, blasphemous, defamatory, obscene,
                      pornographic, pedophilic, invasive of another&#39;s
                      privacy, including bodily privacy, insulting or harassing
                      on the basis of gender, libellous, racially or ethnically
                      objectionable, or otherwise inconsistent with or contrary
                      to the laws in force;
                    </li>
                    <li>
                      is hateful, racially or ethnically objectionable,
                      disparaging of any person;
                    </li>
                    <li>
                      relates to or seems to encourage money laundering or
                      gambling
                    </li>
                    <li>harm minors in any way;</li>
                    <li>
                      infringes any patent, trademark, copyright, or other
                      proprietary rights;
                    </li>
                    <li>
                      violates any law in India for the time being in force;
                    </li>
                    <li>
                      deceives or misleads the addressee about the origin of
                      your message and intentionally communicates any
                      information which is patently false or misleading in
                      nature but may reasonably be perceived as a fact;
                    </li>
                    <li>
                      communicates any information which is grossly offensive or
                      menacing in nature;
                    </li>
                    <li>impersonates another person;</li>
                    <li>
                      contains software viruses or any other computer code and
                      malicious programs;
                    </li>
                    <li>
                      File or program designed to interrupt, destroy or limit
                      the functionality of any computer resource;
                    </li>
                    <li>
                      threatens the unity, integrity, defence, security, or
                      sovereignty of India, friendly relations with foreign
                      states, or public order, promoting enmity between
                      different groups on the grounds of religion or caste with
                      the intent to incite violence;
                    </li>
                    <li>
                      is patently false and untrue, and is written or published
                      in any form, with the intent to mislead or harass a
                      person, entity or agency for financial gain or to cause
                      any injury to any person;
                    </li>
                    <li>
                      incites any offence or prevents investigation of any
                      offence or insults any other nation;
                    </li>
                    <li>
                      is abusive or inappropriate to the HSP conducting your
                      medical consultation or any employees, consultants or
                      technicians of Tele Medicine or affiliate who you may
                      interact with for availing Services; and
                    </li>
                    <li>
                      is not relating to the medical consultation or relating to
                      any of the services you avail from us.
                    </li>
                    <p>
                      You also understand and acknowledge that if you fail to
                      adhere to the above, we have the right to remove such
                      information and/or immediately terminate your access to
                      the Services and/or to the Platform.{' '}
                    </p>
                  </ol>
                </p>
                <p>
                  <h5 className="mb-4">4. DATA & INFORMATION POLICY </h5>
                  <ol type="a">
                    <li>
                      We respect your right to privacy in respect of any
                      personal information provided to us for the purposes of
                      availing our Services.{' '}
                    </li>
                    <li>
                      {' '}
                      Account Deletion: Users have the right to delete their
                      Tele Medicine account and personal information at any
                      time, in line with Tele Medicine&#39;s commitment to data
                      privacy and applicable laws. Tele Medicine will delete the
                      user&#39;s data within 45 days of the account deletion
                      request, and no further communications will be sent.
                      Retrieval of deleted data will not be possible, except
                      upon specific user request for account reactivation. Tele
                      Medicine will mark the phone number as &#39;inactive&#39;
                      after account deletion, allowing subsequent logins without
                      data retrieval. Users can refer to an FAQ on the entire
                      process, including how to delete an account and how to
                      restore it on Tele Medicine’s website.
                    </li>
                  </ol>
                </p>
              </div>
            </div>
            <div className="col-lg-3 mt-3">
              <div className="side-img"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermsOfUse;
