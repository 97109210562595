import React, { useEffect } from 'react';
import 'Assets/style/conformPopup.css';
import { translate } from '../../Language/Translate';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import calendarIcon from 'Assets/img/icons/calender-icon.svg';
import ButtonLoader from 'Components/Common/ButtonLoader';
import FocusTrap from 'focus-trap-react'
const ConflictConform = (props) => {
  const { onConfirm, onClose, isBtnLoading = false } = props;

  useEffect(() => {
    document.body.classList.add('modal-overflow');
    return () => document.body.classList.remove('modal-overflow');
  }, []);

  return (
    <>
     <FocusTrap
     focusTrapOptions={{
      clickOutsideDeactivates:true
    }}>
      <div className="AppointmentModal d-flex">
        <div className={`modalContent leaveConfirm_popup modal_xs custom-modal-md`}>
          <div className="modalHeader">
            <div className="row">
              <div className="col-md-12">
                <CloseIcon className='close-icon'
                  style={{ float: 'right', cursor: 'pointer', color: '#75808E' }}
                  onClick={onClose}
                />
              </div>
            </div>
          </div>
          <div
            className="modalContentarea-popup pb-0"
            style={{
              maxHeight: 430,
              marginBottom: 10,
            }}
          >
            <div className="mb-3">
              <div className="modalContentarea-popup">
                <div className="mt-2 justify-content-center text-center">
                  <img className='calendar-icon' src={calendarIcon} alt='' />
                  <h4 className='fw-bold text-center mb-3'>
                    {translate(
                      'appointments.previousAppointment',
                      'Previous Appointment In Progress'
                    )}
                  </h4>
                  <div style={{ marginBottom: 5, marginTop: 5 }}>
                    <p>
                      {translate(
                        'appointments.areYouWantTo',
                        'You currently have an appointment in progress. Are you sure you want to start a new appointment?'
                      )}
                      <p className="mt-3 pb-4 fw-500">
                        {translate(
                          'appointments.completePrevious',
                          'The previous appointment will be automatically marked as completed.'
                        )}
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal_footer">
                <div className="">
                  <div className="row mt-1">
                    <div className="col-md-12">
                      <ul className="modalBtm-popup d-flex align-items-center justify-content-center">
                        <li onClick={onClose}>
                          <button
                            type="button"
                            className="btn btn-light fit-content px-4"
                            id="cancel"
                          >
                            {translate('common.cancel', 'Cancel')}
                          </button>
                        </li>
                        <li onClick={onConfirm}>
                          <button
                            type="button"
                            className={'btn btn-primary loader-btn'}
                            id="save"
                          >
                            {
                              isBtnLoading ?
                                <ButtonLoader
                                  text={
                                    translate(
                                      'appointments.start',
                                      'Start Appointment'
                                    )
                              }
                                />
                                :
                                translate(
                                  'appointments.start',
                                  'Start Appointment'
                                )
                            }
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </FocusTrap>
    </>
  );
};

ConflictConform.prototype = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  leaveStatus: PropTypes.string,
};

export default ConflictConform;
