import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { translate } from '../../../Language/Translate';
import keycloak from 'Utils/keyCloak/keyCloak';
import { mainLogoUrl } from 'Utils/Branding/logoGenerator';
import { connect } from 'react-redux';
import { checkAppPermission } from 'Utils/Permissions/Permissions';

const Navbar = (props) => {
  const { showNav, setShowNav, loggedIn, subscriptionData } = props;
  const history = useHistory();
  const pathlocation = useLocation();

  const [pathname, setPathname] = useState(pathlocation.pathname.split('/')[2]);
 
  useEffect(() => {
    setPathname(pathlocation.pathname.split('/')[2]);
  }, [pathlocation]);

  const handleUserLogin = () => {
    keycloak.login({
      redirectUri:
        window.location.origin +
        '/#/webConnect?loginType=patient&isLogin=success',
    });
  };
  const handleUserSignUp = () => {
    keycloak.register({
      redirectUri: window.location.origin + '/#/webConnect?isLogin=success',
    });
  };

  const menuItems = [
    {
      path: '/webConnect/appointments',
      label: translate('patientNav.appointments', 'Appointments'),
      isActive: pathname === 'appointments',
    },
    {
      path: '/webConnect/profiles',
      label: translate('patientNav.profiles', 'Profiles'),
      isActive: pathname === 'profiles' || pathname === 'profileSetting',
    },
    {
      path: '/webConnect/prescriptions',
      label: translate('patientNav.prescriptions', 'Prescriptions'),
      isActive: pathname === 'prescriptions',
    },
    {
      path: '/webConnect/medical-records',
      label: translate('patientNav.medRecords', 'Medical Records'),
      isActive: pathname === 'medical-records',
    },
    // {
    //   path: '/webConnect/invoices',
    //   label: translate('patientNav.invoices', 'Invoices'),
    //   isActive: pathname === 'invoices',
    // },
    {
      path: '/webConnect/contact-us',
      label: translate('patientNav.contactUs', 'Contact Us'),
      isActive: pathname === 'contact-us',
    },
  ];

  return (
    <div
      className={`collapse navbar-collapse justify-content-end responsive-section ${
        showNav ? 'show' : ''
      }`}
      id="navbarSupportedContent"
    >
      <div className="responsive-header">
        <Link to="/webConnect" id="home">
          <img
            className="logo"
            src={mainLogoUrl(subscriptionData)}
            alt="Logo"
            onClick={() => setShowNav(false)}
          />
        </Link>
        <button
          type="button"
          className="btn-close"
          onClick={() => setShowNav(false)}
        ></button>
      </div>
      {!checkAppPermission(subscriptionData) ? null : loggedIn ? (
        <>
          <div className="main-links">
            <ul>
              {menuItems.map((item, index) => (
                <li
                  key={index}
                  className={item.isActive ? 'active-btn-link' : 'hover-link'}
                  onClick={() => {
                    setShowNav(false);
                    history.push(item.path);
                  }}
                  onKeyDown={(e)=>{
                    if(e.key === "Enter" && !e.shiftKey){
                      setShowNav(false);
                      history.push(item.path);
                    }
                  }}
                >
                  <a
                  tabIndex={item.isActive ? "-1" : "0"}
                  >{item.label}</a>
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : (
        <ul className="header-btn-wrap main-links">
          <li
            className={`${
              pathname === 'contact-us' ? 'active-btn-link' : 'hover-link'
            }`}
          >
            <NavLink
              onClick={() => setShowNav(false)}
              className="td-none"
              to={'/webConnect/contact-us'}
            >
              {translate('patientNav.contactUs', 'Contact Us')}{' '}
            </NavLink>
          </li>
          <div className="login-btns">
            <li>
              <button
                className="link-btn hover-link"
                onClick={() => handleUserLogin()}
              >
                {translate('patientPortal.login', 'Login')}
              </button>
            </li>
            <li>
              <button
                className="btn btn-secondary"
                onClick={() => handleUserSignUp()}
              >
                {translate('patientPortal.signup', 'Sign Up')}
              </button>
            </li>
          </div>
        </ul>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  subscriptionData:
    state.SettingsReducer.subscriptionData,
});

export default connect(mapStateToProps)(Navbar);
