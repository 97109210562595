import React, { useEffect, useState} from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "moment/locale/fr";
import "moment/locale/en-in";
import greenTick from 'Assets/img/icons/green-tick-badge.svg'
import "react-big-calendar/lib/css/react-big-calendar.css";
import { appendTime, formatDateto_D_WD_M_Y, formatDateInDDMMYYYY } from 'Utils/DateFormatter/Date';
import "./customTimeline.css"
import reschedueledIcon from 'Assets/img/icons/rescheduled-icon.svg'
import { translate } from 'Language/Translate';
import CoinIcon from 'Assets/img/icons/coin.svg';
import telephone from '../../Assets/img/icons/telephone.svg'
import envelope from '../../Assets/img/icons/envelope.svg'
import person from '../../Assets/img/icons/person-round.svg'
import clock from '../../Assets/img/icons/clock.svg'
import listIcon from '../../Assets/img/icons/list-icon.svg'
import editIcon from '../../Assets/img/icons/edit-icon-new.svg'
import deleteIcon from '../../Assets/img/icons/delete-icon-new.svg'
import { APPOINTMENT_STATUS, PAID } from 'Constants/constant';
import { Tooltip } from '@mui/material';
// import Loader from 'Components/Common/Loader';
// import { PAID } from 'Constants/constant';
import {formatDate} from '../../Utils/DateFormatter/Date'

let status = {
  upcoming: {
    class: 'upcoming',
    label: translate('appointments.upcoming', 'Upcoming'),
  },
  notattempted: {
    class: 'not-attempted',
    label: translate('appointments.notAttempted', 'Not Attempted'),
  },
  cancelled: {
    class: 'cancelled',
    label: translate('appointments.cancelled', 'Cancelled'),
  },
  completed: {
    class: 'completed',
    label: translate('appointments.completed', 'Completed'),
  },
  inprogress: {
    class: 'in-progress',
    label: translate('appointments.inProgress', 'In Progress'),
  },
  waiting: {
    class: 'waiting',
    label: translate('appointments.waiting', 'Waiting'),
  },
};

const statusDot = {
  upcoming: 'upcoming',
  notattempted: 'notattempted',
  cancelled: 'cancelled',
  label: 'Cancelled',
  completed: 'completed',
  inprogress: 'in-progress',
  empty: 'empty',
  waiting: 'waiting',
};

const CustomTimeline = ({
  events,
  onEditClick,
  onDeleteClick,
  onCancelClick,
  handlepaymentInfo,
  language,
  apptisLoader,
  isloader,
  filteredDate,
  patient,
  stat
}) => {
  moment.locale(language);
  const localizer = momentLocalizer(moment);
  const [currentDate] = useState(new Date())
  
  useEffect(() => {
    setTimeout(() => {
      if (formatDate(currentDate) == formatDate(filteredDate) && !(patient || stat)) {
        let ele = document.getElementsByClassName("rbc-current-time-indicator");
        if (ele[0]) {
          ele[0].scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      } else {
        if (patient || stat) {
        let ind = getLowestTimeIndex(events);
        if (events[ind]) {
          let ele = document.getElementById(`${events[ind].id}EventId`);
          if (ele) {
            ele.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
              });
            }
          }
        } else {
          let ele = document.getElementsByClassName(
            "rbc-current-time-indicator"
          );
          if (ele[0]) {
            ele[0].style.display = "block";
            ele[0].style.visibility = "hidden";
            ele[0].scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }
        }
      }
    }, 100);
  }, [events]);

  function getLowestTimeIndex(data) {
    return data.reduce((lowestIndex, obj, index) => {
      const currentTime = new Date(`1970-01-01 ${obj.appt_start_time}`);
      const lowestTime = new Date(
        `1970-01-01 ${data[lowestIndex].appt_start_time}`
      );
      return currentTime < lowestTime ? index : lowestIndex;
    }, 0);
  }
  const [reschedView, setReschdView] = useState({});
  // store appointment
  const [appointmemntView,setAppointmemntView] = useState(false)
  // handle rescedule details view
  const handleRescheduleDetails = (event,id) => {
    if(Object.keys(reschedView).length === 0){
    event.stopPropagation();
    setReschdView({ [id]: !reschedView[id] });
    setAppointmemntView({})
    }
  };
  const handleHistoryClose=()=>{
    setReschdView({})
  }

  const EventComponent = (e) => {
    let { event } = e;
    return (
      <div className="h-100 eventTimelineScroll" key={event.id} id={`${event.id}EventId`} 
      // title={`${event.appt_start_time} - ${event.appt_end_time} : ${event.appt_status}`}
      onClick={()=>{
        if(Object.keys(reschedView).length){
          setReschdView({});
        }else if(appointmemntView?.appointment_id){
          setAppointmemntView(false)
        }
      }}
      >
        {' '}
        <div
          id={`appointmentViewToggle-${event.id}`}
          className={`single-slot ${status[event.status]?.class} m-0`}
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="true"
        >
          <div className="schedule-and-name text-truncate ">
            <h6 className="schedule">
              {appendTime(event.start, language)} -{' '}
              {appendTime(event.end, language)}
            </h6>

            <h6 className="patient-name text-truncate">
            <Tooltip title={event.title} placement='bottom-end'>{event.title}</Tooltip>
            </h6>
          </div>
          <div className="status">
            <span className="status-icon">
              {event?.payment_details?.payment_status === PAID && (
                <Tooltip title={translate('appointments.paymentpaid', 'Paid')}>
                  <img className="tick-icon" src={greenTick} alt="" />
                </Tooltip>
              )}
              {event?.rescheduled_appointments?.length && event.status === APPOINTMENT_STATUS.upcoming ? (
                <img
                onClick={(e) =>
                  handleRescheduleDetails(e,event.appointment_id)
                }
                  className="rescheduled-img"
                  src={reschedueledIcon}
                  alt=""
                  title={'Rescheduled'}
                />
              ) : null}
            </span>
            <div className="dot">•</div> {status[event.status]?.label}
          </div>
        </div>
        {reschedView[event.appointment_id] && event.rescheduled_appointments?.length
          ?
          <>
            <div className="popover-container v2 bottom custom-tooltip-v2 history-popover">
              <div
                className={`custom-popover show`}
              // ref={ref}
              >
                <div className="popover-header">
                  <h6>
                    {/* {translate('doctors.feeHistory', 'Fee History')} */}
                    Reschedule History
                  </h6>
                  <button
                    className="btn-close"
                    onClick={handleHistoryClose}
                  ></button>
                </div>
                <div className="name-and-count">
                  <div className="name tex-capitalize">
                    Patient Name: {event.patient_name}
                  </div>
                  <span className="total-res">
                    {translate("Total Reschedules")}:{" "}
                    {event.rescheduled_appointments?.length}
                  </span>
                </div>
                <div className="popover-body">
                  <table >
                    <thead>
                      <tr>
                        <th>Doctor Name </th>
                        <th>Department</th>
                        <th>Scheduled From</th>
                      </tr>
                    </thead>
                    <tbody>
                      {event.rescheduled_appointments?.map(
                        (item) => {
                          return (
                            <>
                              <tr>
                                {/* <div className="single-history"> */}
                                <td>
                                  <span>{item.doctor_name}</span>
                                </td>
                                <td>
                                  <span className="text-capitalize">
                                    {item.dept_name}
                                  </span>
                                </td>
                                <td>
                                  <div>
                                    {formatDateInDDMMYYYY(
                                      item.appt_date
                                    )}
                                  </div>
                                  <span className="time">
                                    {appendTime(
                                      item.appt_start_time
                                    )}{" "}
                                    -{" "}
                                    {appendTime(
                                      item.appt_end_time
                                    )}
                                  </span>
                                </td>
                                {/* </div> */}
                              </tr>
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="overlay"></div>
          </>
          :
          handlePopup(event)
        }
      </div>
    );
  };

  const onClose = (id) => {
    const element = document.getElementById(`appointmentView-${id}`);
    const element1 = document.getElementById(`appointmentViewToggle-${id}`);
    element.classList.remove('show');
    element1.classList.remove('show');
  };
  const handlePopup = (item) => {
    return (
      <div>
        <div
        className="dropdown-menu custom-popover v2 popupDoctorViewday"
        id={`appointmentView-${item?.appointment_id}`}
        key={item?.appointment_id}
      >
        <div className="popover-header mb-3">
         <div className='header-id'>
         <h6>
            {translate('patientView.apptDetails', 'Appointment Details')}
          </h6>
          <div className="d-flex gap-2 align-items-center">
          <span> <p className='id'>{item?.appointment_id}</p></span>
          <span className={`status ${statusDot[item?.live_status]}`}>
            <div className="dot">•</div> {status[item?.live_status]?.label}
          </span>
          </div>
         </div>
          <span className="d-flex align-items-center gap-1">
             {(onEditClick && (item.live_status === 'upcoming' || item.live_status=== 'waiting')) && (
                  <button className="btn-transparent" onClick={() => onEditClick(item)}>
                    <img src={editIcon} alt="" />
                  </button>
                )}
              {(onDeleteClick && item.live_status != 'completed' && item.live_status !== APPOINTMENT_STATUS.inProgress&& item.live_status !== APPOINTMENT_STATUS.waiting&&item.live_status !== APPOINTMENT_STATUS.upcoming) && (
                  <button className="btn-transparent"  onClick={() => onDeleteClick(item)}>
                    <img src={deleteIcon} alt="" />
                  </button>
                )}
            {/* <button className="btn-transparent">
          <i className="bi bi-pencil" />
        </button> */}
            <button
              className="btn-transparent"
              onClick={() => {
                onClose(item?.appointment_id);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
              </svg>
            </button>
          </span>
        </div>
        <div className="popover-body">
        <div className="icon-block mb-3">
          <img src={person} alt="" />
            <div className="mt-1">
              <span>
                {translate('appointments.patientName', 'Patient Name')}
              </span>
              <p className="mb-0 mt-2">{item?.patient_name}</p>
            </div>
          </div>
          {item.patient_email &&
            <div className="icon-block mb-3 me-4 bordred-top-bottom">
              <img src={envelope} alt="" />
              <div className="mt-1">
                <span>{translate('common.emailId', 'Email Address')}</span>
                <p className="mb-0 mt-2 mail-text">{item.patient_email}</p>
              </div>
            </div>
          }
          <div className="items-set">
          <div className="icon-block">
          <img src={clock} alt="" />
            <div>
            <span>Date & Time</span>
              <p className="mb-0 mt-2">
                {formatDateto_D_WD_M_Y(new Date(item?.appt_date), language)}
              </p>
              <p className="mb-0 mt-2">
                {appendTime(item?.appt_start_time, language)} -{' '}
                {appendTime(item.appt_end_time, language)}
              </p>
            </div>
          </div>
          {item?.patient_tele_country_code &&
            <div className="icon-block mb-3">
              <img src={telephone} alt="" />
              <div className="mt-1">
                <span>{translate('users.phone', 'Phone Number')}</span>
                <p className="mb-0 mt-2">+{item?.patient_tele_country_code}{item.patient_phone}</p>
              </div>
            </div>
          }
          </div>

      
        </div>
        <div className="popover-footer">
          <div className="icon-block">
          <img src={listIcon} alt="" />
            <div>
              <span>
                {translate('patientView.reason', 'Reason For Appointment')}
              </span>
              <p className="mb-0 mt-2 first-capital">{item?.reason}</p>
            </div>
          </div>
          {(item.live_status === APPOINTMENT_STATUS.cancelled || item.live_status === APPOINTMENT_STATUS.notAttempted) && item?.payment_details?.payment_status !== 'paid' ? null : 
            <div className="popover-footer">
              <div className="icon-block  linked-text d-flex">
                <img className='coin-icon' src={CoinIcon} />
                <div>
                  <p
                    onClick={() => {
                      handlepaymentInfo(item);
                    }}
                    className="cursor-pointer"
                  >
                    {translate('common.paymentInfo', 'Payment Information')}
                  </p>
                </div>
              </div>
            </div>
          }
            {item.live_status === 'upcoming' && onCancelClick && (
            <div className="mt-3 d-flex align-items-center gap-3 popover-footer v2">
              <button className="selector" onClick={() => onCancelClick(item)}>
                {translate('common.cancelAppointment', 'Cancel Appointment')}
              </button>
            </div>
            )}
        </div>
      </div>
      <div className="overlay"></div>
      </div>
    );
  };

  return (
    <div className="dayview-admin">
      <Calendar
        enableAutoScroll={false}
        toolbar={false}
        localizer={localizer}
        events={isloader || apptisLoader ? [] : events}
        startAccessor={'start'}
        endAccessor="end"
        style={{ height: 658 }}
        view="day"
        timeslots={2} //1 means 30 minutes
        dayLayoutAlgorithm={'no-overlap'} // this is for no overlapping
        components={{ event: EventComponent }}
        tooltipAccessor={()=>null}
       // min={events?.length ? new Date(events[0].dayStartTime).setMinutes(0) : timeZoneDate().setHours(0, 0)}
      />
    </div>
  );
};

export default CustomTimeline;
