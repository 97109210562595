import {
  CHANGE_APP_LANGUAGE,
  RECEVIE_USER_PROFILE_DETAILS,
  RECEIVE_APPLICATION_DETAILS,
  SET_SUBSCRIPTION_DATA,
  SHOW_EXPIRED_SUBSCRIPTION,
  CHANGE_LOGO,
  CHANGE_FAVICON,
  SETTING_BTN_LOADING,
  UPDATE_APPLICATION_DETAILS,
  PROFILE_IMG_BTN_LOADING,
  OTP_LOADER
} from '../Actions/settingsAction';
import { LOCALES } from '../Language/Locales';

const initialState = {
  locale: LOCALES.ENGLISH,
  profileDetails: {},
  applicationData: {},
  subscriptionData: {},
  otpDetails: {},
  subscriptionFailed: false,
  logoChange: false,
  faviconChange: false,
  isBtnLoading: false,
  profileImgBtnLoading: false,
  showOtpLoader:false
};

const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_APP_LANGUAGE: {
      return {
        ...state,
        locale: action.payload,
      };
    }
    case RECEVIE_USER_PROFILE_DETAILS: {
      return {
        ...state,
        profileDetails: action.payload,
      };
    }
    case RECEIVE_APPLICATION_DETAILS: {
      return {
        ...state,
        otpDetails: action.payload.otp_settings,
        applicationData: action.payload.client_details,
      };
    }
    case UPDATE_APPLICATION_DETAILS: {
      return {
        ...state,
        applicationData: action.payload,
      };
    }
    case SET_SUBSCRIPTION_DATA: {
      return {
        ...state,
        subscriptionData: {
          ...action.payload,
          setting: {
            ...action?.payload?.setting,
            defaultCountryCode:
              action?.payload?.setting?.defaultCountryCode?.replace('+', ''),
          },
        },
        locale: action.payload?.setting?.defaultLanguage || LOCALES.ENGLISH,
        theme: action.payload?.branding?.themeName,
        // theme:"theme-2"
      };
    }
    case CHANGE_LOGO: {
      return {
        ...state,
        logoChange: !state.logoChange,
      };
    }
    case CHANGE_FAVICON: {
      return {
        ...state,
        faviconChange: !state.faviconChange,
      };
    }
    case SHOW_EXPIRED_SUBSCRIPTION: {
      return {
        ...state,
        subscriptionFailed: true,
      };
    }
    case SETTING_BTN_LOADING: {
      return {
        ...state,
        isBtnLoading: action.payload,
      };
    }
    case PROFILE_IMG_BTN_LOADING: {
      return {
        ...state,
        profileImgBtnLoading: action.payload,
      };
    }
    case OTP_LOADER:{
      return{
        ...state,
        showOtpLoader: action.payload
      }
    }
    default:
      return state;
  }
};

export default SettingsReducer;
