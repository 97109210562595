import React, { useEffect, useRef, useState } from 'react'
import PhoneNumberInput from 'Components/Common/PhoneInput';
import { translate } from 'Language/Translate';
import AntDatepicker from 'Components/Common/AntdDatePicker';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import { BLOOD_GROUP_OPTIONS, RELATIONS, RELATIONS_LABEL } from 'Constants/constant';
import { formatDate, timeZoneDate } from 'Utils/DateFormatter/Date';
import { linkNewProfile, updateLinkedProfile } from 'Actions/patientProfileAction';
import { connect } from 'react-redux';
import moment from 'moment';
import { showToaster, ToasterTypes } from 'Actions/toasterAction';
import ButtonLoader from 'Components/Common/ButtonLoader';
import useNavigationWarning from 'Hooks/useNavigationWarning';
import { validateMobile } from 'Utils/Validation/validator';
import InfoIcon from '../../../Assets/img/icons/info-icon-black.svg';
import { Tooltip } from '@mui/material';

function AddNewMember({
    setAddMemberClick,
    setOpenPatientInfo,
    newMemberDetails,
    setNewMemberDetails,
    handleVerifyOtp,
    addMemberClick,
    addNewProfile,
    otpData,
    setOtpData,
    getLinkedProfileList,
    updateProfile,
    setType,
    defaultCountryCode,
    // otpDetails
    displaytoaster,
    isBtnLoading,
    dataSaved, 
    setDataSaved=()=>{}
}) {
    const numField = useRef()
    const focusRef = useRef()
    const firstField = useRef()
    const genderMaleRef = useRef()
    const genderFemaleRef = useRef()
    const genderOtherRef = useRef()
    const {Prompt,setIsSaved}=useNavigationWarning()
    const [invalidAlert, setInvalidAlert] = useState({});
    const [checkBoxErr, setCheckBoxErr] = useState(false)
    const [newMemberDetailsCopy]=useState({...newMemberDetails})
    const { fName, lName, dob, age, bloodGroup, patientId, relation, gender, phno, isChecked, country } = newMemberDetails
    const { nameErr, dobErr, relationErr, genderErr,mobileValidErr } = invalidAlert
    const [numberLength, setNumberLength] = useState(10);
    const [code,setCode]=useState('IN')

    
    useEffect(() => {
        if(JSON.stringify(newMemberDetails)!==JSON.stringify(newMemberDetailsCopy))
        {
            setIsSaved(false)
            setDataSaved(false)
        }
        else{
            setIsSaved(true)
            setDataSaved(true)
        }
    }, [newMemberDetails,newMemberDetailsCopy])
    useEffect(() => {
        if (!addMemberClick) {
            setInvalidAlert({
                nameErr: false,
                dobErr: false,
                relationErr: false,
                genderErr: false,
            })
        }
        setCheckBoxErr(false)
    }, [addMemberClick])

    // function to save and verify new member details
    const handleSave = () => {
        const { verifyOtp } = JSON.parse(localStorage.getItem("Resend"))
        if (verifyOtp && ((verifyOtp - timeZoneDate().getTime()) / 1000) > 0) {
            displaytoaster('OTP send limit exceeded please try again after 5 min', ToasterTypes.Warning)
        }
        else {
            if (!fName.trim().length ||
                // !relation ||
                !gender || !dob || !isChecked || (phno && country && !validateMobile(phno, code))) {
                setInvalidAlert({
                    ...invalidAlert,
                    nameErr: !fName.trim().length,
                    dobErr: !dob,
                    // relationErr: !relation,
                    genderErr: !gender,
                    mobileValidErr: phno &&  !validateMobile(phno,code) 
                })
                if (!isChecked) {
                    setCheckBoxErr(true);
                }
            } else {
                if (patientId) {
                    setType('edit')
                    let payload = {
                        id: newMemberDetails.linked_user_id,
                        payload: {
                            data: {
                                first_name: newMemberDetails.fName,
                                last_name: newMemberDetails.lName,
                                dob: formatDate(newMemberDetails?.dob),
                                blood_group: newMemberDetails?.blood_group?.value,
                                relation: newMemberDetails?.relation?.value,
                                gender: newMemberDetails?.gender,
                                consent: true,
                                tele_country_code: newMemberDetails?.phno
                                    ? newMemberDetails?.country
                                    : '',
                                phone: newMemberDetails?.country ? newMemberDetails?.phno?.replace(/\s/g, "") : '',
                            }
                        }
                    }
                    updateProfile(payload, onUpdateProfileSuccess)
                } else {
                    let payload = {
                        data: {
                            first_name: fName,
                            last_name: lName,
                            dob: dob,
                            blood_group: bloodGroup?.value,
                            relation: relation?.value,
                            gender: gender,
                            consent: true,
                            tele_country_code: phno ? country : '',
                            phone: phno ? phno.replace(/\s/g, "") : "",
                        }
                    }
                    addNewProfile(payload, (data) => {
                        let { result, response } = data
                        setDataSaved(true)
                        setIsSaved(true)
                        if (response.code === 201) {
                            setNewMemberDetails({
                                ...newMemberDetails,
                                patient_id: result?.patient_data?.patient_id,
                                patientId: result?.patient_data?.patient_id,
                                user_id: result?.patient_data?.user_id,
                                linked_user_id: result?.patient_data?.linked_user_id
                            })
                        }
                        if (response.code === 202) {
                            getLinkedProfileList()
                            handleVerifyOtp()
                            setNewMemberDetails({
                                ...newMemberDetails,
                                patient_id: result?.patient_details?.patient_id,
                                patientId: result?.patient_details?.patient_id,
                                user_id: result?.patient_details?.user_id,
                                linked_user_id: result?.patient_details?.linked_user_id
                            })
                            setOtpData({
                                ...otpData,
                                token: result?.otp_token?.token,
                                exp: result?.otp_token?.exp,
                                exp_time: result?.otp_token?.exp_unix,
                            })
                        }
                        else {
                            getLinkedProfileList()
                            setAddMemberClick(false)
                            setOpenPatientInfo(true)
                        }
                    })
                }
            }
        }
    }
    const onUpdateProfileSuccess = (data) => {
        let { result, response } = data;
        setDataSaved(true)
        setIsSaved(true)
        if (response.code === 202) {
            let phone = `${result.profile_data.tele_country_code}${result.profile_data.phone}`;
            setOtpData({
                user_id: newMemberDetails?.user_id,
                patient_id: newMemberDetails.patient_id,
                phone: result.profile_data.phone,
                tele_country_code: result.profile_data.tele_country_code,
                codeAndphone: phone,
                token: result?.token?.token,
                exp: result?.token?.exp,
                linked_user_id: newMemberDetails?.
                    linked_user_id
            });
            handleVerifyOtp();
        } else {
            getLinkedProfileList();
            setAddMemberClick(false)
            setOpenPatientInfo(true)
        }
    };

    //   handle checkbox
    const handleCheck = () => {
        setNewMemberDetails({
            ...newMemberDetails,
            isChecked: !isChecked
        })
        setCheckBoxErr(false)
    };

    const handleBack = () => {
        let a=true
        if(!dataSaved ){
            a= window.confirm("You have unsaved changes. Are you sure you want to leave this page ? Unsaved changes will be lost.")
        }
        if(a){
            setAddMemberClick(false)
            setOpenPatientInfo(true)
            setNewMemberDetails({...newMemberDetailsCopy})
            setDataSaved(true)
            setIsSaved(true)
            setInvalidAlert({
                nameErr: false,
                dobErr: false,
                relationErr: false,
                genderErr: false,
            })
            setCheckBoxErr(false)
        }
    }

    // handle reset click
    const handleReset = () => {
        setNewMemberDetails({
            fName: '',
            lName: '',
            dob: '',
            age: '',
            bloodGroup: '',
            patientId: '',
            relation: { label: translate('relation.other', 'Other'), value: RELATIONS.other },
            gender: '',
            phno: '',
            country:defaultCountryCode.toString(),
        })
        setInvalidAlert({
            nameErr: false,
            dobErr: false,
            relationErr: false,
            genderErr: false,
        })
        setCheckBoxErr(false)
    }

    // handle input change
    const handleInputChange = (event) => {
        const { value, name } = event.target
        // const regex = /^\+?[0-9 ]*\.?[0-9 ]*$/;
        const regex = /^[0-9]*$/;
        if (name === 'phno') {
            if(!value ||  (!regex.test(value)||validateMobile(value,code))){
                setInvalidAlert({
                    ...invalidAlert,
                    mobileValidErr:false
                })
            }
            if (regex.test(value)) {
                setNewMemberDetails((prev) => ({
                    ...prev,
                    [name]: value
                }))
            }
        } else {
            setNewMemberDetails((prev) => ({
                ...prev,
                [name]: value
            }))
        }
        if (name == 'fName') {
            setInvalidAlert({
                ...invalidAlert,
                nameErr: false
            })
        }
        if (name == 'dob') {
            setInvalidAlert({
                ...invalidAlert,
                dobErr: false
            })
        }
    }

    // handle bloodgroup change
    const handleChangeOption = (option) => {
        setNewMemberDetails({
            ...newMemberDetails,
            bloodGroup: option
        })
    }
    // handle country code change
    const handleMobileNumberChange = (phone) => {
        setNewMemberDetails({
            ...newMemberDetails,
            country: phone
        })
    }
    //   handle gender change

    const genderHandle = (e) => {
        setNewMemberDetails({
            ...newMemberDetails,
            gender: e
        })
        setInvalidAlert({
            ...invalidAlert,
            genderErr: false
        });
    }

    // handle relation change
    const handleChangeRelation = (option) => {
        setNewMemberDetails({
            ...newMemberDetails,
            relation: option
        })
        setInvalidAlert({
            ...invalidAlert,
            relationErr: false
        });
    }

    const getAge = (birthDate) =>
        Math.floor((timeZoneDate() - new Date(birthDate).getTime()) / 3.15576e10);

    // handle dob change
    const handleDobChange = (date) => {
        setNewMemberDetails({
            ...newMemberDetails,
            dob: formatDate(date),
            age: `${getAge(formatDate(date))}`

        });
        setInvalidAlert({
            ...invalidAlert,
            dobErr: false
        });
    };

    // disable futeure dates for ant datepicker
    const disableFutureDates = (current) => {
        return current && current > moment();
    };

    return (
        <>
            {Prompt}
            <div className=''>
                <div className="sub-title mb-4 mr-1">
                    <button className="btn-transparent" data-bs-dismiss="modal"
                        data-bs-target="#patientInfoModal"><i className="bi bi-chevron-left" onClick={handleBack}></i></button>
                    <span> {translate('patientPortal.addNewMember', 'Add New Member')} </span>
                </div>

                <div className="set-1">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group mb-4">
                                <label htmlFor="fName" className="mandatory">{translate('common.firstName', 'First Name')}</label>
                                <input maxLength="40" id="fName" name='fName' type="text" value={fName} placeholder="i.e, John" className={`input w-100 ${nameErr ? 'input-err' : ''}`} onChange={handleInputChange} ref={firstField} 
                                onKeyDown={(e)=>{
                                    if(e.key === "Tab" && e.shiftKey) e.preventDefault()
                                }}
                                />
                                <span className={`${nameErr ? 'err' : 'd-none'}`}>
                                    {translate('common.required', 'This field is required')}
                                </span>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-4">
                                <label htmlFor="lName">{translate('common.lastName', 'Last Name')}</label>
                                <input maxLength="40" id="lName" name='lName' placeholder="i.e, Doe" type="text" className="input w-100" value={lName} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-4">
                                <label htmlFor="date" className="mandatory">{translate('patientPortal.dob', 'Date of Birth')}</label>

                                <div className="icon-field date">
                                    <AntDatepicker onInputChange={handleDobChange} className={`${dobErr ? 'input-err' : ''}`} value={dob} placeholder="DD/MM/YYYY"
                                        disabledDate={disableFutureDates}

                                    />
                                </div>
                                <span className={`${dobErr ? 'err' : 'd-none'}`}>
                                    {translate('common.required', 'This field is required')}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group mb-4">
                                        <label htmlFor="age">{translate('patientPortal.ageinYears', 'Age (Years)')}</label>
                                        <input disabled id="age" name='age' value={age} onChange={handleInputChange} placeholder="0" type="text" className="input w-100" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group mb-4">
                                        <label htmlFor="bloodGroup">{translate('common.bloodGroup', 'Blood Group')}</label>
                                        <div className="select">
                                            <ReactSelectDropDown
                                                options={BLOOD_GROUP_OPTIONS}
                                                value={bloodGroup}
                                                onInputChange={handleChangeOption}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="set-2">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group mb-4">
                                <label htmlFor="id">{translate('patientPortal.patient_id', 'Patient ID')}</label>
                                <input disabled id="id" type="text" placeholder="i.e, TC0001" value={patientId} className="input w-100" />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group mb-4"
                            onKeyDown={(e)=>{
                                if(e.key === "Tab" && !e.shiftKey) {
                                    e.preventDefault()
                                    genderMaleRef?.current?.focus()
                                }
                            }}
                            >
                                <label htmlFor="relation">{translate('patientPortal.relation', 'Relation')}</label>
                                <ReactSelectDropDown
                                    label="Select"
                                    value={relation}
                                    customClass={`${relationErr ? 'custom-dr-err' : ''}`}
                                    options={RELATIONS_LABEL}
                                    onInputChange={handleChangeRelation}
                                />
                                <span className={`${relationErr ? 'err' : 'd-none'}`}>
                                    {translate('common.required', 'This field is required')}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-4">
                                <span className="label mandatory" >{translate('patientPortal.gender', 'Gender')}</span>
                                <div>
                                    <div className={`btn-group ${genderErr ? 'radio-group-err' : ''}`} role="group" aria-label="Basic radio toggle button group">

                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1"
                                        ref={genderMaleRef}
                                        tabIndex='0'
                                        onKeyDown={(e)=>{
                                            if(e.key === "Tab" && !e.shiftKey){
                                                e.preventDefault();
                                                genderFemaleRef?.current?.focus();
                                            }
                                            else if (e.key=== "Enter" && !e.shiftKey){
                                                e.preventDefault();
                                                genderHandle('male');

                                            }
                                        }}
                                        autoComplete="off" checked={gender == 'male'} />
                                        <label className="btn btn-black" htmlFor="btnradio1"
                                            onClick={() => genderHandle('male')}
                                        >{translate('common.male', 'Male')}</label>

                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2"
                                        autoComplete="off"
                                        checked={gender == 'female'}
                                        ref={genderFemaleRef}
                                        onKeyDown={(e)=>{
                                            if(e.key === "Tab" && !e.shiftKey){
                                                e.preventDefault();
                                                genderOtherRef?.current?.focus();
                                            }
                                            else if(e.key === "Tab" && e.shiftKey){
                                                e.preventDefault();
                                                genderMaleRef?.current?.focus();
                                            }
                                            else if(e.key === "Enter" && !e.shiftKey){
                                                e.preventDefault();
                                                genderHandle('female');
                                                }
                                            }}
                                        tabIndex='0'
                                        />
                                        <label className="btn btn-black" htmlFor="btnradio2"
                                            onClick={() => genderHandle('female')}
                                        >{translate('common.female', 'Female')}</label>

                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio3"
                                        autoComplete="off"
                                        tabIndex='0'
                                        ref={genderOtherRef}
                                        onKeyDown={(e)=>{
                                            if(e.key === "Tab" && e.shiftKey){
                                                e.preventDefault();
                                                genderFemaleRef?.current?.focus();
                                            }
                                            else if(e.key === "Enter" && !e.shiftKey){
                                                e.preventDefault();
                                                genderHandle('other');
                                            }
                                        }}
                                        checked={gender == 'other'} />
                                        <label className="btn btn-black" 
                                        htmlFor="btnradio3" onClick={() => genderHandle('other')}>{translate('common.other', 'Other')}</label>
                                    </div>
                                </div>
                                <span className={`${genderErr ? 'err' : 'd-none'}`}>
                                    {translate('common.genderRequired', 'Please select gender')}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-4">
                                <label htmlFor="phno" className="lable">{translate('patientPortal.mobileNo', 'Mobile Number')}  {'('}{translate('common.optional', 'Optional')}{')'}
                                {" "}
                                <Tooltip
                        placement="top"
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                        title={translate(
                          "patients.linkedNumber",
                          "If the Linked patient's mobile number is not provided then the main account holder's mobile number will be considered."
                        )}
                      >
                        <img src={InfoIcon} />
                      </Tooltip>
                                </label>
                                <div className="phone-field ">
                                    <div className="dropdown country-code-picker"
                                     onKeyDown={(e) => {
                                        if (e.key === "Tab" && !e.shiftKey)
                                        {
                                          e.preventDefault()
                                          numField.current.focus()
                                        } 
                                        else if(e.key === "Tab" && e.shiftKey){
                                            e.preventDefault()
                                            genderOtherRef?.current?.focus()
                                        }
                                      }}
                                    >
                                        <PhoneNumberInput value={country}
                                            onChange={handleMobileNumberChange}
                                            numberLength={numberLength}
                                             setNumberLength={setNumberLength}
                                             focusRef={focusRef}
                                             code={code}
                                             setCode={setCode}
                                        />
                                    </div>
                                    <div>
                                    <input
                                        type="tel"
                                        id="phno"
                                        placeholder="i.e, 98950 98950"
                                        name="phno"
                                        value={phno}
                                        ref={numField}
                                        onChange={handleInputChange}
                                        className={`form-control grey input ${mobileValidErr && 'input-err'}`}
                                        maxLength={numberLength}
                                        onKeyDown={(e)=>{
                                            if(e.shiftKey && e.key === "Tab"){
                                              e.preventDefault()
                                              focusRef.current.focus()
                                            }
                                          }}
                                    />
                                    <span className={`${mobileValidErr ? 'err' : 'd-none'}`}>
                                    {translate(
                                        'common.invalidPhone',
                                        'Invalid mobile number.'
                                    )}
                                </span>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3 mb-4">
                            <div className="d-flex align-items-center gap-3 form-group">
                                <input className={`form-check-input mt-0 ${checkBoxErr ? 'cbox-err' : ''}`} type="checkbox" id="consent" checked={isChecked} onClick={handleCheck}
                                onKeyDown={(e)=>{
                                    if(e.key === "Enter" && !e.shiftKey){
                                        e.preventDefault()
                                        handleCheck()
                                    }
                                }}
                                />
                                <label htmlFor="consent" className="pb-0 mandatory">{translate(
                                    'patientPortal.consentAgree',
                                    'I have consent to book on behalf of this patient.'
                                )}
                                </label>

                            </div>
                            <span className={`${checkBoxErr ? 'err' : 'd-none'}`}>
                                {translate(
                                    'checkboxReq.required',
                                    'Please allow consent before proceeding'
                                )}
                            </span>
                        </div>

                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end gap-3 btns footer-btns">
                    <button id="reset" className="btn btn-outlined-dark" onClick={handleReset}>{translate('patientPortal.reset', 'Reset')}</button>
                    <button id="save" className="btn btn-primary loader-btn" data-bs-dismiss="modal"
                        data-bs-target="#enterOtpModalOthers" disabled={isBtnLoading} onClick={handleSave}
                        onKeyDown={(e)=>{
                            if(e.key === "Tab" && !e.shiftKey){
                              e.preventDefault()
                              firstField.current.focus()
                            }
                          }}
                        >
                        {isBtnLoading ? <ButtonLoader text={translate('common.saveVerify', 'Save & Verify')} />
                            : translate('common.saveVerify', 'Save & Verify')
                        }
                    </button>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    otpDetails: state.SettingsReducer.otpDetails,
    isBtnLoading: state.ProfileReducer.isBtnLoading
});

const mapDispatchToProps = (dispatch) => ({
    addNewProfile: (data, callback) => dispatch(linkNewProfile(data, callback)),
    updateProfile: (data, callBack, errCallBack) =>
        dispatch(updateLinkedProfile(data, callBack, errCallBack)),
    displaytoaster: (message, type) => dispatch(showToaster(message, type))

});
export default connect(mapStateToProps, mapDispatchToProps)(AddNewMember)