import store from 'store';
import { showSubscriptionExpired } from 'Actions/settingsAction';
import { translate } from 'Language/Translate';
import keycloak from 'Utils/keyCloak/keyCloak';
import { API_STATUS } from '../../Constants/constant';
import {
  clearLocalStorage,
  getLocalStorageItem,
  setLocalStorageItem,
} from '../LocalStorage/local-storage';
import { apiInstance } from './ApiInstance';
import { isUnauthorizedAction } from 'Utils/Permissions/Permissions';

const instance = apiInstance();
const baseURL = '/api/v1';

// Refresh token update with three retries
const updateTokenWithRetries = (retries = 3) => {
  return new Promise((resolve, reject) => {
    const attemptUpdateToken = (attemptsLeft) => {
      keycloak.updateToken(120).then(()=> {
        // success
        resolve()
      }).catch((err) => {
        if (attemptsLeft > 1) attemptUpdateToken(attemptsLeft - 1);
        else reject(err)
      })
    }
    attemptUpdateToken(retries)
  })
}

// API interceptor to update the token 
instance.interceptors.request.use(async (config) => {
  if (
    keycloak &&
    keycloak.authenticated &&
    keycloak.token &&
    keycloak.refreshToken
  ) {
      const oldToken = keycloak.token;
      await updateTokenWithRetries();
      const newToken = keycloak.token;
      if (newToken !== oldToken) {
        setLocalStorageItem('access_token', keycloak.token);
        setLocalStorageItem('refresh_token', keycloak.refreshToken);
        setLocalStorageItem(
          'refresh_token_exp',
          keycloak.refreshTokenParsed.exp
        );
        updateToken(keycloak.token);
        config.headers.Authorization = keycloak.token;
      }
  }
  return config;
});

export const updateToken = (token) => {
  return new Promise((resolve) => {
    instance.defaults.headers.Authorization = token || '';
    resolve('success');
  });
};

export const updateRole = (role) => {
  instance.defaults.headers.user_role = role || '';
};

const loggoutUser = () => {
  const pathname = window.location.hash;
  const portalType = getLocalStorageItem('portalType');
  clearLocalStorage();
  keycloak.logout();
  updateToken('');
  setLocalStorageItem('portalType', portalType);
  if (pathname.toLocaleLowerCase().includes('#/webconnect'))
    window.location.href = '#/webconnect';
  else window.location.href = '#/admin/auth/login';
};


// custom fetch function to make common API calling point
const fetch = async ({ url, data, method, params }) => {
  let error = false;
  let resultData = null;
  let displayMessage = '';
  let statusCode;

  // FIX-ME remove when headers is allowed
  params
    ? (params.ur = instance.defaults.headers.user_role)
    : (params = { ur: instance.defaults.headers.user_role });

  await instance
    .request({
      url: `${baseURL}${url}`,
      method,
      data,
      params,
    })
    .then((response) => {
      const { data, status: code } = response;
      const { data: result, status, message } = data;
      if (status === API_STATUS.success) {
        resultData = result;
        displayMessage = message;
        statusCode = code;
      } else {
        error = true;
        displayMessage = message;
      }
    })
    .catch((e) => {
      error = true;
      const responseData = e.response || {};
      const { data, status: code } = responseData || {};
      let { additional_message, data : additionalData = {}, code: errorCode } = data || {};

      if (code === 401) {
        // Unauthorized
        if (isUnauthorizedAction(errorCode)) {
          additional_message = translate('common.noActionPermission', 'You do not have permission to perform this action')
        }
        // Unauthenticated
        else {
          loggoutUser();
        }
      } else if (code === 402) {
        // When the subscription expired in between using the application
        store.dispatch(showSubscriptionExpired());
      } else if (code === 423) {
        // when user is inactivated. Delayed for 4 sec to show the toaster and logout the user
        setTimeout(() => {
          loggoutUser();
        }, 4000);
      }
      statusCode = code;
      resultData = additionalData
      displayMessage =
        additional_message ||
        translate(
          'common.wentWrong',
          'There was an error processing your request. Please try again'
        );
    });

  // custom response message
  return {
    result: resultData, // API response data
    message: displayMessage, // Toaster message
    isError: error, // Has error occured flag true/false
    code: statusCode, // API status code
  };
};

// get method
const get = ({ url, data }) => {
  return fetch({ url, method: 'get', params: data });
};

// post method
const post = async ({ url, data, params = {} }) => {
  return fetch({ url, method: 'post', data , params});
};

// put method
const put = async ({ url, data }) => {
  return fetch({ url, method: 'put', data });
};

// patch method
const patch = async ({ url, data }) => {
  return fetch({ url, method: 'patch', data });
};

// delete method
const destroy = async ({ url, data }) => {
  return fetch({ url, method: 'delete', data });
};

const network = { get, post, put, patch, delete: destroy };

export default network;
