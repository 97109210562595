import React from 'react';
import CalendarComponent from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { timeZoneDate } from 'Utils/DateFormatter/Date';
export default function Calendar({
  value = timeZoneDate(),
  onDateChange = () => {},
  activeDate,
  setActiveDate,
  language="EN-en"
}) {
  const onChange = (e) => {
    onDateChange(e);
  };
  const onActiveStartDateChange = ({ activeStartDate }) => {
    setActiveDate(activeStartDate);
    // onDateChange(activeStartDate)
  };
  const formatShortWeekday = (locale, date) => {
    const options = { weekday: 'short' };
    return new Intl.DateTimeFormat(locale, options).format(date)[0];
  };
  return (
    <div>
      <CalendarComponent
        locale={language}
        calendarType="US"
        formatShortWeekday={formatShortWeekday}
        onChange={(e) => {
          onChange(e);
        }}
        value={value}
        activeStartDate={activeDate}
        onActiveStartDateChange={onActiveStartDateChange}
      />
    </div>
  );
}
