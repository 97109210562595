import React from 'react';
import NoResultFound from '../Common/NoResultFound';

function Observations() {
  return (
    <>
      <NoResultFound />
    </>
  );
}

export default Observations;
