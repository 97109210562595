import { LOCALES } from '../Locales';

const message = {
  [LOCALES.FRENCH]: {
    common: {
      yes: 'OUI',
      no: 'NON',
      logout: 'Êtes-vous sûr de vouloir vous déconnecter ?',
      edit: 'MODIFIER',
      update: 'METTRE À JOUR',
      status: 'Statut',
      apply: 'APPLIQUER',
      add: 'AJOUTER',
      save: 'SAUVEGARDER',
      reset: 'RÉINITIALISER',
      cancel: 'ANNULER',
      submit: 'SOUMETTRE',
      confirm: 'CONFIRMER',
      action: 'Action',
      back: 'DOS',
      itemPerPage: 'Éléments par page',
      active: 'Actif',
      inactive: 'Inactif',
      doYouWantToDelete: 'Voulez-vous supprimer ?',
      doYouWantToDeleteDepartment:
        'Etes-vous sûr de vouloir supprimer ce département ?',
      doYouWantToDeleteUser:
        'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
      doYouWantToDeleteLeave: 'Souhaitez-vous supprimer ce congé ?',
      doYouWantToDeleteDoctor:
        'Êtes-vous sûr de vouloir supprimer ce médecin ?',
      doYouWantToDeleteAppointment:
        'Êtes-vous sûr de vouloir supprimer ce rendez-vous ?',
      slno: 'Nombre',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      phoneNumber: 'Numéro de téléphone',
      emailId: 'Adresse e-mail',
      strong: 'Fort',
      medium: 'Moyen',
      weak: 'Faible',
      reason: 'Raison',
      noData: 'Aucune donnée trouvée',
      pageNotFound: 'Page non trouvée',
      somethingWrong: "Quelque chose s'est mal passé",
      unableToLoad:
        'Une erreur s’est produite, impossible de charger les paramètres d’abonnement',
      noPermission: "Vous n'êtes pas autorisé à afficher cette page",
      unauthorised:
        'Nous ne parvenons pas à trouver le sous-domaine demandé. Veuillez vérifier le sous-domaine et réessayer.',
      tryAgain: 'Impossible de traiter la demande. Veuillez réessayer',
      requiredFields: 'Veuillez remplir tous les champs obligatoires',
      required: 'Ce champ est obligatoire',
      reqField:'Ces champs sont obligatoires',
      requiredValid:'Veuillez saisir un montant valide',
      nameError:
        'Veuillez sélectionner dans la liste ou ajouter un membre de la famille !',
      checkboxReq: 'La sélection d’une case à cocher est requise.',
      from: 'De',
      to: 'À',
      time: 'Heure',
      monday: 'Lundi',
      tuesday: 'Mardi',
      wednesday: 'Mercredi',
      thursday: 'Jeudi',
      friday: 'Vendredi',
      saturday: 'Samedi',
      sunday: 'Dimanche',
      male: 'Homme',
      female: 'Femme',
      select: 'Sélectionner',
      bloodGroup: 'Groupe sanguin',
      signOut: 'Se déconnecter',
      other: 'Autre',
      date: 'Date',
      name: 'nom',
      delete: 'Supprimer',
      print: 'Imprimer',
      saveVerify: 'Enregistrer et vérifier',
      loading: 'chargement...',
      success: 'Succès',
      error: 'Erreur !',
      warning: 'Attention !',
      info: 'Information',
      invalidEmail: 'Format d’identification de l’e-mail non valide',
      invalidPhone: 'Numéro de mobile non valide',
      all: 'Toute',
      invalidCaptcha: 'Captcha invalide!',
      guest: 'Utilisateur invité',
      wentWrong:
        'Il y a eu une erreur lors du traitement de votre demande. Veuillez réessayer',
      hi: 'Salut',
      noResultFound: 'Aucun résultat n’a été trouvé.',
      more: 'plus',
      of: 'de',
      noOption: 'Aucune option',
      noSessionOpted: "Sélectionnez d'abord n'importe quelle session pour les plages horaires disponibles",
      paymentInfo: 'Informations de paiement',
      transactionMethod: 'Méthode de transaction',
      amount: 'Quantité',
      refId: 'ID de référence',
      additionalInfo: 'Infos complémentaires',
      searchpatient: 'Rechercher un patient',
      go: 'Aller',
      day: 'Jour',
      week: 'Semaine',
      today: 'Aujourd’hui',
      start: 'Commencer',
      cancelAppointment: 'Annuler rendez-vous',
      expiryNotification:
        'La abonnement pour {appName} expire dans {date} jour(s). Si vous souhaitez renouveler votre abonnement, il suffit',
      expiryNotificationToday:
        'La abonnement de {appName} expire aujourd hui. Si vous souhaitez renouveler votre abonnement, il suffit',
      clickLink: 'cliquez sur ce lien',
      otpLimitMsg:
        'La limite d’envoi OTP est dépassée, veuillez réessayer après 5 minutes',
      itsYou: `C'est toi`,
      verifyOtp: 'Vérifier OTP',
      resetData: 'Réinitialiser',
      optional: 'Facultative',
      crop: "Recadrer l'image",
      saveChanges: 'Sauvegarder les modifications',
      search: 'Rechercher',
      enterPassword: 'Entrer le mot de passe',
      reject: 'REJETER',
      searchDoctors:'Rechercher des médecins',
      addNew: 'Ajouter un nouveau',
      addDepartment: 'Ajouter un département',
      noResult: 'Aucun résultat trouvé.',
      noResultAppointments: 'Aucun rendez-vous ajouté.',
      noAppointments: 'Pas de rendez-vous!',
      noAppointmentsAdded:"Il semble que vous n'ayez pas encore ajouté de rendez-vous.",
      noAppointmentsbooked:"Il semblerait que vous n'ayez pas encore de rendez-vous réservé.",
      noResultMsg: 'Aucun résultat ne correspond aux critères de filtre Supprimez le filtre ou effacez tous les filtres pour afficher les résultats',
      noDocs: 'Voulez-vous prendre rendez-vous pour la prochaine date disponible ?',
      clicknow: 'Cliquez maintenant',
      landline: 'Numéro de téléphone fixe',
      notAdd:'Non ajouté',
      noActionPermission: "Vous n'êtes pas autorisé à effectuer cette action",
    },
    reset: {
      title: 'réinitialiser le mot de passe',
      resetTooltip: 'Réinitialiser',
    },
    signup: {
      title: "S'inscrire",
      selectDomain: 'Sélectionnez votre domaine de télémédecine',
      clientcodeError: 'Veuillez entrer un code client valide',
      criteria:
        "Le code client ne doit contenir que des lettres et des chiffres, avec au plus un trait d'union qui ne se trouve ni au début ni à la fin.",
    },
    login: {
      title: "Portail d'administration client",
      subTitle: "Connectez-vous avec vos informations d'identification",
      username: "Nom d'utilisateur",
      email: 'Courriel',
      password: 'Mot de passe',
      rememberMe: 'Se souvenir de moi',
      forgotPassword: 'Mot de passe oublié ?',
      login: 'CONNEXION',
      usernameRequired: "Le nom d'utilisateur est requis",
      passwordRequired: 'Le mot de passe est requis',
      emailRequired: 'L’identifiant de messagerie est requis',
    },
    appointments: {
      doYouWantToCancel: 'Êtes-vous sûr de vouloir annuler le rendez-vous ?',
      title: 'Rendez-vous',
      availability: 'Disponibilité',
      addAppointment: 'Ajouter un rendez-vous',
      editAppointment: 'Modifier le rendez-vous',
      deleteAppointment: 'Supprimer le rendez-vous',
      startAppt: 'Commencer le rendez-vous',
      notifyAndSave: 'NOTIFIER LE PATIENT ET ENREGISTRER',
      selectDate: 'Sélectionner une date',
      slNo: 'Sl No',
      id: 'ID',
      patientName: 'Nom du patient',
      doctorName: 'Nom du médecin',
      date: 'Date',
      timeSlot: 'Plage horaire',
      type: 'Type',
      department: 'Département',
      appointmentDate: 'Date du rendez-vous',
      session: 'Séance',
      email: 'E-mail',
      mobile: 'Mobile',
      reason: 'Motif',
      appointmentSuccess: 'Votre rendez-vous a été pris avec succès',
      bookAnother: 'Prendre un autre rendez-vous',
      appointmentId: 'ID du rendez-vous',
      bookedSlot: 'Créneau réservé',
      makeAppointment: 'Prendre un rendez-vous',
      searchBy: 'Département / médecin',
      deptDoct: 'Département/Médecin',
      selectAppointmentDate: 'Sélectionner la date du rendez-vous',
      fees: 'Frais',
      viewProfile: 'Voir le profil',
      bookSlot: 'Réserver des créneaux',
      notAvailable: 'Non disponible',
      availableSlots: 'Créneaux disponibles',
      timing: 'Horaire',
      reserveSlot: 'Réserver un créneau',
      patientInfo: 'Ajouter les informations du patient',
      existingPatient: 'Patient existant',
      newPatient: 'Nouveau patient',
      selectMobileNumber: 'Sélectionner un numéro de mobile',
      enterMobileNumber: 'Saisir un numéro de mobile',
      validate: 'Valider',
      enterOTP: 'Saisir le code OTP',
      didntReceive: "Vous n'avez pas reçu le code OTP ?",
      resend: 'Renvoyer le code OTP',
      patientMRN: 'MRN du patient',
      MRN: 'MRN',
      nextStep: 'AJOUTER DES INFORMATIONS SUR LE PATIENT',
      next: 'SUIVANT',
      enterPatientName: 'Veuillez saisir le nom du patient',
      enterValidEmail: 'Veuillez saisir une adresse e-mail valide',
      selectExistingUser:
        'Veuillez sélectionner un numéro existant dans la liste',
      enterMobile: 'Veuillez saisir le numéro de mobile',
      enterValidMobile: 'Veuillez saisir un numéro de mobile valide',
      enterOTPToProceed: 'Saisissez le code OTP pour continuer',
      validateMobile: 'Veuillez valider le numéro de mobile',
      inProgress: 'En cours',
      all: 'tout',
      upcoming: 'À venir',
      rescheduled: 'Reprogrammé',
      completed: 'Complété',
      notAttempted: 'Pas tenté',
      previous: 'Précédente',
      cancelled: 'Annulé',
      waiting: 'En attendant',
      doctDept: 'Médecin / Département',
      searchDoctDept: 'Recherche Docteure/Dept.',
      sec: 'seconde',
      cancelAppointment: 'Annuler rendez-vous',
      enterReason: 'Veuillez fournir la raison',
      rescDetails: 'Détails reprogrammés',
      totalResc: 'Total des reprogrammations',
      searchMobile: 'Recherche par numéro de portable',
      dob: 'Date de naissance',
      gender: 'Genre',
      noAvailableSlots: 'Aucun emplacement disponible',
      mobileNumberNoFound: 'Numéro de portable introuvable',
      continueGuest: 'Continuer en tant qu’invité',
      goNext: 'Allez à la semaine prochaine',
      goPrevious: 'Aller à la semaine précédente',
      goNextDay: 'Aller au lendemain',
      goPreviousDay: 'Aller à la journée précédente',
      noSlots: 'Aucun emplacement disponible',
      leaveDateConflict: 'Date(s) de congé en conflit',
      dateSelected: 'Date de sélection',
      rescheduleTo: 'Reprogrammer à',
      slotDetails: 'Détails des emplacements',
      bookedFor: 'Réservé pour',
      bookedWith: 'Réservé avec',
      leaveDates: 'Date(s) de congé',
      rescheduleAppts: 'Reprogrammer les rendez-vous',
      cancelAppts: 'Annuler un ou plusieurs rendez-vous',
      notify: 'Notifier',
      previousAppointment: 'Rendez-vous précédent en cours',
      areYouWantTo:
        'Vous avez actuellement un rendez-vous en cours. Êtes-vous sûr de vouloir prendre un nouveau rendez-vous ?',
      completePrevious:
        'Le rendez-vous précédent sera automatiquement marqué comme terminé.',
      start: 'Commencer le rendez-vous',
      cash: 'argent',
      upi: 'UPI',
      card: 'Carte',
      netbanking: 'Services bancaires nets',
      viewAppt: 'Voir le rendez-vous',
      tableView: 'Vue de tableau',
      calenderView: 'Vue du calendrier',
      optime: 'OP Heure',
      view: 'Vue',
      notifyMsg:
        'Etes-vous sûr de vouloir informer les patients sélectionnés de reprogrammer leurs rendez-vous en raison du congé des médecins ?',
      notifyPatients: 'Informer les patients',
      sendNotification: 'Envoyer une notification',
      cancelMsg:
        'Êtes-vous sûr de vouloir annuler les rendez-vous sélectionnés ?',
      paymentpaid: 'Payé',
      notifyReschedule: 'Informer le patient et reprogrammer',
      manageSlots: 'Gérer les machines à sous',
      patientType: 'Sélectionnez le type de patient',
      apptId: 'Numéro de rendez-vous',
      inactiveusererror:'Cet utilisateur est inactif, veuillez activer l’utilisateur et procéder à la prise de rendez-vous',
      doctors: 'Médecins)',
      departments: 'Départements)',
      deptSelected: 'Département sélectionné',
      docSelected: 'Médecin sélectionné',
      apntDateConflicted: 'Date(s) de rendez-vous en conflit',
      currentAppointments: 'Nominations actuelles',
      noDataAppointments:'Aucun rendez-vous ajouté',
      noDataAppointmentsMsg: `Il semble que vous n'ayez pas encore ajouté de rendez-vous.`,
      booked: 'Réservée',
      yourSelection: 'Votre sélection',
      available:'disponible'
    },
    patientView: {
      searchPatient: 'Rechercher un patient',
      reason: 'Raison de la consultation',
      upcoming: 'À venir',
      previous: 'Précédent',
      total: 'Total des rendez-vous',
      showMore: 'Afficher plus',
      prescriptionNote: 'Note de prescription',
      save: 'Enregistrer et terminer le rendez-vous',
      view: 'Voir',
      download: 'Télécharger',
      latest: 'Derniers documents',
      medicine: 'Médicament',
      medicineName: 'Nom du médicament',
      dosage: 'Dosage',
      duration: 'Durée',
      addMore: 'Ajouter plus',
      advices: 'Conseils',
      note: 'Remarque',
      patientId: 'Identifiant du patient',
      phone: 'Téléphone',
      date: 'Date',
      apptDetails: 'Détails du rendez-vous',
      apptDate: 'Date du rendez-vous',
      timeSlot: 'Créneau horaire',
      department: 'Service',
      vitals: 'Constantes vitales',
      weight: 'Poids',
      height: 'Taille',
      heartBeat: 'Pouls',
      bp: 'Tension artérielle',
      glucose: 'Taux de glucose',
      noAppt: 'Aucun rendez-vous',
      apptReason: 'Raison du rendez-vous',
      prescriptions: 'Ordonnances',
      noData: 'Aucune donnée',
      referal:
        'Utilisez # pour choisir un service lors de la référence, puis @ pour y nommer un médecin.',
      followUp: 'Planifier un rendez-vous de suivi',
      required:
        "Veuillez compléter la note d'ordonnance en remplissant l'un des champs",
      medRequired: "Veuillez ajouter le nom d'un médicament à toutes les ordonnances ajoutées.",
    },
    cancelAppt: {
      cancelappt: 'Annuler le rendez-vous',
      areYouSure: 'Êtes-vous sûr, vous voulez annuler ce rendez-vous ?',
      pleaseEnter:
        'Si c’est le cas, veuillez indiquer la raison de l’annulation de votre rendez-vous.',
      reason: 'Raison de l’annulation',
      yesCancelAppt: 'Oui, annuler mon rendez-vous',
      noCancelAppt: 'Non, Retour à la liste',
      cancelSuccessfully: 'Rendez-vous annulé avec succès !',
      cancelSuccessfullyMsg: 'Vous avez annulé votre rendez-vous avec succès.',
      backToAppt: 'Retour à la liste',
      apptwithbookid:'Le rendez-vous avec le numéro de réservation',
      bookforDr:'réservé pour Dr.',
      cancelon:'le',
      cancelat:' à',
      cancelsuccessfully:'a été annulé avec succès.',
    },
    doctors: {
      title: 'Médecins',
      slots: 'Machines à sous',
      selectDepartment: 'Sélectionnez un département',
      viewProfile: 'Voir le profil',
      myProfile: 'Mes profil',
      doctorListing: 'Liste des médecins',
      doctorDetails: 'Détails du médecin',
      addDoctor: 'Ajouter un médecin',
      editDoctor: 'Modifier le médecin',
      doctorName: 'Nom du médecin',
      department: 'Département',
      appointmenttype: 'Type de rendez-vous',
      apptType: 'Appt. Taper',
      designation: 'Désignation',
      emailaddress: 'Adresse e-mail',
      about: 'À propos',
      qualification: 'Qualification',
      opconsultation: 'Consultation Externe',
      consultingfees: 'Frais de consultation',
      fees: 'Frais',
      personalinformation: 'Informations personnelles',
      gender: 'Sexe',
      qualificationdetails: 'Détails Des Qualifications',
      slotduration: 'Durée Du Créneau',
      minutes: 'Minutes',
      mins: 'Mins',
      minutedescription: 'Ou entrez une durée personnalisée',
      rupees: 'Euro',
      op: 'Ambulatoire',
      monday: 'Lun',
      tuesday: 'Mar',
      wednesday: 'Mercre',
      thursday: 'Jeu',
      friday: 'Ven',
      saturday: 'Same',
      sunday: 'Dim',
      doc: 'médecin',
      experience: 'Expérience (Année)',
      removeOverride: "Supprimer l'horaire de remplacement",
      addOverride: 'Ajouter un horaire de remplacement',
      override: "Remplacer l'horaire",
      close: 'Fermer',
      expand: 'Développer',
      slotError: 'Veuillez saisir les détails du créneau',
      profile: 'Profil du médecin',
      qualify: 'Qualifications',
      connect: 'Connectons-nous',
      editProfile: 'EDITER LE PROFIL',
      feeHistory: 'Historique des frais',
      bufferTime: 'Temps tampon',
      bufferInfo:
        "L'intervalle de temps entre des plages de rendez-vous consécutives permettant des pauses ou des retards imprévus.",
      maxApptinfo:
        'Le nombre maximum de rendez-vous pouvant être programmés dans une journée.',
      maxAppoinments: 'Max. Nombre de rendez-vous',
      default: 'défaut',
      overrideSlots: 'Remplacer les emplacements',
      noSlotFound: 'Aucun créneau trouvé',
      noSlotFoundMsg:
        'Veuillez sélectionner nimporte quel département/médecin pour afficher les créneaux horaires.',
      slotManagement: 'Gestion des emplacements',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      overrideDetails: 'Détails de remplacement',
      viewDoc: 'Voir le médecin',
      editDoc: 'Modifier Docteur',
      deleteDoc: 'Supprimer le médecin',
      slotManage: 'Gestion des créneaux horaires',
      errMsgTimeRange:
        'Plage horaire non valide, lheure de début doit être antérieure à lheure de fin.',
      errMsgDuratoinTimeRange:
        'La durée du planning ne peut pas être inférieure à la durée du créneau.',
      errMsgdays: 'Veuillez sélectionner au moins un jour',
      scheduleSettings: 'Paramètres de planification',
      errMsgDate: 'La date de début doit être antérieure à la date de fin.',
      doYouWantToDeleteOverride:
        'Voulez-vous supprimer cet horaire de remplacement ?',
      entPersonalInformation: 'Entrez des informations personnelles',
      entAcademicInformation: 'Entrez les informations académiques',
      noQual: 'Aucune qualification ajoutée',
      manage:'Gérer la durée du créneau du médecin, le temps tampon, le nombre de rendez-vous, etc.',
      collapse: 'Effondrement',
      customDuration: 'Durée personnalisée',
      noDataDoctors:'Aucun médecin ajouté',
      noDataDoctorsMsg: `Il semble que vous n'ayez pas encore ajouté de médecins.`,
      noDepartaddedDoctorsMsg:"On dirait que vous n'avez ajouté aucun service au médecin, ",
      noActiveDoctorMessage:'Le médecin ajouté est inactif, veuillez activer le médecin',
      addLandline: 'Ajouter une ligne fixe',
      remove: 'Retirer'
    },
    invoice: {
      invoices: 'Factures',
      filterName: 'Filtrer par nom',
      duration: 'Durée',
      search: 'Rechercher',
      reset: 'Réinitialisation',
      download: 'Télécharger',
    },
    contactUs: {
      contactus: 'Contactez-nous',
      firstName: 'Prénom',
      lastName: 'Nom',
      emailAddress: 'Adresse courriel',
      mobile: 'Numéro de téléphone portable',
      message: 'Message',
      sendMessage: 'Envoyer un message',
      ourLocation: 'Nos implantations',
      enterText: 'Entrez le texte ici',
      connectUs: 'Connectons-nous avec nous',
      chooseType: 'Choisissez votre type de requête',
      enquiry: 'Enquête',
      complaint: 'Plainte',
      feedback: 'Rétroaction',
      department: 'Département',
      doctor: 'Médecin',
      enterCaptcha: 'C’est là qu’intervient Captcha',
    },
    leaveApproval: {
      title: 'Quitter la liste',
      leaveAppr: "Quitter l'approbation",
      leaveReject: 'Rejeter la demande de congé',
      cancelLeaveReq: 'Annuler la demande de congé',
      title1: 'Mes feuilles',
      title2: 'Feuilles',
      editLeave: 'Modifier le congé',
      addLeave: 'Ajouter un nouveau congé',
      comments: 'Commentaires',
      addComments: 'Ajoutez des commentaires',
      date: 'Date',
      slNo: 'Sl No',
      doctorId: 'ID du médecin',
      doctorName: 'Nom du médecin',
      leaveDates: 'Date(s) de départ',
      leaveDate: 'Date du congé',
      leaveType: 'Type de congé',
      deleteLeave: 'Supprimer le congé',
      approveLeave: 'Approuver le congé',
      rejectLeave: 'Rejeter le congé',
      addComment: 'Ajouter un commentaire',
      cancelLeave: 'Annuler le congé',
      commentRequired: 'Veuillez ajouter un commentaire',
      invalidTime: 'Veuillez fournir une durée de congé valide',
      invalidDate: 'Veuillez fournir des dates de congé valides',
      doctor: 'Médecin',
      areYouWantTo: 'Êtes-vous sûr de vouloir',
      thisLeave: 'ce congé ?',
      accept: 'Accepter',
      cancel: 'Annuler',
      reject: 'Rejeter',
      firstHalf: 'Première moitié',
      secondHalf: 'Deuxième moitié',
      fullDay: 'Journée complète',
      custom: 'Personnalisé',
      pending: 'En attente',
      approved: 'Approuvé',
      rejected: 'Rejeté',
      cancelled: 'Annulé',
      enterComments: 'Entrez des commentaires',
      leaveConfirmation: 'Confirmation de congé',
      apptConflict: 'Conflit de rendez-vous',
      apptConflictMsg:
        'Il y a des rendez-vous confirmés déjà programmés à ces dates). Voulez-vous tout reprogrammer/annuler ?',
      apptConflictMsgDoc: 'Il y a des rendez-vous confirmés déjà programmés à ces dates. Voulez-vous continuer à demander un congé ?',
      noDataLeaves:'Aucune feuille ajoutée',
      noDataLeavesMsg: `Il semble que vous n'ayez pas encore ajouté de congés.`
    },
    patients: {
      title: 'les patients',
      dob: 'Date de naissance',
      regDate:"Rég.Date",
      age: 'Âge',
      emailAddress:"Adresse e-mail",
      lastApptDate:"Dernier Appt. rendez-vous",
      editPatientInfo:'Modifier les informations sur le patient',
      noDataPatients:'Aucun patient ajouté',
      noDataPatientsMsg: `Il semble que vous n'ayez pas encore ajouté de patients.`,
      linkedNumber:`si le numéro de portable du patient lié n’est pas fourni, le numéro de portable du titulaire du compte principal sera pris en compte.`
    },
    departments: {
      title: 'Départements',
      department: 'Département',
      addDepartment: 'Ajouter un nouveau département',
      viewDepartment: 'Voir le département',
      editDepartment: 'Modifier le département',
      deptNameId: 'Nom du département',
      id: 'ID',
      deptName: 'Nom du département',
      deptDescription: 'Description du département',
      deptHead: 'Chef de département',
      noDoctors: "Aucun médecin n'est actuellement ajouté dans ce département.",
      doctors: 'Médecins',
      doctorList: 'Liste des médecins',
      selected: 'Médecin(s) sélectionné(s)',
      deleteDepartment: 'Supprimer le département',
      noDoctorsfound: 'Aucun médecin trouvé',
      deptHeadRemove: 'est le chef de ce département. Êtes-vous sûr de vouloir continuer ?',
      noDataDepts:'Aucun département ajouté',
      noDataDeptsMsg: `Il semble que vous n'ayez encore ajouté aucun département.`
    },
    users: {
      title: 'Utilisateurs',
      userName: "Nom d'utilisateur",
      roleSelect: "Sélectionnez le rôle de l'utilisateur",
      roletype: 'Type de rôle',
      adduser: 'Ajouter un utilisateur',
      selectdoc: 'Sélectionnez des médecins',
      selectdept: 'Sélectionnez un département',
      admin: 'Administrateur',
      doctor: 'Médecin',
      Appocord: 'Coordonnateur des rendez-vous',
      nameEmail: 'Nom/E-mail',
      role: "Rôle d'utilisateur",
      name: 'Nom',
      phone: 'Numéro de téléphone',
      editUser: "Modifier l'utilisateur",
      editPatient: "Modifier le patient",
      deleteUser: "Supprimer l'utilisateur",
      invalidMail: 'Identifiant de messagerie invalide',
      invalidLocationLink: 'Lien de localisation invalide',
      invalidPhone: 'Numéro de téléphone invalide',
      addDept: 'Ajouter un département',
      removeDept: 'Supprimer un département',
      userRole: 'Vous devez choisir le service et les médecins si vous choisissez l option coordonnateur de rendez-vous',
      noDataUsers:'Aucun utilisateur ajouté',
      noDataUsersMsg: `Il semble que vous n'ayez encore ajouté aucun utilisateur.`
    },
    forgotp: {
      forgot: 'Mot de passe oublié?',
      desc: 'Entrez le-mail associé à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.',
      email: 'Courriel',
      try: 'Vous souvenez-vous de votre mot de passe ? Essayer',
      sign: 'Se connecter',
      invalid: '*Identifiant de messagerie invalide',
      send: 'Envoyer',
    },
    settings: {
      title: 'Paramètres',
      profileSettings: 'Paramètres du profil',
      changeProfile: 'Changer la photo de profil (PNG, JPG, JPEG)',
      addProfile:'Ajouter une photo de profil (PNG, JPG, JPEG)',
      cropProfile: 'Changer la photo de profil',
      changePassword: 'Changer le mot de passe',
      currentPassword: 'Mot de passe actuel',
      newPassword: 'Nouveau mot de passe',
      capitalLetter:
        '1. Le mot de passe doit commencer par une lettre majuscule.',
        smallLetter:"2. Doit contenir au moins une petite lettre.",
      eightChar: '3. Le mot de passe doit comporter au moins 8 à 20 caractères.',
      specialChar: '4. Doit contenir au moins un caractère spécial.',
      oneNumber: '5. Le mot de passe doit contenir au moins un chiffre.',
      confirmPassword: 'Confirmer le mot de passe',
      generalSettings: 'Paramètres généraux',
      setLanguage: 'Sélectionnez la langue préférée',
      validMobile: 'Veuillez saisir un numéro de mobile valide.',
      validEmail: 'Veuillez saisir une adresse e-mail valide.',
      passwordMatch:
        'Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas.',
      criteriaMatch: 'Le mot de passe ne correspond pas aux critères.',
      verified: 'Vérifié',
      verify: 'Vérifier',
      verifyMail: "Veuillez vérifier l'email",
      verifyPhone: 'Veuillez vérifier le numéro de téléphone',
      systemSettings: 'Paramètres système',
      subscriptionSettings: "Gérer l'abonnement",
      enterPhoneOtp:
        'Veuillez saisir le mot de passe temporaire pour vérifier votre téléphone {phone}',
      enterEmailOtp:
        'Veuillez saisir le mot de passe temporaire pour vérifier votre email {email}',
      maxSize: '(Taille maximale de téléchargement de fichier : 5 Mo)',
      maxSizeValue:
        '(Taille maximale de téléchargement de fichier : {value} Mo)',
      verifySize:
        'La taille du fichier dépasse la limite de 5 Mo. Veuillez sélectionner un fichier plus petit.',
      verifySizeValue:
        'La taille du fichier dépasse la limite de {value} Mo. Veuillez sélectionner un fichier plus petit.',
      wrongfile: 'Format de fichier non pris en charge',
      changeYourProfile: 'Modifier votre photo de profil',
      uploadPic: 'Télécharger l’image',
      removePic: 'Supprimer l’image',
      passwordMatchErr:
        'Le mot de passe et le mot de passe de confirmation ne correspondent pas.',
      editProfile: 'Editer le profil',
      apptSettings: 'Paramètres de rendez-vous',
      emailSettings: 'Paramètres de messagerie',
      contactSettings: 'Paramètres de contact',
      reminderSettings: 'Rappel de rendez-vous',
      setTheme: 'Sélectionner le thème du portail patient',
      setCountry: "Sélectionner l'indicatif de pays par défaut",
      setOtp: 'Activer la vérification OTP',
      changeLogo: 'Modifier le logo du système (PNG, JPG, JPEG)',
      maxReschedule:
        'Nombre maximum de fois où un rendez-vous peut être reprogrammé',
      maxAdvance:
        "Délai maximum à l'avance pendant lequel un rendez-vous peut être réservé (en jours)",
      minBefore:
        "Délai minimum avant le début du rendez-vous pour qu'il puisse être réservé (en heures)",
      paymentType: 'Type de paiement',
      replayMail: "Adresse e-mail de réponse de l'utilisateur",
      feedbackMail: 'Adresse e-mail des commentaires',
      enquiryMail: 'Adresse e-mail des demandes de renseignements',
      complaintMail: 'Adresse e-mail des plaintes',
      branchName: 'Nom de la succursale',
      place: 'Lieu',
      address: 'Adresse',
      locLink: 'Lien de localisation',
      primaryPhone: 'Téléphone principal',
      secPhone: 'Téléphone secondaire',
      email: 'Courriel',
      otherLocation: 'Autre emplacement',
      add: 'Ajouter',
      primaryLocation: 'Emplacement principal',
      location: 'Emplacement',
      systemGeneratedPatientId: 'ID patient généré par le système',
      mrnOrHealthId: 'MRN / Identifiant de santé',
      healthCareIdSetting: 'Paramètres de l’identifiant de soins de santé',
      subscriptionOpened:
        'Les paramètres d’abonnement s’ouvrent dans un nouvel onglet (cliquez ici pour réessayer si nécessaire)',
      retry: 'Réessayer',
      brandname: 'Définir le nom de la marque',
      organizationname:"Définir le nom de l'organisation",
      supportMail: "Adresse e-mail d'assistance",
      changeFavicon: 'Changer de favicon (PNG, JPG, JPEG)',
      greenTheme: 'Thème vert',
      blueTheme: 'Thème bleu',
      duplicateEntry: 'Entrée en double',
      remindBefore: 'Rappeler avant',
      reminderType: 'Type de rappel',
      ReminderlimitExceeded: 'Limite de rappel dépassée selon votre forfait',
      all: 'tout',
      sms:'SMS',
      minutes: 'Minutes',
      hours: 'Heures',
      days: 'Jours',
      weeks: 'Semaines',
      favicondesc: 'Un Favicon est une petite icône de 16 x 16 pixels utilisée sur les navigateurs Web pour représenter un site Web ou une page Web.',
      verifyEmail: `Veuillez vérifier l'e-mail`,
      allowSubsequentSlots: 'Autoriser le remplissage des créneaux suivants',
      allow: 'Permettre'
    },
    patientPortal: {
      login: 'Se connecter',
      signup: 'Inscription',
      allRightsReserved: 'Tous droits réservés',
      poweredBy: 'Propulsé par',
      search: 'Recherche',
      caption1: 'Renforcer la santé',
      caption2:
        'Réservation de médecins en ligne sans faille au bout de vos doigts',
      subCaption:
        "L'endroit le plus simple et le plus rapide pour réserver un médecin en ligne",
      signin: 'Connectez-vous',
      guest: "Continuer en tant qu'invité",
      otpVerification: 'Vérification OTP',
      otpExpire: 'OTP expirera dans',
      mobileNo: 'Numéro de mobile',
      myMobileNo : 'Mon numéro de portable',
      request: 'Demander OTP',
      resend: 'Renvoyer OTP',
      alreadyHave: 'Vous avez déjà un compte',
      or: 'OU',
      saveTime: "Gagnez du temps à l'hôpital, inscrivez-vous maintenant.",
      loginWithOTP: 'Connectez-vous avec OTP au lieu du mot de passe',
      loginWithPsw: 'Connectez-vous avec un mot de passe au lieu d’OTP',
      forgotPassword: 'Mot de passe oublié',
      password: 'Mot de passe',
      emailOrmobile: 'Email / Numéro de mobile',
      name: 'Nom',
      email: 'Identifiant email',
      mrnHealthId: 'MRN / Identifiant de santé',
      confirmPassword: 'Confirmer le mot de passe',
      terms: "Conditions d'utilisation",
      agreed: "J'ai lu et accepté les",
      enterPassword: 'Tapez votre mot de passe',
      reEnterPassword: 'Entrez à nouveau le mot de passe',
      requestPswReset: 'Demander la réinitialisation du mot de passe',
      docOrSpec: 'Nom du médecin / Spécialité',
      selectDate: 'Sélectionner une date',
      speciality: 'Spécialité',
      doctor: 'Médecin',
      doctors: 'Médecins',
      bookAnAppointment: 'Réserver un rendez-vous',
      whenQuestion: 'Quand?',
      whichSpecialty:'Quelle spécialité?',
      relevance: 'Pertinence',
      experience: 'Expérience',
      bookAppointment: 'Prendre rendez-vous',
      LookingMore: "Recherche d'autres options",
      bookingsuccess: 'Réservation réussie',
      bookingconfimred: 'Votre réservation chez {span} a été confirmée !',
      appointmentDetails: 'Les détails du rendez-vous sont inclus ci-dessous',
      bookingId: 'ID de réservation',
      ConsultationFee: 'Frais de consultation',
      BookAnotherAppointment: 'Prendre un autre rendez-vous',
      availability: 'Disponibilité',
      viewProfile: 'Voir le profil',
      pickTimeSlot: 'Choisissez un créneau horaire',
      // unavailableSlot: 'Créneau indisponible',
      morning: 'Matin',
      afternoon: 'après-midi',
      evening: 'Soir',
      night: 'Nuit',
      doctorQualification: 'Qualification du médecin',
      confirm: 'Confirmer',
      proceed: 'Procéder',
      inClinicAppointment: 'Ce rendez-vous en clinique est',
      patientName: 'Nom du patient',
      patientMoblNo: 'Numéro de téléphone du patient',
      payAtClinic: 'Payer à la clinique',
      payOnline: 'Payer en ligne',
      both: 'les deux',
      patientEmail: 'Adresse e-mail du patient',
      prescriptions: 'Ordonnances',
      filterByName: 'Filtrer par nom',
      durationQ: 'Durée?',
      reset: 'Réinitialisation',
      sortBy: 'Trier par',
      home: 'Accueil',
      profiles: 'Profils',
      linkProfile: 'Lier un profil',
      patient_id: 'Identifiant du patient',
      dob: 'Date de naissance',
      viewRecords: 'Afficher les enregistrements',
      viewAppointments: 'Voir les rendez-vous',
      familyMem: 'Membres de la famille',
      linkedProfiles: 'Profils liés',
      quote:
        'Connectez-vous avec vos proches pour rester informé de leur santé',
      id: 'ID',
      gender: 'Genre',
      relation: 'Relation',
      delink: 'Dissocier',
      verifyOTP: 'Vérifier l’OTP',
      updatedOn: 'Mis à jour le',
      age: 'Âge',
      ageinYears: 'Âge (années)',
      consentAgree: "J'ai le consentement pour réserver au nom de ce patient.",
      enterPsw:
        'Veuillez saisir le mot de passe à usage unique pour vérifier votre compte.',
      enterProfilePsw:
        'Veuillez entrer le mot de passe à usage unique pour vérifier votre profil',
      otpSent: ' Un mot de passe à usage unique a été envoyé à',
      edit: 'Éditer',
      otpVerified: 'OTP vérifié avec succès !',
      inCorrectOtp: 'Saisie d’un mot de passe à usage unique incorrect',
      invalidOtp: 'Veuillez entrer OTP',
      verify: 'Vérifier',
      continue: 'Continuer',
      forMySelf: 'pour moi-même',
      forSomeOne: 'pour Quelqu’un d’autre',
      dLinkProfile: 'Dissocier le profil',
      areYouSure: 'Êtes-vous sûre de vouloir dissocier ce profil ?',
      areYouSureNoPhone:
        "Ce profil de patient sera définitivement supprimé du système car le numéro de téléphone n'est pas mis à jour",
      yesDeLink: 'Oui, dissocier le profil',
      noKeep: 'Non, conserver le profil',
      profileLinked: 'Profil lié avec succès !',
      succesfullyLinked: 'Vous avez réussi à lier le membre de votre famille.',
      backToListing: 'Retour à la liste',
      mailSentSuccess: 'Courrier envoyé avec succès !',
      clickLink:
        'Nous avons envoyé un e-mail à l’adresse fournie. Cliquez sur le lien dans l’e-mail pour réinitialiser votre mot de passe.',
      didntReceiveMail: 'Vous n’avez pas reçu d’e-mail ?',
      resendMail: 'Renvoyer l’e-mail',
      noSlotAvailable: 'Aucun créneau n’est disponible à la date sélectionnée.',
      noDoctorAvailable: 'Aucun créneau n est disponible pour le medecin sélectionné.',
      noDeptAvailable: 'Aucun médecin n’est disponible.',
      noDataBookSlot:
        'Besoin d’un rendez-vous rapide ? Recherchez des médecins, des services et réservez votre visite en quelques minutes.',
      found: 'fonder',
      warning:
        "Veuillez remplir ces informations dans votre profil : Nom, Sexe et Date de naissance avant d'aller prendre rendez-vous",
      addMember: '+ Ajouter un nouveau membre',
      add: '+ Ajouter',
      addNewFamMember: 'Ajouter un nouveau membre de la famille',
      reschedulesuccess: 'Replanification réussie',
      searchResult: 'Résultats de la recherche',
      linkaProfile: 'Lier un profil',
      updateContinue: 'Mettre à jour et continuer',
      noMember: 'Aucun membre trouvé',
      addDetails: 'Veuillez entrer les détails.',
      selectDocOrSpeciality:'Veuillez sélectionner un médecin ou une spécialité',
      addDate: 'Veuillez sélectionner une date.',
      addNewMember: 'Ajouter un nouveau membre',
    },
    userType: {
      owner: "Propriétaire de l'abonnement",
      admin: 'Administratrice',
      doctor: 'Médecin',
      patient: 'Patiente',
      appointmentCordinator: 'Coordinateur des nominations',
    },
    myAppointments: {
      home: 'Accueil',
      myAppointments: 'Mes rendez-vous',
      bookAnAppointment: 'Prendre rendez-vous',
      searchName: 'Rechercher par nom',
      docSpec: 'Médecin / Spécialité',
      duration: 'Durée',
      aptStatus: 'État du rendez-vous',
      filterByName: 'Filtrer par nom de patient',
      reset: 'Réinitialiser',
      confirmed: 'Confirmé',
      noShow: 'Absente',
      notAttempted: 'Pas tenté',
      completed: 'Terminé',
      cancelled: 'Annulé',
      upcomingVisit: 'Prochaine visite',
      previousVisit: 'Visite précédente',
      cancelledAppointment: 'Rendez-vous annulé',
      aptConfirmed: 'Votre rendez-vous est confirmé',
      aptCancelled: 'Annulé',
      bookFor: 'Réservé pour',
      reason: 'Motif du rendez-vous',
      reasonForCancel: 'Raison de l’annulation',
      showMore: 'Afficher plus',
      cancel: 'Annuler le rendez-vous',
      reschedule: 'Reporter le rendez-vous',
      medRecords: 'Dossiers médicaux',
      prescription: 'Ordonnance',
      show: 'Montrer',
      more: 'plus...',
      less: 'Moins',
      confirmation:
        'Etes-vous sûr de vouloir reprogrammer le rendez-vous en cours ?',
      continue: 'Oui, continuer',
      stop: 'Non, respecter rendez-vous',
      patientInfo: 'Informations sur les patients',
    },
    patientNav: {
      appointments: 'Rendez-vous',
      profiles: 'Profils',
      prescriptions: 'Ordonnances',
      medRecords: 'Dossiers médicaux',
      invoices: 'Factures',
      contactUs: 'Nous contacter',
      profile: 'Profil',
      profileSettings: 'Paramètres du profil',
      changePassword: 'Changer le mot de passe',
      settings: 'Paramètres',
      logout: 'Déconnexion',
      guest: 'Invitée',
    },
    medicalRecords: {
      home: 'Accueil',
      medicalRecords: 'Dossiers médicaux',
      addMedicalRecord: 'Ajouter un dossier médical',
      filterByName: 'Filtrer par nom',
      duration: 'Durée',
      search: 'Rechercher',
      reset: 'Réinitialiser',
      slNo: 'Sl.No',
      fileName: 'Nom du fichier',
      uploadDate: 'Date de téléchargement',
      remarks: 'Remarques',
      action: 'Action',
      itemPerPage: 'Éléments par page :',
      patientName: 'Nom du patient',
      uploadFile: 'Charger le fichier',
      uploadDescription:
        'Vous pouvez télécharger le document aux formats PDF, Jpeg et PNG',
      selectFile: 'Sélectionner le fichier',
      youWantToUpload: 'vous souhaitez télécharger',
      maxFileUploadSize:
        'Taille maximale de téléchargement de fichiers : 10 Mo.',
      txt: 'TXT',
      pdf: 'PDF',
      doc: 'DOC',
      png: 'PNG',
      remark: 'Remarque',
      description: 'Description',
      addDocument: 'Ajouter un document',
      remarkPlaceholder:
        "c.-à-d., rythme régulier, pas de bruit fémoral, pas d'antécédents",
      MedicalRecordDetails: 'Détails du dossier médical',
      MemberInfo: 'Informations sur les membres',
      id: 'ID',
      fileUploaded: 'Fichier téléchargé',
      choosePerson: 'Choisissez la personne',
      uploadFileSize: 'La taille du fichier doit être inférieure à 10 Mo',
      totalFileSize: 'Overall Files size should be less than 10 MB',
      maxFileCount: 'Max Number of File allowed is 5',
      deleteMedicalRecord: 'Supprimer le dossier médical',
      deleteConfirm: 'Êtes-vous sûre de vouloir supprimer ce dossier médical ?',
      continue: 'Oui, supprimer',
      stop: 'Non, tenir un registre',
      allowedFiles: 'Fichiers autorisés:',
      unsupportedFiles: 'Format de fichier non pris en charge',
      filter: 'Filtrer par nom de patient',
    },
    profileSetting: {
      home: 'Accueil',
      accountInformation: 'Informations sur le profil',
      manageInformation:
        'Gérer vos informations personnelles et vos coordonnées',
      manageInfo:
        'Gérez vos informations personnelles et vos coordonnées. Atteindre',
      profile: 'Profil',
      cancel: 'Annuler',
      update: 'Mettre à jour',
      setPsw: 'Définir le mot de passe',
      currentPsw: 'Mot de passe actuel',
      newPsw: 'Nouveau mot de passe',
      confirmPsw: 'Confirmer le mot de passe',
      accountInfo: 'Informations sur le compte',
      changePassword: 'Changer le mot de passe',
      profileInfo: 'Informations sur le profil',
      observations: 'Remarques',
      notAdded: 'Pas ajouté',
      entercurrentPsw: 'Entrer le mot de passe actuel',
      enternewPsw: 'Entrez un nouveau mot de passe',
      enterconfirmPsw: 'Entrez Confirmer le mot de passe',
    },
    linkProfile: {
      search:
        'Recherche par identifiant de courrier électronique / numéro de portable / identifiant de patient',
      searchmrn:
        'Recherche par identifiant de courrier électronique / numéro de portable / MRN (ID de santé)',
      otpSent:
        'L’OTP a été envoyé avec succès au numéro de mobile enregistré sur votre compte.',
      match: 'Profil correspondant trouvé',
      email: 'Identifiant de courrier électronique',
      mobile: 'Mobile Number',
      patient: 'ID du patient',
      noResult: 'Aucun résultat trouvé.',
      profileUpdated: 'Mise à jour du profil réussie!',
      edit: 'Editer le profil',
      delink: 'Dissocier',
      verify: 'Vérifier OTP',
      profileInfo: 'Informations sur le profil',
      observations: 'Remarques',
      patientInformation: 'Informations sur le profil',
      managePatientInfo: 'Gérer vos informations personnelles et de contact',
    },
    prescriptionView: {
      appointmentDate: 'Date de rendez-vous',
      appointmentDateTime: 'Appt. Date et heure',
      apntmnttDate: 'Date d’appt.',
      prescriptionDetail: "Détails de l'ordonnance",
      notes: 'Remarques',
      medicines: 'Médicaments',
      drugName: 'Nom du médicament',
      dosage: 'Posologie',
      duration: 'Durée',
      slNo: 'Numéro',
      advices: 'Conseils',
      noNotes: 'Aucune note ajoutée',
      noAdvices: 'Aucun conseil ajouté',
    },
    calender: {
      january: 'janvier',
      february: 'février',
      march: 'mars',
      april: 'avril',
      may: 'mai',
      june: 'juin',
      july: 'juillet',
      august: 'août',
      september: 'septembre',
      october: 'octobre',
      november: 'novembre',
      december: 'décembre',
      sunday: 'dimanche',
      monday: 'lundi',
      tuesday: 'mardi',
      wednesday: 'mercredi',
      thursday: 'jeudi',
      friday: 'vendredi',
      saturday: 'samedi',
      mon: 'Lun',
      tue: 'Mar',
      wed: 'Mercre',
      thu: 'Jeu',
      fri: 'Ven',
      sat: 'Same',
      sun: 'Dim',
      sunFull: 'dimanche',
      monFull: 'lundi',
      tueFull: 'mardi',
      wedFull: 'mercredi',
      thuFull: 'jeudi',
      friFull: 'vendredi',
      satFull: 'samedi',
    },
    relation: {
      daughter: 'Fille',
      son: 'Fils',
      mother: 'Mère',
      father: 'Père',
      husband: 'Mari',
      wife: 'Femme',
      brother: 'Frère',
      sister: 'Soeur',
      grandmother: 'Grand-mère',
      grandfather: 'Grand-père',
      stepmother: 'Belle-mère',
      stepfather: 'Beau-père',
      aunt: 'Tante',
      uncle: 'Oncle',
      niece: 'Nièce',
      nephew: 'Neveu',
      cousin: 'Cousine',
      stepson: 'Beau-fils',
      stepdaughter: 'Belle-fille',
      motherinlaw: 'Belle-mère',
      fatherinlaw: 'Beau-père',
      brotherinlaw: 'Beau-frère',
      sisterinlaw: 'Belle-soeur',
      grandson: 'Petit-fils',
      granddaughter: 'Petite-fille',
      greatgrandfather: 'Arrière-grand-père',
      greatgrandmother: 'Arrière-grand-mère',
      other: 'Autre',
      friend: 'Ami / Amie',
    },
    subscription: {
      expired: 'Le lien que vous avez suivi a expiré',
      portal: 'Le portail de prise de rendez-vous nest actuellement pas accessible. Veuillez contacter lhôpital/la clinique concernée pour prendre rendez-vous',
      booking:
        'nest actuellement pas accessible, veuillez contacter lhôpital/clinique concerné pour prendre rendez-vous',
      support: 'Soutien',
    },
    subscription_admin: {
      expiry: 'Votre abonnement a expiré',
      description:
        'Pour continuer à utiliser l’application sans interruption, veuillez consulter les informations relatives à votre abonnement et prendre les mesures nécessaires',
      renew: 'RENOUVELER L’ABONNEMENT',
      descriptionNew:
        "Pour continuer à utiliser l'application sans interruption veuillez contacter votre administrateur pour renouveler l'abonnement",
    },
  },
};
export default message;
