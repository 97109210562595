import en_US from './en-US';
import fr_FR from './fr-FR';
import ja_JA from './ja-JA';

const messages = {
  ...en_US,
  ...fr_FR,
  ...ja_JA,
};

export default messages;
