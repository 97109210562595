import React, { useEffect, useRef, useState } from 'react';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'Language/Translate';
import ChangePassword from './ChangePassword';
import ProfileInformation from './ProfileInformation';
import { fetchUserProfileDetails } from 'Actions/settingsAction';
import Observations from './Observations';
import img from 'Assets/img/patient-portal/User_Placeholder.svg';
import errorImg from '../../../Assets/img/patient-portal/error_img_placeHolder.png';
import Loader from 'Components/Common/Loader';
import deleteIicon from '../../../Assets/img/icons/trash-circle.svg';
import { CardMedia } from '@mui/material';
import CropImage from '../Common/CropImage';
import Modal from '../Common/Modal';
import { showToaster, ToasterTypes } from 'Actions/toasterAction';

function ProfileSetting({
  getProfileDetails,
  profileDetails,
  applicationData,
  displaytoaster
}) {
  let { path } = useRouteMatch();
  const history = useHistory();
  let pathname = window.location.hash.split('/')[3];
  const [editmode, setEditmode] = useState(false);
  const [isUpdateProfileImg, setIsUpdateProfileImg] = useState(false);
  const [profileImg, setProfileImg] = useState({});
  const [profileImgCrop, setProfileImgCrop] = useState('');
  const [loading, isLoading] = useState();
  const [showCrop, setShowCrop] = useState(false);
  const [key, setKey] = useState(0);
  const imgStatus = useRef(true);
  useEffect(() => {
    setProfileImg(userDetails?.profile_img);
  }, [profileDetails]);

  const hiddenFileInput = useRef(null);
  useEffect(() => {
    isLoading(true);
    getProfileDetails(isLoading);
    setIsUpdateProfileImg(false);
    imgStatus.current = true;
  }, [editmode]);

  // handle home click
  const handleHomeClick = () => {
    history.push('/webConnect');
  };
  const { patient_details: patientDetails, user_details: userDetails } =
    profileDetails;
  const profileRoutes = [
    {
      path: `${path}/changePassword`,
      component: ChangePassword,
      hasSubRoute: false,
    },
    {
      path: `${path}/profileInfo`,
      component: ProfileInformation,
      hasSubRoute: false,
    },
    {
      path: `${path}/observations`,
      component: Observations,
      hasSubRoute: false,
    },
  ];

  const menuItems = [
    {
      path: '/webConnect/profileSetting/profileInfo',
      label: translate('profileSetting.profileInfo', 'Profile Information'),
      icon: <i className="bi bi-person"></i>,
      isActive: pathname === 'profileInfo',
    },
    // userDetails?.name
    //   ? {
    //       path: '/webConnect/profileSetting/changePassword',
    //       label: translate('profileSetting.changePassword', 'Change Password'),
    //       icon: <i className="bi bi-key"></i>,
    //       isActive: pathname === 'changePassword',
    //     }
    //   : '',
    // {
    //   path: '/webConnect/profileSetting/observations',
    //   label: translate('profileSetting.observations', 'Observation'),
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="16"
    //       height="16"
    //       fill="currentColor"
    //       className="bi bi-heart-pulse"
    //       viewBox="0 0 16 16"
    //     >
    //       <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053.918 3.995.78 5.323 1.508 7H.43c-2.128-5.697 4.165-8.83 7.394-5.857q.09.083.176.171a3 3 0 0 1 .176-.17c3.23-2.974 9.522.159 7.394 5.856h-1.078c.728-1.677.59-3.005.108-3.947C13.486.878 10.4.28 8.717 2.01zM2.212 10h1.315C4.593 11.183 6.05 12.458 8 13.795c1.949-1.337 3.407-2.612 4.473-3.795h1.315c-1.265 1.566-3.14 3.25-5.788 5-2.648-1.75-4.523-3.434-5.788-5" />
    //       <path d="M10.464 3.314a.5.5 0 0 0-.945.049L7.921 8.956 6.464 5.314a.5.5 0 0 0-.88-.091L3.732 8H.5a.5.5 0 0 0 0 1H4a.5.5 0 0 0 .416-.223l1.473-2.209 1.647 4.118a.5.5 0 0 0 .945-.049l1.598-5.593 1.457 3.642A.5.5 0 0 0 12 9h3.5a.5.5 0 0 0 0-1h-3.162z" />
    //     </svg>
    //   ),
    //   isActive: '',
    // },
  ];

  const uploadFile = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    setKey(key+1)
    const file = event.target.files[0];
    const reader = new FileReader();
    const maxSizeInBytes = 5 * 1024 * 1024;
    if (
      file?.type !== 'image/jpeg' &&
      file?.type !== 'image/jpg' &&
      file?.type !== 'image/png' &&
      file
    ) {
      displaytoaster(
        translate('settings.wrongfile', 'Unsupported file format'),
        ToasterTypes.Warning
      );
    } else if (file?.size > maxSizeInBytes) {
      displaytoaster(
        translate(
          'settings.verifySize',
          'File size exceeds the 5MB limit. Please select a smaller file.'
        ),
        ToasterTypes.Warning
      );
    } else {
      reader.onload = () => {
        // setIsUpdateProfileImg(true);
        setProfileImgCrop(reader.result);
        setShowCrop(true);
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        setIsUpdateProfileImg(false);
      }
  }
  };

  const removeProfilePic = () => {
    setIsUpdateProfileImg(true);
    setProfileImg('');
  };

  // hanlde crop modal close
  const handleCropClose = () => {
    setIsUpdateProfileImg(false);
    setProfileImgCrop('');
    setShowCrop(false);
  };

  const setData = (data) => {
    setIsUpdateProfileImg(true);
    setProfileImg(data);
  };

  return (
    <div>
      {showCrop &&
        <Modal
          close={showCrop}
          setClose={handleCropClose}
          title={translate('settings.cropProfile', 'Change profile picture')}
        >
          <CropImage
            imgSrc={profileImgCrop}
            setData={setData}
            setShowCrop={setShowCrop}
            onClose={handleCropClose}
          />
        </Modal>
      }
      <section className="bread-crumbs mb-lg-2">
        <div className="wrapper links">
          <a
            className="previous"
            onClick={handleHomeClick}
            style={{ cursor: 'pointer' }}
          >
            {translate('profileSetting.home', 'Home')}
          </a>
          <span> {'>'} </span>
          <a className="bread-crumb-cursor">
            {' '}
            {translate(
              'profileSetting.accountInformation',
              'Profile Information'
            )}
          </a>
        </div>
      </section>
      <section className="main-header">
        <div className="wrapper">
          <h1 className="main-title">
            {translate(
              'profileSetting.accountInformation',
              'Profile Information'
            )}
          </h1>
        </div>
      </section>
      {loading ? (
        <Loader />
      ) : (
        <section className="change-password profile-information">
          <div className="wrapper mb-5">
            <div className="row">
              <div className="col-lg-3">
                <div className="right-section">
                  <div className="card profile-card">
                    <div className="main-img">
                      {editmode && profileImg ? (
                        <button
                          onClick={removeProfilePic}
                          className="btn-transparent"
                        >
                          <img src={deleteIicon} alt="" />
                        </button>
                      ) : null}
                      <CardMedia
                        component="img"
                        image={profileImg || img}
                        onError={(e) => {
                          if (imgStatus.current) {
                            e.target.src = errorImg;
                            imgStatus.current = false;
                          }
                        }}
                      />
                      {editmode ? (
                        <>
                          <input
                            type="file"
                            ref={hiddenFileInput}
                            accept="image/*"
                            onChange={handleChange}
                            style={{ display: 'none' }}
                            key={key}
                          />
                          <button
                            className="add-photo-btn"
                            onClick={uploadFile}
                          >
                            <i className="bi bi-camera-fill"></i> Add
                          </button>
                        </>
                      ) : null}
                    </div>
                    <h4>
                      {userDetails?.name
                        ? `${userDetails?.name} ${userDetails?.last_name}`
                        : translate('common.guest', 'Guest User')}
                    </h4>
                    {applicationData.use_system_generated_mrn ? (
                      <>
                        {translate('patientPortal.patient_id', 'Patient ID')} :{' '}
                      </>
                    ) : patientDetails?.mrn ? (
                      <>
                        {translate(
                          'patientPortal.mrnHealthId',
                          'mrn / Health Id'
                        )}{' '}
                        :{' '}
                      </>
                    ) : (
                      ''
                    )}

                    {applicationData?.use_system_generated_mrn
                      ? patientDetails?.patient_id
                      : patientDetails?.mrn}
                  </div>
                  <div className="side-nav">
                    <ul>
                      {menuItems
                        .filter((i) => i !== '')
                        .map((item, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              history.push(item.path);
                            }}
                          >
                            <a className={item.isActive ? 'active-link' : ''}>
                              {item.icon}
                              {item.label}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                {profileRoutes.map((item) => (
                  <Route
                    path={item.path}
                    key={item.path}
                    exact={!item.hasSubRoute}
                    render={(props) => (
                      <item.component
                        {...props}
                        editmode={editmode}
                        profileImg={profileImg}
                        setEditmode={setEditmode}
                        isUpdateProfileImg={isUpdateProfileImg}
                        setIsUpdateProfileImg={setIsUpdateProfileImg}
                      />
                    )}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  profileDetails: state.SettingsReducer.profileDetails,
  applicationData: state.SettingsReducer.applicationData,
});

const mapDispatchToProps = (dispatch) => ({
  getProfileDetails: (isLoading) =>
    dispatch(fetchUserProfileDetails(false, isLoading)),
    displaytoaster: (message, type) => dispatch(showToaster(message, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSetting);
