import store from 'store';
import network from 'Utils/Api/Network';
import { storeApplicationDetails } from 'Actions/settingsAction';

const REDIRECTION_URL = process.env.REACT_APP_REDIRECTION_URL

export function ClearCookies() {
  window.location.href = '/';
  document.cookie = 'session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export async function SetCookies(key_name, key_value) {
  document.cookie = (await key_name) + JSON.stringify(key_value);
  if (
    document.cookie &&
    document.cookie !== '' &&
    document.cookie.split(';').some(function (item) {
      return item.indexOf('session=') >= 0;
    })
  ) {
    return document.cookie;
  } else {
    return false;
  }
}

export async function FetchCookies(key_name) {
  var response =
    document.cookie && document.cookie.split(key_name)[1]
      ? JSON.parse(document.cookie.split(key_name)[1])
      : '';
  return response;
}

// set interval to refresh the cloudfront cookies
const refreshCookeies = (duration) => {
  setInterval(
    () => fetchCookiesWithSubscriptionDetails(false),
    duration * 60 * 1000
  );
};

// fetch subscription data from telemedicine backend
export const fetchCookiesWithSubscriptionDetails = (
  firstTime = true,
  // setUnidentified = () => {},
  setIsError = () => {},
  setIsDataLoaded = () => {}
) => {
  network.get({ url: '/app/status' }).then((response) => {
    const { result, code, isError } = response;
    const { client_details, otp_settings } = result || {};
    if (code === 403) {
      // setUnidentified(true);
      window.location.href = REDIRECTION_URL;
    } else if (isError) {
      setIsError(true);
    } else {
      if (result?.cookie_exp_duration && firstTime) {
        refreshCookeies(result.cookie_exp_duration);
        setIsDataLoaded((prev) => ({ ...prev, status: true }));
      }
      store.dispatch(storeApplicationDetails({ client_details, otp_settings }));
    }
  });
};
