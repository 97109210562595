/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import searchIcon from '../../Assets/img/icons/search-icon-emp.svg';
import toggleSearchIcon from '../../Assets/img/icons/card-search-icon.svg';
import backToNormal from '../../Assets/img/icons/back-to-icon.png'
import ClearIcon from '@mui/icons-material/Clear';
import ReactSelectDropDown from '../Common/ReactSelectDropDown';
import { connect } from 'react-redux';
import {
  clearDepartmentData,
  FetchDepartmentData,
} from '../../Actions/departmentAction';
import { FormattedMessage } from 'react-intl';
import Loader from 'Components/Common/Loader';
import Checkbox from '../Common/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { translate } from 'Language/Translate';
import { useHistory } from 'react-router-dom';
import { listAllDoc, removeDrPrefix } from 'Utils/StringFormatter/String';

const EditDepartment = (props) => {
  const {
    dataToEdit,
    updatedepartment_head,
    updatedepartment_status,
    updateDepartment_desc,
    department_name,
    department_status,
    validationErr,
    department_desc,
    getDepartmentData,
    selectedDepartmentData,
    doctorList,
    setDoctorUpdate,
    clearDepartment,
    focusArea="",
    setFocusArea=()=>{},
    intl,
    updateShowRemConformPopup,
    setRemoveDocList,
    removeDocList,
    nonDeptDoctors,
    setNonDeptDoctors,
    doctors,
    setDoctors,
    add,
    remove,
    selectedDepartHead,
    SetSelectedDepartHead,
    updateShowConformPopup,
    setRemoveDoc,
    docRemoveAction
  } = props;

  const history = useHistory();
  const searchRef = useRef()
  const [search, setSearch] = useState('');
  // loader state
  const [doctorisLoading, updateDoctorisLoading] = useState(false);
  const [searchShow,setSearchShow] = useState(false)

  useEffect(() => {
    if (dataToEdit) {
      updateDoctorisLoading(true);
      getDepartmentData(dataToEdit.dept_id, updateDoctorisLoading);
      let option = dataToEdit?.dept_head?.dept_head_id
        ? {
            label: 'Dr.'+' '+dataToEdit?.dept_head?.dept_head_name,
            value: dataToEdit?.dept_head?.dept_head_id,
          }
        : '';
      SetSelectedDepartHead(option);
    }
    if(focusArea){
       let ele= document.getElementById(focusArea)
       if(ele){
        ele.focus()
       }
    }
    return () => {
      setFocusArea("")
      clearDepartment();
    };
  }, [dataToEdit]);

  useEffect(() => {
    if (
      selectedDepartmentData.department &&
      Array.isArray(doctorList) 
      && doctorList.length
    ) {
      const doctorOptions =
        selectedDepartmentData?.doctors?.map((doctor) => ({
          label: `${doctor?.name} ${doctor.last_name}`,
          value: doctor.doctor_id,
          userId: doctor.user_id
        })) || [];
      setDoctors(doctorOptions);

      const doctorNotInDepartmentOptions = doctorList.map((doctor) => ({
        label: `${doctor?.name} ${doctor.last_name}`,
        value: doctor.doctor_id,
        userId: doctor.user_id,
        status:doctor.doctor_status,
        isAdded: doctorOptions.some((obj2) => obj2.value === doctor.doctor_id),
      }));
      setNonDeptDoctors(doctorNotInDepartmentOptions);
    }
  }, [selectedDepartmentData, doctorList]);

  // handle doctor search
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
    const options = listAllDoc(value) ? doctorList : doctorList?.filter(
      (doctor) =>
        `${doctor?.name?.toLowerCase()} ${doctor?.last_name?.toLowerCase()}`.includes(removeDrPrefix(value).toLowerCase())
    );
    const doctorOptions = options.map((doctor) => ({
      label: `${doctor.name} ${doctor.last_name}`,
      value: doctor.doctor_id,
      userId: doctor.user_id,
      status:doctor.doctor_status,
      isAdded: doctors.some((obj2) => obj2.value === doctor.doctor_id),
    }));

    setNonDeptDoctors(doctorOptions);
  };

  // handle add a new doctor to the department
  const handleCheckDoctor = (event, doctor, index) => {
    const { checked } = event.target;
    if (checked) {
      const newdoctors = [doctor, ...doctors];
      setDoctors(newdoctors);
      const nonDeptDoctorsCopy = [...nonDeptDoctors];
      nonDeptDoctorsCopy[index].isAdded = checked;
      setNonDeptDoctors(nonDeptDoctorsCopy);
      // to handle doctor that was alredy added unchecked and checked 
      const removedDoctors = remove ? [...remove] : [];
      if (removedDoctors.includes(doctor.value)) {
        const remove = removedDoctors.findIndex(
          (item) => item === doctor.value
        );
        if (remove > -1) {
          removedDoctors.splice(remove, 1);
          setDoctorUpdate((prev) => ({
            ...prev,
            remove: removedDoctors,
          }));
        }
      } else { // new doctor add
        const newDoctors = add ? [...add] : [];
        newDoctors.push(doctor.value);
        setDoctorUpdate((prev) => ({
          ...prev,
          add: newDoctors,
        }));
      }
    } else {
      handleRemoveDoctorList(event, doctor)
        
    }
  };

  // handle remove a doctor from the department
  const handleRemoveDoctor = (doctor, index) => {
    setRemoveDoc({ doctor, index })
    if(selectedDepartHead && (selectedDepartHead.value === doctor.userId)){
      updateShowConformPopup(true)
      return
    }
    docRemoveAction(doctor, index)
  };



  const handleRemoveDoctorList = (event, doctor) => {
    setRemoveDocList({event, doctor})
    if(selectedDepartHead && (selectedDepartHead.value === doctor.userId)){
      updateShowRemConformPopup(true)
      return
    }
    removeDocList(event, doctor)
  };


  // handle department head selection
  const handleDepartmentHead = (item) => {
    updatedepartment_head(item?.value);
    SetSelectedDepartHead(item);
  };

  return (
    <>
      <div className="container pr-2">
        <div className="row mt-3">
          <div className="col-md-4">
            <div className={validationErr ? 'form-group error' : 'form-group'}>
              <label htmlFor="User name">
                <FormattedMessage
                  id="departments.deptName"
                  defaultMessage="Department Name"
                />
              </label>
              <input
                value={department_name}
                className="form-control text-capitalize"
                placeholder="Department"
                disabled
              />
            </div>
          </div>
          {/* <div className="col-12"></div> */}
          <div className="col-md-4">
            <div className="form-group"
            onKeyDown={(e)=>{
              if(e.key === "Tab" && e.shiftKey) e.preventDefault()
            }}
            >
              <label htmlFor="User name">
                <FormattedMessage
                  id="departments.deptHead"
                  defaultMessage="Department Head"
                />
              </label>
              <ReactSelectDropDown
                label={intl.formatMessage({
                  id: 'departments.deptHead',
                  defaultMessage: 'Department Head',
                })}
                options={doctors.map(item => ({ label: `Dr. ${item.label}`, value: item.userId }))}
                onInputChange={handleDepartmentHead}
                value={selectedDepartHead}
                isClearable
                autoFocus
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="User name">
                <FormattedMessage id="common.status" defaultMessage="Status" />
              </label>
              <div className="switchWrap">
                        <RadioGroup
                          row
                          value={department_status ? 'active' : 'inactive'}
                          onChange={(e) =>
                            updatedepartment_status(e.target.value === 'active')
                          }
                        >
                          <FormControlLabel
                            value="active"
                            control={<Radio />}
                            label={translate('common.active', 'Active')}
                          />
                          <FormControlLabel
                            value="inactive"
                            control={<Radio />}
                            label={translate('common.inactive', 'Inactive')}
                          />
                        </RadioGroup>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className={'form-group'}>
              <label htmlFor="User name">
                <FormattedMessage
                  id="departments.deptDescription"
                  defaultMessage="Department Description"
                />
              </label>
              <div className="text-area-wrap">
              <textarea
                type="text"
                rows="3"
                className="form-control down-arrow input"
                id="departmentname"
                maxLength="300"
                autoComplete="off"
                value={department_desc}
                onChange={(e) => updateDepartment_desc(e.target.value)}
              />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-3 mb-lg-0">
            <div className="listWrap">
              <div className="listBox v2">
                {/* use closed or opened class to toggle state of the search */}
                <div className={`search-container ${searchShow ? 'opened':'closed'}`}>
                <h6 className='card-heading'>Doctor List</h6>
                <div className="searchInput">
                  <input
                    type="text"
                    className="form-control search"
                    value={search}
                    maxLength="30"
                    onChange={handleSearchChange}
                    id="search"
                    autoComplete="off"
                    ref={searchRef}
                    placeholder={intl.formatMessage({
                      id: 'common.searchDoctors',
                      defaultMessage: 'Search doctors.',
                    })}
                  />
                  <img src={searchIcon} alt="search" />
                </div>
                <div className="search-toggle-wrap" onClick={()=>{
                  if(searchShow) handleSearchChange({target: {value: ''}});
                  setSearchShow(!searchShow);
                  searchRef.current?.focus()
                }}>
                <img className='search-img' src={toggleSearchIcon} alt="search" />
                <img className='back-to-img' src={backToNormal} alt="search" />
                </div>
                </div>

                <ul id="department_Doctr-List" tabIndex={-1}>
                  {nonDeptDoctors && nonDeptDoctors.length ? (
                    nonDeptDoctors.sort((a,b)=>b.isAdded - a.isAdded).map((doctor, index) => (
                      <li key={index + 1} className="checkbox-container ps-2">
                        <Checkbox
                          checked={doctor.isAdded}
                          handleChange={(e) =>
                            handleCheckDoctor(e, doctor, index)
                          }
                          // disabled={doctor.isAdded}
                        />
                        <label className='doc-name text-capitalize' onClick={() => handleCheckDoctor({target: {checked: !doctor.isAdded}}, doctor, index)}>{`Dr. ${doctor.label}`}</label>
                        <label className='ms-auto me-1 text-capitalize'><span className={`${doctor.status==="active"?"active":"inactive"} activity-badge`}><span className="dot">●</span>{doctor?.status === 'active'
                        ? translate('common.active', 'Active')
                        : translate('common.inactive', 'Inactive')}</span></label>
                      </li>
                    ))
                  ) : doctorisLoading ? (
                    <Loader />
                  ) : (
                    <p
                      style={{
                        textAlign: 'center',
                        border: 'none',
                        color: '#646c9a',
                        marginTop: 45,
                      }}
                    >
                      <FormattedMessage
                        id="departments.noDoctorsfound"
                        defaultMessage="No doctors found"
                      />
                      {Array.isArray(doctorList) && !doctorList.length ? 
                        <a 
                          className='no-options-link'
                          onClick={() => {
                            history.push({pathname: 'doctorslisting/adddoctor', state: {
                              label: department_name,
                              value: dataToEdit.dept_id,
                              isEditDepartment: true
                            }}
                            )}}>
                            . +Add New
                          </a> 
                      : null}
                    </p>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="listWrap">
              <div
                className="listBox bg"
                style={{
                  position: 'relative',
                }}
              >
                <h6 className='card-heading'>Selected Doctor(s)</h6>
                <div className="doctor-list-wrap">
                {doctors &&
                  doctors.map((doctor, index) => (
                    <p key={index + 1} className='selected-doctor text-capitalize'>
                      {"Dr. "}{doctor.label}
                      <ClearIcon
                        style={{
                          float: 'right',
                          fontSize: 19,
                          color: '#b1bccd',
                          marginLeft: 10,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleRemoveDoctor(doctor, index)}
                      />
                    </p>
                  ))}
                </div>
                {doctorisLoading && <Loader />}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <div className="border_btm"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getDepartmentData: (id, isloader) =>
    dispatch(FetchDepartmentData(id, isloader)),
  clearDepartment: () => dispatch(clearDepartmentData()),
});

const mapStateToProps = (state) => ({
  selectedDepartmentData: state.DepartmentReducer.selectedDepartmentData,
  doctorList: state.DepartmentReducer.doctorList,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDepartment);
