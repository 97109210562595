const getLocalStorageItem = (key) => {
  const item = localStorage.getItem(key);
  return item;
};

const setLocalStorageItem = (key, value) => {
  localStorage.setItem(key, value);
};

const removeLocalStorageItem = (key) => {
  localStorage.removeItem(key);
};

const clearLocalStorage = () => {
  localStorage.clear();
};

export {
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem,
  clearLocalStorage,
};
