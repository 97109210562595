import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import AddIcon from 'Assets/img/icons/add-icon.svg';
import CloseIcon from 'Assets/img/icons/close-icon-2.svg';
import Refresh from 'Assets/img/icons/refresh-2.svg';
import { SystemSettingsApi } from 'Utils/Api/SystemSettingsApi'
import { translate } from 'Language/Translate';
import { showToaster, ToasterTypes } from 'Actions/toasterAction';
import { useDispatch } from 'react-redux';
import { REMINDER_FREQ_UNIT, REMINDER_NOTIFICATION_CHANNEL } from 'Constants/constant';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { Tooltip } from '@mui/material';

const generateCount = (count, diff) => {
    return new Array(count).fill().map((e, i) => {
        const val = i + 1
        if (diff) {
            return { label: val * diff, value: val * diff }
        }
        return { label: val, value: val }
    });
}


const maxCount = 5
const ReminderItem = ({ item, reminderDetails, lastOne = false }) => {

    const { key, values, errors } = item
    const { reminderValues, setReminderValues, duplicatedRow, emptyFieldCheck } = reminderDetails
    const dispatch = useDispatch();

    const constants = {
        notification_channel: REMINDER_NOTIFICATION_CHANNEL,
        freequency_unit: REMINDER_FREQ_UNIT,
        freequency_value: {
            minute: generateCount(4, 15),
            hour: generateCount(12),
            day: generateCount(6),
            week: generateCount(4),
        },
    }


    const handleSelectNew = (e, field) => {
        setReminderValues((prev) => {
            const preVal = [...prev]
            return duplicatedRow(handleSelectChange(preVal, e, field))
        })
    }

    const handleSelectChange = (arr ,e, field) =>{
        return arr.map((item) => {
            if (item.key === key) {
                if (field === 'freequency_unit' &&
                    !constants['freequency_value'][e.value].find(x => x.value === item?.values?.freequency_value)) {
                    return {
                        ...item,
                        values: {
                            ...item.values,
                            [field]: e.value,
                            freequency_value: ''
                        },
                        errors: {
                            ...item.errors,
                            [field]: false,
                            freequency_value: item?.errors?.required
                        }
                    }
                }
                return {
                    ...item,
                    values: {
                        ...item.values,
                        [field]: e.value
                    },
                    errors: {
                        ...item.errors,
                        [field]: false,
                    }
                }
            }
            return item
        })
    } 



    const handleAddClick = () => {
        if (reminderValues.length < maxCount) {
            const { values } = reminderValues.at(-1)
            if (values?.['notification_channel'] && values?.['freequency_unit'] && values?.['freequency_value']) {
                setReminderValues((prev) => {
                    const copyPrev = [...prev, {
                        key: uuidv4(), values: { notification_channel: '', freequency_value: '', freequency_unit: '' }, errors: {
                            notification_channel: false,
                            freequency_value: false,
                            freequency_unit: false,
                            duplicatedEntry: '',
                            required: ''
                        }
                    }]
                    return copyPrev
                })
            }
            else {
                setReminderValues((prev) => {
                    const copyPrev = [...prev]
                    return emptyFieldCheck(copyPrev, true)
                })
            }
        } else {
            dispatch(showToaster(<FormattedMessage
                id="settings.ReminderlimitExceeded"
                defaultMessage="Reminder limit exceeded as per your plan"
            />, ToasterTypes.Warning))
        }
    }

    const handleRemoveClick = (key) => {
        setReminderValues((prev) => {
            let prevCopy = [...prev].filter(item => item.key !== key);
            if (prevCopy?.length === 0) {
                prevCopy = [{
                    key: uuidv4(), values: { notification_channel: '', freequency_value: '', freequency_unit: '' }, errors: {
                        notification_channel: false,
                        freequency_value: false,
                        freequency_unit: false,
                        duplicatedEntry: '',
                        required: ''
                    }
                }]
            }
            return duplicatedRow(prevCopy)
        })
    }


    return (
        <div className="row">
            <div className="col-lg-4 col-md-4">
                <div className="form-group mb-4">
                    <ReactSelectDropDown
                        value={values?.['notification_channel'] ? { label: values?.['notification_channel'], value: values?.['notification_channel'] } : ''}
                        options={constants['notification_channel']}
                        onInputChange={(e) => handleSelectNew(e, 'notification_channel')}
                        validationErr={errors?.['duplicatedEntry'] || errors?.['notification_channel']}
                    />
                    {errors?.['required'] &&  errors?.['notification_channel'] && (
                        <div className="errmsg-slottime-required">
                            <p
                                className="fs-11 fw-500"
                                style={{
                                    color: 'red',
                                    textAlign: 'ceneter',
                                }}
                            >
                                {errors?.['required'] ? translate('common.required', 'This field is required') : ''}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className="col-lg-8 col-md-8">
                <div className="row">
                    <div className="col-lg-3 col-md-5">
                        <div className="form-group mb-4">
                            <ReactSelectDropDown
                                value={values?.['freequency_value'] ? { label: values?.['freequency_value'], value: values?.['freequency_value'] } : ''}
                                options={constants['freequency_value'][values?.['freequency_unit']] || constants['freequency_value']['minute']}
                                onInputChange={(e) => handleSelectNew(e, 'freequency_value')}
                                validationErr={errors?.['duplicatedEntry'] || errors?.['freequency_value']}
                            />
                            {errors?.['required'] &&  errors?.['freequency_value'] && (
                                <div className="errmsg-slottime-required">
                                    <p
                                        className="fs-11 fw-500"
                                        style={{
                                            color: 'red',
                                            textAlign: 'ceneter',
                                        }}
                                    >
                                        {errors?.['required'] ? translate('common.required', 'This field is required') : ''}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-5">
                        <ReactSelectDropDown
                            value={values?.['freequency_unit'] ? { label: REMINDER_FREQ_UNIT.find(x => x.value === values?.['freequency_unit'])?.label, value: values?.['freequency_unit'] } : ''}
                            options={constants['freequency_unit']}
                            onInputChange={(e) => handleSelectNew(e, 'freequency_unit')}
                            validationErr={errors?.['duplicatedEntry'] || errors?.['freequency_unit']}
                        />
                        {errors?.['required'] && errors?.['freequency_unit'] && (
                            <div className="errmsg-slottime-required">
                                <p
                                    className="fs-11 fw-500"
                                    style={{
                                        color: 'red',
                                        textAlign: 'ceneter',
                                    }}
                                >
                                    {errors?.['required'] ? translate('common.required', 'This field is required') : ''}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="col-lg-2 col-md-2 ps-0 field-height ps-0-mob action-icons">
                        {reminderValues.length === 1 ?
                        <Tooltip placement='top' title='Reset'>
                            <span className='add-close-icon'>
                                <img className='action-icon v1 refresh-icon'
                                    src={Refresh}
                                    style={{
                                        color: '#000',
                                        fontSize: 20,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleRemoveClick(key)}
                                    tabIndex="0"
                                    onKeyDown={(e)=>{
                                        if(e.key === "Enter" && !e.shiftKey){
                                            e.preventDefault()
                                            handleRemoveClick(key)
                                        }
                                    }}
                                />
                            </span>
                        </Tooltip>
                             :
                        <Tooltip placement='top' title='Remove'>
                            <span className='add-close-icon'>
                            <img className='action-icon v2 add-close-icon'
                                src={CloseIcon}
                                style={{
                                    color: '#999999',
                                    fontSize: 20,
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleRemoveClick(key)}
                                tabIndex='0'
                                onKeyDown={(e)=>{
                                    if(e.key === "Enter" && !e.shiftKey){
                                        e.preventDefault()
                                        handleRemoveClick(key)
                                    }
                                }}
                            />
                            </span>
                        </Tooltip>
                        }
                        {
                            lastOne &&
                            <Tooltip placement='top' title='Add'>
                            <span className='add-close-icon'>
                            <img className='action-icon v3 add-close-icon'
                                src={AddIcon}
                                style={{
                                    color: '#999999',
                                    fontSize: 20,
                                    cursor: 'pointer',
                                }}
                                onClick={handleAddClick}
                                tabIndex='0'
                                onKeyDown={(e)=>{
                                    if(e.key === "Enter" && !e.shiftKey){
                                        e.preventDefault()
                                        handleAddClick()
                                    }
                                }}
                            />
                            </span>
                            </Tooltip>
                        }

                    </div>
                </div>
            </div>
            {errors?.['duplicatedEntry'] && (
                <div className="errmsg-slottime">
                    <p
                        className="fs-11 fw-500"
                        style={{
                            color: 'red',
                            textAlign: 'ceneter',
                        }}
                    >
                        {errors?.['duplicatedEntry'] ? translate('settings.duplicateEntry', 'Duplicated entry') : ''}
                    </p>
                </div>
            )}
        </div>
    )
}


function ReminderSettings({ reminderDetails,setReminderChanges,setChanges }) {
     const [oldReminderValue,setOldRemValue]=useState('')
    const { reminderValues, setReminderValues, resetCount } = reminderDetails
    useEffect(() => {
        SystemSettingsApi.getReminderDetails().then(({ error, response }) => {
            if (error) {
                console.log(error);
                return
            }
            if (response?.notification_timing) {
                const remArray = response?.notification_timing.map(item => ({
                    key: uuidv4(), values: { ...item },
                     errors: {
                        notification_channel: false,
                        freequency_value: false,
                        freequency_unit: false,
                        duplicatedEntry: '',
                        required: ''
                    }
                }))
                setOldRemValue(remArray)
                setReminderValues(remArray)
            } else {
                setOldRemValue([{}])
                setReminderValues([{}])
            }
        })
    }, [resetCount])

    useEffect(() => {
        reminderValues.map((item, i) => {
            setChanges(false)
            if (!_.isEqual(oldReminderValue[i]?.values, reminderValues[i]?.values)) {
                setReminderChanges(false)
            }
            else {
                setReminderChanges(true)
            }
        })
    }, [reminderValues])

    return (
        <div className="row mt-3">
            <div className="col-lg-4 col-md-4">
                <div className="form-group mb-0">
                    <label htmlFor="Reminder type">
                        <FormattedMessage
                            id="settings.reminderType"
                            defaultMessage="Reminder type"
                        />
                        {/* <span className="sup"> *</span> */}
                    </label>
                </div>
            </div>
            <div className="col-lg-8 col-md-8 ps-2">
                <div className="form-group mb-0">
                    <label htmlFor="Remind before">
                        <FormattedMessage
                            id="settings.remindBefore"
                            defaultMessage="Remind before"
                        />
                        {/* <span className="sup"> *</span> */}
                    </label>
                </div>
            </div>
            {reminderValues.map((item, i) => {
                return <ReminderItem
                    key={i}
                    item={item}
                    index={i}
                    reminderDetails={reminderDetails}
                    lastOne={i === reminderValues?.length - 1 ? true : false}
                />
            })}
        </div>)
}

export default ReminderSettings