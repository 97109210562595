/* eslint-disable react/no-unknown-property */
import React from 'react';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const VisibilityIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.50007 9.73757C6.26257 9.73757 5.26257 8.73757 5.26257 7.50007C5.26257 6.26257 6.26257 5.26257 7.50007 5.26257C8.73757 5.26257 9.73757 6.26257 9.73757 7.50007C9.73757 8.73757 8.73757 9.73757 7.50007 9.73757Z" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.1938 9.1187C13.7563 8.23745 13.7563 6.7562 13.1938 5.87495C11.7625 3.62495 9.70627 2.32495 7.50002 2.32495C5.29377 2.32495 3.23752 3.62495 1.80627 5.87495C1.24377 6.7562 1.24377 8.23745 1.80627 9.1187C3.23752 11.3687 5.29377 12.6687 7.50002 12.6687C9.70627 12.6687 11.7625 11.3687 13.1938 9.1187Z" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const VisibilityOffIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.91882 9.08126C5.51257 8.67501 5.26257 8.11876 5.26257 7.50001C5.26257 6.26251 6.26257 5.26251 7.50007 5.26251C8.11882 5.26251 8.67507 5.51251 9.08132 5.91876L5.91882 9.08126Z" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.1375 3.60624C10.0438 2.78124 8.79377 2.33124 7.50002 2.33124C5.29377 2.33124 3.23752 3.63124 1.80627 5.88124C1.24377 6.76249 1.24377 8.24374 1.80627 9.12499C2.30002 9.89999 2.87502 10.5687 3.50002 11.1062" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.26257 12.2063C5.97507 12.5063 6.73132 12.6688 7.50007 12.6688C9.70632 12.6688 11.7626 11.3688 13.1938 9.1188C13.7563 8.23755 13.7563 6.7563 13.1938 5.87505C12.9876 5.55005 12.7626 5.2438 12.5313 4.9563" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.69365 7.93747C9.53115 8.81872 8.8124 9.53747 7.93115 9.69997" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.91875 9.08127L1.25 13.75" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.75 1.25L9.0813 5.91875" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


const PasswordInput = ({
  showPass,
  setShowPass,
  showEye,
  setShowEye,
  password,
  handlePasswordChange,
  name,
  id,
  top,
  validationErr = false,
  placeholder=''
}) => {
  const handleOnFocus = () => {
    const otherKeys = Object.keys(showEye).filter((key) => key !== id);
    const newObj = {
      [id]: true,
      ...Object.fromEntries(otherKeys.map((key) => [key, false])),
    };
    setShowEye(newObj);
    setShowPass({
      current: false,
      newPass: false,
      confirmPass: false,
    });
  };
  return (
    <>
      <input
        type={showPass[id] ? 'text' : 'password'}
        className="form-control"
        id="userid"
        value={password}
        name={name}
        maxLength="20"
        onChange={handlePasswordChange}
        onFocus={handleOnFocus}
        style={{ borderColor: validationErr && 'red' }}
        placeholder={placeholder}
      />
      {showEye[id] && (
        <span
          className="eye-show-password "
          style={{ top: top || 30 }}
          onClick={() =>
            setShowPass((prev) => ({
              ...prev,
              [id]: !showPass[id],
            }))
          }
        >
          {showPass[id] ? VisibilityOffIcon : VisibilityIcon}
        </span>
      )}
    </>
  );
};

export default PasswordInput;
