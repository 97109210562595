import network from '../Utils/Api/Network';
import { showToaster, ToasterTypes } from './toasterAction';

export const APPOINTMENT_PATIENT_DETAILS = 'APPOINTMENT_PATIENT_DETAILS';
export const RECEIVE_APPOINTMENT_LIST_STATUS =
  'RECEIVE_APPOINTMENT_LIST_STATUS';
export const RECEIVE_PRESCRIPTIONS_LIST = 'RECEIVE_PRESCRIPTIONS_LIST';
export const CLEAR_PRESCRIPTIONS_LIST = 'CLEAR_PRESCRIPTIONS_LIST';
export const ADD_FILTER_PRESCRIPTIONS = 'ADD_FILTER_PRESCRIPTIONS';

const receiveAppointmentDetails = (payload) => ({
  type: APPOINTMENT_PATIENT_DETAILS,
  payload,
});

const receiveAppointmentList = (payload) => ({
  type: RECEIVE_APPOINTMENT_LIST_STATUS,
  payload,
});

const receivePrescriptiosList = (payload) => ({
  type: RECEIVE_PRESCRIPTIONS_LIST,
  payload,
});

export const clearPrescriptiosList = () => ({
  type: CLEAR_PRESCRIPTIONS_LIST,
});

export const addFilterPrescriptions = (payload) => ({
  type: ADD_FILTER_PRESCRIPTIONS,
  payload,
});

export const FetchAppointmentDetails = (id, setLoader=()=>{}) => {
  return (dispatch) => {
    return network
      .get({ url: `/appointment/${id}?other_records=include` })
      .then((response) => {
        const { isError, result } = response;
        if (isError) {
          setLoader(false)
          dispatch(receiveAppointmentDetails({}));
        } else {
          setLoader(false)
          dispatch(receiveAppointmentDetails(result));
        }
      });
  };
};

export const FetchAppointmentListWithStatus = (data,setLoader=()=>{}) => {
  return (dispatch) => {
    return network.get({ url: '/appointments', data }).then((response) => {
      const { isError, result } = response;
      if (!isError && result?.appointments) {
        setLoader(false)
        dispatch(receiveAppointmentList(result));
      } else {
        setLoader(false)
        dispatch(receiveAppointmentList({}));
      }
    });
  };
};

export const uploadPrescription = (
  id,
  data,
  setSaveFile = () => {},
  setLoading = () => {},
  setSuccess=()=>{}
) => {
  return (dispatch) => {
    return network
      .put({ url: `/appointment/${id}/complete`, data })
      .then((response) => {
        const { isError, message } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
          setLoading(false);
        } else {
          setSaveFile(true);
          setSuccess()
          dispatch(showToaster(message, ToasterTypes.Success));
          dispatch(FetchAppointmentDetails(id, setLoading));
        }
      });
  };
};

export const FetchPrescriptionsList = (data, setIsLoading=()=>{}) => {
  return (dispatch) => {
    return network
      .get({ url: '/patient/prescriptions', data })
      .then((response) => {
        const { isError, result } = response;
        if (!isError && result?.prescriptions) {
          dispatch(receivePrescriptiosList(result));
          setIsLoading(false)
        } else {
          dispatch(receivePrescriptiosList({}));
          setIsLoading(false)
        }
      });
  };
};
