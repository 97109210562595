/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from "react";
import ReactSelectDropDown from "../Common/ReactSelectDropDown";
import PhoneNumberInput from "../Common/PhoneInput";
import MultipleSelect from "../Common/MultipleSelect";
import { translate } from "../../Language/Translate";
import { USER_TYPE, USER_TYPE_CREATE_OPTIONS } from "../../Constants/userTypes";
import { ALL_VALUE } from "../../Constants/constant";
import { Tooltip } from "@mui/material";
import {
  validateEmail,
  validateMobile,
} from "../../Utils/Validation/validator";

const addIcon = (
  <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.9706 24.9706C6.34316 24.9706 0.97058 19.598 0.97058 12.9706C0.97058 6.34316 6.34316 0.97058 12.9706 0.970581C19.598 0.970581 24.9706 6.34316 24.9706 12.9706C24.9706 19.598 19.598 24.9706 12.9706 24.9706Z" stroke="#919DAC" stroke-width="1.75"/>
  <path d="M12.969 17.6353V8.30195" stroke="#919DAC" stroke-width="1.75" stroke-linecap="round"/>
  <path d="M8.30234 12.9693L17.6357 12.9693" stroke="#919DAC" stroke-width="1.75" stroke-linecap="round"/>
  </svg>
);

const closeIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
      stroke="#919DAC"
      stroke-width="1.5"
    />
    <path
      d="M12.4746 7.52502L7.52486 12.4748"
      stroke="#919DAC"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M12.4746 12.4747L7.52486 7.52498"
      stroke="#919DAC"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);

const AddAppointments = (props) => {
  const {
    // setRoleType,
    updateCountryCode,
    updateMobile,
    fname,
    updateFname,
    lname,
    updateLname,
    email,
    updateEmail,
    country,
    mobile,
    departments,
    selectedDept,
    setSelectDept,
    selectedDocValue,
    setSelectDocValue,
    setSelectRoleType,
    selectedRoleType,
    errorState,
    isEditing,
    getDepartmentData,
    doctorsOption,
    setDocOptions,
    userData,
    clearUserDetails,
    setError,
    doctorLoader,
    numberLength,
    setNumberLength,
    code,
    setCode,
  } = props;

  const {
    nameError,
    emailError,
    phoneError,
    countryError,
    typeError,
    invalidMail,
    invalidPhone,
    docError,
    deptError,
  } = errorState || {};

  const [deptOptions, setDeptOptions] = useState(departments);
  const [roleLoading, setRoleLoading] = useState(false);
  const numField = useRef();
  const focusRef = useRef();
  const roleRef = useRef();
  const selectDeptref = useRef([])
  const firstNameRef = useRef();
  useEffect(() => {
    setDeptOptions(departments);
  }, [departments]);

  useEffect(() => {
    if (isEditing) setRoleLoading(true);
    if (userData?.user_roles) {
      const typelist = userData.user_roles
        .filter((item) => item !== "patient" && item !== "subscription-owner")
        .map((item) => {
          return { label: item, value: item };
        });
      setSelectRoleType(typelist);
      if (isEditing) setRoleLoading(false);
    }
    if (userData?.user_dept_doctor_acl) {
      let docList = [];
      const deptList = userData.user_dept_doctor_acl.map((dept, index) => {
        const doctors = [];
        dept?.doctors?.forEach((doc) => {
          doc.doctor_id === ALL_VALUE.value
            ? doctors.push(ALL_VALUE)
            : doctors.push({
                label: `Dr. ${doc.name} ${doc.last_name}`,
                value: doc.doctor_id,
              });
        });
        docList.push(doctors);
        getDepartmentData(dept.dept_id, index);
        return { label: dept.dept_name, value: dept.dept_id };
      });
      const filterdDept = departments.filter(
        (item) => !deptList.some((dept) => dept.value === item.value)
      );

      setDeptOptions(filterdDept);
      setSelectDept(deptList);
      setSelectDocValue(docList);
    }
    // return () => {clearUserDetails()};
  }, [userData, departments]);

  useEffect(() => {
    return () => {
      clearUserDetails();
    };
  }, []);

  // handle user type change
  // const onInputChange = (option) => {
  //   setRoleType(option);
  //   setError((prev) => ({
  //     ...prev,
  //     typeError: false,
  //   }));
  // };

  // handle country code
  const handleMobileNumberChange = (phone) => {
    updateCountryCode(phone);
  };
  const handleKeyDown = (e) => {
    const dropDownBtn = document.querySelector(".selected-flag");
    if (e.key === "Enter") {
      if (!dropDownBtn.classList.contains("open")) {
        e.preventDefault();
        dropDownBtn.classList.toggle("open");
        dropDownBtn.click();
      } else {
        numField.current?.focus();
      }
    }
    if (e.key === "Tab" && dropDownBtn.classList.contains("open")) {
      e.preventDefault();
      focusRef.current?.focus();
      dropDownBtn.click();
    }
  };

  // handle phone number chnange
  const numberChange = (e) => {
    const inputValue = e.target.value;
    // eslint-disable-next-line no-useless-escape
    // const regex = /^\+?([1-9]\d{0,2})\s*[-\.]?(?:\(?\d{3}\)?[-\.]?)?\d{3}\d{4}$/;
    const regex = /^\+?[0-9 ]*\.?[0-9 ]*$/;
    if (regex.test(inputValue)) {
      updateMobile(inputValue);
      setError((prev) => ({
        ...prev,
        phoneError: false,
      }));
      if (validateMobile(inputValue?.trim(), code) || !inputValue) {
        setError((prev) => ({
          ...prev,
          invalidPhone: false,
        }));
      }
    }
  };

  // handle department selection
  const handleDeptSelection = (value, index) => {
    const deptCopy = [...selectedDept];
    deptCopy[index] = value;
    setSelectDept(deptCopy);
    const filterdDept = departments.filter(
      (item) => !deptCopy.some((dept) => dept.value === item.value)
    );
    setDeptOptions(filterdDept);
    const docCopy = [...selectedDocValue];
    docCopy[index] = [];
    setSelectDocValue(docCopy);
    setError((prev) => ({
      ...prev,
      deptError: false,
    }));
    const docListCopy = [...doctorsOption];
    docListCopy[index] = [];
    setDocOptions(docListCopy);
    getDepartmentData(value.value, index);
  };

  // handle add button click
  const handleAddClick = () => {
    if (
      selectedDept[selectedDept.length - 1]?.value &&
      selectedDocValue[selectedDocValue.length - 1]?.length
    ) {
      const deptCopy = [...selectedDept, ""];
      setSelectDept(deptCopy);
      setSelectDocValue([...selectedDocValue, ""]);
        setTimeout(()=>{
          if(selectDeptref.current[deptCopy.length-1]){
            selectDeptref.current[deptCopy.length-1].focus()
          }
        },0)
    }
  };

  // handle close click
  const handleCloseClick = (index) => {
    const updatedDepartments = selectedDept.filter((_, i) => i !== index);
    const filterdDept = departments.filter(
      (item) => !updatedDepartments.some((dept) => dept.value === item.value)
    );
    const updatedDoctors = selectedDocValue.filter((_, i) => i !== index);
    setSelectDocValue(updatedDoctors);
    const updatedDoctorOptions = doctorsOption.filter((_, i) => i !== index);
    setDocOptions(updatedDoctorOptions);
    setDeptOptions(filterdDept);
    setSelectDept(updatedDepartments);
  };

  // handle doctor selection
  const handleDoctorSelection = (values, index) => {
    const docCopy = [...selectedDocValue];
    let isClear = false;

    // check whether the all option selected is removed then remove all ticked option
    if (
      docCopy[index]?.length &&
      docCopy[index].some((x) => x.value === ALL_VALUE.value) &&
      values?.length &&
      !values.some((x) => x.value === ALL_VALUE.value)
    ) {
      isClear = true;
    }

    docCopy[index] = isClear
      ? []
      : values[values.length - 1]?.value === ALL_VALUE.value || // if last select is all add only all value
        (values.length === doctorsOption[index]?.length - 1 &&
          values.every((x) => x.value !== ALL_VALUE.value)) // if all doctors are selected
      ? [ALL_VALUE]
      : values.filter((item) => item.value !== ALL_VALUE.value); // otherwise add all value except all
    setSelectDocValue(docCopy);
    setError((prev) => ({
      ...prev,
      docError: false,
    }));
  };
  // handle role type change
  const handleRoleChange = (values) => {
    setSelectRoleType(values);
    setError((prev) => ({
      ...prev,
      typeError: false,
    }));
    if (
      values &&
      !values.find((item) => item.value === USER_TYPE.appointmentCordinator)
    ) {
      setSelectDocValue([""]);
      setSelectDept([""]);
      setDeptOptions(departments);
    }
  };

  // handle Email change
  const handleEmail = (e) => {
    const { value } = e.target;
    updateEmail(value);
    setError((prev) => ({
      ...prev,
      emailError: false,
    }));
    if (validateEmail(value)) {
      setError((prev) => ({
        ...prev,
        invalidMail: false,
      }));
    }
  };

  return (
    <>
      <div
        className="container-main mb-3 add-doc-modal"
      >
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="User name">
                {translate("common.firstName", "First Name")}{" "}
                <span className="sup"> *</span>
              </label>
              {selectedRoleType?.find(
              (item) => item.value === "doctor"
            )?<div className="with-prefix">
              <div className="prefix">Dr.</div>
              <input
                maxLength={40}
                value={fname}
                onChange={(e) => {
                  updateFname(e.target.value);
                  setError((prev) => ({
                    ...prev,
                    nameError: false,
                  }));
                }}
                type="text"
                className="form-control"
                style={{ borderColor: nameError && "red" }}
                id="fname"
                placeholder="i.e, John"
                ref={firstNameRef}
                onFocusCapture={() => {
                  setTimeout(() => {
                    firstNameRef?.current?.setSelectionRange(
                      fname?.length,
                      fname?.length
                    );
                  }, 0);
                }}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Tab" && e.shiftKey) e.preventDefault();
                }}
              />
              </div>:<input
                maxLength={40}
                value={fname}
                onChange={(e) => {
                  updateFname(e.target.value);
                  setError((prev) => ({
                    ...prev,
                    nameError: false,
                  }));
                }}
                type="text"
                className="form-control"
                style={{ borderColor: nameError && "red" }}
                id="fname"
                placeholder="i.e, John"
                ref={firstNameRef}
                onFocusCapture={() => {
                  setTimeout(() => {
                    firstNameRef?.current?.setSelectionRange(
                      fname?.length,
                      fname?.length
                    );
                  }, 0);
                }}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Tab" && e.shiftKey) e.preventDefault();
                }}
              />}
              {nameError ? (
                <p className="mt-1 fs-10 fw-500" style={{ color: "red" }}>
                  {translate("common.required", "This field is required")}
                </p>
              ) : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="gender">
                {translate("common.lastName", "Last Name")}
              </label>
              <input
                maxLength={40}
                value={lname}
                onChange={(e) => updateLname(e.target.value)}
                type="text"
                className="form-control"
                id="lname"
                placeholder="i.e, Doe"
                onKeyDown={(e) => {
                  if (isEditing && e.key === "Tab" && !e.shiftKey) {
                    e.preventDefault();
                    roleRef.current.focus();
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="User name">
                {translate("common.emailId", "Email Address")}{" "}
                <span className="sup"> *</span>
              </label>
              <input
                maxLength={50}
                value={email}
                onChange={handleEmail}
                type="text"
                className="form-control"
                style={{ borderColor: emailError ? "red" : invalidMail ? "red" : "" }}
                id="email"
                disabled={isEditing}
                placeholder="i.e, johndoe@gmail.com"
              />
              {emailError ? (
                <p className="mt-1 fs-10 fw-500" style={{ color: "red" }}>
                  {translate("common.required", "This field is required")}
                </p>
              ) : null}
              {invalidMail ? (
                <p className="mt-1 fs-10 fw-500" style={{ color: "red" }}>
                  {translate("users.invalidMail", "Invalid Email id")}
                </p>
              ) : null}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="doc-labels">
                {translate("common.phoneNumber", "Mobile")}{" "}
                <span className="sup"> *</span>
              </label>
              <div className="d-flex gap-3 user-countrycode">
                <div
                  className={`${countryError && "error"}`}
                  onKeyDown={(e) => {
                    if (e.key === "Tab" && !e.shiftKey) {
                      e.preventDefault();
                      numField.current.focus();
                    }
                    handleKeyDown(e);
                  }}
                >
                  <PhoneNumberInput
                    value={country}
                    onChange={handleMobileNumberChange}
                    editStatus={isEditing}
                    validationErr={countryError}
                    numberLength={numberLength}
                    setNumberLength={setNumberLength}
                    focusRef={focusRef}
                    code={code}
                    setCode={setCode}
                    // onFocus={(e) => e.target.blur()}
                  />
                </div>
                <div className="w-100">
                <input
                  type="text"
                  maxLength={numberLength}
                  style={{
                    borderColor: (phoneError || invalidPhone) && "red",
                  }}
                  className="form-control"
                  onChange={numberChange}
                  value={mobile}
                  disabled={isEditing}
                  ref={numField}
                  onKeyDown={(e) => {
                    if (e.shiftKey && e.key === "Tab") {
                      e.preventDefault();
                      focusRef.current.focus();
                    }
                  }}
                  placeholder="i.e, 9788355344"
                />
              {phoneError || countryError ? (
                <p className="mt-1 fs-10 fw-500" style={{ color: "red" }}>
                  {translate("common.required", "This field is required")}
                </p>
              ) : null}
              {invalidPhone ? (
                <p className="mt-1 fs-10 fw-500" style={{ color: "red" }}>
                  {translate("users.invalidPhone", "Invalid phone number")}
                </p>
              ) : null}
                </div>  
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group custom-dropdown">
              <label htmlFor="User name">
                {translate("users.roletype", "Role Type")}{" "}
                <span className="sup"> *</span>
              </label>
              <div className="add-doc-multi-select">
                <MultipleSelect
                  value={USER_TYPE_CREATE_OPTIONS
                    ?.sort((a, b) => {
                      if(selectedRoleType.length){
                        const indexA = selectedRoleType?.findIndex(item => item?.value === a.value)
                        const indexB = selectedRoleType?.findIndex(item => item?.value === b.value)
                        if (indexA !== -1 && indexB !== -1) return indexA - indexB;
                        if (indexA !== -1) return -1
                        if (indexB !== -1) return 1
                      }
                      return 0;
                    })
                  }
                  selectedDeptValue={selectedRoleType}
                  setSelectDeptValue={(values) => handleRoleChange(values)}
                  custom={typeError}
                  isLoading={roleLoading}
                  maxToShow={
                    selectedRoleType?.[0]?.value ===
                      USER_TYPE.appointmentCordinator ||
                    selectedRoleType?.[1]?.value ===
                      USER_TYPE.appointmentCordinator
                      ? 1
                      : 2
                  }
                  reference={roleRef}
                />
              </div>
              {typeError ? (
                <p className="mt-1 fs-10 fw-500" style={{ color: "red" }}>
                  {translate("common.required", "This field is required")}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        {selectedRoleType?.find(
          (item) => item.value === USER_TYPE.appointmentCordinator
        )
          ? selectedDept.map((x, index) => (
              <div key={index} className="row mb-3">
                <div className="col-lg-6">
                  <div className="form-group no-formgroup mb-3 mb-md-0" style={{ marginBottom: "0px" }}>
                    {index === 0 && (
                      <label htmlFor="User name">
                        {translate("users.selectdept", "Select Department")}
                        <span className="sup"> *</span>
                      </label>
                    )}
                    <ReactSelectDropDown
                      options={deptOptions}
                      value={selectedDept[index]}
                      onInputChange={(value) =>
                        handleDeptSelection(value, index)
                      }
                      validationErr={
                        selectedDept[index] == "" ? deptError : null
                      }
                      reference={(ref)=>selectDeptref.current[index]=ref}
                    />
                    {selectedDept[index] == "" && deptError ? (
                      <p className="mt-1 fs-10 fw-500" style={{ color: "red" }}>
                        {translate("common.required", "This field is required")}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 multi-select-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                    }}
                  >
                    <div
                      className="form-group add-doc-mutiselect"
                    >
                      {index === 0 && (
                        <label htmlFor="User name">
                          {translate("users.selectdoc", "Select Doctor(s)")}
                          <span className="sup"> *</span>
                        </label>
                      )}
                      <div className="add-doc-multi-select">
                        <MultipleSelect
                          value={doctorsOption[index]?.sort((a, b) => {
                          if (selectedDocValue[index]) {
                            const indexA = selectedDocValue[index]?.findIndex(item => item.value === a.value)
                            const indexB = selectedDocValue[index]?.findIndex(item => item.value === b.value)
                            if (b?.value === "all") {
                              return 
                            }
                            if (indexA !== -1 && indexB !== -1) return indexA - indexB;
                            if (indexA !== -1) return -1;
                            if (indexB !== -1) return 1;
                          }
                          return 0;
                        })
                        }
                          selectedDeptValue={selectedDocValue[index]}
                          setSelectDeptValue={(values, options) =>
                            handleDoctorSelection(values, index, options)
                          }
                          isDisabled={!selectedDocValue[index]}
                          isLoading={doctorLoader[index]}
                          custom={
                            selectedDocValue[index]?.length == 0
                              ? docError
                              : false
                          }
                        />
                      </div>
                      {selectedDocValue[index]?.length == 0 && docError ? (
                        <p
                          className="mt-1 fs-10 fw-500 err-msg"
                          style={{ color: "red" }}
                        >
                          {translate(
                            "common.required",
                            "This field is required"
                          )}
                        </p>
                      ) : null}
                    </div>
                    <div className="res-close">
                      <Tooltip
                        title={translate(
                          "users.removeDept",
                          "Remove Department"
                        )}
                        placement={"top"}
                      >
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            handleCloseClick(index);
                          }}
                          style={{
                            display:
                              // index === selectedDept?.length - 1 &&
                              // deptOptions?.length != 0 &&
                              selectedDept.length != 1 ? "block" : "none",
                          }}
                        >
                          {closeIcon}
                        </span>
                      </Tooltip>
                      <Tooltip
                        title={translate("users.addDept", "Add Department")}
                        placement={"top"}
                      >
                        <span
                          className="cursor-pointer"
                          style={{
                            visibility:
                              index === selectedDept?.length - 1 &&
                              deptOptions?.length != 0 &&
                              selectedDocValue &&
                              selectedDocValue[index] &&
                              selectedDocValue[index]?.length != 0
                                ? "visible"
                                : "hidden",
                          }}
                          onClick={handleAddClick}
                        >
                          {addIcon}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </>
  );
};
export default AddAppointments;
