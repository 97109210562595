import {
  CLEAR_DEPARTMENT_DATA,
  CLEAR_SELECTED_DEPARMENT,
  RECEIVE_DEPARTMENT_DATA,
  RECEIVE_DEPARTMENT_SUGGESTION_LIST,
  RECEIVE_DOCTOR_LIST,
  UPDATE_DEPART_SEARCH_DATA,
  CLEAR_DEPARTMENT_DATA_EXCEPT_FILTER,
  SET_DEPARTMENT_PER_PAGE,
  SET_DEPARTMENT_CURRENT_PAGE,
  SET_DEPARTMENT_COLUMN_SORT
} from '../Actions/departmentAction';

const initialState = {
  departmentSuggestionList: [],
  selectedDepartmentData: {},
  doctorList: [],
  searchData:{},
  paginationData:{
    perPage:25,
    currentPage:1
  },
  columnSort:{}
};

const DepartmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_DEPARTMENT_SUGGESTION_LIST: {
      let deptOptions = [];
      if (Array.isArray(action.payload)) {
        deptOptions = action.payload.map((item) => ({
          label: item.dept_name,
          value: item.dept_name,
        }));
      }
      return {
        ...state,
        departmentSuggestionList: deptOptions,
      };
    }
    case RECEIVE_DEPARTMENT_DATA: {
      return {
        ...state,
        selectedDepartmentData: action.payload,
      };
    }
    case CLEAR_SELECTED_DEPARMENT: {
      return {
        ...state,
        selectedDepartmentData: {},
      };
    }
    case RECEIVE_DOCTOR_LIST: {
      return {
        ...state,
        doctorList: action.payload,
      };
    }
    case CLEAR_DEPARTMENT_DATA: {
      return initialState;
    }
    case UPDATE_DEPART_SEARCH_DATA: {
      return {...state,
        searchData:action.payload}
    }
    case CLEAR_DEPARTMENT_DATA_EXCEPT_FILTER: {
      return {...initialState,
        searchData:state.searchData,
        paginationData:state.paginationData
      }
    }
    case SET_DEPARTMENT_PER_PAGE: {
      return {...state,
        paginationData:{
          ...state.paginationData,
          perPage:action.payload
        } 
      }
    }
    case SET_DEPARTMENT_CURRENT_PAGE: {
      return {...state,
        paginationData:{
          ...state.paginationData,
          currentPage:action.payload
        } 
      }
    }
    case SET_DEPARTMENT_COLUMN_SORT: {
      return {
        ...state,
        columnSort: action.payload,
      };
    }
    default:
      return state;
  }
};

export default DepartmentReducer;
