import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { DOCTOR_SPECIALITY } from '../../../Constants/constant';
import { translate } from '../../../Language/Translate';
import network from '../../../Utils/Api/Network';
import './style/landing_page.css';
import { getLocalStorageItem, setLocalStorageItem } from '../../../Utils/LocalStorage/local-storage';
import { providerLogout } from '../../../Actions/loginAction';
import moment from 'moment';
import AntDatepicker from 'Components/Common/AntdDatePicker';
import loadingImg from '../../../Assets/img/icons/search-loader.gif';
import { timeZoneDate } from 'Utils/DateFormatter/Date';
import { DoctorsApi } from 'Utils/Api/DoctorsApi';

let apiTimeout = null;

const HomePage = (props) => {
  const { history, logoutUser, applicationData } = props;
  const inputRef = useRef()
  // const dispatch = useDispatch();
  const intl = useIntl();
  const [search, setSearch] = useState('');
  const [selectedValue, setSelectedValue] = useState({});
  const [date, setDate] = useState(dayjs());
  const [suggestionList, setSuggestionList] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [selectErr, setSelectErr] = useState(false);
  const [invalidErr,setInvalidErr]=useState(false)
  const [dateErr, setDateErr] = useState(false);
  const [docLoader,setDocLoader] = useState({}); // showing loader for search doctor / department
  const [valueDate,setValueDate] = useState(false)
  const disabledDates = (current) => {
    return (
      (current && current.isBefore(moment().subtract(1, 'day'))) ||
      current.isAfter(
        moment().add(applicationData?.booking_window_max_days - 1, 'day')
      )
    );
  };

  // load options to the dropdown on typing
  const loadOptions = async (inputValue) => {
    let fetchedOptions = [];
    if (inputValue?.trim()) {
      let id = new Date().getTime()
      setDocLoader((prev)=>({
        ...prev,
        [id]:true
      }));
      const config = {
        doc_with_dept: true,
        status: 'active',
        limit: 0
      }
      await network
        .get({ url: `/search/department-doctor/${inputValue}`, data: config })
        .then((response) => {
          setDocLoader((prev)=>({
            ...prev,
            [id]:false
          }));
          const { isError, result } = response;
          if (isError) {
            return;
          } else if (!result?.search_result) {
            fetchedOptions = [
              {
                value: '',
                label: `${intl.formatMessage({
                  id: 'common.noData',
                  defaultMessage: 'No data found.',
                })}`,
                isDisabled: true,
              },
            ];
          } else {
            const options = result?.search_result.map((item) => ({
              label: `${item.type === 'doctor' ?  `Dr. ${item.name}` : item.name}`,
              value: item.id,
              type: item.type,
            }));
            fetchedOptions = options;
          }
        })
        .catch(() => {});
    }
    setSuggestionList(fetchedOptions);
  };
  useEffect(() => {
    localStorage.removeItem('deptType');
    localStorage.removeItem('deptName');
    localStorage.removeItem('deptLabel');
    localStorage.removeItem('deptDate');
    const token = getLocalStorageItem('access_token');
    const refreshTokenExp = getLocalStorageItem('refresh_token_exp');
    const currentDate = timeZoneDate();
    const refreshExpiryTime = new Date(refreshTokenExp * 1000);
    if (!token || (token && currentDate > refreshExpiryTime)) logoutUser();
  }, []);

  useEffect(()=>{
    DoctorsApi.getDoctorsList({
      search:'',
      limit:0,
      book_appointment:true,
      doc_with_dept:true,
      // status:'active',
    }).then(({response})=>{
      if(response?.doctors?.length === 1){
        const value = {
          label:`Dr. ${response?.doctors[0]?.name} ${response?.doctors[0]?.last_name}`,
          value:response?.doctors[0]?.doctor_id,
          type:'doctor',
        } 
        setSearch(value.label)
        setIsSelect(true);
        setSelectedValue(value)
      }
    })  
  },[])

  // api call with 3sec delay
  useEffect(() => {
    if (!isSelect) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadOptions(search);
      }, 300);
      apiTimeout = timeout;
    }
  }, [search, isSelect]);

  //handle search change
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
    setSelectErr(false);
    setInvalidErr(false)
    setIsSelect(false);
    setSelectedValue({});
  };

  // handle search selection
  const handleSelect = (value) => {
    setLocalStorageItem("availableDocId", value.value);
    if(!value.isDisabled){
    setSearch(value.label);
    setDocLoader({});
    setIsSelect(true);
    setSuggestionList([]);
    setSelectedValue(value);
    }
  };

  // handle date change
  const handleDateChange = (value) => {
    setDate(value);
    setDateErr(false);
  };

  //handle doctor search
  const handleDoctorSearch = () => {
    if (isSelect && !!date) {
      history.push({
        pathname: '/webConnect/bookAppointment',
        state: {
          label: selectedValue.label,
          search: selectedValue.value,
          type: selectedValue.type,
          date: date,
        },
      });
    } else {
      if (!date) setDateErr(true);
      if (!search) setSelectErr(true);
      if (search && !isSelect) setInvalidErr(true)
    }
  };

  return (
    <div className="landing-page">
      <div className="wrapper">
        <h1 className="main-text">
          <span className="color-primary">
            {translate('patientPortal.caption1', 'Empowering Health')},
          </span>{' '}
          {translate(
            'patientPortal.caption2',
            'Seamless Online Doctor Booking at Your Fingertips'
          )}
          !
        </h1>
        <p className="one-liner">
          {translate(
            'patientPortal.subCaption',
            'The easiest and fastest place to book a doctor online'
          )}
        </p>
      </div>
      <div className="wrapper">
        <div className="filter">
       
          <div className={`icon-field name ${selectErr ? 'custom-err' : ''} name loading v2`}>
            {search ? null :
            <label className='mandatory-inner-lable' htmlFor="nameSearch" style={{cursor: 'auto'}} onClick={() => inputRef.current.focus()}>
              {intl.formatMessage({
                  id: 'patientPortal.docOrSpec ',
                  defaultMessage: 'Doctor Name / Speciality ',
                })}
            </label>}
            <i className="bi bi-search icon"></i>
            <input
              className="input w-100"
              autoComplete="off"
              autoFocus
              type="search"
              id="name nameSearch"
              value={search}
              onChange={handleSearchChange}
              onBlur={() => setTimeout(() => setSuggestionList([]), 400)}
              ref={inputRef}
            />
            {search !== ''&& !isSelect  && Object.values(docLoader).includes(true) && (
              <img
                className="loading-img"
                src={loadingImg}
                alt={'loading image'}
              />
            )}
            {search !== "" && suggestionList.length && !isSelect && !Object.values(docLoader).includes(true) ? (
              <div className={`customSearchList ${suggestionList[0]?.isDisabled ? 'disabled' : ''
            }`}>
                <ul style={{ marginBottom: 1 }}>
                  {suggestionList.map((item, index) => (
                    <li
                      key={index}
                      className="suggestion-item"
                      onClick={() => handleSelect(item)}
                    >
                      {item.label}
                      <span style={{ float: 'right' }}>
                        {DOCTOR_SPECIALITY[item.type]}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {selectErr ? (
              <div className="err-box">
                {translate('patientPortal.addDetails', 'Please enter details.')}
              </div>
            ) : null}
            {invalidErr ? (
              <div className="err-box">
                {translate('patientPortal.selectDocOrSpeciality', 'please select a doctor or speciality.')}
              </div>
             ) : null}
          </div>
          <div className={`icon-field date ${dateErr ? 'custom-err' : ''}`} >
            {date || valueDate ? null :
              <label className='mandatory-inner-lable' htmlFor="nameSearch" style={{cursor: 'pointer'}} >
                 {translate('appointments.selectDate', 'Select Date')}
              </label>}
              <AntDatepicker
                titleValue={setValueDate}
                onInputChange={handleDateChange} 
                value={date}
                disabledDate={disabledDates}
                allowClearBtn={true}
              />
            {dateErr ? (
              <div className="err-box">
                {translate('patientPortal.addDate', 'Please select a date.')}
              </div>
            ) : null}
          </div>
          <button
            id="search"
            className="btn btn-primary"
            onClick={handleDoctorSearch}
          >
            {translate('patientPortal.search', 'Search')}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  applicationData: state.SettingsReducer.applicationData,
});
const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(providerLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
