import network from '../Utils/Api/Network';
import { showToaster, ToasterTypes } from './toasterAction';

export const RECEIVE_DEPARTMENT_DATA = 'RECEIVE_DEPARTMENT_DATA';
export const RECEIVE_DEPARTMENT_SUGGESTION_LIST =
  'RECEIVE_DEPARTMENT_SUGGESTION_LIST';
export const CLEAR_DEPARTMENT_DATA = 'CLEAR_DEPARTMENT_DATA';
export const RECEIVE_DOCTOR_LIST = 'RECEIVE_DOCTOR_LIST';
export const CLEAR_SELECTED_DEPARMENT = 'CLEAR_SELECTED_DEPARMENT';
export const UPDATE_DEPART_SEARCH_DATA = 'UPDATE_DEPART_SEARCH_DATA';
export const CLEAR_DEPARTMENT_DATA_EXCEPT_FILTER =
  'CLEAR_DEPARTMENT_DATA_EXCEPT_FILTER';
export const SET_DEPARTMENT_PER_PAGE = 'SET_DEPARTMENT_PER_PAGE';
export const SET_DEPARTMENT_CURRENT_PAGE = 'SET_DEPARTMENT_CURRENT_PAGE';
export const SET_DEPARTMENT_COLUMN_SORT = 'SET_DEPARTMENT_COLUMN_SORT';

const receiveDepartmentData = (payload) => ({
  type: RECEIVE_DEPARTMENT_DATA,
  payload,
});

const receiveDepartmentSuggestionList = (payload) => ({
  type: RECEIVE_DEPARTMENT_SUGGESTION_LIST,
  payload,
});

const receiveDoctorList = (payload) => ({
  type: RECEIVE_DOCTOR_LIST,
  payload,
});

export const cleraAllData = () => ({
  type: CLEAR_DEPARTMENT_DATA,
});

export const clearDepartmentData = () => {
  return {
    type: CLEAR_SELECTED_DEPARMENT,
  };
};
export const updateSearchData = (payload) => {
  return {
    type: UPDATE_DEPART_SEARCH_DATA,
    payload,
  };
};
export const clearDeptDataExceptFilter = () => {
  return {
    type: CLEAR_DEPARTMENT_DATA_EXCEPT_FILTER,
  };
};
export const updatePerPage = (payload) => {
  return {
    type: SET_DEPARTMENT_PER_PAGE,
    payload,
  };
};
export const updateCurrentPage = (payload) => {
  return {
    type: SET_DEPARTMENT_CURRENT_PAGE,
    payload,
  };
};
export const updateColumnSort = (payload) => {
  return {
    type: SET_DEPARTMENT_COLUMN_SORT,
    payload,
  };
};

export const FetchDoctorList = (status="active") => {
  return (dispatch) => {
    return network
      .get({
        url: '/doctors',
        data: { limit: 0, status: status, sort: 'name' },
      })
      .then((response) => {
        const { result, isError } = response || {};
        if (!isError && result.doctors) {
          dispatch(receiveDoctorList(result.doctors));
        } else {
          dispatch(receiveDoctorList([]));
        }
      });
  };
};

export const FetchDepartmentSuggestionList = () => {
  return (dispatch) => {
    return network.get({ url: '/suggestion/department' }).then((response) => {
      const { result, isError } = response || {};
      if (!isError && result.department_suggestions) {
        dispatch(
          receiveDepartmentSuggestionList(result.department_suggestions)
        );
      } else {
        dispatch(receiveDepartmentSuggestionList([]));
      }
    });
  };
};

export const FetchDepartmentData = (id, setLoading) => {
  return (dispatch) => {
    return network
      .get({
        url: `/department/${id}`,
      })
      .then((response) => {
        const { result, isError } = response || {};
        if (typeof setLoading === 'function') setLoading(false);
        if (!isError && result.department) {
          dispatch(receiveDepartmentData(result));
        } else {
          dispatch(receiveDepartmentData({}));
        }
      });
  };
};

export const UpdateDepartmentStatus = (config, id, fetch) => {
  return (dispatch) => {
    return network
      .patch({
        url: `/department/${id}/status`,
        data: config,
      })
      .then((response) => {
        const { result, isError, message } = response || {};
        if (!isError && result.department) {
          dispatch(receiveDepartmentData(result));
          dispatch(showToaster(message, ToasterTypes.Success));
          fetch({}, true);
        } else {
          dispatch(showToaster(message, ToasterTypes.Failed));
        }
      });
  };
};
