import React, { useEffect } from 'react';
import 'Assets/style/conformPopup.css';
import { translate } from 'Language/Translate';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import conflictIcon from 'Assets/img/icons/calender-time.svg';
import FocusTrap from 'focus-trap-react'
const AppointmentConflictPopup = (props) => {
  const {
    onConfirm,
    onClose,
    label = translate(
      'leaveApproval.apptConflictMsg',
      'There are confirmed appointment(s) already scheduled on these date(s). Do you want to reschedule/cancel all?'
    )
  } = props;
  useEffect(() => {
    document.body.classList.add('modal-overflow');
    return () => document.body.classList.remove('modal-overflow');
  }, []);

  return (
    <>
     <FocusTrap
     focusTrapOptions={{
      clickOutsideDeactivates:true
    }}>
      <div className="AppointmentModal d-flex">
        <div className={`modalContent leaveConfirm_popup modal_xs`}>
          <div className="modalHeader">
            <div className="row">
              <div className="col-md-12">
                <h4>
                  {translate(
                    'leaveApproval.apptConflict',
                    'Appointment Conflict'
                  )}
                  <CloseIcon className='close-icon'
                    style={{ float: 'right', cursor: 'pointer' }}
                    onClick={onClose}
                  />
                </h4>
              </div>
              <div className="col-md-12">
                <div className="border_btm"></div>
                <div
                  className="modalContentarea-popup pb-0"
                  style={{
                    maxHeight: 430,
                    marginBottom: 10,
                  }}
                >
                  <div className="mb-3">
                    <div
                      style={{
                        padding: '10px 10px 20px 10px',
                        textAlign: 'center',
                      }}
                      className="modalContentarea-popup"
                    >
                      <div className="justify-content-center">
                        <img className="centered-main-img" src={conflictIcon} />
                        <div style={{ marginBottom: 5, marginTop: 5 }}>
                          <p>
                            {label}{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="modal_footer">
                      <div className="">
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <ul className="modalBtm-popup d-flex align-items-center justify-content-center">
                              <li className='ps-0' onClick={onClose}>
                                <button
                                  type="button"
                                  className="btn btn-light fit-content px-4 fixed-width-btn"
                                  id="cancel"
                                >
                                  {' '}
                                  {translate('common.cancel', 'CANCEL')}{' '}
                                </button>
                              </li>
                              <li onClick={onConfirm}>
                                <button
                                  type="button"
                                  className={`btn btn-primary fit-content px-4`}
                                  id="save"
                                >
                                  {' '}
                                  {translate(
                                    'myAppointments.continue',
                                    'Yes, Proceed'
                                  )}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </FocusTrap>
    </>
  );
};

AppointmentConflictPopup.prototype = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

export default AppointmentConflictPopup;
