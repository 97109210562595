import React, { useRef, useState, useEffect } from 'react'
import otpLogo from '../../../Assets/img/icons/enter-otp-main-image.svg'
import { translate } from 'Language/Translate'
import correct from '../../../Assets/img/icons/correct.svg'
import { getProfileDetails, linkExistingProfile, updateLinkedProfile, validateToken, verifyOtpPhoneUpdate } from 'Actions/patientProfileAction'
import { connect } from 'react-redux'
import { formatDate, timeZoneDate } from 'Utils/DateFormatter/Date';
import { useIntl } from 'react-intl'

function VerifyOtpModal({ verifyOtp, setVerifyOtp, setAddMemberClick,
    newMemberDetails,
    otpData,
    verifyOtpFunction,
    setOpenPatientInfo,
    getTokenForResend,
    setOtpData,
    verifyOtpforUpdate,
    type,
    updateProfile,
    otpDetails,
    showOtpLoader,
    otpState,
}) {
    const inputRefs = useRef([])
    const intl = useIntl()
    const [otp, setOtp] = useState(['', '', '', '']);
    const [countdown, setCountdown] = useState()
    const [otpVerified, setOtpVerified] = useState('')
    const [otpError, setOtpError] = useState(false)
    // Resend Counter
    const [resend, setResend] = useState(0)
    const [resendCounter, setResendCounter] = useState(0)
    const [showResend, setShowResend] = useState(false)
    const { setOtpStatus } = otpState

    const { otp_limit_duration, otp_resend_limit } = otpDetails;
    const [resendLoader, setResendLoader] = useState(false);

    useEffect(() => {
        const resendDetails = JSON.parse(localStorage.getItem("Resend"))
        if (resendDetails) {
            const { verifyOtp } = resendDetails
            if (verifyOtp) {
                let ResendInterval = setInterval(() => remainingResendTime(verifyOtp, ResendInterval), 1000);
            }
        } else {
            localStorage.setItem("Resend", JSON.stringify({}))
        }
    }, [])

    //  function to verify otp
    const handleVerifyOtp = () => {
        if (otp.length && otp.join('').length == 4) {
            if (type == 'edit') {
                let data = {
                    id: otpData?.linked_user_id,
                    payload: {
                        data: {
                            update_data: {
                                tele_country_code: otpData.tele_country_code,
                                phone: otpData?.phone
                            },
                            otp_verification: {
                                otp: otp.join(""),
                                token: otpData?.token
                            }
                        }
                    }
                }
                verifyOtpforUpdate(data, onVerifyOtpSuccess, onVerifyOtpError)
            }
            else {
                let payload = {
                    data: {
                        "patient_query": newMemberDetails.patient_id,
                        // "patient_query": otpData?.tele_country_code+otpData?.phone,
                        "otp_verification": {
                            "otp": otp.join(""),
                            "token": otpData?.token
                        }
                    }
                }
                verifyOtpFunction(payload, onVerifyOtpSuccess, onVerifyOtpError)
            }
        }
    }

    // handle verify otp success
    const onVerifyOtpSuccess = (message) => {
        setOtpVerified('success')
        setOtpStatus(true)
        setVerifyOtp(false)
        setOpenPatientInfo(true)
        setOtpError(message)
    }
    // handle verify otp failure
    const onVerifyOtpError = (message) => {
        setOtpVerified('error')
        setOtpError(message)
    }

    useEffect(() => {
        handleVerifyOtp()
    }, [otp])

    // setting up timer
    let remainingTime = (targetTime, Interval) => {
        let currentTime = timeZoneDate().getTime()
        let leftSecond = Math.trunc((targetTime - currentTime) / 1000)
        setCountdown(leftSecond)
        if (leftSecond <= 0) {
            clearInterval(Interval)
        }
        return leftSecond
    }

    let remainingResendTime = (targetTime, Interval) => {
        let currentTime = timeZoneDate().getTime()
        let leftSecond = Math.trunc((targetTime - currentTime) / 1000)
        setResendCounter(leftSecond)
        if (leftSecond <= 0) {
            clearInterval(Interval)
            setShowResend(false)
            setResend(0)
        }
        else if (!showResend) {
            setShowResend(true)
        }
        return leftSecond
    }
    useEffect(() => {
        if (!verifyOtp) {
            setOtp(['', '', '', ''])
            setOtpVerified('')
            setOtpError('')
        }
        setTimeout(() => {
            inputRefs?.current?.[0]?.focus();
        }, 300)
        let targetTime = timeZoneDate().getTime() + (otpData?.exp * 60 * 1000) + 1000
        let Interval = setInterval(() => remainingTime(targetTime, Interval), 1000);
        return () => { clearInterval(Interval) }
    }, [otpData, verifyOtp])

    const handlePaste = (e) => {
        e.preventDefault()
        e.stopPropagation()
        let val = e?.clipboardData?.getData('text').split("")
        if (val?.length) {
          val.push("", "", "", "")
          otp.reverse().map((item) => {
            if (item !== "") {
              val.unshift(item)
            }
          })
          inputRefs?.current?.[val.indexOf("") < 3 ? val.indexOf("") : 3].focus()
          setOtp(val.splice(0, 4))
        }
        setOtpVerified('')
      };

    // handle input change
    const handleinput = (e, index) => {
        const value = e.target.value
          if (isNaN(value) || value.trim() == "" ||showOtpLoader) return;
          setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = value.slice(-1);
            return newOtp;
          })
          if (value !== '' && index < 3) {
            inputRefs.current[index + 1].focus();
          }
        setOtpVerified('')
      }
      const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                inputRefs.current[index -1].focus();
            }
        }
        else if (e.key === 'Backspace' && !showOtpLoader) {
          setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = "";
            return newOtp;
          });
          if (index > 0) {
            inputRefs.current[index].focus();
          }
          setOtpVerified('')
        }
        if(index === 3 && e.key === "Tab" && !e.shiftKey && countdown !== 0) e.preventDefault()
        if(index === 0 && e.key === "Tab" && e.shiftKey ) e.preventDefault()
      };

    //  function to resend otp before editing
    const getTokenBeforeEdit = (item) => {
        let payload = {
            data: {
                patient_query: item?.patientId,
                // patient_query: item?.country+item?.phno,
            },
        };
        setResendLoader(true)
        getTokenForResend(payload, ongetTokenBeforeEditSuccess, ongetTokenBeforeEditError)
    }

    const ongetTokenBeforeEditSuccess = (result) => {
        setOtpData({
            ...otpData,
            token: result?.otp_token?.token,
            exp: result?.otp_token?.exp,
            exp_time: result?.otp_token?.exp_unix,
        });
        const timer = setTimeout(() => {
            setResendLoader(false);
          }, 3000);
          return () => clearTimeout(timer);
    }
    const ongetTokenBeforeEditError = () => {
    }

    // function to resend otp after edit
    const getTokenAfterEdit = () => {
        setResendLoader(true);
        let payload = {
            id: newMemberDetails.linked_user_id,
            payload: {
                data: {
                    first_name: newMemberDetails.fName,
                    last_name: newMemberDetails.lName,
                    dob: formatDate(newMemberDetails?.dob),
                    blood_group: newMemberDetails?.blood_group?.value,
                    relation: newMemberDetails?.relation?.value,
                    gender: newMemberDetails?.gender,
                    consent: true,
                    tele_country_code: newMemberDetails?.phno
                        ? newMemberDetails?.country
                        : '',
                    phone: newMemberDetails?.country ? newMemberDetails?.phno : '',
                }
            }
        }
        updateProfile(payload, onUpdateProfileSuccess)
    }

    const onUpdateProfileSuccess = (data) => {
        let { result, response } = data;
        if (response.code === 202) {
            let phone = `${result.profile_data.tele_country_code}${result.profile_data.phone}`;
            setOtpData({
                user_id: newMemberDetails?.user_id,
                patient_id: newMemberDetails.patient_id,
                phone: result.profile_data.phone,
                tele_country_code: result.profile_data.tele_country_code,
                codeAndphone: phone,
                token: result?.token?.token,
                exp: result?.token?.exp,
                linked_user_id: newMemberDetails?.linked_user_id
            });
        }
        const timer = setTimeout(() => {
            setResendLoader(false);
          }, 3000);
          return () => clearTimeout(timer);
    };

    //  handle otp resend 
    const resendOtp = (e) => {
        if(!e.key || (e.key === 'Enter')){
            if (type == 'edit') {
                getTokenAfterEdit()
            }
            else {
                getTokenBeforeEdit(newMemberDetails)
            }
            setOtp(['', '', '', ''])
            inputRefs.current[0].focus()
            setOtpVerified('')
            setOtpError('')
            setResend(resend + 1)
            if ((resend + 1) >= otp_resend_limit) {
                let targetResendTime = timeZoneDate().getTime() + (otp_limit_duration * 60 * 1000) + 1000
                const resendlocal = JSON.parse(localStorage.getItem("Resend"))
                localStorage.setItem("Resend", JSON.stringify({ ...resendlocal, verifyOtp: targetResendTime }))
                let IntervalRemaining = setInterval(() => remainingResendTime(targetResendTime, IntervalRemaining), 1000);
            }
        }
        else if(e.key === "Tab" && !e.shiftKey) e.preventDefault()
    }

    // handle edit member details
    const handleEdit = () => {
        setVerifyOtp(false)
        setAddMemberClick(true)
    }
    return (
      <>
        <img className="main-img" src={otpLogo} alt="" />
        <h6>
          {translate(
            "patientPortal.enterPsw",
            "Please enter the One Time Password to verify your account."
          )}
        </h6>
        <p>
          {translate("patientPortal.otpSent", "An OTP has been sent to")}{" "}
          <span className="fw-bold">
            {" "}
            +{newMemberDetails.country} {newMemberDetails.phno}
          </span>{" "}
          <a onClick={handleEdit}>{translate("patientPortal.edit", "Edit")}</a>
        </p>
        <div
          className={`otp-theme ${otpVerified === "success" && "success"} ${
            otpVerified === "error" && "error"
          }`}
        >
          <div
            className={`otp-field ${showOtpLoader && "loading"}`}
            onPaste={(e) => handlePaste(e)}
          >
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                className="form-control otpinput"
                // maxLength='1'
                value={digit}
                onChange={(e) => handleinput(e, index)}
                ref={(ref) => (inputRefs.current[index] = ref)}
                onFocus={(e) => e.target.select}
                autoFocus={index === 0}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            ))}
            {otpVerified === "success" ? (
              <img
                className="status-icon correct icon-1"
                src={correct}
                alt=""
              />
            ) : (
              ""
            )}
          </div>
          <div>
            {resendCounter < 1 &&
              countdown > 0 &&
              !(otpVerified === "success") && (
                <>
                  {translate("patientPortal.otpExpire", "OTP will expire in")}{" "}
                  {countdown && countdown}{" "}
                  {translate("appointments.sec", "sec")}
                </>
              )}
          </div>
          {resendCounter < 1 &&
            countdown === 0 &&
            resend < otp_resend_limit && (
              <>
                {resendLoader ? (
                  <a id="resend" tabIndex="0" className="resend-otp loading">
                    {translate("patientPortal.resend", "Resend OTP")}
                  </a>
                ) : (
                  <a
                    id="resend"
                    tabIndex="0"
                    onKeyDown={resendOtp}
                    className="resend-otp"
                    onClick={resendOtp}
                  >
                    {translate("patientPortal.resend", "Resend OTP")}
                  </a>
                )}
              </>
            )}
          {resendCounter > 0 && (
            <>
              <span className="exceeded-msg">
                {intl.formatMessage({
                  id: "commom.otpLimitMsg",
                  defaultMessage:
                    "OTP send limit exceeded please try again after 5 min",
                })}
              </span>
            </>
          )}
          <div className="d-flex align-items-center justify-content-center gap-2">
            {otpVerified === "success" && (
              <span className="success-msg">
                {translate(
                  "patientPortal.otpVerified",
                  "OTP verified successfully!"
                )}
              </span>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <img
              className="status-icon correct icon-2"
              src="./assets/images/icons/correct.svg"
              alt=""
            />
            {otpError && <span className="error-msg">{otpError}</span>}
          </div>
        </div>
        {/* <div className="d-flex align-items-center justify-content-end modal-footer">
                    <button className="btn btn-primary">Save & Verify</button>
                </div> */}
      </>
    );
}
const mapStateToProps = (state) => ({
    otpDetails: state.SettingsReducer.otpDetails,
    showOtpLoader: state.ProfileReducer.showOtpLoader,
});
const mapDispatchToProps = (dispatch) => ({
    verifyOtpFunction: (data, callback, errCallBack) => dispatch(validateToken(data, callback, errCallBack)),
    getTokenForResend: (data, callBack, errCallBack) => dispatch(linkExistingProfile(data, callBack, errCallBack)),
    getPatientDetails: (id, callBack, errCallBack) => dispatch(getProfileDetails(id, callBack, errCallBack)),
    verifyOtpforUpdate: (data, callBack, errCallBack) => dispatch(verifyOtpPhoneUpdate(data, callBack, errCallBack)),
    updateProfile: (data, callBack, errCallBack) => dispatch(updateLinkedProfile(data, callBack, errCallBack)),
})
export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtpModal)