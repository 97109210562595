import { USER_TYPE } from 'Constants/userTypes';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import expiry from '../../Assets/img/expired-icon.svg';
import { translate } from '../../Language/Translate';

function SubscriptionModal() {
  const roles = useSelector(
    (state) => state.LoginReducer.userPermissions.roles
  );

  const subscriptionData = useSelector(
    (state) => state.SettingsReducer.subscriptionData
  );

  useEffect(() => {
    document.body.classList.add('modal-overflow');
    return () => document.body.classList.remove('modal-overflow');
  }, []);

  const handleRenewal = () => {
    window.open(subscriptionData.session_url);
  };

  return (
    <div className="fixed-modal">
      <div className="modal-card">
        <img src={expiry} alt="" />
        <h5>
          {translate(
            'subscription_admin.expiry',
            'Your subscription has expired'
          )}
        </h5>
        <p>
          {roles?.length &&
          (roles.includes(USER_TYPE.subscriptionOwner) ||
            roles.includes(USER_TYPE.admin))
            ? translate(
                'subscription_admin.description',
                'To keep using the application without interruption please review your subscription information and take necessary action'
              )
            : translate(
                'subscription_admin.descriptionNew',
                'To keep using the application without interruption please contact your administrator to renew the subscription'
              )}
        </p>
        {roles?.length && roles.includes(USER_TYPE.subscriptionOwner) && subscriptionData.session_url ? (
          <button className="btn btn-primary" onClick={handleRenewal}>
            {translate('subscription_admin.renew', 'Renew Subscription')}
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default SubscriptionModal;
