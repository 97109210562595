import React, {useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-in';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  appendTime,
  formatDateto_D_WD_M_Y,
  formatDate,
  timeZoneDate,
} from 'Utils/DateFormatter/Date';
import './customTimeline.css';
import { translate } from 'Language/Translate';
import telephone from 'Assets/img/icons/telephone.svg'
import envelope from 'Assets/img/icons/envelope.svg'
import person from 'Assets/img/icons/person-round.svg'
import clock from 'Assets/img/icons/clock.svg'
import listIcon from 'Assets/img/icons/list-icon.svg'
import { Tooltip } from '@mui/material';
// import ButtonLoader from 'Components/Common/ButtonLoader';

let status = {
  upcoming: {
    class: 'upcoming',
    label: translate('appointments.Upcoming', 'Upcoming'),
  },
  notattempted: {
    class: 'not-attempted',
    label: translate('appointments.notAttempted', 'Not Attempted'),
  },
  cancelled: {
    class: 'cancelled',
    label: translate('appointments.cancelled', 'Cancelled'),
  },
  completed: {
    class: 'completed',
    label: translate('appointments.completed', 'Completed'),
  },
  inprogress: {
    class: 'in-progress',
    label: translate('appointments.inProgress', 'In Progress'),
  },
  waiting: {
    class: 'waiting',
    label: translate('appointments.waiting', 'Waiting'),
  },
};

const statusDot = {
  upcoming: 'upcoming',
  notattempted: 'notattempted',
  cancelled: 'cancelled',
  label: 'Cancelled',
  completed: 'completed',
  inprogress: 'in-progress',
  empty: 'empty',
  waiting: 'waiting',
};

const CustomTimeline = ({
  events,
  handleAppointmnentStart,
  language,
  filteredDate,
  // isBtnLoading = false,
  patient,
  stat,
  conflictConform=false,
}) => {
  moment.locale(language);
  const localizer = momentLocalizer(moment);

  useEffect(() => {
    setTimeout(() => {
      const currentDate = new Date();
      if (formatDate(currentDate) == formatDate(filteredDate) && !(patient || stat.status || stat.department )) {
        let ele = document.getElementsByClassName("rbc-current-time-indicator");
        if (ele[0] && !conflictConform) {
          ele[0].scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      } else {
        if (patient || stat.status || stat.department ) {
          let ind = getLowestTimeIndex(events);
          if (events[ind]) {
            let ele = document.getElementById(`${events[ind].id}EventDocId`);
            if (ele && !conflictConform) {
              ele.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
              });
            }
          }
        } else {
          let ele = document.getElementsByClassName(
            "rbc-current-time-indicator"
          );
          if (ele[0] && !conflictConform) {
            ele[0].style.display = "block";
            ele[0].style.visibility = "hidden";
            ele[0].scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }
        }
      }
    }, [100])
  }, [events,conflictConform]);

  function getLowestTimeIndex(data) {
    return data.reduce((lowestIndex, obj, index) => {
      const currentTime = new Date(`1970-01-01 ${obj.appt_start_time}`);
      const lowestTime = new Date(
        `1970-01-01 ${data[lowestIndex].appt_start_time}`
      );
      return currentTime < lowestTime ? index : lowestIndex;
    }, 0);
  }

  const EventComponent = (e) => {
    let { event } = e;
    return (
      <div className="h-100 eventTimelineScroll" key={event.id} id={`${event.id}EventDocId`} >
        {' '}
        <div
          id={`appointmentViewToggle-${event.id}`}
          className={`single-slot ${status[event.status]?.class} m-0`}
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="true"
        >
          <div className="schedule-and-name text-truncate ">
            <h6 className="schedule">
              {appendTime(event.start)} - {appendTime(event.end)}
            </h6>
            <h6 className="patient-name text-truncate"><Tooltip title={event.title} placement='bottom-end'>{event.title}</Tooltip></h6>
          </div>
          <span className="status ">
            <div className="dot">•</div> {status[event.status]?.label}
          </span>
        </div>
        {handlePopup(event)}
      </div>
    );
  };

  const onClose = (id) => {
    const element = document.getElementById(`appointmentView-${id}`);
    const element1 = document.getElementById(`appointmentViewToggle-${id}`);
    element.classList.remove('show');
    element1.classList.remove('show');
  };
  const handlePopup = (item) => {
    return (
      <div>
        <div
        className="dropdown-menu custom-popover v2 popupDoctorViewday"
        id={`appointmentView-${item?.appointment_id}`}
        key={item?.appointment_id}
      >
        <div className="popover-header mb-3">
        <div className='header-id'>
          <h6>Appointment Details</h6>
          <div className="d-flex gap-2 align-items-center">
          <span> <p className='id'>{item?.appointment_id}</p></span>
          <span className={`status ${statusDot[item?.live_status]}`}>
            <div className="dot">•</div> {status[item?.live_status]?.label}
          </span>
          </div>
        </div>
          <span className="d-flex align-items-center gap-1">
            <button
              className="btn-transparent"
              onClick={() => {
                onClose(item?.appointment_id);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
              </svg>
            </button>
          </span>
        </div>
        <div className="popover-body">
        <div className="d-flex flex-wrap">
          </div>
          <div className="icon-block mb-3">
          <img src={person} alt="" />
            <div className="mt-1">
              <span>Patient Name</span>
              <p className="mb-0 mt-2">{item?.patient_name}</p>
            </div>
          </div>
          {item.patient_email &&
            <div className="icon-block mb-3 me-4 bordred-top-bottom">
              <img src={envelope} alt="" />
              <div className="mt-1">
                <span>{translate('common.emailId', 'Email Address')}</span>
                <p className="mb-0 mt-2 mail-text">{item.patient_email}</p>
              </div>
            </div>
          }
          <div className="items-set">
          <div className="icon-block">
          <img src={clock} alt="" />
            <div>
            <span>Date & Time</span>
              <p className="mb-0 mt-2">
                {formatDateto_D_WD_M_Y(new Date(item?.appt_date), language)}
              </p>
              <p className="mb-0 mt-2">
                {appendTime(item?.appt_start_time, language)} -{' '}
                {appendTime(item.appt_end_time, language)}
              </p>
            </div>
          </div>
          {item?.patient_tele_country_code &&
            <div className="icon-block mb-3">
              <img src={telephone} alt="" />
              <div className="mt-1">
                <span>{translate('users.phone', 'Phone Number')}</span>
                <p className="mb-0 mt-2">+{item?.patient_tele_country_code}{item.patient_phone}</p>
              </div>
            </div>
          }
          </div>
        </div>
        <div className="popover-footer">
          <div className="icon-block">
          <img src={listIcon} alt="" />
            <div>
              <span>Reason for Appointment</span>
              <p className="mb-0 mt-2">{item?.reason}</p>
            </div>
          </div>
          <div className="mt-3 d-flex align-items-center gap-3 popover-footer v2">
            <button
              className="btn btn-primary loader-btn"
              onClick={(e) => {
                // e.preventDefault()
                e.stopPropagation();
                handleAppointmnentStart(
                  item,
                  (item.live_status === 'upcoming' ||
                    item.live_status === 'waiting') &&
                    formatDate(item.appt_date) ===
                    formatDate(timeZoneDate())
                    ? 'Start'
                    : 'View'
                );
              }}
              // disabled={isBtnLoading}
            >
              {/* {isBtnLoading ? (
                <ButtonLoader
                  text={
                    (item.live_status === 'upcoming' ||
                      item.live_status === 'waiting') &&
                    formatDate(item.appt_date) ===
                      new Date().toISOString().split('T')[0]
                      ? translate('common.start', 'Start')
                      : translate('appointments.view', 'View')
                  }
                />
              ) : ( */}
                <>
                  {' '}
                  {(item.live_status === 'upcoming' ||
                    item.live_status === 'waiting') &&
                  formatDate(item.appt_date) ===
                  formatDate(timeZoneDate())
                    ? translate('common.start', 'Start')
                    : translate('appointments.view', 'View')}
                </>
              {/* // )} */}
            </button>
            {/* <button className="selector">
          Cancel Appointment
        </button> */}
          </div>
        </div>
      </div>
      <div className="overlay"></div>
      </div>
    );
  };

  return (
    <div className="dayview-doc">
      <Calendar
        enableAutoScroll={false}
        toolbar={false}
        localizer={localizer}
        events={events}
        startAccessor={'start'}
        endAccessor="end"
        style={{ height: 650 }}
        view="day"
        timeslots={2}
        dayLayoutAlgorithm={'no-overlap'} // this is for no overlapping
        // timeslots="1"   //1 min 30 minutes
        components={{ event: EventComponent }}
        tooltipAccessor={()=>null}
        // range={["10:00"]}
        // min={
        //   events?.length
        //     ? new Date(events[0].dayStartTime).setMinutes(0)
        //     : timeZoneDate().setHours(0, 0)
        // }
      />
    </div>
  );
};

export default CustomTimeline;
