import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import {
  BLOOD_GROUP_OPTIONS,
  PATIENT_LOGIN_TITLES,
  RELATIONS_LABEL,
} from '../../../Constants/constant';
import { translate } from '../../../Language/Translate';
import AntDatepicker from '../../Common/AntdDatePicker';
import PhoneNumberInput from '../../Common/PhoneInput';
import { connect } from 'react-redux';
import {
  getProfileDetails,
  updateLinkedProfile,
} from 'Actions/patientProfileAction';
import { formatDate, timeZoneDate } from '../../../Utils/DateFormatter/Date';
import OtpVerifyPopup from './OtpVerifyPopup';
import Modal from '../Common/Modal';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import { showToaster, ToasterTypes } from 'Actions/toasterAction';
import { useIntl } from 'react-intl';
import ButtonLoader from 'Components/Common/ButtonLoader';
import Loader from 'Components/Common/Loader';
import { validateMobile } from 'Utils/Validation/validator';
// import Toaster from '../Common/Toaster';

const EditProfile = (props) => {
  const {
    editModal,
    setEditModal,
    onSubmitSuccess,
    editId,
    getLinkedProfileList,
    getPatientDetails,
    updateProfile,
    displaytoaster,
    isBtnLoading,
    setLoading,
    applicationData
  } = props;
  const [numberLength, setNumberLength] = useState(10);
  const [verifyOtpData, setVerifyOtpData] = useState({});
  const intl = useIntl()
  const numField = useRef()
  const focusRef = useRef()
  const firstField = useRef()
  const submitBtnRef = useRef()
  const [invalidAlert, setInvalidAlert] = useState({});
  const [showOtp, setShowOtp] = useState(false);
  const [linkProfileDetails, setLinkProfileDetails] = useState({
    country: '91',
  });
  const [resetData, setResetData] = useState({});
  const [loading, setIsLoading]=useState(true)
  const [code,setCode]=useState('IN');
  const [resendLoader, setResendLoader] = useState(false);
  useEffect(() => {
    if (!editModal) {
      setInvalidAlert({});
      setLinkProfileDetails({
        country: '91',
      });
    } else {
      getPatientDetails(editId, onGetPatientDetailsSuccess);
    }
  }, [editModal]);

  // useEffect(() => {
  //   getPatientDetails(editId, onGetPatientDetailsSuccess);
  // }, []);

  const onGetPatientDetailsSuccess = (result) => {
    if(result){
      setIsLoading(false)
    }
    let { patient_details, profile_link_details } = result;
    const relationObj = RELATIONS_LABEL.find(
      (rel) => rel.value === profile_link_details?.relation
    );
    let currentValues = {
      fname: patient_details?.name,
      relation: relationObj,
      phno: patient_details?.phone,
      country: patient_details?.tele_country_code
        ? patient_details?.tele_country_code
        : linkProfileDetails.country,
      gender: patient_details?.gender,
      lName: patient_details?.last_name,
      patient_id: patient_details?.patient_id,
      mrn:patient_details?.mrn,
      dob: patient_details?.dob ? patient_details?.dob : null,
      age: patient_details?.dob
        ? `${getAge(formatDate(patient_details?.dob))}`
        : '',
      user_id: patient_details?.user_id,
      blood_group: patient_details?.blood_group
        ? {
          value: patient_details?.blood_group,
          label: patient_details?.blood_group,
        }
        : null,
    };
    setLinkProfileDetails(currentValues);
    setResetData(currentValues);
  };

  // handle input change
  const inputHandle = (e) => {
    const { name, value } = e.target
    // const regex = /^\+?[0-9 ]*\.?[0-9 ]*$/;
    const regex = /^[0-9]*$/;
    if (name === 'phno') {
      if(!value || (!regex.test(value)||validateMobile(value,code))){
        setInvalidAlert((prev) => ({
          ...prev,
          mobileValidErr: false,
        }));
      }
      if (regex.test(value)) {
        setLinkProfileDetails({
          ...linkProfileDetails,
          [e.target.name]: e.target.value,
        });
        setInvalidAlert((prev) => ({
          ...prev,
          [e.target.name]: false,
        }));  
      }
    } else {
      setLinkProfileDetails({
        ...linkProfileDetails,
        [e.target.name]: e.target.value,
      });
      setInvalidAlert((prev) => ({
        ...prev,
        [e.target.name]: false,
      }));   
    }
  };
  // handle bloog group
  const handleChangeOption = (option) => {
    setLinkProfileDetails({
      ...linkProfileDetails,
      blood_group: option,
    });
  };

  // handle relation
  const handleChangeRelation = (option) => {
    setLinkProfileDetails({
      ...linkProfileDetails,
      relation: option,
    });
    setInvalidAlert((prev) => ({
      ...prev,
      relation: false,
    }));
  };

  // handle gender change
  const genderHandle = (e) => {
    setInvalidAlert({});
    setLinkProfileDetails({ ...linkProfileDetails, gender: e });
  };

  // handle dob change
  const handleDobChange = (date) => {
    setLinkProfileDetails({
      ...linkProfileDetails,
      dob: date,
      age: `${getAge(formatDate(date))}`,
    });
  };
  const getAge = (birthDate) =>
    Math.floor((timeZoneDate() - new Date(birthDate).getTime()) / 3.15576e10);

  // handle country code channge
  const handleMobileNumberChange = (phone) => {
    setLinkProfileDetails({
      ...linkProfileDetails,
      country: phone,
    });
  };

  // handle reset click
  const resetHandle = () => {
    setLinkProfileDetails({
      ...resetData,
    });
    setInvalidAlert({});
  };

  // handle profile save
  const handleProfileSave = () => {
    const { editLinkProfileOtp } = JSON.parse(localStorage.getItem("Resend"))
    if (editLinkProfileOtp && ((editLinkProfileOtp - timeZoneDate().getTime()) / 1000) > 0) {
      displaytoaster(intl.formatMessage({
        id: 'commom.otpLimitMsg',
        defaultMessage: 'OTP send limit exceeded please try again after 5 min'
      }), ToasterTypes.Warning)
    }
    else if (
      !linkProfileDetails?.fname?.trim().length ||
      !linkProfileDetails?.dob ||
      !linkProfileDetails?.relation?.value ||
      // !linkProfileDetails?.phno ||
      !linkProfileDetails?.gender ||
      (linkProfileDetails?.phno && linkProfileDetails?.country && !validateMobile(linkProfileDetails?.phno,code))

      // !linkProfileDetails?.country
    ) {
      setInvalidAlert({
        ...invalidAlert,
        fname: !linkProfileDetails?.fname?.trim().length,
        dob: !linkProfileDetails?.dob,
        relation: !linkProfileDetails?.relation?.value,
        // phno: !linkProfileDetails?.phno,
        gender: !linkProfileDetails?.gender,
        country: linkProfileDetails?.phno && !linkProfileDetails?.country,
        mobileValidErr:(linkProfileDetails?.phno && !validateMobile(linkProfileDetails?.phno,code))
      });
    } else {
      let payload = {
        id: linkProfileDetails.user_id,
        payload: {
          data: {
            first_name: linkProfileDetails.fname,
            last_name: linkProfileDetails.lName,
            dob: formatDate(linkProfileDetails?.dob),
            blood_group: linkProfileDetails?.blood_group?.value,
            relation: linkProfileDetails?.relation?.value,
            gender: linkProfileDetails?.gender,
            consent: true,
            tele_country_code: linkProfileDetails?.phno
              ? linkProfileDetails?.country
              : '',
            phone: linkProfileDetails?.country ? linkProfileDetails?.phno?.replace(/\s/g, "") : '',
          },
        },
      };
      updateProfile(payload,  onUpdateProfileSuccess);
    }
  };
  const onUpdateProfileSuccess = (data) => {
    let { result, response } = data;
    if (response.code === 202) {
      let phone = `${result.profile_data.tele_country_code}${result.profile_data.phone}`;
      setVerifyOtpData({
        user_id: linkProfileDetails?.user_id,
        patient_id: linkProfileDetails.patient_id,
        phone: result.profile_data.phone,
        tele_country_code: result.profile_data.tele_country_code,
        codeAndphone: phone,
        token: result?.token?.token,
        exp: result?.token?.exp,
      });
      // setVerifyOtpData({})
      setShowOtp(true);
      //set otp data
    } else {
      getLinkedProfileList();
      onSubmitSuccess();
    }
  };

  // handle profile save for resend otp
  const callupdateAPIForOTP = () => {
    setResendLoader(true)
    let payload = {
      id: linkProfileDetails.user_id,
      payload: {
        data: {
          first_name: linkProfileDetails.fname,
          last_name: linkProfileDetails.lName,
          dob: formatDate(linkProfileDetails?.dob),
          blood_group: linkProfileDetails?.blood_group?.value,
          relation: linkProfileDetails?.relation?.value,
          gender: linkProfileDetails?.gender,
          consent: true,
          tele_country_code: linkProfileDetails?.phno
            ? linkProfileDetails?.country
            : '',
          phone: linkProfileDetails?.country ? linkProfileDetails?.phno?.replace(/\s/g, "") : '',
        },
      },
    };
    updateProfile(payload, onSendingOtp, ()=>{}, true);
  };
  const onSendingOtp = (data) => {
    let { result } = data;
    setVerifyOtpData({ ...verifyOtpData, token: result.token.token });
    const timer = setTimeout(() => {
      setResendLoader(false);
    }, 3000);
    return () => clearTimeout(timer);
  };
  const closeVerifyModal = () => {
    setShowOtp(false);
  };
  // disable futeure dates for ant datepicker
  const disableFutureDates = (current) => {
    return current && current > moment();
  };
  return (
    <>
      <div
        className={`offcanvas offcanvas-end custom-off-canvas link-profile-canvas ${editModal ? 'show' : ''
          }`}
        tabIndex="-1"
        id="editAccount"
      >
        <div className="offcanvas-header">
          <h5 id="viewScheduleLabel">
            {translate('linkProfile.edit', 'Edit Profile')}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => setEditModal(false)}
          ></button>
        </div>
        {
          loading ?
            <div className="loader-wrap mb-4">
              <Loader />
            </div> : 
          <div className="offcanvas-body">
          <div className="set-1">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <label htmlFor="fName">
                    {translate('common.firstName', 'First Name')}
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    id="fName"
                    name="fname"
                    ref={firstField}
                    type="text"
                    placeholder="i.e, John"
                    className={`input w-100 ${invalidAlert.fname ? 'input-err' : ''
                      } `}
                    value={linkProfileDetails.fname ?? ''}
                    defaultValue=""
                    onChange={(e) => inputHandle(e)}
                    maxLength={40}
                    onKeyDown={(e)=>{
                      if(e.key === "Tab" && e.shiftKey) 
                      {
                        e.preventDefault()
                        submitBtnRef.current.focus()
                      }
                    }}
                  />
                  <span className={`${invalidAlert.fname ? 'err' : 'd-none'}`}>
                    {translate('common.required', 'This field is required')}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <label htmlFor="lName">
                    {translate('common.lastName', 'Last Name')}
                  </label>
                  <input
                    id="lName"
                    name="lName"
                    placeholder="i.e, John"
                    type="text"
                    className={`input w-100`}
                    defaultValue=""
                    value={linkProfileDetails.lName ?? ''}
                    onChange={(e) => inputHandle(e)}
                    maxLength={40}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <label htmlFor="date">
                    {translate('patientPortal.dob', 'Date of Birth')}
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className="icon-field date">
                    <AntDatepicker
                      onInputChange={handleDobChange}
                      value={linkProfileDetails?.dob}
                      disabledDate={disableFutureDates}
                      placeholder="DD/MM/YYYY"
                      popupClassName="link_profile_date_picker"
                      className={`${invalidAlert.dob ? 'input-err' : ''}`}
                    />
                    <span className={`${invalidAlert.dob ? 'err' : 'd-none'}`}>
                      {translate('common.required', 'This field is required')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group mb-4">
                      <label htmlFor="age">
                        {translate('patientPortal.ageinYears', 'Age (Years)')}
                      </label>
                      <input
                        disabled
                        id="age"
                        name="age"
                        placeholder="age"
                        type="text"
                        value={linkProfileDetails.age ?? ''}
                        defaultValue=""
                        className="input w-100"
                      // onChange={(e) => inputHandle(e)}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group mb-4">
                      <label htmlFor="bloodGroup">
                        {translate('common.bloodGroup', 'Blood Group')}
                      </label>
                      <div className="select min-height-dr">
                        <ReactSelectDropDown
                          options={BLOOD_GROUP_OPTIONS}
                          value={linkProfileDetails.blood_group}
                          onInputChange={handleChangeOption}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="set-2">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <label htmlFor="id">
                        {applicationData?.use_system_generated_mrn ? translate('patientPortal.patient_id', 'Patient ID') : translate(
                          "patientPortal.mrnHealthId",
                          "mrn / Health Id"
                        )}
                    {/* {translate('patientPortal.patient_id', 'Patient ID')} */}
                  </label>
                  <input
                    disabled
                    id="id"
                    type="text"
                    placeholder="i.e, TC0001"
                    className="input w-100"
                    value={applicationData?.use_system_generated_mrn?linkProfileDetails?.patient_id:linkProfileDetails?.mrn}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group mb-4">
                  <label htmlFor="relation">
                    {translate('patientPortal.relation', 'Relation')}
                    {/* <span style={{ color: 'red' }}>*</span> */}
                  </label>
                  <div className="min-height-dr">
                    <ReactSelectDropDown
                      label="Select"
                      customClass={`${invalidAlert.relation ? 'custom-dr-err' : ''
                        }`}
                      options={RELATIONS_LABEL}
                      onInputChange={handleChangeRelation}
                      value={linkProfileDetails.relation ?? ''}
                    />
                  </div>
                  <span
                    className={`${invalidAlert.relation ? 'err' : 'd-none'}`}
                  >
                    {translate('common.required', 'This field is required')}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <span className="label">
                    {translate('patientPortal.gender', 'Gender')}
                    <span style={{ color: 'red' }}>*</span>
                  </span>
                  <div>
                    <div
                      className={`btn-group ${invalidAlert.gender ? 'radio-group-err' : ''
                        }
                    `}
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        autoComplete="off"
                        checked={linkProfileDetails.gender === 'male'}
                      />
                      <label
                        className="btn btn-black"
                        htmlFor="btnradio1"
                        onClick={() => genderHandle('male')}
                      >
                        {translate('common.male', 'Male')}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        autoComplete="off"
                        checked={linkProfileDetails.gender === 'female'}
                      />
                      <label
                        className="btn btn-black"
                        htmlFor="btnradio2"
                        onClick={() => genderHandle('female')}
                      >
                        {translate('common.female', 'Female')}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio3"
                        autoComplete="off"
                        checked={linkProfileDetails.gender === 'other'}
                      />
                      <label
                        className="btn btn-black"
                        htmlFor="btnradio3"
                        onClick={() => genderHandle('other')}
                      >
                        {translate('common.other', 'Others')}
                      </label>
                    </div>
                  </div>
                  <span className={`${invalidAlert.gender ? 'err' : 'd-none'}`}>
                    {translate('common.genderRequired', 'Please select gender')}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <label htmlFor="phno" className="lable">
                    {translate('patientPortal.mobileNo', 'Mobile Number')}
                  </label>
                  <div className="phone-field gap-3">
                    <span
                     onKeyDown={(e) => {
                      if (e.key === "Tab" && !e.shiftKey)
                      {
                        e.preventDefault()
                        numField.current.focus()
                      } 
                    }}>
                    <PhoneNumberInput
                      // defaultCountry={false}
                      value={linkProfileDetails.country}
                      onChange={handleMobileNumberChange}
                      validationErr={invalidAlert?.country}
                      numberLength={numberLength}
                      setNumberLength={setNumberLength}
                      focusRef={focusRef}
                      code={code}
                      setCode={setCode}
                    />
                      </span>
                      <div>
                    <input
                      type="tel"
                      id="phno"
                      name="phno"
                      placeholder="i.e, 98950 98950"
                      ref={numField}
                      className={`form-control grey input ${invalidAlert.phno|| invalidAlert?.mobileValidErr ? 'input-err' : ''
                        } `}
                      value={linkProfileDetails.phno ?? ''}
                      defaultValue=""
                      onChange={(e) => inputHandle(e)}
                      maxLength={numberLength}
                      onKeyDown={(e)=>{
                        if(e.shiftKey && e.key === "Tab"){
                          e.preventDefault()
                          focusRef.current.focus()
                        }
                      }}
                    />
                <span
                    className={`${invalidAlert.country ? 'err' : 'd-none'}`}
                  >
                    {translate('common.required', 'This field is required')}
                  </span>
                  <span className={`${invalidAlert?.mobileValidErr ? 'err' : 'd-none'}`}>
                       {translate(
                          'common.invalidPhone',
                          'Invalid mobile number.'
                        )}
              </span>
              </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end gap-3 footer-btns">
            <button
              id="reset"
              className="btn btn-outlined-dark px-5"
              onClick={resetHandle}
            >
              Reset
            </button>
            <button
              id="update"
              className="btn btn-primary px-5"
              onClick={handleProfileSave}
              disabled={isBtnLoading}
              ref={submitBtnRef}
              onKeyDown={(e)=>{
                if(e.key === "Tab" && !e.shiftKey){
                  e.preventDefault()
                  firstField.current.focus()
                }
              }}
            >
              {isBtnLoading ? <ButtonLoader text={translate('patientPortal.update', 'Update')} /> :
                translate('patientPortal.update', 'Update')
              }
            </button>
          </div>
        </div>
        }
      </div>
      <div
        onClick={() => setEditModal(false)}
        className={` ${editModal && 'offcanvas-backdrop fade show'}`}
      ></div>
      {showOtp && (
        <Modal
          close={showOtp}
          setClose={closeVerifyModal}
          title={PATIENT_LOGIN_TITLES.otpVerification}
        >
          <OtpVerifyPopup
            setEditModal={setEditModal}
            closeVerifyModal={closeVerifyModal}
            getLinkedProfileList={getLinkedProfileList}
            setVerifyOtpData={setVerifyOtpData}
            verifyOtpData={verifyOtpData}
            getToken={callupdateAPIForOTP}
            type="edit"
            modalType={"editLinkProfileOtp"}
            setLoading={setLoading}
            resendLoader={resendLoader}
          />
        </Modal>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  otpDetails: state.SettingsReducer.otpDetails,
  isBtnLoading: state.ProfileReducer.isBtnLoading,
  applicationData: state.SettingsReducer.applicationData,
});
const mapDispatchToProps = (dispatch) => ({
  updateProfile: (data, callBack, errCallBack, resentOtp) =>
    dispatch(updateLinkedProfile(data, callBack, errCallBack, resentOtp)),
  getPatientDetails: (id, callBack, errCallBack) =>
    dispatch(getProfileDetails(id, callBack, errCallBack)),
    displaytoaster: (message, type) => dispatch(showToaster(message, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
