import React, { useEffect, useState,useRef } from 'react';
import { translate } from '../../../Language/Translate';

const ConfirmReschedule = (props) => {
  const { open, handleStopReschdule, handleProceedReschdule } = props;
  const proccedRef = useRef()
  const cancelRef = useRef()
  const [openModal, setOpenModal] = useState(false);
  const [displayMode, setDisplayMode] = useState(false);
  useEffect(() => {
    if (open) {
      setDisplayMode(true);
    } else {
      setOpenModal(false);
    }
    setTimeout(() => {
      if (open) {
        setOpenModal(true);
      } else {
        setDisplayMode(false);
      }
    }, 100);
  }, [open]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        document.body.classList.add('modal-overflow');
      }, 0);
    } else {
      document.body.classList.remove('modal-overflow');
    }
    return () => document.body.classList.remove('modal-overflow');
  }, [open]);
  return (
    <>
      <div
        className={`modal custom-modal fade modal-dialog-centered confirmation-modal ${
          openModal ? 'show' : ''
        }`}
        id="rescheduleConfirmModal"
        style={{ display: displayMode ? 'flex' : 'none' }}
        tabIndex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <button
                type="button"
                className="btn-close"
                onClick={handleStopReschdule}
              ></button>
            </div>
            <div className="modal-body text-center">
              <h5 className="fw-600">
                {translate(
                  'myAppointments.reschedule',
                  'Reschedule Appointment'
                )}
              </h5>
              <p className="text-black">
                {translate(
                  'myAppointments.confirmation',
                  'Are you sure you want to reschedule current appointment ?'
                )}
              </p>
              <div className="confirmation-btns">
                <button
                  type="button"
                  className="btn btn-outlined-dark"
                  onClick={handleProceedReschdule}
                  ref={proccedRef}
                  onKeyDown={(e)=>{
                    if(e.key === "Tab") {
                      e.preventDefault()
                      cancelRef.current.focus()
                    }
                    if(e.key === "Enter" && !e.shiftKey){
                      e.preventDefault()
                      handleProceedReschdule()
                    }
                  }}
                >
                  {' '}
                  <span
                    data-bs-toggle="offcanvas"
                    data-bs-target="#rescheduleAppointment"
                    aria-controls="rescheduleAppointment"
                  >
                    {translate('myAppointments.continue', 'Yes, Reschedule')}
                  </span>
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleStopReschdule}
                  ref={cancelRef}
                  onKeyDown={(e)=>{
                    e.preventDefault()
                    if(e.key === "Tab") {
                      proccedRef.current.focus()
                    }
                    if(e.key === "Enter" && !e.shiftKey){
                      handleStopReschdule()
                    }
                  }}
                >
                  {translate('myAppointments.stop', 'No, Keep Appointment')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={handleStopReschdule}
        className={` ${openModal && 'offcanvas-backdrop fade show'}`}
      ></div>
    </>
  );
};

export default ConfirmReschedule;
