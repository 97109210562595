import React from 'react';
import expired from '../../../Assets/img/patient-portal/link-expired.svg';
import { connect } from 'react-redux';
import { fetchContactInformation } from 'Actions/contactAction';
import { translate } from '../../../Language/Translate';

function SubscriptionExp({ getContactInfo }) {
  const [adress, setAddress] = React.useState({});

  React.useEffect(() => {
    getContactInfo(setBranchDetails);
  }, []);

  const setBranchDetails = (data) => {
    const mainBranchData =
      data.find((item) => item.is_main_branch) || data[0] || {};
    setAddress(mainBranchData);
  };

  return (
    <section className="link-expired">
      <div className="wrapper">
        <div className="message-content">
          <img src={expired} alt="" />
          <h5 className="highlighted-text">
            {translate(
              'subscription.expired',
              'The link you’ve followed has expired'
            )}
          </h5>
          <p className="site">
            {translate('subscription.portal', 'The appointment booking portal is currently not accessible. Please contact the respective hospital/clinic for booking appointments')}{' '}
          </p>
          {adress.tele_country_code_primary && (
            <p className="tel">
              {adress.branch_name}{' '}
              {translate('subscription.support', 'Support')} :{' '}
              <a>
                {`+${adress.tele_country_code_primary} ${adress.phone_primary}`}
              </a>
            </p>
          )}
        </div>
      </div>
    </section>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getContactInfo: (setBranches) =>
    dispatch(fetchContactInformation(setBranches)),
});

export default connect(null, mapDispatchToProps)(SubscriptionExp);
