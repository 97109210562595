import React from 'react';
import './style/AntMultiDatePicker.css';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;
const AntMultiDatePicker = (props) => {
  const { start, end, onchangeDate, sx, format = 'DD-MM-YYYY',placeholder=['DD-MM-YYYY', 'DD-MM-YYYY'], disabledDates = () => {
    return false
  } } = props;
  return (
    <div>
      <RangePicker
        style={sx ?? ''}
        onChange={(e) => onchangeDate(e)}
        value={start && end ? [dayjs(start), dayjs(end)] : ''}
        placeholder={placeholder}
        format={format}
        disabledDate={disabledDates}
      />
    </div>
  );
};

export default AntMultiDatePicker;
