import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PrescriptionsApi } from 'Utils/Api/PrescriptionsApi';
import {
  dateinFormatDDMonthYYYY,
  timeIn12HourFormat,
} from 'Utils/DateFormatter/Date';
import { revertFromBase64 } from 'Utils/Services/Authenticator';
import { translate } from 'Language/Translate';
import './PrescriptionDetails.css';
import demoPhoto from 'Assets/img/patient-portal/User_Placeholder.svg';
import docDemoPhoto from 'Assets/img/icons/doctor-placeholder-avatar.svg';
import errorImg from '../../../Assets/img/patient-portal/error_img_placeHolder.png';
import noResult_T1 from 'Assets/img/icons/theme-1/no-results-found.svg';
import noResult_T2 from '../../../Assets/img/icons/theme-2/no-results-found.svg';
import Loader from '../../Common/Loader';
import { useSelector } from 'react-redux';
import { CardMedia, Tooltip } from '@mui/material';
import { removeDrPrefix } from 'Utils/StringFormatter/String';
import TableWrapper from 'Components/Common/TableWrpper';
// import { saveAs } from 'file-saver';


const PrescriptionView = () => {
  const [isLoading, setIsloading] = useState(false);
  const [showMore, setShowMore] = useState({});
  const [prescriptionDetails, setPrescriptionDetails] = useState({});
  const theme = useSelector(state => state?.SettingsReducer?.theme)

  const params = useParams();
  const { id: recordId } = params || {};
  const patientImgStatus = useRef(true)
  const doctorImgStatus = useRef(true)

  useEffect(() => {
    getPrescriptionsDetails(recordId);
  }, [recordId]);

  const getPrescriptionsDetails = (id) => {
    setIsloading(true);
    PrescriptionsApi.getPrescriptionsDetails(id).then((res) => {
      const { error, response } = res;
      if (error) {
        return;
      } else {
        setPrescriptionDetails(response.prescription_details);
        setIsloading(false);
      }
    });
  };

  // handle prescription download
  const handleDownload = (pdfData) => {
      fetch(pdfData.file_link, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-store', // IMPORTANT: for some reason Chrome's caching doesn't send Origin
      })
        .then((response) => {
          return response.blob()
        }).then((blob) => {
          const url =  URL.createObjectURL(blob)
          const ifr = document.createElement('iframe')
          ifr.src = url
          ifr.style.display = 'none'
          document.body.appendChild(ifr)
          ifr.onload = ()=> {
            ifr.contentWindow.print()
            // document.body.removeChild(ifr)};
            URL.revokeObjectURL(url)
          }
        })
        .catch((e) => console.log(e.message));
  };

  const activeDeptList = (data) => {
    return data
      ?.filter((item) => item.dept_status === 'active')
      .map((item) => item.dept_name);
      // .join(', ');
  };

  const toggleShowMore = (type) => {
    setShowMore((state) => ({
      ...state,
      [type]: !state[type],
    }));
  };

  const isMedicineEmpty = (data) => {
    return data.length && data[0].medicineName;
  };

  const {
    prescription = {},
    doctor_info = {},
    appointment_info = {},
  } = prescriptionDetails;

  const textData = prescription?.text_data
    ? revertFromBase64(prescription?.text_data)
    : {};
  return (
    <section>
      <section className="bread-crumbs mb-lg-2">
        <div className="wrapper links">
          <a className="previous" href="#/webConnect">
            Home
          </a>
          <span>{' > '} </span>
          <a href="#/webConnect/prescriptions">
            {' '}
            {translate('patientPortal.prescriptions', 'Prescriptions')}
          </a>
          <span> {'> '} </span>
          <a className="bread-crumb-cursor">
            {translate(
              'prescriptionView.prescriptionDetail',
              'Prescription Details'
            )}
          </a>
        </div>
      </section>
      <section className="main-header">
        <div className="wrapper border-bottom-0 mb-0 pb-0">
          <h1 className="main-title">
            {translate(
              'prescriptionView.prescriptionDetail',
              'Prescription Details'
            )}
          </h1>
        </div>
      </section>
      {isLoading ? (
        <Loader />
      ) : (
        <section className="prescription-details">
          <div className="wrapper">
            <div className="card p-4 mt-4">
              {prescriptionDetails ? 
              <div className="row">
                <div className="col-md-7 mb-4">
                  <div className="card detail-card">
                    <div className="profile">
                      <CardMedia
                        component="img"
                        image={
                          prescriptionDetails?.patient_profile_img || demoPhoto
                        }
                        className="main-img"
                        onError={(e) => {
                          if (patientImgStatus.current) {
                            e.target.src = errorImg
                            patientImgStatus.current = false
                          }
                        }}
                      />
                      <div>
                        <h5 className="fw-600">
                          {appointment_info?.patient_name}
                        </h5>
                        <p>
                          {translate('patientPortal.id', 'ID')} :{' '}
                          {prescriptionDetails?.patient_user_id}
                        </p>
                      </div>
                    </div>
                    <div className="remaining-info">
                      <div className="single-info">
                        <p>
                          {translate(
                            'prescriptionView.appointmentDateTime',
                            'Appt. Date & Time'
                          )}
                        </p>
                        <h6>
                          {dateinFormatDDMonthYYYY(appointment_info?.appt_date)},{' '}
                          {timeIn12HourFormat(
                            appointment_info?.appt_start_time
                          )}
                        </h6>
                      </div>
                      <div className="single-info">
                        <p>{translate('patientPortal.gender', 'Gender')}</p>
                        <h6 className="text-capitalize">
                          {prescriptionDetails?.patient_gender || '-' }
                        </h6>
                      </div>
                      {/* todo */}
                      <div className="single-info">
                        <p>{translate('patientPortal.relation', 'Relation')}</p>
                        <h6>{prescriptionDetails?.relation || '-'}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 mb-4">
                  <div className="card detail-card">
                    <div className="profile">
                      <CardMedia
                        component="img"
                        image={
                          prescriptionDetails?.doctor_profile_image || docDemoPhoto
                        }
                        className="main-img"
                        onError={(e) => {
                          if (doctorImgStatus.current) {
                            e.target.src = errorImg
                            doctorImgStatus.current = false
                          }
                        }}
                      />
                      <div>
                        <h5 className="fw-600 text-capitalize">
                          Dr. {removeDrPrefix(`${doctor_info?.name} ${doctor_info?.last_name}`)}
                        </h5>
                        <p className="text-capitalize"> { activeDeptList(doctor_info?.departments)?.slice(0,3).join(', ')} 
                        {activeDeptList(doctor_info?.departments)?.length > 3 ? (
                          <Tooltip title={
                            activeDeptList(doctor_info?.departments)?.slice(3)?.map((item, index) => (
                                <span
                                  className="text-capitalize fs-14 d-block"
                                  key={index}
                                >
                                  {item}
                                </span>
                            ))
                          }>
                                <span
                                  role="button"
                                  className="pointer extra-details text-nowrap"
                                  style={{ paddingBottom: 'unset' }}
                                >
                                  ...+{activeDeptList(doctor_info?.departments).length - 3}{' '}
                                  {translate('common.more', 'More')}
                                </span>
                            </Tooltip>
                              ) : null}

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> : null }

              <div className="notes">
                <div className="icon-container">
                  <i className="bi bi-file-earmark-text"></i>
                </div>
                <div>
                  <p>{translate('prescriptionView.notes', 'Notes')}:</p>
                  <h6>
                    {textData?.prescriptionNote ? (
                      <>
                        {textData?.prescriptionNote?.slice(
                          0,
                          showMore['notes']
                            ? textData?.prescriptionNote.length
                            : 210
                        )}{' '}
                        {textData?.prescriptionNote?.length > 150 ? (
                          <span
                            onClick={() => toggleShowMore('notes')}
                            className="ml-1 fs-15 fw-500"
                            style={{ color: '#4bb691', cursor: 'pointer' }}
                          >
                            {showMore['notes'] ? 'less' : 'more...'}
                          </span>
                        ) : null}
                      </>
                    ) : (
                      translate('prescriptionView.noNotes', 'No notes added')
                    )}
                  </h6>
                </div>
              </div>
            </div>
            <div>
              <div className="table-heading">
                <div className="icon-heading">
                  <svg
                    width="22px"
                    height="22px"
                    viewBox="0 0 22 22"
                    version="1.1"
                  >
                    <title>meds</title>
                    <g
                      id="Patient-Portal"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="26a-Prescription-Details"
                        transform="translate(-89, -430)"
                        fill="#000000"
                        fillRule="nonzero"
                      >
                        <g id="meds" transform="translate(89, 430)">
                          <path
                            d="M20.3004875,1.69995117 C19.2049105,0.603744496 17.7474421,0 16.1973189,0 C14.6470278,0 13.1900631,0.603744496 12.0939825,1.69995117 L6.8969153,6.89665221 L1.69968034,12.0940247 C0.603767528,13.1902313 0,14.647644 0,16.1973724 C0,17.7471009 0.603767528,19.2045135 1.69968034,20.3000488 C2.79525738,21.3962555 4.2527257,22 5.80284893,22 C7.35297216,22 8.81010476,21.3962555 9.90601753,20.3000488 L15.1032525,15.1033478 L20.3004875,9.90597532 C21.3964003,8.80976869 22,7.35235596 22,5.80262757 C22,4.25289915 21.3964003,2.79548646 20.3004875,1.69995117 L20.3004875,1.69995117 Z M8.81195111,19.2060241 C8.00793487,20.0098419 6.93921428,20.4527893 5.80284893,20.4527893 C4.66598001,20.4527893 3.59759511,20.0100098 2.79374671,19.2060241 C1.98989831,18.4020386 1.54743758,17.3338623 1.54743758,16.1975403 C1.54743758,15.0612183 1.99023399,13.9920349 2.79374671,13.1882172 L7.44394851,8.53785707 L13.4621529,14.5565033 L8.81195111,19.2060241 Z M19.2064211,8.81195068 L14.5562193,13.4623108 L8.53801488,7.44366455 L13.1882167,2.79397585 C13.9920651,1.99015807 15.0607857,1.54721067 16.1973189,1.54721067 C17.33402,1.54721067 18.4024048,1.98999023 19.2062533,2.79397585 C20.0101017,3.59796143 20.4525624,4.6661377 20.4525624,5.8024597 C20.4525624,6.93878174 20.0099339,8.00796511 19.2064211,8.81195068 Z"
                            id="Shape"
                          ></path>
                          <path
                            d="M18.2664439,5.21701048 C18.0196998,5.21701048 17.7771521,5.09918213 17.6272593,4.88081361 C17.2652002,4.35192871 16.667979,4.0368805 16.0291301,4.0368805 C15.5123105,4.0368805 15.0268794,4.23779297 14.6611275,4.60353089 C14.3588241,4.90565492 13.8691967,4.90565492 13.5670611,4.60353089 C13.2649256,4.30123899 13.2649256,3.81163024 13.5670611,3.50950621 C14.2248773,2.85137939 15.0990562,2.48950195 16.0291301,2.48950195 C17.1787559,2.48950195 18.2535192,3.05615234 18.9041177,4.00599671 C19.1454905,4.35864258 19.0556891,4.84002686 18.7028619,5.08189391 C18.5694187,5.1732025 18.4171759,5.21701048 18.2664439,5.21701048 L18.2664439,5.21701048 Z"
                            id="Path"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <h6>
                    {translate('prescriptionView.medicines', 'Medicines')}
                  </h6>
                </div>
                <button
                  onClick={() => handleDownload(prescription)}
                  className="btn btn-outlined-dark"
                >
                  <i className="bi bi-printer"></i>{' '}
                  {translate('common.print', 'Print')}
                </button>
              </div>
              <TableWrapper data={textData?.medicines}>
                <div className="table-container mb-5">
                  {textData?.medicines?.length &&
                    isMedicineEmpty(textData?.medicines) ? (
                    <table>
                      <thead>
                        <th>{translate('prescriptionView.slNo', 'Sl.No')}</th>
                        <th>
                          {translate('prescriptionView.drugName', 'Drug Name')}
                        </th>
                        <th>{translate('prescriptionView.dosage', 'Dosage')}</th>
                        <th>
                          {translate('prescriptionView.duration', 'Duration')}
                        </th>
                      </thead>
                      <tbody>
                        {textData?.medicines?.map((item, index) => (
                          <tr key={index}>
                            <td width="70">{index + 1}</td>
                            <td>{item.medicineName}</td>
                            <td>{item.dosage}</td>
                            <td>{item.duration}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="wrapper">
                      <div className="empty">
                        <div className="no-results w-100">
                          <img className="no-results-img" src={theme === "theme-2" ? noResult_T2 : noResult_T1} alt="" />
                          <h6>No results found.</h6>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </TableWrapper>
              <div className="advice mb-5">
                <div className="icon-heading mb-2 pb-1">
                  <i className="bi bi-chat-dots"></i>
                  <h6>{translate('prescriptionView.advices', 'Advice')} :</h6>
                </div>
                <p>
                  {textData?.advice ? (
                    <>
                      {textData?.advice?.slice(
                        0,
                        showMore['advices'] ? textData?.advice.length : 210
                      )}{' '}
                      {textData?.advice?.length > 150 ? (
                        <span
                          onClick={() => toggleShowMore('advices')}
                          className="ml-1 fs-15 fw-500"
                          style={{ color: '#4bb691', cursor: 'pointer' }}
                        >
                          {showMore['advices'] ? 'less' : 'more...'}
                        </span>
                      ) : null}
                    </>
                  ) : (
                    translate('prescriptionView.noAdvices', 'No advices added')
                  )}
                </p>
              </div>
            </div>
          </div>
        </section>
      )}
    </section>
  );
};

export default PrescriptionView;
