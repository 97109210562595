import React, { useEffect, useState,useRef } from 'react'
import { translate } from '../../Language/Translate';
import CloseIcon from '@mui/icons-material/Close';
import { validateEmail } from 'Utils/Validation/validator';
import AntDatepicker from '../Common/AntdDatePicker';
import { formatDate } from 'Utils/DateFormatter/Date';
import moment from 'moment';
import ReactSelectDropDown from '../Common/ReactSelectDropDown';
import PhoneNumberInput from '../Common/PhoneInput';
import { timeZoneDate } from 'Utils/DateFormatter/Date';
import {
  BLOOD_GROUP_LABELS,
  BLOOD_GROUP_OPTIONS,
  GENDER_LABEL,
  GENDER_LIST,
} from '../../Constants/constant';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import VerifyData from 'Components/Settings/VerifyData';
import { showToaster, ToasterTypes } from 'Actions/toasterAction';
import {
  validateMobile,
} from '../../Utils/Validation/validator';
import { validatePhoneMail } from 'Actions/settingsAction';
import { updatePatientDetails, verifyPhoneMail_Otp } from 'Actions/usersAction';
import tick from '../../Assets/img/icons/verified-tick.svg'
import { Fetch_Patient_Data } from 'Utils/Api/PatientsApi';
import Loader from 'Components/Common/Loader';
import ButtonLoader from 'Components/Common/ButtonLoader';
let Interval = null;
function EditPatientModal({
  isOpen,
  setIsOpen,
  applicationData,
  item,
  displaytoaster,
  otpDetails,
  validateData,
  verifyOTP,
  updatePatientData,
  fetchUsers,
  isBtnLoading,
  isVerifyLoading,
  defaultCountryCode
}) {
  const firstNameRef = useRef()
  const numField = useRef()
  const focusRef = useRef()
  const [numberLength, setNumberLength] = useState(10);
  const [code,setCode]=useState('IN')
  const [patientData, setPatientData] = useState({country:""})
  const [validateType, setValidateType] = useState('');
  const [feildError, setFeildError] = useState({
    name: false,
    phone: false,
    dob: false,
    gender: false,
    country: false,
    email: false,
  });
  const [resend, setResend] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const [resendCounterPhn, setResendCounterPhn] = useState(0);
  const [resendCounterEmail, setResendCounterEmail] = useState(0);
  const [showResend, setShowResend] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [showVerify, setShowVerify] = useState(false);
  const [resendType, setResendType] = useState('');
  const [otpData, setOtpData] = useState({
    otp: '',
    token: '',
    len: '',
    exp: '',
  });
  const [resendLoader, setResendLoader] = useState(false)

  const [previousData, setPreviousData] = useState({});
  const { otp_limit_duration, otp_resend_limit } = otpDetails || {};
  const intl = useIntl();
  useEffect(() => {
    getPatientDetails()
  }, [item])

  const getPatientDetails = async () => {
    setIsLoading(true)
    let { response } = await Fetch_Patient_Data({ patient_id: item.patient_id })
    if (response) {
      let patient = response?.patients[0]
      let obj = {
        name: patient?.name,
        last_name: patient?.last_name,
        mrn: applicationData?.use_system_generated_mrn ?patient?.patient_id: patient?.mrn ? patient.mrn : "",
        emailId: patient?.email,
        country: patient?.tele_country_code,
        phoneNo: patient?.phone?patient?.phone:"",
        dob: patient?.dob,
        gender: patient?.gender,
        bloodGroup: patient?.blood_group,
        phoneVerified: patient?.phone_verified,
        emailVerified: patient?.email_verified
      }
      setPreviousData({ ...obj });
      setPatientData({ ...obj })
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }

  const getPatientVerified = async () => {
    setIsLoading(true);
    let { response } = await Fetch_Patient_Data({
      patient_id: item.patient_id,
    });
    if (response) {
      let patient = response?.patients[0];
      let obj = {
        ...(validateType == "phone" && {
          phoneVerified: patient?.phone_verified,
        }),
        ...(validateType == "email" && {
          emailVerified: patient?.email_verified,
        }),
      };
      setPreviousData({ ...previousData, ...obj });
      setPatientData({ ...patientData, ...obj });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  // handle input data change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'name') {
      setFeildError((prev) => ({
        ...prev,
        name: false,
      }));
    } else if (name === 'emailId' && (validateEmail(value) || !value)) {
      setFeildError((prev) => ({
        ...prev,
        // name: false,
        email: false
      }));
    }
    setPatientData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // handle dob change
  const handleDobChange = (date) => {
    setFeildError((prev) => ({
      ...prev,
      dob: false,
    }));
    setPatientData((prev) => ({
      ...prev,
      dob: formatDate(date),
    }));
  };

  // handle bloodgroup change
  const handleBloodGroupChange = (value) => {
    setPatientData((prev) => ({
      ...prev,
      bloodGroup: value?.value,
    }));
  };

  // handle gender change
  const handleGenderChange = (value) => {
    setFeildError((prev) => ({
      ...prev,
      gender: "",
    }));
    setPatientData((prev) => ({
      ...prev,
      gender: value?.value,
    }));
  };

  // disable futeure dates for ant datepicker
  const disableFutureDates = (current) => {
    return current && current > moment();
  };

  const remainingTime = (targetTime) => {
    let currentTime = timeZoneDate().getTime();
    let leftSecond = Math.trunc((targetTime - currentTime) / 1000);
    setCountdown(leftSecond);
    if (leftSecond <= 0) {
      clearInterval(Interval);
    }
    return leftSecond;
  };

  let remainingResendTimePhn = (targetTime, Interval) => {
    let currentTime = timeZoneDate().getTime();
    let leftSecond = Math.trunc((targetTime - currentTime) / 1000);
    setResendCounterPhn(leftSecond);
    if (leftSecond <= 0) {
      clearInterval(Interval);
      setShowResend(false);
      setResend(0);
    } else if (!showResend) {
      setShowResend(true);
    }
    return leftSecond;
  };
  let remainingResendTimeEmail = (targetTime, Interval) => {
    let currentTime = timeZoneDate().getTime();
    let leftSecond = Math.trunc((targetTime - currentTime) / 1000);
    setResendCounterEmail(leftSecond);
    if (leftSecond <= 0) {
      clearInterval(Interval);
      setShowResend(false);
      setResend(0);
    } else if (!showResend) {
      setShowResend(true);
    }
    return leftSecond;
  };

  // handle close
  const handleClose = (type = '') => {
    setResend(0);
    setCountdown();
    remainingTime(0);
    setShowVerify(false);
    setValidateType('');
    setOtpData({
      otp: '',
      token: '',
      len: '',
      exp: '',
    });
    if (type === 'phone') {
      setPatientData({
        ...patientData,
        phoneVerified: true
      })
      setPreviousData((prev) => ({
        ...prev,
        phone: patientData?.phoneNo,
        country: patientData?.country,
      }));
    } else if (type === 'email') {
      setPatientData({
        ...patientData,
        emailVerified: true
      })
      setPreviousData((prev) => ({
        ...prev,
        email: patientData?.emailId,
      }));
    }
  };

  // handle OTP verification
  const handleOtpVerification = () => {
    let config = {};
    if (validateType === 'phone') {
      config = {
        data: {
          update_data: {
            tele_country_code: patientData?.country,
            phone: patientData?.phoneNo,
          },
          otp_verification: {
            otp: Array.isArray(otpData.otp) ? otpData.otp.join('') : otpData.otp,
            token: otpData.token,
          },
        },
      };
    } else {
      config = {
        data: {
          update_data: {
            email: patientData?.emailId,
          },
          otp_verification: {
            otp: Array.isArray(otpData.otp) ? otpData.otp.join('') : otpData.otp,
            token: otpData.token,
          },
        },
      };
    }
    verifyOTP({ id: item?.user_id, type: validateType, data: config }, handleOtpSuccess);
  };

  const handleOtpSuccess = () => {
    getPatientVerified()
    handleClose()
  }
  // handle validate success on verify btn next to number/email
  const handleValidateSuccess = (data) => {
    const { otp_token } = data;
    const { token, exp, len } = otp_token || {};
    setOtpData((prev) => ({
      ...prev,
      token,
      len,
      exp,
    }));
    let targetTime = timeZoneDate().getTime() + exp * 60 * 1000 + 1000;
    if (Interval) clearInterval(Interval);
    Interval = setInterval(() => remainingTime(targetTime), 1000);
    setShowVerify(true);
    setResendCounterPhn(0);
    setResendCounterEmail(0);
    const timer = setTimeout(() => {
      setResendLoader(false);
    }, 3000);
    return () => clearTimeout(timer);
  };

  //  verify btn click next to number/email
  const handleVerify = (type) => {
    setValidateType(type);
    setResend(0);
    setCountdown();
    let config = {};
    if (type === 'phone') {
      setResendLoader(true)
      const resendDetails = localStorage.getItem('Resend');
      const { settingsOtpPhn } = JSON.parse(resendDetails);
      if (
        settingsOtpPhn &&
        (settingsOtpPhn - timeZoneDate().getTime()) / 1000 > 0
      ) {
        displaytoaster(
          intl.formatMessage({
            id: 'commom.otpLimitMsg',
            defaultMessage:
              'OTP send limit exceeded please try again after 5 min',
          }),
          ToasterTypes.Warning
        );
      }
       else if (previousData?.phoneNo?.trim() &&(!patientData?.phoneNo?.trim() || !patientData?.country)) {
        setFeildError((prev) => ({
          ...prev,
          phoneNo: translate('common.required', 'This field is required'),
          country: !patientData?.country,
        }));
      } 
      else if (!validateMobile(patientData?.phoneNo?.trim(), code)) {
        setFeildError((prev) => ({
          ...prev,
          phoneNo: translate('common.invalidPhone', 'Invalid mobile number.')
        }));
      } else {
        config = {
          data: {
            tele_country_code: patientData?.country,
            phone: patientData?.phoneNo?.replace(/\s/g, "")
          },
        };
        setFeildError((prev) => ({
          ...prev,
          phoneNo: false,
          country: false,
          // phoneVerifyError: false,
          // invalidPhone: false
        }));
        setResendType('settingsOtpPhn');
        setResendCounterPhn(0);
        setResendCounterEmail(0);
        validateData({ id: item.user_id, type, data: config }, handleValidateSuccess);
      }
    } else {
      setResendLoader(true)
      const resendDetails = localStorage.getItem('Resend');
      const { settingsOtpEmail } = JSON.parse(resendDetails);
      if (
        settingsOtpEmail &&
        (settingsOtpEmail - timeZoneDate().getTime()) / 1000 > 0
      ) {
        displaytoaster(
          intl.formatMessage({
            id: 'commom.otpLimitMsg',
            defaultMessage:
              'OTP send limit exceeded please try again after 5 min',
          }),
          ToasterTypes.Warning
        );
      } else if (patientData?.emailId && !validateEmail(patientData?.emailId)) {
        setFeildError((prev) => ({
          ...prev,
          email: intl.formatMessage({ id: 'appointments.enterValidEmail', defaultMessage: 'Please enter valid emailId' })
        }));
      } else {
        config = {
          data: {
            email: patientData?.emailId,
          },
        };
        setFeildError((prev) => ({
          ...prev,
          email: "",
        }));
        setResendType('settingsOtpEmail');
        setResendCounterPhn(0);
        setResendCounterEmail(0);
        validateData({ id: item.user_id, type, data: config }, handleValidateSuccess);
      }
    }
  };

  // handle resend otp
  const handleResend = () => {
    handleVerify(validateType);
    setOtpData((prev) => ({
      ...prev,
      otp: '',
      token: '',
    }));
    remainingTime(0);
    setResend(resend + 1);
    if (resend + 1 >= otp_resend_limit) {
      let targetResendTime =
        timeZoneDate().getTime() + otp_limit_duration * 60 * 1000 + 1000;
      const resendlocal = JSON.parse(localStorage.getItem('Resend'));
      localStorage.setItem(
        'Resend',
        JSON.stringify({ ...resendlocal, [resendType]: targetResendTime })
      );
      if (resendType === 'settingsOtpPhn') {
        let IntervalRemaining = setInterval(
          () => remainingResendTimePhn(targetResendTime, IntervalRemaining),
          1000
        );
      } else if (resendType === 'settingsOtpEmail') {
        let IntervalRemaining = setInterval(
          () => remainingResendTimeEmail(targetResendTime, IntervalRemaining),
          1000
        );
      }
    }
  };
  // handle phone number chnange
  const numberChange = (e) => {
    const inputValue = e.target.value;
    // const regex = /^[0-9]*\.?[0-9]*$/;
    const regex = /^\+?[0-9 ]*\.?[0-9 ]*$/;
    if (regex.test(inputValue?.trim())) {
      if (
        inputValue === previousData.phoneNo &&
        patientData?.country === previousData.country &&
        previousData?.phoneVerified
      ) {
        setPatientData({ ...patientData, phoneVerified: true })
        // setPhoneVerified(true);
        setFeildError((prev) => ({
          ...prev,
          phoneNo: false,
          // phoneVerifyError: false
        }));
      } else {
        setFeildError((prev) => ({
          ...prev,
          phoneNo: false,
        }));
        setPatientData({ ...patientData, phoneVerified: false })
        // setPhoneVerified(false);
      }

      if (inputValue.trim() && validateMobile(inputValue.trim(), code)) {
        setFeildError((prev) => ({
          ...prev,
          phoneNo: false,
        }));
      }
      setPatientData((prev) => ({
        ...prev,
        phoneNo: inputValue,
      }))
    }
  };

  useEffect(() => {
    document.body.classList.add('modal-overflow');
    return () => {
      document.body.classList.remove('modal-overflow');
      //   clearCountdown();
    };
  }, []);

  // handle country code channge
  const handleMobileNumberChange = (phone) => {
    if (
      phone === previousData.country &&
      patientData?.phoneNo === previousData.phoneNo &&
      previousData?.phoneVerified
    ) {
      setPatientData({ ...patientData, phoneVerified: true })
      //   setPhoneVerified(true);
      setFeildError((prev) => ({
        ...prev,
        country: false,
        // phoneVerifyError: false
      }));
    } else {
      setPatientData({ ...patientData, phoneVerified: false })
      setFeildError((prev) => ({
        ...prev,
        country: false,
      }));
    }
    setPatientData((prev) => ({
      ...prev,
      country: phone
    }))
  };

  // handle profile info
  const handleEmailChange = (event) => {
    const { value, name } = event.target;
    if (value === previousData.emailId && previousData?.emailVerified) {
      setPatientData((prev) => ({
        ...prev,
        emailVerified: true,
        [name]: value,
      }));
    } else {
      setPatientData((prev) => ({
        ...prev,
        emailVerified: false,
        [name]: value,
      }));
    }
    if (value && !validateEmail(value)) {
      setFeildError((prev) => ({
        ...prev,
        email: intl.formatMessage({
          id: 'appointments.enterValidEmail',
          defaultMessage:
            'Please enter valid emailId',
        }),
      }));
    } else {
      setFeildError((prev) => ({
        ...prev,
        email: "",
      }));
    }

  };

  //handleConfirm
  let handleConfirm = () => {
    if (validateForm()) {
      let payload = {
        patientId: item?.patient_id,
        data: {
          name: patientData?.name,
          last_name: patientData?.last_name,
          blood_group: patientData?.bloodGroup,
          dob: patientData?.dob,
          email: patientData?.emailId,
          gender: patientData?.gender,
          profile_image_update: false,
          mrn: patientData?.mrn
        }
      }
      updatePatientData(payload,()=>{
        handleModalClose()
        fetchUsers();
      })
    }
  }

  //validate before submitting
  let validateForm = () => {
    let valid = true
    let errorObj = {}

    //email validation
    if (patientData?.emailId && !validateEmail(patientData?.emailId)) {
      valid = false
      errorObj.email = intl.formatMessage({
        id: 'appointments.enterValidEmail',
        defaultMessage:
          'Please enter valid emailId',
      })
    }
    else if (previousData?.emailId && !patientData?.emailId) {
      valid = false
      errorObj.email = translate('common.required', 'This field is required')
    }
    else if ( previousData?.emailId !==patientData?.emailId && patientData?.emailId && validateEmail(patientData?.emailId) && !patientData?.emailVerified) {
      valid = false
      errorObj.email = translate('settings.verifyEmail', 'Please verify email')
    }

    //phone validation
    if (patientData?.phoneNo?.trim() && (!validateMobile(patientData?.phoneNo?.trim(), code))) {
      valid = false;
      errorObj.phoneNo = translate('common.invalidPhone', 'Invalid mobile number.')
    }
    else if (previousData?.phoneNo?.trim() &&(!patientData?.phoneNo?.trim() || !patientData?.country)) {
      valid = false
      errorObj.phoneNo = translate('common.required', 'This field is required')
      errorObj.country = !patientData?.country
    }
    else if ((previousData?.phoneNo !==patientData?.phoneNo || previousData?.country!== patientData?.country) && patientData?.phoneNo && (validateMobile(patientData?.phoneNo, code)) && !patientData?.phoneVerified) {
      valid = false
      errorObj.phoneNo = translate('settings.verifyPhone', 'Please verify the phone number')
    }


    if (!patientData?.gender) {
      valid = false
      errorObj.gender = translate('common.required', 'This field is required')
    }
    if (!patientData?.dob) {
      valid = false
      errorObj.dob = translate('common.required', 'This field is required')
    }
    if (!patientData?.name?.trim()) {
      valid = false
      errorObj.name = translate('common.required', 'This field is required')
    }
    if (!valid) {
      setFeildError((prev) => ({
        ...prev,
        ...errorObj
      }));
    }
    return valid
  }

  const handleKeyDown = (e) => {
    const dropDownBtn = document.querySelector(".selected-flag");
    if (e.key === "Enter") {
      if (!dropDownBtn.classList.contains("open")) {
        e.preventDefault();
        dropDownBtn.classList.toggle("open");
        dropDownBtn.click();
      } else {
        numField.current?.focus();
      }
    }
    if (e.key === "Tab" && dropDownBtn.classList.contains("open")) {
      e.preventDefault();
      focusRef.current?.focus();
      dropDownBtn.click();
    }
  };

  const handleModalClose = () => {
    setIsOpen(false)
  }
  return (
    <>
      <div className="AppointmentModal d-flex">
        <div
          className={`modalContent editPatient_popup ${
            isOpen ? "adda-patient-info-popup" : ""
            }`}
        >
          <div className="modalHeader">
            <div className="row">
              <div className="col-md-12">
                <h4>
                  {translate(
                    "patients.editPatientInfo",
                    "Edit Patient Information"
                  )}
                  <CloseIcon className='close-icon'
                    style={{
                      float: "right",
                      cursor: "pointer",
                      color: "#75808E",
                    }}
                    onClick={handleModalClose}
                  />
                </h4>
              </div>
              {isLoading && <Loader />}
              <div className="px-0">
                <div className="border_btm mt-2"></div>
                <div
                  className="modalContentarea-popup pb-0"
                  style={{
                    maxHeight: 430,
                    marginBottom: 10,
                      overflowX: "auto",
                  }}
                >
                  <div className="container appointmentLabel mb-1 admin-add-patient mb-4">
                    {/* //here */}
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="User name">
                              {translate("common.firstName", "First Name")}
                            <span className="sup">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            id="name"
                            maxLength="40"
                            autoComplete="off"
                            style={{
                                borderColor: feildError?.name ? "red" : "",
                            }}
                            value={patientData?.name}
                            onChange={handleInputChange}
                           autoFocus
                            onKeyDown={(e)=>{
                              if(e.key === "Tab" && e.shiftKey)e.preventDefault()}}
                            ref={firstNameRef}
                            onFocusCapture={() => {
                              setTimeout(() => {
                                firstNameRef?.current?.setSelectionRange(
                                  patientData?.name?.length,
                                  patientData?.name?.length
                                );
                              }, 0);
                            }}
                          />
                          {feildError?.name ? (
                            <p
                              className="fs-10 fw-500"
                                style={{ color: "red" }}
                            >
                              {translate(
                                  "common.required",
                                  "This field is required"
                              )}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="User name">
                              {translate("common.lastName", "Last Name")}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            id="last_name"
                            maxLength="40"
                            autoComplete="off"
                            // style={{ borderColor: nameError && 'red' }}
                            value={patientData?.last_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div
                            className={`form-group ${
                              patientData.dob ? "" : feildError?.dob && "error"
                            }`}
                        >
                          <label htmlFor="User name">
                              {translate("appointments.dob", "Date of birth")}
                            <span className="sup">*</span>
                          </label>
                          <AntDatepicker
                            onInputChange={handleDobChange}
                            value={patientData?.dob}
                            placeholder={"DD/MM/YYYY"}
                            className="form-control"
                            disabledDate={disableFutureDates}
                            isAdmin
                          />
                          {feildError?.dob ? (
                            <p
                              className="fs-10 fw-500"
                                style={{ color: "red" }}
                            >
                              {translate(
                                  "common.required",
                                  "This field is required"
                              )}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-3">
                          <div className={"form-group"}>
                          <label htmlFor="User name">
                              {translate("common.bloodGroup", "Blood Group")}
                          </label>
                          <ReactSelectDropDown
                            options={BLOOD_GROUP_OPTIONS}
                            value={
                              patientData?.bloodGroup
                                ? {
                                      label:
                                        BLOOD_GROUP_LABELS[
                                          patientData?.bloodGroup
                                        ],
                                  value: patientData?.bloodGroup,
                                }
                                : []
                            }
                            onInputChange={handleBloodGroupChange}
                            emptyClass
                          />
                        </div>
                      </div>
                      {applicationData?.use_system_generated_mrn ? (
                          ""
                      ) : (
                        <div className="col-md-3">
                            <div className={"form-group"}>
                            <label htmlFor="User name">
                              {translate(
                                  "appointments.patientMRN",
                                  "Patient MRN"
                              )}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="mrn"
                              disabled={previousData?.mrn}
                              value={patientData?.mrn}
                              maxLength="40"
                              id="departmentname"
                              autoComplete="off"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-md-3">
                          <div className={"form-group"}>
                          <label htmlFor="User name">
                              {translate("appointments.gender", "Gender")}
                            <span className="sup">*</span>
                          </label>
                          <ReactSelectDropDown
                            options={GENDER_LIST}
                            value={
                              patientData?.gender
                                ? {
                                  label: GENDER_LABEL[patientData?.gender],
                                  value: patientData?.gender,
                                }
                                : []
                            }
                            onInputChange={handleGenderChange}
                            validationErr={
                                patientData.gender ? "" : feildError?.gender
                            }
                            customClass="mb-0"
                            emptyClass
                          />
                          {feildError?.gender ? (
                            <p
                              className="fs-10 fw-500"
                                style={{ color: "red" }}
                            >
                              {translate(
                                  "common.required",
                                  "This field is required"
                              )}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                          <div className="form-group">
                          <label htmlFor="User name">
                            {translate(
                                "appointments.mobile",
                                "Mobile Number"
                            )}
                              {previousData?.phoneNo ? (
                                <span className="sup">*</span>
                              ) : null}
                          </label>
                          <div className="verify-btn-wrap d-flex gap-3">
                            <div
                                className={`${
                                  feildError?.country && "error"
                                } code-area`}
                                onKeyDown={(e) => {
                                  if (e.key === "Tab" && !e.shiftKey) {
                                    e.preventDefault();
                                    numField.current.focus();
                                  }
                                  handleKeyDown(e);
                                }}
                            >
                              <PhoneNumberInput
                                value={patientData?.country ?? 
                                  defaultCountryCode}
                                onChange={handleMobileNumberChange}
                                validationErr={feildError?.country}
                                numberLength={numberLength}
                                setNumberLength={setNumberLength}
                                code={code}
                                setCode={setCode}
                                defaultCountry={true}
                                focusRef={focusRef}
                              />
                            </div>
                            <div className='w-100'>
                              <div className="verify-input-container">
                              <input
                                type="text"
                                className="form-control"
                                id="password"
                                value={patientData?.phoneNo}
                                name="phoneNo"
                                maxLength={numberLength}
                                style={{
                                  // marginLeft: 10,
                                  borderColor:
                                    (feildError?.phoneNo ||
                                      feildError?.country) &&
                                      "red",
                                }}
                                onChange={numberChange}
                          ref={numField}
                  onKeyDown={(e) => {
                    if (e.shiftKey && e.key === "Tab") {
                      e.preventDefault();
                      focusRef.current.focus();
                    }
                  }}
                              />
                              {patientData?.phoneVerified ? (
                                <div className="badge-verified">
                                  <img src={tick} alt="" />
                                    {translate("settings.verified", "Verified")}
                                </div>
                              ) : (
                                <div>
                                  <button
                                      disabled={
                                        !patientData?.phoneNo ||
                                        !patientData?.country
                                      }
                                    className="verify-btn"
                                      onClick={() => handleVerify("phone")}
                                  >
                                    {translate('settings.verify', 'Verify')}
                                  </button>
                                </div>
                              )}
                            </div>
                          {feildError?.phoneNo ? (
                            <p
                              className="fs-10 fw-500"
                                style={{ color: "red" }}
                            >
                              {feildError.phoneNo}
                            </p>
                          ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                          <div className="form-group">
                          <label htmlFor="User name">
                              {translate("common.emailId", "Email ID")}
                              {previousData?.emailId ? (
                                <span className="sup">*</span>
                              ) : null}
                          </label>
                          <div className="verify-input-container">
                            <input
                              type="email"
                              className="form-control"
                              id="password"
                              value={patientData?.emailId}
                              style={{
                                  borderColor: feildError?.email && "red",
                              }}
                              name="emailId"
                              maxLength="50"
                              onChange={handleEmailChange}
                            />
                            {patientData?.emailVerified ? (
                                <div className="badge-verified">
                                <img src={tick} alt="" />
                                  {translate("settings.verified", "Verified")}
                              </div>
                            ) : (
                              <div>
                                <button
                                  disabled={!patientData?.emailId}
                                  className="verify-btn"
                                    onClick={() => handleVerify("email")}
                                >
                                    {translate("settings.verify", "Verify")}
                                </button>
                              </div>
                            )}
                          </div>
                          {feildError?.email ? (
                            <p
                              className="fs-10 fw-500"
                                style={{ color: "red" }}
                            >
                              {feildError.email}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                    <ul className="admin-modal-footer v2">
                      <li
                        onClick={handleModalClose}
                        className="ms-0 d-block d-sm-inline"
                      >
                      <button
                        type="button"
                        className="btn btn-light mb-0"
                        id="cancel"
                      >
                          {" "}
                          {translate("common.cancel", "CANCEL")}{" "}
                      </button>
                    </li>

                      <li
                        onClick={isBtnLoading ? () => {} : handleConfirm}
                        className="ms-0 d-block d-sm-inline ps-0"
                      >
                        {" "}
                      <button
                        disabled={isBtnLoading}
                        type="button"
                        className="btn btn-primary mb-0 loader-btn"
                        id="save"
                        onKeyDown={(e)=>{
                          if(e.key === "Tab" && !e.shiftKey) e.preventDefault()
                        }}
                      >
                          {isBtnLoading ? (
                            <ButtonLoader
                              text={translate("common.update", "Update")}
                            />
                          ) : (
                            translate("common.update", "Update")
                          )}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVerify && (
        <VerifyData
          onClose={handleClose}
          otpData={otpData}
          countdown={countdown}
          country={patientData?.country}
          phone={patientData?.phoneNo}
          email={patientData?.emailId}
          setOtpData={setOtpData}
          handleResend={handleResend}
          onConfirm={handleOtpVerification}
          validateType={validateType}
          resendCount={resend}
          otp_resend_limit={otp_resend_limit}
          otp_limit_duration={otp_limit_duration}
          resendCounterPhn={resendCounterPhn}
          resendCounterEmail={resendCounterEmail}
          resendType={resendType}
          counter={
            resendType === "settingsOtpPhn"
              ? resendCounterPhn
              : resendCounterEmail
          }
          isVerifyLoading={isVerifyLoading}
          resendLoader={resendLoader}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  defaultCountryCode:
    state.SettingsReducer.subscriptionData.setting.defaultCountryCode,
  applicationData: state.SettingsReducer.applicationData,
  otpDetails: state.SettingsReducer.otpDetails,
  isBtnLoading: state.UsersReducer.isBtnLoading,
  isVerifyLoading:state.UsersReducer.isVerifyLoading
  // profileDetails: state.LoginReducer.loginDetails, // shoul be removed
});
const mapDispatchToProps = (dispatch) => ({
  updatePatientData: (payload, handleSuccess, handleError) => {
    dispatch(updatePatientDetails(payload, handleSuccess, handleError))
  },
  verifyOTP: (payload, handleSuccess) =>
    dispatch(verifyPhoneMail_Otp(payload, handleSuccess)),
  validateData: (payload, handleSuccess) =>
    dispatch(validatePhoneMail(payload, handleSuccess)),
  displayToaster: (message, type) => dispatch(showToaster(message, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditPatientModal);