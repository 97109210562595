export const APPOINTMENT_FEATURE = 'APPOINTMENT';
export const ADD_APPOINTMENT = 'ADD_APPOINTMENT';
export const DOCTOR_LISTING = 'DOCTOR_LISTING';
export const DOCTOR_PROFILE = 'DOCTOR_PROFILE';
export const DOCTOR_EDIT = 'DOCTOR_EDIT';
export const LEAVE_APPROVAL = 'LEAVE_APPROVAL';
export const DEPARTMENTS = 'DEPARTMENTS';
export const USER_FEATURE = 'USER_FEATURE';
export const PORTAL_SETTINGS = 'PORTAL_SETTINGS';
export const GENERAL_SETTINGS = 'GENERAL_SETTINGS';
export const SLOT_MANAGEMENT = 'SLOT_MANAGEMENT';

   export const SUBSCRIPTION  = "subscription"
   export const CLIENT        = "client"
   export const USER          = "user"
   export const DEPARTMENT    = "department"
   export const APPOINTMENT   = "appointment"
   export const DOCTOR        = "doctor"
   export const PATIENT       = "patient"
   export const PATIENTRECORDS= "patient_records"
   export const PATIENTFAMILY = "patient_family"

