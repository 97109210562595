import React, { useEffect } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import './Assets/style/font.css';
import { Provider } from 'react-redux';
import { IntlProvider } from './Language/Index';
import store from './store';
import Authentication from './Components/Authentication';
import LoggedinPages from './Components/Home';
import NotFound from './Components/NotFound/NotFound';
import ErrorBoundary from './ErrorBoundary';
import UserLoggedinPages from './Components/Patient_Portal/LogIn/LoggedinPages';
import ClientActivation from './Components/Activation/ClientActivation';
import withSubscriptionData from 'Utils/WithUtils/withSubscriptionData';
import BrandingWrapper from 'Utils/Branding/BrandingWrapper';

function App() {
  useEffect(() => {
    const resendDetails = localStorage.getItem('Resend');
    if (!resendDetails) {
      localStorage.setItem('Resend', JSON.stringify({}));
    }
  }, []);

  return (
    <Provider store={store}>
      <BrandingWrapper>
      <IntlProvider>
        <ErrorBoundary>
          <HashRouter>
            <Switch>
              <Route path="/activate/:id" exact>
                <ClientActivation />
              </Route>
              <Route path="/admin/auth">
                <Authentication />
              </Route>
              <Route path="/admin">
                <LoggedinPages />
              </Route>
              <Route path="/webConnect">
                <UserLoggedinPages />
              </Route>
              <Route exact path="/">
                <Redirect to="/webconnect" />
              </Route>
              <Route component={NotFound} />
            </Switch>
          </HashRouter>
        </ErrorBoundary>
      </IntlProvider>
      </BrandingWrapper>
    </Provider>
  );
}

export default withSubscriptionData(App);
