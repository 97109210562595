import React from 'react';
import Error404 from '../../Assets/img/icons/404.png';
import '../../Assets/style/NoData.css';
import { translate } from '../../Language/Translate';

const Unauthorized = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#ffffff',
      }}
      className="my-5 d-flex justify-content-center align-items-center"
    >
      <div>
        <img className='d-block mx-auto' src={Error404} alt="404" />
        <h4 style={{ fontWeight: 'bold', color: '#24435b' }}>
          {translate(
            'common.noPermission',
            "You don't have permission to view this page"
          )}
          !
        </h4>
      </div>
    </div>
  );
};

export default Unauthorized;
