import React from 'react';
import Error404 from '../../Assets/img/icons/404.png';
import '../../Assets/style/NoData.css';
import { translate } from '../../Language/Translate';

const Unauthorized = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        zIndex: 99,
        backgroundColor: '#ffffff',
        position: 'fixed',
        top: 0,
        left: 0,
      }}
    >
      <div className="page-not-found">
        <img src={Error404} alt="404" />
        <h2>
          {translate(
            'common.noPermission',
            "You don't have permission to view this page"
          )}
          !
        </h2>
      </div>
    </div>
  );
};

export default Unauthorized;
