import { translate } from 'Language/Translate';
import React from 'react';
import Select from 'react-select';
import { createFilter } from 'react-select';
import { useIntl } from 'react-intl';

const ReactSelectDropDown = (props) => {
  const {
    onInputChange,
    emptyClass,
    value,
    id,
    options,
    label,
    isDisabled,
    isClearable = false,
    components,
    validationErr,
    customClass = '',
    isLoading = false,
    noOptionsMessage = () => (
      <div className="noOption">
        {translate('common.noData', 'No data found')}
      </div>
    ),
    isLowerCase = false,
    isCasePlaceholder = false,
    excludeValueFilter = false,
    customFilter = false,
    autoFocus=false,
    reference,
    targetBody = true
  } = props;
  
  const intl = useIntl();
  const customStyles = {
    placeholder: (base) => ({
      ...base,
      position: 'absolute',
      top: ' 16px',
      textTransform: isCasePlaceholder ? '' : 'uppercase',
      fontSize: '13px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontFamily: 'Poppins',
      lineHeight: 'normal',
      letterSpacing: '0.43px',
      color: '#636363',
      transition: 'all ease 0.3s',
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      boxShadow: 'none',
      cursor: 'text',
      fontSize: 13,
      textTransform: isLowerCase ? 'lowercase' : '',
      borderColor: validationErr ? 'red !important' : '#ced4da',
      '&:hover': {
        borderColor: validationErr ? 'red' : baseStyles.borderColor,
      },
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid #e7e7e7',
      color: state.isSelected ? '#fff' : state.isDisabled ? 'grey' : '#000',
      cursor: state.isDisabled ? 'no-drop' : 'pointer',
      fontFamily: 'Poppins',
      backgroundColor: state.isSelected
        ? '#007bff'
        : state.isDisabled
        ? 'rgba(206, 217, 224, 0.5)'
        : '#fff',
      ':hover': {
        backgroundColor: !state.isSelected && '#f5f5ff',
      },
      textTransform: isLowerCase ? 'lowercase' : '',
    }),
    dropdownIndicator: (defaultStyles) => {
      return {
        ...defaultStyles,
        cursor: 'pointer',
      };
    },
    clearIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    valueContainer: (provided) => ({
      ...provided,
      textTransform: isLowerCase ? 'lowercase' : '',
    }),
  };

  let className = emptyClass ? 'selectDrop' : 'form-group';
  className = className + customClass;

  return (
    <div className={`${className} ${validationErr ? `dropdown-error` : ''}`}>
      <Select
        // openMenuOnFocus={true}
        tabIndex="0"
        value={value ? value : ''}
        onChange={(option) => {
          onInputChange(option, id);
        }}
        maxMenuHeight={150}
        options={options}
        isOptionDisabled={(option) => option.disabled}
        placeholder={
          label ||
          `${intl.formatMessage({
            id: 'common.select',
            defaultMessage: 'select',
          })}`
        }
        styles={customStyles} // classNamePrefix="form-control"
        isDisabled={isDisabled ? isDisabled : ''}
        className={customClass}
        classNamePrefix="react-select"
        menuPortalTarget={targetBody ? document.body : ''}
        // menuIsOpen={true}
        isClearable={isClearable}
        components={components}
        isLoading={isLoading}
        noOptionsMessage={noOptionsMessage}
        tabSelectsValue={false}
        autoFocus={autoFocus}
        ref={reference}
        filterOption={createFilter(
          excludeValueFilter
            ? {
                // Ignore case for filtering
                ignoreCase: true,
                // Only consider the label property for filtering
                matchFrom: 'any',
                stringify: (option) => option.label,
              }
            : customFilter
            ? {
                ignoreCase: true,
                matchFrom: 'any',
                stringify: customFilter,
                trim: true,
              }
            : {}
        )}
      />
    </div>
  );
};

export default ReactSelectDropDown;
