import React from 'react'
import { ReactComponent as BtnLoader } from 'Assets/img/icons/btn-loader.svg'


function ButtonLoader({ text = '' }) {
    return (
        <>
            <div className='loader-gif'>
                <BtnLoader />
            </div>
            <span className="loader-btn-text">
                {text}
            </span>
        </>
    )
}

export default ButtonLoader