import { parsePhoneNumber } from "libphonenumber-js";
// check for valid email format
export const validateEmail = (email) => {
  const regex = /^[a-zA-Z0-9._%+'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};

//cheack for valid mobile number
// export const validateMobile = (mob,len) => {
//   const regex = new RegExp(`^(?:(?:\\+|1\\s)?)?[0-9]{${len}}$`);
//   return regex.test(mob);
// };
export const validateMobile = (mob,countryCode) => {
  if(mob?.length > 1){
    const phoneNumber = parsePhoneNumber(mob, countryCode.toLocaleUpperCase())
    return phoneNumber.isValid()
  }
  return false
};
//cheack for valid mobile number
export const validateLocationLink = (location) => {
  const regex = /^https:\/\/(www\.)?google\.(com|[a-z]{2,3})\/maps\/(place|dir|search|@|view|preview|maps|localsearch|business|embed|q=)[^\s]*$/i
  // const regex = /https:\/\/(www\.)?google\.(com|[a-z]{2,3})\/maps\/(place|dir|search|@|view|preview|maps|localsearch|business|embed|q=)\S*/i;
  return regex.test(location);
};
