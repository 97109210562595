import React, { useState, useRef, useEffect } from 'react';
import { translate } from '../../Language/Translate';
import closeIcon from '../../Assets/img/icons/closes.svg';
import svgIc from 'Assets/img/icons/placeholder-avatar.svg'
import { CardMedia } from '@mui/material';
import { connect } from 'react-redux';
import { updateProfileDetails } from '../../Actions/settingsAction';
import ButtonLoader from 'Components/Common/ButtonLoader';
import CropImage from 'Components/Common/CropImage';
import { showToaster, ToasterTypes } from 'Actions/toasterAction';
import FocusTrap from 'focus-trap-react'

function ProfilePopup({
  onClose,
  profile_img,
  userDetails,
  editProfileData,
  profileImgBtnLoading,
  displaytoaster
}) {
  const [userData, setUserData] = useState('');
  const [profileimg, setProfileimg] = useState({
    profileImg: profile_img,
    profileImgCrop: '',
    profileImgUpdate: false,
  });
  const { profileImg, profileImgCrop, profileImgUpdate } = profileimg;
  const [key, setKey] = useState(0)
  const [showCrop, setShowCrop] = useState(false);
  const hiddenFileInput = useRef();
  const [
    ,
    // loading
    setLoading,
  ] = useState(false);

  useEffect(() => {
    document.body.classList.add('modal-overflow');
    return () => document.body.classList.remove('modal-overflow');
  }, []);

  useEffect(() => {
    setUserData(userDetails);
  }, [userDetails]);
  //   handel image browse open
  const hanldeDesktopBrowse = () => {
    hiddenFileInput.current.click();
  };

  // handle profile image selection
  const handleImageSelection = (event) => {
    setKey(key+1)
    const file = event.target.files[0];
    const reader = new FileReader();
    const maxSizeInBytes = 5 * 1024 * 1024;
    if (
      file?.type !== 'image/jpeg' &&
      file?.type !== 'image/jpg' &&
      file?.type !== 'image/png' &&
      file
    ) {
      displaytoaster(
        translate('settings.wrongfile', 'Unsupported file format'),
        ToasterTypes.Warning
      );
    } else if (file?.size > maxSizeInBytes) {
      displaytoaster(
        translate(
          'settings.verifySize',
          'File size exceeds the 5MB limit. Please select a smaller file.'
        ),
        ToasterTypes.Warning
      );
    } else {
      reader.onload = () => {
        setProfileimg({
          profileImgCrop: reader.result,
          // profileImgUpdate: true,
        });
        setShowCrop(true);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleImageRemove = () => {
    setProfileimg({
      profileImg: '',
      profileImgUpdate: true,
    });
  };

  const handleSuccess = () => {
    setLoading(false);
    onClose();
  };

  const handleProfileSave = () => {
    setLoading(true);
    const config = {
      data: {
        name: userData.name,
        last_name: userData.last_name,
        address: userData.address,
        dob: userData.dob,
        gender: userData.gender,
        profile_image_update: profileImgUpdate,
        profile_img: profileImg,
      },
    };
    editProfileData(config, handleSuccess);
  };
  const setData = (data) => {
    setProfileimg((prev) => ({
      ...prev,
      profileImg: data,
      profileImgUpdate: true,
    }));
  };

  // hanlde crop modal close
  const handleCropClose = () => {
    setProfileimg((prev) => ({
      ...prev,
      profileImgCrop: '',
      // profileImgUpdate: true,
    }));
    setShowCrop(false);
  };

  return (
    <>
      {showCrop && (
        <CropImage
          imgSrc={profileImgCrop}
          setShowCrop={setShowCrop}
          setData={setData}
          onClose={handleCropClose}
          title={translate('settings.cropProfile', 'Change profile picture')}
        />
      )}
     <FocusTrap
     focusTrapOptions={{
      clickOutsideDeactivates:true
    }}>
      <div className="AppointmentModal d-flex custom-profile-modal">
        <div className="modalContent delete_popup modal_xs p-20">
          <div className="modalHeader">
            <div className="container">
              <div className="row">
                <div className="col-md-12" onClick={onClose}>
                  <div className="modal-header">
                    <h6 className="fw-600">
                      {translate(
                        'settings.changeYourProfile',
                        'Change your profile picture'
                      )}
                    </h6>
                    <img className="close close-icon" src={closeIcon} alt="close"  />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modalContentarea-popup mb-0 pb-0 mt-3">
            <div className="container">
              <div className="row mt-2 justify-content-center">
                <div className="col-md-12"></div>
                <div className="col-8">
                  <div
                    className="card"
                    style={{ border: 'none' }}
                    onClick={hanldeDesktopBrowse}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageSelection}
                      ref={hiddenFileInput}
                      style={{ display: 'none' }}
                      key={key}
                    />
                    <p className="icon-text text-nowrap upload active pb-3">
                      <span style={{ cursor: 'pointer' }}>
                        <i
                          className="bi bi-upload"
                          style={{ cursor: 'pointer' }}
                        ></i>
                        <span className="px-2">
                          {translate('settings.uploadPic', 'Upload picture')}
                        </span>
                      </span>
                    </p>
                  </div>

                  <p
                    className={`icon-text text-nowrap remove ${profileImg ? 'active': ''}`}
                    onClick={profileImg ? handleImageRemove : () => {}}
                  >
                    <span
                      style={{ cursor: profileImg ? 'pointer' : 'not-allowed' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="15"
                        fill="currentColor"
                        className="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                      </svg>
                      <span className="px-2">
                        {translate('settings.removePic', 'Remove picture')}
                      </span>
                    </span>
                  </p>
                </div>
                <div className="col-4">
                  <div className="imgDeleteWrap">
                    {profileImg ? (
                      <CardMedia
                        className="display-img"
                        style={{
                          borderRadius: '50%',
                        }}
                        component="img"
                        image={profileImg}
                      />
                    ) : (
                      <img
                        className="display-img"
                        style={{
                          borderRadius: '50%',
                        }}
                        src={svgIc}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-2 justify-content-center"></div>
            </div>
          </div>
          <div className="modal_footer">
            <div className="container">
              <div className="row mt-1">
                <div className="col-md-12">
                  <ul className="admin-modal-footer">
                    <li>
                      {' '}
                      <button
                        type="button"
                        className="btn btn-outline-secondary px-4"
                        id="cancel"
                        onClick={onClose}
                      >
                        {' '}
                        {translate('common.close', 'Close')}
                      </button>
                    </li>
                    <li
                    // onClick={onClose}
                    >
                      <button
                        type="button"
                        className="btn btn-primary px-4 loader-btn"
                        id="save"
                        onClick={handleProfileSave}
                        disabled={profileImgBtnLoading}
                      >
                        {profileImgBtnLoading ? (
                          <ButtonLoader
                            text={translate('common.save', 'SAVE')}
                          />
                        ) : (
                          translate('common.save', 'SAVE')
                        )}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </FocusTrap>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDetails: state.LoginReducer.loginDetails,
  profileImgBtnLoading: state.SettingsReducer.profileImgBtnLoading,
});
const mapDispatchToProps = (dispatch) => ({
  editProfileData: (data, handleSuccess) =>
    dispatch(updateProfileDetails(data, handleSuccess)),
    displaytoaster: (message, type) => dispatch(showToaster(message, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePopup);
