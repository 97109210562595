import { translate } from '../Language/Translate';

export const USER_TYPE = {
  subscriptionOwner: 'subscription-owner',
  admin: 'admin',
  doctor: 'doctor',
  appointmentCordinator: 'appointment-coordinator',
  patient: 'patient',
};

export const PERMISSIONS = {
  read: 'read',
  write: 'write',
  execute: 'execute',
};

export const USER_TYPE_NAME = {
  [USER_TYPE.subscriptionOwner]: translate(
    'userType.owner',
    'Subscription Owner'
  ),
  [USER_TYPE.admin]: translate('userType.admin', 'Admin'),
  [USER_TYPE.doctor]: translate('userType.doctor', 'Doctor'),
  [USER_TYPE.appointmentCordinator]: translate(
    'userType.appointmentCordinator',
    'Appointment Coordinator'
  ),
  [USER_TYPE.patient]: translate('userType.patient', 'Patient'),
};

export const USER_TYPE_OPTIONS = [
  { value: USER_TYPE.admin, label: translate('users.admin', 'Admin') },
  { value: USER_TYPE.doctor, label: translate('users.doctor', 'Doctor') },
  {
    value: USER_TYPE.appointmentCordinator,
    label: translate('users.Appocord', 'Appointment Coordinator'),
  },
];

export const USER_TYPE_CREATE_OPTIONS = [
  { value: USER_TYPE.admin, label: translate('users.admin', 'Admin') },
  {
    value: USER_TYPE.appointmentCordinator,
    label: translate('users.Appocord', 'Appointment Coordinator'),
  },
  { value: USER_TYPE.doctor, label: translate('users.doctor', 'Doctor') },
];
