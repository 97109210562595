import {
  CLEAR_USER_DATA,
  RECEIVE_USERS_LIST,
  RECEIVE_USER_DATA,
  UPDATE_USER_SEARCH_DATA,
  CLEAR_ALL_USER_DATA,
  UPDATE_USER_PER_PAGE,
  UPDATE_USER_PAGINATION,
  UPDATE_USER_CURRENT_PAGE,
  SET_USER_COLUMN_SORT,
  SET_USER_PATIENT_COLUMN_SORT,
  USER_BTN_LOADING,
  GET_PATIENTS_DATA,
  UPDATE_PATIENT_SEARCH_DATA,
  VERIFY_BTN_LOADING
} from '../Actions/usersAction';

const initialState = {
  usersList: {},
  userData: {},
  searchData:{},
  paginationData:{
    perPage:25,
    currentPage:1
  },
  patientPaginationData:{
    perPage:25,
    currentPage:1
  },
  columnSort:{},
  patientColumnSort:{},
  isBtnLoading: false,
  patients_Data:{},
  isVerifyLoading: false
};

const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_USERS_LIST: {
      return {
        ...state,
        usersList: action.payload,
      };
    }
    case RECEIVE_USER_DATA: {
      return {
        ...state,
        userData: action.payload,
      };
    }
    case GET_PATIENTS_DATA: {
      return {
        ...state,
        patients_Data: action.payload,
      };
    }
    case CLEAR_USER_DATA: {
      return {
        ...state,
        userData: {},
      };
    }
    case CLEAR_ALL_USER_DATA: {
      return initialState
    }
    case UPDATE_USER_SEARCH_DATA: {
      return {
        ...state,
        searchData:action.payload
      };
    }
    case UPDATE_PATIENT_SEARCH_DATA: {
      return {
        ...state,
        patient_searchData:action.payload
      };
    }
    case UPDATE_USER_CURRENT_PAGE: {
      return {
        ...state,
        paginationData:{
          ...state.paginationData,
          currentPage:action.payload
        }
      };
    }
    case UPDATE_USER_PER_PAGE: {
      return {
        ...state,
        paginationData:{
          ...state.paginationData,
          perPage:action.payload
        }
      };
    }
    case UPDATE_USER_PAGINATION: {
      return {
        ...state,
        patientPaginationData:{
          ...state.patientPaginationData,
          ...action.payload
        }
      };
    }
    case SET_USER_COLUMN_SORT: {
      return {
        ...state,
        columnSort: action.payload,
      };
    }
    case SET_USER_PATIENT_COLUMN_SORT: {
      return {
        ...state,
        patientColumnSort: action.payload,
      };
    }
    case USER_BTN_LOADING: {
      return {
        ...state,
        isBtnLoading: action.payload,
      };
    }
    case VERIFY_BTN_LOADING : {
      return{
        ...state,
        isVerifyLoading: action.payload
      }
    }
    default:
      return state;
  }
};

export default UsersReducer;
