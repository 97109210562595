import { RECEIVE_OVERRIDE_DETAILS } from '../Actions/doctorsAction';
import { 
  RECEIVE_LEAVE_LIST,
  // UPDATE_LEAVE_DOC_SUGGESTION,
  UPDATE_LEAVE_SEARCH_DATA,
  SET_LEAVE_PER_PAGE,
  SET_LEAVE_CURRENT_PAGE,
  CLEAR_LEAVE_DATA,
  SET_LEAVE_COLUMN_SORT,
  LEAVE_BTN_LOADING
  } from '../Actions/LeaveActions';

const initialState = {
  leaveList: [],
  overrideList: {},
  searchData:{},
  paginationData:{
    perPage:25,
    currentPage:1
  },
  columnSort:{},
  isBtnLoading: false
};

const LeaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_LEAVE_LIST: {
      return {
        ...state,
        leaveList: action.payload,
      };
    }
    case RECEIVE_OVERRIDE_DETAILS: {
      return {
        ...state,
        overrideList: action.payload,
      };
    }
    case UPDATE_LEAVE_SEARCH_DATA: {
      return {
        ...state,
        searchData: action.payload,
      };
    }
    case SET_LEAVE_CURRENT_PAGE: {
      return {
        ...state,
        paginationData:{
          ...state.paginationData,
          currentPage:action.payload
        }
      };
    }
    case SET_LEAVE_PER_PAGE: {
      return {
        ...state,
        paginationData:{
          ...state.paginationData,
          perPage:action.payload
        }
      };
    }
    case CLEAR_LEAVE_DATA: {
      return initialState
    }
    case SET_LEAVE_COLUMN_SORT: {
      return {
        ...state,
        columnSort: action.payload
      };
    }
    case LEAVE_BTN_LOADING: {
      return {
        ...state,
        isBtnLoading: action.payload,
      };
    }
    default:
      return state;
  }
};

export default LeaveReducer;
