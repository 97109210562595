/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FetchDepartmentData } from '../../Actions/departmentAction';
import { FormattedMessage } from 'react-intl';
import { translate } from 'Language/Translate';
import { DEPARTMENT_VIEW_HEADERS } from 'Constants/constant';
import TableColumnHr from 'Components/Common/TableColumnHr';
import usePrevious from 'Hooks/usePrevious';
import { isEqual } from 'lodash';
import useDidMountEffect from 'Hooks/useDidMountEffect';
import Loader from 'Components/Common/Loader';
import NoResultFound from 'Components/Common/NoResult';
import { removeDrPrefix } from 'Utils/StringFormatter/String';
import { USER_TYPE } from 'Constants/userTypes';

const ViewDepartment = (props) => {
  const { showDepartment, getDepartmentData, selectedDepartmentData, onSubmit,userType } = props;
  const { dept_name, description, dept_head, dept_status, dept_id } =
    showDepartment;

  const { dept_head_name } = dept_head || {};

  const [loading, setLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [columnSort, setColumnSort] = useState({})

  useEffect(() => {
    getDepartmentData(dept_id, setLoading);
    setLoading(true);
  }, []);

  useEffect(() => {
    if (selectedDepartmentData.doctors) {
      setDoctors(selectedDepartmentData.doctors);
    }
  }, [selectedDepartmentData]);

  const prevSortVal = usePrevious(columnSort);

  useDidMountEffect(() => {
    if (!isEqual(prevSortVal, columnSort)) {
      const keyvalue = Object.entries(columnSort)?.[0]
      if (keyvalue.length) {
        const doctorsCopy = [...doctors]
        const docList = doctorsCopy.sort((a, b) => keyvalue[1] === 'asc' ? a[keyvalue[0]].localeCompare(b[keyvalue[0]]) : b[keyvalue[0]].localeCompare(a[keyvalue[0]]));
        setDoctors(docList)
      }
    }
  }, [columnSort]);

  return (
    <>
      <div className="container mb-4">
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="d_top_head d_top_head_new">
              <div className="row align-items-start">
                <div className="d-flex align-items-center gap-3 justify-content-between">
                <div>
                  <h5>
                    {dept_name}
                  </h5>
                </div>
                <div>
                  <h6 className="status-active-text" style={{ marginTop: 4, fontSize: 14, fontWeight: 400 }}>
                    <span
                      className={`${dept_status} activity-badge ps-2`}
                    >
                      <span className="dot">●</span>{' '}
                      {dept_status === 'active'
                        ? translate('common.active', 'Active')
                        : translate('common.inactive', 'Inactive')}
                    </span>
                  </h6>
                </div>
                </div>
                <div className="col-md-12">
                  <h6 className='dept-head-name'>
                     {dept_head_name?.trim()?`Dr. ${removeDrPrefix(dept_head_name)} (Dept. Head)`:userType !== USER_TYPE.appointmentCordinator&&<p className='nothing-to-show'> {translate('departments.noHead', 'No doctor was selected as the department head.')} {onSubmit && <a tabIndex='0' onKeyDown={(e)=>{
                       if(e.key==="Enter" && !e.shiftKey) 
                       {
                         e.preventDefault();
                         onSubmit();
                        }
                     }} 
                     onClick={onSubmit}>Update Info</a>} </p>}
                  </h6>
                </div>
                  <div className="col-md-12">
                    <p className='description'>
                      {description || <p className='nothing-to-show'>No description added. {onSubmit && <a tabIndex='0' 
                       onKeyDown={(e)=>{
                        if(e.key==="Enter" && !e.shiftKey){
                          e.preventDefault()
                          onSubmit("departmentname")
                          }
                      }} 
                      onClick={()=>{onSubmit("departmentname")}}>Add description</a>} </p>}
                    </p>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <h5 className='blue-heading'
            >
              <FormattedMessage
                id="departments.doctors"
                defaultMessage="Doctors"
              />
            </h5>
          </div>
        </div>

        <div className="mt-3 viewDepartment">
          {loading ? (
            <Loader />
          ) : doctors.length ? (
            <>
              <div className="list-user">
                <table className='table'>
                  <thead>
                    <tr>
                    {DEPARTMENT_VIEW_HEADERS.map((header, index) => (
                      <TableColumnHr
                        item={header}
                        key={index}
                        columnSort={columnSort}
                        setColumnSort={setColumnSort}
                        sortEnable={doctors.length > 1 ? true : false}
                      />
                    ))}
                    </tr>
                  </thead>
                  <tbody>
                    {doctors.map((doctor, index) => (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td width={150}>
                          <div className="img-name">
                            Dr. {removeDrPrefix(`${doctor.name} ${doctor.last_name}`)}
                          </div>
                        </td>
                        <td width={110}>{doctor.designation || '-'}</td>
                        <td width={200}>
                        <span
                      className={`${doctor.doctor_status} activity-badge`}
                    >
                            <span className="dot">●</span>{' '}
                            {doctor.doctor_status === 'active'
                              ? translate('common.active', 'Active')
                              : translate('common.inactive', 'Inactive')}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="patient-portal">
              <NoResultFound text={<p className="nothing-to-show">No doctors added. {userType !== USER_TYPE.appointmentCordinator&&<a tabIndex='0' onClick={()=>{onSubmit("department_Doctr-List")}} className="link">+Add New</a>}</p>} removeMarginBottom removeMarginTop heightClass='nodata-height' />
            </div>
          )}
        </div>

        <div className="row mt-1">
          <div className="col-md-12">
            <div className="border_btm"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getDepartmentData: (id, setLoading) =>
    dispatch(FetchDepartmentData(id, setLoading)),
});

const mapStateToProps = (state) => ({
  selectedDepartmentData: state.DepartmentReducer.selectedDepartmentData,
  userType: state.LoginReducer.user_type
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewDepartment);
