import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import profilePic from 'Assets/img/icons/doctor-placeholder-avatar.svg';
// import profilePic from 'Assets/img/bg/profilePic.svg';
import reschedueledIcon from 'Assets/img/icons/rescheduled-icon.svg';
import greenTick from 'Assets/img/icons/green-tick-badge.svg';
// import addbtn from 'Assets/img/icons/add-btn-icon.svg';
import { useIntl } from 'react-intl';
import {
  APPOINTMENT_STATUS,
  APPOINTMENT_STATUS_NAMES,
  PAID,
} from 'Constants/constant';
import {
  CancelAppointment,
  clearDoctorSlots,
  DeleteAppointment,
  FetchAppointmentList,
  FetchDepartmentData,
  FetchDoctorSlots,
  paymentHandle,
  startAppointment,
  UpdateAppointment,
  updateCurrentPage,
} from 'Actions/appointmentAction';
import {
  appendTime,
  formatDate,
  // formatDateForRoaster,
  formatDateInDDMMYYYY,
  formatDateto_D_WD_M_Y,
  getWeekBounds,
  getWeekDays,
} from 'Utils/DateFormatter/Date';
import { translate } from 'Language/Translate';
import { removeDrPrefix, truncateString } from 'Utils/StringFormatter/String';
// import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import PopupWrapeer from 'Components/Common/PopupWrapeer';
import EditAppointments from './EditAppointment';
import { clearDepartmentData } from 'Actions/departmentAction';
import CancelPopup from './CancelPopup';
import ConformPopup from 'Components/Common/ConformPopup';
import PaymentInfoPopUp from './PaymentInfoPopUp';
import CoinIcon from 'Assets/img/icons/coin.svg';
import { compose } from 'redux';
import withPermissionCheck from 'Utils/WithUtils/WithPermission';
import { APPOINTMENT } from 'Constants/feature';
import { CREATE, STATUS_UPDATE } from 'Constants/scopes';
import Loader from 'Components/Common/Loader';
import { CardMedia, Tooltip } from '@mui/material';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import telephone from 'Assets/img/icons/telephone.svg'
import envelope from 'Assets/img/icons/envelope.svg'
import person from 'Assets/img/icons/person-round.svg'
import clock from 'Assets/img/icons/clock.svg'
import listIcon from 'Assets/img/icons/list-icon.svg'
import editIcon from 'Assets/img/icons/edit-icon-new.svg'
import deleteIcon from 'Assets/img/icons/delete-icon-new.svg'
import NoResultFound from 'Components/Common/NoResult';
import { useHistory } from 'react-router-dom';


const AddIcon = <svg width="44px" height="44px" viewBox="0 0 44 44" version="1.1">
    <title>Group 13</title>
    <g id="Client-Portal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Doctors-Appointments---Own-View-2" transform="translate(-1263, -790)">
            <g id="Group-13" transform="translate(1263, 790)">
                <circle id="Oval" fill="#5875FF" cx="22" cy="22" r="22"></circle>
                <g id="Group-12" transform="translate(14, 14.5)" stroke="#FFFFFF" strokeLinecap="square" strokeWidth="2">
                    <line x1="15.6520462" y1="7.8260231" x2="0" y2="7.8260231" id="Line-8-Copy"></line>
                    <line x1="7.8260231" y1="15.6520462" x2="7.8260231" y2="3.28626015e-14" id="Line-8-Copy-2"></line>
                </g>
            </g>
        </g>
    </g>
</svg>


const days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const status = {
  upcoming: 'upcoming',
  notattempted: 'not-attempted',
  cancelled: 'cancelled',
  label: 'Cancelled',
  completed: 'completed',
  inprogress: 'in-progress',
  empty: 'empty',
  waiting: 'waiting',
};

const statusDot = {
  upcoming: 'upcoming',
  notattempted: 'notattempted',
  cancelled: 'cancelled',
  label: 'Cancelled',
  completed: 'completed',
  inprogress: 'in-progress',
  empty: 'empty',
  waiting: 'waiting',
};

// let '' = { label: 'All', value: undefined };

const WeekRoster = (props) => {
  const {
    getAppointments,
    date,
    appointmentList,
    patientName,
    applyCounter,
    // startAppointment,
    // setSearch,
    deptDocSuggestionList,
    onDeptChangeSearch,
    handleDeptChange,
    setDeptDocSuggestionList,
    selectedDoc,
    setSelecteDoc,
    searchDoc,
    doctorSlotData,
    getDoctorSlots,
    getDepartmentData,
    clearDeptData,
    selectedDepartmentData,
    clearSlots,
    editAppointment,
    onCancelAppointment,
    deleteAppointment,
    currentPage,
    SetCurrentPage,
    handleAddButton,
    status: apptStatus,
    applySearchData,
    searchData,
    docDetails,
    opTime,
    language_Code,
    ACCESS: permission,
    paymentHandle,
    isBtnLoading,
    isloader,
    allDocList,
    handleDoctorChange,
    CustomOption,
    searchDocDeptLoading,
    isSelect,
    handleWeekDayClick
  } = props;
  const {
    editAppt: editApptLoading = false,
    delAppt: delApptLoading = false,
    cancelAppt: cancelApptLoading = false,
  } = isBtnLoading;
  // const history = useHistory();
  const [weekDays, setWeekDays] = useState([]);
  // const [depts, setDepts] = useState([]);
  const [apptList, setApptList] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [
    ,
    // isLoading
    updateLoading,
  ] = useState(false);
  // store appointment
  const [appointmemntView,setAppointmemntView] = useState(false)

  // to store payment appointment id
  const [paymentAppt, setPaymentAppt] = useState('');

  //Edit details
  const [showPopup, updateShowPopup] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  const [error, setError] = useState('');
  const [feildError, setFeildError] = useState({
    deptError: false,
    doctorError: false,
    dateError: false,
    slotError: false,
    scheduleError: false,
    reasonError: false,
  });
  // cancel Deatils
  const [showCancelPopup, updateCancelPopup] = useState(false);
  const [cancelId, setCancelId] = useState('');

  // Delete deatils
  const [showConformPopup, updateShowConformPopup] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  // to ref scroll
  const [hasScroll, setHasScroll] = useState(false);
  const scrollRef = useRef();
  const popupRef = useRef({});
  // loading status
  const [apptisLoader, updateApptLoader] = useState(false);

  // const [reschuleStatus, setReschuleStatus] = useState(false)
  const [openPaymentInfo, setOpenPaymentInfo] = useState(false);
  const [reschedView, setReschdView] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dummyState, setDummystate] = useState(1);
  const intl = useIntl();

  useEffect(() => {
    // Update windowWidth on initial render and resize
    setWindowWidth(window.innerWidth);

    // Add event listener for resize event
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchAppointments = ({
    search: searchWord,
    department,
    appStatus,
    start_Date,
    end_Date,
  } = {}) => {
    if (!apptisLoader) updateApptLoader(true);
    const { startDate, endDate } = getWeekBounds(date);
    const config = {
      patient_search:
        searchWord || searchWord === '' ? searchWord || undefined : patientName,
      doctor_id:
        department || department === ''
          ? department || undefined
          : selectedDoc.value,
      start_date:
        start_Date || start_Date === ''
          ? start_Date || undefined
          : formatDate(startDate),
      end_date:
        end_Date || end_Date === ''
          ? end_Date || undefined
          : formatDate(endDate),
      status:
        appStatus || appStatus === '' ? appStatus || undefined : undefined,
      page: 1,
      limit: 0,
      acl: true,
      sort: 'appt_start_time',
    };
    getAppointments(config, updateApptLoader);
  };
  useEffect(() => {
    if (selectedDoc.value && applyCounter) {
      const { startDate, endDate } = getWeekBounds(date);
      fetchAppointments({
        search: patientName,
        appStatus: apptStatus ? apptStatus.value : '',
        department: selectedDoc.value,
        start_Date: formatDate(startDate),
        end_Date: formatDate(endDate),
      });
    }
  }, [applyCounter]);

  const setScrollhandle = () => {
    setTimeout(() => {
      const element = scrollRef.current;
      if (element) {
        setHasScroll(element?.scrollHeight > element?.clientHeight);
      }
    }, 0);
  };

  useEffect(() => {
    if (applyCounter) {
      setWeekDays(getWeekDays(date, language_Code));
      const { startDate, endDate } = getWeekBounds(date);
      applySearchData({
        ...searchData,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      });
    }
  }, [date, applyCounter]);

  useEffect(() => {
    if (appointmentList?.appointments?.length) {
      const groupedByDate = _.groupBy(
        appointmentList.appointments,
        'appt_date'
      );
      setApptList(groupedByDate);
    } else {
      setApptList({});
    }
  }, [appointmentList]);

  const getHighestArrayLength = (obj) => {
    let highestLength = 0;
  
    for (const value of Object.values(obj)) {
      if (Array.isArray(value) && addEmptySlots(value).length > highestLength) {
        highestLength = addEmptySlots(value).length;
      }
    }
  
    return highestLength;
  }

    // to find the number of empty deiv needed to fill the week calender completely
    const fillEmptySlots = () => {
      const parentDiv = document.getElementById('scrollRef');
      const parentRect = parentDiv.getBoundingClientRect();
      const numChildDivs = Math.floor(parentRect.height / 30);
      const a = getHighestArrayLength(apptList)
      return windowWidth > 991 ? a > numChildDivs ? a : numChildDivs : 1
    }

    // add empty solts to remaining part of appointments
    const fillAppointmentWithEmpty = (data) => {
      const parentDiv = document.getElementById('scrollRef');
      const parentRect = parentDiv.getBoundingClientRect();
      const numChildDivs = Math.floor(parentRect.height / 30);
      const maxLength = getHighestArrayLength(apptList)
      return windowWidth > 991 ? maxLength >= numChildDivs ? 
           data.concat(new Array(maxLength - data.length).fill({ live_status: 'empty' })) : data.concat(new Array(numChildDivs - data.length).fill({ live_status: 'empty' })) : data
    } 

  // add breaks in between appointment if the gpasare greater than one hour
  const addEmptySlots = (appointments) => {
    const updatedAppointments = [];

    for (let i = 0; i < appointments.length - 1; i++) {
      const currentEnd = appointments[i].appt_end_time;
      const nextStart = appointments[i + 1].appt_start_time;

      // Convert time strings to Date objects for comparison
      const currentEndTime = new Date(`2000-01-01T${currentEnd}`);
      const nextStartTime = new Date(`2000-01-01T${nextStart}`);

      // Calculate time difference in milliseconds
      const timeDifference = nextStartTime - currentEndTime;

      // Add the original appointment
      updatedAppointments.push(appointments[i]);

      if (timeDifference > 2 * 3600000) {
        // Insert empty slots for each hour difference
        const numEmptySlots = Math.floor(timeDifference / (2 * 3600000));
        for (let j = 1; j <= numEmptySlots; j++) {
          updatedAppointments.push({ live_status: 'empty' });
        }
      }
    }

    // Add the last appointment
    updatedAppointments.push(appointments[appointments.length - 1]);

    // sort the array to show cancelled first on each slot
    const sortedAppointments = updatedAppointments.sort((a, b) => {
      if (a.appt_start_time === b.appt_start_time) {
        if (a.live_status === 'cancelled' && b.live_status !== 'cancelled') {
          return -1;
        } else if (
          a.live_status !== 'cancelled' &&
          b.live_status === 'cancelled'
        ) {
          return 1;
        }
      }
      return 0;
    });

    return sortedAppointments;
  };

  // handle appointment start
  // const handleAppointmnentStart = async (item, type) => {
  //   if (type === 'Start') {
  //     let payload = { id: item?.appointment_id, auto_complete: true };
  //     startAppointment(payload, () => {
  //       history.push(`/admin/appointments/patientview/${item.appointment_id}`);
  //     });
  //   } else {
  //     history.push(`/admin/appointments/patientview/${item.appointment_id}`);
  //   }
  // };

  useEffect(() => {
    if (appointmentList?.appointments) {
      setAppointments(appointmentList.appointments);
    } else {
      setAppointments([]);
    }
    setScrollhandle();
  }, [appointmentList]);

  // handle edit click
  const onEdit = (item) => {
    updateShowPopup(true);
    setDataToEdit(item);
  };

  // handle edit close
  const handleEditClose = () => {
    updateShowPopup(false);
    setDataToEdit({});
    setError('');
    setFeildError({
      deptError: false,
      doctorError: false,
      dateError: false,
      slotError: false,
      scheduleError: false,
      reasonError: false,
    });
  };

  const handleEditAppointment = () => {
    if (
      !dataToEdit.doctor_id ||
      !dataToEdit.dept_name ||
      !dataToEdit.appt_start_time ||
      !dataToEdit.appt_end_time ||
      !dataToEdit.appt_date ||
      !dataToEdit.reason
    ) {
      setFeildError({
        deptError: !dataToEdit.dept_name,
        doctorError: !dataToEdit.doctor_id,
        dateError: !dataToEdit.appt_date,
        slotError: !dataToEdit.appt_end_time || !dataToEdit.appt_start_time,
        scheduleError:
          !dataToEdit.session &&
          (!dataToEdit.appt_end_time || !dataToEdit.appt_start_time),
        reasonError: !dataToEdit.reason,
      });
      setError(
        translate(
          'common.requiredFields',
          'Please fill all the required fields'
        )
      );
    } else {
      const config = {
        data: {
          doctor_id: dataToEdit.doctor_id,
          dept_name: dataToEdit.dept_name,
          dept_id: dataToEdit.dept_id,
          schedule_type: 'op',
          appt_date: formatDate(dataToEdit.appt_date),
          appt_start_time: dataToEdit.appt_start_time,
          appt_end_time: dataToEdit.appt_end_time,
          appt_slot_session: dataToEdit.appt_slot_session,
          reason: dataToEdit.reason,
          patient_details: {
            patient_id: dataToEdit.patient_id,
            name: dataToEdit.patient_name,
            email: dataToEdit.patient_email,
            dob: '2023-12-12',
            gender: 'male',
          },
        },
      };
      editAppointment(
        dataToEdit.appointment_id,
        config,
        fetchAppointments,
        handleEditClose
      );
    }
  };

  // handle cancel Conform pop open
  const onHandleCancelOpen = (item) => {
    updateCancelPopup(true);
    setCancelId(item.appointment_id);
  };

  // handle cancel conform pop close
  const onHandleCancelClose = () => {
    updateCancelPopup(false);
    setCancelId('');
  };

  // handle close of the popup
  const onClose = (id) => {
    const element = document.getElementById(`appointmentView-${id}`);
    const element1 = document.getElementById(`appointmentViewToggle-${id}`);
    element.classList.remove('show');
    element1.classList.remove('show');
  };

  //handle cancel click
  const handleCancel = () => {
    const config = {
      data: {
        appt_status: 'canceled',
      },
    };
    onCancelAppointment(
      cancelId,
      config,
      fetchAppointments,
      onHandleCancelClose
    );
  };

  //handle delete click
  const onDelete = (item) => {
    onClose(item.appointment_id);
    updateShowConformPopup(true);
    setDeleteId(item.appointment_id);
  };

  // handle delete success
  const handleDeleteSuccess = () => {
    if (appointments.length === 1 && currentPage > 1) {
      SetCurrentPage(currentPage - 1);
      fetchAppointments({ pageNo: currentPage - 1 });
    } else {
      fetchAppointments();
    }
  };

  // const isAllinActive=(arr)=>{
  //   return arr.every((item)=>item.doctor_status==="inactive")
  // }

  // handle on delete confirm
  const deleteConfirm = () => {
    updateLoading(true);
    deleteAppointment(deleteId, handleDeleteSuccess, updateShowConformPopup);
  };
  // handle dept change
  // const handleDeptChange = (item) => {
  //   setSelectedDept(item);
  // };
  const handlepaymentInfo = (id) => {
    setOpenPaymentInfo(true);
    setPaymentAppt(id);
  };
  const handleClose = () => {
    setPaymentAppt('');
    setOpenPaymentInfo(false);
  };
  const handleRescheduleDetails = (event, id) => {
    if(Object.keys(reschedView).length === 0){
  //   setReschuleStatus(!reschuleStatus)
    event.stopPropagation()
    // event.preventDefault()
    setReschdView({ [id]: !reschedView[id] })
    setAppointmemntView({})
    }
  }

  // function getFields(input, field) {
  //   var output = [];
  //   for (var i = 1; i < input.length; ++i) output.push(input[i][field].split(' ').map(dept=> dept.charAt(0).toUpperCase()+ dept.slice(1)).join(' '));
  //   return output;
  // }

  const calculateHeight = (data) => {
    const ele = document.getElementById(days[data]);
    return ele?.scrollHeight;
  };
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (windowWidth < 991) setDummystate(dummyState + 1);
    });

    resizeObserver.observe(scrollRef.current);

    return () => resizeObserver.disconnect(); // Cleanup on unmount
  }, [scrollRef.current, windowWidth, apptList]);


  const history = useHistory();

  const hadleAddnewDoc = () => {
    history.push('doctorslisting/adddoctor');
  }

  const handleHistoryClose=()=>{
    setReschdView({})
  }

  useEffect(() => {
    setTimeout(() => {
      let ele = document.getElementsByClassName("selected-profile");
      if (ele[0]) {
        ele[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }, 100)
  }, [docDetails])
  
  return (
    <>
      {openPaymentInfo && (
        <PaymentInfoPopUp
          openPaymentInfo={openPaymentInfo}
          onClose={handleClose}
          paymentAppt={paymentAppt}
          fetchAppointments={fetchAppointments}
          paymentHandle={paymentHandle}
        />
      )}
      {showConformPopup && (
        <ConformPopup
          onClose={() => updateShowConformPopup(false)}
          onConfirm={deleteConfirm}
          message={translate(
            'common.doYouWantToDeleteAppointment',
            'Are you sure you want to delete this appointment?'
          )}
          isBtnLoading={delApptLoading}
        />
      )}
      {showPopup && (
        <PopupWrapeer
          title={translate('appointments.editAppointment', 'Edit Appointment')}
          onClose={handleEditClose}
          onSubmit={handleEditAppointment}
          saveBtn={translate(
            'appointments.notifyAndSave',
            'NOTIFY PATIENT AND SAVE'
          )}
          isBtnLoading={editApptLoading}
          autoFocus={true}
        >
          <EditAppointments
            dataToEdit={dataToEdit}
            setDataToEdit={setDataToEdit}
            getDoctorSlots={getDoctorSlots}
            getDepartmentData={getDepartmentData}
            clearDeptData={clearDeptData}
            selectedDepartmentData={selectedDepartmentData}
            clearSlots={clearSlots}
            setError={setError}
            error={error}
            feildError={feildError}
            setFeildError={setFeildError}
            doctorSlotData={doctorSlotData}
          />
        </PopupWrapeer>
      )}
      {showCancelPopup && (
        <CancelPopup
          onConfirm={() => handleCancel()}
          onClose={() => onHandleCancelClose()}
          isBtnLoading={cancelApptLoading}
        />
      )}
      <div className="data-wrapper">
        <div className="no-formgroup doc-name-dr mobile-only">
          <ReactSelectDropDown
            options={allDocList}
            onInputChange={handleDoctorChange}
            className="form-select"
            value={selectedDoc}
            components={{ Option: CustomOption }}
            customFilter={(option) =>
              `${option.label} ${option.value} ${option.data.departments}`
            }
          />
        </div>
        <div className={`schedule-table`}>
          <div
            className="schedule-header"
            style={{ paddingRight: hasScroll ? '8px' : '0px' }}
          >
            <div className="left-side cell">
              <div className={`search-input ${Object.values(searchDocDeptLoading).includes(true)&& !isSelect && 'loading'}`}>
                <input
                  type="search"
                  name=""
                  id=""
                  value={searchDoc}
                  placeholder={intl.formatMessage({
                    id: 'appointments.searchDoctDept',
                    defaultMessage: 'Search Doctor/Dept.',
                  })}
                  // placeholder="Search Doctor/Dept."
                  onChange={onDeptChangeSearch}
                  onBlur={() =>
                    setTimeout(() => setDeptDocSuggestionList([]), 400)
                  }
                />
                <i className="bi bi-search"></i>
                {deptDocSuggestionList.length && 
                !Object.values(searchDocDeptLoading).includes(true) && !isSelect
                ? (
                  <div className="customSearchList">
                    <ul style={{ marginBottom: 1 }}>
                      {deptDocSuggestionList.map((item, index) => (
                        <li
                          key={index}
                          className={`suggestion-item ${item.isDisabled ? 'disabled' : ''}`}
                          onClick={() => !item.isDisabled && handleDeptChange(item)}
                        >
                          {item.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
            {weekDays.map((day, index) => (
              <div
                className={`cell ${day.active ? 'active' : ''} cursor-pointer`}
                key={index + 1}
                style={{
                  height:
                    windowWidth < 991 && apptList[day.formatedDate]
                      ? calculateHeight(index)
                      : '',
                }}
                onClick={()=> handleWeekDayClick(new Date(day.formatedDate))}
              >
                <span>{day.date}</span>
              </div>
            ))}
          </div>
          <div className="schedule-body">
            <div className="left-area">
              {docDetails?.length
                ? 
                // isAllinActive(docDetails)?
                // <div className='patient-portal'> 
                //   <NoResultFound
                //   text={ 
                //     translate('doctors.noDataDoctors', 'No Doctors added')
                //   }
                //   label={
                //     translate('doctors.noActiveDoctorMessage', `Doctor added is inactive, Please activate the doctor`)
                //   }
                //   />
                //   </div>
                // :
                docDetails?.map((data, index) => (
                    <div
                      className={`profile scrollMarginTop3 ${
                        data.doctor_id === selectedDoc.value
                          ? 'selected-profile'
                          : ''
                      }`}
                      key={index}
                      onClick={() => {
                        if (
                          selectedDoc?.value !== data.doctor_id &&
                          !(isloader || apptisLoader)
                        ) {
                          setSelecteDoc({
                            label: data.name + ' ' + data.last_name,
                            value: data.doctor_id,
                          });
                        }
                      }}
                    >
                      <div className="profile-content">
                        <CardMedia
                          component="img"
                          image={data.profile_img || profilePic}
                        />
                        <div>
                          <span className="doc-name text-capitalize">
                            Dr. {removeDrPrefix(data.name+' '+data.last_name)}
                          </span>
                          <span
                            className="dept text-capitalize"
                          >{
                            (data?.departments?.length
                                ? truncateString(
                                    data?.departments[0]?.dept_name,
                                    data?.departments?.length === 1 ? 20 : 15
                                  ).includes('...')? <Tooltip title={<p className='text-capitalize'>{data?.departments[0]?.dept_name}</p>} placement='bottom-start'>{
                                truncateString(
                                        data?.departments[0]?.dept_name,
                                        data?.departments?.length === 1 ? 20 : 15
                                      )
                                }
                                </Tooltip>: 
                                data?.departments[0]?.dept_name
                                : null)
                                    }
                                <Tooltip classes={{tooltip: 'top-pover'}}  title={data?.departments?.slice(1).map((x, i) => <p className='text-capitalize' key={i}>{x.dept_name}</p>)} placement='top-start'>
                                {data?.departments?.length > 1 ? (
                                  <span
                                    href="#"
                                    className="td-none text-primary ms-2"
                                  >
                                    + {data?.departments.length - 1}
                                  </span>
                                ) : null}
                            </Tooltip>
                            {/* <a href="#" className="td-none text-primary ms-2">+3</a> */}
                          </span>
                          {/* {depts.length > 2 ? (
                            <>
                              <a
                                className="td-none text-primary ms-2 fs-12 pointer extra-details"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="true"
                              >
                                +{depts.length - 2}
                              </a> */}
                          {/* Department on hover */}
                          {/* <ul
                                className="dropdown-menu dropdown-menu-start custom-tooltip"
                                data-popper-placement="bottom-end"
                              >
                                {depts?.map((item, index) => (
                                  <li key={index}>
                                    <a
                                      className="dropdown-item small"
                                      data-bs-toggle="offcanvas"
                                      data-bs-target="#editAccount"
                                      aria-controls="editAccount"
                                    >
                                      {item.label}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : null} */}
                        </div>
                      </div>
                      {data.doctor_id === selectedDoc.value ? (
                        <span className="selected-badge">
                          {translate('appointments.optime', 'OP Time')}
                          {' : '}
                          {opTime}
                        </span>
                      ) : null}
                    </div>
                  ))
                : apptisLoader || isloader ? null :
                <div className='patient-portal'>
                    <NoResultFound text='No doctors added' removeMarginBottom removeMarginTop label={<a className='link' onClick={hadleAddnewDoc}>+Add New</a>} />
                </div>
                }
            </div>
            <div
              className="right-area"
              id='scrollRef'
              onClick={()=>{
              if(Object.keys(reschedView).length){
                setReschdView({});
              }
            }
             }
              ref={scrollRef}
            >
              {weekDays.map((day, index) => (
                // each slot card
                <div
                  className={`cell-wrap ${days[index]}`}
                  id={days[index]}
                  key={index + 1}
                >
                  {apptList[day.formatedDate] && !apptisLoader && !isloader ? (
                    fillAppointmentWithEmpty(addEmptySlots(apptList[day.formatedDate])).map(
                      (appointment, ind) => (
                        <>
                          <div
                            data-bs-toggle={
                              appointment.live_status !== 'empty'
                                ? 'dropdown'
                                : ''
                            }
                            aria-expanded="false"
                            className={`cell ${
                              status[appointment.live_status]
                            }`}
                            key={ind + 1}
                            data-bs-auto-close="outside"
                            id={`appointmentView-${appointment.appointment_id}`}
                            onClick={(e)=>{
                              if(Object.keys(reschedView).length === 0){
                                e.stopPropagation()
                                e.preventDefault()
                                setAppointmemntView(appointment)
                              }
                            }}

                          >
                            {appointment.live_status !== 'empty' && (
                              <span>
                                {appointment?.payment_details
                                  ?.payment_status === PAID ? (
                                  <Tooltip
                                    title={translate(
                                      'appointments.paymentpaid',
                                      'Paid'
                                    )}
                                  >
                                    <img src={greenTick} alt="" />
                                  </Tooltip>
                                ) : null}
                                {appointment.rescheduled_appointments
                                  ?.length && appointment.live_status === APPOINTMENT_STATUS.upcoming ? (
                                  <Tooltip
                                    title={
                                      APPOINTMENT_STATUS_NAMES[
                                        APPOINTMENT_STATUS.rescheduled
                                      ]
                                    }
                                  >
                                    <img
                                      src={reschedueledIcon}
                                      alt=""
                                       onClick={(e) => handleRescheduleDetails(e, appointment.appointment_id)}
                                    />
                                  </Tooltip>
                                ) : null}
                                {appendTime(
                                  appointment.appt_start_time,
                                  language_Code
                                )}{' '}
                                -{' '}
                                {appendTime(
                                  appointment.appt_end_time,
                                  language_Code
                                )}
                              </span>
                            )}
                          </div>
                          {reschedView[appointment.appointment_id] &&
                            appointment.rescheduled_appointments?.length ? (
                             <div>
                               <div className="popover-container v2 bottom custom-tooltip-v2 history-popover">
                              <div
                                // className={`custom-popover show`}
                                className={`custom-popover ${Object.keys(reschedView).length ? "show":""}`}
                                // ref={ref}
                                onClick={(e)=>{
                                  e.stopPropagation()
                                }}

                              >
                                <div className="popover-header">
                                  <h6>
                                    {/* {translate('doctors.feeHistory', 'Fee History')} */}
                                    Reschedule History
                                  </h6>
                                  <button
                                    className="btn-close"
                                    onClick={handleHistoryClose}
                                  ></button>
                                </div>
                                <div className="name-and-count">
                                  <div className="name tex-capitalize">
                                    Patient Name: {appointment.patient_name}
                                  </div>
                                  <span className="total-res">
                                    {translate("Total Reschedules")}:{" "}
                                    {appointment.rescheduled_appointments?.length}
                                  </span>
                                </div>
                                <div className="popover-body">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Doctor Name </th>
                                        <th>Department</th>
                                        <th>Scheduled From</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {appointment.rescheduled_appointments?.map(
                                        (item) => {
                                          return (
                                            <>
                                              <tr>
                                                {/* <div className="single-history"> */}
                                                <td>
                                                  <span>{item.doctor_name}</span>
                                                </td>
                                                <td>
                                                  <span className="text-capitalize">
                                                    {item.dept_name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <div>
                                                    {formatDateInDDMMYYYY(
                                                      item.appt_date
                                                    )}
                                                  </div>
                                                  <span className="time">
                                                    {appendTime(
                                                      item.appt_start_time
                                                    )}{" "}
                                                    -{" "}
                                                    {appendTime(
                                                      item.appt_end_time
                                                    )}
                                                  </span>
                                                </td>
                                                {/* </div> */}
                                              </tr>
                                            </>
                                          );
                                        }          
                                     )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="overlay"></div>
                             </div>
                            ):
                         // {/* popup for each card */}
                          <ul
                            data-popper-placement="top-start"
                            className={`dropdown-menu custom-popover v2 popupDoctorViewday ${appointment?.appointment_id &&appointmemntView.appointment_id === appointment.appointment_id ? "show" : ""}`}
                            // className="dropdown-menu custom-popover v2 popupDoctorViewday"
                            id={`appointmentViewToggle-${appointment.appointment_id}`}
                            ref={(ref) => (popupRef.current[appointment.appointment_id] = ref)}
                            // ref={popupRef}
                          >
                            <div className="popover-header mb-3">
                              <div className='header-id'>
                                <h6>
                                  {translate(
                                    'patientView.apptDetails',
                                    'Appointment Details'
                                  )}
                                </h6>
                                <div className="d-flex gap-2 align-items-center">
                                  <span> <p className='id'>{appointment?.appointment_id}</p></span>
                                  <span className={`status ${statusDot[appointment?.live_status]}`}>
                                    <div className="dot">•</div> 
                                    {
                                    APPOINTMENT_STATUS_NAMES[
                                      appointment.live_status
                                    ]
                                    }
                                  </span>
                                </div>
                              </div>
                              <span className="d-flex align-items-center gap-1">
                                {appointment.live_status === APPOINTMENT_STATUS.upcoming || appointment.live_status === APPOINTMENT_STATUS.waiting ? (
                                  <button className="btn-transparent"
                                  onClick={() => {
                                    onEdit(appointment);
                                    onClose(appointment.appointment_id);
                                  }}>
                                    <img src={editIcon} alt="" />
                                  </button>
                                ) : (
                                  appointment.live_status != APPOINTMENT_STATUS.completed &&  appointment.live_status !== APPOINTMENT_STATUS.inProgress && appointment.live_status !== APPOINTMENT_STATUS.waiting &&(
                                    <button className="btn-transparent" onClick={() => onDelete(appointment)}>
                                    <img src={deleteIcon} alt="" />
                                    </button>
                                  )
                                )}

                                <button
                                  className="btn-transparent"
                                  onClick={() =>
                                    onClose(appointment.appointment_id)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-x-lg"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                  </svg>
                                </button>
                              </span>
                            </div>
                            <div className="popover-body">
                              <div className="icon-block mb-3">
                               <img src={person} alt="" />
                                <div>
                                  <span>
                                    {translate(
                                      'appointments.patientName',
                                      'Patient Name'
                                    )}
                                  </span>
                                  <p className="mb-0">
                                    {appointment.patient_name}
                                  </p>
                                </div>
                              </div>
                              {appointment.patient_email &&
                                <div className="icon-block mb-3 me-4 bordred-top-bottom">
                                  <img src={envelope} alt="" />
                                  <div className="mt-1">
                                    <span>{translate('common.emailId', 'Email Address')}</span>
                                    <p className="mb-0 mt-2 mail-text">{appointment.patient_email}</p>
                                  </div>
                                </div>
                              }
                              <div className="items-set">
                              <div className="icon-block">
                                <img src={clock} alt="" />
                                <div>
                                <span>Date & Time</span>
                                  <p className="mb-0 mt-2">
                                    {formatDateto_D_WD_M_Y(
                                      new Date(appointment.appt_date),
                                      language_Code
                                    )}
                                  </p>
                                  <p className="mb-0">
                                    {appendTime(
                                      appointment.appt_start_time,
                                      language_Code
                                    )}{' '}
                                    -{' '}
                                    {appendTime(
                                      appointment.appt_end_time,
                                      language_Code
                                    )}
                                  </p>
                                </div>
                              </div>
                              {appointment?.patient_tele_country_code &&
                                <div className="icon-block mb-3">
                                  <img src={telephone} alt="" />
                                  <div className="mt-1">
                                    <span>{translate('users.phone', 'Phone Number')}</span>
                                    <p className="mb-0 mt-2">+{appointment?.patient_tele_country_code}{appointment.patient_phone}</p>
                                  </div>
                                </div>
                              }
                              </div>
                            </div>
                            <div className="popover-footer">
                              <div className="icon-block">
                                <img src={listIcon} alt="" />
                                <div>
                                  <span>
                                    {translate(
                                      'patientView.reason',
                                      'Reason For Appointment'
                                    )}
                                  </span>
                                  <p className="mb-0 first-capital">{appointment.reason}</p>
                                </div>
                              </div>
                              {(appointment.live_status === APPOINTMENT_STATUS.cancelled || appointment.live_status === APPOINTMENT_STATUS.notAttempted) && appointment?.payment_details?.payment_status !== 'paid' ? null : 
                              <div className="popover-footer">
                                <div className="icon-block linked-text">
                                  <img className='coin-icon' src={CoinIcon} />
                                  <div>
                                    <p
                                      onClick={() => {
                                        handlepaymentInfo(appointment);
                                        onClose(appointment.appointment_id);
                                      }}
                                    >
                                      {translate(
                                        'common.paymentInfo',
                                        'Payment Information'
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              }

                             
                                {/* No permission for doctor to cancel appointment
                              <button className="selector">
                                Cancel Appointment
                            <div className="mt-3 d-flex align-items-center gap-3">
                              {/* <button
                                className="btn btn-primary"
                                onClick={() =>
                                  handleAppointmnentStart(
                                    appointment,
                                    (appointment.live_status ===
                                      APPOINTMENT_STATUS.upcoming ||
                                      appointment.live_status ===
                                      APPOINTMENT_STATUS.notAttempted ||
                                      appointment.live_status === 'waiting') &&
                                      formatDate(appointment.appt_date) ===
                                      new Date().toISOString().split('T')[0]
                                      ? 'Start'
                                      : 'View'
                                  )
                                }
                              >
                                {(appointment.live_status ===
                                  APPOINTMENT_STATUS.upcoming ||
                                  appointment.live_status ===
                                  APPOINTMENT_STATUS.notAttempted ||
                                  appointment.live_status === 'waiting') &&
                                  formatDate(appointment.appt_date) ===
                                  new Date().toISOString().split('T')[0]
                                  ? 'Start'
                                  : 'View'}
                              </button> */}
                                {appointment.live_status === 'upcoming' &&
                                  permission(STATUS_UPDATE) && (
                                    <div className="mt-3 d-flex align-items-center gap-3 popover-footer v2">
                                      <button
                                        className="selector"
                                        onClick={() => {
                                          onHandleCancelOpen(appointment);
                                          onClose(appointment.appointment_id);
                                        }}
                                      >
                                        {translate(
                                          'common.cancelAppointment',
                                          'Cancel Appointment'
                                        )}
                                      </button>
                                    </div>
                                  )}
                            </div>
                          </ul>}
                          <div className="overlay"></div>
                        </>
                      )
                    )
                  ) : (<>
                    {Array(fillEmptySlots()).fill(0).map((x, i) => <div className="cell empty h-100" key={i}></div>)}</>
                  )}
                </div>
              ))}
              {apptisLoader || isloader ? (
                <div className="loader-container">
                  <Loader />
                </div>
              ) : null}
            </div>
          </div>
          <div className="schedule-footer">
            <div>
              <span className="legend in-progress">
                <span className="dot">●</span>{' '}
                {APPOINTMENT_STATUS_NAMES[APPOINTMENT_STATUS.inProgress]}
              </span>
              <span className="legend upcoming">
                <span className="dot">●</span>{' '}
                {APPOINTMENT_STATUS_NAMES[APPOINTMENT_STATUS.upcoming]}
              </span>
              <span className="legend cancelled">
                <span className="dot">●</span>
                {APPOINTMENT_STATUS_NAMES[APPOINTMENT_STATUS.cancelled]}
              </span>
              <span className="legend not-attempted">
                <span className="dot">●</span>{' '}
                {APPOINTMENT_STATUS_NAMES[APPOINTMENT_STATUS.notAttempted]}
              </span>
              <span className="legend completed">
                <span className="dot">●</span>{' '}
                {APPOINTMENT_STATUS_NAMES[APPOINTMENT_STATUS.completed]}
              </span>
              <span className="legend waiting">
                <span className="dot">●</span>{' '}
                {APPOINTMENT_STATUS_NAMES[APPOINTMENT_STATUS.waiting]}
              </span>
            </div>
          </div>
        </div>
        {permission(CREATE) && (
          <button className="add-btn v2" onClick={() => handleAddButton()}>
           {AddIcon}
          </button>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  appointmentList: state.AppointmentReducer.appointmentList,
  selectedDepartmentData: state.AppointmentReducer.selectedDepartmentData,
  doctorSlotData: state.AppointmentReducer.doctorSlotData,
  currentPage: state.AppointmentReducer.paginationData.currentPage,
  isBtnLoading: state.AppointmentReducer.isBtnLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getAppointments: (data, setLoader) =>
    dispatch(FetchAppointmentList(data, setLoader)),
  startAppointment: (data, onSuccess, onError) =>
    dispatch(startAppointment(data, onSuccess, onError)),
  getDoctorSlots: (id, config) => dispatch(FetchDoctorSlots(id, config)),
  getDepartmentData: (id, config) => dispatch(FetchDepartmentData(id, config)),
  clearDeptData: () => dispatch(clearDepartmentData()),
  clearSlots: () => dispatch(clearDoctorSlots()),
  editAppointment: (id, data, fetchAppointments, handleEditClose) =>
    dispatch(UpdateAppointment(id, data, fetchAppointments, handleEditClose)),
  onCancelAppointment: (id, data, fetchAppointments, onHandleCancelClose) =>
    dispatch(
      CancelAppointment(id, data, fetchAppointments, onHandleCancelClose)
    ),
  deleteAppointment: (id, handleDeleteSuccess, updateLoading) =>
    dispatch(DeleteAppointment(id, handleDeleteSuccess, updateLoading)),
  SetCurrentPage: (data) => dispatch(updateCurrentPage(data)),
  paymentHandle: (id, data, fetchAppointments) =>
    dispatch(paymentHandle(id, data, fetchAppointments)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissionCheck(APPOINTMENT)
)(WeekRoster);
