import React from 'react';
import Unauthorized from '../../Components/NotFound/UnauthorisedPage';
import { useSelector } from 'react-redux';

const withPermissionCheck = (feature) => (WrappedComponent) => {
  return function WithPermissionCheck(props) {
    const permissions = useSelector(state => state.LoginReducer.userPermissions.ResourceNameScope)
    const hasPermission = () => {
      const permission = permissions?.[feature];
      if (!permission || !permission?.length ) {
        return <Unauthorized />;
      }
      let canAccess=(scope)=>{
        return permission.includes(scope)
      }
      let ACCESSOF=(feature,scope)=>{
        const permission = permissions?.[feature];
        return permission.includes(scope)
      }
      return (
        <WrappedComponent
          {...props}
          ACCESS={canAccess}
          ACCESSOF={ACCESSOF}
          />
      );
    };
    return hasPermission();
  };
};

export default withPermissionCheck;
