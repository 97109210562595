import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import noResult_T1 from '../../../Assets/img/icons/theme-1/no-results-found.svg';
import noResult_T2 from '../../../Assets/img/icons/theme-2/no-results-found.svg';
import { translate } from '../../../Language/Translate';
import {
  linkExistingProfile,
  validateToken,
} from 'Actions/patientProfileAction';
import { ToasterTypes, showToaster } from 'Actions/toasterAction';
import { useIntl } from 'react-intl';
import ButtonLoader from 'Components/Common/ButtonLoader';
import { timeZoneDate } from 'Utils/DateFormatter/Date';

const ExitingPatient = (props) => {
  const {
    onSubmitSuccess,
    modalShow,
    addExistingProfile,
    verifyOtp,
    getLinkedProfileList,
    search,
    setSearch,
    showToaster,
    otpDetails,
    displaytoaster,
    loading,
    showOtpLoader,
    applicationData
  } = props;
  const [isSearch, setIsSearch] = useState(false);
  const [ searchValue,setSearchValue]=useState('')

  const intl = useIntl();
  const inputRef = useRef()
  const firstField = useRef()
  const [resultFound, setResultFound] = useState(false);
  const [receivedData, setReceivedData] = useState({});
  const [countdown, setCountdown] = useState('');
  const [showCounter, setShowCounter] = useState(false);
  const [otp, setOtp] = useState('');
  const [errObj, setErrObj] = useState({});
  // Resend Counter
  const [resend, setResend] = useState(0);
  const [resendCounter, setResendCounter] = useState(0);
  const [showResend, setShowResend] = useState(false);
  const { otp_resend_limit, otp_limit_duration } = otpDetails;
  const [resendLoader, setResendLoader] = useState(false);

  const theme = useSelector((state) => state?.SettingsReducer?.theme);

  useEffect(() => {
    const resendDetails = JSON.parse(localStorage.getItem('Resend'));
    if (resendDetails) {
      const otpverifyPop = resendDetails?.existingOtp;
      if (otpverifyPop) {
        let ResendInterval = setInterval(
          () => remainingResendTime(otpverifyPop, ResendInterval),
          1000
        );
      }
    } else {
      localStorage.setItem('Resend', JSON.stringify({}));
    }
  }, []);

  useEffect(() => {
    if (receivedData && receivedData.exp) {
      let targetTime =
        timeZoneDate().getTime() + receivedData?.exp * 60 * 1000 + 1000;
      let interval = setInterval(
        () => remainingTime(targetTime, interval),
        1000
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, [receivedData]);

  let remainingTime = (targetTime, interval) => {
    let currentTime = timeZoneDate().getTime();
    let leftSecond = Math.trunc((targetTime - currentTime) / 1000);
    setCountdown(leftSecond);
    if (leftSecond <= 0) {
      clearInterval(interval);
      setShowCounter(false);
    } else if (!showCounter) {
      setShowCounter(true);
    }
    return leftSecond;
  };

  let remainingResendTime = (targetTime, Interval) => {
    let currentTime = timeZoneDate().getTime();
    let leftSecond = Math.trunc((targetTime - currentTime) / 1000);
    setResendCounter(leftSecond);
    if (leftSecond <= 0) {
      clearInterval(Interval);
      setShowResend(false);
      setResend(0);
    } else if (!showResend) {
      setShowResend(true);
    }
    return leftSecond;
  };

  useEffect(() => {
    if (!modalShow) {
      setSearch('');
      setIsSearch(false);
      setOtp('');
    }
  }, [modalShow]);

  // handle patient search
  const handleSearch = () => {
    const resendDetails = localStorage.getItem('Resend');
    const { existingOtp } = JSON.parse(resendDetails);
    if (existingOtp && (existingOtp - timeZoneDate().getTime()) / 1000 > 0) {
      displaytoaster(
        intl.formatMessage({
          id: 'commom.otpLimitMsg',
          defaultMessage:
            'OTP send limit exceeded please try again after 5 min',
        }),
        ToasterTypes.Warning
      );
    } else {
      let payload = {
        data: {
          patient_query: search,
          params:{use_custom_mrn:!applicationData?.use_system_generated_mrn}
        },
      };
      addExistingProfile(
        payload,
        onAddExistingProfileSuccess,
        onAddExistingProfileError
      );
    }
  };
  const onAddExistingProfileError = () => {
    setIsSearch(true);
    setResultFound(false);
  };
  const onAddExistingProfileSuccess = (result) => {
    let { patient_data, otp_token } = result;
    setReceivedData({
      phone: patient_data?.phone,
      patient_id: patient_data?.patient_id,
      email: patient_data?.email,
      token: otp_token?.token,
      exp: otp_token?.exp,
    });
    setIsSearch(true);
    setResultFound(true);
    inputRef.current.focus();
    const timer = setTimeout(() => {
      setResendLoader(false);
    }, 3000);
    return () => clearTimeout(timer);
  };

  // handle search change
  const handleSearchChnage = (e) => {
    setSearch(e.target.value);
    setSearchValue(e.target.value.trim().length)
  };

  const validate = () => {
    if (!otp || otp.trim() == '') {
      setErrObj({ otp: true });
      return false;
    } else {
      setErrObj({ otp: false });
      return true;
    }
  };
  // handle submit
  const handleSubmit = () => {
    if (validate()) {
      let data = {
        data: {
          patient_query: search,
          otp_verification: {
            otp: otp,
            token: receivedData?.token,
          },
          params:{ use_custom_mrn:!applicationData?.use_system_generated_mrn}
        },
      };
      verifyOtp(data, onSucessOtpVerification, onErrVerification);
    }
  };
  const onSucessOtpVerification = () => {
    onSubmitSuccess();
    getLinkedProfileList();
  };
  const onErrVerification = (message) => {
    showToaster(message, ToasterTypes.Failed);
  };
  const handleChangeOtp = (e) => {
    if (e?.target?.value.trim()) {
      setErrObj({ otp: false });
    }
    if (!isNaN(e.target.value)) {
      setOtp(e.target.value);
    }
  };
  const handleResendOtp = () => {
    setResendLoader(true)
    setCountdown(receivedData?.exp * 60);
    setShowCounter(true);
    handleSearch();
    setOtp('');
    setResend(resend + 1);
    if (resend + 1 >= otp_resend_limit) {
      let targetResendTime =
        timeZoneDate().getTime() + otp_limit_duration * 60 * 1000 + 1000;
      const resendlocal = JSON.parse(localStorage.getItem('Resend'));
      localStorage.setItem(
        'Resend',
        JSON.stringify({ ...resendlocal, existingOtp: targetResendTime })
      );
      let IntervalRemaining = setInterval(
        () => remainingResendTime(targetResendTime, IntervalRemaining),
        1000
      );
    }
  };

  // FIX-ME - shift between nodata and data found during integration
  return (
    <div
      className="tab-pane fade show active"
      id="new-patient-tab"
      role="tabpanel"
    >
      <div className="row align-items-end">
        <div className="col-sm-9">
          <div className="form-group mb-0">
            <label htmlFor="searchPatient" className="label">
              
              {applicationData?.use_system_generated_mrn?
               translate(
                "linkProfile.search",
                "Search by Email ID / Mobile Number / Patient ID"
              ):
              translate(
                "linkProfile.searchmrn",
                "Search by Email ID / Mobile Number / MRN (Health ID)"
              )
             }
            </label>
            <input
              className="input form-control"
              type="search"
              placeholder="i.e, johndoe@gmail.com / 919895098950 / TM-PT-100159"
              name="serach"
              id="serach"
              maxLength="35"
              value={search}
              onChange={handleSearchChnage}
              ref={firstField}
              onKeyDown={(e) => {
                if (e.key === "Tab" && !e.shiftKey && !searchValue)
                  e.preventDefault();
              }}
              autoFocus
            />
          </div>
        </div>
        <div className="col-sm-3 mt-3 ps-sm-0">
          <button
            className="btn btn-secondary w-100 h-40"
            disabled={!searchValue}
            onClick={loading ? () => {} : handleSearch}
            onKeyDown={(e) => {
              if (!resultFound && !e.shiftKey && e.key === "Tab")
                e.preventDefault();
            }}
          >
            {loading ? (
              <ButtonLoader
                text={translate("medicalRecords.search", "Search")}
              />
            ) : (
              translate("medicalRecords.search", "Search")
            )}
          </button>
        </div>
      </div>
      {isSearch ? (
        <>
          {/* result found */}
          {resultFound ? (
            <div>
              <div className="results-container">
                <h6>
                  {translate("linkProfile.match", "Matched profile found")}
                </h6>
                <p>
                  {translate(
                    "linkProfile.otpSent",
                    "OTP has been successfully sent to the mobile number registered on your account."
                  )}
                </p>
                <div className="row patient-data">
                  <div className="col-4 mb-3 label">
                    {translate("linkProfile.email", "Email ID")}
                  </div>
                  <div className="col-8 mb-3 value">
                    {receivedData?.email ? receivedData?.email : "-"}
                  </div>
                  <div className="col-4 mb-3 label">
                    {translate("linkProfile.mobile", "Mobile Number")}
                  </div>
                  <div className="col-8 mb-3 value">{receivedData?.phone}</div>
                  <div className="col-4 mb-3 label">
                    {
                    applicationData?.use_system_generated_mrn?
                    translate("linkProfile.patient", "Patient ID"): 
                    translate('appointments.patientMRN', 'Patient MRN')
                    }
                  </div>
                  <div className="col-8 mb-3 value">
                    {receivedData?.patient_id}
                  </div>
                  <div className="col-4 mb-3 label">OTP</div>
                  <div className="col-8 mb-3 value">
                    <input
                      className={
                        errObj.otp
                          ? "input otp-input input-err"
                          : "input otp-input"
                      }
                      type="text"
                      name=""
                      id=""
                      ref={inputRef}
                      maxLength="4"
                      value={otp}
                      onChange={(e) => handleChangeOtp(e)}
                    />
                    <span className={`${errObj.otp ? "err" : "d-none"}`}>
                      {translate("common.required", "This field is required")}
                    </span>
                    <div className="mt-3">
                      {resendCounter < 1 && showCounter && countdown && (
                        <>
                          {translate(
                            "patientPortal.otpExpire",
                            "OTP will expire in"
                          )}{" "}
                          {showCounter && countdown}{" "}
                          {translate("appointments.sec", "sec")}
                        </>
                      )}
                    </div>
                    {resendCounter < 1 &&
                      countdown === 0 &&
                      resend < otp_resend_limit && (
                        <>
                          {resendLoader ? (
                            <a className="d-block mt-4 color-primary resend-otp loading">
                              {translate("patientPortal.resend", "Resend OTP")}
                            </a>
                          ) : (
                            <a
                              className="d-block mt-4 color-primary resend-otp"
                              onClick={handleResendOtp}
                            >
                              {translate("patientPortal.resend", "Resend OTP")}
                            </a>
                          )}
                        </>
                      )}
                    {resendCounter > 0 && (
                      <>
                        {intl.formatMessage({
                          id: "commom.otpLimitMsg",
                          defaultMessage:
                            "OTP send limit exceeded please try again after 5 min",
                        })}
                        {/* {translate('patientPortal.resend', 'Resend OTP')} */}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="footer-btn">
                <button
                  id="save"
                  className="btn btn-primary px-4 loader-btn"
                  onClick={showOtpLoader ? () => {} : handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Tab" && !e.shiftKey) {
                      e.preventDefault();
                      firstField.current.focus();
                    }
                  }}
                >
                  {showOtpLoader ? (
                    <ButtonLoader
                      text={translate("common.verifyOtp", "Verify OTP")}
                    />
                  ) : (
                    translate("common.verifyOtp", "Verify OTP")
                  )}
                </button>
              </div>
            </div>
          ) : (
            <div className="no-results">
              <img
                className="no-results-img"
                src={theme === "theme-2" ? noResult_T2 : noResult_T1}
                alt=""
              />
              <h6>{translate("linkProfile.noResult", "No results found.")}</h6>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};
const mapStateToProps = (state) => ({
  otpDetails: state.SettingsReducer.otpDetails,
  loading:state.ProfileReducer.isBtnLoading,
  showOtpLoader: state.ProfileReducer.showOtpLoader,
  applicationData: state.SettingsReducer.applicationData,
});

const mapDispatchToProps = (dispatch) => ({
  showToaster: (message, type) => {
    dispatch(showToaster(message, type));
  },
  addExistingProfile: (data, callBack, errCallBack) =>
    dispatch(linkExistingProfile(data, callBack, errCallBack)),
  verifyOtp: (data, onSubmitSuccess, errCallBack) =>
    dispatch(validateToken(data, onSubmitSuccess, errCallBack)),
  displaytoaster: (message, type) => dispatch(showToaster(message, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExitingPatient);
