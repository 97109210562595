import { HideToaster, setToasterRef, ToasterTypes } from 'Actions/toasterAction';
import { translate } from 'Language/Translate';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import './style/toaster.css';

const Toaster = ({ type, toasterMessage, closeToaster, toasterDisplay, setToasterRef }) => {

  const toasterRef = useRef();

  useEffect(() => {
      setToasterRef(toasterRef)
  }, [toasterRef])

  return (
    <>
      <div ref={toasterRef}
        className={`toast ${
          type === ToasterTypes.Success
            ? 'toast-success'
            : type === ToasterTypes.Failed
            ? 'toast-error'
            : type === ToasterTypes.Warning
            ? 'toast-warning'
            : type === ToasterTypes.Info
            ? 'toast-info'
            : ''
        } ${toasterDisplay ? 'show' : ''}`}
      >
        <div className="img-container"></div>
        <div className="content">
          <div>
            <h6>
              {type === ToasterTypes.Success
                ? translate('common.success', 'Success')
                : type === ToasterTypes.Failed
                ? translate('common.error', 'Error !')
                : type === ToasterTypes.Warning
                ? translate('common.warning', 'Warning !')
                : type === ToasterTypes.Info
                ? translate('common.info', 'Information')
                : ''}
            </h6>
            <p>{toasterMessage}</p>
          </div>
          <button className="btn-close" onClick={closeToaster}></button>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closeToaster: () => dispatch(HideToaster()),
  setToasterRef: (ref) => dispatch(setToasterRef(ref))
});

const mapStateToProps = (state) => ({
  toasterMessage: state.ToasterReducer.toasterMessage,
  type: state.ToasterReducer.toasterType,
  toasterDisplay: state.ToasterReducer.toasterDisplay,
});

export default connect(mapStateToProps, mapDispatchToProps)(Toaster);
