import { APPOINTMENT } from 'Constants/feature';
import { CREATE } from 'Constants/scopes';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import AddAppointment from './AddAppointment';
import AppointmentsRoster from './AppointmentsRoster';
// import Appointments from './Appointments';
import Unauthorized from 'Components/NotFound/UnauthorisedPage';

const AppointmentMain = (props) => {
  const { match } = props;
  const permissions = useSelector(state => state.LoginReducer.userPermissions.ResourceNameScope)

  const checkpermission = (feature, scope) => {
    const permission = permissions?.[feature];
    if (!permission || !permission?.length) {
      return false
    }
    else {
      return permission.includes(scope)
    }
  }
  return (
    <>
      <Route
        path={`${match.path}/addAppointment`}
        exact
        component={checkpermission(APPOINTMENT, CREATE) ? AddAppointment : Unauthorized}
      />
      <Route path={match.path} exact component={AppointmentsRoster} />
    </>
  );
};

export default AppointmentMain;
