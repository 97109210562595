import React, { useState, useRef } from 'react';

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  //   Crop,
  //   PixelCrop,
  //   convertToPixelCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDebounceEffect } from 'Hooks/useDebounceEffect';
import { translate } from 'Language/Translate';
import { canvasPreview } from 'Components/Common/canvas';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function CropImage({ imgSrc, setShowCrop, setData, onClose }) {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const aspect = 1;

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  async function onDownloadCropClick() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      setData(reader.result);
      setShowCrop(false);
    };
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    [completedCrop]
  );

  return (
    <>
      <div className="row cropper-outer">
        <div className="col-12 cropper-container" style={{ margin: 10 }}>
          {!!imgSrc && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#fff',
                height: 200,
                overflow: 'hidden',
              }}
            >
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => {
                  setCompletedCrop(c);
                }}
                aspect={aspect}
                keepSelection
                minWidth={100}
                style={{ maxWidth: '100%', height: 200 }}
                minHeight={100}
                circularCrop
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{
                    transform: `scale(${1}) rotate(${0}deg)`,
                    maxWidth: '100%',
                    height: 200,
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
          )}
          {!!completedCrop && (
            <div className="d-none">
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </div>
          )}
        </div>
        <div className="col-md-12 px-0 pt-3">
          <ul className="mt-0 gap-3 d-flex justify-content-end mb-0 crop-footer">
            <li onClick={onClose} className="ms-0 d-block d-sm-inline">
              <button type="button" className="btn btn-outlined-dark mb-0 px-4 py-2" id="cancel">
                {translate('profileSetting.cancel', 'Cancel')}{' '}
              </button>
            </li>

            <li className="ms-0 d-block d-sm-inline ps-0">
              <button
                type="button"
                onClick={onDownloadCropClick}
                className="btn btn-primary mb-0 loader-btn py-2"
                id="save"
              >
                {translate('common.saveChanges', 'Save Changes')}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
