import React, { useEffect, useState } from 'react';

const Modal = ({
  close,
  setClose,
  children,
  title,
  modalClass = '',
  style,
  modalBodyClass ='',
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [displayMode, setDisplayMode] = useState(false);
  // state for animation
  // useeffect for animation for Modal 0.2 seconds transform set
  useEffect(() => {
    if (close) {
      setDisplayMode(true);
    } else {
      setOpenModal(false);
    }
    setTimeout(() => {
      if (close) {
        if(!displayMode){
          setDisplayMode(true);
        }
          setOpenModal(true);
      } else {
        setDisplayMode(false);
      }
    }, 100);
  }, [close]);

  useEffect(() => {
    if (close) {
      setTimeout(() => {
        document.body.classList.add('modal-overflow');
      }, 0);
    } else {
      document.body.classList.remove('modal-overflow');
    }
    return () => document.body.classList.remove('modal-overflow');
  }, [close]);
  return (
    <div>
      <div
        className={`modal custom-modal fade ${modalClass} ${
          openModal ? 'show' : ''
        }`}
        id="guestModal"
        style={{ display: displayMode ? 'block' : 'none' }}
      >
        <div className="modal-dialog modal-fullscreen-md-down modal-dialog-centered">
          <div className="modal-content" style={{ borderRadius: '.5rem' }}>
            <div
              className="modal-header"
              style={style ? { border: 'none' } : null}
            >
              <h1 className="modal-title fs-5 mb-0" id="guestModalLabel">
                {title}
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={() => setClose(false)}
              ></button>
            </div>
            <div className={`modal-body ${modalBodyClass}`}>{children}</div>
          </div>
        </div>
      </div>
      {
        <div
          className={`modal-backdrop fade ${openModal ? 'show' : ''}`}
          style={{ display: displayMode ? 'block' : 'none' }}
        ></div>
      }
    </div>
  );
};

export default Modal;
