import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import PhoneNumberInput from '../../Common/PhoneInput';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import { translate } from '../../../Language/Translate';
import './Contact.css';
import { queryTypeList } from 'Constants/constant';
import { validateEmail, validateMobile } from 'Utils/Validation/validator';
import { fetchContactInformation, postFeedback } from 'Actions/contactAction';
import { connect } from 'react-redux';
import { DepartmentsApi } from 'Utils/Api/DepartmentApi';
import {
  generateCaptchaImage,
  generateCaptchText,
} from 'Utils/Captcha/captchaGenerator';
import ButtonLoader from 'Components/Common/ButtonLoader';
import Loader from 'Components/Common/Loader';
import useNavigationWarning from 'Hooks/useNavigationWarning';
import ContactEmpty from '../../../Assets/img/bg/contact-us-empty-img.svg'

const Contact = (props) => {
  const { addFeedback, getContactInfo, defaultCountryCode, isBtnLoading } = props;
  const {Prompt,setIsSaved}=useNavigationWarning()
  const intl = useIntl();
  const focusRef = useRef()
  const phoneInputRef = useRef()
  const [queryType, setQueryType] = useState(queryTypeList.enquiry);
  const [errMsg, setErrMsg] = useState({
    fNameErr: false,
    emailErr: false,
    mobileErr: false,
    messageErr: false,
    emailValidErr: false,
    captchaErr: false,
    mobileValidErr: false,
    invalidCaptcha: false,
  });
  const [infoData, setInfoData] = useState({
    fName: '',
    lName: '',
    email: '',
    mobile: '',
    message: '',
    captcha: '',
    country: defaultCountryCode?.toString(),
    type: queryTypeList.enquiry,
  });
  const [dummyData] = useState({
    fName: '',
    lName: '',
    email: '',
    mobile: '',
    message: '',
    captcha: '',
    country: defaultCountryCode?.toString(),
    type: queryTypeList.enquiry,
  });
  const [deptOptions, setDeptOptions] = useState([]);
  const [selectedDept, setSelectedDept] = useState([]);
  const [docOptions, setDocOptions] = useState([]);
  const [seletcedDoc, setSelectedDoc] = useState([]);
  const [mainBranch, setMainBranch] = useState({});
  const [branches, setBranches] = useState([]);
  const [captchaText, setCaptchaText] = useState('');
  const [captchaImg, setCaptchaImg] = useState('');
  const [isLoading,setIsLoading]=useState(false)
   const [docLoading, setDocLoading] = useState(false);
   const [numberLength, setNumberLength] = useState(10);
   const [code,setCode]=useState('IN')

  const getDepartmentList = () => {
    DepartmentsApi.FetchAllDepartmentList({
      limit: 0,
      status: 'active',
      sort: "dept_name"
    }).then(({ error, response }) => {
      if (error) {
        return;
      }
      if (response) {
        const options = response?.departments?.map((dept) => ({
          label: dept.dept_name,
          value: dept.dept_id,
        }));
        setDeptOptions(options);
      }
    });
  };

  const getDepatmentData = (search) => {
    setDocLoading(true)
    DepartmentsApi.GetDepartmentDetails(search, { status: 'active' }).then(
      (res) => {
        setDocLoading(false)
        if (res.error) {
          return;
        }
        if (res.response.doctors) {
          const options = res.response.doctors?.map((doc) => ({
            label: `Dr. ${doc.name} ${doc.last_name}`,
            value: doc.doctor_id,
          }));
          setDocOptions(options);
        } else {
          setDocOptions([]);
        }
      }
    );
  };

  const setBranchDetails = (data) => {
    setIsLoading(false)
    const mainBranchData =
      data.find((item) => item.is_main_branch) || data[0] || {};
    setMainBranch(mainBranchData);
    const otherBarnches = data.filter(
      (item) => item.branch_id !== mainBranchData.branch_id
    );
    setBranches(otherBarnches);
  };

  useEffect(()=>{
    if(JSON.stringify(infoData)!==JSON.stringify(dummyData)){
      setIsSaved(false)
    }
    else{
      setIsSaved(true)
    }
  },[infoData])

  useEffect(() => {
    getDepartmentList();
    setIsLoading(true)
    getContactInfo(setBranchDetails);
    const initialCaptcha = generateCaptchText();
    const initialCaptchaImg = generateCaptchaImage(initialCaptcha);
    setCaptchaText(initialCaptcha);
    setCaptchaImg(initialCaptchaImg);
  }, []);

  useEffect(() => {
    setInfoData((prev) => ({
      ...prev,
      country: defaultCountryCode?.toString(),
    }));
  }, [defaultCountryCode]);

  // reset errors
  const resetErrors = () => {
    setErrMsg({
      fNameErr: false,
      emailErr: false,
      mobileErr: false,
      messageErr: false,
      emailValidErr: false,
      captchaErr: false,
      mobileValidErr: false,
      invalidCaptcha: false,
    });
  };

  // handle captcha reset
  const handleCaptchaReset = () => {
    const newCaptcha = generateCaptchText();
    const newCaptchaImg = generateCaptchaImage(newCaptcha);
    setCaptchaText(newCaptcha);
    setCaptchaImg(newCaptchaImg);
    setInfoData((prev) => ({
      ...prev,
      captcha: '',
    }));
    setErrMsg((prev) => ({
      ...prev,
      invalidCaptcha: false,
    }));
  };

  // query type change
  const changeQueryTypeHandle = (type = queryType) => {
    setQueryType(type);
    setInfoData((prev) => ({
      ...prev,
      fName: '',
      lName: '',
      email: '',
      mobile: '',
      message: '',
      country: defaultCountryCode?.toString(),
      captcha: '',
      type: type,
    }));
    resetErrors();
    setSelectedDoc([]);
    setSelectedDept([]);
    setDocOptions([]);
    // handleCaptchaReset();
  };

  // handle country code channge
  const handleMobileNumberChange = (phone) => {
    setInfoData((prev) => ({
      ...prev,
      country: phone,
    }));
  };
  const handleKeyDown = (e)=>{
    const dropDownBtn = document.querySelector('.selected-flag')
    if(e.key === "Enter"){
      if(!dropDownBtn.classList.contains("open")){
        e.preventDefault()
        dropDownBtn.classList.toggle("open")
        dropDownBtn.click()
      }
      else
      {
        phoneInputRef.current?.focus()
      }
    }
    if(e.key === "Tab" && dropDownBtn.classList.contains("open")){
      e.preventDefault()
      dropDownBtn.classList.contains("open")
      focusRef.current?.focus()
        dropDownBtn.click()
    }
  }

  // handle dept change
  const handleDeptChange = (value) => {
    setSelectedDept(value);
    getDepatmentData(value.value);
    setSelectedDoc([]);
    setDocOptions([]);
  };

  // handle doc change
  const handleDocChange = (value) => {
    setSelectedDoc(value);
  };

  // handle message submit success
  const handleMessageSubmitSuccess = () => {
    changeQueryTypeHandle();
    handleCaptchaReset();
  };

  // on submit handle
  const sendMessageHandle = () => {
    var isValid = true;
    if (
      !infoData.fName.trim().length ||
      !infoData.email ||
      !infoData.mobile ||
      !infoData.country ||
      !infoData.message.trim().length ||
      !infoData.captcha
    ) {
      isValid = false;
      setErrMsg((prev) => ({
        ...prev,
        fNameErr: !infoData.fName.trim().length,
        emailErr: !infoData.email,
        mobileErr: !infoData.mobile || !infoData.country,
        messageErr: !infoData.message.trim().length,
        captchaErr: !infoData.captcha,
        emailValidErr: false,
        mobileValidErr: false,
        invalidCaptcha: false,
      }));
    }
    if (infoData.email && !validateEmail(infoData.email)) {
      isValid = false;
      setErrMsg((prev) => ({
        ...prev,
        emailValidErr: true,
      }));
    }
    if (infoData.mobile && !validateMobile(infoData.mobile,code)) {
      isValid = false;
      setErrMsg((prev) => ({
        ...prev,
        mobileValidErr: true,
      }));
    }
    if (infoData.captcha && infoData.captcha !== captchaText) {
      isValid = false;
      setErrMsg((prev) => ({
        ...prev,
        invalidCaptcha: true,
      }));
    }
    if (isValid) {
      resetErrors();
      const config = {
        data: {
          query_type: queryType,
          first_name: infoData.fName,
          last_name: infoData.lName,
          email: infoData.email,
          tele_country_code: infoData.country,
          phone: infoData.mobile?.replace(/\s/g, ""),
          department_id: selectedDept.value ?? undefined,
          doctor_id: seletcedDoc.value ?? undefined,
          message: infoData.message,
        },
      };
      addFeedback(config, handleMessageSubmitSuccess);
    }
  };

  // input handle
  const inputChangeHandle = (e) => {
    const { name, value } = e.target;
    const regex = /^\+?[0-9 ]*\.?[0-9 ]*$/;

    // to store data handle
    if (name === 'mobile') {
        if(!value || validateMobile(value,code)){
          setErrMsg((prev) => ({
            ...prev,
            mobileValidErr: false,
          }));
        }
      if (regex.test(value)) {
        setInfoData((prev) => ({
          ...prev,
          [name]: value,
        }));
        // to remove error message handle
        setErrMsg((prev) => ({
          ...prev,
          [name + 'Err']: false,
        }));
      }
    } else {
      setInfoData((prev) => ({
        ...prev,
        [name]: value,
      }));
      // to remove error message handle
      setErrMsg((prev) => ({
        ...prev,
        [name + 'Err']: false,
      }));
    }

    if (name === 'email' && validateEmail(value)) {
      setErrMsg((prev) => ({
        ...prev,
        emailValidErr: false,
      }));
    } else if (name === 'mobile' && validateMobile(value,code)) {
      setErrMsg((prev) => ({
        ...prev,
        mobileValidErr: false,
      }));
    } else if (name === 'captcha' && value === captchaText) {
      setErrMsg((prev) => ({
        ...prev,
        invalidCaptcha: false,
      }));
    }
  };

  return (
    <div className="medical-records">
      {Prompt}
      <div className="bread-crumbs mb-lg-2">
        <div className="wrapper links">
          <a className="previous" href="#/webConnect">
            {translate('patientPortal.home', 'Home')}
          </a>
          <span> {`>`} </span>
          <a className="bread-crumb-cursor">{translate('contactUs.contactus', 'Contact Us')} </a>
        </div>
      </div>
      <div className="main-header">
        <div className="wrapper">
          <h1 className="main-title">
            {translate('contactUs.contactus', 'Contact Us')}
          </h1>
        </div>
      </div>
      <div className="contact-us">
        {!isLoading?<div className="wrapper">
          <div className="row">
            <div className="col-lg-6">
              {Object.keys(mainBranch)?.length != 0 ?
              <>
              <h1 className="district">{mainBranch.address_highlight}</h1>
              <h5 className='app-name'>{mainBranch.branch_name}</h5>
              <div className="row address">
                <div className="col-sm-6">
                  <p>
                    {mainBranch.address
                      ? mainBranch.address?.split('\n').map((x) => (
                          <>
                            {`${x}`} <br />
                          </>
                        ))
                      : null}
                  </p>
                </div>
                <div className="col-sm-6 links">
                  {mainBranch.phone_primary ? (
                    <div className="icon-link">
                      <i className="bi bi-phone"></i>
                      <a href="tel:+91 98950 98950">
                        {mainBranch.tele_country_code_primary
                          ? '+' + mainBranch.tele_country_code_primary
                          : null}{' '}
                        {
                          mainBranch.phone_primary ?
                        mainBranch.phone_primary.match(/.{1,5}/g).join(" ")
                        :null  
                      }
                      </a>
                    </div>
                  ) : null}
                  {mainBranch.phone_secondary ? (
                    <div className="icon-link">
                      <i className="bi bi-telephone"></i>
                      <a href="tel:+91 484 2683 330">
                        {mainBranch.tele_country_code_secondary
                          ? '+' + mainBranch.tele_country_code_secondary
                          : null}{' '}
                        {mainBranch.phone_secondary}
                      </a>
                    </div>
                  ) : null}
                  {mainBranch.email ? (
                    <div className="icon-link">
                      <i className="bi bi-envelope"></i>
                      <a href={`mailto:${mainBranch.email}`}>
                        {mainBranch.email}
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
              </> : null }
              <h5 className="fw-bold one-liner">
                {translate('contactUs.connectUs', 'Let’s Connect with Us')}
              </h5>
              {/* feedback form */}
              <div className="feed-back row">
                <div className="col-12 mb-1">
                  <div className="form-group">
                    <label className="mb-3 pb-1">
                      {translate(
                        'contactUs.chooseType',
                        'Choose your Query type'
                      )}
                    </label>
                    <div className="radio-container mb-4 pb-1">
                      <div
                        onClick={() =>
                          changeQueryTypeHandle(queryTypeList.enquiry)
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <input
                          type="radio"
                          name="queryType"
                          onChange={() =>
                            changeQueryTypeHandle(queryTypeList.enquiry)
                          }
                          checked={queryType === queryTypeList.enquiry}
                        />
                        {translate('contactUs.enquiry', 'Enquiry')}
                      </div>
                      <div
                        onClick={() =>
                          changeQueryTypeHandle(queryTypeList.complaint)
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <input
                          type="radio"
                          name="queryType"
                          onChange={() =>
                            changeQueryTypeHandle(queryTypeList.complaint)
                          }
                          checked={queryType === queryTypeList.complaint}
                        />
                        {translate('contactUs.complaint', 'Complaint')}
                      </div>
                      <div
                        onClick={() =>
                          changeQueryTypeHandle(queryTypeList.feedback)
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <input
                          type="radio"
                          name="queryType"
                          onChange={() =>
                            changeQueryTypeHandle(queryTypeList.feedback)
                          }
                          checked={queryType === queryTypeList.feedback}
                        />
                        {translate('contactUs.feedback', 'Feedback')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="form-group">
                    <label className="mandatory">
                      {translate('contactUs.firstName', 'First Name')}
                    </label>
                    <input
                      placeholder="i.e John"
                      type="text"
                      name="fName"
                      value={infoData?.fName}
                      maxLength="40"
                      className={`input ${
                        errMsg.fNameErr ? 'input-err ' : ''
                      }form-control`}
                      onChange={(e) => inputChangeHandle(e)}
                    />
                    {errMsg.fNameErr ? (
                      <span className="err">
                        {translate(
                          'common.required',
                          'This field is required.'
                        )}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="form-group">
                    <label>
                      {translate('contactUs.lastName', 'Last Name')}
                    </label>
                    <input
                      placeholder="i.e Doe"
                      type="text"
                      name="lName"
                      value={infoData?.lName}
                      maxLength="40"
                      className="input form-control"
                      onChange={(e) => inputChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="form-group">
                    <label className="mandatory">
                      {translate('contactUs.emailAddress', 'Email Address')}
                    </label>
                    <input
                      placeholder="i.e, johndoe@gmail.com"
                      type="text"
                      name="email"
                      value={infoData?.email}
                      maxLength="50"
                      className={`input ${
                        errMsg.emailErr || errMsg.emailValidErr
                          ? 'input-err'
                          : ''
                      }  form-control`}
                      onChange={(e) => inputChangeHandle(e)}
                    />
                    {errMsg.emailErr ? (
                      <span className="err">
                        {translate(
                          'common.required',
                          'This field is required.'
                        )}
                      </span>
                    ) : null}
                    {errMsg.emailValidErr ? (
                      <span className="err">
                        {translate(
                          'common.invalidEmail',
                          'Invalid Email id Format.'
                        )}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="form-group">
                    <label className="mandatory">
                      {translate('contactUs.mobile', 'Mobile Number')}
                    </label>
                    <div className="phone-field">
                      <div className="dropdown country-code-picker"
                       onKeyDown={(e) => {
                        if (e.key === "Tab" && !e.shiftKey)
                        {
                          e.preventDefault()
                          phoneInputRef.current.focus()
                        } 
                        handleKeyDown(e)
                      }}
                      >
                        <PhoneNumberInput
                          value={infoData?.country}
                          onChange={handleMobileNumberChange}
                          // onFocus={(e) => e.target.blur()}
                          numberLength={numberLength}
                          setNumberLength={setNumberLength}
                          code={code}
                          setCode={setCode}
                          focusRef={focusRef}
                        />
                      </div>
                      <div>
                      <input
                        type="tel"
                        placeholder="i.e, 98950 98950"
                        className={`form-control grey input ${
                          errMsg.mobileErr || errMsg.mobileValidErr
                            ? 'input-err'
                            : ''
                        }  `}
                        name="mobile"
                        ref={phoneInputRef}
                        maxLength={numberLength}
                        value={infoData?.mobile}
                        onChange={(e) => inputChangeHandle(e)}
                        onKeyDown={(e)=>{
                          if(e.shiftKey && e.key === "Tab"){
                            e.preventDefault()
                            focusRef.current.focus()
                          }
                        }}
                      />
                      {errMsg.mobileErr ? (
                      <span className="err">
                        {translate(
                          'common.required',
                          'This field is required.'
                        )}
                      </span>
                    ) : null}
                    {errMsg.mobileValidErr ? (
                      <span className="err">
                        {translate(
                          'common.invalidPhone',
                          'Invalid mobile number.'
                        )}
                      </span>
                    ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                {/* doctor and department input field for enquiry and complaint */}
                {queryType !== queryTypeList.feedback ? (
                  <>
                    <div className="col-md-6 mb-4">
                      <div className="form-group">
                        <label>
                          {translate('contactUs.department', 'Department')}
                        </label>
                        <ReactSelectDropDown
                          options={deptOptions}
                          value={selectedDept}
                          label={`${intl.formatMessage({
                            id: 'common.select',
                            defaultMessage: 'select',
                          })}`}
                          onInputChange={handleDeptChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="form-group">
                        <label>{translate('contactUs.doctor', 'Doctor')}</label>
                        <ReactSelectDropDown
                          options={docOptions}
                          value={seletcedDoc}
                          label={`${intl.formatMessage({
                            id: 'common.select',
                            defaultMessage: 'select',
                          })}`}
                          onInputChange={handleDocChange}
                          isLoading={docLoading}
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                {/* input field for enquiry tab */}
                {queryType === queryTypeList.enquiry ? (
                  <div className="col-12 mb-4">
                    <div className="form-group">
                      <label className="mandatory">
                        {translate('contactUs.message', 'Message')}
                      </label>
                      <div className="text-area-wrap">
                      <textarea
                        className={`input ${
                          errMsg.messageErr ? 'input-err' : ''
                        } form-control`}
                        placeholder={intl.formatMessage({
                          id: 'contactUs.enterText',
                          defaultMessage: 'Enter text here.',
                        })}
                        cols="30"
                        rows="4"
                        name="message"
                        value={infoData?.message}
                        maxLength="300"
                        onChange={(e) => inputChangeHandle(e)}
                      ></textarea>
                  </div>
                      {errMsg.messageErr ? (
                        <span className="err">
                          {translate(
                            'common.required',
                            'This field is required.'
                          )}
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {/* input field for complaint tab */}
                {queryType === queryTypeList.complaint ? (
                  <div className="col-12 mb-4">
                    <div className="form-group">
                      <label className="mandatory">
                        {translate('contactUs.complaint', 'Complaint')}
                      </label>
                      <div className="text-area-wrap">
                      <textarea
                        className={`input ${
                          errMsg.messageErr ? 'input-err' : ' '
                        } form-control`}
                        placeholder={intl.formatMessage({
                          id: 'contactUs.enterText',
                          defaultMessage: 'Enter text here.',
                        })}
                        cols="30"
                        rows="4"
                        name="message"
                        maxLength={350}
                        value={infoData?.message}
                        onChange={(e) => inputChangeHandle(e)}
                      ></textarea>
                  </div>
                      {errMsg.messageErr ? (
                        <span className="err">
                          {translate(
                            'common.required',
                            'This field is required.'
                          )}
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {/* input field for feedback */}
                {queryType === queryTypeList.feedback ? (
                  <div className="col-12 mb-4">
                    <div className="form-group">
                      <label className="mandatory">
                        {translate('contactUs.feedback', 'Feedback')}
                      </label>
                      <div className="text-area-wrap">
                      <textarea
                        className={`input ${
                          errMsg.messageErr ? 'input-err' : ''
                        } form-control`}
                        placeholder={intl.formatMessage({
                          id: 'contactUs.enterText',
                          defaultMessage: 'Enter text here.',
                        })}
                        cols="30"
                        rows="4"
                        name="message"
                        value={infoData?.message}
                        maxLength={350}
                        onChange={(e) => inputChangeHandle(e)}
                      ></textarea>
                  </div>
                      {errMsg.messageErr ? (
                        <span className="err">
                          {translate(
                            'common.required',
                            'This field is required.'
                          )}
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                <div className="col-md-6 mb-4">
                  <div className="captcha">
                    <div className="captcha-img">
                      <img src={captchaImg} alt="" draggable="false" />
                    </div>
                    <button
                      id="refreshCaptcha"
                      className="refresh-btn"
                      onClick={handleCaptchaReset}
                    >
                      <i className="bi bi-arrow-repeat"></i>
                    </button>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="form-group">
                    <input
                      placeholder={intl.formatMessage({
                        id: 'contactUs.enterCaptcha',
                        defaultMessage: 'Enter Captcha',
                      })}
                      type="text"
                      className={`input form-control ${
                        errMsg.captchaErr || errMsg.invalidCaptcha
                          ? 'input-err'
                          : ''
                      }`}
                      name="captcha"
                      value={infoData?.captcha}
                      onChange={(e) => inputChangeHandle(e)}
                    />
                    {errMsg.captchaErr ? (
                      <span className="err">
                        {translate(
                          'common.required',
                          'This field is required.'
                        )}
                      </span>
                    ) : null}
                    {errMsg.invalidCaptcha ? (
                      <span className="err">
                        {translate('common.invalidCaptcha', 'Invalid Captcha!')}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-primary w-100 loader-btn"
                    onClick={() => sendMessageHandle()}
                    disabled={isBtnLoading}
                  >
                    {isBtnLoading ? (
                      <ButtonLoader
                        text={translate('contactUs.sendMessage', 'Send Message')}
                      />
                    ) : (
                      translate('contactUs.sendMessage', 'Send Message')
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ps-lg-4">
              <div className="map">
                {mainBranch?.location?
                <iframe
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  src={mainBranch.location}
                ></iframe> :
               <div className='align-items-center h-100 d-none d-lg-flex'>
                 <img className="contact-empty-img" src={ContactEmpty} />
                 </div>}
              </div>
            </div>
          </div>
            {branches.length
              ? 
              <>
                <h5 className="section-title">
                  {branches.length
                    ? translate('contactUs.ourLocation', 'Our Locations')
                    : null}
                </h5>
                <div className="row cards-container">
                {branches.map((x, index) => (
                    <div className="col-md-4 card-wrap" key={index + 1}>
                      <div className="location-card">
                        <h1 className="district">{x.address_highlight}</h1>
                        <h5 className='app-name'>{x.branch_name}</h5>
                        <p>
                          {x.address
                            ? x.address?.split('\n').map((x) => (
                                <>
                                  {`${x}`} <br />
                                </>
                              ))
                            : null}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </>
              : null}
        </div>: <div className="loader-wrap mb-4"><Loader/></div>}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  defaultCountryCode:
    state.SettingsReducer.subscriptionData.setting.defaultCountryCode,
  isBtnLoading: state.ProfileReducer.isBtnLoading
});

const mapDispatchToProps = (dispatch) => ({
  addFeedback: (data, onSuccess) => dispatch(postFeedback(data, onSuccess)),
  getContactInfo: (setBranches) =>
    dispatch(fetchContactInformation(setBranches)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
