import React, { useEffect, useState } from 'react';
import HeaderFields from 'Components/Common/Header';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import useDidMountEffect from 'Hooks/useDidMountEffect';
import { translate } from 'Language/Translate';
import { DoctorsApi } from 'Utils/Api/DoctorsApi';
import { DepartmentsApi } from 'Utils/Api/DepartmentApi';
import OpSheduleManage from './OpSheduleManage';
import Override from './Override';
import { useLocation } from 'react-router-dom';
import { SLOTS_TABS } from 'Constants/constant';
import './slot.css';
import { USER_TYPE } from 'Constants/userTypes';
import { connect } from 'react-redux';
import { DOCTOR } from 'Constants/feature';
import { SCHEDULE_UPDATE } from 'Constants/scopes';
import { compose } from 'redux';
import withPermissionCheck from 'Utils/WithUtils/WithPermission';
import NoResultFound from 'Components/Common/NoResult';


const SlotManagement = ({ userType, doctorDetails,ACCESSOF }) => {
  const [selectedDepart, setSelectedDepart] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [docList, setDocList] = useState([]);
  const [deptLoading, setDeptLoading] = useState(false);
  const [docLoading, setDocLoading] = useState(false);
  const [applyBtn, setApplyBtn] = useState(false);
  const [docSelected, setDocSelected] = useState(false);
  const [selectedTab] = useState('default');
  const [showFilter, setShowFilter] = useState(false)
  const location = useLocation();

  useEffect(() => {
    if (deptList?.length == 1) {
      const oneDept = deptList?.map((item) => ({
        label: item.dept_name,
        value: item.dept_id,
      }));
      setSelectedDepart(oneDept);
    }
  }, [deptList]);

  useEffect(() => {
    if (docList?.length == 1) {
      const oneDoc = {
        label: `Dr. ${docList[0].name} ${docList[0]?.last_name}`,
        value: docList[0].doctor_id,
      };
      setSelectedDoc(oneDoc);
    }
  }, [docList]);

  useEffect(() => {
    getAllDeptList();
    getAllDocList();
  }, []);

  useEffect(() => {
    if (location.state) {
      setSelectedDoc(location.state.doctorId);
      setApplyBtn(true);
      setDocSelected(true)
    }
  }, []);

  useEffect(() => {
    if (userType === 'doctor') {
      const { doctor_id: doctorId } = doctorDetails
      setSelectedDoc({ label: '', value: doctorId });
      setApplyBtn(true);
      setDocSelected(true)
    }
  }, []);

  useDidMountEffect(() => {
    getAllDocList();
  }, [selectedDepart]);

  const handleApplyClick = () => {
    if (!applyBtn){ 
      setDocSelected(true)
      setApplyBtn(true);
    }
  };

  const handleReset = () => {
    setDocSelected(false)
    setSelectedDepart([]);
    setSelectedDoc([]);
  };

  const getAllDeptList = () => {
    setDeptLoading(true);
    const config = {
      limit: 0,
      acl: userType === USER_TYPE.appointmentCordinator,
      sort: "dept_name"
    };
    DepartmentsApi.FetchAllDepartmentList(config).then((res) => {
      setDeptLoading(false);
      if (res.error) {
        return;
      }
      if (res.response) {
        setDeptList(res.response?.departments);
      } else {
        setDeptList([]);
      }
    });
  };

  const getAllDocList = () => {
    setDocLoading(true);
    const config = {
      acl: userType === USER_TYPE.appointmentCordinator,
      limit: 0,
      // status: 'active',
      dept_id: selectedDepart['value'],
      sort: 'name'
    };
    DoctorsApi.getDoctorsList(config).then((res) => {
      setDocLoading(false);
      if (res.error) {
        return;
      }
      if (res.response) {
        setDocList(res.response?.doctors);
      } else {
        setDocList([]);
      }
    });
  };
  return (
    <div className="design-upgrade tab-design add-dept-modal">
     {ACCESSOF(DOCTOR, SCHEDULE_UPDATE) && userType !== USER_TYPE.doctor  && (
        <div className="admin-bread-crumbs mb-lg-2 fs-12">
          <div className="wrapper links">
            <a className="previous" href="#/admin/doctorslisting">
              {translate('doctors.title', 'Doctors')}
            </a>
            <span> {'>'} </span>
            <a className="bread-crumb-cursor">{translate('doctors.slots', 'Slots')}</a>
          </div>
        </div>
      )}
      <div className="mobile-only ms-auto fit-content d-none">
        <div className="button-wrap"><button className="selector filter" onClick={() => {setShowFilter(!showFilter)}}><i className="bi bi-funnel-fill"></i></button></div>
      </div>
      {userType !== 'doctor' && (
        <>
          <HeaderFields
            onApply={handleApplyClick}
            applyBtnDisable={!selectedDoc?.value}
            onReset={handleReset}
            childBreakPt={11}
            btnBreakPt={1}
            applyBtnCls={'col-sm-2'}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
          >
            <div className="col-md-3 col-sm-4 pe-sm-0">
              <div className="form-group">
                <ReactSelectDropDown
                  options={
                    deptList?.map((item) => ({
                      label: item.dept_name,
                      value: item.dept_id,
                    })) || []
                  }
                  id="Department"
                  label={translate('doctors.department', 'Department')}
                  onInputChange={(item) => {
                    setSelectedDepart(item);
                    setSelectedDoc([]);
                  }}
                  addValid_class={true}
                  required={true}
                  value={selectedDepart}
                  isLoading={deptLoading}
                  isCasePlaceholder={true}
                  noOptionsMessage={() => <div>{!deptList?.length ? <p className='no-options-msg'>No departments added </p> : <div className="noOption">
                  {translate('common.noData', 'No data found')}
                </div>}</div>}
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-4 pe-sm-0">
              <div className="form-group">
                <ReactSelectDropDown
                  options={
                    docList?.map((item) => ({
                      label: `Dr. ${item.name} ${item?.last_name}`,
                      value: item.doctor_id,
                    })) || []
                  }
                  id="Doctor"
                  label={translate('doctors.doctorName', 'Doctor Name')}
                  onInputChange={(item) => {
                    setApplyBtn(false);
                    setSelectedDoc(item);
                  }}
                  addValid_class={true}
                  required={true}
                  value={selectedDoc?.value ? selectedDoc : []}
                  isLoading={docLoading}
                  isCasePlaceholder={true}
                />
              </div>
            </div>
          </HeaderFields>
          {/* <hr className="doc-addhr"></hr> */}
        </>
      )}

      {selectedDoc?.value && docSelected ? (
        <div className="settings-page">
          <div className="tab-wrapper tab-wrapper-slot">
            <div className="tab-body tab-body-slot">
              <ul className="nav mb-2 custom-tab" id="pills-tab" role="tablist">
                {SLOTS_TABS.map((item, index) => (
                  <li key={index} className="nav-item" role="presentation">
                    <button
                      className={item.value === selectedTab ? 'active' : ''}
                      // onClick={() => setSelectedTab(item.value)}
                      id="default"
                      data-bs-toggle="pill"
                      data-bs-target={`#${item.value}-tab`}
                      type="button"
                      role="tab"
                      aria-controls={`pills-${item.value}`}
                      aria-selected="true"
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="default-tab"
                  role="tabpanel"
                  aria-labelledby="pills-default"
                >
                  <OpSheduleManage
                    selectedDoc={selectedDoc}
                    applyBtn={applyBtn}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="overrideSlots-tab"
                  role="tabpanel"
                  aria-labelledby="pills-overrideSlots"
                >
                  <div className="mt-3">
                    <Override selectedDoc={selectedDoc} applyBtn={applyBtn} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='patient-portal'>
          <NoResultFound
            text={translate('doctors.noSlotFound', 'No slots found.')}
            label={translate(
              'doctors.noSlotFoundMsg',
              'Please select any Department/Doctor for viewing slots.'
            )}
            removeMarginTop
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userType: state.LoginReducer.user_type,
  doctorDetails: state.LoginReducer.doctorDetails,
});


export default compose(withPermissionCheck(DOCTOR),connect(mapStateToProps))(SlotManagement);