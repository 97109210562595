import { LOCALES } from '../Locales';

const message = {
  [LOCALES.ENGLISH]: {
    common: {
      yes: 'YES',
      no: 'NO',
      logout: 'Are you sure you want to log out?',
      edit: 'EDIT',
      update: 'UPDATE',
      status: 'Status',
      apply: 'APPLY',
      go: 'GO',
      add: 'ADD',
      save: 'SAVE',
      reset: 'RESET',
      cancel: 'CANCEL',
      submit: 'SUBMIT',
      confirm: 'CONFIRM',
      action: 'Action',
      back: 'BACK',
      itemPerPage: 'Items per page',
      active: 'Active',
      inactive: 'Inactive',
      doYouWantToDelete: 'Do you want to delete?',
      doYouWantToDeleteDepartment:
        'Are you sure you want to delete this department?',
      doYouWantToDeleteUser: 'Are you sure you want to delete this user?',
      doYouWantToDeleteLeave: 'Are you sure you want to delete this leave?',
      doYouWantToDeleteDoctor: 'Are you sure you want to delete this doctor?',
      doYouWantToDeleteAppointment:
        'Are you sure you want to delete this appointment?',
      slno: 'Sl No.',
      firstName: 'First Name',
      lastName: 'Last Name',
      phoneNumber: 'Phone Number',
      emailId: 'Email Id',
      strong: 'Strong',
      medium: 'Medium',
      weak: 'Weak',
      reason: 'Reason',
      noData: 'No data found',
      pageNotFound: 'Page Not Found',
      somethingWrong: 'Something Went Wrong',
      unableToLoad:
        'Something went wrong, Unable to load subscription settings',
      noPermission: "You don't have permission to view this page",
      unauthorised:
        "We're unable to find the requested subdomain. Please check the subdomain and try again.",
      tryAgain: 'Unable to process the request. Please try again',
      requiredFields: 'Please fill all the required fields',
      required: 'This field is required',
      reqField:'These fields are required',
      genderRequired: 'Please select gender',
      requiredValid:'Please enter valid amount',
      nameError: 'Please select from the list or Add family member!',
      checkboxReq: 'Please allow consent before proceeding',
      from: 'From',
      to: 'To',
      time: 'Time',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      male: 'Male',
      female: 'Female',
      select: 'Select',
      bloodGroup: 'Blood Group',
      signOut: 'Sign Out',
      other: 'Other',
      date: 'Date',
      name: 'Name',
      delete: 'Delete',
      print: 'Print',
      saveVerify: 'Save & Verify',
      loading: 'Loading...',
      success: 'Success',
      error: 'Error !',
      warning: 'Warning !',
      info: 'Information',
      invalidEmail: 'Invalid Email id Format',
      invalidPhone: 'Invalid mobile number',
      all: 'All',
      invalidCaptcha: 'Invalid Captcha!',
      guest: 'Guest User',
      wentWrong: 'There was an error processing your request. Please try again',
      hi: 'Hi',
      noResultFound: 'No results found.',
      more: 'More',
      of: 'of',
      noOption: 'No options',
      noSessionOpted: 'Select any session first for available time slots',
      paymentInfo: 'Payment Information',
      transactionMethod: 'Transaction Method',
      amount: 'Amount',
      refId: 'Reference ID',
      additionalInfo: 'Additional Info',
      searchpatient: 'Search Patient',
      day: 'Day',
      week: 'Week',
      today: 'Today',
      start: 'Start',
      cancelAppointment: 'Cancel Appointment',
      expiryNotification:
        'Subscription for {appName} is expiring within {date} day(s). If you wish to renew your subscription, simply',
      expiryNotificationToday:
        'Subscription for {appName} is expiring Today. If you wish to renew your subscription, simply',
      clickLink: 'click this link',
      otpLimitMsg: 'OTP send limit exceeded please try again after 5 min',
      itsYou: `It's you`,
      verifyOtp: 'Verify OTP',
      resetData: 'Reset',
      optional: 'Optional',
      crop: 'Crop Image',
      saveChanges: 'Save Changes',
      search: 'Search',
      enterPassword: 'Enter password',
      reject: 'REJECT',
      continue: 'YES, DELETE',
      yesCancel: 'YES, CANCEL',
      searchDoctors:'Search doctors',
      addNew: 'Add New',
      addDepartment: 'Add Department',
      noResult: 'No results found.',
      noResultAppointments: 'No appointments added.',
      noAppointments: 'No Appointments!',
      noAppointmentsAdded:"Looks like you haven't added any appointments yet.",
      noAppointmentsbooked:"Looks like you have no appointments booked yet.",
      noResultMsg: 'No results match the filter criteria, remove filter or clear all filters to show results',
      noDocs: 'Do you want to make an appointment for the next available date? ',
      clicknow: 'Click Now',
      landline: 'Landline Number',
      notAdd:'Not added',
      noActionPermission: 'You do not have permission to perform this action',
    },
    reset: {
      title: 'Reset Password',
      resetTooltip: 'Reset',
    },
    signup: {
      title: 'SignUp',
      selectDomain: 'Select your Telemedicine Domain',
      clientcodeError: 'Please enter valid client code',
      criteria:
        'The client code should only contain letters and numbers, with at most one hyphen that is not at the beginning or end.',
    },
    login: {
      title: 'Client Admin Portal',
      subTitle: 'Login with your credentials',
      username: 'Username',
      email: 'Email',
      password: 'Password',
      rememberMe: 'Remember me',
      forgotPassword: 'Forgot Your Password ?',
      login: 'LOGIN',
      usernameRequired: 'Username is required',
      passwordRequired: 'Password is required',
      emailRequired: 'Email Id is required',
    },
    forgotp: {
      forgot: 'Forgot Your Password?',
      desc: 'Enter the email associated with your account and we will send you a link to reset your password.',
      email: 'Email',
      try: 'Did you remember your password? Try',
      sign: 'Sign in',
      invalid: '*Invalid Email id',
      send: 'SEND',
    },
    appointments: {
      doYouWantToCancel: 'Are you sure you want to cancel the Appointment?',
      title: 'Appointments',
      availability: 'Availability',
      addAppointment: 'Add Appointment',
      editAppointment: 'Edit Appointment',
      deleteAppointment: 'Delete Appointment',
      startAppt: 'Start Appointment',
      notifyAndSave: 'NOTIFY PATIENT AND SAVE',
      selectDate: 'Select Date',
      slNo: 'Sl No',
      id: 'ID',
      patientName: 'Patient Name',
      doctorName: 'Doctor Name',
      date: 'Date',
      timeSlot: 'Time Slot',
      type: 'Type',
      department: 'Department',
      appointmentDate: 'Appointment Date',
      session: 'Session',
      email: 'Email',
      mobile: 'Mobile',
      reason: 'Reason',
      appointmentSuccess: 'Your appointment has been booked successfully',
      bookAnother: 'Book Another Appointment',
      appointmentId: 'Appointment Id',
      bookedSlot: 'Booked Slot',
      makeAppointment: 'Make An Appointment',
      searchBy: 'Department / Doctor',
      deptDoct: 'Department/Doctor',
      searchDoctDept: 'Search Doctor/Dept.',
      selectAppointmentDate: 'Select Date of Appointment',
      fees: 'Fee',
      viewProfile: 'View Profile',
      bookSlot: 'Book Slots',
      notAvailable: 'Not Available',
      availableSlots: 'Available Slots',
      timing: 'Timing',
      reserveSlot: 'Reserve Slot',
      patientInfo: 'Add Patient Information',
      existingPatient: 'Existing Patient',
      newPatient: 'New Patient',
      selectMobileNumber: 'Select Mobile Number',
      enterMobileNumber: 'Enter Mobile Number',
      validate: 'Validate',
      enterOTP: 'Enter OTP',
      didntReceive: "Didn't receive OTP ?",
      resend: 'Resend OTP',
      patientMRN: 'Patient MRN',
      MRN: 'MRN',
      nextStep: 'ADD PATIENT INFORMATION',
      next: 'NEXT',
      enterPatientName: 'Please enter Patient Name',
      enterValidEmail: 'Please enter valid emailId',
      selectExistingUser: 'Please select an existing number from the list',
      enterMobile: 'Please enter the mobile number',
      enterValidMobile: 'Please enter a valid mobile number',
      enterOTPToProceed: 'Enter the OTP to proceed',
      validateMobile: 'Please validate the mobile number',
      inProgress: 'In Progress',
      all: 'All',
      upcoming: 'Upcoming',
      resheduled: 'Rescheduled',
      completed: 'Completed',
      previous: 'Previous',
      notAttempted: 'Not Attempted',
      cancelled: 'Cancelled',
      waiting: 'Waiting',
      doctDept: 'Doctor / Department',
      sec: 'sec',
      cancelAppointment: 'Cancel Appointment',
      enterReason: 'Please provide the reason',
      rescDetails: 'Rescheduled details',
      totalResc: 'Total Reschedules',
      searchMobile: 'Search by mobile number',
      dob: 'Date of birth',
      gender: 'Gender',
      noAvailableSlots: 'No slots available',
      mobileNumberNoFound: 'Mobile number not found',
      continueGuest: 'Continue as Guest',
      goNext: 'Go to next week',
      goPrevious: 'Go to previous week',
      goNextDay: 'Go to next day',
      goPreviousDay: 'Go to previous day',
      noSlots: 'No Slots Available',
      leaveDateConflict: 'Leave Date(s) Conflicted',
      dateSelected: 'Date Selected',
      rescheduleTo: 'Reschedule to',
      slotDetails: 'Slot details',
      bookedFor: 'Booked for',
      bookedWith: 'Booked with',
      leaveDates: 'Leave Date(s)',
      rescheduleAppts: 'Reschedule Appointments',
      cancelAppts: 'Cancel Appointment(s)',
      notify: 'Notify',
      previousAppointment: 'Previous Appointment In Progress',
      areYouWantTo:
        'You currently have an appointment in progress. Are you sure you want to start a new appointment?',
      completePrevious:
        'The previous appointment will be automatically marked as completed.',
      start: 'Start Appointment',
      cash: 'Cash',
      upi: 'UPI',
      card: 'Card',
      netbanking: 'Netbanking',
      viewAppt: 'View Appointment',
      tableView: 'Table View',
      calenderView: 'Calendar View',
      optime: 'OP Time',
      view: 'View',
      notifyMsg:
        'Are you sure you want to notify the selected patients to reschedule their appointments due to the doctors leave?',
      notifyPatients: 'Notify Patients',
      sendNotification: 'Send Notification',
      cancelMsg: 'Are you sure you want to cancel the selected appointment(s)?',
      paymentpaid: 'Paid',
      notifyReschedule: 'Notify Patient & Reschedule',
      manageSlots: 'Manage Slots',
      patientType: 'Select Patient Type',
      apptId: 'Appointment ID',
      inactiveusererror:'This user is inactive, please activate the user and proceed with the appointment booking',
      doctors: 'Doctor(s)',
      departments: 'Department(s)',
      deptSelected: 'Department Selected',
      docSelected: 'Doctor Selected',
      apntDateConflicted: 'Appointment Date(s) Conflicted',
      currentAppointments: 'Current Appointments',
      noDataAppointments:'No appointments added',
      noDataAppointmentsMsg: `Looks like you haven't added any appointments yet.`,
      booked: 'Booked',
      yourSelection: 'Your Selection',
      available: 'Available',
    },
    patientView: {
      searchPatient: 'Search For Patient',
      reason: 'Reason For Appointment',
      upcoming: 'Upcoming',
      previous: 'Previous',
      total: 'Total Appointments',
      showMore: 'Show More',
      prescriptionNote: 'Prescription Note',
      save: 'Save and complete the Appointment',
      view: 'View',
      download: 'Download',
      latest: 'Latest Documents',
      medicine: 'Medicine',
      medicineName: 'Medicine Name',
      dosage: 'Dosage',
      duration: 'Duration',
      addMore: 'Add more',
      advices: 'Advice',
      note: 'Note',
      patientId: 'Patient Id',
      phone: 'Phone',
      date: 'Date',
      apptDetails: 'Appointment Details',
      apptDate: 'Appointment Date',
      timeSlot: 'Time Slot',
      department: 'Department',
      vitals: 'Vitals',
      weight: 'Weight',
      height: 'Height',
      heartBeat: 'Heart Beat',
      bp: 'BP',
      glucose: 'Glucose Rate',
      noAppt: 'No Appointments',
      apptReason: 'Reason',
      prescriptions: 'Prescriptions',
      noData: 'No Data',
      referal:
        'Use # to choose a department when referring, and then @ to name a physician there.',
      followUp: 'Schedule Follow-up Appointment',
      required:
        'Please complete the prescription note by filling out any of the field',
      medRequired: 'Please add a medicine name to all of the added prescriptions.'
    },
    cancelAppt: {
      cancelappt: 'Cancel Appointment',
      areYouSure: 'Are you sure, you want to cancel this appointment?',
      pleaseEnter:
        'If so, please enter a reason for cancelling your appointment.',
      reason: 'Reason for Cancellation',
      yesCancelAppt: 'Yes, Cancel my Appointment',
      noCancelAppt: 'No, Back to listing',
      cancelSuccessfully: 'Appointment cancelled successfully!',
      cancelSuccessfullyMsg:
        'You have successfully cancelled your appointment.',
      backToAppt: 'Back to listing',
      apptwithbookid:'Appointment with booking id',
      bookforDr:'booked for Dr.',
      cancelon:'on',
      cancelat:'at',
      cancelsuccessfully:'has been cancelled successfully.',
    },
    doctors: {
      title: 'Doctors',
      slots: 'Slots',
      selectDepartment: 'Select Department',
      viewProfile: 'View Profile',
      myProfile: 'My Profile',
      doctorListing: 'Doctors List',
      doctorDetails: 'Doctor Details',
      addDoctor: 'Add Doctor',
      editDoctor: 'Edit Doctor',
      doctorName: 'Doctor Name',
      department: 'Department',
      appointmenttype: 'Appointment Type',
      apptType: 'Appt. Type',
      designation: 'Designation',
      emailaddress: 'Email Address',
      about: 'About',
      qualification: 'Qualification',
      opconsultation: 'OP Consultation',
      consultingfees: 'Consulting Fee',
      personalinformation: 'Personal Information',
      gender: 'Gender',
      qualificationdetails: 'Qualification Details',
      slotduration: 'Slot duration',
      minutes: 'Minutes',
      mins: 'Mins',
      minutedescription: 'Or enter a custom duration',
      rupees: 'INR',
      op: 'OP',
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
      sunday: 'Sun',
      doc: 'Dr',
      experience: 'Experience (Year)',
      removeOverride: 'Remove override schedule',
      addOverride: 'Add override schedule',
      override: 'Override Schedule',
      close: 'Close',
      expand: 'Expand',
      slotError: 'Please enter slot details',
      profile: 'Doctor Profile',
      qualify: 'Qualifications',
      connect: 'Let’s Connect',
      editProfile: 'EDIT PROFILE',
      feeHistory: 'Fee History',
      slotManagement: 'Slot Management',
      bufferTime: 'Buffer time',
      bufferInfo:
        'The time interval between consecutive appointment slots allowing for breaks or unforeseen delays.',
      maxApptinfo:
        'The maximum number of appointments that can be scheduled in a day.',
      maxAppoinments: 'Max. number of appointments',
      default: 'Default',
      overrideSlots: 'Override Slots',
      noSlotFound: 'No slots found.',
      noSlotFoundMsg: 'Please select any Department/Doctor for viewing slots.',
      startDate: 'Start date',
      endDate: 'End date',
      overrideDetails: 'Override Details',
      viewDoc: 'View Doctor',
      editDoc: 'Edit Doctor',
      deleteDoc: 'Delete Doctor',
      slotManage: 'Slot Management',
      errMsgTimeRange:
        'Invalid time range, start time should be before end time.',
      errMsgDuratoinTimeRange:
        'Schedule time cannot be less than slot duration.',
      errMsgdays: 'Please select at least one day',
      scheduleSettings: 'Schedule Settings',
      errMsgDate: 'Start date must be before end date.',
      doYouWantToDeleteOverride: 'Do you want to delete this override shedule?',
      entPersonalInformation: 'Enter personal information',
      entAcademicInformation: 'Enter academic information',
      fees: 'Fee',
      noQual: 'No qualifications added',
      manage:'Manage doctor’s slot duration, Buffer time, Number of appointments etc.',
      noInfo: 'No personal information added.',
      collapse: 'Collapse',
      customDuration: 'Custom duration',
      noDataDoctors:'No doctors added',
      noDataDoctorsMsg: `Looks like you haven't added any doctors yet.`,
      noDepartaddedDoctorsMsg:"Looks like you haven't added any department to the doctor,",
      noActiveDoctorMessage:"Doctor added is inactive, Please activate the doctor",
      userInactiveMessage: 'User status of this doctor is inactive, Please activate the doctor',
      addLandline: 'Add Landline',
      remove: 'Remove'
    },
    invoice: {
      invoices: 'Invoices',
      filterName: 'Filter by Name',
      duration: 'Duration',
      search: 'Search',
      reset: 'Reset',
      download: 'Download',
    },
    contactUs: {
      contactus: 'Contact Us',
      firstName: 'First Name',
      lastName: 'Last Name',
      emailAddress: 'Email Address',
      mobile: 'Mobile Number',
      message: 'Message',
      sendMessage: 'Send Message',
      ourLocation: 'Our Locations',
      enterText: 'Enter text here',
      connectUs: 'Let’s Connect with Us',
      chooseType: 'Choose your Query type',
      enquiry: 'Enquiry',
      complaint: 'Complaint',
      feedback: 'Feedback',
      department: 'Department',
      doctor: 'Doctor',
      enterCaptcha: 'Enter Captcha',
    },
    leaveApproval: {
      title: 'Leave Listing',
      leaveAppr: 'Leave Approval',
      leaveReject: 'Reject Leave Request',
      cancelLeaveReq: 'Cancel Leave Request',
      title1: 'My Leaves',
      title2: 'Leaves',
      editLeave: 'Edit Leave',
      addLeave: 'Add New Leave',
      comments: 'Comments',
      addComments: 'Add comments',
      date: 'Date',
      slNo: 'Sl No',
      doctorId: 'Doctor ID',
      doctorName: 'Doctor Name',
      leaveDates: 'Leave Date(s)',
      leaveDate: 'Leave Date',
      leaveType: 'Leave Type',
      deleteLeave: 'Delete Leave',
      approveLeave: 'Approve Leave',
      rejectLeave: 'Reject Leave',
      addComment: 'Add Comment',
      cancelLeave: 'Cancel Leave',
      commentRequired: 'Please add a comment',
      invalidTime: 'Please provide valid leave time',
      invalidDate: 'Please provide valid leave dates',
      doctor: 'Doctor',
      areYouWantTo: 'Are you sure you want to',
      thisLeave: 'these leave(s)?',
      accept: 'Accept',
      cancel: 'Cancel',
      reject: 'Reject',
      firstHalf: 'First Half',
      secondHalf: 'Second Half',
      fullDay: 'Full Day',
      custom: 'Custom',
      pending: 'Pending',
      approved: 'Approved',
      rejected: 'Rejected',
      cancelled: 'Cancelled',
      enterComments: 'Enter Comments',
      leaveConfirmation: 'Leave Confirmation',
      apptConflict: 'Appointment Conflict',
      apptConflictMsg:
        'There are confirmed appointment(s) already scheduled on these date(s). Do you want to reschedule/cancel all?',
      apptConflictMsgDoc: 'There are confirmed appointment(s) already scheduled on these date(s). Do you want to continue apply for leave?',
      noDataLeaves:'No leaves added',
      noDataLeavesMsg: `Looks like you haven't added any leave(s) yet.`
    },
    patients: {
      title: 'Patients',
      dob: 'Date of Birth',
      regDate:"Reg.Date",
      age: 'Age',
      emailAddress:"Email Address",
      lastApptDate:"Last Appt. date",
      editPatientInfo:'Edit Patient Information',
      noDataPatients:'No patients added',
      noDataPatientsMsg: `Looks like you haven't added any patients yet.`,
      linkedNumber:`If the Linked patient's mobile number is not provided then the main account holder's mobile number will be considered.`
    },
    departments: {
      title: 'Departments',
      department: 'Department',
      addDepartment: 'Add New Department',
      viewDepartment: 'View Department',
      editDepartment: 'Edit Department',
      deptNameId: 'Department Name',
      id: 'ID',
      deptName: 'Department Name',
      deptDescription: 'Department Description',
      deptHead: '	Department Head',
      noDoctors: 'No doctors are currently added in this department.',
      doctors: 'Doctors',
      doctorList: 'Doctor List',
      selected: 'Selected Doctor(s)',
      deleteDepartment: 'Delete Department',
      noDoctorsfound: 'No doctors found',
      deptHeadRemove: 'is the head of this department, Are you sure you want to proceed?',
      noHead: 'No doctor was selected as the department head.',
      noDataDepts:'No departments added',
      noDataDeptsMsg: `Looks like you haven't added any departments yet.`
    },
    users: {
      title: 'Users',
      userName: 'User Name',
      roleSelect: 'Select User Role',
      roletype: 'Role Type',
      adduser: 'Add User',
      selectdoc: 'Select Doctor(s)',
      selectdept: 'Select Department',
      admin: 'Admin',
      doctor: 'Doctor',
      Appocord: 'Appointment Coordinator',
      nameEmail: 'Name / Email',
      role: 'User Role',
      name: 'Name',
      phone: 'Phone Number',
      editUser: 'Edit User',
      editPatient: 'Edit Patient',
      deleteUser: 'Delete User',
      invalidMail: 'Invalid email id',
      invalidLocationLink: 'Invalid Location Link',
      invalidPhone: 'Invalid phone number',
      addDept: 'Add Department',
      removeDept: 'Remove Department',
      userRole: 'You must choose the department and doctors if you choose the appointment coordinator option',
      noDataUsers:'No users added',
      noDataPatientsMsg: `Looks like you haven't added any users yet.`
    },
    settings: {
      title: 'Settings',
      profileSettings: 'Profile Settings',
      changeProfile: 'Change profile picture (PNG, JPG, JPEG)',
      addProfile:'Add profile picture (PNG, JPG, JPEG)',
      cropProfile: 'Change profile picture',
      changePassword: 'Change Password',
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      capitalLetter: 'Start with a capital letter.',
      smallLetter:"At least one small letter.",
      eightChar: 'At least 8 to 20 characters long.',
      specialChar: 'At least one special character :!@#$',
      oneNumber: 'At least one number (0-9)',
      confirmPassword: 'Confirm Password',
      generalSettings: 'General Settings',
      setLanguage: 'Select preferred language',
      validMobile: 'Please enter a valid mobile number.',
      validEmail: 'Please enter a valid email id.',
      passwordMatch: 'New password and confirm password does not match.',
      criteriaMatch: 'Password does not match the criteria.',
      verified: 'Verified',
      verify: 'Verify',
      verifyMail: 'Please verify the email',
      verifyPhone: 'Please verify the phone number',
      systemSettings: 'System Settings',
      subscriptionSettings: 'Manage Subscription',
      enterPhoneOtp:
        'Please enter the One Time Password to verify your phone {phone}',
      enterEmailOtp:
        'Please enter the One Time Password to verify your Email {email}',
      maxSize: '(Maximum file upload size : 5MB)',
      maxSizeValue: '(Maximum file upload size : {value}MB)',
      verifySize:
        'File size exceeds the 5MB limit. Please select a smaller file.',
      verifySizeValue:
        'File size exceeds the {value}MB limit. Please select a smaller file.',
      wrongfile: 'Unsupported file format',
      changeYourProfile: 'Change your profile picture',
      uploadPic: 'Upload picture',
      removePic: 'Remove picture',
      passwordMatchErr: 'Password and confirm password does not match.',
      editProfile: 'Edit Profile',
      apptSettings: 'Appointment Settings',
      emailSettings: 'Email Settings',
      contactSettings: 'Contact Settings',
      reminderSettings: 'Appointment Reminder',
      setTheme: 'Select patient portal theme',
      setCountry: 'Select default country code',
      setOtp: 'Enable OTP verification',
      changeLogo: 'Change  system logo (PNG, JPG, JPEG)',
      maxReschedule:
        'Maximum number of times that an appointment can be rescheduled',
      maxAdvance:
        'Maximum time in advance that an appointment can be booked (in days)',
      minBefore:
        'Minimum time before appointment start that it can be booked (in hrs)',
      paymentType: 'Payment type',
      replayMail: 'User reply email address',
      feedbackMail: 'Feedback email address',
      enquiryMail: 'Enquiry email address',
      complaintMail: 'Complaint email address',
      branchName: 'Branch name',
      place: 'Place',
      address: 'Address',
      locLink: 'Location link',
      primaryPhone: 'Primary phone',
      secPhone: 'Secondary phone',
      email: 'Email',
      otherLocation: 'Other Location',
      add: 'Add',
      primaryLocation: 'Primary Location',
      location: 'Location',
      systemGeneratedPatientId: 'System Generated Patient ID',
      mrnOrHealthId: 'MRN/HealthID',
      healthCareIdSetting: 'Healthcare ID settings',
      subscriptionOpened:
        'Subscription settings is opened in a new tab (click here to retry if needed)',
      retry: 'Retry',
      brandname: 'Set brand name',
      organizationname: 'Set organization name',
      supportMail: 'Support email address',
      changeFavicon: 'Change favicon (PNG, JPG, JPEG)',
      greenTheme: 'Green theme',
      blueTheme: 'Blue theme',
      duplicateEntry: 'Duplicated entry',
      remindBefore: 'Remind before',
      reminderType: 'Reminder type',
      ReminderlimitExceeded: 'Reminder limit exceeded as per your plan',
      all: 'All',
      sms:'SMS',
      minutes: 'Minutes',
      hours: 'Hours',
      days: 'Days',
      weeks: 'Weeks',
      favicondesc: 'A Favicon is a small, 16x16 pixel icon used on web browsers to represent a website or a web page.',
      verifyEmail: 'Please verify email',
      allowSubsequentSlots: 'Allow filling subsequent slots',
      allow: 'Allow'
    },
    patientPortal: {
      login: 'Sign In',
      signup: 'Sign Up',
      allRightsReserved: 'All Rights Reserved',
      poweredBy: 'Powered by',
      search: 'Search',
      caption1: 'Empowering Health',
      caption2: 'Seamless Online Doctor Booking at Your Fingertips',
      subCaption: 'The easiest and fastest place to book a doctor online',
      signin: 'Sign In',
      guest: 'Continue as Guest',
      otpVerification: 'OTP Verification',
      otpExpire: 'OTP will expire in',
      mobileNo: 'Mobile Number',
      myMobileNo : 'My Mobile Number',
      request: 'Request OTP',
      resend: 'Resend OTP',
      alreadyHave: 'Already have an account',
      or: 'OR',
      saveTime: 'Save time in the hospital, Sign Up now.',
      loginWithOTP: 'Login with OTP instead of password',
      loginWithPsw: 'Login with password instead of OTP',
      forgotPassword: 'Forgot Password',
      password: 'Password',
      emailOrmobile: 'Email / Mobile Number',
      name: 'Name',
      email: 'Email Id',
      mrnHealthId: 'MRN / Health ID',
      confirmPassword: 'Confirm Password',
      terms: 'Terms of Use',
      agreed: 'I have read and agreed to the',
      enterPassword: 'Enter your password',
      reEnterPassword: 'Re-enter password',
      requestPswReset: 'Request Password Reset',
      docOrSpec: 'Doctor Name / Speciality',
      docSpec: 'Doctor / Speciality',
      selectDate: 'Select Date',
      speciality: 'speciality',
      doctor: 'Doctor',
      doctors: 'Doctors',
      bookAnAppointment: 'Book an Appointment',
      whenQuestion: 'When?',
      whichSpecialty:'Which Specilality?',
      relevance: 'Relevance',
      experience: 'Experience',
      bookAppointment: 'Book Appointment',
      LookingMore: 'Looking for more',
      bookingsuccess: 'Booking Successful',
      bookingconfimred: 'Your booking at {span} has been confirmed!',
      appointmentDetails: 'Appointment details are included below',
      bookingId: 'Booking ID',
      ConsultationFee: 'Consultation Fee',
      BookAnotherAppointment: 'Book another Appointment',
      availability: 'Availability',
      viewProfile: 'View Profile',
      pickTimeSlot: 'Pick a time slot',
      // unavailableSlot: 'Unavailable Slot',
      morning: 'Morning',
      afternoon: 'Afternoon',
      evening: 'Evening',
      night: 'Night',
      doctorQualification: 'Doctor Qualification',
      confirm: 'Confirm',
      proceed: 'Proceed',
      inClinicAppointment: 'This in-clinic appointment is',
      patientName: 'Patient’s Name',
      patientMoblNo: 'Patient’s Phone Number',
      payAtClinic: 'Pay at Clinic',
      payOnline: 'Pay Online',
      both: 'Both',
      patientEmail: 'Patient’s Email Id',
      prescriptions: 'Prescriptions',
      filterByName: 'Filter by Name',
      durationQ: 'Duration?',
      reset: 'Reset',
      sortBy: 'Sort by',
      searchResult: 'Search Results',
      home: 'Home',
      profiles: 'Profiles',
      linkProfile: 'Link a Profile',
      patient_id: 'Patient ID',
      dob: 'Date of Birth',
      viewRecords: 'View Records',
      viewAppointments: 'View Appointments',
      familyMem: 'Family Members',
      linkedProfiles: 'Linked Profiles',
      quote: 'Connect with your loved ones to stay updated on their health',
      id: 'ID',
      gender: 'Gender',
      relation: 'Relation',
      delink: 'De-link',
      verifyOTP: 'Verify OTP',
      updatedOn: 'Updated on',
      age: 'Age',
      ageinYears: 'Age (Years)',
      consentAgree: 'I have consent to book on behalf of this patient.',
      enterPsw: 'Please enter the One Time Password to verify your account.',
      enterProfilePsw:
        'Please enter the One Time Password to verify your profile.',
      otpSent: ' An OTP has been sent to',
      edit: 'Edit',
      otpVerified: 'OTP verified successfully!',
      inCorrectOtp: 'Incorrect OTP entered',
      invalidOtp: 'Please enter OTP',
      verify: 'Verify',
      continue: 'Continue',
      forMySelf: 'for Myself',
      forSomeOne: 'for Someone else',
      dLinkProfile: 'De-link Profile',
      areYouSure: 'Are you sure you want to de-link this profile ?',
      areYouSureNoPhone:
        'This patient profile will be permanently deleted from the system as the phone number is not updated',
      yesDeLink: 'Yes, Delink Profile',
      noKeep: 'No, Keep Profile',
      profileLinked: 'Profile linked successfully!',
      succesfullyLinked: 'You have successfully linked your family member.',
      backToListing: 'Back to listing',
      mailSentSuccess: 'Mail Sent Successfully!',
      clickLink:
        'We’ve sent an email to the address provided. Click the link in the email to reset your password.',
      didntReceiveMail: 'Didn’t receive an email ?',
      resendMail: 'Resend Email',
      noSlotAvailable: 'No slots are available on the selected date.',
      noDoctorAvailable: 'No slots are available for the selected doctor.',
      noDeptAvailable: 'No doctors available.',
      noDataBookSlot:
        'Need an appointment fast? Search doctors, departments, and book your visit in minutes.',
      found: 'found',
      warning:
        'Please fill these information in your profile : Name, Gender and Date of Birth before going to book any appointments',
      addMember: '+ Add New Member',
      add: '+ Add',
      addNewMember: 'Add New Member',
      reschedulesuccess: 'Reschedule Successful',
      linkaProfile: 'Link a Profile',
      updateContinue: 'Update & Continue',
      noMember: 'No members found',
      addDetails: 'Please enter details.',
      selectDocOrSpeciality:'Please select a doctor or speciality',
      addDate: 'Please select a date.',
      update:'Update'
    },
    medicalRecords: {
      home: 'Home',
      medicalRecords: 'Medical Records',
      addMedicalRecord: 'Add Medical Record',
      filterByName: 'Filter by Name',
      duration: 'Duration',
      search: 'Search',
      reset: 'Reset',
      slNo: 'Sl.No',
      fileName: 'File Name',
      uploadDate: 'Upload Date',
      remarks: 'Remarks',
      action: 'Action',
      itemPerPage: 'Items per page :',
      patientName: 'Patient Name',
      uploadFile: 'Upload File ',
      uploadDescription:
        'You can upload the document as PDF, Jpeg, PNG formats',
      selectFile: 'Select the file',
      youWantToUpload: 'you want to upload',
      maxFileUploadSize: 'Max. file upload size : 10 MB.',
      txt: 'TXT',
      pdf: 'PDF',
      doc: 'DOC',
      png: 'PNG',
      remark: 'Remark',
      description: 'Description',
      addDocument: 'Add Document',
      remarkPlaceholder: 'i.e, Regular rhythm, No Femoral bruit, No history',
      MedicalRecordDetails: 'Medical Record Details',
      MemberInfo: 'Member Info',
      id: 'ID',
      fileUploaded: 'File(s) Uploaded',
      choosePerson: 'Choose the Person',
      uploadFileSize: 'File size should be less than 10 mb',
      totalFileSize: 'Overall Files size should be less than 10 MB',
      maxFileCount: 'Max Number of File allowed is 5',
      deleteMedicalRecord: 'Delete Medical Record',
      deleteConfirm: 'Are you sure you want to delete this medical record ?',
      continue: 'Yes, Delete',
      stop: 'No, Keep Record',
      allowedFiles: 'Allowed files:',
      unsupportedFiles: 'Unsupported file format',
      filter: 'Filter by Patient Name',
    },
    userType: {
      owner: 'Subscription Owner',
      admin: 'Admin',
      doctor: 'Doctor',
      patient: 'Patient',
      appointmentCordinator: 'Appointment Coordinator',
    },
    myAppointments: {
      home: 'Home',
      myAppointments: 'My Appointments',
      bookAnAppointment: 'Book an Appointment',
      searchName: 'Search by Name',
      docSpec: 'Doctor / Speciality',
      duration: 'Duration',
      aptStatus: 'Appointment Status',
      filterByName: 'Filter by Patient Name',
      reset: 'Reset',
      confirmed: 'Confirmed',
      noShow: 'No Show',
      notAttempted: 'Not Attempted',
      completed: 'Completed',
      cancelled: 'Cancelled',
      upcomingVisit: 'Upcoming Visit',
      previousVisit: 'Previous Visit',
      cancelledAppointment: 'Cancelled Appoinment',
      aptConfirmed: 'Your Appointment is confirmed',
      aptCancelled: 'Cancelled',
      bookFor: 'Booked for',
      reason: 'Reason for Appointment',
      reasonForCancel: 'Reason for cancellation',
      showMore: 'Show more',
      cancel: 'Cancel Appointment',
      reschedule: 'Reschedule Appointment',
      medRecords: 'Medical Records',
      prescription: 'Prescription',
      show: 'Show',
      more: 'more >',
      less: 'less',
      confirmation: 'Are you sure you want to reschedule current appointment ?',
      continue: 'Yes, Proceed',
      stop: 'No, Keep Appointment',
      patientInfo: 'Patient Information',
    },
    patientNav: {
      appointments: 'Appointments',
      profiles: 'Profiles',
      prescriptions: 'Prescriptions',
      medRecords: 'Medical Records',
      invoices: 'Invoices',
      contactUs: 'Contact Us',
      profile: 'Profile',
      profileSettings: 'Profile Settings',
      changePassword: 'Change Password',
      settings: 'Settings',
      logout: 'Logout',
      guest: 'Guest',
    },
    profileSetting: {
      home: 'Home',
      accountInformation: 'Profile Information',
      manageInformation: 'Manage your personal and contact information',
      manageInfo: 'Manage your personal and contact information. Go to',
      profile: 'Profile',
      cancel: 'Cancel',
      update: 'Update',
      setPsw: 'Set Password',
      currentPsw: 'Current Password',
      newPsw: 'New Password',
      confirmPsw: 'Confirm Password',
      accountInfo: 'Account Info',
      changePassword: 'Change Password',
      profileInfo: 'Profile Information',
      observations: 'Observations',
      notAdded: 'Not Added',
      entercurrentPsw: 'Enter Current Password',
      enternewPsw: 'Enter New Password',
      enterconfirmPsw: 'Enter Confirm Password',
    },
    linkProfile: {
      search: 'Search by Email ID / Mobile Number / Patient ID',
      searchmrn: 'Search by Email ID / Mobile Number / MRN (Health ID)',
      otpSent:
        'OTP has been successfully sent to the mobile number registered on your account.',
      match: 'Matched profile found',
      email: 'Email ID',
      mobile: 'Mobile Number',
      patient: 'Patient ID',
      noResult: 'No results found.',
      profileUpdated: 'Profile updated successfully!',
      edit: 'Edit Profile',
      delink: 'De-link',
      verify: 'Verify OTP',
      profileInfo: 'Profile Information',
      observations: 'Observations',
      patientInformation: 'Profile Information',
      managePatientInfo: 'Manage your personal and contact information',
    },
    prescriptionView: {
      appointmentDate: 'Appointment Date',
      appointmentDateTime: 'Appt. Date & Time',
      apntmnttDate: 'Appt. Date',
      prescriptionDetail: 'Prescription Details',
      notes: 'Notes',
      medicines: 'Medicines',
      drugName: 'Drug Name',
      dosage: 'Dosage',
      duration: 'Duration',
      slNo: 'Sl.No',
      advices: 'Advice',
      noNotes: 'No notes added',
      noAdvices: 'No advices added',
    },
    calender: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      mon: 'Mon',
      tue: 'Tue',
      wed: 'Wed',
      thu: 'Thu',
      fri: 'Fri',
      sat: 'Sat',
      sun: 'Sun',
      sunFull: 'Sunday',
      monFull: 'Monday',
      tueFull: 'Tuesday',
      wedFull: 'Wednesday',
      thuFull: 'Thursday',
      friFull: 'Friday',
      satFull: 'Saturday',
    },
    relation: {
      daughter: 'Daughter',
      son: 'Son',
      mother: 'Mother',
      father: 'Father',
      husband: 'Husband',
      wife: 'Wife',
      brother: 'Brother',
      sister: 'Sister',
      grandmother: 'Grandmother',
      grandfather: 'Grandfather',
      stepmother: 'Stepmother',
      stepfather: 'Stepfather',
      aunt: 'Aunt',
      uncle: 'Uncle',
      niece: 'Niece',
      nephew: 'Nephew',
      cousin: 'Cousin',
      stepson: 'Stepson',
      stepdaughter: 'Stepdaughter',
      motherinlaw: 'Mother-in-law',
      fatherinlaw: 'Father-in-law',
      brotherinlaw: 'Brother-in-law',
      sisterinlaw: 'Sister-in-law',
      grandson: 'Grandson',
      granddaughter: 'Granddaughter',
      greatgrandfather: 'Great grandfather',
      greatgrandmother: 'Great grandmother',
      other: 'Other',
      friend: 'Friend',
    },
    subscription: {
      expired: 'The link you’ve followed has expired',
      portal: 'The appointment booking portal is currently not accessible. Please contact the respective hospital/clinic for booking appointments',
      // booking:
      //   'is currently not accessible, please contact the respective hospital/clinic for booking appointments',
      support: 'Support',
    },
    subscription_admin: {
      expiry: 'Your subscription has expired',
      description:
        'To keep using the application without interruption please review your subscription information and take necessary action',
      renew: 'RENEW SUBSCRIPTION',
      descriptionNew:
        'To keep using the application without interruption please contact your administrator to renew the subscription',
    },
  },
};

export default message;
