import Network from './Network';

const getAllApntInfo = async (data = {}) => {
  let response = null;
  let error = null;
  await Network.get({
    url: '/appointment/date-info',
    data,
  }).then((res) => {
    const { isError, result, message } = res;
    if (isError) {
      error = message;
    } else {
      response = result;
    }
  });
  return { error, response };
};

const getAvailableApptDates = async (data = {}) => {
  let response = null;
  let error = null;
  await Network.get({
    url: "/doctors/slots/date-info",
    data,
  }).then((res) => {
    const { isError, result, message } = res;
    if (isError) {
      error = message;
    } else {
      response = result;
    }
  });
  return { error, response };
};

export const AppointmentApi = {
  getAllApntInfo,
  getAvailableApptDates
};
