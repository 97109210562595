import axios from 'axios';
import { getLocalStorageItem } from '../LocalStorage/local-storage';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_LOCAL;

// FIXME: clientCodes

export const clientCode = 'carebridge';

// get the base url base on the window URL
const getBaseURL = () => {
  const origin = window.location.origin;
  if (origin.includes('localhost')) {
    // return origin.replace('3000', '8080');
    return API_BASE_URL.replace('//', `//${clientCode}.`);
  } else {
    return origin;
  }
};

// get sass base url
export const getSassBaseURL = () => {
  let origin = window.location.origin;
  if (origin.includes('localhost')) {
    origin = process.env.REACT_APP_SAAS_BASE_URL.replace('*', clientCode);
  }
  return origin;
};

const apiInstance = () => {
  let instance = axios.create({
    baseURL: getBaseURL(),
    headers: {
      'Content-Type': 'application/json',
      Authorization: getLocalStorageItem('access_token') || '',
    },
  });
  return instance;
};

export { apiInstance };
