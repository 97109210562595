import React, { useEffect, useState } from 'react';
import { translate } from '../../Language/Translate';

const PasswordStrengthChecker = ({ password, setStrongPassword }) => {
  const [strength, setStrength] = useState('');
  
  const calculateScore = (text) => {
    let score = 0;

    // Check if password starts with a capital letter
    if (/^[A-Z]/.test(text)) {
      score += 1;
    }

    // Check if password has a minimum of 8 letters
    if (text.length >= 8) {
      score += 1;
    }

    // Check if password has at least one special character
    if (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(text)) {
      score += 1;
    }

    // Check if password has at least one number
    if (/\d/.test(text)) {
      score += 1;
    }

    // Check if password has at least one small letter
    if (/[a-z]/.test(text)) {
      score += 1;
    }

    return score;
  };

  useEffect(() => {
    const score = calculateScore(password);
    setStrength(score);
    if (score === 5) setStrongPassword(true);
    else setStrongPassword(false);
  }, [password, setStrongPassword]);

  const renderProgressBar = () => {
    const colors = {
      1: '#ef4836',
      2: '#f6b44d',
      3: '#2b90ef',
      4: '#2b90ef',
      5: '#25c281',
    };

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          marginTop: 3,
        }}
      >
        {[1, 2, 3, 5].map((item,index) => (
          <div
            key={index}
            style={{
              width: '24%',
              backgroundColor: item > strength ? '#ced4da' : colors[strength],
              height: 2,
            }}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      {strength ? (
        <div style={{ textAlign: 'right', fontSize: 13 }}>
          {renderProgressBar()}
          {strength === 5
            ? translate('common.strong', 'Strong')
            : strength === 4 || strength === 3
            ? translate('common.medium', 'Medium')
            : translate('common.weak', 'Weak')}
        </div>
      ) : null}
    </>
  );
};

export default PasswordStrengthChecker;
