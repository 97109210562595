import React from 'react';
import { CardMedia } from '@mui/material';
import Modal from '@mui/material/Modal';
import { FILE_TYPES } from 'Constants/constant';

const PdfViewer = ({ pdfUrl, open, onClose, fileType = '' }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <>
          {fileType === FILE_TYPES.image ? (
            <>
            <img src={pdfUrl} className="pdf-img"/>
              {/* <CardMedia className='pdf-wrapper'
                title="PDF Viewer"
                image={pdfUrl}
                style={{ height: '90vh', width: '75%', backgroundSize:"auto" }}
              /> */}
            </>
          ) : (
            <CardMedia
              component="iframe"
              title="PDF Viewer"
              src={pdfUrl}
              style={{ height: '90vh', width: '75%' }}
            />
         
          )}
        <button className='btn close-btn fixed-close' onClick={()=> onClose()}>✕</button>
        </>
      </Modal>
    </>
  );
};

export default PdfViewer;
