import Network from './Network';

const getDoctorsList = async (config) => {
  let response = null;
  let error = null;
  await Network.get({
    url: '/doctors',
    data: config,
  }).then((res) => {
    const { isError, result, message } = res;
    if (isError) {
      error = message;
    } else {
      response = result;
    }
  });
  return { error, response };
};

const AddDoctorsList = async (config) => {
  let response = null;
  let error = null;
  let displayMessage = '';
  await Network.post({
    url: '/doctor',
    data: config,
  }).then((res) => {
    const { isError, result, message } = res;
    if (isError) {
      error = message;
    } else {
      response = result;
      displayMessage = message;
    }
  });

  return { error, response, displayMessage };
};

const EditDoctorsList = async (config, id) => {
  let response = null;
  let error = null;
  let displayMessage = "";
  let errCode = ''
  await Network.put({
    url: '/doctor/' + id,
    data: config,
  }).then((res) => {
    const { isError, result, message, code } = res;
    if (isError) {
      error = message;
      errCode = code
      response = result;
    } else {
      response = result;
      displayMessage = message;
    }
  });
  return { error, response, message: displayMessage, code: errCode };
};

const DeleteDoctorsList = async (id) => {
  let response = null;
  let error = null;
  let displayMessage = '';
  await Network.delete({
    url: '/doctor/' + id,
  }).then((res) => {
    const { isError, message } = res;
    if (isError) {
      error = message;
    } else {
      displayMessage = message;
    }
  });
  return { error, response, message: displayMessage };
};

const getDoctorDetails = async (id, data = {}) => {
  let response = null;
  let error = null;
  await Network.get({
    url: '/doctor/' + id,
    data,
  }).then((res) => {
    const { isError, result, message } = res;
    if (isError) {
      error = message;
    } else {
      response = result;
    }
  });
  return { error, response };
};

const changeStatus = async (id, config) => {
  let response = null;
  let error = null;
  let displayMessage = "";
  let errCode = ''
  await Network.patch({
    url: '/doctor/' + id + '/status',
    data: config,
  }).then((res) => {
    const { isError, result, message, code } = res;
    if (isError) {
      error = message;
      errCode = code
      response = result;
    } else {
      response = result;
      displayMessage = message;
    }
  });
  return { error, response, message: displayMessage, code: errCode };
};

export const DoctorsApi = {
  getDoctorsList,
  AddDoctorsList,
  EditDoctorsList,
  DeleteDoctorsList,
  getDoctorDetails,
  changeStatus,
};
