/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useSelector,
} from 'react-redux';
import './style/header.css';
import ProfileData from './ProfileData';
import Navbar from './Navbar';
import { mainLogoUrl } from 'Utils/Branding/logoGenerator';
import { checkAppPermission } from 'Utils/Permissions/Permissions';

const Header = () => {
  const [showNav, setShowNav] = useState(false);

  const loggedIn = useSelector((state) => state.LoginReducer.loggedIn);
  const subscriptionData = useSelector(
    (state) => state.SettingsReducer.subscriptionData
  );

  // handle nav bar open click
  const handleNavBarOpen = () => {
    setShowNav(!showNav);
  };

  return (
    <div>
      <header className="header patient-header">
        <nav className="navbar navbar-expand-xl">
          <div className="container-fluid px-0 wrapper">
            <Link to="/webConnect" id="home">
              <img
                className="logo"
                src={mainLogoUrl(subscriptionData)}
                alt="Logo"
              />
            </Link>
            {checkAppPermission(subscriptionData) ? (
              <button className="navbar-toggler" onClick={handleNavBarOpen}>
                <span className="navbar-toggle-icon"></span>
              </button>
            ) : null}
            <Navbar
              showNav={showNav}
              setShowNav={setShowNav}
              loggedIn={loggedIn}
            />
            <div className="overlay"></div>
            {loggedIn && <ProfileData />}
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
