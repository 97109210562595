import React from 'react';
import './BookAppointment.css';
import bookingv1 from '../../../Assets/img/icons/theme-1/booking-successful.svg';
import bookingv2 from '../../../Assets/img/icons/theme-2/booking-successful.svg';
import { useIntl } from 'react-intl';
import { translate } from '../../../Language/Translate';
import { appendTime } from '../../../Utils/DateFormatter/Date';
import { useSelector } from 'react-redux';
import { removeDrPrefix } from 'Utils/StringFormatter/String';

function ConfirmBooking({
  bookAnotherAppnmnt,
  appId,
  selectedSlot,
  doctorData,
  newdate,
  rescchedule,
  patientInfo = {},
  showMyInfo,
  someOneInfo,
  deptValue
}) {
  const theme = useSelector((state) => state?.SettingsReducer?.theme);
  const brandName = useSelector(
    (state) => state?.SettingsReducer?.subscriptionData?.branding?.brandName
  );
  const dateObj = new Date(newdate);
  const appt_Date = dateObj.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
  let [month, day, year] = appt_Date.split(' ');
  const formattedDate = `${day?.replace(',', '')} ${month} ${year}`;
  const intl = useIntl();
  return (
    <div className="modal-body px-3 pb-0"
    onKeyDown={(e)=>{
      if(e.key === "Enter" && !e.shiftKey){
        bookAnotherAppnmnt()
      }
    }}
    >
      {
        <img
          className="main-img"
          src={
               theme === 'theme-2'
              ? bookingv2
              : bookingv1
          }
          alt=""
        />
      }
      <h1 className="main-title">
        {' '}
        {rescchedule
          ? intl.formatMessage({
              id: 'patientPortal.reschedulesuccess',
              defaultMessage: 'Reschedule Successful',
            })
          : intl.formatMessage({
              id: 'patientPortal.bookingsuccess',
              defaultMessage: 'Booking Successful',
            })}
      </h1>
      <p className="confirmation-msg mx-auto col-sm-10">
        {translate(
          'patientPortal.bookingconfimred',
          'Your booking at {span} has been confirmed!',
          { span: <span className="fw-700">{brandName}</span> }
        )}
        <br />
        {intl.formatMessage({
          id: 'patientPortal.appointmentDetails',
          defaultMessage: 'Appointment details are included below',
        })}
      </p>
      <div className="booking-info">
        <div className="doctor">
          <label>{translate('appointments.patientName', 'Patient Name')}</label>
          {showMyInfo ? (
            <h6>{`${patientInfo.myName} ${patientInfo.lastName}`}</h6>
          ) : (
            <h6>{`${someOneInfo?.patientName} ${
              someOneInfo?.patientLastName ? someOneInfo.patientLastName : ''
            }`}</h6>
          )}
        </div>
        <div className="doctor">
          <label>
            {intl.formatMessage({
              id: 'patientPortal.doctor',
              defaultMessage: 'Doctor',
            })}
          </label>
          <h6 className='text-capitalize'>
            Dr. {removeDrPrefix(doctorData.name+' '+doctorData.last_name)}
          </h6>
          <p className="text-capitalize">
            {deptValue?.label}
            {/* {doctorData?.departments?.map((val) => val.dept_name).join(', ')} */}
          </p>
        </div>
        <div className="date-time">
          <label>
            {intl.formatMessage({
              id: 'common.date',
              defaultMessage: 'Date',
            })}{' '}
            &{' '}
            {intl.formatMessage({
              id: 'common.time',
              defaultMessage: 'Time',
            })}
          </label>
          <h6>
            {formattedDate}, {appendTime(selectedSlot.start_time)} -{' '}
            {appendTime(selectedSlot.end_time)}{' '}
          </h6>
        </div>
        <div className="id-fee row">
          <div className="col-md-6">
            <label>
              {intl.formatMessage({
                id: 'patientPortal.bookingId',
                defaultMessage: 'Booking ID',
              })}{' '}
            </label>
            <h6>{appId}</h6>
          </div>
          <div className="col-md-6">
            <label>
              {intl.formatMessage({
                id: 'patientPortal.ConsultationFee',
                defaultMessage: 'Consultation Fee',
              })}
            </label>
            <h6>{doctorData.op_fees ? `₹${doctorData.op_fees}` : '-'}</h6>
          </div>
        </div>
      </div>
      {!rescchedule && (
        <a
          id="bookAnother"
          type="button"
          onClick={bookAnotherAppnmnt}
          className="d-block mx-auto color-primary mb-2"
          tabIndex="0"
        >
          {intl.formatMessage({
            id: 'patientPortal.BookAnotherAppointment',
            defaultMessage: 'Book another Appointment   ',
          })}
        </a>
      )}
    </div>
  );
}

export default ConfirmBooking;
