import React, { useEffect, useRef, useState } from 'react';
import 'Assets/style/own-view-day.css';
import 'Assets/style/own-view-week.css';
// import exportIcon from 'Assets/img/icons/import-export.svg';
import DayRoster from './DayRoster';
import WeekRoster from './WeekRoster';
import Calendar from './Calendar';
import AppointmentsTableView from './Appointments';
import network from 'Utils/Api/Network';
import { dayMonthAndYear, timeZoneDate } from 'Utils/DateFormatter/Date';
import { translate } from 'Language/Translate';
import ViewSwitch from 'Components/Common/ViewSwitch';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import { APPOINTMENT_STATUS_OPTIONS_ALL } from 'Constants/constant';
import { updateDoctorSearchData } from 'Actions/appointmentAction';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { DoctorsApi } from 'Utils/Api/DoctorsApi';
import { Tooltip } from '@mui/material';

let apiTimeout = null;

let allValue = { label: translate('common.all', 'All'), value: undefined };

const options = [
  { label: translate('common.week', 'Week'), value: 'week' },
  { label: translate('common.day', 'Day'), value: 'day' },
  { label: translate('common.today', 'Today'), value: 'today' },
];

const AppointmentsRoster = (props) => {
  const { applySearchData, searchData, language_Code, doctorDetails } = props;
  const [view, setView] = useState(searchData.view ? searchData.view : 'week');
  const [dateChange, setDateChange] = useState(timeZoneDate());
  const [activeDateChange, setActiveDateChange] = useState(timeZoneDate());
  const [date, setDate] = useState(timeZoneDate());
  const [activeDate, setActiveDate] = useState(timeZoneDate());
  const [patientName, setPatientName] = useState('');
  const [isPatientSelect, setPatientIsSelect] = useState(false);
  const [patientSuggestion, setPatientSuggestion] = useState([]);
  const [applyCounter, setApplyCounter] = useState(0);
  const [sort, setSort] = useState({});
  const [searchPatientLoading, setSearchPatientLoading] = useState({});
  const [viewType, setViewType] = useState(
    searchData.view === 'day' ? options[1] : options[0]
  );
  const [viewRoster, setViewRoster] = useState(
    searchData.viewType === 'list' ? false : true
  );
  // btn disable
  // const [disablebtn, setDisableBtn] = useState(true);

  const [docDetails, setDocDetail] = useState({});
  const [depts, setDepts] = useState([]);
  const [showFilter, setShowFilter] = useState(false)


  // use intl
  const intl = useIntl();

  const divRef = useRef(null);

  // handle outside close of filter
  const handleOutsideClose = () => {
    setSort({
      statusChange: searchData.selectedStatus || '',
      status: searchData.selectedStatus || '',
      department: '',
    });
    setDate(searchData.date || timeZoneDate());
    setActiveDate(searchData.date || timeZoneDate());
    setDateChange(searchData.date || timeZoneDate());
    setActiveDateChange(searchData.date || timeZoneDate());
    setPatientName(searchData.search || '');
    setPatientIsSelect(true)
  }

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)&&showFilter) {
      setShowFilter(false);
      handleOutsideClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFilter]);

  const viewDoctorDetails = (id) => {
    DoctorsApi.getDoctorDetails(id).then((res) => {
      if (res.error) {
        return;
      } else {
        setDocDetail(res.response.doctor);
        let deptList = [];
        if (res.response.departments) {
          deptList = res.response.departments.map((dept) => ({
            label: dept.dept_name,
            value: dept.dept_name,
          }));
        }
        deptList.unshift(allValue);
        setDepts(deptList);
      }
    });
  };

  useEffect(() => {
    const { doctor_id } = doctorDetails;
    viewDoctorDetails(doctor_id);
  }, []);

  // preset the previous filter
  useEffect(() => {
    setSort({
      statusChange: searchData.selectedStatus || '',
      status: searchData.selectedStatus || '',
      department: '',
    });
    setDate(searchData.date || timeZoneDate());
    setActiveDate(searchData.date || timeZoneDate());
    setDateChange(searchData.date || timeZoneDate());
    setActiveDateChange(searchData.date || timeZoneDate());
    setPatientName(searchData.search || '');
    if (viewRoster) {
      setApplyCounter(applyCounter + 1)
    } else {
      setApplyCounter(0)
    }
  }, [viewRoster]);

  // api c   with 3sec delay patient
  useEffect(() => {
    if (!isPatientSelect) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadOptionsPatient(patientName);
      }, 300);
      apiTimeout = timeout;
    }
  }, [patientName, isPatientSelect]);

  // handle view dropdown change
  const handleViewChange = (value) => {
    setViewType(value);
    if (value.value === 'today') {
      //activeDate state is used to change the view from another month to selected month view
      setActiveDate(timeZoneDate());
      setDate(timeZoneDate());
      setActiveDateChange(timeZoneDate());
      setDateChange(timeZoneDate());
      setApplyCounter(applyCounter + 1)
    } else {
      setView(value.value);
      applySearchData({ ...searchData, view: value.value });
      setSort((prev) => ({
        ...prev,
        statusChange: '',
      }));
    }
  };

  // handle status dropdown change
  const handleStatusChange = (value) => {
    setSort((prev) => ({
      ...prev,
      statusChange: value,
    }));
    // setDisableBtn(false);
  };

  // handle week change
  const handleWeekChange = (direction) => {
    // setViewType(options[1]);
    if (direction === 'previous') {
      const lastWeekDate = view === 'week' ? new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000) : new Date(date.getTime() - 24 * 60 * 60 * 1000);
      setDate(lastWeekDate);
      setActiveDate(lastWeekDate);
      setDateChange(lastWeekDate);
      setActiveDateChange(lastWeekDate);
      applySearchData({ ...searchData, date: lastWeekDate });
    } else {
      const nextWeekDate = view === 'week' ? new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000) : new Date(date.getTime() + 24 * 60 * 60 * 1000) ;
      setActiveDate(nextWeekDate);
      setDate(nextWeekDate);
      setActiveDateChange(nextWeekDate);
      setDateChange(nextWeekDate);
      applySearchData({ ...searchData, date: nextWeekDate });
    }
    setApplyCounter(applyCounter + 1);
  };

  // handle date change
  const onDateChange = (e) => {
    setViewType(options.find((x) => x.value === view));
    // setDisableBtn(false)
    setDateChange(e);
    applySearchData({ ...searchData, date: e });
    const element = document.getElementById('rosterWeekViewCalender');
    const element1 = document.getElementById('rosterWeekViewCalenderToggle');
    element?.classList?.remove('show');
    element1?.classList?.remove('show');
  };

  // handle search value change patient
  const onChangePatientSearch = (event) => {
    const { value } = event.target;
    setPatientIsSelect(false);
    setPatientName(value);
    // setDisableBtn(false);
  };

  // load options to the dropdown on typing patient
  const loadOptionsPatient = async (inputValue) => {
    let fetchedOptions = [];
    if (inputValue?.trim()) {
      let id = new Date().getTime()
      setSearchPatientLoading((prev)=>({
        ...prev,
        [id]:true
      }));
      await network
        .get({ url: `/patients/search/patient-name/${inputValue}` })
        .then((response) => {
          setSearchPatientLoading(false);
          const { isError, result } = response;
          if (isError) {
            return;
          } else {
            let options = result?.patients.map((item) => ({
              label: item.name,
              value: item.name,
              // type: item.type,
            }));
            if (!options || !options?.length) {
              options = [{
                  value: '',
                  label: `${intl.formatMessage({
                    id: 'common.noData',
                    defaultMessage: 'No data found.',
                  })}`,
                  isDisabled: true,
              }]
            }
            fetchedOptions = options;
          }
        })
        .catch(() => {});
    }
    setPatientSuggestion(fetchedOptions);
  };

  // handle search name selection fron list patient
  const handleSearchPatientSelect = (value) => {
    setPatientName(value);
    setPatientSuggestion([]);
    setPatientIsSelect(true);
  };

  // handle apply
  const handleApplyClick = () => {
    setApplyCounter(applyCounter + 1);
    setShowFilter(false);
    setDate(dateChange);
    setActiveDate(activeDateChange)
    setSearchPatientLoading([])
    // setDisableBtn(true);
    setSort((prev) => ({
      ...prev,
      status: sort?.statusChange,
    }));
    applySearchData({
      ...searchData,
      search: patientName,
      selectedStatus: sort?.statusChange || '',
    });
  };

  // handle reset
  const handleReset = () => {
    setPatientName('');
    // setDisableBtn(true);
    setShowFilter(false);
    setPatientSuggestion([]);
    setSearchPatientLoading([])
    setPatientIsSelect(false);
    setApplyCounter(applyCounter + 1);
    setSort({});
    // setDate(timeZoneDate());
    // setDateChange(timeZoneDate());
    // setActiveDate(timeZoneDate());
    // setActiveDateChange(timeZoneDate());
    //here
    // applySearchData({ ...searchData,date:timeZoneDate(), selectedStatus: '', search: '' });
  };

  // handle viewMode change
  const handleViewModeChange = (mode) => {
    applySearchData({ ...searchData, viewType: mode });
    if (mode === 'list') {
      setViewRoster(false);
    } else {
      setViewRoster(true);
    }
  };

  
  // handle dept change
  const handleDeptChange = (item) => {
    setSort((prev) => ({
      ...prev,
      department: item,
    }));
    // setDisableBtn(false)
  };

  return (
    <>
      {viewRoster ? (
        <section className="d-flex w-100">
          <div
            className={`doctor-roster content ${
              view === 'day' ? 'own-view-day' : 'own-view-week'
            } w-100`}
          >
            <div className="own-view-week">
            <div className={`view-filter`}>
              <div className="d-flex gap-2 flex-wrap doc-search">
              <div className='d-flex align-items-center gap-2 justify-content-between fg-1'>
                  <div className="d-flex gap-2 week-nav-filter">
                    <div className="navigation me-1">
                      <Tooltip
                        title={view === 'week' ? translate(
                          'appointments.goPrevious',
                          'Go to previous week'
                        ) :
                        translate(
                          'appointments.goPreviousDay',
                          'Go to previous day'
                        )}
                      >
                        <button
                          className="previous"
                          onClick={() => handleWeekChange('previous')}
                        >
                          <i className="bi bi-chevron-left"></i>
                        </button>
                      </Tooltip>
                      <Tooltip
                        title={ view === 'week' ? translate(
                          'appointments.goNext',
                          'Go to next week'
                        ):
                        translate(
                          'appointments.goNextDay',
                          'Go to next day'
                        )}
                      >
                        <button
                          className="next"
                          onClick={() => handleWeekChange('next')}
                        >
                          <i className="bi bi-chevron-right"></i>
                        </button>
                      </Tooltip>
                    </div>
                    </div>

                    {/* filter popup section */}
                    <div className="filter-section" ref={divRef}>
                    <button className='filter-btn applied' onClick={()=> {setShowFilter(!showFilter); if(showFilter) handleOutsideClose()}}>
                    <i className="bi bi-funnel"></i>
                    </button>
                    <div className={` ${showFilter ? 'open-filter listings-fiter-container' : 'listings-fiter-container'}`}>
                    <div className="filter-body">
                      <div className="row">
                      <div className="col-md-6 pe-md-0">
                        <div className="form-group mb-0">
                          <label htmlFor="">Date</label>
                          <div
                        name=""
                        id="rosterWeekViewCalenderToggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className="form-select date-dr"
                        data-bs-auto-close="outside"
                      >
                        {dayMonthAndYear(dateChange, language_Code)}
                      </div>
                      <div
                        className="calander-area dropdown-menu"
                        id="rosterWeekViewCalender"
                      >
                        <Calendar
                          language={language_Code}
                          value={dateChange}
                          onDateChange={onDateChange}
                          activeDate={activeDateChange}
                          setActiveDate={setActiveDateChange}
                        />
                      </div>
                      </div>
                      </div>
                  
                      <div className="col-md-6">
                       <div className="form-group mb-0">
                        <label htmlFor="">Patient</label>
                        <div
                          className={`search-input ${
                            Object.values(searchPatientLoading).includes(true) && !isPatientSelect ? 'loading' : ''
                          }`}
                        >
                          <input
                            type="text"
                            placeholder={intl.formatMessage({
                              id: 'common.searchpatient',
                              defaultMessage: 'Search Patient',
                            })}
                            value={patientName}
                            onChange={onChangePatientSearch}
                            onBlur={() =>
                              setTimeout(() => setPatientSuggestion([]), 400)
                            }
                            onFocus={() => {
                              if (patientName) loadOptionsPatient(patientName);
                            }}
                          />
                          <i className="bi bi-search" />
                          {patientSuggestion.length && patientName
                          && !Object.values(searchPatientLoading).includes(true)
                          && !isPatientSelect
                          ? (
                            <div
                              className="customSearchList"
                            >
                              <ul style={{ marginBottom: 1 }}>
                                {patientSuggestion.map((item, index) => (
                                  <li
                                    key={index}
                                    className={`suggestion-item ${item.isDisabled ? 'disabled' : ''}`}
                                    onClick={() =>
                                      !item.isDisabled && handleSearchPatientSelect(item.value)
                                    }
                                  >
                                    {item.label}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      </div>

                      <div className="col-md-6 pe-md-0 mt-md-3">
                       <div className="form-group mb-0">
                        <label htmlFor="">{translate('common.status', 'Status')}</label>
                        <ReactSelectDropDown
                          options={APPOINTMENT_STATUS_OPTIONS_ALL}
                          className="form-select"
                          onInputChange={handleStatusChange}
                          value={sort?.statusChange || sort?.status}
                          label={translate('common.status', 'Status')}
                          isClearable={true}
                        />
                      </div>
                      </div>

                      <div className="col-md-6 mt-md-3">
                       <div className="form-group mb-0">
                        <label htmlFor="">Department</label>
                          <ReactSelectDropDown
                            options={depts}
                            value={sort?.department || ''}
                            onInputChange={handleDeptChange}
                            className="form-select"
                            label={translate('appointments.department', 'Department')}
                            isClearable
                          />
                        </div>
                      </div>
                    </div>
                 </div>
                 <div className="filter-footer back-to-btn">
                  <button
                        id="reset"
                        className="btn btn-outlined"
                        onClick={handleReset}
                  >
                      {translate('common.reset', 'RESET')}
                  </button>
                  <button
                    id="apply"
                    className="btn btn-secondary"
                    onClick={handleApplyClick}
                    // disabled={disablebtn}
                  >
                    {translate('common.apply', 'Apply')}
                  </button>
                  </div>
                </div>
                </div>
                </div>
              <div className="right-filter-section">
                <div className='day-dr'>
                  <ReactSelectDropDown
                    options={options}
                    onInputChange={handleViewChange}
                    className="form-select"
                    value={viewType}
                  />
                </div>
                {/* )} */}
                {/* Excel export is not taken in this phase */}
                {/* <div className="dropdown icon-dr">
                <button
                  className="form-select"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={exportIcon} className="pe-1 mb-1" alt="" />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Export as Excel
                    </a>
                  </li>
                </ul>
              </div> */}
                <div >
                  <ViewSwitch
                    active="menu"
                    handleViewModeChange={handleViewModeChange}
                  />
                </div>
              </div>
            </div>
            </div>
            <div className="overlay"></div>
            </div>
            {view == 'day' ? (
              <DayRoster
                date={date}
                setDate={setDate}
                activeDate={activeDate}
                setActiveDate={setActiveDate}
                patientName={patientName}
                applyCounter={applyCounter}
                sort={sort}
                setSort={setSort}
                applySearchData={applySearchData}
                searchData={searchData}
                language_Code={language_Code}
                depts={depts}
                patient={patientName}
                stat={sort}
              />
            ) : (
              <WeekRoster
                date={date}
                patientName={patientName}
                applyCounter={applyCounter}
                status={sort?.status || ''}
                selecedDept={sort?.department || ''}
                sort={sort}
                setSort={setSort}
                applySearchData={applySearchData}
                searchData={searchData}
                language_Code={language_Code}
                depts={depts}
                docDetails={docDetails}
              />
            )}
          </div>
        </section>
      ) : (
        <AppointmentsTableView handleViewModeChange={handleViewModeChange} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  searchData: state.AppointmentReducer.doctorSearchData,
  language_Code: state.SettingsReducer.locale,
  doctorDetails: state.LoginReducer.doctorDetails,
});

const mapDispatchToProps = (dispatch) => ({
  applySearchData: (data) => dispatch(updateDoctorSearchData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsRoster);
