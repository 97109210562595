import React from 'react';
import Error404 from '../../Assets/img/icons/404-img.svg';
import '../../Assets/style/NoData.css';
import { translate } from '../../Language/Translate';

const NotFound = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        zIndex: 99,
        backgroundColor: '#ffffff',
        position: 'fixed',
        top: 0,
        left: 0,
      }}
    >
      <div className="page-not-found">
        <img src={Error404} alt="404" />
        <h2>{translate('common.pageNotFound', 'Page Not Found')}!</h2>
        <p>Sorry, the page you are looking for doesn’t exist or has been moved. </p>
      </div>
    </div>
  );
};

export default NotFound;
