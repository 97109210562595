import React, { useEffect, useRef, useState } from 'react';
// import AddIcon from '../../Assets/img/icons/add-btn-icon.svg';
// import Calendar from './Calendar';
import {
  // appendTime,
  formatDate,
  formatDateto_D_WD_M_Y,
  getIsToday,
  getTimeDurationInMinutes,
  setHourAndMinute,
} from 'Utils/DateFormatter/Date';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  CancelAppointment,
  DeleteAppointment,
  FetchAppointmentList,
  FetchDepartmentData,
  FetchDoctorSlots,
  UpdateAppointment,
  clearDoctorSlots,
  updateCurrentPage,
  paymentHandle,
} from 'Actions/appointmentAction';
import { removeDrPrefix, truncateString } from 'Utils/StringFormatter/String';
// import profilePic from 'Assets/img/bg/profilePic.svg';
import profilePic from 'Assets/img/icons/doctor-placeholder-avatar.svg';
import CustomTimeline from './CustomTimeline';
// import Avatar from '@mui/material/Avatar';
import PopupWrapeer from 'Components/Common/PopupWrapeer';
import { translate } from 'Language/Translate';
import EditAppointments from './EditAppointment';
import { clearDepartmentData } from 'Actions/departmentAction';
import ConformPopup from 'Components/Common/ConformPopup';
import CancelPopup from './CancelPopup';
import PaymentInfoPopUp from './PaymentInfoPopUp';
import withPermissionCheck from 'Utils/WithUtils/WithPermission';
import { APPOINTMENT } from 'Constants/feature';
import { CREATE, DELETE, STATUS_UPDATE, UPDATE } from 'Constants/scopes';
import Loader from 'Components/Common/Loader';
import { useIntl } from 'react-intl';
import { CardMedia, Tooltip } from '@mui/material';
import NoResultFound from 'Components/Common/NoResult';
import { useHistory } from 'react-router-dom';


const AddIcon = <svg width="44px" height="44px" viewBox="0 0 44 44" version="1.1">
    <title>Group 13</title>
    <g id="Client-Portal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Doctors-Appointments---Own-View-2" transform="translate(-1263, -790)">
            <g id="Group-13" transform="translate(1263, 790)">
                <circle id="Oval" fill="#5875FF" cx="22" cy="22" r="22"></circle>
                <g id="Group-12" transform="translate(14, 14.5)" stroke="#FFFFFF" strokeLinecap="square" strokeWidth="2">
                    <line x1="15.6520462" y1="7.8260231" x2="0" y2="7.8260231" id="Line-8-Copy"></line>
                    <line x1="7.8260231" y1="15.6520462" x2="7.8260231" y2="3.28626015e-14" id="Line-8-Copy-2"></line>
                </g>
            </g>
        </g>
    </g>
</svg>


const DayRoster = ({
  getAppointments,
  date,
  // setDate,
  // activeDate,
  // setActiveDate,
  searchDoc,
  selectedDoc,
  setSelecteDoc,
  onDeptChangeSearch,
  setDeptDocSuggestionList,
  deptDocSuggestionList,
  handleDeptChange,
  patientName,
  applyCounter,
  status: apptStatus,
  appointmentList,
  handleAddButton,
  editAppointment,
  getDoctorSlots,
  getDepartmentData,
  clearDeptData,
  selectedDepartmentData,
  clearSlots,
  doctorSlotData,
  deleteAppointment,
  currentPage,
  SetCurrentPage,
  onCancelAppointment,
  searchData,
  applySearchData,
  docDetails,
  opTime,
  language_Code,
  ACCESS: permission,
  paymentHandle,
  isBtnLoading,
  isLoader,
  allDocList,
  handleDoctorChange,
  CustomOption,
  searchDocDeptLoading,
  isSelect,
  patient,
  stat
}) => {
  const {
    editAppt: editApptLoading = false,
    delAppt: delApptLoading = false,
    cancelAppt: cancelApptLoading = false,
    paymentAppt: paymentApptLoading = false,
  } = isBtnLoading;
  const [appointments, setAppointments] = useState([]);
  const [events, setEvents] = useState([]);
  // const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  //Edit details
  const [showPopup, setShowPopup] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  const [error, setError] = useState('');
  const [feildError, setFeildError] = useState({
    deptError: false,
    doctorError: false,
    dateError: false,
    slotError: false,
    scheduleError: false,
    reasonError: false,
  });
  // Delete deatils
  const [showConformPopup, setShowConformPopup] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const [apptisLoader, updateApptLoader] = useState(false);
  const [
    ,
    // isLoading
    updateLoading,
  ] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [cancelId, setCancelId] = useState('');
  const [openPaymentInfo, setOpenPaymentInfo] = useState(false);
  const intl = useIntl();
  const [adjustTimelineSize,setAdjustTimelineSize]=useState(false)
  useEffect(()=>{
    if(events.length){
      let durationInMinutes= events.map((interval)=>{
         return getTimeDurationInMinutes(interval?.appt_start_time,interval?.appt_end_time)
       })
       let condition =durationInMinutes.some((val)=>Number(val)<=10)
          setAdjustTimelineSize(condition)
      }
    }
    ,[events])

  // to store appointment id number
  const [paymentApptId, setPaymentApptid] = useState();
  // const onDateChange = (e) => {
  //   setDate(e);
  //   const element = document.getElementById('rosterDayViewCalender');
  //   const element1 = document.getElementById('rosterDayViewCalenderToggle');
  //   element?.classList?.remove('show');
  //   element1?.classList?.remove('show');
  // };

  useEffect(() => {
    if (applyCounter) {
      applySearchData({
        ...searchData,
        date: date,
        startDate: formatDate(date),
        endDate: formatDate(date),
      });
    }
  }, [date, applyCounter]);

  // const isAllinActive=(arr)=>{
  //   return arr.every((item)=>item.doctor_status==="inactive")
  // }

  const fetchAppointments = ({
    search: searchWord,
    department,
    appStatus,
    start_Date,
    end_Date,
    pageNo,
  } = {}) => {
    if (!apptisLoader) updateApptLoader(true);
    const config = {
      patient_search:
        searchWord || searchWord === '' ? searchWord || undefined : patientName,
        doctor_id:
        department || department === ''
          ? department || undefined
          : selectedDoc.value,
      start_date:
        start_Date || start_Date === ''
          ? start_Date || undefined
          : formatDate(date),
      end_date:
        end_Date || end_Date === '' ? end_Date || undefined : formatDate(date),
      status:
        appStatus || appStatus === '' ? appStatus || undefined : undefined,
      page: pageNo ? pageNo : 1,
      limit: 0,
      acl: true,
      sort: 'appt_start_time',
    };
    getAppointments(config, updateApptLoader);
  };

  // handle cancel Conform pop open
  const onCancelClick = (item) => {
    setCancelPopup(true);
    setCancelId(item.appointment_id);
  };
  // handle cancel payment click
  const handlepaymentInfo = (id) => {
    setOpenPaymentInfo(true);
    setPaymentApptid(id);
  };
  const handleClose = () => {
    setOpenPaymentInfo(false);
    setPaymentApptid();
  };

  //handle cancel click
  const handleCancel = () => {
    const config = {
      data: {
        appt_status: 'canceled',
      },
    };
    onCancelAppointment(
      cancelId,
      config,
      fetchAppointments,
      onHandleCancelClose
    );
  };

  // handle cancel conform pop close
  const onHandleCancelClose = () => {
    setCancelPopup(false);
    setCancelId('');
  };
  //handle delete click
  const onDeleteClick = (item) => {
    setShowConformPopup(true);
    setDeleteId(item.appointment_id);
  };

  // handle delete success
  const handleDeleteSuccess = () => {
    if (appointments.length === 1 && currentPage > 1) {
      SetCurrentPage(currentPage - 1);
      fetchAppointments({ pageNo: currentPage - 1 });
    } else {
      fetchAppointments();
    }
  };
  // handle on delete confirm
  const deleteConfirm = () => {
    updateLoading(true);
    deleteAppointment(deleteId, handleDeleteSuccess, setShowConformPopup);
  };
  //on editclick
  const onEditClick = (item) => {
    setShowPopup(true);
    setDataToEdit(item);
  };

  // handle edit close
  const handleEditClose = () => {
    setShowPopup(false);
    setDataToEdit({});
    setError('');
    setFeildError({
      deptError: false,
      doctorError: false,
      dateError: false,
      slotError: false,
      scheduleError: false,
      reasonError: false,
    });
  };

  const handleEditAppointment = () => {
    if (
      !dataToEdit.doctor_id ||
      !dataToEdit.dept_name ||
      !dataToEdit.appt_start_time ||
      !dataToEdit.appt_end_time ||
      !dataToEdit.appt_date ||
      !dataToEdit.reason
    ) {
      setFeildError({
        deptError: !dataToEdit.dept_name,
        doctorError: !dataToEdit.doctor_id,
        dateError: !dataToEdit.appt_date,
        slotError: !dataToEdit.appt_end_time || !dataToEdit.appt_start_time,
        scheduleError:
          !dataToEdit.session &&
          (!dataToEdit.appt_end_time || !dataToEdit.appt_start_time),
        reasonError: !dataToEdit.reason,
      });
      setError(
        translate(
          'common.requiredFields',
          'Please fill all the required fields'
        )
      );
    } else {
      const config = {
        data: {
          doctor_id: dataToEdit.doctor_id,
          dept_name: dataToEdit.dept_name,
          dept_id: dataToEdit.dept_id,
          schedule_type: 'op',
          appt_date: formatDate(dataToEdit.appt_date),
          appt_start_time: dataToEdit.appt_start_time,
          appt_end_time: dataToEdit.appt_end_time,
          appt_slot_session: dataToEdit.appt_slot_session,
          reason: dataToEdit.reason,
          patient_details: {
            patient_id: dataToEdit.patient_id,
            name: dataToEdit.patient_name,
            email: dataToEdit.patient_email,
            dob: '2023-12-12',
            gender: 'male',
          },
        },
      };
      editAppointment(
        dataToEdit.appointment_id,
        config,
        fetchAppointments,
        handleEditClose
      );
    }
  };

  useEffect(() => {
    if (selectedDoc.value && applyCounter) {
      fetchAppointments({
        search: patientName,
        appStatus: apptStatus ? apptStatus.value : '',
        department: selectedDoc.value,
        start_Date: formatDate(date),
        end_Date: formatDate(date),
      });
    }
  }, [applyCounter]);
  
  useEffect(() => {
    setTimeout(() => {
      let ele = document.getElementsByClassName("selected-profile");
      if (ele[0]) {
        ele[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }, 1500)
  }, [docDetails])

  useEffect(() => {
    if (appointmentList?.appointments) {
      setAppointments(appointmentList.appointments);
    } else {
      setAppointments([]);
    }
  }, [appointmentList]);

  useEffect(() => {
    if (appointmentList?.appointments?.length) {
      let ar = [];
      let cancelled = [];
      let other = [];
      let upcoming = [];
      let appointmentStart;
      appointmentList.appointments.map((val, i) => {
        if (i == 0) {
          appointmentStart = new Date(setHourAndMinute(val.appt_start_time));
        }
        if (val.live_status === 'cancelled') {
          cancelled.push({
            ...val,
            id: val.appointment_id,
            title: val.patient_name,
            status: val.live_status,
            start: new Date(setHourAndMinute(val.appt_start_time)),
            end: new Date(setHourAndMinute(val.appt_end_time)),
            dayStartTime: appointmentStart,
          });
        } else {
          other.push({
            ...val,
            id: val.appointment_id,
            title: val.patient_name,
            status: val.live_status,
            start: new Date(setHourAndMinute(val.appt_start_time)),
            end: new Date(setHourAndMinute(val.appt_end_time)),
            dayStartTime: appointmentStart,
          });
        }
      });
      ar = [...cancelled, ...other];
      getIsToday(date) &&
        appointmentList?.appointments.forEach((val) => {
          if (val.live_status === 'upcoming') {
            upcoming.push(val);
          }
        });
      // setUpcomingAppointments(upcoming);
      setEvents(ar);
    } else if (!appointmentList?.appointments) {
      // setUpcomingAppointments([]);
      setEvents([]);
    }
    setTimeout(() => {
      let element = document.getElementsByClassName(
        'rbc-current-time-indicator'
      );
      if (element.length) {
        if (getIsToday(date)) {
          element[0].style.display = 'block';
        } else {
          element[0].style.display = 'none';
        }
      }
    }, [100]);
  }, [appointmentList, date]);

  const history = useHistory();

  const hadleAddnewDoc = () => {
    history.push('doctorslisting/adddoctor');
  }

  const tooltipRef = useRef(null);

  // Function to prevent default scroll behavior
  const preventScroll = (event) => {
    event.preventDefault();
  };

  // Function to disable scroll
  const disableScroll = () => {
    tooltipRef.current.addEventListener('scroll', preventScroll);
    tooltipRef.current.addEventListener('wheel', preventScroll);
  };

  // Function to enable scroll
  const enableScroll = () => {
    tooltipRef.current.removeEventListener('scroll', preventScroll);
    tooltipRef.current.removeEventListener('wheel', preventScroll);
  };

  return (
    <>
      <div className={`own-view-day  ${adjustTimelineSize?"adjustTimelineHeight":""}`}>
        <div className="data-wrapper">
          <div className="left-area">
            <div className="mobile-only">
              <div className="doctor-list no-formgroup doc-name-dr">
                <ReactSelectDropDown
                  options={allDocList}
                  onInputChange={handleDoctorChange}
                  className="form-select"
                  value={selectedDoc}
                  components={{ Option: CustomOption }}
                  customFilter={(option) =>
                    `${option.label} ${option.value} ${option.data.departments}`
                  }
                />
              </div>
            </div>
            <div className="list-filter">
              <div className="d-flex align-items-center gap-3 dr-container">
                <div className="left-side cell">
                  <div className={`search-input ${Object.values(searchDocDeptLoading).includes(true) && !isSelect && 'loading' } `}>
                    <input
                      type="search"
                      name=""
                      id=""
                      value={searchDoc}
                      placeholder={intl.formatMessage({
                        id: 'appointments.searchDoctDept',
                        defaultMessage: 'Search Doctor/Dept.',
                      })}
                      // placeholder="Search Doctor/Dept."
                      onChange={onDeptChangeSearch}
                      onBlur={() =>
                        setTimeout(() => setDeptDocSuggestionList([]), 400)
                      }
                    />
                    <i className="bi bi-search" />
                    {deptDocSuggestionList.length
                    && !Object.values(searchDocDeptLoading).includes(true)
                    && !isSelect
                    ? (
                      <div
                        className="customSearchList"
                      >
                        <ul style={{ marginBottom: 1 }}>
                          {deptDocSuggestionList.map((item, index) => (
                            <li
                              key={index}
                              className={`suggestion-item ${item.isDisabled ? 'disabled' : ''}`}
                              onClick={() => !item.isDisabled && handleDeptChange(item)}
                            >
                              {item.label}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
                <h6>
                  {formatDateto_D_WD_M_Y(date, language_Code)}
                </h6>
              </div>
            </div>
            <div className="slot-listing-wrapper">
              <div className="doctor-list" ref={tooltipRef}>
                {docDetails?.length
                  ? 
                  docDetails?.map((data, index) => (
                      <div
                        className={`profile ${
                          data.doctor_id === selectedDoc.value
                            ? 'selected-profile'
                            : ''
                        }`}
                        key={index}
                        onClick={() => {
                          if (
                            selectedDoc.value !== data.doctor_id &&
                            !(apptisLoader || isLoader)
                          ) {
                            // setUpcomingAppointments([]);
                            setSelecteDoc({
                              label: data.name + ' ' + data.last_name,
                              value: data.doctor_id,
                            });
                          }
                        }}
                      >
                        <div className="profile-content">
                          <CardMedia
                            component="img"
                            image={data.profile_img || profilePic}
                          />
                          <div>
                            <span className="doc-name text-capitalize">
                              Dr. {removeDrPrefix(data.name+ ' '+data.last_name)}
                            </span>
                            <span
                              className="dept text-capitalize"
                            >
                              {
                                (data?.departments?.length
                                  ? truncateString(
                                      data?.departments[0]?.dept_name,
                                      data?.departments?.length === 1 ? 20 : 15
                                    ).includes('...')? 
                                    <Tooltip title={<p className='text-capitalize'>{data?.departments[0]?.dept_name}</p>} placement='right'>
                                      {
                                    truncateString(
                                          data?.departments[0]?.dept_name,
                                          data?.departments?.length === 1 ? 20 : 15
                                        )}
                                  </Tooltip>:
                                   data?.departments[0]?.dept_name
                                  : null)
                              }
                                  <Tooltip
                                    classes={{tooltip: 'top-pover'}}
                                    onScroll={(e) => {e.preventDefault(); e.stopPropagation();}}
                                    title={data?.departments?.slice(1).map((x, i) => <p className='text-capitalize' key={i}>{x.dept_name}</p>)}
                                    placement='top-start'
                                  >
                                    {data?.departments?.length > 1 ? (
                                      <span
                                        href="#"
                                        className="td-none text-primary ms-2"
                                        onMouseEnter={disableScroll}
                                        onMouseLeave={enableScroll}  
                                      >
                                        + {data?.departments.length - 1}
                                      </span>
                                    ) : null}
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                        {data.doctor_id === selectedDoc.value ? (
                          <span className="selected-badge">
                            {translate('appointments.optime', 'OP Time')}{' '}
                            {' : '} {opTime}
                          </span>
                        ) : null}
                      </div>
                    ))
                  : apptisLoader || isLoader ? null :
                  <div className='patient-portal'>
                    <NoResultFound text='No doctors added' removeMarginBottom removeMarginTop label={<a className='link' onClick={hadleAddnewDoc}>+Add New</a>} />
                  </div>}
              </div>

              <div className="slots-listing  h-100">
                {(appointmentList?.appointments?.length || !docDetails?.length) && !(apptisLoader || isLoader) ?
                  <CustomTimeline
                    isloader={isLoader}
                    apptisLoader={apptisLoader}
                    events={events}
                    onEditClick={permission(UPDATE) ? onEditClick : ''}
                    onDeleteClick={permission(DELETE) ? onDeleteClick : ''}
                    onCancelClick={permission(STATUS_UPDATE) ? onCancelClick : ''}
                    handlepaymentInfo={handlepaymentInfo}
                    language={language_Code}
                    filteredDate={searchData.date}
                    patient={patient}
                    stat={stat}
                  /> : 
                  apptisLoader || isLoader ?
                    <div className='slot-listing-wrapper-loading'></div>
                  :
                    <div className='patient-portal'>  
                      <NoResultFound
                        text={translate('common.noResultAppointments', 'No appointments added.')}
                        label={<>{translate('common.noAppointmentsAdded',"Looks like you haven't added any appointments yet.")}{permission(CREATE) &&<a  onClick={() => handleAddButton()}>+Add New</a>}</>}
                        // text={translate("appointments.noDataAppointments", "No Appointments added")}
                        // label={translate('appointments.noDataAppointmentsMsg', `Looks like you haven't added any appointments yet.`)}
                        // addNew={
                        //   permission(CREATE) ? handleAddButton : null
                        // }
                        removeMarginBottom removeMarginTop />
                    </div>
                }

                {apptisLoader || isLoader ? (
                  <div className="loaderWrap">
                    <Loader disableScroll={false} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div className="right-area d-none">
            <div className="calander-area large-screen-only">
              <Calendar
                value={date}
                onDateChange={onDateChange}
                activeDate={activeDate}
                setActiveDate={setActiveDate}
                language={language_Code}
              />
            </div>
            {upcomingAppointments.length ? (
              <div className="upcoming-appointments upcomming_appoinments">
                <h5>Upcoming Appointments</h5>
                {upcomingAppointments.map((val, i) => {
                  return (
                    <div className="upcoming-card mb-3" key={i}>
                      {val.patient_profile_image ? (
                        <img src={val.patient_profile_image} alt="" />
                      ) : (
                        <Avatar
                          sx={{ width: 40, height: 40 }}
                          src="/broken-image.jpg"
                        />
                      )}
                      <div>
                        <h6 className="schedule">{`${appendTime(
                          val.appt_start_time,
                          language_Code
                        )} - ${appendTime(
                          val.appt_end_time,
                          language_Code
                        )}`}</h6>
                        <span className="patient-name fw-bold">
                          {val.patient_name}
                        </span>
                        <p className="reason">{val.reason}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div> */}
          {permission(CREATE) && (
            <button
              id="add"
              className="add-btn v2"
              onClick={() => handleAddButton()}
            >
              {AddIcon}
            </button>
          )}
        </div>
      </div>
      {showPopup && (
        <PopupWrapeer
          title={translate('appointments.editAppointment', 'Edit Appointment')}
          onClose={handleEditClose}
          onSubmit={handleEditAppointment}
          saveBtn={translate(
            'appointments.notifyAndSave',
            'NOTIFY PATIENT AND SAVE'
          )}
          isBtnLoading={editApptLoading}
          autoFocus={true}
        >
          <EditAppointments
            dataToEdit={dataToEdit}
            setDataToEdit={setDataToEdit}
            getDoctorSlots={getDoctorSlots}
            getDepartmentData={getDepartmentData}
            clearDeptData={clearDeptData}
            selectedDepartmentData={selectedDepartmentData}
            clearSlots={clearSlots}
            setError={setError}
            error={error}
            feildError={feildError}
            setFeildError={setFeildError}
            doctorSlotData={doctorSlotData}
          />
        </PopupWrapeer>
      )}
      {showConformPopup && (
        <ConformPopup
          onClose={() => setShowConformPopup(false)}
          onConfirm={deleteConfirm}
          message={translate(
            'common.doYouWantToDeleteAppointment',
            'Are you sure you want to delete this appointment?'
          )}
          isBtnLoading={delApptLoading}
        />
      )}
      {cancelPopup && (
        <CancelPopup
          onConfirm={() => handleCancel()}
          onClose={() => onHandleCancelClose()}
          isBtnLoading={cancelApptLoading}
        />
      )}
      {openPaymentInfo && (
        <PaymentInfoPopUp
          openPaymentInfo={openPaymentInfo}
          onClose={handleClose}
          paymentAppt={paymentApptId}
          fetchAppointments={fetchAppointments}
          paymentHandle={paymentHandle}
          isBtnLoading={paymentApptLoading}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  appointmentList: state.AppointmentReducer.appointmentList,
  selectedDepartmentData: state.AppointmentReducer.selectedDepartmentData,
  doctorSlotData: state.AppointmentReducer.doctorSlotData,
  currentPage: state.AppointmentReducer.paginationData.currentPage,
  isBtnLoading: state.AppointmentReducer.isBtnLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getAppointments: (data, setLoader) =>
    dispatch(FetchAppointmentList(data, setLoader)),
  editAppointment: (id, data, fetchAppointments, handleEditClose) =>
    dispatch(UpdateAppointment(id, data, fetchAppointments, handleEditClose)),
  getDoctorSlots: (id, config) => dispatch(FetchDoctorSlots(id, config)),
  getDepartmentData: (id, config) => dispatch(FetchDepartmentData(id, config)),
  clearDeptData: () => dispatch(clearDepartmentData()),
  clearSlots: () => dispatch(clearDoctorSlots()),
  deleteAppointment: (id, handleDeleteSuccess, updateLoading) =>
    dispatch(DeleteAppointment(id, handleDeleteSuccess, updateLoading)),
  SetCurrentPage: (data) => dispatch(updateCurrentPage(data)),
  onCancelAppointment: (id, data, fetchAppointments, onHandleCancelClose) =>
    dispatch(
      CancelAppointment(id, data, fetchAppointments, onHandleCancelClose)
    ),
  paymentHandle: (id, data, fetchAppointments) =>
    dispatch(paymentHandle(id, data, fetchAppointments)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissionCheck(APPOINTMENT)
)(DayRoster);
