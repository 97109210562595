import { AddAppointmentData, updateUserDetails } from 'Actions/appointmentAction';
import AntDatepicker from 'Components/Common/AntdDatePicker';
import PhoneNumberInput from 'Components/Common/PhoneInput';
import { translate } from 'Language/Translate';
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { appendTime, dateinFormatDAYMonthDDYYYY, formatDate } from 'Utils/DateFormatter/Date';
import { setLocalStorageItem } from 'Utils/LocalStorage/local-storage';
import { convertToBase64 } from 'Utils/Services/Authenticator';
import moment from 'moment';
import errorImg from '../../../Assets/img/patient-portal/error_img_placeHolder.png'
// import { useHistory } from 'react-router-dom'
import { PAYMENT_TYPE } from 'Constants/constant';
import ButtonLoader from 'Components/Common/ButtonLoader';
import { useIntl } from 'react-intl';
import { CardMedia, Avatar } from '@mui/material';

const GuestInfoModal = (props) => {
    const {
        date,
        doctorData,
        selectedSlot,
        patientDetails,
        userDetails,
        conformhandle,
        setAppId,
        addAppointment,
        updateData,
        status,
        applicationData,
        isBtnLoading,
    } = props
    const { addAppt = false} = isBtnLoading

    const [infoData, setInfoData] = useState({
        name: userDetails?.name ?? '',
        gender: userDetails?.gender ?? '',
        reasons: '',
        termsOfUs: false,
        date: userDetails?.dob ?? '',
    })
    const [numberLength, setNumberLength] = useState(10);
    const [code, setCode]=useState("IN")

    const imgStatus = useRef(true)
    const intl = useIntl()
    // const history = useHistory()
    useEffect(() => {
        setInfoData(prev => ({
            ...prev,
            name: userDetails?.name ?? '',
            gender: userDetails?.gender ?? '',
            date: userDetails?.dob ?? '',
            reasons: '',
            termsOfUs: false,
        }))
    }, [userDetails])

    const [infoDataErr, setInfoDataErr] = useState({
        nameErr: false,
        genderErr: false,
        reasonsErr: false,
        mobileErr: false,
        termsOfUsErr: false,
        dateErr: true,
        mobileValidErr: false
    })
    const { name, gender, reasons, termsOfUs, date: dob } = infoData
    const { nameErr, genderErr, reasonsErr, mobileErr, termsOfUsErr, dateErr, mobileValidErr } = infoDataErr

    useEffect(() => {
        // to handle clear input field data and error message while opening modal
        if (!status) {
            setInfoDataErr({
                nameErr: false,
                genderErr: false,
                reasonsErr: false,
                mobileErr: false,
                termsOfUsErr: false,
                dateErr: false,
                mobileValidErr: false
            })
            setInfoData(prev => ({
                ...prev,
                name: userDetails?.name ?? '',
                gender: userDetails?.gender ?? '',
                date: userDetails?.dob ?? '',
                reasons: '',
                termsOfUs: false,
            }))
        }
    }, [status])
    // to handle scroll issue while using modal
    useEffect(() => {
        if (status) {
            setTimeout(() => {
                document.body.classList.add('modal-overflow');
            }, 1000)
        } else {
            document.body.classList.remove('modal-overflow');
        }
        return () => document.body.classList.remove('modal-overflow');
    }, [status])

    // const handleTermsOfUse = (e) => {
    //     e.preventDefault();
    //     history.push('/webConnect/terms-of-use')
    // }

    const dateChangeHandle = (date) => {
        setInfoData((prev) => ({
            ...prev,
            date,
        }))
        setInfoDataErr((prev) => ({
            ...prev,
            dateErr: false
        }))
    }

    // store input data and clear error message
    const inputChangeHandle = (event) => {
        const { name, value } = event.target
        setInfoData((prev) => ({
            ...prev,
            [name]: value
        }))
        setInfoDataErr((prev) => ({
            ...prev,
            [name + 'Err']: false,
        }))
    }
    // to store gender data and gender error message 
    const genderHandle = (value) => {
        if (!(userDetails?.gender)) {
            setInfoData((prev) => ({
                ...prev,
                gender: value,
            }))
            setInfoDataErr((prev) => ({
                ...prev,
                genderErr: false
            }))
        }
    }

    // call function after success api call 
    const handleSuccess = () => {
        const newUserDetails = Object.assign({}, userDetails, { name, gender, dob: formatDate(dob) })
        updateData(newUserDetails)
        conformhandle()
        const base64 = convertToBase64(newUserDetails);
        setLocalStorageItem('user', base64)
    }

    // to handle appointment 
    const payHandle = () => {
        var isValid = true
        if (!name || !reasons || !termsOfUs || !gender || !dob) {
            setInfoDataErr({
                nameErr: !name,
                genderErr: !gender,
                reasonsErr: !reasons,
                mobileErr: !userDetails?.phone,
                termsOfUsErr: !termsOfUs,
                dateErr: !dob,

            })
            isValid = false
        }
        if (isValid) {
            const config = {
                data: {
                    doctor_id: doctorData.doctor_id,
                    dept_name: doctorData.departments.find((dept) => dept.dept_status === 'active').dept_name,
                    dept_id: doctorData.departments.find((dept) => dept.dept_status === 'active').dept_id,
                    schedule_type: 'op',
                    appt_date: formatDate(date),
                    appt_start_time: selectedSlot.start_time,
                    appt_end_time: selectedSlot.end_time,
                    appt_slot_session: selectedSlot.session,
                    reason: reasons,
                    patient_details: {
                        patient_id: patientDetails.patient_id,
                        name,
                        gender,
                        dob: formatDate(dob),
                    }
                }
            }
            addAppointment(config, handleSuccess, setAppId)
        }
    }

    // disable futeure dates for ant datepicker
    const disableFutureDates = (current) => {
        return current && current > moment();
    };


    return (
        <div>
            <div className="single-doctor-card pb-1 v2 mb-2">
                {doctorData.profile_img ? (
                    <CardMedia
                        className="doctor-booking-img"
                        style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                        }}
                        component="img"
                        image={doctorData.profile_img}
                        onError={(e) => {
                            if (imgStatus.current) {
                                e.target.src = errorImg
                                imgStatus.current = false
                            }
                        }}
                    />
                ) : (
                    <Avatar
                        className="doctor-booking-img"
                        src="/broken-image.jpg"
                    />
                )}
                <div>
                    <div className="set">
                        <h6 className="name">Dr.{' '}{doctorData?.name}{' '}{doctorData?.last_name}</h6>
                        <p className="speciality mb-0">
                            {
                                doctorData?.departments?.map((item, index) =>
                                (<>
                                    {item.dept_name}{`${index < (doctorData?.departments?.length - 2) ? ' , ' : `${index == (doctorData?.departments?.length - 1) ? '  ' : ' & '}`}`}
                                </>)

                                )
                            }
                            {doctorData?.experience
                                ? doctorData.experience === 1
                                    ? `/ ${doctorData.experience} yr. exp.`
                                    : `/ ${doctorData.experience} yrs. exp.`
                                : ''
                            }

                        </p>
                    </div>
                    <div className="set booking-info">
                        <div className="id-date">
                            <div className="date-time">
                                <span className="label">Date & Time</span>
                                <p>
                                    {
                                        date && dateinFormatDAYMonthDDYYYY(formatDate(date), intl)
                                    }
                                    {' - '}
                                    {
                                        appendTime(selectedSlot.start_time)
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="set">
                <div className="form-group mb-4 pt-2">
                    <label
                        className="mandatory"
                        htmlFor="name"
                    >Name
                    </label>
                    <input
                        type="text"
                        className={`input form-control ${nameErr ? 'input-err' : ''}`}
                        placeholder="i.e, John Doe"
                        name='name'
                        value={infoData.name}
                        onChange={inputChangeHandle}
                        autoComplete={'off'}
                        maxLength="40"
                        disabled={userDetails?.name ? true : false}
                    />
                    {
                        nameErr ? (
                            <span className="err">
                                {translate('common.required', 'This field is required.')}
                            </span>
                        ) : null
                    }

                </div>
                <div className="form-group mb-4">
                    <label htmlFor="phno" className="lable mandatory">Mobile Number</label>
                    <div className="phone-field">
                        <div className="dropdown country-code-picker">
                            <PhoneNumberInput
                                editStatus={userDetails?.tele_country_code}
                                value={userDetails?.tele_country_code}
                                numberLength={numberLength}
                                setNumberLength={setNumberLength} 
                                code={code}
                                setCode={setCode}
                            />
                        </div>
                        <input
                            type="tel"
                            id="phno"
                            name='mobile'
                            placeholder="i.e, 98950 98950"
                            value={userDetails?.phone}
                            className={`form-control grey input  ${mobileErr || mobileValidErr ? 'input-err' : ''}`}
                            onChange={(e) => {
                                inputChangeHandle(e),
                                    setInfoDataErr((prev) => ({
                                        ...prev,
                                        mobileValidErr: false
                                    }))
                            }}
                            disabled
                        />
                    </div>
                    {
                        mobileErr ? (
                            <span className="err">
                                {translate('common.required', 'This field is required.')}
                            </span>
                        ) : null
                    }
                    {
                        mobileValidErr ? (
                            <span className="err">
                                {translate('common.require', 'Invalid Mobile Format .')}
                            </span>
                        ) : null
                    }
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <div className="form-group mb-4">
                            <span className="label mandatory">Gender</span>
                            <div>

                                <div className={`btn-group  ${genderErr ? 'radio-group-err' : ''}`}
                                    role="group"
                                    aria-label="Basic radio toggle button group">
                                    <input
                                        type="radio"
                                        className="btn-check"
                                        name="btnradio"
                                        id="btnradio1"
                                        autoComplete="off"
                                        checked={infoData.gender === 'male'}
                                        disabled={userDetails?.gender}
                                    />
                                    <label
                                        className="btn btn-black"
                                        htmlFor="btnradio1"
                                        onClick={() => genderHandle('male')}
                                    >Male</label>

                                    <input
                                        type="radio"
                                        className="btn-check"
                                        name="btnradio"
                                        id="btnradio2"
                                        autoComplete="off"
                                        checked={infoData.gender === 'female'}
                                        disabled={userDetails?.gender}
                                    />
                                    <label
                                        className="btn btn-black"
                                        htmlFor="btnradio2"
                                        onClick={() => genderHandle('female')}
                                    >Female</label>

                                    <input
                                        type="radio"
                                        className="btn-check"
                                        name="btnradio"
                                        id="btnradio3"
                                        autoComplete="off"
                                        checked={infoData.gender === 'other'}
                                        disabled={userDetails?.gender}
                                    />
                                    <label
                                        className="btn btn-black"
                                        htmlFor="btnradio3"
                                        onClick={() => genderHandle('other')}
                                    >Other</label>
                                </div>
                            </div>
                            {
                                genderErr ? (
                                    <span className="err">
                                        {translate('common.required', 'This field is required.')}
                                    </span>
                                ) : null
                            }
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="form-group mb-4">
                            <label htmlFor="date" className="mandatory">Date of Birth</label>
                            <div className="icon-field date">
                                <AntDatepicker
                                    onInputChange={dateChangeHandle}
                                    value={dob}
                                    className={`${dateErr ? 'input-err' : ''}`}
                                    disabled={userDetails?.dob}
                                    placeholder={'DD/MM/YYYY'}
                                    disabledDate={disableFutureDates}
                                    isAdmin
                                />
                            </div>
                            {
                                dateErr ? (
                                    <span className="err">
                                        {translate('common.required', 'This field is required.')}
                                    </span>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
                <div className="form-group mb-2 pb-1">
                    <label
                        htmlFor="reasonFor"
                        className="mandatory"
                    >Reason for Appointment</label>
            <div className="text-area-wrap">
            <textarea
                        className={`input form-control  ${reasonsErr ? 'input-err' : ''}`}
                        name="reasons"
                        id="reasonFor"
                        cols="30"
                        rows="3"
                        placeholder="e.g. General Check-up"
                        onChange={inputChangeHandle}
                    >
                    </textarea>
            </div>
                    
                    {
                        reasonsErr ? (
                            <span className="err">
                                {translate('common.required', 'This field is required.')}
                            </span>
                        ) : null
                    }
                </div>

                <div className="form-group mb-4">
                    <div className="d-flex align-items-center gap-2 otp">
                        <input
                            className={`form-check-input  ${termsOfUsErr ? 'cbox-err' : ''}`}
                            type="checkbox"
                            id="terms"
                            name="termsOfUs"
                            onChange={(e) => {
                                setInfoData((prev) => ({
                                    ...prev,
                                    termsOfUs: e.target.checked
                                }))
                                setInfoDataErr((prev) => ({
                                    ...prev,
                                    termsOfUsErr: false
                                }))
                            }}
                        />
                        <label
                            className="fw-medium mt-2"
                        > {translate(
                            'patientPortal.agreed',
                            'I have read and agreed to the'
                        )}{' '}
                            <a
                                // onClick={handleTermsOfUse}
                                rel="noreferrer"
                                href={'/#/webConnect/terms-of-use'}
                                target='_blank'
                                className="color-primary fw-600"
                            > {translate('patientPortal.terms', 'Terms of Use')}
                            </a>
                        </label>
                    </div>
                    {
                        termsOfUsErr && (
                            <span className="err checkbox-err">
                                {translate('common.required', 'This field is required.')}
                            </span>
                        )
                    }
                </div>
                <div className="d-flex align-items-center gap-3 footer-btns">
                    {/* <button
                        className="btn btn-outlined-dark w-100"
                        onClick={() => payHandle()}
                    >Pay Online</button>
                    <button
                        className="btn btn-primary w-100"
                        onClick={() => payHandle()}
                    >Pay at Clinic
                    </button> */}

                    {applicationData?.payment_type === PAYMENT_TYPE.online ? (
                        <button className="btn btn-outlined-dark w-100">
                            {translate('patientPortal.payOnline', 'Pay Online')}
                        </button>
                    ) : applicationData?.payment_type === PAYMENT_TYPE.clinic ? (
                        <button
                            className="btn btn-primary
                     w-100 loader-btn"
                     onClick={payHandle}
                            disabled={addAppt}
                        >
                            {addAppt ? <ButtonLoader text={translate('patientPortal.payAtClinic', 'Pay at Clinic')} />
                                : translate('patientPortal.payAtClinic', 'Pay at Clinic')
                            }
                        </button>
                    ) : applicationData?.payment_type === PAYMENT_TYPE.both ? (
                        <>
                            <button className="btn btn-outlined-dark w-100">
                                {translate('patientPortal.payOnline', 'Pay Online')}
                            </button>
                            <button
                                className="btn btn-primary
                     w-100 loader-btn"
                               
                                disabled={addAppt}
                            >
                                {addAppt ? <ButtonLoader text={translate('patientPortal.payAtClinic', 'Pay at Clinic')} />
                                    : translate('patientPortal.payAtClinic', 'Pay at Clinic')
                                }
                            </button>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    )
}
const mapStateTOProps = (state) => ({
    patientDetails: state.LoginReducer.patientDetails,
    userDetails: state.LoginReducer.loginDetails,
    applicationData: state.SettingsReducer.applicationData,
    isBtnLoading: state.AppointmentReducer.isBtnLoading,
})
const mapDispatchToProps = (dispatch) => ({
    addAppointment: (data, handleSuccess, setAppId) =>
        dispatch(AddAppointmentData(data, handleSuccess, setAppId)),
    updateData: (data) => dispatch(updateUserDetails(data))
})

export default connect(mapStateTOProps, mapDispatchToProps)(GuestInfoModal)
