import React from 'react';
import Error404 from '../../Assets/img/icons/404.png';
import '../../Assets/style/NoData.css';

const UnIdentified = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        zIndex: 99,
        backgroundColor: '#ffffff',
        position: 'fixed',
        top: 0,
        left: 0,
      }}
    >
      <div className="page-not-found text-center">
        <img className='mx-auto d-block px-4' src={Error404} alt="404" />
        <h5 className='mt-3 px-4'>
          {`We're unable to find the requested subdomain. Please check the subdomain and try again!`}
        </h5>
      </div>
    </div>
  );
};

export default UnIdentified;
