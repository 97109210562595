/* eslint-disable react/no-unknown-property */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Tooltip } from '@mui/material';
import '../../Assets/style/table.css';
import Switch from '../Common/Switch';
import Loader from '../Common/Loader';
import PopupWrapeer from '../Common/PopupWrapeer';
import AddUser from './AddNewUser';
import Pagination from '../Common/Pagination';
import withPermissionCheck from '../../Utils/WithUtils/WithPermission';
import { translate } from '../../Language/Translate';
import { USER } from '../../Constants/feature';
import { showToaster, ToasterTypes } from 'Actions/toasterAction';
import { DepartmentsApi } from '../../Utils/Api/DepartmentApi';
import {
  addNewUser,
  clearUserData,
  fetchUserData,
  fetchUserList,
  removeUser,
  updateSearchData,
  updateUser,
  updateUserStatus,
  updatePerPage,
  updateCurrentPage,
  updateColumnSort,
} from '../../Actions/usersAction';
import HeaderFields from '../Common/HeaderFeilds';
import { useIntl } from 'react-intl';
import ReactSelectDropDown from '../Common/ReactSelectDropDown';
import { ALL_VALUE, USERS_HEADERS } from '../../Constants/constant';
import { USER_TYPE_NAME, USER_TYPE_OPTIONS } from '../../Constants/userTypes';
import {
  validateEmail,
  validateMobile,
} from '../../Utils/Validation/validator';
import ConformPopup from '../Common/ConformPopup';
import network from '../../Utils/Api/Network';
import TableColumnHr from 'Components/Common/TableColumnHr';
import useDidMountEffect from 'Hooks/useDidMountEffect';
import { isEqual } from 'lodash';
import usePrevious from 'Hooks/usePrevious';
import { CREATE, DELETE, UPDATE } from 'Constants/scopes';
import { sortRolesByPriority } from 'Utils/Permissions/Permissions';
import NoResultFound from 'Components/Common/NoResult';
import TableWrapper from 'Components/Common/TableWrpper';

const deleteIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.1085 3.7371C11.0299 3.5311 8.93875 3.42499 6.85388 3.42499C5.61793 3.42499 4.38199 3.48741 3.14604 3.61225L1.87264 3.7371" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.30582 3.10625L5.44315 2.2875C5.54303 1.69375 5.61793 1.25 6.67285 1.25H8.3083C9.36322 1.25 9.44437 1.71875 9.538 2.29375L9.67533 3.10625" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.7665 5.71252L11.3608 12.0063C11.2921 12.9875 11.2359 13.75 9.49435 13.75H5.48689C3.74533 13.75 3.68915 12.9875 3.62049 12.0063L3.21475 5.71252" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.44814 10.3125H8.52677" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.93004 7.8125H9.05112" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const editIcon = <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.72757 7.68029C3.53419 7.88654 3.34706 8.29279 3.30963 8.57404L3.07883 10.599C2.99774 11.3303 3.52172 11.8303 4.24531 11.7053L6.25388 11.3615C6.53458 11.3115 6.92757 11.1053 7.12094 10.8928L12.2422 5.46154C13.128 4.52404 13.5272 3.45529 12.1486 2.14904C10.7763 0.855287 9.73458 1.31154 8.84881 2.24904L3.72757 7.68029Z" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.99438 3.15625C8.26261 4.88125 9.65988 6.2 11.394 6.375" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.44946 13.75H13.6775" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

function User(props) {
  const {
    getUsersList,
    usersList,
    addUser,
    editUser,
    editUserSatus,
    userData,
    getUserData,
    clearUserDetails,
    deleteUser,
    searchData,
    applySearchData,
    currentPage,
    SetCurrentPage,
    perPage,
    SetPerPage,
    setColumnSort,
    columnSort,
    defaultCountryCode,
    ACCESS,
    isBtnLoading,
    userDetails,
    showToaster
  } = props;
  const intl = useIntl();

  const [loading, updateLoading] = useState(false);
  const [doctorLoader,updateDoctorLoader] = useState({})
  const [showPopup, updateShowPopup] = useState(false);
  const [fname, updateFname] = useState('');
  const [editDetails, setEditDetails] = useState({});
  const [lname, updateLname] = useState('');
  const [email, updateEmail] = useState('');
  const [mobile, updateMobile] = useState('');
  const [country, updateCountryCode] = useState(defaultCountryCode?.toString());
  const [deptOptions, setDeptOptions] = useState([]);
  const [docOptions, setDocOptions] = useState([]);
  const [selectedDept, setSelectDept] = useState(['']);
  const [selectedDocValue, setSelectDocValue] = useState(['']);
  const [selectedRoleType, setSelectRoleType] = useState(['']);

  // filter hanlde
  const [search, setSearch] = useState('');
  const [selectedRole, SetSelectedRole] = useState('');
  const [selectedStatus, SetSelectedStatus] = useState('');

  // Delete deatils
  const [showConformPopup, updateShowConformPopup] = useState(false);
  const [delelteid, updateDeleteid] = useState('');

  //error handle
  const [errorState, setError] = useState({
    nameError: false,
    emailError: false,
    phoneError: false,
    countryError: false,
    invalidMail: false,
    invalidPhone: false,
    typeError: false,
    deptError: false,
    docError: false,
  });

  // edit status
  const [editId, setEditId] = useState(false);

  // pagination handle
  const [users, setUsers] = useState([]);
  // const [currentPage, SetCurrentPage] = useState(1);
  // const [perPage, SetPerPage] = useState(10);
  const [totalCount, SetTotalCount] = useState(null);
  const [numberLength, setNumberLength] = useState(10);
  const [code,setCode]=useState('IN')

  let apiTimeout=null
  // state to handle user suggestion
  const [userSuggestion, setUserSuggestion] = useState([]);
  const [isUserSelect, setIsUserSelect] = useState(false);
  const [userLoader, setUserLoader] = useState({});

  // get department list
  const getDepartmentList = () => {
    DepartmentsApi.FetchAllDepartmentList({
      limit: 0,
      status: 'active',
      sort: 'dept_name'
    }).then(({ error, response }) => {
      if (error) {
        return;
      }
      if (response) {
        const options = response?.departments?.map((dept) => ({
          label: dept.dept_name,
          value: dept.dept_id,
        }));
        setDeptOptions(options);
      }
    });
  };

  const isApplied = (
    (searchData?.search?.trim() !== '' && searchData?.search) ||
    (searchData?.selectedRole !== '' && searchData?.selectedRole?.value !== undefined) ||
    (searchData?.selectedStatus !== [] && searchData?.selectedStatus?.value !== undefined)
  )

  // get users List
  const fetchUsers = (
    { search: searchWord, userStatus, role, pageNo, perPageCount } = {},
    loading = true
  ) => {
    if (loading) updateLoading(true);
    const sortKey = Object.keys(columnSort)[0];
    const config = {
      search:
        searchWord || searchWord === ''
          ? searchWord || undefined
          : searchData?.search
          ? searchData?.search
          : undefined,
      status:
        userStatus || userStatus === ''
          ? userStatus || undefined
          : searchData?.selectedStatus?.value
          ? searchData?.selectedStatus?.value
          : undefined,
      role:
        role || role === ''
          ? role || undefined
          : searchData?.selectedRole?.value
          ? searchData?.selectedRole?.value
          : undefined,
      page: pageNo || currentPage,
      limit: perPageCount || perPage,
      sort: sortKey
        ? columnSort[sortKey] === 'asc'
          ? sortKey
          : `-${sortKey}`
        : undefined,
    };
    getUsersList(config, updateLoading);
  };

  useEffect(() => {
    setSearch(searchData?.search ?? '');
    setIsUserSelect(searchData?.search ?? [])
    SetSelectedRole(searchData?.selectedRole ?? []);
    SetSelectedStatus(searchData?.selectedStatus ?? []);
    getDepartmentList();
    fetchUsers({
      search: searchData?.search,
      userStatus: searchData?.selectedStatus?.value,
      role: searchData?.selectedRole?.value,
    });
  }, []);

  const prevSortVal = usePrevious(columnSort);

  useDidMountEffect(() => {
    if (!isEqual(prevSortVal, columnSort)) {
      fetchUsers({
        search: searchData?.search,
        userStatus: searchData?.selectedStatus?.value,
        role: searchData?.selectedRole?.value,
      });
    }
  }, [columnSort]);

  useEffect(() => {
    if (usersList.users) {
      setUsers(usersList.users);
      SetTotalCount(usersList?.page_details?.total_documents);
    } else {
      setUsers([]);
      SetTotalCount(0);
    }
  }, [usersList]);

  const loadUserOptions = async (inputValue) => {
    let fetchedOptions = [];
    if (inputValue?.trim()) {
      let id = new Date().getTime()
      setUserLoader((prev)=>({
        ...prev,
        [id]:true
      }));
      await network.get({ url: '/provider/users', data: { search: inputValue, limit: 0 }}).then((response) => {
        setUserLoader((prev)=>({
          ...prev,
          [id]:false
        }));
        const { isError, result } = response;
        if (isError) {
          return
        } else {
          let options = result?.users.map((item) => ({
            label: `${item.name} ${item.last_name}`,
            value: `${item.name} ${item.last_name}`
          }))
          if (!options || !options?.length) {
            options = [{
              value: '',
              label: `${intl.formatMessage({
                id: 'common.noData',
                defaultMessage: 'No data found.',
              })}`,
              isDisabled: true,
            }]
          }
          fetchedOptions = options
        }
      })
        .catch(() => { })
    }
    setUserSuggestion(fetchedOptions)
  }

  // handle filter apply
  const handleApplyClick = () => {
    let search_Data = { search, selectedRole, selectedStatus };
    applySearchData(search_Data);
    if (
      (search?.trim() !== '' && search) ||
      (selectedRole !== '' && selectedRole?.value !== undefined) ||
      (selectedStatus !== [] && selectedStatus.value !== undefined) ||
      isApplied
    ) {
      fetchUsers({
        pageNo: 1,
        search: search,
        userStatus: selectedStatus?.value,
        role: selectedRole?.value !== undefined? selectedRole?.value:'',
      });
      SetCurrentPage(1);
    }
    setUserSuggestion([])
  };

  //handle filter reset
  const handleReset = () => {
    applySearchData({});
    setUserLoader({})
    setColumnSort({});
    SetSelectedRole('');
    SetSelectedStatus('');
    setSearch('');
    SetPerPage(25);
    SetCurrentPage(1);
    if (
      search ||
      selectedRole ||
      selectedStatus.length !== 0 ||
      currentPage !== 1 ||
      perPage !== 25 ||
      isApplied
    ) {
      fetchUsers({
        search: '',
        userStatus: '',
        role: '',
        pageNo: 1,
        perPageCount: 25,
      });
    }
  };

  // handle enter click
  const handleKeyPress = (e) => {
    if (e && e.key === 'Enter' && showPopup !== true) {
      e.target.blur();
      if (search || selectedStatus || selectedRole) {
        handleApplyClick();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [search, selectedStatus, selectedRole,showPopup]);

  // handle modal close
  const handleClose = (successType = null) => {
    updateShowPopup(false);
    updateFname('');
    updateLname('');
    updateEmail('');
    setError({
      nameError: false,
      emailError: false,
      phoneError: false,
      countryError: false,
      invalidMail: false,
      invalidPhone: false,
      typeError: false,
      deptError: false,
      docError: false,
    });
    updateMobile('');
    updateCountryCode(defaultCountryCode?.toString());
    setSelectDept(['']);
    setSelectDocValue(['']);
    setSelectRoleType(['']);
    setDocOptions([]);
    setEditId(null);
    if (successType === 'edit') {
      fetchUsers();
    } else if (successType === 'add') {
      fetchUsers();
      // SetCurrentPage(1);
      // setSearch('');
      // SetSelectedStatus('');
      // SetSelectedRole('');
    }
    updateDoctorLoader({})
  };

  // handle department status change
  const handleStatusChange = (value, id) => {
    const config = {
      data: {
        user_status: value ? 'active' : 'inactive',
      },
    };
    editUserSatus(id, config, fetchUsers);
  };

  // on pagination change
  const handleCurrentPageChange = (pageNo) => {
    SetCurrentPage(pageNo);
    fetchUsers({ pageNo });
  };

  // handel perPage change
  const handlePerPageChange = (count) => {
    SetPerPage(count);
    SetCurrentPage(1);
    fetchUsers({ pageNo: 1, perPageCount: count });
  };

   const handleUserSelect=(name)=>{
     setSearch(name)
     setIsUserSelect(true)
     setUserSuggestion([])
   }
  // handle search value change
  const onChangeSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
    setIsUserSelect(false)
  };

  useEffect(() => {
    if (!isUserSelect) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadUserOptions(search);
      }, 300);
      apiTimeout = timeout;
    }
  }, [search]);
  
  // handle add / edit leave submit
  const handleSubmit = () => {
    console.log({condition:selectedRoleType?.length&& selectedRoleType?.find(
      (item) => item.value === "doctor"
    ),selectedRoleType})
    let isValid = true
    if (
      !fname?.trim()||
      !email?.trim() ||
      !country ||
      !mobile?.trim() ||
      !selectedRoleType?.find((item) => item.value) ||
      selectedRoleType?.find(
        (item) => item.value === "appointment-coordinator"
      ) && selectedDept.some((element) => element === null || element === "") ||
      selectedRoleType?.find(
        (item) => item.value === "appointment-coordinator"
      ) && selectedDocValue.some(
        (element) => element === null || element === "" || element.length == 0
      ) ||
      selectedDocValue === null
    ) {
      isValid = false;
      setError((prev) => ({
        ...prev,
        nameError: !fname.trim(),
        emailError: !email.trim(),
        phoneError: !mobile.trim(),
        countryError: !country,
        typeError: !selectedRoleType?.find((item) => item.value),
        deptError:
          selectedRoleType?.find(
            (item) => item.value === "appointment-coordinator"
          ) &&
          selectedDept.some((element) => element === null || element === ""),
        docError:
          selectedRoleType?.find(
            (item) => item.value === "appointment-coordinator"
          ) &&
          selectedDocValue.some(
            (element) =>
              element === null || element === "" || element.length == 0
          ),
      }));
    } 
     if (email?.trim() && !validateEmail(email)) {
       isValid = false;
       setError((prev) => ({
         ...prev,
         invalidMail: true,
       }));
     } else {
       setError((prev) => ({
         ...prev,
         invalidMail: false,
       }));
     }
     if (mobile?.trim() && (!validateMobile(mobile,code))) {
      isValid = false
      setError((prev) => ({
        ...prev,
        invalidPhone: true,
      }));
    } 
    if(isValid == true) {
      setError({
        nameError: false,
        emailError: false,
        phoneError: false,
        countryError: false,
        invalidMail: false,
        invalidPhone: false,
        typeError: false,
        deptError: false,
        docError: false
      });

      let user_dept_doctor_acl = [];
      if (selectedDept[0]?.value && selectedDocValue[0]) {
        selectedDept.forEach((dept, index) => {
          const dept_id = dept.value;
          selectedDocValue[index]?.forEach((doc) => {
            const doctor_id = doc.value;
            user_dept_doctor_acl.push({ dept_id, doctor_id });
          });
        });
      }

      const roleType = selectedRoleType.map((item) => item.value);
      let newRoleType = [];
      if (
        userData?.user_roles?.includes('subscription-owner') &&
        !roleType.includes('admin')
      ) {
        newRoleType = [...roleType, 'admin', 'patient'];
      } else {
        newRoleType = [...roleType, 'patient'];
      }
      if(newRoleType.includes("appointment-coordinator") && user_dept_doctor_acl.length == 0){
            showToaster(translate('users.userRole', 'You must choose the department and doctors if you choose the appointment coordinator option", ToasterTypes.Failed'), ToasterTypes.Failed)
      }else{
        const config = {
          data: {
            name:fname,
            last_name: lname,
            email: email,
            tele_country_code: country,
            phone: mobile?.replace(/\s/g, ""),
            user_roles: newRoleType,
            user_dept_doctor_acl,
            // need to add gender,dob,address or it will get erased from patient screen
            ... editId&&(editDetails),
          },
        };
  
        if (editId) {
          editUser(editId, config, handleClose, editId === userDetails.user_id);
        } else {
          addUser(config, handleClose);
        }
      }  
    }
  };

  // handle Edit click
  const handleEditClick = (item) => {
    getUserData(item.user_id);
    setEditId(item.user_id);
    updateShowPopup(true);
    setEditDetails({dob:item?.dob,gender:item?.gender,address:item?.address})
    updateFname(item.name);
    updateLname(item.last_name);
    updateEmail(item.email);
    updateCountryCode(
      item.tele_country_code
        ? item.tele_country_code
        : defaultCountryCode?.toString()
    );
    updateMobile(item.phone);
  };

  // handle delete
  const onDelete = (id) => {
    updateShowConformPopup(true);
    updateDeleteid(id);
  };

  // fetch depratment data
  const fetchDepartmentDoctors = (id, index) => {
    updateDoctorLoader({...doctorLoader,[index]:true})
    network
      .get({
        url: `/department/${id}`,
      })
      .then((response) => {
        const { result, isError } = response || {};
        if (!isError && result?.doctors?.length) {
          const doctList =
            result?.doctors.length > 1 ? [
              ALL_VALUE,
              ...result.doctors.map((doc) => ({
                label: `Dr. ${doc.name} ${doc.last_name}`,
                value: doc.doctor_id,
              })),
            ] :
              [
                ...result.doctors.map((doc) => ({
                  label: `Dr. ${doc.name} ${doc.last_name}`,
                  value: doc.doctor_id,
                })),
              ]
          setDocOptions((prevOptions) => {
            const updatedOptions = [...prevOptions];
            updatedOptions[index] = doctList;
            return updatedOptions;
          });
        } else {
          setDocOptions((prevOptions) => {
            const updatedOptions = [...prevOptions];
            updatedOptions[index] = [];
            return updatedOptions;
          });
        }
        updateDoctorLoader({...doctorLoader,[index]:false})
      });
  };

  // handle delete success
  const handleDeleteSuccess = () => {
    updateShowConformPopup(false);
    if (users.length === 1 && currentPage > 1) {
      SetCurrentPage(currentPage - 1);
      fetchUsers({ pageNo: currentPage - 1 });
    } else {
      fetchUsers();
    }
  };

  // confirm delete
  const confirmDelete = () => {
    deleteUser(delelteid, handleDeleteSuccess);
  };

  // handle outside close of filter
  const handleOutsideClose = () => {
    setSearch(searchData?.search);
    SetSelectedRole(searchData?.selectedRole ?? []);
  }

  return (
    <div>
      {showPopup && (
        <PopupWrapeer
          title={
            editId
              ? translate('users.editUser', 'Edit User')
              : translate('users.adduser', 'Add User')
          }
          modalContent="modalContent modal_sm"
          additionalClass='user-popup'
          onClose={handleClose}
          onSubmit={handleSubmit}
          saveBtn={
            editId
              ? translate('common.update', 'UPDATE')
              : translate('common.save', 'SAVE')
          }
          isBtnLoading={isBtnLoading}
        >
          <AddUser
            fname={fname}
            updateFname={updateFname}
            lname={lname}
            updateLname={updateLname}
            email={email}
            updateEmail={updateEmail}
            country={country}
            updateCountryCode={updateCountryCode}
            mobile={mobile}
            updateMobile={updateMobile}
            departments={deptOptions}
            selectedDept={selectedDept}
            setSelectDept={setSelectDept}
            selectedDocValue={selectedDocValue}
            setSelectDocValue={setSelectDocValue}
            setSelectRoleType={setSelectRoleType}
            selectedRoleType={selectedRoleType}
            errorState={errorState}
            isEditing={!!editId}
            getDepartmentData={fetchDepartmentDoctors}
            doctorsOption={docOptions}
            setDocOptions={setDocOptions}
            userData={userData}
            clearUserDetails={clearUserDetails}
            setError={setError}
            doctorLoader={doctorLoader}
            numberLength={numberLength}
            setNumberLength={setNumberLength}
            code={code}
            setCode={setCode}
          />
        </PopupWrapeer>
      )}
      {showConformPopup && (
        <ConformPopup
          onClose={() => updateShowConformPopup(false)}
          onConfirm={confirmDelete}
          message={translate(
            'common.doYouWantToDeleteUser',
            'Are you sure you want to delete this user?'
          )}
          isBtnLoading={isBtnLoading}
        />
      )}
      <HeaderFields
        totalCount={totalCount}
        onApply={handleApplyClick}
        onReset={handleReset}
        onAddBtnClick={ACCESS(CREATE) ? () => updateShowPopup(true) : null}
        childBreakPt={11}
        btnBreakPt={1}
        applyBtnCls={'col-sm-2'}
        breakPoint="md"
        isApplied={isApplied}
        handleOutsideClose={handleOutsideClose}
      >
        <div className="col-md-6 pe-md-1 mb-0">
          <div className="form-group mb-0">
          <label htmlFor="">{translate('users.nameEmail', 'Name / Email')}</label>
          <div className={`search-input ${search !== '' && !isUserSelect && Object.values(userLoader).includes(true) && 'loading'}`}>
              <input
                type="search"
                className="form-control down-arrow"
                onChange={onChangeSearch}
                placeholder={intl.formatMessage({
                  id: 'common.search',
                  defaultMessage: 'Search',
                })}
                value={search}
                maxLength="40"
                autoComplete="off"
                onBlur={() => setTimeout(() => setUserSuggestion([]), 400)}
                onFocus={()=>{
                  if(search) loadUserOptions(search)
                }}
              />
            </div>
            {
              userSuggestion.length && search && !isUserSelect && !Object.values(userLoader).includes(true) ?
                (
                  <div className="customSearchList">
                    <ul style={{ marginBottom: 1 }}>
                      {userSuggestion.map((user, index) => (
                        <li
                          key={index}
                          className={`suggestion-item ${user.isDisabled ? 'disabled' : ''}`}
                          onClick={() => !user.isDisabled && handleUserSelect(user.value)}
                        >
                          {user.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null
            }
          </div>
        </div>
        <div className="col-md-6 mb-0">
          <div className="form-group react-dr-v2 mb-0">
          <label htmlFor="">User Role</label>
            <ReactSelectDropDown
              options={USER_TYPE_OPTIONS || []}
              value={selectedRole}
              onInputChange={(item) => {
                SetSelectedRole(item);
              }}
              // label={intl.formatMessage({
              //   id: 'users.roleSelect',
              //   defaultMessage: 'Select',
              // })}
              isCasePlaceholder={true}
              isClearable
            />
          </div>
        </div>
        {/* <div className="col-md-2">
          <div className="form-group">
            <ReactSelectDropDown
              options={DEPARTMENT_STATUS || []}
              id="status"
              label={intl.formatMessage({
                id: 'common.status',
                defaultMessage: 'Status',
              })}
              onInputChange={(item) => {
                SetSelectedStatus(item);
              }}
              value={selectedStatus}
            />
          </div>
        </div> */}
      </HeaderFields>
      <TableWrapper data={users}>
        <div className="list-user" style={{minHeight: loading ? '55vh' : '', overflow: !users?.length ? 'hidden' : ''}}>
          {users && users.length && !loading ? (
            <table className="table departments-calendar v2 new-user-table">
              <thead>
                <tr>
                  {USERS_HEADERS.map((header, index) => (
                    <TableColumnHr
                      item={header}
                      key={index}
                      columnSort={columnSort}
                      setColumnSort={setColumnSort}
                      sortEnable={users.length > 1 ? true : false}
                    />
                  ))}
                </tr>
              </thead>
              <tbody>
                {users.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{(currentPage - 1) * perPage + (index + 1)}</td>
                    <td className='text-capitalize'>
                      {`${item.name} ${item.last_name}`}{' '}
                      {item.user_id === userDetails.user_id && (
                        <span className="badge badge-me">
                          {translate('common.itsYou', `It's you`)}
                        </span>
                      )}
                    </td>
                    <td>{item.phone}</td>
                    <td>{item.email}</td>
                    <td className="text-capitalize">
                      {item.user_roles.length != 0 ? (
                        <div className='d-flex align-items-center'>
                          {/* <span className='truncated-role' title='Role Name'> */}
                          <span className='role-text'>
                          {
                            USER_TYPE_NAME[
                              sortRolesByPriority(item.user_roles)[0]
                            ]
                          }
                          </span>
                        <span className='relative'>
                        {item.user_roles?.length >= 2 ? (
                            <span
                              role="button"
                              // data-bs-toggle="dropdown"
                              // aria-expanded="true"
                              className="pointer extra-details"
                              style={{ paddingBottom: 'unset' }}
                            >
                              +{item.user_roles?.length - 1}
                            </span>
                          ) : null}
                          <ul
                          className="dropdown-menu dropdown-menu-start custom-tooltip start top"
                          data-popper-placement="bottom-start"
                          >
                            {sortRolesByPriority(item.user_roles)
                              ?.slice(1)
                              .map((item, index) => (
                                <li key={index}>
                                  <span
                                    className="dropdown-item small"
                                    // data-bs-toggle="offcanvas"
                                    data-bs-target="#editAccount"
                                    aria-controls="editAccount"
                                  >
                                    {USER_TYPE_NAME[item]}
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </span>
                        </div>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      <Switch
                        checkedProps={item.user_status === 'active'}
                        handleSwitchChange={(checked) =>
                          handleStatusChange(checked, item.user_id)
                        }
                        key={item.user_id}
                        disabled={item.user_id === userDetails.user_id}
                      />
                      {item.user_status === 'active'
                          ? translate('common.active', 'Active')
                          : translate('common.inactive', 'Inactive')}
                    </td>
                    <td>
                      <div>
                        <div className="action-btns">
                          {ACCESS(UPDATE) && (
                            <Tooltip
                              title={translate('users.editUser', 'Edit User')}
                              placement="top"
                            >
                              <span className='edit-icon'  onClick={() => handleEditClick(item)}>
                                {editIcon}
                              </span>
                            </Tooltip>
                          )}
                          {ACCESS(DELETE) && (
                            <Tooltip
                              title={translate('users.deleteUser', 'Delete User')}
                              placement="top"
                            >
                              {item.user_roles.find(
                                (role) => role === "subscription-owner") ?
                                <span className='delete-icon disabled'>
                                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.1085 3.7371C11.0299 3.5311 8.93875 3.42499 6.85388 3.42499C5.61793 3.42499 4.38199 3.48741 3.14604 3.61225L1.87264 3.7371" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5.30582 3.10625L5.44315 2.2875C5.54303 1.69375 5.61793 1.25 6.67285 1.25H8.3083C9.36322 1.25 9.44437 1.71875 9.538 2.29375L9.67533 3.10625" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11.7665 5.71252L11.3608 12.0063C11.2921 12.9875 11.2359 13.75 9.49435 13.75H5.48689C3.74533 13.75 3.68915 12.9875 3.62049 12.0063L3.21475 5.71252" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.44814 10.3125H8.52677" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5.93004 7.8125H9.05112" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                </span>
                                : 
                                <span className='delete-icon' onClick={() => onDelete(item.user_id)}>
                                  {deleteIcon}
                                </span>}
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : !loading ? (
              <div className='patient-portal'>
                <NoResultFound text={isApplied ?
                  translate('common.noResult', 'No results found.') :
                  translate('users.noDataUsers', 'No users added')} 
                  removeMarginBottom removeMarginTop
                  label={isApplied ? 
                  translate('common.noResultMsg', 'No results match the filter criteria Remove filter or clear all filters to show results') : 
                  translate('users.noDataPatientsMsg', `Looks like you haven't added any users yet.`)}
                  addNew={
                    ACCESS(CREATE) && !isApplied ? () => updateShowPopup(true) : null
                  } />
              </div>
          ) : (
            <Loader />
          )}
        </div>
      </TableWrapper>
      {!loading && users && users.length ? (
        <Pagination
          SetCurrentPage={handleCurrentPageChange}
          SetPerPage={handlePerPageChange}
          perPage={parseInt(perPage)}
          totalCount={totalCount}
          currentPage={currentPage}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  usersList: state.UsersReducer.usersList,
  userData: state.UsersReducer.userData,
  userDetails: state.LoginReducer.loginDetails,
  searchData: state.UsersReducer.searchData,
  perPage: state.UsersReducer.paginationData.perPage,
  currentPage: state.UsersReducer.paginationData.currentPage,
  columnSort: state.UsersReducer.columnSort,
  defaultCountryCode:
    state.SettingsReducer.subscriptionData.setting.defaultCountryCode,
  isBtnLoading: state.UsersReducer.isBtnLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getUsersList: (data, setLoader) => dispatch(fetchUserList(data, setLoader)),
  addUser: (data, handleSuccess) => dispatch(addNewUser(data, handleSuccess)),
  editUser: (id, data, handleSuccess, isMe) =>
    dispatch(updateUser(id, data, handleSuccess, isMe)),
  editUserSatus: (id, data, handleSuccess) =>
    dispatch(updateUserStatus(id, data, handleSuccess)),
  getUserData: (id) => dispatch(fetchUserData(id)),
  clearUserDetails: () => dispatch(clearUserData()),
  deleteUser: (id, handleSuccess) => dispatch(removeUser(id, handleSuccess)),
  applySearchData: (data) => dispatch(updateSearchData(data)),
  SetCurrentPage: (data) => dispatch(updateCurrentPage(data)),
  SetPerPage: (data) => dispatch(updatePerPage(data)),
  setColumnSort: (data) => dispatch(updateColumnSort(data)),
  showToaster: (message,type) => dispatch(showToaster(message,type)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissionCheck(USER)
)(User);
