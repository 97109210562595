import React, { useEffect, useRef, useState } from 'react'
import otpLogo from '../../../Assets/img/icons/enter-otp-main-image.svg'
import correct from '../../../Assets/img/icons/correct.svg'
import { translate } from '../../../Language/Translate'
import { connect } from 'react-redux'
import { validateToken, verifyOtpPhoneUpdate } from 'Actions/patientProfileAction'
import { useIntl } from 'react-intl'
import { timeZoneDate } from 'Utils/DateFormatter/Date'


const OtpVerifyPopup = ({ verifyOtpData = {},
  callPatientDetailsForOTP = () => { },
  verifyOtp = () => { },
  closeVerifyModal = () => { },
  getToken = () => { },
  type = "",
  getLinkedProfileList = () => { },
  verifyOtpforUpdate = () => { },
  setEditModal = () => { },
  otpDetails,
  modalType = "otpModalVerification",
  showOtpLoader,
  setLoading,
  resendLoader
}) => {

  const inputRefs = useRef([]);
  const intl = useIntl()
  const [otp, setOtp] = useState(['', '', '', '']);
  const [otpVerified, setOtpVerified] = useState('')
  // const [otpError, setOtpError] = useState(false)
  // const [errorMsg, setErrMsg] = useState('')
  // const otp = ['','','','']
  // let otpVerified = ''
  // const otpError = false
  const errorMsg = ''
  // const enteredOtp = otp.join('')
  const [countdown, setCountdown] = useState(0)
  const [showCounter, setShowCounter] = useState(false)
  // Resend Counter
  const [resend, setResend] = useState(0)
  const [resendCounter, setResendCounter] = useState(0)
  const [showResend, setShowResend] = useState(false)
  const { otp_resend_limit, otp_limit_duration } = otpDetails

  // for checking resend Disabled or Not
  useEffect(() => {
    const resendDetails = JSON.parse(localStorage.getItem("Resend"))
    if (resendDetails) {
      const otpverifyPop = resendDetails[modalType]
      if (otpverifyPop) {
        let ResendInterval = setInterval(() => remainingResendTime(otpverifyPop, ResendInterval), 1000);
      }
    } else {
      localStorage.setItem("Resend", JSON.stringify({}))
    }

    setTimeout(() => {
      inputRefs.current[0].focus();
    }, 300)

  }, [])


  useEffect(() => {
    let targetTime = timeZoneDate().getTime() + (verifyOtpData?.exp * 60 * 1000) + 1000
    let Interval = setInterval(() => remainingTime(targetTime, Interval), 1000);
    return () => {
      clearInterval(Interval)
      getLinkedProfileList()
    }
  }, [verifyOtpData])

  let remainingTime = (targetTime, Interval) => {
    let currentTime = timeZoneDate().getTime()
    let leftSecond = Math.trunc((targetTime - currentTime) / 1000)
    setCountdown(leftSecond)
    if (leftSecond <= 0) {
      clearInterval(Interval)
      setShowCounter(false)
    }
    else if (!showCounter) {
      setShowCounter(true)
    }
    return leftSecond
  }

  let remainingResendTime = (targetTime, Interval) => {
    let currentTime = timeZoneDate().getTime()
    let leftSecond = Math.trunc((targetTime - currentTime) / 1000)
    setResendCounter(leftSecond)
    if (leftSecond <= 0) {
      clearInterval(Interval)
      setShowResend(false)
      setResend(0)
    }
    else if (!showResend) {
      setShowResend(true)
    }
    return leftSecond
  }


  const handleinput = (e, index) => {
    const value = e.target.value
      if (isNaN(value) || value.trim() == "" ||showOtpLoader) return;
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value.slice(-1);
        return newOtp;
      })
      if (value !== '' && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    setOtpVerified('')
  }

  const handleResendOtp = (e) => {
    if(!e.key || (e.key ==="Enter")){
      setOtpVerified("")
      setOtp(['', '', '', ''])
      inputRefs.current[0].focus();
      if (type == "edit") {
        getToken()
      }
      else {
        callPatientDetailsForOTP(verifyOtpData)
      }
      setResend(resend + 1)
      if ((resend + 1) >= otp_resend_limit) {
        let targetResendTime = timeZoneDate().getTime() + (otp_limit_duration * 60 * 1000) + 1000
        const resendlocal = JSON.parse(localStorage.getItem("Resend"))
        localStorage.setItem("Resend", JSON.stringify({ ...resendlocal, [modalType]: targetResendTime }))
        let IntervalRemaining = setInterval(() => remainingResendTime(targetResendTime, IntervalRemaining), 1000);
      }
    }
    else if(e.key === "Tab" && !e.shiftKey) e.preventDefault()
  }

  const handleVerifyOtp = () => {
    if (otp.length && otp.join("").trim().length === 4) {
      if (type === "edit") {
        let data = {
          id: verifyOtpData?.user_id,
          payload: {
            data: {
              update_data: {
                tele_country_code: verifyOtpData.tele_country_code,
                phone: verifyOtpData?.phone
              },
              otp_verification: {
                otp: otp.join(""),
                token: verifyOtpData?.token
              }
            }
          }
        }
        verifyOtpforUpdate(data, onSucessOtpVerification, onErrorOtpVerification)
      } else {
        let data = {
          data: {
            patient_query: verifyOtpData.patient_id,
            // patient_query: verifyOtpData.tele_country_code+verifyOtpData?.phone,
            otp_verification: {
              otp: otp.join(""),
              token: verifyOtpData?.token
            }
          }
        }
        verifyOtp(data, onSucessOtpVerification, onErrorOtpVerification)
      }
    }
  };
  const onSucessOtpVerification = () => {
    setOtpVerified("success")
    setLoading(true)
    getLinkedProfileList()
    if (type == "edit") {
      setEditModal()
    }
    setTimeout(() => {
      closeVerifyModal()
    }, 2000)
  }
  const onErrorOtpVerification = (message) => {
    setOtpVerified(message)
  }
  useEffect(() => {
    handleVerifyOtp()
  }, [otp])

  // const handleKeyDown = (e, index) => {
  //   if (e.key === 'Backspace' && otp[index] === '') {
  //     if (index > 0) {
  //       inputRefs.current[index - 1].focus();
  //     }
  //   }
  // };
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
    else if (e.key === 'Backspace' && !showOtpLoader) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = "";
        return newOtp;
      });
      if (index > 0) {
        inputRefs.current[index].focus();
      }
      setOtpVerified('')
    }
    if(index === 3 && e.key === "Tab" && !e.shiftKey && countdown !== 0) e.preventDefault()
        if(index === 0 && e.key === "Tab" && e.shiftKey ) e.preventDefault()
  };

  const handlePaste = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let val = e?.clipboardData?.getData('text').split("")
    if (val?.length) {
      val.push("", "", "", "")
      otp.reverse().map((item) => {
        if (item !== "") {
          val.unshift(item)
        }
      })
      inputRefs?.current?.[val.indexOf("") < 3 ? val.indexOf("") : 3].focus()
      setOtp(val.splice(0, 4))
    }
    setOtpVerified('')
  };

  return (
    <div className="otp-verification">
      <div className="enter-otp-modal">
        <div className="modal-body">
          <img className="main-img" src={otpLogo} alt="" />
          <h6>
            {" "}
            {translate(
              "patientPortal.enterProfilePsw",
              "Please enter the One Time Password to verify your profile."
            )}
          </h6>
          <p>
            {" "}
            {translate("patientPortal.otpSent", "An OTP has been sent to")}{" "}
            <span className="fw-bold">
              +
              {type === "edit"
                ? `${verifyOtpData.tele_country_code} ${verifyOtpData.phone}`
                : verifyOtpData.phone}
            </span>{" "}
          </p>

          <div
            className={`otp-theme ${otpVerified === "success" && "success"} ${
              otpVerified && otpVerified !== "success" && "error"
            }`}
          >
            <div
              className={`otp-field ${showOtpLoader && "loading"}`}
              onPaste={(e) => handlePaste(e)}
            >
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  className="form-control otpinput"
                  // maxLength="1"
                  value={digit}
                  onChange={(e) => handleinput(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(ref) => (inputRefs.current[index] = ref)}
                  readOnly={otpVerified === "success"}
                  onFocus={(e) => e.target.select}
                  autoFocus={index === 0}
                />
              ))}
              {otpVerified === "success" ? (
                <img
                  className="status-icon correct icon-1"
                  src={correct}
                  alt=""
                />
              ) : (
                ""
              )}
            </div>
            {otpVerified !== "success" && resendCounter < 1 && showCounter && (
              <>
                {translate("patientPortal.otpExpire", "OTP will expire in")}{" "}
                {showCounter && countdown}{" "}
                {translate("appointments.sec", "sec")}
              </>
            )}
            {otpVerified !== "success" &&
              resendCounter < 1 &&
              countdown === 0 &&
              resend < otp_resend_limit && (
                <>
                  {resendLoader ? (
                    <a
                      id="resend"
                      tabIndex="0"
                      className="resend-otp loading"
                    >
                      {translate("patientPortal.resend", "Resend OTP")}
                    </a>
                  ) : (
                    <a
                      id="resend"
                      tabIndex="0"
                      className="resend-otp"
                      onKeyDown={handleResendOtp}
                      onClick={handleResendOtp}
                    >
                      {translate("patientPortal.resend", "Resend OTP")}
                    </a>
                  )}
                </>
              )}
            {otpVerified !== "success" && resendCounter > 0 && (
              <span className="exceeded-msg">
                {intl.formatMessage({
                  id: "commom.otpLimitMsg",
                  defaultMessage:
                    "OTP send limit exceeded please try again after 5 min",
                })}
              </span>
            )}
            <div className="d-flex align-items-center justify-content-center gap-2">
              {otpVerified === "success" && (
                <span className="success-msg">
                  {translate(
                    "patientPortal.otpVerified",
                    "OTP verified successfully!"
                  )}
                </span>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center gap-2">
              <img
                className="status-icon correct icon-2"
                src="./assets/images/icons/correct.svg"
                alt=""
              />
              {otpVerified && otpVerified !== "success" && (
                <span className="error-msg">{otpVerified}</span>
              )}
              {errorMsg && <span className="error-msg">{errorMsg}</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  otpDetails: state.SettingsReducer.otpDetails,
  showOtpLoader: state.ProfileReducer.showOtpLoader,
});
const mapDispatchToProps = (dispatch) => ({
  verifyOtp: (data, callBack, errCallBack) => dispatch(validateToken(data, callBack, errCallBack)),
  verifyOtpforUpdate: (data, callBack, errCallBack) => dispatch(verifyOtpPhoneUpdate(data, callBack, errCallBack))
})

export default connect(mapStateToProps, mapDispatchToProps)(OtpVerifyPopup);
