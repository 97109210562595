import React, { useEffect, useState } from 'react';
import 'Assets/style/table.css';
import 'Assets/style/PatientView.css';
import { GENDER_LABEL } from '../../../Constants/constant';
import { translate } from '../../../Language/Translate';
import {
  appendTime,
  formatDateInDDMMYYYY,
} from '../../../Utils/DateFormatter/Date';
import { mainLogoUrl } from 'Utils/Branding/logoGenerator';
import { connect, useSelector } from 'react-redux';
import { removeDrPrefix } from 'Utils/StringFormatter/String';

const ViewPrescription = (props) => {
  const {
    medicines = [],
    advice = '',
    prescriptionNote,
    patientDetails,
    appointmentDetails,
    applicationData,
  } = props;
  const [logoUrl, setLogoUrl] = useState('');

  const subscriptionData = useSelector(
    (state) => state.SettingsReducer.subscriptionData
  );

  useEffect(() => {
    fetch(mainLogoUrl(subscriptionData))
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const data = reader.result;
          setLogoUrl(data);
        };
        reader.readAsDataURL(blob);
      })
      .catch(() => console.log('logo error'));
  }, [subscriptionData]);

  const { name, last_name, tele_country_code, phone, mrn, gender, patient_id } =
    patientDetails;

  const { doctor_name, appt_date, appt_start_time, dept_name } =
    appointmentDetails;

  return (
    <>
      <div className="container" id="divToPrint">
        <div className="row">
          <div className="col-6">
          <div>
            <div className="fs-14 fw-600 mt-1">Dr. {removeDrPrefix(doctor_name)}</div>
            <div className="fs-11 fw-500 text-capitalize" style={{ color: '#838383' }}>
              {dept_name}
            </div>
          </div>
          </div>
          <div className="col-6 d-flex justify-content-end position-relative">
          <img className='pdf-logo d-block'
              src={logoUrl}
              alt="logo"
              style={{
                maxWidth: 130,
                height: 47,
                objectFit: 'contain',
                position: 'relative',
              }}
            />
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-6">
            <div className="fs-14 fw-600 mt-1">{`${name} ${last_name}`}</div>
            <div className="fs-11 fw-500" style={{ color: '#838383' }}>
              {applicationData.use_system_generated_mrn ? (
                <>{translate('patientPortal.patient_id', 'Patient ID')} : </>
              ) : (
                <>
                  {translate('patientPortal.mrnHealthId', 'mrn / Health Id')} :{' '}
                </>
              )}
              {applicationData.use_system_generated_mrn
                ? patient_id
                : mrn
                ? mrn
                : '-'}
            </div>
            <div className="fs-11 fw-500" style={{ color: '#838383' }}>
              {translate('appointments.gender', 'Patient Id')} :{' '}
              {GENDER_LABEL[gender]}
            </div>
            {phone && (
              <div className="fs-11 fw-500" style={{ color: '#838383' }}>
                {translate('patientView.phone', 'Phone')}:{' '}
                {`+${tele_country_code}${phone}`}
              </div>
            )}
          </div>
          <div className="col-md-6">
            <div
              className="fs-11 fw-500 mt-4"
              style={{ color: '#838383', textAlign: 'end' }}
            >
              Appt. {translate('patientView.date', 'Date')} :{' '}
              {formatDateInDDMMYYYY(appt_date)} {appendTime(appt_start_time)}
            </div>
          </div>
        </div>
        {prescriptionNote && (
          <div
            style={{ marginBottom: 20, marginTop: 20, whiteSpace: 'pre-line' }}
          >
            <div className="fs-11 fw-500" style={{ color: '#616161' }}>
              {translate('patientView.note', 'Note')} : <br />{' '}
              {prescriptionNote}
            </div>
          </div>
        )}
        {medicines?.[0]?.medicineName && (
          <table className="table mt-4 pdf-table">
            <thead>
              <tr>
                <th className="priscription-border"></th>
                <th className="fs-12 fw-600 priscription-border">
                  {translate('patientView.medicineName', 'Medicine Name')}
                </th>
                <th className="fs-12 fw-600 priscription-border">
                  {translate('patientView.dosage', 'Dosage')}
                </th>
                <th className="fs-12 fw-600 priscription-border">
                  {translate('patientView.duration', 'Duration')}
                </th>
              </tr>
            </thead>
            <tbody>
              {medicines.map((medicine, index) => (
                <tr key={index} style={{pageBreakInside: 'avoid'}}>
                  <td className="fs-11 fw-500 priscription-border">
                    {index + 1}
                  </td>
                  <td className="fs-11 fw-500 priscription-border">
                    {medicine['medicineName']}
                  </td>
                  <td className="fs-11 fw-500 mt-4 priscription-border">
                    {medicine['dosage']}
                  </td>
                  <td className="fs-11 fw-500 mt-4 priscription-border">
                    {medicine['duration']}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {advice && (
          <div
            style={{ marginBottom: 20, marginTop: 20, whiteSpace: 'pre-line' }}
          >
            <div className="fs-11 fw-500" style={{ color: '#616161' }}>
              {translate('patientView.advices', 'Advice')} : <br /> {advice}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  applicationData: state.SettingsReducer.applicationData,
});

export default connect(mapStateToProps)(ViewPrescription);
