export const truncateString = (str, maxLength = 20) => {
  if (!str || typeof str !== 'string') {
    return '';
  }

  return str.length > maxLength ? `${str.substring(0, maxLength - 1)}...` : str;
};

export const removeDrPrefix = (name) => {
  if (name?.toLowerCase()?.startsWith('dr. ')) {
    return name.substring(4);
  }
  return name;
};

export const hasDrPrefix = (name) => {
  if (name?.toLowerCase()?.startsWith('dr.')) {
    return true;
  }
  return false;
};

export const isDrPrefix = (name) => {
  if (name?.toLowerCase() === 'dr.' || name?.toLowerCase() === 'dr. ') {
    return true;
  }
  return false;
};

export const listAllDoc = (val) => {
  if (val?.toLowerCase() === 'd' || val?.toLowerCase() === 'dr' || isDrPrefix(val)) {
    return true;
  }
  return false;
} 