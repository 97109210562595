import React, { useEffect, useRef } from 'react';
import { translate } from '../../../Language/Translate';
import { Tooltip } from '@mui/material';
import PhoneNumberInput from '../../Common/PhoneInput';
import AntDatePicker from '../../Common/AntdDatePicker';
import moment from 'moment';
import {
  BLOOD_GROUP_OPTIONS,
  RELATIONS,
  RELATIONS_LABEL,
} from '../../../Constants/constant';
import { connect } from 'react-redux';
import { validateToken } from 'Actions/patientProfileAction';
import { formatDate, timeZoneDate } from '../../../Utils/DateFormatter/Date';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import { useIntl } from 'react-intl';
import ButtonLoader from 'Components/Common/ButtonLoader';
import { validateMobile } from 'Utils/Validation/validator';
import InfoIcon from '../../../Assets/img/icons/info-icon-black.svg';

const NewPatient = (props) => {
  const {
    invalidAlert,
    setInvalidAlert,
    modalShow,
    linkProfileDetails,
    setLinkProfileDetails,
    setShowOtp,
    handleProfileSave,
    defaultCountryCode,
    isBtnLoading,
    numberLength,
    setNumberLength,
    code,
    setCode,
    firstnameRef,
    applicationData
  } = props;
  const intl = useIntl();
  const numField = useRef();
  const focusRef =useRef();

  useEffect(() => {
    if (!modalShow) {
      resetHandle();
      // setOtp('');
      // setOtpVerified('');
    }
  }, [modalShow]);

  // handle input change
  const inputHandle = (e) => {
    const { name, value } = e.target
    // const regex = /^\+?[0-9 ]*\.?[0-9 ]*$/;
    const regex = /^[0-9]*$/;
    if (name === 'phno') {
      if(!value ||(!regex.test(value)|| validateMobile(value,code))){
        setInvalidAlert((prev) => ({
          ...prev,
          mobileValidErr: false,
        }));
      }
      if (regex.test(value)) {
        setLinkProfileDetails({
          ...linkProfileDetails,
          [e.target.name]: e.target.value,
        });
        setInvalidAlert((prev) => ({
          ...prev,
          [e.target.name]: false,
        }));
      }
    } else {
      setLinkProfileDetails({
        ...linkProfileDetails,
        [e.target.name]: e.target.value,
      });
      setInvalidAlert((prev) => ({
        ...prev,
        [e.target.name]: false,
      }));
    }
  };
  const handleConsent = (e) => {
    if(e.key ==="Enter"){
      setLinkProfileDetails({
        ...linkProfileDetails,
        [e.target.name]: !e.target.checked,
      });
    }
    else
    {
      setLinkProfileDetails({
        ...linkProfileDetails,
        [e.target.name]: e.target.checked,
      });
    }
    
    setInvalidAlert((prev) => ({
      ...prev,
      [e.target.name]: false,
    }));
  };

  const handleChangeOption = (option) => {
    setLinkProfileDetails({
      ...linkProfileDetails,
      blood_group: option,
    });
  };

  const handleChangeRelation = (option) => {
    setLinkProfileDetails({
      ...linkProfileDetails,
      relation: option,
    });
    setInvalidAlert((prev) => ({
      ...prev,
      relation: false,
    }));
  };

  // handle gender change
  const genderHandle = (e) => {
    setInvalidAlert((prev) => ({
      ...prev,
      gender: false,
    }));
    setLinkProfileDetails({ ...linkProfileDetails, gender: e });
  };

  // handle dob change
  const handleDobChange = (date) => {
    setLinkProfileDetails({
      ...linkProfileDetails,
      dob: formatDate(date),
      age: `${getAge(formatDate(date))}`,
    });
    setInvalidAlert((prev) => ({
      ...prev,
      dob: false,
    }));
  };
  const getAge = (birthDate) =>
    Math.floor((timeZoneDate() - new Date(birthDate).getTime()) / 3.15576e10);

  // handle country code channge
  const handleMobileNumberChange = (phone) => {
    setLinkProfileDetails({
      ...linkProfileDetails,
      country: phone,
    });
  };

  // handle reset click
  const resetHandle = () => {
    setLinkProfileDetails({
      country: defaultCountryCode.toString(),
      relation: { label: translate('relation.other', 'Other'), value: RELATIONS.other },
    });
    setInvalidAlert({});
    setShowOtp(false);
  };

  // handle profile save

  // disable futeure dates for ant datepicker
  const disableFutureDates = (current) => {
    return current && current > moment();
  };

  return (
    <div
      className={'tab-pane fade show active'}
      id="new-patient-tab"
      role="tabpanel"
    >
      {/* patient data */}
      <div className="set-1">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-4">
              <label htmlFor="fName">
                {translate('common.firstName', 'First Name')}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                id="fName"
                name="fname"
                type="text"
                placeholder="i.e, John"
                className={`input w-100 ${invalidAlert.fname ? 'input-err' : ''
                  } `}
                value={linkProfileDetails.fname ?? ''}
                defaultValue=""
                maxLength="40"
                onChange={(e) => inputHandle(e)}
                ref={firstnameRef}
                autoFocus
              />
              <span className={`${invalidAlert.fname ? 'err' : 'd-none'}`}>
                {translate('common.required', 'This field is required')}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-4">
              <label htmlFor="lName">
                {translate('common.lastName', 'Last Name')}
              </label>
              <input
                id="lName"
                name="lName"
                placeholder="i.e, John"
                type="text"
                className={`input w-100`}
                defaultValue=""
                maxLength="40"
                value={linkProfileDetails.lName ?? ''}
                onChange={(e) => inputHandle(e)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-4">
              <label htmlFor="date">
                {translate('patientPortal.dob', 'Date of Birth')}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <div className="icon-field date">
                <AntDatePicker
                  onInputChange={handleDobChange}
                  value={linkProfileDetails?.dob}
                  disabledDate={disableFutureDates}
                  popupClassName="link_profile_date_picker"
                  placeholder="DD/MM/YYYY"
                  className={`${invalidAlert.dob ? 'input-err' : ''}`}
                />
                <span className={`${invalidAlert.dob ? 'err' : 'd-none'}`}>
                  {translate('common.required', 'This field is required')}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-6">
                <div className="form-group mb-4">
                  <label htmlFor="age">
                    {translate('patientPortal.ageinYears', 'Age (Years)')}
                  </label>
                  <input
                    disabled
                    id="age"
                    name="age"
                    placeholder="0"
                    type="text"
                    maxLength={3}
                    value={linkProfileDetails.age ?? ''}
                    defaultValue=""
                    className="input w-100"
                  // onChange={(e) => inputHandle(e)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-4">
                  <label htmlFor="bloodGroup">
                    {translate('common.bloodGroup', 'Blood Group')}
                  </label>
                  <div className="select min-height-dr v2">
                    <ReactSelectDropDown
                      options={BLOOD_GROUP_OPTIONS}
                      value={linkProfileDetails.blood_group}
                      onInputChange={handleChangeOption}
                      targetBody={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="set-2">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-4">
              <label htmlFor="id">
              {applicationData?.use_system_generated_mrn ? translate('patientPortal.patient_id', 'Patient ID') : translate(
                          "patientPortal.mrnHealthId",
                          "mrn / Health Id"
                        )}
              </label>
              <input
                disabled
                id="id"
                type="text"
                placeholder="i.e, TC0001"
                className="input w-100"
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="form-group mb-4">
              <label htmlFor="relation">
                {translate('patientPortal.relation', 'Relation')}
                {/* <span style={{ color: 'red' }}>*</span> */}
              </label>
              <div className="min-height-dr">
                <ReactSelectDropDown
                  label={`${intl.formatMessage({
                    id: 'common.select',
                    defaultMessage: 'select',
                  })}`}
                  customClass={`${invalidAlert.relation ? 'custom-dr-err' : ''
                    }`}
                  emptyClass
                  value={linkProfileDetails.relation ?? ''}
                  options={RELATIONS_LABEL}
                  onInputChange={handleChangeRelation}
                  targetBody={false}
                />
              </div>
              <span className={`${invalidAlert.relation ? 'err' : 'd-none'}`}>
                {translate('common.required', 'This field is required')}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-4">
              <span className="label">
                {translate('patientPortal.gender', 'Gender')}
                <span style={{ color: 'red' }}>*</span>
              </span>
              <div
                className={`btn-group ${invalidAlert.gender ? 'radio-group-err' : ''
                  }`}
                role="group"
                aria-label="Basic radio toggle button group"
              >             
                <input
                  type="radio"
                  className="btn-check"
                  name="btnradio"
                  id="btnradio1"
                  autoComplete="off"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      genderHandle('male')
                    }
                  }}
                  checked={linkProfileDetails.gender === 'male'}
                />
                <label
                  className="btn btn-black"
                  htmlFor="btnradio1"
                  onClick={() => genderHandle('male')}
                >
                  {translate('common.male', 'Male')}
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="btnradio"
                  id="btnradio2"
                  autoComplete="off"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      genderHandle('female')
                    }
                  }}
                  checked={linkProfileDetails.gender === 'female'}
                />
                <label
                  className="btn btn-black"
                  htmlFor="btnradio2"
                  onClick={() => genderHandle('female')}
                >
                  {translate('common.female', 'Female')}
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="btnradio"
                  id="btnradio3"
                  autoComplete="off"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      genderHandle('other')
                    }
                  }}
                  checked={linkProfileDetails.gender === 'other'}
                />
                <label
                  className="btn btn-black"
                  htmlFor="btnradio3"
                  onClick={() => genderHandle('other')}
                >
                  {translate('common.other', 'Others')}
                </label>
              </div>
              <span className={`${invalidAlert.gender ? 'err' : 'd-none'}`}>
                {translate('common.genderRequired', 'Please select gender')}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-4">
              <label htmlFor="phno" className="lable">
                {translate('patientPortal.mobileNo', 'Mobile Number')} {'('}
                {translate('common.optional', 'Optional')}
                {')'}
                {" "}
                <Tooltip
                        placement="top"
                        enterTouchDelay={0}
                        leaveTouchDelay={7000}
                        title={translate(
                          "patients.linkedNumber",
                          "If the Linked patient's mobile number is not provided then the main account holder's mobile number will be considered."
                        )}
                      >
                        <img className='lable-info-img' src={InfoIcon} />
                      </Tooltip>
              </label>
              <div className="phone-field gap-3">
                <span 
                onKeyDown={(e) => {
                if (e.key === "Tab" && !e.shiftKey)
                {
                  e.preventDefault()
                  numField.current.focus()
                } 
              }}>
                <PhoneNumberInput
                  value={linkProfileDetails.country}
                  onChange={handleMobileNumberChange}
                  validationErr={invalidAlert?.country}
                  numberLength={numberLength}
                  setNumberLength={setNumberLength}
                  focusRef={focusRef}
                  code={code}
                  setCode={setCode}
                />
                </span>
                <div>
                <input
                  type="tel"
                  id="phno"
                  name="phno"
                  placeholder="i.e, 98950 98950"
                  className={`form-control grey input ${invalidAlert.phno || invalidAlert.mobileValidErr ? 'input-err' : ''
                    } `}
                  value={linkProfileDetails.phno ?? ''}
                  defaultValue=""
                  maxLength={numberLength}
                  ref={numField}
                  onChange={(e) => inputHandle(e)}
                  onKeyDown={(e)=>{
                    if(e.key === "Tab" && e.shiftKey){
                      e.preventDefault()
                      focusRef.current.focus()
                    }
                  }}
                />
                <span className={`${invalidAlert?.country ? 'err' : 'd-none'}`}>
                {translate('common.required', 'This field is required')}
              </span>
              <span className={`${invalidAlert?.mobileValidErr ? 'err' : 'd-none'}`}>
              {translate(
                          'common.invalidPhone',
                          'Invalid mobile number.'
                        )}
              </span>
              </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="d-flex align-items-center gap-3 form-group ">
              <input
                className={`form-check-input ${invalidAlert.checkbox ? 'cbox-err' : ''
                  }`}
                type="checkbox"
                id="consent"
                name="checkbox"
                onChange={(e) => handleConsent(e)}
                onKeyDown={(e)=>{handleConsent(e)}}
                checked={linkProfileDetails.checkbox}
              />
              <div>
                <label htmlFor="consent" className="pb-0">
                  {translate(
                    'patientPortal.consentAgree',
                    'I have consent to book on behalf of this patient.'
                  )}
                  <span style={{ color: 'red' }}>*</span>
                </label>
              </div>
            </div>
            <span className={`${invalidAlert.checkbox ? 'err' : 'd-none'}`}>
              {translate(
                'checkboxReq.required',
                'Please allow consent before proceeding'
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-3 footer-btns">
        <button
          id="reset"
          className="btn btn-outlined-dark px-sm-5"
          onClick={resetHandle}
        >
          {translate('common.resetData', 'Reset')}
        </button>
        <button
          id="save"
          className="btn btn-primary px-sm-5 loader-btn"
          onClick={handleProfileSave}
          onKeyDown={(e)=>{
            if(e.key==="Tab" && !e.shiftKey) e.preventDefault()
          }}
          disabled={isBtnLoading}
        >
          {isBtnLoading ? (
            <ButtonLoader
              text={translate('common.saveVerify', 'Save & Verify')}
            />
          ) : (
            translate('common.saveVerify', 'Save & Verify')
          )}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  applicationData: state.SettingsReducer.applicationData,
});
const mapDispatchToProps = (dispatch) => ({
  verifyOtp: (data, callback, errCallBack) =>
    dispatch(validateToken(data, callback, errCallBack)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPatient);
