import React
, { useEffect }// { useEffect }
  from 'react';
import '../../Assets/style/loader.css';
import '../../Assets/style/table.css';
const Loader = ({heightLoader = false, disableScroll=true}) => {
  useEffect(()=>{
    if(disableScroll){
      document.body.style.overflow = "hidden"
      return()=>{
        document.body.style.overflow = ""
      }
    }
  },[])
  return (
    <>
      <div className="loader" id="loader"
      style={{
        height:`${heightLoader ? heightLoader:'auto'}`
        
      }}
      >
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
      </div>
    </>
  );
};
export default Loader;
