import React from 'react';
// import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

const ColumnSort = (props) => {
  const { width, header, sortBy, value, setSortBy, allowSort } = props;

  const handleColumnSort = () => {
    const currentVal = sortBy[value];
    if (currentVal) {
      setSortBy({ [value]: currentVal === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortBy({ [value]: 'asc' });
    }
  };

  return (
    <>
      {allowSort ? (
        <th
          width={width}
          onClick={handleColumnSort}
          style={{ cursor: 'pointer' }}
        >
          <div className="d-flex align-items-center gap-1">
            {header}
            <span className="sort-container">
              <i
                className="bi bi-chevron-up"
                style={{ color: sortBy[value] === 'asc' ? '#000' : '#adadad' }}
              ></i>
              <i
                className="bi bi-chevron-down"
                style={{ color: sortBy[value] === 'desc' ? '#000' : '#adadad' }}
              ></i>
            </span>
          </div>
        </th>
      ) : (
        <th width={width}>{header}</th>
      )}
    </>
  );
};

export default ColumnSort;
