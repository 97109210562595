/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Tooltip } from '@mui/material';
import { translate } from 'Language/Translate';

const calenderIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 1.5V3.75" stroke="#7487A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 1.5V3.75" stroke="#7487A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.625 6.81747H15.375" stroke="#7487A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.75 12.75C15.75 15 14.625 16.5 12 16.5H6C3.375 16.5 2.25 15 2.25 12.75V6.375C2.25 4.125 3.375 2.625 6 2.625H12C14.625 2.625 15.75 4.125 15.75 6.375V12.75Z" stroke="#7487A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.99686 10.275H9.00359" stroke="#7487A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.22049 10.275H6.22723" stroke="#7487A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.22049 12.525H6.22723" stroke="#7487A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


const menuIcon = <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1" y="1" width="12" height="2.5" rx="1.25" stroke="#7487A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<rect x="1" y="6" width="12" height="2.5" rx="1.25" stroke="#7487A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<rect x="1" y="11" width="12" height="2.5" rx="1.25" stroke="#7487A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


const ViewSwitch = (props) => {
  const { active, handleViewModeChange } = props;
  return (
    <div className="button-wrap d-inline">
      <div className="fake-tabs">
        <Tooltip classes={{tooltip: 'after-left'}}
          title={translate('appointments.calenderView', 'Calender View')}
        >
          <button
            className={`selector ${active === 'menu' ? 'active' : ''}`}
            onClick={() => handleViewModeChange('menu')}
          >
            {calenderIcon}
          </button>
        </Tooltip>
        <Tooltip title={translate('appointments.tableView', 'Table View')}>
          <button
            className={`selector ${active === 'list' ? 'active' : ''}`}
            onClick={() => handleViewModeChange('list')}
          >
            {menuIcon}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default ViewSwitch;
