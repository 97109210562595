import React, { useRef } from 'react';
import { translate } from '../../../Language/Translate';
import { connect } from 'react-redux';
import { deLinkProfile } from 'Actions/patientProfileAction';
import ButtonLoader from 'Components/Common/ButtonLoader';

function DeLinkModal({
  closeDelinkModal,
  item,
  delinkAccount,
  getLinkedProfileList,
  isBtnLoading,
  setIsLoading,
}) {
const backBtnRef = useRef()
const accBtnRef = useRef()
  const handleDelink = () => {
    let data = {
      id: item.id,
      payload: {
        data: {
          force_delete: !item?.phone && !item.email ? true : false,
        },
      },
    };
    delinkAccount(data, handleDelinkSuccess, () => {}, item.patient_id);
  };
  const handleDelinkSuccess = () => {
    setIsLoading(true);
    getLinkedProfileList();
    closeDelinkModal();
  };
  return (
    <div className="text-center delink-content">
      <h5 className="fw-600">
        {translate('patientPortal.dLinkProfile', 'De-link Profile')}
      </h5>
      <p className="text-black">
        {translate(
          'patientPortal.areYouSure',
          'Are you sure you want to de-link this profile ?'
        )}
      </p>
      {!item?.phone && !item.email ? (
        <p className="text-black small">
          {translate(
            'patientPortal.areYouSureNoPhone',
            'This patient profile will be permanently deleted from the system as the phone number is not updated'
          )}
        </p>
      ) : null}

      <div className="confirmation-btns">
        <button
          type="button"
          className={`btn btn-outlined-dark ${isBtnLoading && 'loader-btn'}`}
          // data-bs-dismiss="modal"
          disabled={isBtnLoading}
          onClick={handleDelink}
          ref={accBtnRef}
          onKeyDown={(e)=>{
            e.preventDefault()
            if(e.key === "Tab") backBtnRef.current.focus()
          }}
        >
          {isBtnLoading ? (
            <ButtonLoader
              text={translate('patientPortal.yesDeLink', 'Yes, Delink Profile')}
            />
          ) : (
            translate('patientPortal.yesDeLink', 'Yes, Delink Profile')
          )}
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={closeDelinkModal}
          ref={backBtnRef}
          onKeyDown={(e)=>{
            e.preventDefault()
            if(e.key === "Tab") accBtnRef.current.focus()
          }}
        >
          {translate('patientPortal.noKeep', 'No, Keep Profile')}
        </button>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  isBtnLoading: state.ProfileReducer.isBtnLoading,
});

const mapDispatchToProps = (dispatch) => ({
  delinkAccount: (data, callBack, errCallBack, patientId) =>
    dispatch(deLinkProfile(data, callBack, errCallBack, patientId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeLinkModal);
