import React, { useEffect, useState } from "react";
import store from "store";
import { setSubscriptionData } from "Actions/settingsAction";
import { fetchCookiesWithSubscriptionDetails } from "Utils/Services/Cookies";
import UnIdentified from "Components/NotFound/UnIdentified";
import { clientCode, getSassBaseURL } from "Utils/Api/ApiInstance";
import { dateBetweenNotificationPeriod } from "Utils/DateFormatter/Date";
import ServerDown from "../../Components/NotFound/ServerDown";

const withSubscriptionData = (WrappedComponent) => {
  const WithSubscriptionData = (props) => {
    const [isDataLoaded, setIsDataLoaded] = useState({
      appnest: false,
      status: false,
    });
    const [isError, setIsError] = useState(false);
    const [unidentified, setUnidentified] = useState(false);

    useEffect(() => {
      fetchSubscriptionDetails();
      fetchCookiesWithSubscriptionDetails(
        true,
        // setUnidentified,
        setIsError,
        setIsDataLoaded
      );
    }, []);

    let org = window.location.host;
    if (org.includes("localhost"))
      org = `${clientCode}.telmed.d.feathersoft.org`;

    // get the subscription data from Appnest
    const fetchSubscriptionDetails = async () => {
      const subData = await fetch(
        `${getSassBaseURL()}/appnest/api/customer/subscription-details/${org}`
      )
        .then((response) => response.json())
        .then((data) => {
          const { timeZone = "" } = data?.data?.setting;
          localStorage.setItem("timeZone", timeZone);
          return data;
        })
        .catch(() => {
          setIsError(true);
        });
      // check whether to show the subscription ending banner
      let { showAlert } = dateBetweenNotificationPeriod(
        subData?.data?.subscription_end_date,
        30
      );
      await store.dispatch(
        setSubscriptionData({ ...subData?.data, showAlert })
      );
      if (subData?.data?.customer_id)
        setIsDataLoaded((prev) => ({ ...prev, appnest: true }));
      else setUnidentified(true);
    };

    return isError ? (
      <ServerDown />
    ) : unidentified ? (
      <UnIdentified />
    ) : isDataLoaded.appnest && isDataLoaded.status ? (
      <WrappedComponent {...props} />
    ) : (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "#ffff",
          zIndex: 99,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="stand_by_screen"
      >
        <span className="bubble_loader"></span>
      </div>
    );
  };
  return WithSubscriptionData;
};

export default withSubscriptionData;
