import React, { 
  // useState
 } from 'react';
// import { useIntl } from 'react-intl';
// import { translate } from '../../../Language/Translate';
// import PswInput from './PswInput';
import './style/ChangePassword.css';
// import { connect } from 'react-redux';
// import { fetchUserProfileDetails, updateUserPassword } from 'Actions/settingsAction';
// import PasswordStrengthChecker from 'Components/Settings/passwordProgress';

function ChangePassword(
  // { changePassword,profileDetails,getProfileDetails }
  ) {
  // const intl = useIntl();
  // const [passwordInfo, setPasswordInfo] = useState({
  //   currentPassword: '',
  //   newPassword: '',
  //   confirmNewPassword: '',
  // });
  // const [strongPassword, setStrongPassword] = useState(false);
  // const [passwordFieldError, setpasswordFieldError] = useState({
  //   currentError: false,
  //   newError: false,
  //   confirmError: false,
  // });
  // const [passwordError, setPasswordError] = useState('');
  // const { currentPassword, newPassword, confirmNewPassword } = passwordInfo;
  // const { currentError, newError, confirmError } = passwordFieldError;

  // // passwrod view
  // const [showEye, setShowEye] = useState({
  //   current: false,
  //   newPass: false,
  //   confirmPass: false,
  // });
  // const [showPass, setShowPass] = useState({
  //   current: false,
  //   newPass: false,
  //   confirmPass: false,
  // });
  // const { user_password_exists } = profileDetails;
  
  // const handlePswChange = (event) => {
  //   const { value, name } = event.target;
  //   if(user_password_exists){
  //   if (name === 'currentPassword') {
  //     setpasswordFieldError((prev) => ({
  //       ...prev,
  //       currentError: false,
  //     }));
  //     if (value && newPassword && confirmNewPassword) setPasswordError('');
  //   } else if (name === 'newPassword') {
  //     setpasswordFieldError((prev) => ({
  //       ...prev,
  //       newError: false,
  //     }));
  //     if (value && currentPassword && confirmNewPassword) setPasswordError('');
  //   } else if (name === 'confirmNewPassword') {
  //     setpasswordFieldError((prev) => ({
  //       ...prev,
  //       confirmError: false,
  //     }));
  //     if (value && newPassword && currentPassword) setPasswordError('');
  //   }
  //   setPasswordInfo((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // } else {
  //   if (name === 'newPassword') {
  //     setpasswordFieldError((prev) => ({
  //       ...prev,
  //       newError: false,
  //     }));
  //     if (value && confirmNewPassword) setPasswordError('');
  //   } else if (name === 'confirmNewPassword') {
  //     setpasswordFieldError((prev) => ({
  //       ...prev,
  //       confirmError: false,
  //     }));
  //     if (value && newPassword) setPasswordError('');
  //   }
  //   setPasswordInfo((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // }
  // };

  // //handle password success
  // const handlePasswordChangeSuccess = () => {
  //   setPasswordInfo({
  //     currentPassword: '',
  //     newPassword: '',
  //     confirmNewPassword: '',
  //   });
  //   setPasswordError('');
  //   setpasswordFieldError({
  //     currentError: false,
  //     newError: false,
  //     confirmError: false,
  //   });
  //   getProfileDetails()
  // };

  // // handle psw update
  // const handlePswUpdate = () => {
    
  //   if (user_password_exists?(!currentPassword || !newPassword || !confirmNewPassword):(!newPassword || !confirmNewPassword))
  //  {
  //     setPasswordError(
  //       translate(
  //         'common.required',
  //         'This field is required'
  //       )
  //     );
  //     setpasswordFieldError({
  //       currentError: !currentPassword,
  //       newError: !newPassword,
  //       confirmError: !confirmNewPassword,
  //     });
  //   } else if (newPassword !== confirmNewPassword) {
  //     setPasswordError(
  //       translate(
  //         'settings.passwordMatch',
  //         'New password and confirm password does not match.'
  //       )
  //     );
  //     setpasswordFieldError({
  //       currentError: false,
  //       newError: false,
  //       confirmError: false,
  //     });
  //   } else if (!strongPassword) {
  //     setPasswordError(
  //       translate(
  //         'settings.criteriaMatch',
  //         'Password does not match the criteria.'
  //       )
  //     );
  //     setpasswordFieldError({
  //       currentError: false,
  //       newError: false,
  //       confirmError: false,
  //     });
  //   } else {
  //     const config = {
  //       data: {
  //         current_password: currentPassword,
  //         credentials: {
  //           password: newPassword,
  //           confirm_password: confirmNewPassword
  //         }
  //       }
  //     }
  //     changePassword(config, handlePasswordChangeSuccess)
  //   }
  // };

  // const handleCancel = () => {
  //   setPasswordInfo({
  //     currentPassword: '',
  //     newPassword: '',
  //     confirmNewPassword: '',
  //   });
  //   setPasswordError('');
  //   setpasswordFieldError({
  //     currentError: false,
  //     newError: false,
  //     confirmError: false,
  //   });
  // };
  return (
    <>
      {/* <div className="section-title">
        <div>
          <h5>
            {translate('profileSetting.changePassword', 'Change Password')}
          </h5>
          <p className="small pt-1 mb-0">
            {translate(
              'profileSetting.manageInfo',
              'Manage your personal and contact information. Go to'
            )}{' '}
            <a href="#webConnect/profiles" className="fw-600 color-secondary">
              {translate('profileSetting.profile', 'Profile')}
            </a>
          </p>
        </div>
        <div className="btn-container d-flex">
          <button onClick={handleCancel} className="btn btn-outlined-dark">
            {translate('profileSetting.cancel', 'Cancel')}
          </button>
          <button
            type="button"
            onClick={handlePswUpdate}
            className="btn btn-primary"
          >
            {translate('profileSetting.update', 'Update')}
          </button>
        </div>
      </div>
      <div className="card form-card">
       {
         !user_password_exists &&
         <h6 className="mb-4">
         {translate('profileSetting.setPsw', 'Set Password')}
       </h6>
       } 
        {
          user_password_exists?  <div className="form-group">
          <div className="col-md-4">
            <label htmlFor="currentPassword" className="mandatory">
              {translate('profileSetting.currentPsw', 'Current Password')}
            </label>
            <div className="password-container">
              <PswInput
                name="currentPassword"
                password={currentPassword}
                id="current"
                handlePasswordChange={handlePswChange}
                validationErr={currentError}
                showEye={showEye}
                showPass={showPass}
                setShowEye={setShowEye}
                setShowPass={setShowPass}
                placeholder={intl.formatMessage({
                  id: 'profileSetting.entercurrentPsw',
                  defaulMessage: 'Enter Current Password',
                })}
              />
            </div>
            {currentError && (
                <div className="col-md-12 errorMessage">{passwordError}</div>
              )}
            </div>
        </div>:''
        }
        <div className="form-group">
          <div className="col-md-4">
            <label htmlFor="newPassword" className="mandatory">
              {translate('profileSetting.newPsw', 'New Password')}
            </label>
            <div className="password-container">
              <PswInput
                name="newPassword"
                password={newPassword}
                id="newPass"
                handlePasswordChange={handlePswChange}
                validationErr={newError || passwordError}
                showEye={showEye}
                showPass={showPass}
                setShowEye={setShowEye}
                setShowPass={setShowPass}
                placeholder={intl.formatMessage({
                  id: 'profileSetting.enternewPsw',
                  defaulMessage: 'Enter New Password',
                })}
              />
              <PasswordStrengthChecker
                password={newPassword}
                setStrongPassword={setStrongPassword}
              />
            </div>
          </div>
          {newError && (
          <div className="col-md-12 errorMessage">{passwordError}</div>
        )}
        </div>
        <div className="form-group">
          <div className="col-md-4">
            <label htmlFor="confirmNewPassword" className="mandatory">
              {translate('profileSetting.confirmPsw', 'Confirm Password')}
            </label>
            <div className="password-container">
              <PswInput
                name="confirmNewPassword"
                password={confirmNewPassword}
                id="confirmPass"
                handlePasswordChange={handlePswChange}
                validationErr={confirmError ||passwordError}
                showEye={showEye}
                showPass={showPass}
                setShowEye={setShowEye}
                setShowPass={setShowPass}
                placeholder={intl.formatMessage({
                  id: 'profileSetting.enterconfirmPsw',
                  defaulMessage: 'Enter Confirm Password',
                })}
              />
            </div>
            {confirmError && (
              <div className="col-md-12 errorMessage">{passwordError}</div>
            )}
          </div>
          {!confirmError && !newError && !currentError &&  passwordError && (
          <div className="col-md-12 errorMessage">{passwordError}</div>
        )}
        </div>
      </div> */}
    </>
  );
}
// const mapStateToProps = (state) => ({
//   profileDetails: state.SettingsReducer.profileDetails
// });
// const mapDispatchToProps = (dispatch) => ({
//   changePassword: (data, handleSuccess) => dispatch(updateUserPassword(data, handleSuccess)),
//   getProfileDetails: () => dispatch(fetchUserProfileDetails()),

// });
export default 
// connect(mapStateToProps, mapDispatchToProps)(
  ChangePassword
  // );
