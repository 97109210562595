/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { flatten } from 'flat';
import { connect } from 'react-redux';
import messages from './i18n/Index';
import { LOCALES } from './Locales';
import PropTypes from 'prop-types';

const LanguageProvider = ({ children, locale = LOCALES.ENGLISH }) => {
  return (
    <IntlProvider
      locale={locale || LOCALES.ENGLISH}
      messages={flatten(
        messages[locale] ? messages[locale] : messages[LOCALES.ENGLISH]
      )}
      textComponent={Fragment}
    >
      {children}
    </IntlProvider>
  );
};

const mapStateToProps = (state) => ({
  locale: state.SettingsReducer.locale,
});

LanguageProvider.propTypes = {
  children: PropTypes.elementType,
  locale: PropTypes.string,
};

export default connect(mapStateToProps, null)(LanguageProvider);
