import React, { useEffect, useState } from 'react';
import './MedicalRecords.css';
import AddMedicalRecord from './AddMedicalRecord';
import { translate } from '../../../Language/Translate';
import Paginator from './Paginator';
import ViewMedicalRecord from './ViewMedicalRecord';
import ReactSelectDropDown from '../../Common/ReactSelectDropDown';
import AntMultiDatePicker from '../Common/AntMultiDatePicker';
import '../Common/style/filter.css';
import '../Common/style/pagination.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getMedicalRecords,
  deleteMedicalRecords,
  getAllLinkedList,
  updateFilterOptions,
  clearAllMedicalRecordData,
  updateColumnSort,
} from '../../../Actions/medicalRecordAction';
import { formatDate } from '../../../Utils/DateFormatter/Date';
import ConfirmDeleteRecord from './ConfirmDeleteRecord';

import NoResultFound from '../Common/NoResultFound';
import ColumnSort from '../Common/ColumnSort';
import { useIntl } from 'react-intl';
import { saveAs } from 'file-saver';
import withPermissionCheck from 'Utils/WithUtils/WithPermission';
import { PATIENTRECORDS } from 'Constants/feature';
import { CREATE, DELETE, READ_DETAILS } from 'Constants/scopes';
import Loader from 'Components/Common/Loader';
import { Tooltip } from '@mui/material';
import { isEqual } from 'lodash';
import TableWrapper from 'Components/Common/TableWrpper';

function MedicalRecords(props) {
  const {
    filterOptions,
    setFilterOptions,
    getMedicalRecords,
    deleteMedicalRecord,
    getAllLinkedProfile,
    loginDetails = {},
    patientDetails = {},
    clearData,
    ACCESS: permission,
    linkedProfileLoading,
    setColumnSort,
    columnSort,
  } = props;

  // const [filterByName, setFilterByName] = useState();
  const [perPage, SetPerPage] = useState(25);
  const [pageTotal, setPageTotal] = useState(null);
  const [currentPage, SetCurrentPage] = useState(1);
  const [medicalRecordDetails, setMedicalRecordDetails] = useState([]);
  const [btnClick, setBtnClick] = useState(false);
  const [viewClick, setViewclick] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [recordId, setRecordId] = useState('');
  const [showMore, setShowMore] = useState({});
  const [filterState, setFilterState] = useState({});
  const [profileNames, setProfileNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  const {
    searchName = '',
    dateRange = [null, null],
  } = filterOptions;

  const {
    searchNameLoc = searchName,
    dateRangeLoc = dateRange,
  } = filterState;

  const userData = { ...loginDetails, ...patientDetails };
  let userName = userData?.last_name
    ? `${userData?.name} ${userData?.last_name}`
    : userData?.name
    ? userData?.name
    : `+${userData.tele_country_code}${userData.phone}`;
  let userOption = {
    label: userName,
    user_id: userData?.user_id,
    patient_id: userData?.patient_id,
    value: userData?.patient_id,
  };

  useEffect(() => {
    getAllLinkedProfile(mapProfileData);
    //uncomment if default value should be selected here
    if (!filterOptions?.searchName?.value) {
      // setFilterOptions({ ...filterOptions, filterByName: { label: translate('common.all', 'All'), value: '' } });
      setFilterState((state) => ({
        ...state,
        searchNameLoc: { label: translate('common.all', 'All'), value: '' }
      }));
    }
  }, [loginDetails]);

  useEffect(() => {
    return () => {
      clearData();
    };
  }, []);

  useEffect(() => {
    getAllMedicalRecords();
  }, [columnSort, perPage, currentPage, filterOptions]);

  const mapProfileData = ({ result }) => {
    let arr = [{ label: translate('common.all', 'All'), value: '' }, userOption];
    if (result?.linked_profile_names?.length) {
      let list = result?.linked_profile_names;
      list.map((val) => {
        let userName = val?.name
          ? val?.name
          : `+${val?.tele_country_code}${val?.phone}`;
        arr.push({
          label: userName,
          user_id: val?.user_id,
          patient_id: val?.patient_id,
          value: val?.patient_id,
        });
      });
    }
    setProfileNames(arr);
    // setFilterByOptions(arr);
    // updateFilterByName(arr[0])
  };
  
  const getAllMedicalRecords = (data = {}) => {
    setIsLoading(true);
    // let { startDate, endDate, filterByName } = filterOptions;
    const sortKey = Object.keys(columnSort)[0];
    let params = {
      // patient_id: data?.patient_id
      //   ? data?.patient_id :
      //   data?.patient_id === '' ? undefined
      //   : filterByName?.patient_id
      //   ? filterByName?.patient_id
      //   : undefined,
        patient_id: !searchName?.value ? undefined : searchName?.value,
        start_date: !dateRange[0] ? undefined : dateRange[0],
        end_date: !dateRange[1] ? undefined : dateRange[1],
      // start_date: Object.keys(data).includes('startDate')
      //   ? data?.startDate
      //   : startDate,
      // end_date: Object.keys(data).includes('endDate') ? data?.endDate : endDate,
      limit: data?.perPage ? data?.perPage : perPage,
      page: data?.currentPage ? data?.currentPage : currentPage,
      // order: "DESC",
      sort: columnSort[sortKey]
        ? columnSort[sortKey] === 'asc'
          ? sortKey
          : `-${sortKey}`
        : undefined,
    };
    getMedicalRecords(
      params,
      setMedicalRecordDetails,
      setPageTotal,
      setIsLoading
    );
  };

  const handleView = (id) => {
    setSelectedPatientId(id);
    setViewclick(true);
  };

  // handle delete success
  const handleDeleteSuccess = () => {
    setOpenDeleteModal(false);
    if (medicalRecordDetails?.length === 1 && currentPage > 1) {
      getAllMedicalRecords({ currentPage: currentPage - 1 });
      SetCurrentPage(currentPage - 1);
    } else {
      getAllMedicalRecords();
    }
  };

  const handleDelete = (id) => {
    deleteMedicalRecord(id, handleDeleteSuccess);
  };

  const handleCurrentPageChange = (pageNo) => {
    SetCurrentPage(pageNo);
  };

  const handleChangeDate = (e) => {
    // if (e?.length) {
    //   setFilterOptions({
    //     ...filterOptions,
    //     startDate: formatDate(e[0]),
    //     endDate: formatDate(e[1]),
    //   });
    //   // setStartDate(formatDate(e[0]));
    //   // setEndDate(formatDate(e[1]));
    // } else {
    //   setFilterOptions({ ...filterOptions, startDate: null, endDate: null });
    //   // setStartDate(null);
    //   // setEndDate(null);
    // }
    if (e) {
      setFilterState((state) => ({
        ...state,
        dateRangeLoc: [formatDate(e[0]), formatDate(e[1])],
      }));
    } else {
      setFilterState((state) => ({
        ...state,
        dateRangeLoc: [null, null],
      }));
    }
  };
  const onReset = () => {
    // let { startDate, endDate, filterByName } = filterOptions;
    // if (
    //   perPage !== 25 ||
    //   currentPage !== 1 ||
    //   startDate ||
    //   endDate ||
    //   filterByName?.patient_id !== profileNames[0]?.patient_id
    // ) {
    //   SetCurrentPage(1);
    //   SetPerPage(25);
    //   setFilterOptions({
    //     ...filterOptions,
    //     startDate: null,
    //     endDate: null,
    //     filterByName: { label: translate('common.all', 'All'), value: '' },
    //   });
    //   // setEndDate(null);
    //   // setStartDate(null);
    //   // setFilterByName(filterByOptions[0])
    //   getAllMedicalRecords({
    //     currentPage: 1,
    //     perPage: 25,
    //     endDate: null,
    //     startDate: null,
    //     patient_id: '',
    //   });
    //   setFilterOpen(false);
    // }

    if (perPage !== 25 ||
      currentPage !== 1 || !isEqual(filterOptions, filterState) || !isEqual({}, columnSort)) {
      setFilterState({ searchNameLoc: { label: translate('common.all', 'All'), value: '' } });
      SetCurrentPage(1);
      SetPerPage(25);
      setColumnSort({})
      setFilterOptions({ searchNameLoc: { label: translate('common.all', 'All'), value: '' } });
      setFilterOpen(false);
    }
  };
  const onSearch = () => {
    SetCurrentPage(1);
    // getAllMedicalRecords({ currentPage: 1, perPage: 25 });
    if (!isEqual(filterOptions, filterState)) {
      setFilterOptions({
        searchName: searchNameLoc,
        dateRange: dateRangeLoc,
      });
    }
    setFilterOpen(false);
  };
  // handle medical record download
  const handleDownload = (data) => {
    data?.length &&
      data.forEach((pdfData) => {
        saveAs(
          pdfData.link,
          `${pdfData.display_name}.${pdfData.extension}`,
          {}
        );
      });
  };
  const confirmDeleteRecord = (id) => {
    setOpenDeleteModal(true);
    setRecordId(id);
  };

  const toggleShowMore = (id) => {
    setShowMore((state) => ({
      ...state,
      [id]: !state[id],
    }));
  };

  useEffect(()=>{
   if(viewClick || openDeleteModal){
     document.activeElement.blur();
   }
  },[viewClick,openDeleteModal])
  return (
    <div>
      <div className="medical-records">
        <div className="bread-crumbs mb-lg-2">
          <div className="wrapper links">
            <a
              className="previous"
              href="#/webConnect"
              style={{ cursor: 'pointer' }}
            >
              {' '}
              {translate('medicalRecords.home', 'Home')}
            </a>
            <span> {`>`} </span>
            <a className="bread-crumb-cursor">
              {' '}
              {translate(
                'medicalRecords.medicalRecords',
                'Medical Records'
              )}{' '}
            </a>
          </div>
        </div>

        <div className="main-header">
          <div className="wrapper">
            <h1
              className="main-title"
              style={{ fontSize: '26px', fontWeight: '700' }}
            >
              {translate('medicalRecords.medicalRecords', 'Medical Records')}
            </h1>

            <button
              className="open-filter-btn"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              <i className="bi bi-funnel"></i>
            </button>
            <div className="fake-mobile-footer">
              {permission(CREATE) && (
                <button
                  className="btn btn-primary buttonClick"
                  aria-controls="addRecord"
                  onClick={() => setBtnClick(!btnClick)}
                >
                  {translate(
                    'medicalRecords.addMedicalRecord',
                    'Add Medical Record'
                  )}
                </button>
              )}
            </div>
            {/* {btnClick && ( */}
              <AddMedicalRecord
                btnClick={btnClick}
                setBtnClick={setBtnClick}
                getAllMedicalRecords={getAllMedicalRecords}
                filterByOptions={profileNames.slice(1)}
                filterOptions={filterOptions}
                linkedProfileLoading={linkedProfileLoading}
              />
            {/* )} */}
          </div>
        </div>
        <div className="medical-record-table">
          <div className="wrapper">
            <div
              id="common-filter"
              className={`common-filter ${filterOpen && 'opened'}`}
            >
              <button
                className="btn-close"
                onClick={() => setFilterOpen(!filterOpen)}
              >
                <i className="bi bi-x"></i>
              </button>
              <div className="filter-elements row">
                <div className="col-lg-3 field-container form-group">
                  <label htmlFor="name">
                    {translate(
                      'medicalRecords.filter',
                      'Filter by Patient Name'
                    )}
                  </label>
                  <div className="select">
                    <ReactSelectDropDown
                      id="filterbyname"
                      options={profileNames}
                      onInputChange={(item) => {
                        setFilterState((state) => ({
                          ...state,
                          searchNameLoc: item,
                        }));
                      }}
                      value={searchNameLoc}
                      isLoading={linkedProfileLoading}
                    />
                  </div>
                </div>
                <div className="col-lg-6 field-container date-field ">
                  <div className="form-group datePicker">
                    <label htmlFor="date">
                      {translate('medicalRecords.duration', 'Duration')}
                    </label>
                    <div className="date">
                      <AntMultiDatePicker
                        sx={{
                          padding: '9px',
                        }}
                        onchangeDate={handleChangeDate}
                        start={dateRangeLoc[0]}
                        end={dateRangeLoc[1]}
                      />
                    </div>
                  </div>
                  <div className="d-flex gap-3 btn-wrapper button-space ms-3">
                    <button
                      id="search"
                      className="btn btn-secondary fit-content"
                      onClick={onSearch}
                    >
                      {translate('patientPortal.search', 'Search')}
                    </button>
                    <button
                      id="reset"
                      className="btn btn-outlined-dark fit-content "
                      onClick={onReset}
                    >
                      {translate('patientPortal.reset', 'Reset')}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="filter-overlay"></div>
            {!isLoading && medicalRecordDetails.length ? (
              <TableWrapper data={medicalRecordDetails}>
                <div className="table-container">
                <table>
                  <thead>
                    <th width="80">
                      {translate('medicalRecords.slNo', 'Sl.No')}
                    </th>
                    <ColumnSort
                      width="200"
                      header={translate(
                        'medicalRecords.patientName',
                        'Patient Name'
                      )}
                      sortBy={columnSort}
                      allowSort={medicalRecordDetails.length > 1}
                      setSortBy={setColumnSort}
                      value="patient_name"
                    />
                    <th width="200">
                      {translate('medicalRecords.description', 'Description')}
                    </th>
                    <th width="350">{translate('medicalRecords.fileName', 'File Name')}</th>
                    <ColumnSort
                      width="150"
                      header={translate(
                        'medicalRecords.uploadDate',
                        'Upload Date'
                      )}
                      sortBy={columnSort}
                      allowSort={medicalRecordDetails.length > 1}
                      setSortBy={setColumnSort}
                      value="created_at"
                    />
                    <th width="140">{translate('medicalRecords.action', 'Action')}</th>
                  </thead>
                  <tbody>
                    {medicalRecordDetails.map((medicalRecord, index) => (
                      <tr key={index}>
                        <td style={{ width: 50 }}>
                          {(currentPage - 1) * perPage + index + 1}
                        </td>
                        <td>{medicalRecord.patientName}</td>
                        <td>
                          <>
                            {medicalRecord?.remarks?.slice(
                              0,
                              showMore[medicalRecord.id]
                                ? medicalRecord?.remarks.length
                                : 50
                            )}{' '}
                            {medicalRecord?.remarks?.length > 50 ? (
                              <span
                                onClick={() => toggleShowMore(medicalRecord.id)}
                                className="ml-1 fs-15 fw-500"
                                style={{ color: '#4bb691', cursor: 'pointer' }}
                              >
                                {showMore[medicalRecord.id]
                                  ? 'less'
                                  : 'more...'}
                              </span>
                            ) : null}
                          </>
                        </td>
                        <td>
                          {medicalRecord.files.length != 0 ? (
                            <div className='th-file-name d-flex gap-2'>
                              <span className="table-filename">
                                {' '}
                                {medicalRecord.files[0]?.display_name
                                  ? medicalRecord.files[0].display_name
                                  : medicalRecord.files[0]?.filename}
                              </span>
                              <span>
                                {medicalRecord.files?.length >= 2 ? (
                                <span
                                  role="button"
                                  // data-bs-toggle="dropdown"
                                  // aria-expanded="true"
                                  className="pointer extra-details text-nowrap"
                                  style={{ paddingBottom: 'unset' }}
                                >
                                  +{medicalRecord.files?.length - 1}{' '}
                                  {intl.formatMessage({
                                    id: 'common.more',
                                    defaultMessage: 'More',
                                  })}
                                </span>
                               ) : null}
                              <ul
                                className="dropdown-menu dropdown-menu-start custom-tooltip"
                                data-popper-placement="bottom-end"
                              >
                                {medicalRecord.files?.map((item, index) => (
                                  <li key={index}>
                                    <span
                                      className="dropdown-item small"
                                      // data-bs-toggle="offcanvas"
                                      data-bs-target="#editAccount"
                                      aria-controls="editAccount"
                                    >
                                      {item.display_name}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                              </span>
                            </div>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td>{medicalRecord.uploadDate}</td>
                        <td>
                          <div className="action-btns">
                            {permission(READ_DETAILS) && (
                              <Tooltip placement="top" title="View">
                              <button
                                onClick={() => handleView(medicalRecord.id)}
                              >
                                <i className="bi bi-eye"></i>
                              </button>
                              </Tooltip>
                            )}
                            <Tooltip placement="top" title="Download">
                            <button >
                              <i
                                className="bi bi-download"
                                onClick={() =>
                                  handleDownload(medicalRecord.files)
                                }
                              ></i>
                            </button>
                            </Tooltip>
                            {permission(DELETE) && (
                              <Tooltip placement="top" title="Delete">
                              <button
                                className="delete"
                                onClick={() =>
                                  confirmDeleteRecord(medicalRecord.id)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="15"
                                  fill="currentColor"
                                  className="bi bi-trash3"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                </svg>
                              </button>
                              </Tooltip>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              </TableWrapper>
            ) : isLoading ? (
              <div className="loader-wrap mb-4 mt-4">
                <Loader />
              </div>
            ) : (
              <div className="mt-4">
                <NoResultFound />
              </div>
            )}

            {/* {viewClick && ( */}
              <ViewMedicalRecord
                viewClick={viewClick}
                setViewclick={setViewclick}
                id={selectedPatientId}
                setSelectedPatientId={setSelectedPatientId}
              />
            {/* )} */}
            {
              <ConfirmDeleteRecord
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                handleDeleteRecord={() => handleDelete(recordId)}
              />
            }
            {medicalRecordDetails.length && !isLoading ? (
              <Paginator
                perPage={perPage}
                SetPerPage={SetPerPage}
                totalCount={pageTotal}
                SetCurrentPage={handleCurrentPageChange}
                currentPage={currentPage}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  filterOptions: state?.MedicalRecordReducer?.filterOptions,
  loginDetails: state.LoginReducer.loginDetails,
  patientDetails: state.LoginReducer.patientDetails,
  linkedProfileLoading: state.MedicalRecordReducer.linkedProfileLoading,
  columnSort: state.MedicalRecordReducer.columnSort,
});

const mapDispatchToProps = (dispatch) => ({
  clearData: () => dispatch(clearAllMedicalRecordData()),
  setFilterOptions: (data) => dispatch(updateFilterOptions(data)),
  getMedicalRecords: (data, setTableData, setPageTotal, setLoading) =>
    dispatch(getMedicalRecords(data, setTableData, setPageTotal, setLoading)),
  getAllLinkedProfile: (callBack, errCallBack) =>
    dispatch(getAllLinkedList(callBack, errCallBack)),
  deleteMedicalRecord: (data, getAllMedicalRecords, isSuccess) =>
    dispatch(deleteMedicalRecords(data, getAllMedicalRecords, isSuccess)),
  setColumnSort: (data) => dispatch(updateColumnSort(data)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissionCheck(PATIENTRECORDS)
)(MedicalRecords);
