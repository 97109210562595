import { CardMedia } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import img from 'Assets/img/icons/doctor-placeholder-avatar.svg';
import errorImg from '../../../Assets/img/patient-portal/error_img_placeHolder.png';
import { translate } from '../../../Language/Translate';
import {
  appendTime,
  dateinFormatDAYMonthDDYYYY,
} from '../../../Utils/DateFormatter/Date';
import { useIntl } from 'react-intl';
import Loader from 'Components/Common/Loader';
import { removeDrPrefix } from 'Utils/StringFormatter/String';
// import MedicalRecords from './MedicalRecords';

const AppointmentView = (props) => {
  const { open, onClose, tabIndex, data, onCancel, onRescedule, isLoading } =
    props;
  const history = useHistory();
  const cancelBtnRef = useRef()
  const reschuleBtnRef = useRef()
  const intl = useIntl();
  const [showMore, setShowMore] = useState(false);
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        document.body.classList.add('modal-overflow');
      }, 0);
    } else {
      document.body.classList.remove('modal-overflow');
    }
    return () => document.body.classList.remove('modal-overflow');
  }, [open]);
  const modalRef = useRef();
  const imgStatus = useRef(true);

  useEffect(() => {
    const listener = (event) => {
      if (!modalRef.current || modalRef.current.contains(event.target)) {
        return;
      }
      if (event.button === 0) onClose();
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      setShowMore(false);
    };
  }, [modalRef, onClose]);

  useEffect(() => {
    if (open) {
      const listener = (event) => {
        if (event.key === "Tab" && tabIndex === "upcoming" && document.activeElement.id !== "cancelBtn"){
          event.preventDefault();
          cancelBtnRef.current.focus();
        }
      };
      document.addEventListener('keydown', listener);

      return () => {
        document.removeEventListener('keydown', listener);
      };
    }
  }, [open,tabIndex]);

  const toggleShow = () => {
    setShowMore(!showMore);
  };

  // view prescription for completed appointments
  const handleViewPrescription = () => {
    const { record_id } = data?.appointment_details?.appointment_records[0];
    if (record_id) {
      history.push(`prescriptions/${record_id}`);
    }
  };

  return (
    <>
      <div
        className={`offcanvas-backdrop view-appointment-offcanvas fade ${
          open && 'show'
        }`}
      ></div>
      <div
        className={`offcanvas offcanvas-end custom-off-canvas view-schedule-canvas ${
          open && 'show'
        }`}
        tabIndex="-1"
        ref={modalRef}
      >
        <div className="offcanvas-header">
          <h5 id="viewScheduleLabel">
            {tabIndex === 'upcoming'
              ? translate('myAppointments.upcomingVisit', 'Upcoming Visit')
              : tabIndex === 'previous'
              ? translate('myAppointments.previousVisit', 'Previous Visit')
              : translate(
                  'myAppointments.cancelledAppointment',
                  'Cancelled Appoinment'
                )}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            onClick={onClose}
            tabIndex="0"
            onKeyDown={(e)=>{
              if(e.key === "Tab" && e.shiftKey)e.preventDefault()
            }}
          ></button>
        </div>
        {!isLoading ? (
          <div className="offcanvas-body">
            <div className="doc-profile">
              <CardMedia
                style={{
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
                component="img"
                image={data?.appointment_details?.doctor_profile_img ?? img}
                onError={(e) => {
                  if (imgStatus.current) {
                    e.target.src = errorImg;
                    imgStatus.current = false;
                  }
                }}
              />
              <div>
                <h5>Dr. {removeDrPrefix(data?.appointment_details?.doctor_name)}</h5>
                <p className="text-capitalize">{data?.appointment_details?.dept_name}</p>
                <span
                  className={`badge-common ${
                    data.appointment_details?.live_status === 'upcoming' &&
                    'badge-comfirmed'
                  } ${
                    data.appointment_details?.live_status === 'notattempted' &&
                    'badge-absent'
                  } ${
                    data.appointment_details?.live_status === 'completed' &&
                    'badge-completed'
                  } ${
                    data.appointment_details?.live_status === 'cancelled' &&
                    'badge-cancelled'
                  } `}
                >
                  <span className="round"></span>
                  <span>
                    {data.appointment_details?.live_status === 'cancelled'
                      ? translate('myAppointments.aptCancelled', 'Cancelled')
                      : data.appointment_details?.live_status === 'notattempted'
                      ? translate(
                          'myAppointments.notAttempted',
                          'Not Attempted'
                        )
                      : data.appointment_details?.live_status === 'completed'
                      ? translate('myAppointments.completed', 'Completed')
                      : translate(
                          'myAppointments.aptConfirmed',
                          'Your Appointment is confirmed'
                        )}
                  </span>
                </span>
              </div>
            </div>
            <div className="schedule-infos">
              <div className="mini-schedule-info">
                <div className="icon-container">
                  <i className="bi bi-calendar2-week"></i>
                </div>
                <div className="info">
                  <span>
                    {translate('common.date', 'Date')} &{' '}
                    {translate('common.time', 'Time')}
                  </span>
                  <p>
                    {' '}
                    {data.appointment_details?.appt_date
                      ? dateinFormatDAYMonthDDYYYY(
                          data.appointment_details?.appt_date,
                          intl
                        )
                      : ''}
                    {', '}
                    {appendTime(data.appointment_details?.appt_start_time)}
                  </p>
                </div>
              </div>
              <div className="mini-schedule-info mb-0">
                <div className="icon-container">
                  <i className="bi bi-person big"></i>
                </div>
                <div className="info">
                  <span>
                    {translate('myAppointments.bookFor', 'Booked for')}
                  </span>
                  <p>
                    {data.appointment_details?.patient_name}{' '}
                    <span className="fw-normal">
                      ({data.appointment_details?.appointment_id})
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="reason mb-4">
              <h6>
                {data.appointment_details?.live_status === 'cancelled'
                  ? translate(
                      'myAppointments.reasonForCancel',
                      'Reason for cancellation'
                    )
                  : translate(
                      'myAppointments.reason',
                      'Reason for Appointment'
                    )}
              </h6>
              <p>
                {(data.appointment_details?.live_status === 'cancelled'
                  ? data.appointment_details?.cancelation_reason
                    ? data.appointment_details?.cancelation_reason
                    : ' - '
                  : data.appointment_details?.reason
                  ? data.appointment_details?.reason
                  : ' - '
                )?.slice(
                  0,
                  showMore
                    ? (data.appointment_details?.live_status === 'cancelled'
                        ? data?.appointment_details?.cancelation_reason
                        : data?.appointment_details?.reason
                      )?.length
                    : 150
                )}

                {(data.appointment_details?.live_status === 'cancelled'
                  ? data?.appointment_details?.cancelation_reason
                  : data?.appointment_details?.reason
                )?.length > 150 && !showMore
                  ? '...'
                  : ''}
              </p>
              {(data.appointment_details?.live_status === 'cancelled'
                ? data?.appointment_details?.cancelation_reason
                : data?.appointment_details?.reason
              )?.length > 150 ? (
                <a
                  onClick={toggleShow}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Show{' '}
                  {showMore
                    ? translate('myAppointments.less', 'Less')
                    : translate('myAppointments.more', 'More')}
                </a>
              ) : (
                ''
              )}
            </div>
            {tabIndex === 'upcoming' ? (
              <div className="footer-btn-wrap gap-3 pt-2 mt-3">
                {onCancel && (
                  <button
                    className="btn btn-outlined-dark px-4"
                    onClick={() => onCancel()}
                    id="cancelBtn"
                    onKeyDown={(e)=>{
                      if(e.key === "Enter" && !e.shiftKey){ 
                        e.stopPropagation();
                        e.preventDefault();
                        onCancel()
                      }
                      else if (e.key === "Tab" && !e.shiftKey){
                        e.preventDefault();
                        e.stopPropagation();
                        reschuleBtnRef.current.focus()
                      }
                    }}
                    // autoFocus
                    ref={cancelBtnRef}
                  >
                    {translate('myAppointments.cancel', 'Cancel Appointment')}
                  </button>
                )}
                {onRescedule && (
                  <button className="btn btn-secondary" 
                  onKeyDown={(e)=>{
                    e.preventDefault()
                    e.stopPropagation();
                    if(e.key === "Enter" && !e.shiftKey)
                    { onRescedule()}
                    else if (e.key === "Tab"){
                      cancelBtnRef.current.focus()
                    }
                  }}
                  onClick={onRescedule}
                  ref={reschuleBtnRef}
                  >
                    {translate(
                      'myAppointments.reschedule',
                      'Reschedule Appointment'
                    )}
                  </button>
                )}
              </div>
            ) : // ) : data.appointment_details?.live_status === 'completed' ? (
            //   <MedicalRecords
            //     prescriptions={data?.appointment_details?.appointment_records}
            //     labRecords={data?.lab_reports}
            //   />
            data?.appointment_details?.appointment_records?.length ? (
              <div className="mt-4 pt-1" onClick={handleViewPrescription}>
                <button className="btn btn-outlined-dark px-3">
                  <span className="px-1">View Prescription</span>
                </button>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="loader-wrap mb-4">
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

export default AppointmentView;
