import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";

function CustomRadioButton({ checked, handleChange, label}) {
  return (
    <div>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          <FormControlLabel
            style={{ fontSize: 10 }}
            value="female"
            control={
              <Radio
                checked={checked}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            }
            label={label}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default CustomRadioButton;
