import React, { useEffect } from 'react';
import '../../../Assets/style/conformPopup.css';
import { translate } from '../../../Language/Translate';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import infoIcon from 'Assets/img/icons/info-icon.svg';
import ButtonLoader from 'Components/Common/ButtonLoader';
import FocusTrap from 'focus-trap-react'


const NotifyConfirmPopup = (props) => {
    const { onConfirm, onClose, isBtnLoading = false } = props;
    useEffect(() => {
        document.body.classList.add('modal-overflow');
        return () => document.body.classList.remove('modal-overflow');
    }, []);

    return (
        <>
         <FocusTrap
     focusTrapOptions={{
      clickOutsideDeactivates:true
    }}>
            <div className="AppointmentModal d-flex">
                <div
                    className={`modalContent leaveConfirm_popup modal_xs`}
                >
                    <div className="modalHeader">
                        <div className="row">
                            <div className="col-md-12">
                                <h4>
                                    {translate(
                                        'appointments.notifyPatients',
                                        'Notify Patients'
                                    )}
                                    <CloseIcon className='close-icon'
                                        style={{ float: 'right', cursor: 'pointer', color: '#75808E' }}
                                        onClick={onClose}
                                    />
                                </h4>
                            </div>
                            <div className="col-md-12">
                                <div className="border_btm"></div>
                                <div
                                    className="modalContentarea-popup pb-0"
                                    style={{
                                        maxHeight: 430,
                                        marginBottom: 10,
                                    }}
                                >
                                    <div className="mb-3">
                                        <div className="modalContentarea-popup">
                                        <img className='centered-main-img' src={infoIcon} />
                                            <div className="justify-content-center text-center">
                                                <div style={{ marginBottom: 5, marginTop: 5 }}>
                                                    <p>
                                                        {translate(
                                                            'appointments.notifyMsg',
                                                            'Are you sure you want to notify the selected patients to reschedule their appointments due to the doctors leave?'
                                                        )}{' '}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="modal_footer">
                                            <div className="">
                                                <div className="row mt-1">
                                                    <div className="col-md-12">
                                                        <ul className="modal-footer-btns modalBtm-popup d-flex align-items-center justify-content-center">
                                                            <li onClick={onClose}>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-light fit-content px-4"
                                                                    id="cancel"
                                                                >
                                                                    {' '}
                                                                    {translate('common.cancel', 'CANCEL')}
                                                                </button>
                                                            </li>
                                                            <li onClick={isBtnLoading ? () => { } : onConfirm}>
                                                                {' '}
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-primary fit-content px-4 loader-btn`}
                                                                    id="save"
                                                                    disabled={isBtnLoading}
                                                                >
                                                                    {isBtnLoading ? <ButtonLoader text={translate('appointments.sendNotification', 'Send Notification')}/>
                                                                        : translate('appointments.sendNotification', 'Send Notification')
                                                                    }
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </FocusTrap> 
        </>
    );
};

NotifyConfirmPopup.prototype = {
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
};

export default NotifyConfirmPopup;
