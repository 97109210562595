import Network from './Network';

const getReminderDetails = async () => {
  let response = null;
  let error = null;
  await Network.get({
    url: '/notification-timing'
  }).then((res) => {
    const { isError, result, message } = res;
    if (isError) {
      error = message;
    } else {
      response = result;
    }
  });
  return { error, response };
};


export const SystemSettingsApi = {
  getReminderDetails
};
