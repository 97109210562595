/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react';
// import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import ScheduleIcon from 'Assets/img/icons/customTime.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';
import '../../../Assets/style/table.css';
import PopupWrapeer from '../../Common/PopupWrapeer';
import {
  LEAVE_APPROVAL_HEADERS,
  LEAVE_CONCAT_STRING,
  LEAVE_HEADERS,
  LEAVE_LIST_HEADERS,
  LEAVE_STATUS,
  LEAVE_STATUS_NAMES,
  LEAVE_STATUS_OPTIONS,
  LEAVE_TYPES,
  LEAVE_TYPE_NAMES,
} from '../../../Constants/constant';
import HeaderFields from '../../Common/HeaderFeilds';
import Pagination from '../../Common/Pagination';
import Loader from '../../Common/Loader';
import AddLeaves from './AddLeave';
import ConformPopup from '../../Common/ConformPopup';
import ReactSelectDropDown from '../../Common/ReactSelectDropDown';
import ConformLeavePopup from './ConfirmLeave';
import AppointmentConflictPopup from './ApptConflictPopup';
import { connect } from 'react-redux';
import { showToaster, ToasterTypes } from '../../../Actions/toasterAction';
import { FetchDoctorList } from '../../../Actions/departmentAction';
import { DepartmentsApi } from '../../../Utils/Api/DepartmentApi';
import {
  addLeave,
  deleteLeave,
  getLeave,
  updateLeave,
  updateLeaveStatus,
  updateSearchData,
  updatePerPage,
  updateCurrentPage,
  updateColumnSort,
} from '../../../Actions/LeaveActions';
import {
  compareTimes,
  dateInFormat,
  formatDate,
  isDateBefore,
  timeZoneDate,
} from '../../../Utils/DateFormatter/Date';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { translate } from '../../../Language/Translate';
import { DoctorsApi } from '../../../Utils/Api/DoctorsApi';
import { compose } from 'redux';
import withPermissionCheck from '../../../Utils/WithUtils/WithPermission';
import { USER_TYPE } from '../../../Constants/userTypes';
import TableColumnHr from 'Components/Common/TableColumnHr';
import usePrevious from 'Hooks/usePrevious';
import useDidMountEffect from 'Hooks/useDidMountEffect';
import { isEqual } from 'lodash';
import AntMultiDatePicker from 'Components/Patient_Portal/Common/AntMultiDatePicker';
import {
  APPLY_LEAVE,
  APPROVE_LEAVE,
  DELETE_LEAVE,
  MODIFY_LEAVE,
} from 'Constants/scopes';
import { DOCTOR } from 'Constants/feature';
import NoResultFound from 'Components/Common/NoResult';
import { listAllDoc, removeDrPrefix } from 'Utils/StringFormatter/String';
import TableWrapper from 'Components/Common/TableWrpper';
import LightTooltip from 'Components/Common/LightTooltip';

const chatIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 11.875C2.5 11.875 1.25 11.25 1.25 8.125V5C1.25 2.5 2.5 1.25 5 1.25H10C12.5 1.25 13.75 2.5 13.75 5V8.125C13.75 10.625 12.5 11.875 10 11.875H9.6875C9.49375 11.875 9.30625 11.9687 9.1875 12.125L8.25 13.375C7.8375 13.925 7.1625 13.925 6.75 13.375L5.8125 12.125C5.7125 11.9875 5.48125 11.875 5.3125 11.875H5Z" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.375 5H10.625" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.375 8.125H8.125" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

let apiTimeout = null;

const deleteIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.1085 3.7371C11.0299 3.5311 8.93875 3.42499 6.85388 3.42499C5.61793 3.42499 4.38199 3.48741 3.14604 3.61225L1.87264 3.7371" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.30582 3.10625L5.44315 2.2875C5.54303 1.69375 5.61793 1.25 6.67285 1.25H8.3083C9.36322 1.25 9.44437 1.71875 9.538 2.29375L9.67533 3.10625" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.7665 5.71252L11.3608 12.0063C11.2921 12.9875 11.2359 13.75 9.49435 13.75H5.48689C3.74533 13.75 3.68915 12.9875 3.62049 12.0063L3.21475 5.71252" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.44814 10.3125H8.52677" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.93004 7.8125H9.05112" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const editIcon = <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.72757 7.68029C3.53419 7.88654 3.34706 8.29279 3.30963 8.57404L3.07883 10.599C2.99774 11.3303 3.52172 11.8303 4.24531 11.7053L6.25388 11.3615C6.53458 11.3115 6.92757 11.1053 7.12094 10.8928L12.2422 5.46154C13.128 4.52404 13.5272 3.45529 12.1486 2.14904C10.7763 0.855287 9.73458 1.31154 8.84881 2.24904L3.72757 7.68029Z" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.99438 3.15625C8.26261 4.88125 9.65988 6.2 11.394 6.375" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.44946 13.75H13.6775" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const LeaveApproval = (props) => {
  const {
    getDoctorList,
    doctorList,
    addNewLeave,
    getLeaveList,
    leaveList,
    editLeave,
    deleteDoctorLeave,
    editLeaveStatus,
    displayToaster,
    ACCESS,
    userType,
    // write: writePermission,
    // execute: executePermission,
    applySearchData,
    searchData,
    perPage,
    SetPerPage,
    currentPage,
    SetCurrentPage,
    columnSort,
    setColumnSort,
    isBtnLoading,
    doctorDetails,
    userDetails
  } = props;

  const intl = useIntl();
  const history = useHistory();
  const pageRef = useRef()
  const tooltipRef = useRef();
  // Popup handle
  const [showPopup, updateShowPopup] = useState(false);
  const [showCommentPopup, updateShowCommentPopup] = useState(false);
  // Filter handle
  const [docName, setDocName] = useState('');
  const [status, setStatus] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [docSuggestion, setDocSuggestion] = useState([]);
  // Data handle
  const [isLoading, updateLoading] = useState(false);
  // new leave
  let initialData={
    doctorId: '',
    doctorName: '',
    department: '',
    from: '',
    to: '',
    leaveType: '',
    startTime: '',
    endTime: '',
    reason: '',
  }
  const [leaveDetails, setLeaveDetails] = useState(initialData);
  const [deptOptions, setDeptOptions] = useState([]);
  const [error, seterror] = useState('');
  // const [showMore, setShowMore] = useState('');
  const [feildError, setFeildError] = useState({
    doctorError: false,
    fromError: false,
    toError: false,
    leaveTypeError: false,
    startError: false,
    endError: false,
    reasonError: false,
    commentError: false,
    invalidLeave: false,
    invalidTime: false,
  });
  const { commentError } = feildError;
  // comments
  const [comment, setComment] = useState('');
  const [commentView, setCommentView] = useState({});
  const [more, setMore] = useState('');
  // pagination handle
  const [leaveListDetails, setLeaveListDetails] = useState([]);
  // const [currentPage, SetCurrentPage] = useState(1);
  // const [perPage, SetPerPage] = useState(10);
  const [totalCount, SetTotalCount] = useState(10);
  // Edit handle
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  // Delete deatils
  const [showConformPopup, updateShowConformPopup] = useState(false);
  const [delelteid, updateDeleteid] = useState('');
  const [showLeavePopup, updateLeavePopup] = useState(false);
  const [showApptConflictPopup, setShowApptConflictPopup] = useState(false);
  const [leaveStatus, setLeaveStatus] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [docLoader,setDocLoader] = useState({})

  const getDepartmentList = () => {
    DepartmentsApi.FetchAllDepartmentList({ limit: 0, status: 'active', sort: 'dept_name' }).then(
      ({ error, response }) => {
        if (error) {
          return;
        }
        if (response) {
          const options = response?.departments?.map((dept) => ({
            label: dept.dept_name,
            value: dept.dept_id,
          }));
          setDeptOptions(options);
        }
      }
    );
  };

  const isApplied = (
    (searchData?.docName?.trim() !== '' && searchData?.docName) ||
    (searchData?.startDate !== '' && searchData?.startDate) ||
    (searchData?.endDate !== '' && searchData?.endDate) ||
    (searchData?.status !== [] && searchData?.status?.value !== undefined)
  )

  // Get Leave list
  const fetchLeaveList = ({
    start_Date,
    end_Date,
    leaveStatus,
    pageNo,
    perPageCount,
    doctor,
  } = {}) => {
    updateLoading(true);
    const sortKey = Object.keys(columnSort)[0];
    const config = {
      page: pageNo || currentPage,
      limit: perPageCount || perPage,
      search:
        doctor || doctor === ''
          ? removeDrPrefix(doctor) || undefined
          : searchData?.docName
          ? removeDrPrefix(searchData?.docName)
          : undefined,
      start_date:
        start_Date || start_Date === ''
          ? start_Date || undefined
          : searchData?.startDate
          ? searchData?.startDate
          : undefined,
      end_date:
        end_Date || end_Date === ''
          ? end_Date || undefined
          : searchData?.endDate
          ? searchData?.endDate
          : undefined,
      status:
        leaveStatus || leaveStatus === ''
          ? leaveStatus || undefined
          : searchData?.status?.value
          ? searchData?.status?.value
          : undefined,
      sort: sortKey
        ? columnSort[sortKey] === 'asc'
          ? sortKey
          : `-${sortKey}`
        : undefined,
    };
    getLeaveList(config, updateLoading);
  };

  const updateStatusAndComment = () => {
    const config = {
      data: {
        leave_status: leaveStatus || LEAVE_STATUS.pending,
        review_message: leaveDetails.review_message ? leaveDetails.review_message+LEAVE_CONCAT_STRING+comment : comment || '',
      },
    };
    // updateLoading(true);
    editLeaveStatus(config, editId, updateLoading, fetchLeaveList, {
      conflictLeave: () => {
        updateLeavePopup(false);
        setShowApptConflictPopup(true)
      },
      successLeave: () => {
        updateLeavePopup(false);
        updateShowCommentPopup(false);
        setComment('');
        setLeaveStatus('');
      },
    });
  };

  useEffect(() => {
    if (ACCESS(APPROVE_LEAVE)) {
      getDoctorList();
      getDepartmentList();
    }
    setDocName(searchData?.docName ?? '');
    setStartDate(searchData?.startDate ?? '');
    setEndDate(searchData?.endDate);
    setStatus(searchData?.status ?? []);
    setIsSelect(searchData?.isSelect);
    fetchLeaveList({
      start_Date: searchData?.startDate,
      end_Date: searchData?.endDate,
      leaveStatus: searchData?.status?.value,
      doctor: searchData?.docName,
    });
  }, []);

  const prevSortVal = usePrevious(columnSort);

  useDidMountEffect(() => {
    if (!isEqual(prevSortVal, columnSort)) {
      fetchLeaveList({
        start_Date: searchData?.startDate,
        end_Date: searchData?.endDate,
        leaveStatus: searchData?.status,
        doctor: searchData?.docName,
      });
    }
  }, [columnSort]);

  useEffect(() => {
    SetTotalCount(leaveList?.page_details?.total_documents);
    setLeaveListDetails(leaveList?.doctors_leaves);
  }, [leaveList]);

  useEffect(() => {
    if (!isSelect) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadOptions(docName);
      }, 300);
      apiTimeout = timeout;
    }
  }, [docName, isSelect]);

  useEffect(() => {
    const listener = (event) => {
      if (!pageRef.current || pageRef.current.contains(event.target) || tooltipRef?.current?.contains(event.target)) {
        return;
      }
      if (event.button === 0) {
        setCommentView({});
        setMore('');
      }
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [pageRef]);

  // on date filter change
  const onDateChange = (e) => {
    if (e) {
      setStartDate(formatDate(e[0]) ?? '');
      setEndDate(formatDate(e[1]) ?? '');
    } else {
      setStartDate('');
      setEndDate('');
    }
  };
  // handle filter apply
  const handleApplyClick = () => {
    let searchData = {
      startDate,
      endDate,
      status,
      docName,
      isSelect,
    };
    applySearchData(searchData);
    if (
      (docName?.trim() !== '' && docName) ||
      (startDate !== '' && startDate) ||
      (endDate !== '' && endDate) ||
      (status !== [] && status?.value !== undefined) ||
      isApplied
    ) {
      fetchLeaveList({
        pageNo: 1,
        start_Date: startDate,
        end_Date: endDate,
        leaveStatus: status?.value !== undefined ?status?.value:'',
        doctor: docName,
      });
      SetCurrentPage(1);
      setCommentView({});
    }
  };

  //handle filter reset
  const handleReset = () => {
    applySearchData({});
    setDocSuggestion([]);
    setDocLoader({});
    setStartDate('');
    setEndDate('');
    setDocName('');
    setStatus([]);
    SetPerPage(25);
    SetCurrentPage(1);
    setCommentView({});
    setColumnSort({});
    if (
      startDate ||
      endDate ||
      docName ||
      status.length !== 0 ||
      currentPage !== 1 ||
      perPage !== 25 ||
      isApplied
    ) {
      fetchLeaveList({
        search: '',
        start_Date: '',
        end_Date: '',
        leaveStatus: '',
        doctor: '',
        pageNo: 1,
        perPageCount: 25,
      });
    }
  };

  // handle leave edit
  const onEdit = (leave) => {
    if (leave.leave_status === LEAVE_STATUS.pending) {
      updateShowPopup(true);
      setIsEditing(true);
      setCommentView({});
      setEditId(leave.doctor_leave_id);
      setLeaveDetails({
        doctorId: leave?.doctor_id || '',
        doctorName: `${leave.name} ${leave.last_name}`,
        from: leave?.start_date || '',
        to: leave?.end_date || '',
        leaveType: leave?.leave_type || '',
        startTime: leave?.start_time || '',
        endTime: leave?.end_time || '',
        reason: leave?.reason || '',
      });
    } else {
      displayToaster(
        'Unable to Update an Approved/Rejected Leave',
        ToasterTypes.Info
      );
    }
  };

  // handle leave delete
  const onDelete = (id, status) => {
    if (status === LEAVE_STATUS.approved) {
      displayToaster('Unable to Delete an Approved Leave', ToasterTypes.Info);
    } else {
      updateDeleteid(id);
      updateShowConformPopup(true);
      setCommentView({});
    }
  };

  // on pagination change
  const handleCurrentPageChange = (pageNo) => {
    SetCurrentPage(pageNo);
    fetchLeaveList({ pageNo });
  };

  // handel perPage change
  const handlePerPageChange = (count) => {
    SetPerPage(count);
    SetCurrentPage(1);
    fetchLeaveList({ pageNo: 1, perPageCount: count });
  };

  // handle popup close
  const handleClose = () => {
    setShowApptConflictPopup(false);
    updateShowPopup(false);
    setIsEditing(false);
    seterror('');
    setFeildError({
      doctorError: false,
      fromError: false,
      toError: false,
      leaveTypeError: false,
      startError: false,
      endError: false,
      reasonError: false,
      commentError: false,
      invalidLeave: false,
      invalidTime: false,
    });
    setLeaveDetails({
      doctorId: '',
      doctorName: '',
      department: '',
      from: '',
      to: '',
      leaveType: '',
      startTime: '',
      endTime: '',
      reason: '',
    });
  };

  const handleConflict = () => {
    setShowApptConflictPopup(true);
    updateShowPopup(false);
  }
  // handle leave add
  const handleAddLeave = () => {
    let isValid = true;
    if (
      !leaveDetails.doctorId ||
      !leaveDetails.from ||
      !leaveDetails.to ||
      !leaveDetails.leaveType ||
      (leaveDetails.leaveType === LEAVE_TYPES.custom &&
        (!leaveDetails.startTime || !leaveDetails.endTime)) ||
      !leaveDetails?.reason?.trim().length
    ) {
      isValid = false;
      setFeildError((prev) => ({
        ...prev,
        doctorError: !leaveDetails.doctorId,
        fromError: !leaveDetails.from,
        toError: !leaveDetails.to,
        leaveTypeError: !leaveDetails.leaveType,
        startError:
          !leaveDetails.startTime &&
          leaveDetails.leaveType === LEAVE_TYPES.custom,
        endError:
          !leaveDetails.endTime &&
          leaveDetails.leaveType === LEAVE_TYPES.custom,
        reasonError: !leaveDetails?.reason?.trim().length,
      }));
    }
    if (
      leaveDetails.from &&
      leaveDetails.to &&
      isDateBefore(leaveDetails.to, leaveDetails.from)
    ) {
      isValid = false;
      setFeildError((prev) => ({
        ...prev,
        invalidLeave: true,
      }));
    }
    if (
      leaveDetails.leaveType === LEAVE_TYPES.custom &&
      leaveDetails.startTime &&
      leaveDetails.endTime &&
      compareTimes(leaveDetails.endTime, leaveDetails.startTime)
    ) {
      isValid = false;
      setFeildError((prev) => ({
        ...prev,
        invalidTime: true,
      }));
    }
    if (isValid) {
      seterror('');
      setFeildError({
        doctorError: false,
        fromError: false,
        toError: false,
        leaveTypeError: false,
        startError: false,
        endError: false,
        reasonError: false,
        commentError: false,
        invalidLeave: false,
        invalidTime: false,
      });
      const config = {
        data: {
          doctor_id: leaveDetails.doctorId,
          start_date: leaveDetails.from,
          end_date: leaveDetails.to,
          start_time: leaveDetails.startTime,
          end_time: leaveDetails.endTime,
          leave_type: leaveDetails.leaveType,
          reason: leaveDetails.reason,
        },
      };
      if (isEditing) editLeave(config, editId, handleClose, fetchLeaveList, userType === USER_TYPE.doctor, handleConflict);
      else addNewLeave(config, handleClose, fetchLeaveList, userType === USER_TYPE.doctor, handleConflict);
    }
  };

  //handle comment submit
  const handleSubmitComment = () => {
    if (!comment.trim().length) {
      seterror(
        translate('leaveApproval.commentRequired', 'Please add a comment')
      );
      setFeildError((prev) => ({
        ...prev,
        commentError: true,
      }));
    } else {
      updateStatusAndComment();
      // updateShowCommentPopup(false);
    }
  };

  // handle accept leave
  const handleAccept = (leave) => {
    const {doctor_leave_id ,leave_status, review_message} = leave
    if (leave_status.toLocaleLowerCase() === LEAVE_STATUS.pending) {
      setLeaveDetails((prev) => ({
        ...prev, from: leave?.start_date || '',
        to: leave?.end_date || '',
        review_message: review_message || '',
      }))
      updateLeavePopup(true);
      setEditId(doctor_leave_id);
      // if (message) setComment(message);
      setLeaveStatus(LEAVE_STATUS.approved);
    }
  };

  // handle reject leave
  const handleReject = (leave) => {
    const {doctor_leave_id ,leave_status, review_message} = leave
    if (leave_status.toLocaleLowerCase() === LEAVE_STATUS.pending) {
      setLeaveDetails((prev) => ({
        ...prev, from: leave?.start_date || '',
        to: leave?.end_date || '',
        review_message: review_message || '',
      }))
      updateLeavePopup(true);
      setEditId(doctor_leave_id);
      // if (message) setComment(message);
      setLeaveStatus(LEAVE_STATUS.rejected);
    }
  };

  // handle cancel leave
  const handleCancel = (leave) => {
    const {doctor_leave_id, review_message} = leave
    setLeaveDetails((prev) => ({
      ...prev, from: leave?.start_date || '',
      to: leave?.end_date || '',
      review_message: review_message || '',
    }))
    updateLeavePopup(true);
    setEditId(doctor_leave_id);
    // if (review_message) setComment(review_message);
    setLeaveStatus(LEAVE_STATUS.cancelled);
  };

  // handle add comment
  const handleAddComment = (id, message) => {
    updateShowCommentPopup(true);
    if (message) setComment(message);
    setEditId(id);
  };

  // handle leave accept or reject
  const onLeaveStatusConfirm = () => {
    // updateLeavePopup(false);
    updateStatusAndComment();
  };

  const onApptConflictConfirm = () => {
    if (userType === USER_TYPE.doctor) {
      const config = {
        data: {
          doctor_id: leaveDetails.doctorId,
          start_date: leaveDetails.from,
          end_date: leaveDetails.to,
          start_time: leaveDetails.startTime,
          end_time: leaveDetails.endTime,
          leave_type: leaveDetails.leaveType,
          reason: leaveDetails.reason,
        },
      };
      if (isEditing) editLeave(config, editId, handleClose, fetchLeaveList);
      else addNewLeave(config, handleClose, fetchLeaveList);
    } else {
      setShowApptConflictPopup(false);
      history.push(`/admin/leave-approval/reschedule/leave:${editId}`);
    }
  };
  // handle delete success
  const handleDeleteSuccess = () => {
    if (leaveListDetails.length === 1 && currentPage > 1) {
      SetCurrentPage(currentPage - 1);
      fetchLeaveList({ pageNo: currentPage - 1 });
    } else {
      fetchLeaveList();
    }
  };

  // on delete confirm
  const onDeleteConfirm = () => {
    updateShowConformPopup(false);
    updateLoading(true);
    deleteDoctorLeave(delelteid, handleDeleteSuccess, updateLoading);
  };

  // format time in 24hr to AM PM
  const formatTo12 = (time) => {
    if (time) {
      let d = timeZoneDate().setHours(time.split(':')[0], time.split(':')[1], 0);
      let label = new Date(d).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
      return label;
    } else {
      return null;
    }
  };

  // handle doc name search change
  const onChangeSearch = (event) => {
    const { value } = event.target;
    setDocName(value);
    setIsSelect(false);
  };

  // handle doc name selection
  const handleDoctorNameSelect = (name) => {
    setDocName(name);
    setIsSelect(true);
    setDocSuggestion([]);
  };

  const loadOptions = async (inputValue) => {
    const fetchedOptions = [];
    if (inputValue?.trim()) {
      let id = new Date().getTime()
      setDocLoader((prev)=>({
        ...prev,
        [id]:true
      }));
      await DoctorsApi.getDoctorsList({
        search: listAllDoc(inputValue) ? '' : removeDrPrefix(inputValue),
        limit: 0,
      }).then(({ response }) => {
        setDocLoader((prev)=>({
          ...prev,
          [id]:false
        }));
        if (response?.doctors) {
          response.doctors.forEach((doctors) => {
            fetchedOptions.push({
              label: 'Dr.'+' '+ doctors.name +' ' + doctors.last_name,
              value: 'Dr.'+' '+ doctors.name +' ' + doctors.last_name,
            });
          });
        } else {
          fetchedOptions.push({
              value: '',
              label: `${intl.formatMessage({
                id: 'common.noData',
                defaultMessage: 'No data found.',
              })}`,
              isDisabled: true,
          })
        }
      });
    }
    setDocSuggestion(fetchedOptions);
  };

  // handle comment change
  const handleCommentChange = (event) => {
    const { value } = event.target;
    setComment(value);
    seterror('');
    setFeildError((prev) => ({
      ...prev,
      commentError: false,
    }));
  };
  const handleKeyPress = (e) => {
    if (e && e.key === 'Enter') {
      if (docName || startDate || endDate || status) {
        handleApplyClick();
      }
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [docName, startDate, endDate, status]);

  const handleConflictClose =  () => {
    setShowApptConflictPopup(false);
    if (userType === USER_TYPE.doctor) updateShowPopup(true)
  }

  // handle add button click
  const handleAddClick = () => {
    setCommentView({});
    if (userType === USER_TYPE.doctor) {
      const { doctor_id: doctorId } = doctorDetails;
      const { name, last_name } = userDetails || {};
      setLeaveDetails(() => ({
        ...initialData,
        doctorId,
        doctorName: `${name} ${last_name}`,
      }));
    } else {
      setLeaveDetails({...initialData})
    }
    updateShowPopup(true);
  };

  // handle outside close of filter
  const handleOutsideClose = () => {
    setDocName(searchData?.docName ?? '');
    setStartDate(searchData?.startDate ?? '');
    setEndDate(searchData?.endDate);
    setStatus(searchData?.status ?? []);
    setIsSelect(searchData?.isSelect);
  }

  return (
    <div
      onClick={() => {
        setCommentView({});
        setMore('');
      }}
      ref={pageRef}
    >
      {showPopup && (
        <PopupWrapeer
          title={
            isEditing ? (
              <FormattedMessage
                id="leaveApproval.editLeave"
                defaultMessage="Edit New Leave"
              />
            ) : (
              <FormattedMessage
                id="leaveApproval.addLeave"
                defaultMessage="Add New Leave"
              />
            )
          }
          onClose={handleClose}
          onSubmit={handleAddLeave}
          modalContent="addLeaveModal modalContent modal_md"
          saveBtn={
            isEditing ? (
              <FormattedMessage id="common.update" defaultMessage="UPDATE" />
            ) : (
              <FormattedMessage id="common.submit" defaultMessage="SUBMIT" />
            )
          }
          isBtnLoading={isBtnLoading}
        >
          <AddLeaves
            leaveDetails={leaveDetails}
            setLeaveDetails={setLeaveDetails}
            doctorList={doctorList}
            deptOptions={deptOptions}
            isEditing={isEditing}
            intl={intl}
            feildError={feildError}
            setFeildError={setFeildError}
            executePermission={
              ACCESS(APPROVE_LEAVE) &&
              userType !== USER_TYPE.doctor &&
              userType !== USER_TYPE.appointmentCordinator
            }
          />
        </PopupWrapeer>
      )}
      {showCommentPopup && (
        <PopupWrapeer
          title={
            <FormattedMessage
              id="leaveApproval.comments"
              defaultMessage="Comments"
            />
          }
          onClose={() => {
            updateShowCommentPopup(false);
            seterror('');
            setComment('');
            setFeildError((prev) => ({
              ...prev,
              commentError: false,
            }));
          }}
          onSubmit={handleSubmitComment}
          modalContent="addLeaveModal modalContent modal_md"
          saveBtn={
            <FormattedMessage id="common.submit" defaultMessage="SUBMIT" />
          }
          isBtnLoading={isBtnLoading}
        >
          <div className="mt-2 form-group modalContentarea-popup">
            <p htmlFor="leaveComment" className='label'>
              <FormattedMessage
                id="leaveApproval.addComments"
                defaultMessage="Add comments"
              />
            </p>
            <div className="text-area-wrap">
            <textarea
              className="form-control input"
              id="leaveComment"
              rows="3"
              maxLength="250"
              value={comment}
              onChange={handleCommentChange}
              style={{ resize: 'none', borderColor: commentError && 'red' }}
              onKeyDown={(e)=>{
                if(e.key === "Tab" && e.shiftKey)e.preventDefault()
              }}
              autoFocus
            />
            </div>
            
            {error && (
              <div className="col-12" style={{ marginTop: 5 }}>
                <div style={{ color: 'red', fontSize: 12 }}>{error}</div>
              </div>
            )}
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="border_btm"></div>
              </div>
            </div>
          </div>
        </PopupWrapeer>
      )}
      {showConformPopup && (
        <ConformPopup
          onClose={() => updateShowConformPopup(false)}
          onConfirm={onDeleteConfirm}
          message={translate(
            'common.doYouWantToDeleteLeave',
            'Are you sure you want to delete this leave?'
          )}
        />
      )}
      {showLeavePopup && (
        <ConformLeavePopup
          onClose={() => {
            updateLeavePopup(false);
            setEditId('');
            setLeaveStatus('');
            setComment('');
          }}
          onConfirm={onLeaveStatusConfirm}
          comment={comment}
          setComment={setComment}
          leaveStatus={leaveStatus}
          leaveDetails={leaveDetails}
          isBtnLoading={isBtnLoading}
        />
      )}

      {showApptConflictPopup && (
        <AppointmentConflictPopup
          onClose={handleConflictClose}
          label={userType === USER_TYPE.doctor ?
            translate(
              'leaveApproval.apptConflictMsgDoc',
              'There are confirmed appointment(s) already scheduled on these date(s). Do you want to continue apply for leave?'
            )
             : translate(
            'leaveApproval.apptConflictMsg',
            'There are confirmed appointment(s) already scheduled on these date(s). Do you want to reschedule/cancel all?'
          )}
          onConfirm={onApptConflictConfirm}
        />
      )}

      <div className={`${userType === USER_TYPE.doctor ? 'doctor-leave-filter' : ''}`}>
      <HeaderFields
        totalCount={totalCount}
        onApply={handleApplyClick}
        onReset={handleReset}
        onAddBtnClick={(ACCESS(APPLY_LEAVE) && userType !== USER_TYPE.appointmentCordinator) && handleAddClick}
        childBreakPt={11}
        btnBreakPt={1}
        breakPoint="lg"
        isApplied={isApplied}
        handleOutsideClose={handleOutsideClose}
      >
        {ACCESS(APPROVE_LEAVE) &&
          userType !== USER_TYPE.doctor &&
          userType !== USER_TYPE.appointmentCordinator && (
            <div className="col-md-12 mb-md-3">
              <div className="form-group mb-0">
              <label htmlFor="">{intl.formatMessage({id: 'doctors.doctorName', defaultMessage: 'Doctor Name',})}</label>
                <div className={`search-input ${docName !== '' && Object.values(docLoader).includes(true) && !isSelect &&'loading'}`}>
                <input
                  type="search"
                  className="form-control down-arrow"
                  onChange={(event) => onChangeSearch(event)}
                  placeholder={intl.formatMessage({
                    id: 'common.search',
                    defaultMessage: 'Search',
                  })}
                  value={docName}
                  maxLength="40"
                  autoComplete="off"
                  onBlur={() => setTimeout(() => setDocSuggestion([]), 400)}
                  onFocus={() => {
                    if (docName) loadOptions(docName);
                  }}
                />
                  </div>
                {docSuggestion.length && docName
                && !Object.values(docLoader).includes(true)
                && !isSelect
                ? (
                  <div className="customSearchList">
                    <ul style={{ marginBottom: 1 }}>
                      {docSuggestion.map((doc, index) => (
                        <li
                          key={index}
                          className={`suggestion-item ${doc.isDisabled ? 'disabled' : ''}`}
                          onClick={() => !doc.isDisabled && handleDoctorNameSelect(doc.value)}
                        >
                          {doc.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        <div className="col-md-6 pe-md-0">
          <div className="form-group mb-0" onClick={(e) => e.stopPropagation()}>
          <label htmlFor="">Leave Date(s)</label>
            <AntMultiDatePicker
              start={startDate}
              end={endDate}
              format="DD/MM/YYYY"
              sx={{
                padding: '8px',
              }}
              onchangeDate={onDateChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mb-0">
          <label htmlFor="">{translate('common.status', 'Status')}</label>
            <ReactSelectDropDown
              options={LEAVE_STATUS_OPTIONS || []}
              id="Leave-Approvals"
              // label={intl.formatMessage({
              //   id: 'common.status',
              //   defaultMessage: 'Status',
              // })}
              onInputChange={(item) => {
                setStatus(item);
              }}
              addValid_class={true}
              required={true}
              value={status}
              isCasePlaceholder={true}
              isClearable
            />
          </div>
        </div>
      </HeaderFields>
      </div>
      <TableWrapper data={leaveListDetails}>
        <div className="list-user mt-3 compact-table" style={{minHeight: isLoading ? '55vh' : '', overflow: !leaveListDetails?.length ? 'hidden' : ''}}>
          {leaveListDetails && leaveListDetails.length > 0 && !isLoading ? (
            <table
              className={
                isLoading
                  ? 'table departments-calendar tableNodata '
                  : 'table departments-calendar'
              }
            >
              <thead>
                <tr className="no-text-transform">
                  {ACCESS(APPROVE_LEAVE) && userType !== USER_TYPE.appointmentCordinator
                    ? LEAVE_APPROVAL_HEADERS.map((head, index) => (
                        <TableColumnHr
                          item={head}
                          key={index}
                          columnSort={columnSort}
                          setColumnSort={setColumnSort}
                          sortEnable={leaveListDetails.length > 1 ? true : false}
                        />
                      ))
                    : ACCESS(MODIFY_LEAVE) && userType === USER_TYPE.doctor
                    ? LEAVE_HEADERS.map((head, index) => (
                        <TableColumnHr
                          item={head}
                          key={index}
                          columnSort={columnSort}
                          setColumnSort={setColumnSort}
                          sortEnable={leaveListDetails.length > 1 ? true : false}
                        />
                      ))
                    : LEAVE_LIST_HEADERS.map((head, index) => (
                        <TableColumnHr
                          item={head}
                          key={index}
                          columnSort={columnSort}
                          setColumnSort={setColumnSort}
                          sortEnable={leaveListDetails.length > 1 ? true : false}
                        />
                      ))}
                </tr>
              </thead>
              <tbody>
                {leaveListDetails.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{(currentPage - 1) * perPage + index + 1}</td>
                    {(ACCESS(APPROVE_LEAVE) || !ACCESS(MODIFY_LEAVE)) && (
                      <td>Dr. {removeDrPrefix(`${item.name} ${item.last_name}`)}</td>
                    )}
                    <td>
                      {dateInFormat(formatDate(item.created_at))}
                    </td>
                    <td width={250}>
                      {item.end_date === item.start_date ? (
                        dateInFormat(item.end_date)
                      ) : (
                        <span>
                        <span className='text-nowrap'> {dateInFormat(item.start_date)}</span> to {' '}
                          <span className='text-nowrap'>{dateInFormat(item.end_date)}</span>
                        </span>
                      )}
                    </td>
                    <td>
                      {item.leave_type === LEAVE_TYPES.custom ? (
                        <>
                          <Tooltip title={LEAVE_TYPE_NAMES[item.leave_type]}>
                            <img src={ScheduleIcon} style={{ marginRight: 5 }} />
                          </Tooltip>
                          {`${formatTo12(item.start_time)} - ${formatTo12(
                            item.end_time
                          )}`}
                        </>
                      ) : (
                        <span>{LEAVE_TYPE_NAMES[item.leave_type]}</span>
                      )}
                    </td>
                    <td className='relative'>
                      <LightTooltip
                        className="no-after"
                        title={item.reason?.length > 10 ? item.reason : null}
                        placement="top"
                      >
                      {item.reason?.slice(0, 10)}
                      {item.reason?.length > 10 ? (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="ms-1 fs-13 fw-500"
                          style={{ color: '#184bc8', cursor: 'pointer' }}
                        >
                          {'...'}
                        </span>
                      ) : null}
                      </LightTooltip>
                    </td>
                    <td>
                    <span className="status-badge">
                    <span className={`badge-dot ${item.leave_status}`}></span>
                      {LEAVE_STATUS_NAMES[item.leave_status]}
                      {item.review_message && (
                        <LightTooltip className="no-min-width"
                        title={
                          <div style={{ textAlign: 'left' }}>
                            {item.review_message?.split(LEAVE_CONCAT_STRING)[item.review_message?.split(LEAVE_CONCAT_STRING).length-1]}
                          </div>
                        }
                          open={!!commentView[item.doctor_leave_id]}
                          placement="top"
                        >
                          <span
                            style={{
                              color: '#9b9b9b',
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setCommentView({
                                [item.doctor_leave_id]:
                                  !commentView[item.doctor_leave_id],
                              });
                            }}
                          >
                            {chatIcon}
                          </span>
                        </LightTooltip>
                      )}
                    </span>
                    </td>
                    {(ACCESS(APPROVE_LEAVE) || ACCESS(MODIFY_LEAVE)) && userType !== USER_TYPE.appointmentCordinator && (
                      <td>
                        <div style={{ display: 'flex' }}>
                          <div className='align-items-center action-btns'>
                            {(
                                <Tooltip
                                  title={
                                    <FormattedMessage
                                      id="leaveApproval.editLeave"
                                      defaultMessage="Edit Leave"
                                    />
                                  }
                                  placement="top"
                                >
                                <span className={`edit-icon ${item.leave_status === LEAVE_STATUS.pending && (ACCESS(APPROVE_LEAVE) || (ACCESS(MODIFY_LEAVE) && new Date(item.end_date) > timeZoneDate())) ? '' : 'disabled'}`} onClick={() => {
                                  if (item.leave_status === LEAVE_STATUS.pending && (ACCESS(APPROVE_LEAVE) || (ACCESS(MODIFY_LEAVE) && new Date(item.end_date) > timeZoneDate()))) {
                                    onEdit(item)
                                  }
                                }}>
                                    {editIcon}
                                  </span>
                                </Tooltip>
                              )}
                            {item.leave_status !== LEAVE_STATUS.approved &&
                              ACCESS(DELETE_LEAVE) && (
                                <Tooltip
                                  title={
                                    <FormattedMessage
                                      id="leaveApproval.deleteLeave"
                                      defaultMessage="Delete Leave"
                                    />
                                  }
                                  placement="top"
                                >
                                  <span className='delete-icon' onClick={() =>
                                      onDelete(
                                        item.doctor_leave_id,
                                        item.leave_status
                                      )
                                    }>
                                  {deleteIcon}
                                </span>
                                </Tooltip>
                              )}


                            {(item.leave_status === LEAVE_STATUS.approved ||
                              (!ACCESS(APPROVE_LEAVE) &&
                                item.leave_status !== LEAVE_STATUS.cancelled)) &&
                              (ACCESS(APPROVE_LEAVE) ||
                                (ACCESS(MODIFY_LEAVE) &&
                                  new Date(item.end_date) > timeZoneDate())) && (
                                <Tooltip
                                  title={
                                    <FormattedMessage
                                      id="leaveApproval.cancelLeave"
                                      defaultMessage="Cancel Leave"
                                    />
                                  }
                                  placement="top"
                                >
                                <span
                                  className={`cancel-icon ${item.leave_status === LEAVE_STATUS.approved && (new Date(item.start_date) > timeZoneDate() || new Date(item.end_date) > timeZoneDate())? '' : 'disabled'}`}
                                  onClick={() => {
                                    item.leave_status === LEAVE_STATUS.approved &&( new Date(item.start_date) > timeZoneDate()|| new Date(item.end_date) > timeZoneDate()) ? handleCancel(
                                      item
                                    ) :
                                      {}
                                  }
                                  }
                                >
                                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="7.5" cy="7.5" r="6.5" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M3 3.5L12 11.5" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                                </span>
                                </Tooltip>
                              )}

                            {userType !== USER_TYPE.doctor &&
                              userType !== USER_TYPE.appointmentCordinator && (
                              <span className={`more-dot ${item.leave_status === LEAVE_STATUS.pending &&
                                ACCESS(APPROVE_LEAVE) ? '' : 'disabled'}`}>
                                  <LightTooltip 
                                    className="more-options"
                                    title={
                                      // <div className="custom-dr">
                                      <ul
                                        // className={`dropdown-menu`}
                                        ref={tooltipRef}
                                        style={{ display: 'block' }}
                                      >
                                        <li
                                          onClick={() =>
                                            handleAccept(
                                              item
                                            )
                                          }
                                        >
                                          <span className="dropdown-item">
                                            <span
                                              className="icon-text"
                                              id="more-ref"
                                            >
                                              <FormattedMessage
                                                id="leaveApproval.approveLeave"
                                                defaultMessage="Approve Leave"
                                              />
                                            </span>
                                          </span>
                                        </li>
                                        <li
                                          onClick={() =>
                                            handleReject(
                                              item
                                            )
                                          }
                                        >
                                          <span className="dropdown-item">
                                            <span
                                              className="icon-text"
                                              id="more-ref"
                                            >
                                              <FormattedMessage
                                                id="leaveApproval.rejectLeave"
                                                defaultMessage="Reject Leave"
                                              />
                                            </span>
                                          </span>
                                        </li>
                                        <li
                                          onClick={() =>
                                            handleAddComment(
                                              item.doctor_leave_id,
                                              item.review_message
                                            )
                                          }
                                        >
                                          <span className="dropdown-item">
                                            <span
                                              className="icon-text"
                                              id="more-ref"
                                            >
                                              <FormattedMessage
                                                id="leaveApproval.addComment"
                                                defaultMessage="Add Comment"
                                              />
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                    // </div>
                                    }
                                      open={more === item.doctor_leave_id}
                                      placement="bottom"
                                  >
                                    <MoreVertIcon
                                      style={{
                                        color: '#c1cdd3',
                                        cursor: 'pointer',
                                        fontSize: 20,
                                        marginLeft: -4
                                      }}
                                    onClick={(e) => {
                                      if (item.leave_status === LEAVE_STATUS.pending &&
                                        ACCESS(APPROVE_LEAVE)) {
                                        e.stopPropagation();
                                        setCommentView({});
                                        setMore(
                                          more === item.doctor_leave_id
                                            ? ''
                                            : item.doctor_leave_id
                                        );
                                      }
                                    }}
                                    />
                                  </LightTooltip>
                                </span>
                              )}
                        
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : !isLoading ? (
              <div className='patient-portal'>
                <NoResultFound text={isApplied ?
                  translate('common.noResult', 'No results found.') :
                  translate('leaveApproval.noDataLeaves', 'No leaves added')}
                  label={isApplied ?
                    translate('common.noResultMsg', 'No results match the filter criteria Remove filter or clear all filters to show results') :
                    userType !== USER_TYPE.appointmentCordinator ?
                    translate('leaveApproval.noDataLeavesMsg', `Looks like you haven't added any leave(s) yet.`)
                  :
                  null
                  }
                  addNew={
                    (ACCESS(APPLY_LEAVE) && !isApplied && userType !== USER_TYPE.appointmentCordinator) ? handleAddClick : null
                  }
                  removeMarginBottom removeMarginTop />
              </div>
          ) : (
            <Loader />
          )}
        </div>
      </TableWrapper>
      {!isLoading && leaveListDetails && leaveListDetails.length > 0 ? (
        <Pagination
          SetCurrentPage={handleCurrentPageChange}
          SetPerPage={handlePerPageChange}
          perPage={parseInt(perPage)}
          totalCount={totalCount}
          currentPage={currentPage}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  doctorList: state.DepartmentReducer.doctorList,
  leaveList: state.LeaveReducer.leaveList,
  docName: state.LeaveReducer.docName,
  searchData: state.LeaveReducer.searchData,
  currentPage: state.LeaveReducer.paginationData.currentPage,
  perPage: state.LeaveReducer.paginationData.perPage,
  columnSort: state.LeaveReducer.columnSort,
  userType: state.LoginReducer.user_type,
  isBtnLoading: state.LeaveReducer.isBtnLoading,
  doctorDetails: state.LoginReducer.doctorDetails,
  userDetails: state.LoginReducer.loginDetails
});

const mapDispatchToProps = (dispatch) => ({
  displayToaster: (message, type) => dispatch(showToaster(message, type)),
  getDoctorList: () => dispatch(FetchDoctorList()),
  getLeaveList: (config, setLoader) => dispatch(getLeave(config, setLoader)),
  addNewLeave: (data, handleClose, fetchLeaveList, checkConflict, handleConflict) =>
    dispatch(addLeave(data, handleClose, fetchLeaveList, checkConflict, handleConflict)),
  editLeave: (data, leaveId, handleClose, fetchLeaveList, checkConflict, handleConflict) =>
    dispatch(updateLeave(data, leaveId, handleClose, fetchLeaveList, checkConflict, handleConflict)),
  deleteDoctorLeave: (leaveId, handleDeleteSuccess, updateLoading) =>
    dispatch(deleteLeave(leaveId, handleDeleteSuccess, updateLoading)),
  editLeaveStatus: (data, leaveId, fetchLeaveList, updateLoading, actions) =>
    dispatch(
      updateLeaveStatus(data, leaveId, fetchLeaveList, updateLoading, actions)
    ),
  applySearchData: (data) => dispatch(updateSearchData(data)),
  SetCurrentPage: (data) => dispatch(updateCurrentPage(data)),
  SetPerPage: (data) => dispatch(updatePerPage(data)),
  setColumnSort: (data) => dispatch(updateColumnSort(data)),
});

export default compose(
  withPermissionCheck(DOCTOR),
  connect(mapStateToProps, mapDispatchToProps)
)(LeaveApproval);
