import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { translate } from '../../Language/Translate';

const ActivationSuccess = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <CheckCircleOutlineIcon
        style={{
          color: '#67f367',
          fontSize: 125,
        }}
      />
      <h6 className="mt-2">
        {translate(
          'common.success',
          'Account created successfully. Kindly check the email for further details'
        )}
      </h6>
    </div>
  );
};

export default ActivationSuccess;
