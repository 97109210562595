import {
  LOGIN_USER,
  LOGOUT_USER,
  RECEIVE_PATIENT_DETAILS,
  RECEIVE_USER_DETAILS,
  RECEIVE_USER_PERMISSIONS,
  SET_USER_TYPE,
  RECEIVE_DOCTOR_LOGIN_DETAILS
} from '../Actions/loginAction';
import {
  UPDATE_EMAIL_ID,
  UPDATE_USER_DETAILS,
} from 'Actions/appointmentAction';
const initialState = {
  loginDetails: {},
  loggedIn: false,
  patientDetails: {},
  userPermissions: {
    ResourceNameScope: {
    },
  },
  user_type:"",
  doctorDetails:{}
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_USER_DETAILS: {
      return {
        ...state,
        loginDetails: action.payload,
        loggedIn: true,
      };
    }
    case RECEIVE_PATIENT_DETAILS: {
      return {
        ...state,
        patientDetails: action.payload,
        loggedIn: true,
      };
    }
    case RECEIVE_USER_PERMISSIONS: {
      return {
        ...state,
        userPermissions: action.payload,
      };
    }
    case LOGIN_USER: {
      return {
        ...state,
        loggedIn: true,
      };
    }
    case LOGOUT_USER: {
      return {
        ...state,
        loginDetails: {},
        loggedIn: false,
      };
    }
    case UPDATE_EMAIL_ID: {
      return {
        ...state,
        loginDetails: action.payload,
      };
    }
    case UPDATE_USER_DETAILS: {
      return {
        ...state,
        loginDetails: action.payload,
      };
    }
    case SET_USER_TYPE: {
      return {
        ...state,
        user_type: action.payload,
      };
    }
    case RECEIVE_DOCTOR_LOGIN_DETAILS: {
      return {
        ...state,
        doctorDetails: action.payload,
      };
    }
    default:
      return state;
  }
};

export default LoginReducer;
