export const RECEIVE_SEARCH_DATA = 'RECEIVE_SEARCH_DATA';
export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';
export const UPDATE_DOCTORLIST_PER_PAGE = 'UPDATE_DOCTORLIST_PER_PAGE';
export const UPDATE_DOCTORLIST_CURRENT_PAGE = 'UPDATE_DOCTORLIST_CURRENT_PAGE';
export const SET_DOCTORLIST_COLUMN_SORT = 'SET_DOCTORLIST_COLUMN_SORT'

export const receiveSearchData = (payload) => ({
    type: RECEIVE_SEARCH_DATA,
    payload,
  });
export const SetPerPage = (payload) => ({
    type: UPDATE_DOCTORLIST_PER_PAGE,
    payload,
  });
export const SetCurrentPage = (payload) => ({
    type: UPDATE_DOCTORLIST_CURRENT_PAGE,
    payload,
  });
  export const clearSearchData = () => ({
    type: CLEAR_SEARCH_DATA,
  });
export const setColumnSort = (payload) => ({
  type: SET_DOCTORLIST_COLUMN_SORT,
  payload,
});