/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactSelectDropDown from '../Common/ReactSelectDropDown';
import { translate } from '../../Language/Translate';
import { useIntl } from 'react-intl';
import {
  appendTime,
  compareTimes,
  convertDateToDay,
  formatDate,
} from '../../Utils/DateFormatter/Date';
import { DepartmentsApi } from '../../Utils/Api/DepartmentApi';
import { USER_TYPE } from '../../Constants/userTypes';
import { LEAVE_TYPES } from 'Constants/constant';
import { connect } from 'react-redux';
import AntDatepicker from 'Components/Common/AntdDatePicker';
import { FetchDoctorSlots } from 'Actions/appointmentAction';
import moment from 'moment';
import { AppointmentApi } from "Utils/Api/AppointmentApi"
import { isEmpty } from 'lodash';

const EditAppointments = (props) => {
  const {
    dataToEdit,
    setDataToEdit,
    doctorSlotData,
    getDoctorSlots,
    selectedDepartmentData,
    getDepartmentData,
    clearDeptData,
    clearSlots,
    error,
    feildError,
    setFeildError,
    setError,
    userType,
    applicationData
  } = props;
  const {
    patient_name,
    doctor_name,
    appt_date,
    schedule_type,
    dept_name,
    dept_id,
    patient_tele_country_code,
    patient_phone,
    patient_email,
    appt_start_time,
    appt_end_time,
    reason,
    doctor_id,
    session,
    parent_details = {},
    patient_id,
    appt_slot_session = ''
  } = dataToEdit || {};

  let initialLoad=true
  const {
    deptError,
    doctorError,
    dateError,
    slotError,
    scheduleError,
    reasonError,
  } = feildError || {};

  const intl = useIntl();
  const [deptOptions, setDeptOptions] = useState([]);
  const [selectedDept, setSelectedDept] = useState({
    label: dept_name,
    value: dept_name,
    id: dept_id,
  });
  const [docOptions, setDocOptions] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState({
    label: doctor_name,
    value: doctor_id,
  });
  const [initialLoadforSession,setInitialLoadforSession]=useState(true)
  const [scheduleWithSlot, setScheduleWithSlot] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);
  const [selectedschedule, setSelectedSchedule] = useState({});
  const [currentDocDetails,setCurrentDocDetails]=useState({})
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({
    label: `${appendTime(appt_start_time)} - ${appendTime(appt_end_time)}`,
    value: appt_start_time,
  });
  const [firstTime, setFirstTime] = useState(false);
  const [btnLoader,setBtnLoader]=useState({session:true,doctor:true})
  const [availableDateList,setAvailableDateList]=useState([])


  const getDepartmentList = () => {
    DepartmentsApi.FetchAllDepartmentList({
      limit: 0,
      status: 'active',
      acl: userType === USER_TYPE.appointmentCordinator,
    }).then(({ error, response }) => {
      if (error) {
        return;
      }
      if (response) {
        const options = response?.departments?.map((dept) => {
          if (dept.dept_name === dept_name) {
            getDepartmentData(dept.dept_id, {
              book_appointment:true,
              status: 'active',
              schedule: 'include',
              acl: userType === USER_TYPE.appointmentCordinator,
            });
          }
          return {
            label: dept.dept_name,
            value: dept.dept_name,
            id: dept.dept_id,
          };
        });
        setDeptOptions(options);
      }
    });
  };

  useEffect(() => {
    setCurrentDocDetails({
      selectedDoc:{ label: doctor_name, value: doctor_id},
      selectedSlot:{
        label: `${appendTime(appt_start_time)} - ${appendTime(appt_end_time)}`,
        value: appt_start_time,
        appt_start_time,
        appt_end_time
      }
    })
    const config = {
      start_date: formatDate(appt_date),
      end_date: formatDate(appt_date),
    };
    getDoctorSlots(doctor_id, config,updateSession);
    getDepartmentList();
    return () => {
      clearDeptData();
      clearSlots();
    };
  }, []);

  useEffect(() => {
    if (doctorSlotData && doctorSlotData.op_schedule_details) {
      if (doctorSlotData?.op_schedule_details?.length) {
        const { doctor_schedule_details } =
          doctorSlotData?.op_schedule_details[0];
        setScheduleWithSlot(doctor_schedule_details);
        let currentSchedule = {};
        let currentSlots = [];
        let value = []
        doctor_schedule_details.forEach((schedule) => {
          if (
            !compareTimes(appt_start_time, schedule.start_time) &&
            !compareTimes(schedule.end_time, appt_end_time) &&
            !firstTime
          ) {
            currentSchedule = {
              label: `${appendTime(schedule.start_time)} - ${appendTime(
                schedule.end_time
              )}`,
              value: schedule.schedule_id,
            };
            currentSlots = schedule.slots
              .filter((i) => i.status === 'available' || (appendTime(i.start_time)===appendTime(appt_start_time) && appendTime(i.end_time)===appendTime(appt_end_time)))
              .map((item) => ({
                label: `${appendTime(item.start_time)} - ${appendTime(
                  item.end_time
                )}`,
                start_time: item.start_time,
                end_time: item.end_time,
                value: item.start_time,
              }));
          }
          let insidedata = []
          if(!firstTime)
          {
            insidedata =  schedule.slots.filter(a => (a.status === 'available' ||(appendTime(a.start_time)===appendTime(appt_start_time) && appendTime(a.end_time)===appendTime(appt_end_time))))
          }else{
            insidedata =  schedule.slots.filter(a => (a.status === 'available' ||(appendTime(a.start_time)===appendTime(currentDocDetails?.selectedSlot?.appt_start_time) && appendTime(a.end_time)===appendTime(currentDocDetails?.selectedSlot?.appt_end_time)&&(currentDocDetails?.selectedDoc?.value===selectedDoc?.value))))
          }
          if (insidedata.length) {
            value.push({
              label: `${appendTime(schedule.start_time)} - ${appendTime(
                schedule.end_time
              )}`,
              start_time: schedule.start_time,
              end_time: schedule.end_time,
              value: schedule.schedule_id,
            });
          }
        });
        if (!firstTime) {
          setSelectedSchedule(currentSchedule);
          setDataToEdit((prev)=>({
            ...prev,
            session:currentSchedule.value
          }))
          setSlots(currentSlots);
          setFirstTime(true);
        }
        setScheduleList(value);
      } else {
        setScheduleWithSlot([]);
        setScheduleList([]);
        setSlots([]);
      }
    }
  }, [doctorSlotData]);

  //get available dates
  const availableDates = async (doctor_id=[],particularDoc=false) => {
    let payload={
      ...(doctor_id?.length &&{doctor_id:doctor_id.join(",")}),
      excluded_patient:patient_id
    }
    let { response } = await AppointmentApi.getAvailableApptDates(payload)
    if (response) {
      let {slot_info}=response
      let docList=[]
      if(slot_info?.doctors){
        slot_info.doctors.forEach((val)=>{
          docList.push({
            label: val?.name,
            value: val?.doctor_id,
          })
        })
      }
      let dates=slot_info?.availabel_date ?Object.keys(slot_info.availabel_date) :[]
      if((initialLoad && particularDoc )||!initialLoad)
      {
        setAvailableDateList(dates)
      }

      //if particular doctor selection done no need to update current doctor list
      if(!particularDoc){
        setDocOptions(docList);
        setBtnLoader({session:false,doctor:false})
      }
    }
    initialLoad=false
  }
  // leave check
  const leaveCheck = (leaves) => {
    let onLeave = false;
    if (leaves) {
      onLeave = leaves.some(
        (leave) => leave.leave_type === LEAVE_TYPES.fullDay
      );
    }
    return onLeave;
  };

  useEffect(() => {
    if (selectedDepartmentData?.doctors) {
      let doctorIds=[]
      selectedDepartmentData?.doctors
        .filter((i) => {
          const { doctor_leaves, schedule_plans } = i;
          const onLeave = leaveCheck(doctor_leaves);

          const day = convertDateToDay(new Date(appt_date));
          const opSchedule = schedule_plans?.op_schedule?.[day];
          if (!(onLeave || !opSchedule)) {
            return true;
          }
        })
        .forEach((doc) => {
          const { doctor_id} = doc;
          doctorIds.push(doctor_id)
        });
        // for getting the available doctors for the curent selecteddate
        availableDates(doctorIds)
    }
  }, [selectedDepartmentData]);

  useEffect(()=>{
    let docId=[selectedDoc?.value]
     // for getting the available dates for the curent selecteddoctor
    availableDates(docId,true)
  },[selectedDoc])

  useEffect(() => {
    if (!initialLoadforSession && scheduleList?.length && scheduleList?.length === 1) {
      setSelectedSchedule(scheduleList[0]);
      if (
        reason &&
        dept_name &&
        doctor_id &&
        appt_date &&
        appt_start_time &&
        appt_end_time
      ) {
        setError('');
      }
      setFeildError((prev) => ({
        ...prev,
        scheduleError: false,
      }));
      const schedule = scheduleWithSlot?.find(
        (item) => item.schedule_id === scheduleList[0].value
      );
      const { slots: doctorSlots } = schedule || {};
      const list = doctorSlots
        .filter((i) => {
          return (i.status === 'available' ||
            (appendTime(i.start_time) === appendTime(currentDocDetails?.selectedSlot?.appt_start_time) &&
              appendTime(i.end_time) === appendTime(currentDocDetails?.selectedSlot?.appt_end_time) &&
              (currentDocDetails?.selectedDoc?.value === selectedDoc?.value)))
        })
        .map((item) => ({
          label: `${appendTime(item.start_time)} - ${appendTime(
            item.end_time
          )}`,
          start_time: item.start_time,
          end_time: item.end_time,
          value: item.start_time,
        }));
      setSlots(list);
      // setSelectedSlot({});
      // setDataToEdit((prev) => ({
      //   ...prev,
      //   // appt_start_time: '',
      //   // appt_end_time: '',
      //   // session: scheduleList[0].value,
      // }));
    }
    if (initialLoadforSession &&scheduleList?.length) {
      setInitialLoadforSession(false)
    }
  }, [scheduleList])
          
          // handle input box changes
          const handleInpupChange = (event) => {
    const { name, value } = event.target;
    setDataToEdit((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFeildError((prev) => ({
      ...prev,
      reasonError: false,
    }));
    if (
      value &&
      dept_name &&
      doctor_id &&
      appt_date &&
      session &&
      appt_start_time &&
      appt_end_time
      ) {
        setError('');
      }
    };
    
    // handle dropdown chnages
    const handleDropDownChange = (value, name) => {
      if (name === 'timeSlot') {
        setSelectedSlot(value);
        setDataToEdit((prev) => ({
          ...prev,
          appt_start_time: value.start_time,
          appt_end_time: value.end_time,
        }));
        setFeildError((prev) => ({
          ...prev,
          slotError: false,
        }));
        if (reason && dept_name && doctor_id && appt_date) {
          setError('');
        }
      } else if (name === 'session_id') {
        setSelectedSchedule(value);
      if (
        reason &&
        dept_name &&
        doctor_id &&
        appt_date &&
        appt_start_time &&
        appt_end_time
      ) {
        setError('');
      }
      setFeildError((prev) => ({
        ...prev,
        scheduleError: false,
      }));
      const schedule = scheduleWithSlot?.find(
        (item) => item.schedule_id === value.value
      );
      const { slots: doctorSlots } = schedule || {};
      const list = doctorSlots
        .filter((i) =>{
           return (i.status === 'available' || 
           (appendTime(i.start_time)===appendTime(currentDocDetails?.selectedSlot?.appt_start_time)&&
           appendTime(i.end_time)===appendTime(currentDocDetails?.selectedSlot?.appt_end_time)&&
           (currentDocDetails?.selectedDoc?.value===selectedDoc?.value)))})
        .map((item) => ({
          label: `${appendTime(item.start_time)} - ${appendTime(
            item.end_time
          )}`,
          start_time: item.start_time,
          end_time: item.end_time,
          value: item.start_time,
        }));
      setSlots(list);
      setSelectedSlot({});
      setDataToEdit((prev) => ({
        ...prev,
        appt_start_time: '',
        appt_end_time: '',
        session: value.value,
        appt_slot_session: value.label
      }));
    } else if (name === 'dept_name') {
      getDepartmentData(value?.id, {
        status: 'active',
        book_appointment:true,
        schedule: 'include',
        schedule_date: formatDate(appt_date),
        acl: userType === USER_TYPE.appointmentCordinator,
      });
      setSelectedDept(value);
      setSelectedSlot({});
      setSelectedSchedule({});
      setSelectedDoc({});
      setSlots([]);
      setFeildError((prev) => ({
        ...prev,
        deptError: false,
      }));
      if (
        reason &&
        doctor_id &&
        appt_date &&
        appt_start_time &&
        appt_end_time
      ) {
        setError('');
      }
      setScheduleList([]);
      setDataToEdit((prev) => ({
        ...prev,
        dept_name: value.value,
        dept_id: value.id,
        doctor_id: '',
        appt_start_time: '',
        appt_end_time: '',
        session: '',
      }));
    } else if (name === 'doctor_id') {
      setSelectedDoc(value);
      if (
        reason &&
        dept_name &&
        appt_date &&
        appt_start_time &&
        appt_end_time
      ) {
        setError('');
      }
      setFeildError((prev) => ({
        ...prev,
        doctorError: false,
      }));
      const config = {
        start_date: formatDate(appt_date),
        end_date: formatDate(appt_date),
      };
      setDataToEdit((prev) => ({
        ...prev,
        doctor_id: value.value,
        session: '',
        appt_start_time: '',
        appt_end_time: '',
      }));
      setSelectedSlot({});
      setSelectedSchedule({});
      setSlots([]);
      setScheduleList([]);
      setBtnLoader({...btnLoader,session:true})
      getDoctorSlots(value?.value, config,updateSession);
    }
  };

  // handle appointment date change
  const appointmentDateChange = (value) => {
    setDataToEdit((prev) => ({
      ...prev,
      appt_date: value,
      appt_end_time: '',
      appt_start_time: '',
    }));
    setFeildError((prev) => ({
      ...prev,
      dateError: false,
    }));
    if (reason && doctor_id && dept_name && appt_start_time && appt_end_time) {
      setError('');
    }
    setSelectedSchedule({});
    setSelectedSlot({});
    setSlots([]);
    getDepartmentData(selectedDept?.id, {
      status: 'active',
      schedule: 'include',
      book_appointment:true,
      schedule_date: formatDate(value),
      acl: userType === USER_TYPE.appointmentCordinator,
    });
    setScheduleList([]);
    const config = {
      start_date: formatDate(value),
      end_date: formatDate(value),
    };
    if (selectedDoc.value){
      setBtnLoader({doctor:true,session:true})
       getDoctorSlots(selectedDoc?.value, config,updateSession)
      }
  };
const updateSession=(val)=>{
  setBtnLoader({...btnLoader,session:val})
}
  // disable pervious date
  const disabledDates = (current) => {
    let formatdate=new Date(current)
    let formaattedDate = formatDate(formatdate);
    return (
      current &&
      (current.isBefore(moment().subtract(1, 'day')) ||
        current.isAfter(
          moment().add(applicationData?.booking_window_max_days - 1, 'day')
        ) || !availableDateList.includes(formaattedDate)))
  };

  return (
    <>
      <div className="container-main" onKeyDown={(e) => e.stopPropagation()}>
        <div className="row mt-3">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="User name">
                {translate('appointments.patientName', 'Patient Name')}
              </label>
              <input
                type="text"
                value={patient_name}
                disabled
                maxLength="40"
                name="patient_name"
                className="form-control"
                id="Patientname"
                onChange={handleInpupChange}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group"
            onKeyDown={(e)=>{
              if(e.key === "Tab" && e.shiftKey) e.preventDefault()
            }}
            >
              <label htmlFor="User name">
                {translate('appointments.department', 'Department')}{' '}
                <span className="sup">*</span>
              </label>
              <ReactSelectDropDown
                label=""
                value={selectedDept}
                options={deptOptions}
                onInputChange={(item) => {
                  setBtnLoader({...btnLoader,doctor:true})
                  handleDropDownChange(item, 'dept_name');
                }}
                validationErr={deptError}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="User name">
                {translate('appointments.doctorName', 'Doctor Name')}{' '}
                <span className="sup">*</span>
              </label>
              <ReactSelectDropDown
                label=""
                value={selectedDoc}
                options={docOptions}
                isDisabled={!selectedDept?.value}
                onInputChange={(item) => {
                  handleDropDownChange(item, 'doctor_id');
                }}
                validationErr={doctorError}
                isLoading={btnLoader?.doctor}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className={`col-md-4 ${dateError && 'validation-error'}`}>
            <div className="form-group">
              <label htmlFor="User name">
                {translate('appointments.appointmentDate', 'Appointment Date')}{' '}
                <span className="sup">*</span>
              </label>
              <AntDatepicker
                onInputChange={(date)=>appointmentDateChange(new Date(date))}
                value={appt_date}
                placeholder={intl.formatMessage({
                  id: 'appointments.selectDate',
                  defaultMessage: 'Select Date',
                })}
                className="form-control"
                disabledDate={disabledDates}
                isAdmin
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="User name">
                {translate('appointments.session', 'Session')}{' '}
                <span className="sup">*</span>
              </label>
              <ReactSelectDropDown
                label=""
                value={!isEmpty(selectedschedule) ?
                  selectedschedule :
                  (isEmpty(selectedschedule) && scheduleList.length > 1)
                    ? '' :
                    { label: appt_slot_session, value: appt_slot_session }}
                options={scheduleList}
                onInputChange={(item) => {
                  handleDropDownChange(item, 'session_id');
                }}
                validationErr={scheduleError}
                isLowerCase={true}
                isLoading={btnLoader?.session}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="User name">
                {translate('appointments.timeSlot', 'Time Slot')}{' '}
                <span className="sup">*</span>
              </label>
              <ReactSelectDropDown
                label=""
                isDisabled={!selectedschedule?.value}
                value={selectedSlot}
                options={slots}
                onInputChange={(item) => {
                  handleDropDownChange(item, 'timeSlot');
                }}
                validationErr={slotError}
                isLowerCase={true}
                // noOptionsMessage ={() => (
                //   <div className="noOption">
                //     {translate('common.noSessionOpted', 'Select any session first for available time slots')}
                //   </div>
                // )}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="User name">
                {translate('appointments.type', 'Type')}
              </label>
              <input
                type="text"
                value={schedule_type?.toUpperCase()}
                disabled
                className="form-control"
                id="Emai"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="User name">
                {translate('appointments.email', 'Email')}
              </label>
              <input
                type="text"
                value={patient_email}
                name="email"
                maxLength="50"
                onChange={handleInpupChange}
                className="form-control"
                disabled
                id="Emai"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="User name">
                {translate('appointments.mobile', 'Mobile')}
              </label>
              <input
                type="text"
                value={patient_phone
                  ? `+${patient_tele_country_code}${patient_phone}`
                  : `+${parent_details?.tele_country_code}${parent_details?.phone}`}
                   disabled
                className="form-control"
                id="Emai"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="User name">
                {translate('appointments.reason', 'Reason')}{' '}
                <span className="sup">*</span>
              </label>
              <div className="text-area-wrap">
              <textarea
                style={{ resize: 'none', borderColor: reasonError && 'red' }}
                className="form-control input"
                value={reason}
                maxLength="250"
                name="reason"
                onChange={handleInpupChange}
              ></textarea>
              </div>
            </div>
          </div>
        </div>
        {error && (
          <div className="col-12">
            <div style={{ color: 'red', fontSize: 12, textAlign: 'center' }}>
              {error}
            </div>
          </div>
        )}
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="border_btm"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  userType:state.LoginReducer.user_type,
  applicationData: state.SettingsReducer.applicationData,

});
const mapDispatchToProps = (dispatch) => ({
  getDoctorSlots: (id, config,updateSlotLoader) => dispatch(FetchDoctorSlots(id, config,updateSlotLoader)),
 
});
export default connect(mapStateToProps, mapDispatchToProps)(EditAppointments);
