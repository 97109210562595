import React, { useState, useEffect } from 'react';
import HomeLayout from '../Layout/Layout';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import PrivateRoute from '../../../Utils/Services/PrivateRoute';
import NotFound from '../../NotFound/NotFound';
import BookAppointment from '../BookAppointment/BookAppointment';
import HomePage from '../Layout/HomePage';
import SubscriptionExp from '../Subscription/SubscriptionExp';
import Prescriptions from '../Prescriptions/Prescriptions';
import Appointments from '../Appointments/Appointments';
import MedicalRecords from '../MedicalRecords/MedicalRecords';
import Profiles from '../Profile/Profiles';
import Invoice from '../Invoice/Invoice';
import Contact from '../Contact/Contact';
import {
  clearLocalStorage,
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../../Utils/LocalStorage/local-storage';
import { connect, useDispatch } from 'react-redux';
import { providerLogout } from '../../../Actions/loginAction';
import ProfileSetting from '../Profile/ProfileSetting';
import PrescriptionView from '../Prescriptions/PrescriptionView';
import TermsOfUse from '../Terms_of_use/TermsOfUse';
import { APPOINTMENT, PATIENTFAMILY, PATIENTRECORDS } from 'Constants/feature';
import {
  READ_LINK_PROFILE,
  READ_PRESCRIPTIONS,
  READ_RECORDS,
  READ,
} from 'Constants/scopes';
import Unauthorized from 'Components/NotFound/UnauthorisedPage';
import { updateToken } from 'Utils/Api/Network';
import { FetchUserDetails } from 'Actions/loginAction';
import keycloak from 'Utils/keyCloak/keyCloak';
import { PORTAL_TYPES } from 'Constants/constant';
import { timeZoneDate } from 'Utils/DateFormatter/Date';
import { checkAppPermission } from 'Utils/Permissions/Permissions';

const UserLoggedinPages = ({
  getUserDetails,
  userDetails,
  subscriptionData,
  subscriptionFailed,
  permissions,
  loggedIn
}) => {
  const history = useHistory();
  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  const [refresh, setRefresh] = useState(!loggedIn);

  // initialise keycloak and check if the user is already logged in
  useEffect(() => {
    (async () => {
      const portalType = getLocalStorageItem('portalType');
      const accessToken = getLocalStorageItem('access_token');
      const refreshTokenExp = getLocalStorageItem('refresh_token_exp');
      const currentDate = timeZoneDate();
      const refreshExpiryTime = new Date(refreshTokenExp * 1000);

      // if the access token is expired or the user is switching url from admin poratl logout the user
      if (
        keycloak.didInitialize &&
        (portalType !== PORTAL_TYPES.patient ||
          (accessToken && currentDate > refreshExpiryTime))
      ) {
        clearLocalStorage();
        updateToken('');
        await keycloak.logout({
          redirectUri: window.location.href.split('?')[0] + '?test=true',
        });
      }

      setLocalStorageItem('portalType', PORTAL_TYPES.patient);

      if (!keycloak.didInitialize)
        keycloak
          .init({
            redirectUri: window.location.href.split('?')[0] + '?test=true',
            onLoad: 'check-sso',
          })
          .then(async (authenticated) => {
            if (authenticated) {
              setRefresh(true);
              const tokenDetails = {
                access_token: keycloak.token,
                refresh_token: keycloak.refreshToken,
                refresh_token_exp: keycloak.refreshTokenParsed.exp,
              };
              await updateToken(keycloak.token);
              getUserDetails(tokenDetails, setRefresh);
            } else {
              setRefresh(false);
              // to avoid clear search field in book appointment when log out user
              const deptDate = getLocalStorageItem('deptDate');
              const deptName = getLocalStorageItem('deptName');
              const deptType = getLocalStorageItem('deptType');
              const deptLabel = getLocalStorageItem('deptLabel');
              clearLocalStorage();
              setLocalStorageItem('portalType', PORTAL_TYPES.patient);
              if (deptDate && deptName && deptType && deptLabel) {
                setLocalStorageItem('deptDate', deptDate);
                setLocalStorageItem('deptName', deptName);
                setLocalStorageItem('deptType', deptType);
                setLocalStorageItem('deptLabel', deptLabel);
              }
              dispatch(providerLogout());
            }
          });
    })();
  }, []);

  const checkpermission = (feature, scope) => {
    const permission = permissions?.[feature];
    if (!permission || !permission?.length) {
      return false;
    } else {
      return permission.includes(scope);
    }
  };

  // redirect after loggedin from keycloak
  useEffect(() => {
    if (userDetails?.user_id && loggedIn) {
      const token = getLocalStorageItem('access_token');
      if (token) {
        const login = window.location.href.split('isLogin=');
        if (login.length > 1) history.push('/webConnect/appointments');
        else history.push(window.location.hash.replace('#', ''));
      }
    }
  }, [userDetails?.user_id, loggedIn]);

  // check whether the subscription expired 
  const subscriptionLayout = (component) => {
    return checkAppPermission(subscriptionData) && !subscriptionFailed
      ? component
      : SubscriptionExp;
  };

  const publicRoutes = [
    {
      path: `${path}`,
      component: subscriptionLayout(HomePage),
      hasSubRoute: false,
    },
    {
      path: `${path}/bookAppointment`,
      component: subscriptionLayout(BookAppointment),
      hasSubRoute: false,
    },
    {
      path: `${path}/contact-us`,
      component: subscriptionLayout(Contact),
      hasSubRoute: false,
    },
    {
      path: `${path}/terms-of-use`,
      component: subscriptionLayout(TermsOfUse),
      hasSubRoute: false,
    },
  ];

  const privateRoutes = [
    {
      path: `${path}/prescriptions`,
      component: checkpermission(PATIENTRECORDS, READ_PRESCRIPTIONS)
        ? subscriptionLayout(Prescriptions)
        : Unauthorized,
      hasSubRoute: false,
    },
    {
      path: `${path}/prescriptions/:id`,
      component: checkpermission(PATIENTRECORDS, READ_RECORDS)
        ? subscriptionLayout(PrescriptionView)
        : Unauthorized,
      hasSubRoute: false,
    },
    {
      path: `${path}/profiles`,
      component: checkpermission(PATIENTFAMILY, READ_LINK_PROFILE)
        ? subscriptionLayout(Profiles)
        : Unauthorized,
    },
    {
      path: `${path}/appointments`,
      component: checkpermission(APPOINTMENT, READ)
        ? subscriptionLayout(Appointments)
        : Unauthorized,
      hasSubRoute: false,
    },
    {
      path: `${path}/medical-records`,
      component: checkpermission(PATIENTRECORDS, READ)
        ? subscriptionLayout(MedicalRecords)
        : Unauthorized,
      hasSubRoute: false,
    },
    {
      path: `${path}/invoices`,
      component: subscriptionLayout(Invoice),
      hasSubRoute: false,
    },
    {
      path: `${path}/profileSetting`,
      component: subscriptionLayout(ProfileSetting),
      hasSubRoute: true,
    },
  ];

  return (
    <>
      {refresh ? (
        <div
          style={{
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: '#ffff',
            zIndex: 99,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className="stand_by_screen"
        >
          <span className="bubble_loader"></span>
        </div>
      ) : (
        <HomeLayout>
          <Switch>
            {publicRoutes.map((route) => (
              <Route
                path={route.path}
                key={route.path}
                exact={!route.hasSubRoute}
                render={(props) => (
                  <route.component
                    {...props}
                  />
                )}
              />
            ))}
            {privateRoutes.map((route) => (
              <PrivateRoute
                path={route.path}
                key={route.path}
                component={route.component}
                exact={!route.hasSubRoute}
                redirectTo="/webConnect"
              />
            ))}
            <Route component={NotFound} />
          </Switch>
        </HomeLayout>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  userDetails: state.LoginReducer.loginDetails,
  subscriptionData:
    state.SettingsReducer.subscriptionData,
  subscriptionFailed: state.SettingsReducer.subscriptionFailed,
  permissions: state.LoginReducer.userPermissions.ResourceNameScope,
  loggedIn: state.LoginReducer.loggedIn
});
const mapDispatchToProps = (dispatch) => ({
  getUserDetails: (tokenDetails, setRefresh) =>
    dispatch(FetchUserDetails(tokenDetails, setRefresh)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLoggedinPages);
