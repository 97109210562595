import {
  OTP_LOADER,
  PROFILE_BTN_LOADING,
  VIEW_MODE
} from '../Actions/patientProfileAction';

const initialState = {
  isBtnLoading: false,
  showOtpLoader:false,
  viewMode:false
};

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_BTN_LOADING: {
      return {
        ...state,
        isBtnLoading: action.payload,
      };
    }
    case OTP_LOADER: {
      return {
        ...state,
        showOtpLoader: action.payload,
      };
    }
    case VIEW_MODE:{
      return {
        ...state,
        viewMode:action.payload
      }
    }
    default:
      return state;
  }
};

export default ProfileReducer;
