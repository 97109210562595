import Network from './Network';

const getPrescriptionsDetails = async (id, data = {}) => {
  let response = null;
  let error = null;
  await Network.get({
    url: '/patient/prescriptions/' + id,
    data,
  }).then((res) => {
    const { isError, result, message } = res;
    if (isError) {
      error = message;
    } else {
      response = result;
    }
  });
  return { error, response };
};

export const PrescriptionsApi = {
  getPrescriptionsDetails,
};
