import React, { useEffect, useState } from 'react';
import html2pdf from 'html2pdf.js';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { connect } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import 'Assets/style/PatientView.css';
import ViewPrescription from './Prescription';
import LatestDocuments from './Documents';
import Patientdata from './PatientData';
import PrescriptionNote from './PrescriptionNote';
import { translate } from '../../../Language/Translate';
import { APPOINTMENT_STATUS, FILE_TYPES } from '../../../Constants/constant';
import {
  appendTime,
  formatDate,
  formatDateInDDMMYYYY,
  timeZoneDate,
} from '../../../Utils/DateFormatter/Date';
import {
  FetchAppointmentDetails,
  FetchAppointmentListWithStatus,
  uploadPrescription,
} from '../../../Actions/prescriptions';
import PdfViewer from './ViewPdf';
import {
  AddAppointmentData,
  // clearAppointmentReducer,
  clearDoctorAppointmentExceptFilter,
} from '../../../Actions/appointmentAction';
import { convertToBase64 } from '../../../Utils/Services/Authenticator';
import { saveAs } from 'file-saver';
import { APPOINTMENT } from 'Constants/feature';
import { COMPLETE_STATUS } from 'Constants/scopes';
import ButtonLoader from 'Components/Common/ButtonLoader';
import Loader from 'Components/Common/Loader';
import NoResultFound from 'Components/Common/NoResult';

const pdfIcon = <svg width="38" height="50" viewBox="0 0 38 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M37.5352 11.8262L25.9141 0.195312H2.33008C1.29492 0.195312 0.455078 1.03516 0.455078 2.07031V35.3418H37.5352V11.8262Z" fill="#EFF3F5"/>
<path d="M25.9141 9.95117C25.9141 10.9863 26.7539 11.8262 27.7891 11.8262H37.5449L25.9141 0.195312V9.95117Z" fill="#DBDFE0"/>
<path d="M0.464844 35.332V47.9297C0.464844 48.9648 1.30469 49.8047 2.33984 49.8047H35.6699C36.7051 49.8047 37.5449 48.9648 37.5449 47.9297V35.332H0.464844Z" fill="#F1786B"/>
<path d="M9.48827 33.2031C8.89257 33.2031 8.31639 32.9785 7.78905 32.5391C6.90038 31.7969 6.83202 30.9766 6.91991 30.4199C7.3496 27.9102 12.0176 25.6152 14.7324 24.4824C16.207 20.791 17.4863 16.4453 18.2773 13.1836C16.6953 9.79492 16.1387 7.40234 16.6269 6.04492C16.8711 5.37109 17.3691 4.93164 18.0723 4.77539L18.1699 4.75586L18.2676 4.76562C18.3457 4.77539 19.0391 4.88281 19.6055 5.64453C20.3379 6.64062 20.5039 8.25195 20.0937 10.4395C19.9766 11.0645 19.7812 11.9629 19.5273 13.0371C20.9726 16.0449 23.0039 19.3652 24.6641 21.6699C26.0508 21.4746 27.3691 21.4063 28.4824 21.5234C30.5625 21.7383 31.0019 22.6465 31.0801 23.1738C31.1973 23.9453 30.6308 24.7461 29.6641 25.1563C28.3262 25.7227 26.1387 25.5762 24.3613 23.2715C24.2832 23.1738 24.2051 23.0664 24.1269 22.959C21.8418 23.3398 19.1855 24.0625 16.4902 25.0684C16.207 25.1758 15.9336 25.2832 15.6601 25.3906C13.834 29.8633 12.0469 32.4316 10.3379 33.0469C10.0644 33.1543 9.77147 33.2031 9.48827 33.2031ZM14.0781 26.0645C10.2012 27.8125 8.27733 29.541 8.10155 30.625C8.06249 30.8594 8.06249 31.2207 8.56054 31.6309C9.01952 32.0117 9.45897 32.1094 9.94725 31.9336C11.3047 31.4453 12.75 29.1016 14.0781 26.0645ZM25.5039 22.7734C26.8223 24.3262 28.3066 24.4531 29.2051 24.0625C29.6738 23.8672 29.9375 23.5352 29.9082 23.3496C29.8789 23.1543 29.4394 22.8223 28.3652 22.7148C27.5644 22.6367 26.5976 22.6563 25.5039 22.7734ZM19.0976 14.8242C18.5019 17.1191 17.6914 19.8828 16.7441 22.5586C16.5781 23.0176 16.4219 23.457 16.2558 23.8867C18.375 23.1055 20.9336 22.3438 23.3652 21.8848C22.0566 20.0195 20.4453 17.4316 19.0976 14.8242ZM18.2187 5.98633C17.9941 6.06445 17.8476 6.21094 17.7598 6.46484C17.418 7.40234 17.8672 9.16992 18.7363 11.2598C18.8144 10.8887 18.8828 10.5469 18.9414 10.2344C19.3906 7.80273 18.9609 6.75781 18.6582 6.35742C18.4922 6.13281 18.3164 6.02539 18.2187 5.98633Z" fill="#F1786B"/>
<path d="M27.8281 41.8164H25.5918V39.541H27.8281C28.248 39.541 28.5898 39.1992 28.5898 38.7793C28.5898 38.3594 28.248 38.0176 27.8281 38.0176H24.8398C24.4199 38.0176 24.0781 38.3594 24.0781 38.7793V46.3574C24.0781 46.7773 24.4199 47.1191 24.8398 47.1191C25.2598 47.1191 25.6016 46.7773 25.6016 46.3574V43.3301H27.8379C28.2578 43.3301 28.5996 42.9883 28.5996 42.5684C28.5898 42.1484 28.248 41.8164 27.8281 41.8164Z" fill="#EFF3F5"/>
<path d="M11.7148 38.0273H10.1719C9.75195 38.0273 9.41016 38.3691 9.41016 38.7891V46.3672C9.41016 46.7871 9.75195 47.1289 10.1719 47.1289C10.5918 47.1289 10.9336 46.7871 10.9336 46.3672V43.3398H11.7148C13.1797 43.3398 14.3711 42.1484 14.3711 40.6836C14.3711 39.2188 13.1797 38.0273 11.7148 38.0273ZM11.7148 41.8164H10.9336V39.541H11.7148C12.3398 39.541 12.8477 40.0488 12.8477 40.6738C12.8477 41.2988 12.3398 41.8164 11.7148 41.8164Z" fill="#EFF3F5"/>
<path d="M18.2578 38.0273H16.2656C15.8457 38.0273 15.5039 38.3691 15.5039 38.7891V46.3672C15.5039 46.7871 15.8457 47.1289 16.2656 47.1289H18.2578C20.7676 47.1289 22.8086 45.0879 22.8086 42.5781C22.8086 40.0586 20.7676 38.0273 18.2578 38.0273ZM18.2578 45.5957H17.0273V39.5312H18.2578C19.9277 39.5312 21.2852 40.8887 21.2852 42.5586C21.2949 44.2383 19.9277 45.5957 18.2578 45.5957Z" fill="#EFF3F5"/>
</svg>

function PatientView(props) {
  const {
    addPrescription,
    appointmentDetails,
    getAppoinmtentDetails,
    match,
    getAppointments,
    appointmentViewList,
    history,
    doctorSlotData,
    addAppointment,
    clearData,
    permissions,
    doctorDetails,
  } = props;

  const {
    params: { id: dataToView },
  } = match || {};

  // handle tab
  const [today, setToday] = useState(true);
  const [previous, setPrevious] = useState(false);

  const [showPopup, updateShowPopup] = useState(false);

  // handle prescription
  const [prescriptionNote, setPrescriptionNote] = useState('');
  const [medicines, setMedicines] = useState([]);
  const [advice, setAdvice] = useState('');
  const [saveFile, setSaveFile] = useState(false);
  const [loading, setLoading] = useState(false);
  // page loader
  const [isLoading, setIsLoading] = useState(false);
  // appointment list loader
  const [loader, setLoader] = useState(false);
  const [fileName, setFileName] = useState('');
  const [referenceData, setReferenceData] = useState({});
  const [prescriptionError, setPrescriptionError] = useState(false);
  const [medError, setMedError] = useState({});

  // appt list handle
  const [appointments, setAppointments] = useState([]);
  const [total, setTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstTime, setFirstTime] = useState(true);

  // followup
  const [followup, setFollowup] = useState(false);
  const [followData, setFollowData] = useState({
    date: '',
    start: '',
    end: '',
  });
  const [followError, setFollowError] = useState({
    dateError: false,
    sessionError: false,
    timeError: false,
  });

  // pdf url
  const [pdfUrl, setPdfUrl] = useState('');

  const getAppoinmtentsList = ({ status, pageNo }) => {
    setLoader(true);
    const statusVar= status
    ? status
    : previous
    ? APPOINTMENT_STATUS.completed
    : APPOINTMENT_STATUS.upcoming
    const config = {
      patient_id: appointmentDetails?.patient_details?.patient_id,
      status:statusVar,
      page: pageNo || currentPage,
      limit: 5,
      sort : statusVar == "upcoming" ? "appt_date,appt_start_time" : null
    };
    getAppointments(config, setLoader);
  };

  useEffect(() => {
    setIsLoading(true);
    getAppoinmtentDetails(dataToView, setIsLoading);
    return () => clearData();
  }, []);

  useEffect(() => {
    if (appointmentDetails?.patient_details?.patient_id && firstTime) {
      getAppoinmtentsList({
        status: APPOINTMENT_STATUS.upcoming,
        pageNo: 1,
      });
      setFirstTime(false);
    }
  }, [appointmentDetails]);

  useEffect(() => {
    if (appointmentViewList?.appointments?.length) {
      const appointmentList = []
       appointmentViewList.appointments.forEach((item)=>{
        if(dataToView !== item.appointment_id ){
          appointmentList.push(item)
        }
      })
      setAppointments([...appointments, ...appointmentList]);
      if (
        (appointmentDetails?.appointment_details?.live_status ===
          APPOINTMENT_STATUS.upcoming &&
          today) ||
        (appointmentDetails?.appointment_details?.live_status ===
          APPOINTMENT_STATUS.completed &&
          previous)
      ) {
        setTotal(appointmentViewList?.page_details?.total_documents - 1);
      } else {
        setTotal(appointmentViewList?.page_details?.total_documents);
      }
    } else {
      setTotal(0);
      setAppointments([]);
    }
  }, [appointmentViewList]);

  // handle upcoming tab click
  const upcomingClick = () => {
    if (!today) {
      setPrevious(false);
      setToday(true);
      setAppointments([]);
      setTotal(0);
      getAppoinmtentsList({
        status: APPOINTMENT_STATUS.upcoming,
        pageNo: 1,
      });
      setCurrentPage(1);
    }
  };

  // handle previous tab click
  const previousClick = () => {
    if (!previous) {
      setToday(false);
      setPrevious(true);
      setAppointments([]);
      setTotal(0);
      getAppoinmtentsList({
        status: APPOINTMENT_STATUS.completed,
        currentPage: 1,
      });
      setCurrentPage(1);
    }
  };

  const setSuccess=()=>{
    setAppointments([]);
    setPrevious(false);
    setToday(true)
     getAppoinmtentsList( {status: APPOINTMENT_STATUS.upcoming,pageNo: 1,})
  }
  
  // handle prescription save
  const handlePrescriptionSave = async () => {
    if ((advice?.trim() || prescriptionNote?.trim() || medicines?.[0]?.medicineName.trim()) && ((!medicines?.[0]?.medicineName?.trim() && !medicines?.[0]?.dosage && !medicines?.[0]?.duration) || medicines?.every((x) => x.medicineName?.trim()))) {
      const file_display_name = `${dataToView}-${formatDateInDDMMYYYY(
        timeZoneDate()
      )}-Prescription`;
      let data = '';
      const element = document.getElementById('divToPrint');
      if (element) {
        const opt = {
          margin: 0.5,
          filename: fileName,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        await html2pdf()
          .set(opt)
          .from(element)
          .output('datauristring')
          .then(function (base64String) {
            data = base64String;
          });
      }
      const validatedMedicines = medicines?.filter((med) => !!med.medicineName);
      const prescription = {
        prescriptionNote,
        advice,
        medicines: validatedMedicines,
      };
      const config = {
        data: {
          add_prescriptions: [
            {
              record_type: 'prescription',
              record_file_type: FILE_TYPES.pdf,
              file_display_name,
              data,
              text_data: convertToBase64(prescription),
            },
          ],
          referred_appointments: referenceData.dept_id ? [referenceData] : [],
        },
      };
      if (followup) {
        if (!followData.date || !followData.start || !followData.end) {
          setFollowError({
            dateError: !followData.date,
            sessionError: !followData?.session?.value,
            timeError: !followData.start || !followData.end,
          });
        } else {
          setLoading(true);
          setFileName(file_display_name);
          const payload = {
            data: {
              doctor_id: appointmentDetails?.appointment_details?.doctor_id,
              dept_name: appointmentDetails?.appointment_details?.dept_name,
              dept_id: appointmentDetails?.appointment_details?.dept_id,
              schedule_type: 'op',
              appt_date: formatDate(followData.date),
              appt_start_time: followData.start,
              appt_end_time: followData.end,
              appt_slot_session: followData?.session?.label,
              reason: 'Follow up appointment',
              patient_details: {
                patient_id: appointmentDetails?.patient_details?.patient_id,
                name: appointmentDetails?.patient_details?.name,
                last_name: appointmentDetails?.patient_details?.last_name,
                blood_group: appointmentDetails?.patient_details?.blood_group,
                email: appointmentDetails?.patient_details?.email,
                dob: appointmentDetails?.patient_details?.dob,
                gender: appointmentDetails?.patient_details?.gender,
              },
            },
          };
          addAppointment(
            payload,
            () => addPrescription(dataToView, config, setSaveFile, setLoading,setSuccess),
            setLoading
          );
        }
      } else {
        setLoading(true);
        addPrescription(dataToView, config, setSaveFile, setLoading);
        setFileName(file_display_name);
      }
    } else {
      if(!medicines?.[0]?.medicineName && !medicines?.[0]?.dosage && !medicines?.[0]?.duration) {
        setPrescriptionError(true);
      } else {
        medicines.map((x, i)=> !x.medicineName?.trim() && setMedError((prev)=> ({...prev, [i]: true})))
      }
      if (followup)
        setFollowError({
          dateError: !followData.date,
          sessionError: !followData?.session?.value,
          timeError: !followData.start || !followData.end,
        });
    }
  };

  const handleDownload = (pdfData) => {
    saveAs(
      pdfData.file_link,
      `${pdfData.file_display_name}.${pdfData.record_file_type}`,
      {}
    );
  };

  // handle show more
  const handleShowMore = () => {
    setCurrentPage(currentPage + 1);
    getAppoinmtentsList({ pageNo: currentPage + 1 });
  };

  const checkpermission = (feature, scope) => {
    const permission = permissions?.[feature];
    if (!permission || !permission?.length) {
      return false;
    } else {
      return permission.includes(scope);
    }
  };
  return (
    <div className="patient-view-wrapper">
      <div style={{ display: 'none' }}>
        <ViewPrescription
          medicines={medicines}
          advice={advice}
          prescriptionNote={prescriptionNote}
          patientDetails={appointmentDetails?.patient_details || {}}
          appointmentDetails={appointmentDetails?.appointment_details || {}}
        />
      </div>

      {showPopup && <PdfViewer
        open={showPopup}
        pdfUrl={pdfUrl}
        onClose={() => {
          updateShowPopup(false);
          setPdfUrl('');
        }}
      />}

      {!isLoading ? (
        <div className='patient-view outer-wrapper'>
        <div className="row min-height-wrapper">
          <div className="bread-crumb-container mb-4 fs-12 admin-bread-crumbs">
            <div className="links d-inline">
              <a className="previous" href="#/admin/appointments">
                {translate("appointments.title", "Appointments")}
              </a>
              <span> {">"} </span>
              <a className="bread-crumb-cursor">
                {translate("appointments.appointmentView", "Appointment View")}{" "}
              </a>
            </div>
          </div>
          <div className="col-xl-9 accordian-container">
            <div className="patient-data-collapse">
                <Patientdata
                  patientDetails={appointmentDetails?.patient_details || {}}
                  appointmentDetails={appointmentDetails?.appointment_details || {}}
                />
            </div>
            <div style={{ marginTop: 28 }}>
              <div className="fake-tab">
                <div
                  onClick={upcomingClick}
                  className={`appt-tab ${today ? 'selected-tab' : ''}`}
                  style={{ color: today ? '#5875FF' : '#7B7B7B' }}
                >
                  {translate("patientView.upcoming", "Upcoming")}
                </div>
                <div
                  onClick={previousClick}
                  className={`appt-tab ${previous ? 'selected-tab' : ''}`}
                  style={{ color: previous ? '#5875FF' : '#7B7B7B' }}
                >
                  {translate("patientView.previous", "Previous")}
                </div>
              </div>
              <div className="col-md-5">
                {appointments.length ? (
                  <div className="total-appointment-new">
                    {translate('patientView.total', 'Total Appointments')} :{' '}
                    {total}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>


            {/* maxHeight: 500, overflow: "auto" */}

            <div
              className="mt-0"
              style={{  }}
            >
              {appointments.length ? (
                appointments.map((appt, index) => (
                  <>
                   <div className="custom-accordian">
                   <Accordion
                      style={{
                        boxShadow: "none",
                        backgroundColor: index % 2 === 0 ? "#fff" : "#f6f6f6",
                      }}
                      key={index + 1}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <p className="appt-list d-flex flex-wrap align-items-center gap-2 justify-content-between w-100 pe-3">
                          <span className='doc-dept'>
                          Dr.{" "}{appt.doctor_name} - <span className="text-capitalize">{appt.dept_name}</span>
                          </span>
                          <div className='time'>
                          {formatDateInDDMMYYYY(appt.appt_date)}
                          {" / "}
                          <span>{appendTime(appt.appt_start_time)}</span>
                          </div>
                        </p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="appt-list">
                          <h6 className='accordian-heading'>{translate('patientView.reason', 'Reason For Appointment')}</h6> 
                          {" "}
                          <p className='reason first-capital'>{appt.reason}</p>
                          {previous && appt?.appointment_records?.length && (
                            <div className="mt-1">
                            <p className='accordian-heading'> {translate(
                                "patientView.prescriptions",
                                "Prescriptions"
                              )}</p>
                              <div className="files">
                              {appt?.appointment_records?.map(
                                (record, index) => (
                                  <span className='file-name'
                                    key={index}
                                    onClick={() => {
                                      updateShowPopup(true);
                                      setPdfUrl(record.file_link);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      color: "#3b3be5",
                                    }}
                                  >
                                    <p className=" ml-2 fs-11">
                                      {record.file_display_name}.
                                      {record.record_file_type}
                                    </p>
                                  </span>
                                )
                              )}
                              </div>
                            </div>
                          )}
                        </p>
                      </AccordionDetails>
                    </Accordion>
                   </div>
                  </>
                ))
              ) : !loader ? (
                <div className="patient-portal">
                  <NoResultFound text='No appointments added' removeMarginTop heightClass='nodata-height'/>
                </div>
              ) : null}
              {loader ? <div className="bubble_loader" /> : 
              total > appointments.length ? (
                <button className="moreButton mt-2" onClick={handleShowMore}>
                  {translate("patientView.showMore", "Show More")}
                </button>
              ) : null}
              {/* <hr></hr> */}
            </div>
            {(appointmentDetails?.appointment_details?.live_status ===
              APPOINTMENT_STATUS.inProgress ||
              appointmentDetails?.appointment_details?.live_status ===
                APPOINTMENT_STATUS.completed) && (
              <>
                <div className="row mt-4">
                    <>
                      <div className="col-md-12 d-flex mb-2">
                        <div
                          style={{
                            fontSize: 14,
                            marginTop:10,
                            fontWeight: 500,
                            color: "#000000",
                            cursor: "pointer",
                          }}
                        >
                          {translate(
                            "patientView.prescriptionNote",
                            "Prescription Note"
                          )}
                        </div>
                      </div>
                      {appointmentDetails?.appointment_details?.appointment_records?.length ?
                        appointmentDetails?.appointment_details?.appointment_records.map(
                        (item, index) => (
                          <div key={index} className="col-lg-6 mt-2">
                            <div className="patient-download d-flex">
                              {/* <InsertDriveFileIcon
                                className="mt-1"
                                fontSize="large"
                                color="disabled"
                              /> */}
                              {pdfIcon}
                              <div>
                                <p className="file-name">
                                  {item.file_display_name}.
                                  {item.record_file_type}
                                </p>
                                <div className="action-icons">
                                  {item.file_size && <span className='file-size'>{item.file_size}</span>}
                                  <p
                                    onClick={() => {
                                      updateShowPopup(true);
                                      setPdfUrl(item.file_link);
                                    }}
                                    className="mt-1 badge-prescription"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {translate("patientView.view", "View")}
                                  </p>
                                  <div className="seperator"></div>
                                  <p
                                    onClick={() => handleDownload(item)}
                                    className="mt-1 badge-prescription"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {translate(
                                      "patientView.download",
                                      "Download"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      ) : 
                        (!saveFile &&
                        appointmentDetails?.appointment_details?.live_status ===
                          APPOINTMENT_STATUS.inProgress) || loading ?
                        null :
                        (<div className='patient-portal'>
                          <NoResultFound text='No prescriptions added' removeMarginTop heightClass='nodata-height'/>
                        </div>)
                      }
                    </>
                  {!saveFile &&
                    appointmentDetails?.appointment_details?.live_status ===
                      APPOINTMENT_STATUS.inProgress && (
                      <>
                        <PrescriptionNote
                          medicines={medicines}
                          setMedicines={setMedicines}
                          setAdvice={setAdvice}
                          followup={followup}
                          setFollowup={setFollowup}
                          setFollowData={setFollowData}
                          prescriptionNote={prescriptionNote}
                          setPrescriptionNote={setPrescriptionNote}
                          doctorSlotData={doctorSlotData}
                          followError={followError}
                          setFollowError={setFollowError}
                          referenceData={referenceData}
                          setReferenceData={setReferenceData}
                          prescriptionError={prescriptionError}
                          setPrescriptionError={setPrescriptionError}
                          medError={medError}
                          setMedError={setMedError}
                          doctorDetails={doctorDetails}
                        />
                      </>
                    )}
                </div>
              </>
            )}
          </div>
          <LatestDocuments doctuments={appointmentDetails.lab_reports} />
        </div>
         <div className="back-to-btn gap-3">
         <button
              className="btn btn-outlined"
              onClick={() => history.goBack()}
            >{translate(
              'cancelAppt.backToAppt',
              'Back to listing.'
          )}
            </button>
         {!saveFile && (
                  <div>
                    {appointmentDetails?.appointment_details?.live_status ===
                      APPOINTMENT_STATUS.inProgress && (
                      <div>
                        {checkpermission(APPOINTMENT, COMPLETE_STATUS) && (
                          <button
                            type="button"
                            className="btn btn-primary fit-content d-flex justify-content-center align-items-center"
                            onClick={handlePrescriptionSave}
                            disabled={loading}
                          >
                            {loading ? (
                              <ButtonLoader
                                text={translate(
                                  "patientView.save",
                                  "Save and complete the Appointment"
                                )}
                              />
                            ) : (
                              translate(
                                "patientView.save",
                                "Save and complete the Appointment"
                              )
                            )}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
         </div>
        </div>
      ) : (
        <div
          className="list-user"
          style={{ minHeight: isLoading ? "55vh" : "" }}
        >
          <Loader />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  appointmentDetails: state.AppointmentReducer.appointmentDetails,
  appointmentViewList: state.AppointmentReducer.appointmentViewList,
  doctorSlotData: state.AppointmentReducer.doctorSlotData,
  permissions: state.LoginReducer.userPermissions.ResourceNameScope,
  doctorDetails: state.LoginReducer.doctorDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getAppoinmtentDetails: (id, setLoader) =>
    dispatch(FetchAppointmentDetails(id, setLoader)),
  addPrescription: (id, data, setSaveFile, setLoading,setSuccess) =>
    dispatch(uploadPrescription(id, data, setSaveFile, setLoading,setSuccess)),
  getAppointments: (data, setLoader) =>
    dispatch(FetchAppointmentListWithStatus(data, setLoader)),
  addAppointment: (data, handleSuccess, setLoading) =>
    dispatch(AddAppointmentData(data, handleSuccess, setLoading)),
  clearData: () => dispatch(clearDoctorAppointmentExceptFilter()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientView);
