import React, { useState, useEffect, useRef } from 'react';
import { translate } from '../../../Language/Translate';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { addMedicalRecords } from '../../../Actions/medicalRecordAction';
import { showToaster, ToasterTypes } from 'Actions/toasterAction';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import ButtonLoader from 'Components/Common/ButtonLoader';

function AddMedicalRecord(props) {
  const [formData, setFormData] = useState({});
  const [invalidAlert,setInvalidAlert] = useState({})
  const [files, setFiles] = useState([]);
  const [fileKey,setFileKey]=useState(0);
  const [autoFocus, setAutofocus] = useState(false)
  const intl = useIntl();
  const { btnClick, setBtnClick, addMedicalRecord, getAllMedicalRecords, showToaster, loadingAddRecord , filterByOptions,filterOptions, linkedProfileLoading, toasterRef, userDetails } =
    props;
  const modalRef = useRef();
  const dropDownRef = useRef(null);
  const uploadRef= useRef(null)
  const descriptionRef = useRef(null)
  useEffect(() => {
    const listener = (event) => {
      if (!modalRef?.current || modalRef?.current?.contains(event.target) || toasterRef?.current?.contains(event.target)) {
        return;
      }
      if (event.button === 0){
        setFormData({});
        setBtnClick(false);
      }
    };

    if(btnClick) {
      document.addEventListener('mousedown', listener);
    }

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [modalRef, btnClick]);

  useEffect(() => {
    if (btnClick) {
      setTimeout(() => {
        document.body.classList.add('modal-overflow');
      }, 0);
    } else {
      document.body.classList.remove('modal-overflow');
    }
    return () => document.body.classList.remove('modal-overflow');
  }, [btnClick]);

  useEffect(() => {
    if (!btnClick) {
      setFormData({})
      setFiles([])
      setInvalidAlert({})
    }
  }, [btnClick]);

  const handleFormData = (e) => {
    setAutofocus(false)
    const { name } = e.target
    setInvalidAlert(state => ({
      ...state,
      [name]: false
    }))
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handlePatient = (e) => {
    setInvalidAlert(state => ({
      ...state,
      patientName: false
    }))
    setFormData({ ...formData,patientName: e });
  };
  // const handleFile = (event) => {
  //   let currntfile = [...files];
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     let ten_mb = 10240;
  //     if (file.size / 1024 < ten_mb) {
  //       setFiles([
  //         ...currntfile,
  //         {
  //           record_type: 'lab_report',
  //           record_file_type: file.type,
  //           extension: file.name.split('.')[1],
  //           file_display_name: file.name,
  //           size: parseInt(file.size / 1024),
  //           data: reader.result,
  //         },
  //       ]);
  //     } else {
  //       showToaster(translate('medicalRecords.uploadFileSize', 'File size should be less than 10 mb'),ToasterTypes.Warning)
  //     }
  //   };
  //   if (file) {
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleChange = (event) => {
    setFileKey(fileKey + 1)
    const { name } = event.target
    const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
    const allowedSize = 10240;

    if (!event.target.files) {
      return;
    }
    const selectedFiles = Array.from(event.target.files);
    let currntfile = [...files];

    const selectedFilesPreview = selectedFiles.map((fileItem) => {
      let size = fileItem.size;
      let sizeSuffixIndex = 0;
      const sizeSuffix = ["B", "KB", "MB", "GB"];

      while (size / 1024 > 1) {
        size = size / 1024;
        sizeSuffixIndex++;
      }
      size = Number(size.toFixed(1));

      const name = fileItem.name.split(".").slice(0, -1).join(".");
      const type = fileItem.type.split("/")[0];
      const extension = fileItem.type.split("/")[1];

      return new Promise((resolve) => {
        fileToBase64();
        function fileToBase64() {
          const reader = new FileReader();
          reader.readAsDataURL(fileItem);

          reader.onloadend = function () {
            const base64data = reader.result;
            let result = {
              record_type: 'lab_report',
              record_file_type: type,
              extension: extension,
              file_display_name: name,
              size: size,
              data: base64data,
              sizeSuffix: sizeSuffix[sizeSuffixIndex],
              kbSize:fileItem.size,
              lastModified: fileItem.lastModified,
              type: fileItem.type,
            };
            resolve(result);
          };
        }
      });
    })

    Promise.all(selectedFilesPreview)
      .then((values) => {
        let updatedFiles = [...currntfile, ...values].filter((obj, index) => {
          return index === [...currntfile, ...values].findIndex(o => obj.file_display_name === o.file_display_name &&
            obj.lastModified === o.lastModified &&
            obj.kbSize === o.kbSize &&
            obj.type === o.type);
        })
        const totalFileSize = updatedFiles.reduce((acc, cur) => acc + cur.kbSize, 0)
        const ensureAllowExtensions = updatedFiles.filter((obj) => allowedExtensions.includes(obj.extension));
        // const totalFileSize = updatedFiles.find(cur => {return (cur.kbSize/1024 )> allowedSize})
        if (totalFileSize / 1024 < allowedSize ) {
          if(ensureAllowExtensions.length!==updatedFiles.length){
            showToaster(`${intl.formatMessage({
              id: 'medicalRecords.unsupportedFiles',
              defaultMessage:
                'Unsupported file format',
            })}`,ToasterTypes.Warning)
            updatedFiles=ensureAllowExtensions
          }
        // if (!totalFileSize && updatedFiles.length<=noOfFilesallowed) {
          setInvalidAlert(state => ({
            ...state,
            [name]: false
          }))
          setFiles(updatedFiles)
        // } else if( updatedFiles.length > noOfFilesallowed){
        //   showToaster(translate('medicalRecords.maxFileCount', 'Max Number of Files allowed is 5'), ToasterTypes.Warning)
        // } 
       } else {
          showToaster(translate('medicalRecords.uploadFileSize', 'Overall Files size should be less than 10 MB'), ToasterTypes.Warning)
        }
      })
    .catch((data) => {
      showToaster(data,ToasterTypes.Warning)
    })
  }


  const removeFile = (ind) => {
    let currntfile = [...files];
    currntfile.splice(ind, 1);
    setFiles([...currntfile]);
  };

  const handleMedicalRecords = () => {
    if (!formData?.patientName?.value || !files.length || !formData?.remark?.trim()) {
      setInvalidAlert(state => ({
        ...state,
        patientName: !formData?.patientName?.value,
        upload: !files.length,
        remark: !formData?.remark?.trim()
      }))
      if(!formData?.patientName?.value) dropDownRef?.current.focus();
      else if(!files.length) uploadRef?.current.focus();
      else if(!formData?.remark?.trim()) descriptionRef?.current.focus();
    }else{
    let arr = [];
    if (files.length) {
      files.forEach((file) => {
        let obj = {};
        let filetype = file['data'].split(';')[0];
        let type = '';
        if (filetype.includes('image')) {
          type = 'image';
        } else if (filetype.includes('pdf')) {
          type = 'pdf';
        } else if (filetype.includes('text')) {
          type = 'text';
        }
        obj['record_type'] = file['record_type'];
        obj['record_file_type'] = type;
        obj['file_display_name'] = file['file_display_name'];
        obj['data'] = file['data'];
        obj['patientName'] = formData?.patientName?.value;
        // obj['file_size'] = file['size'];
        arr.push(obj);
      });
      let payload = 
      {
        id: formData?.patientName?.value,
        payload: {
          data: {
            add_medical_record: arr,
            remarks: formData?.remark,
          },
        }
      }
      addMedicalRecord(payload, setBtnClick, getAllMedicalRecords, setFormData);
    }}
  };

  useEffect(() => {
    if(filterByOptions.length == 1){
      setAutofocus(false)
      const defaultUser = filterByOptions.find((item) => item.user_id == userDetails.user_id);  
      setFormData({ ...formData, patientName: defaultUser})
    }else if(filterOptions?.searchName){
      setAutofocus(false)
      if(filterOptions?.searchName?.value)
      {
        setFormData({ ...formData, patientName: filterOptions.searchName})
      }else{
        setFormData({ ...formData, patientName: null})
      }
    }
    else{
      setAutofocus(true)
    }
  },[filterByOptions, userDetails,filterOptions])


  return (
    <>
      <div
        className={`offcanvas-backdrop fade ${btnClick ? 'show' : ''}`}
      ></div>

      <div
        className={`offcanvas offcanvas-end custom-off-canvas add-record-canvas ${
          btnClick ? 'show' : ''
        }`}
        tabIndex="-1"
        id="addMedicalRecord"
        ref={modalRef}
        aria-labelledby="addMedicalLabel"
      >
        <div className="offcanvas-header">
          <h5 id="viewScheduleLabel">
            {translate('medicalRecords.addMedicalRecord', 'Add Medical Record')}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            aria-label="Close"
            onClick={() => setBtnClick(false)}
          ></button>
        </div>

        <div className="offcanvas-body">
          <div className="form-group  mb-4 col-md-7"
          onKeyDown={(e)=>{
            if(e.key === "Tab" && e.shiftKey) e.preventDefault()
          }}
          >
            <label htmlFor="name" className="labelName mandatory">
              {translate('medicalRecords.choosePerson', 'Choose the Person')}
            </label>

            <ReactSelectDropDown
              id="patientName"
              options={filterByOptions}
              onInputChange={(item) => {
                handlePatient(item);
              }}
              validationErr={invalidAlert['patientName']}
              value={formData?.patientName}
              isLoading={linkedProfileLoading}
              autoFocus={autoFocus}
              reference={dropDownRef}
              key={Math.random()}
              targetBody={false}
            />
            <span
              className={`${invalidAlert['patientName'] ? 'err' : 'd-none'}`}
            >
              {translate('common.required', 'This field is required')}
            </span>
          </div>

          <div className="form-group mb-4">
            <span className="label mandatory">
              {translate('medicalRecords.uploadFile', 'Upload File')}
            </span>
              <span className="label d-inline fw-normal ms-1 small">
                (
                {translate(
                  'medicalRecords.uploadDescription',
                  'You can upload the document as PDF, Jpeg, PNG formats.'
                )}
                )
              </span>
            <div className={invalidAlert['upload'] ? "file-uploader input-file-err" : "file-uploader"} ref={uploadRef}>
              <label className="fw-medium" htmlFor="fileUpload">
                <i className="bi bi-upload"></i>
                <span className="color-primary">
                  {translate('medicalRecords.selectFile', 'Select the file')}
                </span>{' '}
                {translate(
                  'medicalRecords.youWantToUpload',
                  'you want to upload'
                )}
                <p className="small fw-normal mb-0">
                  {' '}
                  {translate(
                    'medicalRecords.maxFileUploadSize',
                    'Max. file upload size : 10 MB.'
                  )}
                </p>
              </label>
              <input
                key={fileKey}
                className="input"
                type="file"
                name="upload"
                id="fileUpload"
                onChange={handleChange}
                accept=".pdf,image/*,.txt"
                multiple
              />
            </div>
            <span
              className={`${invalidAlert['upload'] ? 'err' : 'd-none'}`}
            >
              {translate('common.required', 'This field is required')}
            </span>
            <div className="file-listing mt-3">
              {files.map((val, ind) => {
                return (
                  <div key={ind} className="single-file">
                    <span className="file-type-badge">
                      {translate(
                        `medicalRecords.${val.extension}`,
                        val.extension.toUpperCase()
                      )}
                    </span>
                    <a style={{ textDecoration: 'none' }}>
                      {val.file_display_name}
                    </a>
                    <span className="size">{val.size} {val.sizeSuffix}</span>
                    <button
                      className="delete"
                      onClick={() => {
                        removeFile(ind);
                      }}
                    >
                       <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                                                            </svg>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="form-group mb-4">
            <label htmlFor="remark" className="labelName mandatory">
              {translate('medicalRecords.description', 'Description')}
            </label>
            <div className="text-area-wrap">
            <textarea
              className={invalidAlert['remark'] ? "form-control input input-err" : "form-control input"}
              onChange={handleFormData}
              placeholder={intl.formatMessage({
                id: 'medicalRecords.remarkPlaceholder',
                defaultMessage:
                  'i.e, Regular rhythm, No Femoral bruit, No history',
              })}
              name="remark"
              id=""
              cols="30"
              rows="3"
              value={formData?.remark || ''}
              maxLength='100'
              ref={descriptionRef}
            />
                  </div>
            <span
              className={`${invalidAlert['remark'] ? 'err' : 'd-none'}`}
            >
              {translate('common.required', 'This field is required')}
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-end addDoc pt-3">
            <button
              className="px-4 btn btn-primary buttonClick loader-btn"
              onClick={loadingAddRecord ? () => { } : handleMedicalRecords}
              disabled={
                !formData?.patientName?.value || !files.length || !formData?.remark?.trim()
              }
              onKeyDown={(e)=>{
                if(e.key === "Tab" && !e.shiftKey) 
                {
                  e.preventDefault()
                  dropDownRef?.current.focus() 
                }
                }}
            >
              {loadingAddRecord ? <ButtonLoader text={translate('medicalRecords.addDocument', 'Add Document')} />
                : translate('medicalRecords.addDocument', 'Add Document')
              }
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loadingAddRecord: state.MedicalRecordReducer.loadingAddRecord,
  userData: {
    ...state.LoginReducer.loginDetails,
    ...state.LoginReducer.patientDetails,
    ...state.UsersReducer.userData,
  },
  toasterRef:state.ToasterReducer.toasterRef,
  userDetails: state.LoginReducer.loginDetails,
});

const mapDispatchToProps = (dispatch) => ({
  addMedicalRecord: (data, isModalOpen, getAllData, clearData) =>
    dispatch(addMedicalRecords(data, isModalOpen, getAllData, clearData)),
  showToaster: (message,type) => dispatch(showToaster(message,type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMedicalRecord);
