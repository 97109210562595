import React from 'react'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';


const TableColumnHr = ({ item, columnSort, setColumnSort ,sortEnable = true }) => {

  const handleColumnSort = (value,_value="") => {
    const currentVal = columnSort[value]
    if (currentVal) {
     _value?setColumnSort({ [value]: currentVal === 'asc' ? 'desc' : 'asc', [_value]: currentVal === 'asc' ? 'desc' : 'asc' }): setColumnSort({ [value]: currentVal === 'asc' ? 'desc' : 'asc' })
    } else {
      _value?setColumnSort({ [value]: 'asc',[_value]:"asc" }): setColumnSort({ [value]: 'asc' })
    }
  }


  return (
    <th width={item.width}>
      <span className={item?.isEnableSort &&sortEnable ? 'table-header':''}onClick={
        item?.isEnableSort && sortEnable ? () => {
          handleColumnSort(item.value,item?._value)
        } : () => { }
      }>
        <span className='table-header-label' >
          {item.heading}{' '}
          {item?.isEnableSort &&sortEnable && columnSort[item.value] &&
            (
              <span className='table-header-icon-show'>
                {columnSort[item.value] === 'desc' ?
                  <ArrowDownward sx={{ fontSize: 15 }} /> :
                  columnSort[item.value] === 'asc' ?
                    <ArrowUpward sx={{ fontSize: 15 }} /> :
                    null
                }
              </span>
            )
          }
        </span>
        {!columnSort[item.value] && item?.isEnableSort &&sortEnable &&
          (<span className='table-header-icon-hide'>
            <ArrowDownward sx={{ fontSize: 15 }} />
          </span>)}
      </span>
    </th>)
}

export default TableColumnHr