import React, { useEffect, useState ,useRef} from 'react';
import '../../Assets/style/conformPopup.css';
import CloseIcon from '@mui/icons-material/Close';
import OtpForm from '../Common/OtpForm';
import PhoneNumberInput from '../Common/PhoneInput';
import {
  CLEAR_PATIENT_DATA,
  FetchPatientsData,
} from '../../Actions/appointmentAction';
import { connect } from 'react-redux';
import { translate } from '../../Language/Translate';
import { validateEmail } from '../../Utils/Validation/validator';
import ReactSelectDropDown from '../Common/ReactSelectDropDown';
import {
  BLOOD_GROUP_LABELS,
  BLOOD_GROUP_OPTIONS,
  GENDER_LABEL,
  GENDER_LIST,
} from '../../Constants/constant';
import { formatDate } from '../../Utils/DateFormatter/Date';
import AntDatepicker from '../Common/AntdDatePicker';
import moment from 'moment';
// import useDidMountEffect from 'Hooks/useDidMountEffect';
import ButtonLoader from 'Components/Common/ButtonLoader';
import { showToaster, ToasterTypes } from 'Actions/toasterAction';

let apiTimeout = null;

const ConformAppointment = (props) => {
  const {
    onConfirm,
    onClose,
    patientReg,
    setPatientReg,
    isProceed,
    patientData,
    setPatientData,
    otp,
    setOtp,
    resendOtp,
    isValid,
    validationError,
    handleMobileValidation,
    isSelect,
    setIsSelect,
    setIsValid,
    setError,
    isOtpRequired,
    patientsData,
    getPatientsData,
    clearPatientData,
    intl,
    countdown,
    otpData,
    patientsDataLoading = {},
    patientsDataIsEmpty,
    defaultCountryCode,
    clearCountdown,
    resendCounter,
    otp_resend_limit,
    resend,
    applicationData,
    isBtnLoading,
    setCountdown,
    validateMob,
    numberLength,
    setNumberLength,
    existingData,
    setExistingData,
    existingOtp,
    setExisingOtp,
    setUnverifyPatient,
    displayToaster,
    code,
    setCode,
    resendLoader
  } = props;

  const { addAppt: addApptLoading = false, verifyOtpPatient: verifyOtpPatientLoading = false ,validateMob:validateInactiveUser = false} = isBtnLoading;

  //  tab navigation  for phone number and country code
const phoneInputRef = useRef()
const focusRef = useRef()

  const [feildError, setFeildError] = useState({
    phoneError: false,
    nameError: false,
    otpError: false,
    dobError: false,
    genderError: false,
    reasonError: false,
  });

  const [invalidMail, setInvalidMail] = useState(false);

  const {
    phoneError,
    nameError,
    otpError,
    reasonError,
    dobError,
    genderError,
  } = feildError;

  useEffect(() => {
    document.body.classList.add('modal-overflow');
    return () => {
      document.body.classList.remove('modal-overflow');
      clearCountdown();
    };
  }, []);

  const {
    name,
    last_Name,
    patientMRN,
    emailId,
    reason,
    mobileNo,
    dob,
    gender,
    bloodGroup,
    countryCode,
  } = patientData || {};

  const { newPatient, existingPatient } = patientReg || {};
  const [mobileSearch, setMobileSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(null);
  const [countryErr, setCountryErr] = useState(countryCode);

  // for disable while select inactive user number
  const [inactiveuser,setInactiveUser] = useState(false)

  // api call with 3sec delay
  useEffect(() => {
    if (!isSelect) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadOptions(mobileSearch);
      }, 300);
      apiTimeout = timeout;
    }
    return () => clearPatientData();
  }, [mobileSearch, isSelect]);

  // useDidMountEffect(() => {
  //   if (
  //     mobileSearch &&
  //     !isSelect &&
  //     patientsDataIsEmpty &&
  //     !patientsDataLoading
  //   ) {
  //     setError(
  //       translate('appointments.mobileNumberNoFound', 'Mobile number not found')
  //     );
  //   } else if (
  //     mobileSearch &&
  //     !isSelect &&
  //     !patientsDataIsEmpty &&
  //     !patientsDataLoading
  //   ) {
  //     setError('');
  //   } else if (!mobileSearch && !isSelect && existingPatient) {
  //     setError(
  //       translate(
  //         'appointments.selectExistingUser',
  //         'Please select an existing number from the list'
  //       )
  //     );
  //   }
  // }, [mobileSearch, isSelect, patientsDataIsEmpty, patientsDataLoading]);

  // handle radio button change
  const handleRadioChange = (name) => {
    setPatientReg({
      newPatient: name === 'newPatient' ? true : false,
      existingPatient: name === 'existingPatient' ? true : false,
    });
    setPatientData((prev) => ({
      ...prev,
      patientId: '',
      mobileNo: '',
      countryCode: defaultCountryCode?.toString(),
      name: '',
      last_Name: '',
      patientMRN: '',
      bloodGroup: '',
      emailId: '',
      dob: '',
      gender: '',
    }));
    setExistingData({
      name: '',
      email: '',
      bloodGroup: '',
      dob: '',
      gender: '',
      patientMRN: '',
    });
    setMobileSearch('');
    setIsValid(false);
    setInactiveUser(false)
    setExisingOtp(false)
    setError('');
    setOtp('');
    clearPatientData();
    setFeildError({
      phoneError: false,
      nameError: false,
      otpError: false,
      reasonError: false,
    });
    clearCountdown();
    setCountdown();
    setUnverifyPatient(false);
  };

  // handle input data change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'name') {
      setFeildError((prev) => ({
        ...prev,
        nameError: false,
      }));
    } else if (name === 'reason') {
      setFeildError((prev) => ({
        ...prev,
        reasonError: false,
      }));
    } else if (name === 'emailId' && (validateEmail(value) || !value)) {
      setInvalidMail(false);
    }
    setPatientData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // handle mobile search
  const handleMobileSearch = (event) => {
    const { value } = event.target;
    // const re = /^[+]?[0-9]?[0-9 ]*$/;
    const re = /^\+?[0-9 ]*\.?[0-9 ]*$/;
    if (re.test(value) || !value) {
      setError('');
      setMobileSearch(value);
      setIsSelect(false);
    }
  };

  // load options to the dropdown on typing
  const loadOptions = async (inputValue) => {
    if (inputValue?.trim()?.replace('+', '')) {
      setCurrentPage(1);
      getPatientsData(
        {
          phone: inputValue.replace('+', ''),
          limit: 10,
          page: 1,
          // status: 'active',
        },
        setCount
      );
    }
  };

  // handle mobile number selection from list
  const handleMobileSelect = (data) => {
    setIsSelect(true);
    setUnverifyPatient(false)
    setInactiveUser(false)
    setExisingOtp(false)
    setPatientData((prev) => ({
      ...prev,
      countryCode: data.tele_country_code,
      mobileNo: data.phone,
      name: data.name,
      last_Name: data.last_name,
      bloodGroup: data.blood_group,
      emailId: data.email,
      patientMRN: data.mrn,
      patientId: data.patient_id,
      dob: data.dob,
      gender: data.gender,
    }));
    setExistingData({
      name: data.name ? data.name + ' ' + data.last_name : '',
      email: data.email,
      bloodGroup: data.blood_group,
      dob: data.dob,
      gender: data.gender,
      patientMRN: data.mrn,
    });
    setFeildError((prev) => ({
      ...prev,
      phoneError: false,
    }));
    setError('');
    if (!data.phone_verified && (data.user_status === "active" || (data.user_status === "inactive" && !data.email_verified))) {
      setUnverifyPatient(true)
    }

    //user manually inactivated by admin for some reason
    if (data.user_status === "inactive" && (data.email_verified || data.phone_verified)) {
      setInactiveUser(true);
      displayToaster(translate('appointments.inactiveusererror','This user is inactive, please activate the user and proceed with the appointment booking'),ToasterTypes.Failed)
    }
    setMobileSearch(renderPhoneNumber(data));
    clearPatientData();
  };

  // handle country number change
  const handleCountryNumberChange = (phone, country) => {
    setCountryErr(country?.dialCode)
    setPatientData((prev) => ({
      ...prev,
      countryCode: country?.dialCode,
    }));
    setIsValid(false);
    setFeildError((prev) => ({
      ...prev,
      otpError: false,
    }));
  };

  // handle mobile number change
  const handleMobileNumberChange = (event) => {
    const { value: phone } = event.target;
    const regex = /^\+?[0-9 ]*\.?[0-9 ]*$/;
    if (regex.test(phone)) {
      setPatientData((prev) => ({
        ...prev,
        mobileNo: phone,
      }));
      setFeildError((prev) => ({
        ...prev,
        phoneError: false,
      }));
      setError('');
      setIsValid(false);
      setFeildError((prev) => ({
        ...prev,
        otpError: false,
      }));
    }
  };

  // handle scroll bottom
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight - scrollTop === clientHeight && count >= currentPage + 1) {
      getPatientsData(
        { phone: mobileSearch, limit: 10, page: currentPage + 1 },
        () => { },
        true
      );
      setCurrentPage(currentPage + 1);
    }
  };

  // handle confirm
  const handleConfirm = () => {
    onConfirm();
    if (isProceed) {
      setFeildError((prev) => ({
        ...prev,
        nameError: !name?.trim(),
        reasonError: !reason?.trim(),
        genderError: !gender,
        dobError: !dob,
      }));
      if (emailId && !validateEmail(emailId)) {
        setInvalidMail(true);
      }
    } else {
      if (newPatient && isValid && !isProceed) {
        setFeildError((prev) => ({
          ...prev,
          phoneError: !mobileNo,
          otpError: !otp || !otp?.join('')?.trim() || otp?.length !== otpData.len,
        }));
      } else {
        setFeildError((prev) => ({
          ...prev,
          phoneError: !mobileNo,
        }));
      }
    }
  };

  // handle mobile number validation
  const handleValidation = () => {
    if (!mobileNo || !countryErr) {
      setFeildError((prev) => ({
        ...prev,
        phoneError: true,
      }));
    }
    handleMobileValidation();
  };

  // handle OTP change
  const handleOTPChange = (value, index) => {
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      if (newOtp?.join('')?.length === otpData?.len) {
        setFeildError((prev) => ({
          ...prev,
          otpError: false,
        }));
        setError('');
      }
      return newOtp;
    });
  };

  // handle bloodgroup change
  const handleBloodGroupChange = (value) => {
    setPatientData((prev) => ({
      ...prev,
      bloodGroup: value?.value,
    }));
  };

  // handle gender change
  const handleGenderChange = (value) => {
    setFeildError((prev) => ({
      ...prev,
      genderError: false,
    }));
    setPatientData((prev) => ({
      ...prev,
      gender: value?.value,
    }));
  };

  // handle dob change
  const handleDobChange = (date) => {
    setFeildError((prev) => ({
      ...prev,
      dobError: false,
    }));
    setPatientData((prev) => ({
      ...prev,
      dob: formatDate(date),
    }));
  };

  const handleKeyDown = (e)=>{
    const dropDownBtn = document.querySelector('.selected-flag')
    if(e.key === "Enter"){
      if(!dropDownBtn.classList.contains("open")){
        e.preventDefault()
        dropDownBtn.classList.toggle("open")
        dropDownBtn.click()
      }
      else
      {
        phoneInputRef.current?.focus()
      }
    }
    if(e.key === "Tab" && dropDownBtn.classList.contains("open")){
      e.preventDefault()
      dropDownBtn.classList.contains("open")
      focusRef.current?.focus()
        dropDownBtn.click()
    }
  }
  // show phone number with hyphen and county code
  const renderPhoneNumber = (data) => {
    const { phone, tele_country_code } = data || {};
    return `+${tele_country_code}${phone}`;
  };

  // disable futeure dates for ant datepicker
  const disableFutureDates = (current) => {
    return current && current > moment();
  };

  return (
    <>
      <div className="AppointmentModal d-flex">
        <div
          className={`modalContent appointmentConfirm_popup v2 ${
            isProceed ? "add-patient-info-popup" : ""
          }`}
        >
          <div className="modalHeader">
            <div className="row">
              <div className="col-md-12">
                <h4>
                  {translate(
                    "appointments.patientInfo",
                    "Add Patient Information"
                  )}
                  <CloseIcon
                    className="close-icon"
                    style={{
                      float: "right",
                      cursor: "pointer",
                      color: "#75808E",
                    }}
                    onClick={onClose}
                  />
                </h4>
              </div>
              <div className="px-0">
                <div className="border_btm mt-2"></div>
                <div
                  className="modalContentarea-popup pb-0"
                  style={{
                    maxHeight: 430,
                    marginBottom: 10,
                    overflowX: "auto",
                  }}
                >
                  <div className="container appointmentLabel mb-1 admin-add-patient mb-4">
                    {!isProceed && (
                      <>
                        <div className="col-md-12">
                          <div
                            className="btn-group custom-radio col-12"
                            role="group"
                            aria-label="Basic radio toggle button group"
                          >
                            <input
                              type="radio"
                              className="btn-check"
                              name="btnradio"
                              id="btnradio1"
                              autoComplete="off"
                              checked={existingPatient}
                              onClick={() =>
                                handleRadioChange("existingPatient")
                              }
                            />
                            <label
                              className="btn btn-outline-primary"
                              style={
                                existingPatient
                                  ? { cursor: "default" }
                                  : { cursor: "pointer" }
                              }
                              htmlFor="btnradio1"
                            >
                              {intl.formatMessage({
                                id: "appointments.existingPatient",
                                defaultMessage: "Existing Patient",
                              })}
                            </label>

                            <input
                              type="radio"
                              className="btn-check"
                              name="btnradio"
                              id="btnradio2"
                              autoComplete="off"
                              checked={newPatient}
                              onClick={() => handleRadioChange("newPatient")}
                            />
                            <label
                              className="btn btn-outline-primary"
                              style={
                                newPatient
                                  ? { cursor: "default" }
                                  : { cursor: "pointer" }
                              }
                              htmlFor="btnradio2"
                            >
                              {intl.formatMessage({
                                id: "appointments.newPatient",
                                defaultMessage: "New Patient",
                              })}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12 tel-wrap">
                          <div className={"form-group mb-0"}>
                            <label htmlFor="User name">
                              {existingPatient
                                ? translate(
                                    "appointments.selectMobileNumber",
                                    "Select Mobile Number"
                                  )
                                : translate(
                                    "appointments.enterMobileNumber",
                                    "Enter Mobile Number"
                                  )}
                              <span className="sup">*</span>
                            </label>
                            {existingPatient ? (
                              <>
                                <input
                                  // type="number"
                                  className="form-control myInput"
                                  id="departmentname"
                                  autoComplete="off"
                                  name="mobileNo"
                                  placeholder={intl.formatMessage({
                                    id: "appointments.searchMobile",
                                    defaultMessage: "Search by mobile number",
                                  })}
                                  maxLength="16"
                                  style={{
                                    borderColor:
                                      validationError && !existingOtp && "red",
                                  }}
                                  value={mobileSearch}
                                  onChange={handleMobileSearch}
                                />
                                {Object.values(patientsDataLoading).includes(
                                  true
                                ) ? (
                                  <p className="mt-1 fs-10 fw-500">
                                    {translate("common.loading", "Loading...")}
                                  </p>
                                ) : patientsData.length === 0 &&
                                  patientsDataIsEmpty &&
                                  !Object.values(patientsDataLoading).includes(
                                    true
                                  ) &&
                                  mobileSearch ? (
                                  <p
                                    className="mt-1 fs-11 fw-500"
                                    style={{ color: "red" }}
                                  >
                                    {translate(
                                      "appointments.mobileNumberNoFound",
                                      "Mobile number not found"
                                    )}
                                  </p>
                                ) : (
                                  validationError &&
                                  !existingOtp && (
                                    <div
                                      // style={{ marginTop: '-20px' }}
                                      className="col-md-12 container ps-0"
                                    >
                                      <p
                                        className="fs-11 fw-500"
                                        style={{ color: "red" }}
                                      >
                                        {validationError}
                                      </p>
                                    </div>
                                  )
                                )}
                                {/* {validationError && !existingOtp && (
                                  <div
                                    // style={{ marginTop: '-20px' }}
                                    className="col-md-12 container ps-0"
                                  >
                                    <p
                                      className="fs-12 fw-500"
                                      style={{ color: 'red' }}
                                    >
                                      {validationError}
                                    </p>
                                  </div>
                                )} */}
                                {/* {mobileSearch &&
                                  !isSelect &&
                                  patientsDataIsEmpty &&
                                  !patientsDataLoading && (
                                    <p
                                      className="mt-1 fs-10 fw-500"
                                      style={{ color: 'red' }}
                                    >
                                      {translate(
                                        'appointments.mobileNumberNoFound',
                                        'Mobile number not found'
                                      )}
                                    </p>
                                  )} */}
                                {!Object.values(patientsDataLoading).includes(
                                  true
                                ) &&
                                patientsData.length &&
                                mobileSearch?.trim() &&
                                !validationError ? (
                                  <div
                                    className="customSearchList"
                                    style={{ position: "relative" }}
                                    onScroll={handleScroll}
                                  >
                                    <ul style={{ marginBottom: 1 }}>
                                      {patientsData.map((data, index) => (
                                        <li
                                          className={`suggestion-item`}
                                          onClick={() =>
                                            handleMobileSelect(data)
                                          }
                                          key={index + 1}
                                        >
                                          {renderPhoneNumber(data)}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <div className="row">
                                {isOtpRequired ? (
                                  <>
                                    <div className="verify-btn-wrap d-flex gap-3">
                                      <div
                                        //   className={`${phoneError && 'error'} code-area col-3 me-2`
                                        // }
                                        className="code-area"
                                        onKeyDown={(e) => {
                                          if (e.key === "Tab" && !e.shiftKey) {
                                            e.preventDefault();
                                            phoneInputRef.current.focus();
                                          }
                                          handleKeyDown(e);
                                        }}
                                      >
                                        <PhoneNumberInput
                                          value={countryCode}
                                          onChange={handleCountryNumberChange}
                                          // validationErr={phoneError}
                                          numberLength={numberLength}
                                          setNumberLength={setNumberLength}
                                          code={code}
                                          setCode={setCode}
                                          focusRef={focusRef}
                                        />
                                      </div>
                                      <div className="w-100">
                                        <div className="verify-input-container">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="password"
                                            value={mobileNo}
                                            name="mobileNo"
                                            maxLength={numberLength}
                                            style={{
                                              borderColor:
                                                (phoneError ||
                                                  (validationError &&
                                                    validationError !==
                                                      intl.formatMessage({
                                                        id: "appointments.enterOTPToProceed",
                                                        defaultMessage:
                                                          "Enter the OTP to proceed",
                                                      }))) &&
                                                "red",
                                            }}
                                            ref={phoneInputRef}
                                            onChange={handleMobileNumberChange}
                                            onKeyDown={(e) => {
                                              if (
                                                e.shiftKey &&
                                                e.key === "Tab"
                                              ) {
                                                e.preventDefault();
                                                focusRef.current.focus();
                                              }
                                            }}
                                          />
                                          <div>
                                            <button
                                              className="verify-btn loader-btn d-flex justify-content-center align-items-center"
                                              onClick={handleValidation}
                                              disabled={
                                                isValid ||
                                                validateMob ||
                                                !mobileNo
                                              }
                                            >
                                              {validateMob ? (
                                                <ButtonLoader
                                                  text={translate(
                                                    "appointments.validate",
                                                    "Validate"
                                                  )}
                                                />
                                              ) : (
                                                translate(
                                                  "appointments.validate",
                                                  "Validate"
                                                )
                                              )}
                                            </button>
                                          </div>
                                        </div>
                                        {validationError &&
                                          validationError !==
                                            intl.formatMessage({
                                              id: "appointments.enterOTPToProceed",
                                              defaultMessage:
                                                "Enter the OTP to proceed",
                                            }) && (
                                            <div className="col-md-12 container">
                                              <p
                                                className="fs-12 fw-500"
                                                style={{ color: "red" }}
                                              >
                                                {validationError}
                                              </p>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="col-md-12">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="departmentname"
                                      autoComplete="off"
                                      name="mobileNo"
                                      maxLength="16"
                                      value={mobileNo}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {newPatient && isValid && !isProceed && (
                      <>
                        <div className="col-md-12 mt-4 add-patient-otp">
                          <div className={"form-group"}>
                            <label htmlFor="User name">
                              {translate("appointments.enterOTP", "Enter OTP")}
                              <span className="sup">*</span>
                            </label>
                            <OtpForm
                              otp={otp}
                              setOtp={handleOTPChange}
                              otpData={otpData}
                              validatinErr={otpError}
                            />
                          </div>
                          {validationError && (
                            <div
                              style={{ marginTop: "-20px" }}
                              className="col-md-12 container ps-0"
                            >
                              <p
                                className="fs-12 fw-500"
                                style={{ color: "red" }}
                              >
                                {validationError}
                              </p>
                            </div>
                          )}
                          {resendCounter < 1 && countdown > 0 ? (
                            <div
                              style={{
                                color: "green",
                                fontSize: 14,
                              }}
                            >
                              {translate(
                                "patientPortal.otpExpire",
                                "OTP will expire in"
                              )}{" "}
                              {countdown} {translate("appointments.sec", "sec")}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-12 mt-3">
                          {resendCounter < 1 &&
                          countdown <= 0 &&
                          resend < otp_resend_limit ? (
                            <p className="mt-2 mb-0 fs-13">
                              {translate(
                                "appointments.didntReceive",
                                "Didn't receive OTP ?"
                              )}
                              {resendLoader ? (
                                <span className="forgotSignin loading" style={{marginLeft: 10}}>
                                  {" "}
                                  {translate(
                                    "appointments.resend",
                                    "Resend OTP"
                                  )}{" "}
                                </span>
                              ) : (
                                <span
                                  className="forgotSignin"
                                  onClick={() => {
                                    resendOtp();
                                    setFeildError((prev) => ({
                                      ...prev,
                                      otpError: false,
                                    }));
                                  }}
                                >
                                  {" "}
                                  {translate(
                                    "appointments.resend",
                                    "Resend OTP"
                                  )}{" "}
                                </span>
                              )}
                            </p>
                          ) : (
                            ""
                          )}
                          {resendCounter > 0 && (
                            <div className="exceeded-msg text-center">
                              {intl.formatMessage({
                                id: "commom.otpLimitMsg",
                                defaultMessage:
                                  "OTP send limit exceeded please try again after 5 min",
                              })}
                              {/* {translate('patientPortal.resend', 'Resend OTP')} */}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {existingOtp && (
                      <>
                        <div className="col-md-12 mt-4 add-patient-otp">
                          <div className={"form-group"}>
                            <label htmlFor="User name">
                              {translate("appointments.enterOTP", "Enter OTP")}
                              <span className="sup">*</span>
                            </label>
                            <OtpForm
                              otp={otp}
                              setOtp={handleOTPChange}
                              otpData={otpData}
                              validatinErr={validationError}
                            />
                          </div>
                          {validationError && (
                            <div
                              style={{ marginTop: "-20px" }}
                              className="col-md-12 container ps-0"
                            >
                              <p
                                className="fs-12 fw-500"
                                style={{ color: "red" }}
                              >
                                {validationError}
                              </p>
                            </div>
                          )}
                          {resendCounter < 1 && countdown > 0 ? (
                            <div
                              style={{
                                color: "green",
                                fontSize: 14,
                              }}
                            >
                              {translate(
                                "patientPortal.otpExpire",
                                "OTP will expire in"
                              )}{" "}
                              {countdown} {translate("appointments.sec", "sec")}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-12 mt-3">
                          {resendCounter < 1 &&
                          countdown <= 0 &&
                          resend < otp_resend_limit ? (
                            <p className="mt-2 mb-0 fs-13">
                              {translate(
                                "appointments.didntReceive",
                                "Didn't receive OTP ?"
                              )}
                              {resendLoader ? (
                                <span className="forgotSignin loading" style={{marginLeft: 10}}>
                                  {" "}
                                  {translate(
                                    "appointments.resend",
                                    "Resend OTP"
                                  )}{" "}
                                </span>
                              ) : (
                                <span
                                  className="forgotSignin"
                                  onClick={() => {
                                    resendOtp();
                                    setFeildError((prev) => ({
                                      ...prev,
                                      otpError: false,
                                    }));
                                  }}
                                >
                                  {" "}
                                  {translate(
                                    "appointments.resend",
                                    "Resend OTP"
                                  )}{" "}
                                </span>
                              )}
                            </p>
                          ) : (
                            ""
                          )}
                          {resendCounter > 0 && (
                            <div className="exceeded-msg text-center">
                              {intl.formatMessage({
                                id: "commom.otpLimitMsg",
                                defaultMessage:
                                  "OTP send limit exceeded please try again after 5 min",
                              })}
                              {/* {translate('patientPortal.resend', 'Resend OTP')} */}
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    {isProceed && (
                      <>
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <div className={"form-group"}>
                              <label htmlFor="User name">
                                {translate("common.firstName", "First Name")}
                                {existingData.name ? null : (
                                  <span className="sup">*</span>
                                )}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="name"
                                maxLength="40"
                                autoComplete="off"
                                style={{
                                  borderColor: nameError ? "red" : "",
                                }}
                                value={name}
                                disabled={!!existingData.name}
                                onChange={handleInputChange}
                                onKeyDown={(e) => {
                                  if (e.key === "Tab" && e.shiftKey)
                                    e.preventDefault();
                                }}
                              />
                              {nameError ? (
                                <p
                                  className="fs-10 fw-500"
                                  style={{ color: "red" }}
                                >
                                  {translate(
                                    "common.required",
                                    "This field is required"
                                  )}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className={"form-group"}>
                              <label htmlFor="User name">
                                {translate("common.lastName", "Last Name")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="last_Name"
                                id="last_Name"
                                maxLength="40"
                                autoComplete="off"
                                // style={{ borderColor: nameError && 'red' }}
                                value={last_Name}
                                disabled={!!existingData.name}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div
                              className={`form-group ${
                                patientData.dob ? "" : dobError && "error"
                              }`}
                            >
                              <label htmlFor="User name">
                                {translate("appointments.dob", "Date of birth")}
                                {existingData.dob ? null : (
                                  <span className="sup">*</span>
                                )}
                              </label>
                              <AntDatepicker
                                onInputChange={handleDobChange}
                                value={dob}
                                placeholder={"DD/MM/YYYY"}
                                className="form-control"
                                disabled={!!existingData.dob}
                                disabledDate={disableFutureDates}
                                isAdmin
                              />
                              {dobError ? (
                                <p
                                  className="fs-10 fw-500"
                                  style={{ color: "red" }}
                                >
                                  {translate(
                                    "common.required",
                                    "This field is required"
                                  )}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className={"form-group"}>
                              <label htmlFor="User name">
                                {translate("common.bloodGroup", "Blood Group")}
                              </label>
                              <ReactSelectDropDown
                                options={BLOOD_GROUP_OPTIONS}
                                value={
                                  bloodGroup
                                    ? {
                                        label: BLOOD_GROUP_LABELS[bloodGroup],
                                        value: bloodGroup,
                                      }
                                    : []
                                }
                                onInputChange={handleBloodGroupChange}
                                isDisabled={!!existingData.bloodGroup}
                                emptyClass
                              />
                            </div>
                          </div>
                          {applicationData?.use_system_generated_mrn ? (
                            ""
                          ) : (
                            <div className="col-md-4">
                              <div className={"form-group"}>
                                <label htmlFor="User name">
                                  {translate(
                                    "appointments.patientMRN",
                                    "Patient MRN"
                                  )}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="patientMRN"
                                  value={patientMRN}
                                  maxLength="40"
                                  id="departmentname"
                                  disabled={!!existingData.mrn}
                                  autoComplete="off"
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          )}
                          <div className="col-md-4">
                            <div className={"form-group"}>
                              <label htmlFor="User name">
                                {translate("appointments.gender", "Gender")}
                                {existingData.gender ? null : (
                                  <span className="sup">*</span>
                                )}
                              </label>
                              <ReactSelectDropDown
                                options={GENDER_LIST}
                                value={
                                  gender
                                    ? {
                                        label: GENDER_LABEL[gender],
                                        value: gender,
                                      }
                                    : []
                                }
                                onInputChange={handleGenderChange}
                                validationErr={
                                  patientData.gender ? "" : genderError
                                }
                                isDisabled={!!existingData.gender}
                                customClass="mb-0"
                                emptyClass
                              />
                              {genderError ? (
                                <p
                                  className="fs-10 fw-500"
                                  style={{ color: "red" }}
                                >
                                  {translate(
                                    "common.required",
                                    "This field is required"
                                  )}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4 mb-3">
                            <label htmlFor="User name">
                              {translate(
                                "appointments.mobile",
                                "Mobile Number"
                              )}
                              {/* <span className="sup">*</span> */}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="mobileNumber"
                              name="mobileNumber"
                              value={"+" + countryCode + mobileNo}
                              maxLength="16"
                              disabled
                            />
                          </div>
                          <div className="col-md-8">
                            <div className={"form-group"}>
                              <label htmlFor="User name">
                                {translate("common.emailId", "Email ID")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="emailId"
                                value={emailId}
                                maxLength="50"
                                id="departmentname"
                                disabled={!!existingData.email}
                                autoComplete="off"
                                onChange={handleInputChange}
                              />
                              {invalidMail ? (
                                <p
                                  className="fs-10 fw-500"
                                  style={{ color: "red" }}
                                >
                                  {intl.formatMessage({
                                    id: "appointments.enterValidEmail",
                                    defaultMessage:
                                      "Please enter valid emailId",
                                  })}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <label htmlFor="User name">
                                {translate("common.reason", "Reason")}
                                <span className="sup">*</span>
                              </label>
                              <div className="text-area-wrap">
                              <textarea
                                type="text"
                                className="form-control input"
                                id="departmentname"
                                name="reason"
                                value={reason}
                                maxLength="200"
                                autoComplete="off"
                                rows={2}
                                onChange={handleInputChange}
                                style={{
                                  borderColor: reasonError ? "red" : "",
                                }}
                              />
                              </div>
                              {reasonError ? (
                                <p
                                  className="fs-10 fw-500"
                                  style={{ color: "red" }}
                                >
                                  {translate(
                                    "common.required",
                                    "This field is required"
                                  )}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="">
                  <div className="modal-footer-single-btn col-12">
                    <button
                      type="button"
                      className="btn btn-primary loader-btn"
                      id="save"
                      disabled={
                        addApptLoading ||
                        verifyOtpPatientLoading ||
                        inactiveuser ||
                        (validateInactiveUser && existingPatient)
                      }
                      onClick={handleConfirm}
                      onKeyDown={(e) => {
                        if (e.key === "Tab" && !e.shiftKey) e.preventDefault();
                      }}
                    >
                      {addApptLoading ||
                      (validateInactiveUser && existingPatient) ? (
                        <ButtonLoader
                          text={
                            isProceed
                              ? translate("appointments.next", "NEXT")
                              : translate(
                                  "appointments.nextStep",
                                  "ADD PATIENT INFORMATION"
                                )
                          }
                        />
                      ) : isProceed ? (
                        translate("appointments.next", "NEXT")
                      ) : verifyOtpPatientLoading ? (
                        <ButtonLoader
                          text={translate(
                            "appointments.nextStep",
                            "ADD PATIENT INFORMATION"
                          )}
                        />
                      ) : (
                        translate(
                          "appointments.nextStep",
                          "ADD PATIENT INFORMATION"
                        )
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  patientsData: state.AppointmentReducer.patientsData,
  patientsDataLoading: state.AppointmentReducer.patientsDataLoading,
  patientsDataIsEmpty: state.AppointmentReducer.patientsDataIsEmpty,
  defaultCountryCode:
    state.SettingsReducer.subscriptionData.setting.defaultCountryCode,
  applicationData: state.SettingsReducer.applicationData,
  isBtnLoading: state.AppointmentReducer.isBtnLoading,
});
const mapDispatchToProps = (dispatch) => ({
  getPatientsData: (data, setCount, isScroll) =>
    dispatch(FetchPatientsData(data, setCount, isScroll)),
  clearPatientData: () => dispatch({ type: CLEAR_PATIENT_DATA }),
  displayToaster: (message, type) => dispatch(showToaster(message, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ConformAppointment);
