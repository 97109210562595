export const LOCALES = {
  ENGLISH: 'en-US',
  FRENCH: 'fr-FR',
  JAPANESE: 'ja-JA',
};

export const LANGUAGE_LABELS = {
  [LOCALES.ENGLISH]: 'English',
  [LOCALES.FRENCH]: 'French',
  [LOCALES.JAPANESE]: 'Japanese',
};
