import React, { useEffect, useState } from 'react';
import HomeLayout from './HomeLayout';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import PrivateRoute from '../../Utils/Services/PrivateRoute';
import Departments from '../Departments/Departments';
import Settings from '../Settings/Settings';
import AppointmentMain from '../Appointments/AppointmentsMain';
import NotFound from '../NotFound/NotFound';
import DoctortMain from '../Doctors/DoctorMain';
import User from '../User/User';
import LeaveApproval from '../Doctors/LeaveApproval/LeaveApproval';
import SlotManagement from '../Doctors/SlotManagement/';
// import DoctorAppointments from '../Doctor_Portal/Appointments';
import DoctorPortal from '../Doctor_Portal/DoctorPortal';
import {
  clearLocalStorage,
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../Utils/LocalStorage/local-storage';
import { useDispatch, useSelector } from 'react-redux';
import { FetchUserDetails } from '../../Actions/loginAction';
import SystemSettings from 'Components/Settings/System_Settings/SystemSettings';
import { CREATE, READ, SCHEDULE_READ, SETTINGS_UPDATE, UPDATE } from 'Constants/scopes';
import {
  APPOINTMENT,
  DEPARTMENT,
  DOCTOR,
  CLIENT,
  SUBSCRIPTION,
  USER,
  PATIENT,
} from 'Constants/feature';
import Unauthorized from 'Components/NotFound/UnauthorisedPage';
import keycloak from 'Utils/keyCloak/keyCloak';
import { updateToken } from 'Utils/Api/Network';
import { PORTAL_TYPES } from 'Constants/constant';
import SubscriptionSettings from 'Components/Settings/Subscription_Settings/SubscriptionSettings';
import 'Assets/style/bubbleLoader.css';
import { USER_TYPE } from 'Constants/userTypes';
import Patients from 'Components/Patients/Patients';
import RescheduleAppt from 'Components/Appointments/Reschedule';

const LoggedinPages = () => {
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const permissions = useSelector(
    (state) => state.LoginReducer.userPermissions.ResourceNameScope
  );
  const loggedIn = useSelector((state) => state.LoginReducer.loggedIn);
  const userType = useSelector((state) => state.LoginReducer.user_type);
  const [refresh, setRefresh] = useState(!loggedIn);

  useEffect(() => {
    (async () => {
      const portalType = getLocalStorageItem('portalType');
      if (keycloak.didInitialize && portalType !== PORTAL_TYPES.provider) {
        await keycloak?.logout({
          redirectUri:
            window.location.href.split('?')[0] + '?loginType=provider',
        });
      }

      setLocalStorageItem('portalType', PORTAL_TYPES.provider);

      if (!keycloak.authenticated && !keycloak.didInitialize) {
        keycloak
          .init({
            onLoad: 'login-required',
            redirectUri:
              window.location.href.split('?')[0] + '?loginType=provider',
          })
          .then(async (authenticated) => {
            if (authenticated) {
              setRefresh(true);
              const tokenDetails = {
                access_token: keycloak.token,
                refresh_token: keycloak.refreshToken,
                refresh_token_exp: keycloak.refreshTokenParsed.exp,
              };
              await updateToken(keycloak.token);
              dispatch(FetchUserDetails(tokenDetails, setRefresh));
            } else {
              clearLocalStorage();
              setLocalStorageItem('portalType', PORTAL_TYPES.provider);
              setRefresh(false);
            }
          });
      }
      document.body.classList.add('admin_portal');
    })();
    return () => document.body.classList.remove('admin_portal');
  }, []);

  const checkpermission = (feature, scope) => {
    const permission = permissions?.[feature];
    if (!permission || !permission?.length) {
      return false;
    } else {
      return permission.includes(scope);
    }
  };

  const routes = [
    {
      path: '/admin/appointments',
      component:
        checkpermission(APPOINTMENT, CREATE) && userType !== USER_TYPE.doctor
          ? AppointmentMain
          : checkpermission(APPOINTMENT, READ) && userType === USER_TYPE.doctor
          ? DoctorPortal
          : Unauthorized,
      hasSubRoute: true,
    },
    {
      path: '/admin/:route/reschedule/:id',
      component:
      checkpermission(APPOINTMENT, UPDATE) ? RescheduleAppt : Unauthorized,
      hasSubRoute: false,
    },
    {
      path: `${path}/doctorslisting`,
      component: DoctortMain,
      hasSubRoute: true,
    },
    {
      path: `${path}/leave-approval`,
      component: LeaveApproval,
      hasSubRoute: false,
    },
    {
      path: `${path}/slot-management`,
      component: checkpermission(DOCTOR, SCHEDULE_READ)
        ? SlotManagement
        : Unauthorized,
      hasSubRoute: false,
    },
    {
      path: `${path}/patients`,
      component:checkpermission(PATIENT, READ) &&(userType === USER_TYPE.subscriptionOwner ||userType === USER_TYPE.admin)?Patients : Unauthorized,
      hasSubRoute: false,
    },
    {
      path: `${path}/departments`,
      component:
        checkpermission(DEPARTMENT, READ) && userType !== USER_TYPE.doctor
          ? Departments
          : Unauthorized,
      hasSubRoute: false,
    },
    {
      path: `${path}/users`,
      component:
        checkpermission(USER, READ) &&
        (userType === USER_TYPE.subscriptionOwner ||
          userType === USER_TYPE.admin)
          ? User
          : Unauthorized,
      hasSubRoute: false,
    },
    {
      path: `${path}/settings`,
      component: Settings,
      hasSubRoute: false,
    },
    {
      path: `${path}/systemSettings`,
      component:
        checkpermission(CLIENT, SETTINGS_UPDATE) &&
        (userType === USER_TYPE.subscriptionOwner ||
          userType === USER_TYPE.admin)
          ? SystemSettings
          : Unauthorized,
      hasSubRoute: false,
    },
    {
      path: `${path}/subscriptionSettings`,
      component:
        checkpermission(SUBSCRIPTION, SETTINGS_UPDATE) &&
        userType === USER_TYPE.subscriptionOwner
          ? SubscriptionSettings
          : Unauthorized,
      hasSubRoute: false,
    },
  ];
  return (
    <div className="admin_portal">
      {refresh ? (
          <div
            style={{
              height: '100vh',
              width: '100vw',
              position: 'fixed',
              top: 0,
              left: 0,
              backgroundColor: '#ffff',
              zIndex: 99,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="stand_by_screen"
          >
            <span className="bubble_loader"></span>
          </div>
        ) : (
        <HomeLayout>
            <Switch>
              <Route exact path={path}>
                <Redirect to="/admin/appointments" />
              </Route>
              {routes.map((route) => (
                <PrivateRoute
                  path={route.path}
                  key={route.path}
                  component={route.component}
                  exact={!route.hasSubRoute}
                  redirectTo="/admin/auth/login"
                />
              ))}
              <Route component={NotFound} />
            </Switch>
        </HomeLayout>
        )}
    </div>
  );
};

export default LoggedinPages;
