import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { CardMedia } from '@mui/material';
import { useHistory } from 'react-router-dom';
import profilePic from 'Assets/img/bg/profilePic.svg';
import { truncateString } from 'Utils/StringFormatter/String';
import {
  appendTime,
  formatDate,
  // formatDateForRoaster,
  formatDateto_D_WD_M_Y,
  getWeekBounds,
  getWeekDays,
  timeZoneDate,
} from 'Utils/DateFormatter/Date';
import {
  FetchAppointmentList,
  FetchDoctorSlots,
  startAppointment,
} from 'Actions/appointmentAction';
import _ from 'lodash';
import {
  APPOINTMENT_STATUS,
  APPOINTMENT_STATUS_NAMES,
} from 'Constants/constant';
import { translate } from 'Language/Translate';
import ConflictConform from './ConflictConfirm';
import Loader from 'Components/Common/Loader';
import telephone from 'Assets/img/icons/telephone.svg'
import envelope from 'Assets/img/icons/envelope.svg'
import person from 'Assets/img/icons/person-round.svg'
import clock from 'Assets/img/icons/clock.svg'
import listIcon from 'Assets/img/icons/list-icon.svg'
// import ButtonLoader from 'Components/Common/ButtonLoader';

const days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const status = {
  upcoming: 'upcoming',
  notattempted: 'not-attempted',
  cancelled: 'cancelled',
  label: 'Cancelled',
  completed: 'completed',
  inprogress: 'in-progress',
  empty: 'empty',
  waiting: 'waiting',
};

const statusDot = {
  upcoming: 'upcoming',
  notattempted: 'notattempted',
  cancelled: 'cancelled',
  label: 'Cancelled',
  completed: 'completed',
  inprogress: 'in-progress',
  empty: 'empty',
  waiting: 'waiting',
};

const WeekRoster = (props) => {
  const {
    getAppointments,
    date,
    appointmentList,
    patientName,
    applyCounter,
    startAppointment,
    status: apptStatus,
    selecedDept,
    // sort,
    applySearchData,
    searchData,
    getDoctorSlots,
    doctorSlotData,
    language_Code,
    doctorDetails,
    depts,
    docDetails,
    startBtnLoad,
  } = props;
  const history = useHistory();
  const scrollRef = useRef();
  const popupRef = useRef({});
  const { startAppt = false } = startBtnLoad;

  // loader state
  const [isLoader, updateLoader] = useState(false);
  const [weekDays, setWeekDays] = useState([]);

  const [apptList, setApptList] = useState([]);
  const [showConflict, setShowConflict] = useState(false);

  // to ref scroll
  const [hasScroll, setHasScroll] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dummyState, setDummystate] = useState(1);

  const setScrollhandle = () => {
    setTimeout(() => {
      const element = scrollRef.current;
      if (element) {
        setHasScroll(element?.scrollHeight > element?.clientHeight);
      }
    }, 0);
  };

  const fetchAppointments = ({
    search: searchWord,
    department,
    appStatus,
    start_Date,
    end_Date,
  } = {}) => {
    if (!isLoader) updateLoader(true);
    const config = {
      patient_search: searchWord ? searchWord : undefined,
      dept_doc_search: department ? department : undefined,
      start_date:
        start_Date || start_Date === ''
          ? start_Date || undefined
          : formatDate(date),
      end_date: end_Date ? end_Date : formatDate(date),
      status: appStatus ? appStatus : undefined,
      page: 1,
      limit: 0,
      acl: true,
      sort: 'appt_start_time',
    };
    getAppointments(config, updateLoader);
  };

  useEffect(() => {
    // Update windowWidth on initial render and resize
    setWindowWidth(window.innerWidth);

    // Add event listener for resize event
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (applyCounter) {
      const { startDate, endDate } = getWeekBounds(date);
      fetchAppointments({
        search: patientName,
        appStatus: apptStatus ? apptStatus.value : '',
        department: selecedDept ? selecedDept.value : '',
        start_Date: formatDate(startDate),
        end_Date: formatDate(endDate),
      });
    }
  }, [applyCounter]);

  useEffect(() => {
    if (applyCounter) {
      setWeekDays(getWeekDays(date, language_Code));
      const { startDate, endDate } = getWeekBounds(date);
      applySearchData({
        ...searchData,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      });
      const { doctor_id } = doctorDetails;
      const config = {
        start_date: formatDate(date),
        end_date: formatDate(date),
      };
      getDoctorSlots(doctor_id, config);
    }
  }, [date, applyCounter]);

  useEffect(() => {
    if (appointmentList?.appointments?.length) {
      const groupedByDate = _.groupBy(
        appointmentList.appointments,
        'appt_date'
      );
      setApptList(groupedByDate);
    } else {
      setApptList({});
    }
    setScrollhandle();
  }, [appointmentList]);

  const getHighestArrayLength = (obj) => {
    let highestLength = 0;
  
    for (const value of Object.values(obj)) {
      if (Array.isArray(value) && addEmptySlots(value).length > highestLength) {
        highestLength = addEmptySlots(value).length;
      }
    }
  
    return highestLength;
  }

    // to find the number of empty deiv needed to fill the week calender completely
    const fillEmptySlots = () => {
      const parentDiv = document.getElementById('scrollRef');
      const parentRect = parentDiv.getBoundingClientRect();
      const numChildDivs = Math.floor(parentRect.height / 30);
      const a = getHighestArrayLength(apptList)
      return windowWidth > 991 ? a > numChildDivs ? a : numChildDivs : 1
    }

    // add empty solts to remaining part of appointments
    const fillAppointmentWithEmpty = (data) => {
      const parentDiv = document.getElementById('scrollRef');
      const parentRect = parentDiv.getBoundingClientRect();
      const numChildDivs = Math.floor(parentRect.height / 30);
      const maxLength = getHighestArrayLength(apptList)
      return windowWidth > 991 ? maxLength >= numChildDivs ? 
           data.concat(new Array(maxLength - data.length).fill({ live_status: 'empty' })) : data.concat(new Array(numChildDivs - data.length).fill({ live_status: 'empty' })) : data
    } 

  // add breaks in between appointment if the gpasare greater than one hour
  const addEmptySlots = (appointments) => {
    const updatedAppointments = [];

    for (let i = 0; i < appointments.length - 1; i++) {
      const currentEnd = appointments[i].appt_end_time;
      const nextStart = appointments[i + 1].appt_start_time;

      // Convert time strings to Date objects for comparison
      const currentEndTime = new Date(`2000-01-01T${currentEnd}`);
      const nextStartTime = new Date(`2000-01-01T${nextStart}`);

      // Calculate time difference in milliseconds
      const timeDifference = nextStartTime - currentEndTime;

      // Add the original appointment
      updatedAppointments.push(appointments[i]);

      if (timeDifference > 2 * 3600000) {
        // Insert empty slots for each hour difference
        const numEmptySlots = Math.floor(timeDifference / (2 * 3600000));
        for (let j = 1; j <= numEmptySlots; j++) {
          updatedAppointments.push({ live_status: 'empty' });
        }
      }
    }

    // Add the last appointment
    updatedAppointments.push(appointments[appointments.length - 1]);

    // sort the array to show cancelled first on each slot
    const sortedAppointments = updatedAppointments.sort((a, b) => {
      if (a.appt_start_time === b.appt_start_time) {
        if (a.live_status === 'cancelled' && b.live_status !== 'cancelled') {
          return -1;
        } else if (
          a.live_status !== 'cancelled' &&
          b.live_status === 'cancelled'
        ) {
          return 1;
        }
      }
      return 0;
    });

    return sortedAppointments;
  };

  // handle error when doctor try to start an appointment while another is inprogress
  const handleInprogressAppointment = (code, payload) => {
    if (code === 409) {
      setShowConflict(payload.id);
      // const element = document.getElementById(`appointmentView-${payload.id}`);
      // const element1 = document.getElementById(
      //   `appointmentViewToggle-${payload.id}`
      // );
      // element.classList.remove('show');
      // element1.classList.remove('show');
    }
  };

  // handle start appointment by completing previous ongoing one
  const handleProceed = () => {
    let payload = { id: showConflict, auto_complete: true };
    startAppointment(payload, () => {
      history.push(`/admin/appointments/patientview/${showConflict}`);
      setShowConflict('');
    });
  };

  // hanlde cancel on conflict
  const hanldeCancel = () => {
    setShowConflict('');
  };

  // handle appointment start
  const handleAppointmnentStart = async (item, type) => {
    if (type === 'Start') {
      let payload = { id: item?.appointment_id };
      startAppointment(
        payload,
        () => {
          history.push(`/admin/appointments/patientview/${item.appointment_id}`);
        },
        handleInprogressAppointment
      );
    } else {
      history.push(`/admin/appointments/patientview/${item.appointment_id}`);
    }
  };

  // handle close of the popup
  const onClose = (id) => {
    const element = document.getElementById(`appointmentView-${id}`);
    const element1 = document.getElementById(`appointmentViewToggle-${id}`);
    element?.classList?.remove('show');
    element1?.classList?.remove('show');
  };

  const getCombinedOpTiming = () => {
    const sessions =
      doctorSlotData?.op_schedule_details?.[0]?.doctor_schedule_details || [];
    // Initialize variables to track earliest start and latest end time
    let earliestStart = null;
    let latestEnd = null;

    // Loop through each session
    for (const session of sessions) {
      // Check if the schedule type is "op"
      if (session.schedule_type === 'op') {
        // Update earliest start time if it's earlier than the current session's start
        if (!earliestStart || session.start_time < earliestStart) {
          earliestStart = session.start_time;
        }

        // Update latest end time if it's later than the current session's end
        if (!latestEnd || session.end_time > latestEnd) {
          latestEnd = session.end_time;
        }
      }
    }

    // If no op schedules are found, return null
    if (!earliestStart || !latestEnd) {
      return 'Unavailable today';
    }

    // Return an object with combined start and end time
    return `${appendTime(earliestStart, language_Code)} - ${appendTime(
      latestEnd,
      language_Code
    )}`;
  };
  function getFields(input, field) {
    var output = [];
    for (var i = 1; i < input.length; ++i) output.push(input[i][field]);
    return output;
  }

  const calculateHeight = (data) => {
    const ele = document.getElementById(days[data]);
    return ele?.scrollHeight;
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (windowWidth < 991) setDummystate(dummyState + 1);
    });

    resizeObserver.observe(scrollRef.current);

    return () => resizeObserver.disconnect(); // Cleanup on unmount
  }, [scrollRef.current, windowWidth, apptList]);

  return (
    <>
      <div className="data-wrapper">
        <div className={`schedule-table `}>
          <div
            className="schedule-header"
            style={{ paddingRight: hasScroll ? '8px' : '0px' }}
          >
            <div className="left-side cell">
            </div>
            {weekDays.map((day, index) => (
              <div
                className={`cell ${day.active ? 'active' : ''}`}
                key={index + 1}
                style={{
                  height:
                    windowWidth < 991 && apptList[day.formatedDate]
                      ? calculateHeight(index)
                      : '',
                }}
              >
                <span>{day.date}</span>
              </div>
            ))}
          </div>
          <div className="schedule-body">
            <div className="left-area">
              <div className="profile selected-profile">
                <div className="profile-content">
                  <CardMedia
                    component="img"
                    image={docDetails.profile_img || profilePic}
                  />
                  <div>
                    <span className="doc-name">
                      {docDetails.name} {docDetails.last_name}
                    </span>
                    <span
                      className="dept text-capitalize"
                      title={getFields(depts, 'label').join(', ')}
                    >
                      {truncateString(
                        depts?.[1]?.label,
                        depts.length === 1 ? 15 : 10
                      )}
                      {depts.length > 2 ? (
                        <>
                          <a
                            className="td-none text-primary ms-2 fs-12 pointer extra-details"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="true"
                          >
                            +{depts.length - 2}
                          </a>
                          {/* Department on hover */}
                          {/* <ul
                          className="dropdown-menu dropdown-menu-start custom-tooltip"
                          data-popper-placement="bottom-end"
                        >
                          {depts?.map((item, index) => {
                            if (index > 0) {
                              return (
                                <li key={index}>
                                  <a
                                    className="dropdown-item small"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#editAccount"
                                    aria-controls="editAccount"
                                  >
                                    {item.label}
                                  </a>
                                </li>
                              );
                            }
                          })}
                        </ul> */}
                        </>
                      ) : null}
                    </span>
                  </div>
                </div>
                <span className="selected-badge">
                  {translate('appointments.optime', 'OP Time')}
                  {' : '}
                  {getCombinedOpTiming()}
                </span>
              </div>
            </div>
            <div
              className="right-area"
              ref={scrollRef}
              id='scrollRef'
            >
              {weekDays.map((day, index) => (
                // each slot card
                <div
                  className={`cell-wrap ${days[index]}`}
                  id={days[index]}
                  key={index + 1}
                >
                  {apptList[day.formatedDate] && !isLoader ? (
                    fillAppointmentWithEmpty(addEmptySlots(apptList[day.formatedDate])).map(
                      (appointment, ind) => (
                        <>
                          <div
                            data-bs-toggle={
                              appointment.live_status !== 'empty'
                                ? 'dropdown'
                                : ''
                            }
                            aria-expanded="false"
                            className={`cell ${
                              status[appointment.live_status]
                            }`}
                            key={ind + 1}
                            data-bs-auto-close="outside"
                            id={`appointmentView-${appointment.appointment_id}`}
                          >
                            {appointment.live_status !== 'empty' && (
                              <span>
                                {appendTime(
                                  appointment.appt_start_time,
                                  language_Code
                                )}{' '}
                                -{' '}
                                {appendTime(
                                  appointment.appt_end_time,
                                  language_Code
                                )}
                              </span>
                            )}
                          </div>
                          {/* popup for each card */}
                          <ul
                            data-popper-placement="bottom-start"
                            className="dropdown-menu custom-popover v2 popupDoctorViewday"
                            id={`appointmentViewToggle-${appointment.appointment_id}`}
                            ref={(ref) => (popupRef.current[appointment.appointment_id] = ref)}
                          >
                            <div className="popover-header mb-3">
                              <div className='header-id'>
                                <h6>
                                  {translate(
                                    'patientView.apptDetails',
                                    'Appointment Details'
                                  )}
                                </h6>
                                <div className="d-flex gap-2 align-items-center">
                                  <span> <p className='id'>{appointment?.appointment_id}</p></span>
                                  <span className={`status ${statusDot[appointment?.live_status]}`}>
                                    <div className="dot">•</div> 
                                    {
                                    APPOINTMENT_STATUS_NAMES[
                                      appointment.live_status
                                    ]
                                    }
                                  </span>
                                </div>
                              </div>
                              <span className="d-flex align-items-center gap-1">
                                {/*  No permission for docor to edit appointment
                              <button className="btn-transparent">
                                <i className="bi bi-pencil"></i>
                              </button> */}
                                <button
                                  className="btn-transparent"
                                  onClick={() =>
                                    onClose(appointment.appointment_id)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-x-lg"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                  </svg>
                                </button>
                              </span>
                            </div>
                            <div className="popover-body">
                              <div className="icon-block mb-3">
                              <img src={person} alt="" /> 
                                <div>
                                  <span>
                                    {translate(
                                      'appointments.patientName',
                                      'Patient Name'
                                    )}
                                  </span>
                                  <p className="mb-0">
                                    {appointment.patient_name}
                                  </p>
                                </div>
                              </div>
                              {appointment.patient_email &&
                                <div className="icon-block mb-3 me-4 bordred-top-bottom">
                                  <img src={envelope} alt="" />
                                  <div className="mt-1">
                                    <span>{translate('common.emailId', 'Email Address')}</span>
                                    <p className="mb-0 mt-2 mail-text">{appointment.patient_email}</p>
                                  </div>
                                </div>
                              }
                              <div className="items-set">
                              <div className="icon-block">
                              <img src={clock} alt="" />
                                <div>
                                <span>Date & Time</span>
                                  <p className="mb-0 mt-2">
                                    {formatDateto_D_WD_M_Y(
                                      new Date(appointment.appt_date),
                                      language_Code
                                    )}
                                  </p>
                                  <p className="mb-0">
                                    {appendTime(appointment.appt_start_time)} -{' '}
                                    {appendTime(appointment.appt_end_time)}
                                  </p>
                                </div>
                              </div>
                              {appointment?.patient_tele_country_code &&
                                <div className="icon-block mb-3">
                                  <img src={telephone} alt="" />
                                  <div className="mt-1">
                                    <span>{translate('users.phone', 'Phone Number')}</span>
                                    <p className="mb-0 mt-2">+{appointment?.patient_tele_country_code}{appointment.patient_phone}</p>
                                  </div>
                                </div>
                              }
                              </div>
                            </div>
                            <div className="popover-footer">
                              <div className="icon-block">
                              <img src={listIcon} alt="" />
                                <div>
                                  <span>
                                    {translate(
                                      'patientView.reason',
                                      'Reason For Appointment'
                                    )}
                                  </span>
                                  <p className="mb-0 first-capital">{appointment.reason}</p>
                                </div>
                              </div>
                              <div className="mt-3 d-flex align-items-center gap-3 popover-footer v2">
                                <button
                                  className="btn btn-primary loader-btn"
                                  onClick={() => {
                                    onClose(appointment.appointment_id);
                                    handleAppointmnentStart(
                                      appointment,
                                      (appointment.live_status ===
                                        APPOINTMENT_STATUS.upcoming ||
                                        appointment.live_status ===
                                          APPOINTMENT_STATUS.notAttempted ||
                                        appointment.live_status ===
                                          APPOINTMENT_STATUS.waiting) &&
                                        formatDate(appointment.appt_date) ===
                                          formatDate(timeZoneDate())
                                        ? 'Start'
                                        : 'View'
                                    );
                                  }}
                                >
                                  {/* {startAppt ? (
                                    <ButtonLoader
                                      text={
                                        (appointment.live_status ===
                                          APPOINTMENT_STATUS.upcoming ||
                                          appointment.live_status ===
                                            APPOINTMENT_STATUS.notAttempted ||
                                          appointment.live_status ===
                                          APPOINTMENT_STATUS.waiting) &&
                                          formatDate(appointment.appt_date) ===
                                          timeZoneDate().toISOString().split('T')[0]
                                          ? translate('common.start', 'Start')
                                          : translate(
                                              'appointments.view',
                                              'View'
                                            )
                                      }
                                    />
                                  ) : */}
                                  <>
                                    {(appointment.live_status ===
                                      APPOINTMENT_STATUS.upcoming ||
                                      appointment.live_status ===
                                        APPOINTMENT_STATUS.notAttempted ||
                                      appointment.live_status ===
                                        APPOINTMENT_STATUS.waiting) &&
                                    formatDate(appointment.appt_date) ===
                                      formatDate(timeZoneDate())
                                      ? translate('common.start', 'Start')
                                      : translate('appointments.view', 'View')}
                                  </>
                                </button>

                                {/* No permission for doctor to cancel appointment
                              <button className="selector">
                                Cancel Appointment
                              </button> */}
                              </div>
                            </div>
                          </ul>
                          <div className="overlay"></div>
                        </>
                      )
                    )
                  ) : (<>
                    {Array(fillEmptySlots()).fill(0).map((x, i) => <div className="cell empty h-100" key={i}></div>)}
                    </>
                  )}
                </div>
              ))}
              {isLoader || startAppt ? (
                <div className="loader-container">
                  <Loader />
                </div>
              ) : null}
            </div>
          </div>
          <div className="schedule-footer">
            <div>
              <span className="legend in-progress">
                <span className="dot">●</span>{' '}
                {APPOINTMENT_STATUS_NAMES[APPOINTMENT_STATUS.inProgress]}
              </span>
              <span className="legend upcoming">
                <span className="dot">●</span>{' '}
                {APPOINTMENT_STATUS_NAMES[APPOINTMENT_STATUS.upcoming]}
              </span>
              <span className="legend cancelled">
                <span className="dot">●</span>
                {APPOINTMENT_STATUS_NAMES[APPOINTMENT_STATUS.cancelled]}
              </span>
              <span className="legend not-attempted">
                <span className="dot">●</span>{' '}
                {APPOINTMENT_STATUS_NAMES[APPOINTMENT_STATUS.notAttempted]}
              </span>
              <span className="legend completed">
                <span className="dot">●</span>{' '}
                {APPOINTMENT_STATUS_NAMES[APPOINTMENT_STATUS.completed]}
              </span>
              <span className="legend waiting">
                <span className="dot">●</span>{' '}
                {APPOINTMENT_STATUS_NAMES[APPOINTMENT_STATUS.waiting]}
              </span>
            </div>
          </div>
        </div>
      </div>
      {showConflict ? (
        <ConflictConform
          onConfirm={handleProceed}
          onClose={hanldeCancel}
          isBtnLoading={startAppt}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  appointmentList: state.AppointmentReducer.appointmentList,
  doctorSlotData: state.AppointmentReducer.doctorSlotData,
  doctorDetails: state.LoginReducer.doctorDetails,
  startBtnLoad: state.AppointmentReducer.isBtnLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getAppointments: (data, setloader) =>
    dispatch(FetchAppointmentList(data, setloader)),
  getDoctorSlots: (id, config) => dispatch(FetchDoctorSlots(id, config)),
  startAppointment: (data, onSuccess, onError) =>
    dispatch(startAppointment(data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WeekRoster);
