import './BookAppointment.css';
import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ConfirmBooking from './ConfirmBooking';
import { useHistory } from 'react-router-dom';
import Modal from '../Common/Modal';
import { useLocation } from 'react-router-dom';
import { DoctorsApi } from '../../../Utils/Api/DoctorsApi';
import { DepartmentsApi } from '../../../Utils/Api/DepartmentApi';
// import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { CardMedia } from '@mui/material';
import img from 'Assets/img/icons/doctor-placeholder-avatar.svg';
import errorImg from '../../../Assets/img/patient-portal/error_img_placeHolder.png';
import loadingImg from '../../../Assets/img/icons/search-loader.gif';

import { DOCTOR_SPECIALITY, LEAVE_TYPES, RELATIONS } from '../../../Constants/constant';
import network from '../../../Utils/Api/Network';
import {
  convertDateToDay,
  formatDate,
  setTimeOnCurrentDate,
  timeZoneDate,
} from '../../../Utils/DateFormatter/Date';
import BookingPop from './BookingPop';
import {
  resetSearchAppointment,
} from '../../../Actions/appointmentAction';
import { connect } from 'react-redux';
import PatientInfoModal from './PatientInfoModal';
import '../Layout/style/forms.css';
import '../Common/style/filter.css';
import '../Common/style/offcanvas.css';
import { translate } from '../../../Language/Translate';
import NoResultFound from '../Common/NoResultFound';
import AntDatepicker from 'Components/Common/AntdDatePicker';
import moment from 'moment';
import { toLower } from 'lodash';
import GuestInfoModal from './GuestInfoModal';
import AddNewMember from './AddNewMember';
import VerifyOtpModal from './VerifyOtpModal';
import { getlinkedProfile } from 'Actions/patientProfileAction';
import keycloak from 'Utils/keyCloak/keyCloak';
import Loader from 'Components/Common/Loader';
import Maxappointment from './MaxappointmentModal';
import { removeDrPrefix } from 'Utils/StringFormatter/String';
import { convertToBase64URI } from 'Utils/Services/Authenticator';
import { getLocalStorageItem, setLocalStorageItem } from 'Utils/LocalStorage/local-storage';
import { AppointmentApi } from 'Utils/Api/AppointmentApi';

let apiTimeout = null;
const BookAppointment = (props) => {
  const {
    userDetails,
    patientDetails,
    loggedIn,
    getLinkedProfile,
    applicationData,
    resetSearchAppointment,
    defaultCountryCode,
  } = props;

  const location = useLocation();
  const intl = useIntl();
  const docNameRef = useRef();
  const genderLabelRef = useRef();
  const referenceRef = useRef();
  const [openPatientInfo, setOpenPatientInfo] = useState(false);
  const [openGuestInfo, setOpenGuestInfo] = useState(false);
  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const [dataSaved, setDataSaved] = useState(true);
  const [genderButton, setGenderButton] = useState(false);
  const [date, setDate] = useState(timeZoneDate());
  const [open, setOpen] = useState(false);
  const [showFilterModal, updateFilterModal] = useState(false);
  const [showReferenceModal, updateReferenceModal] = useState(false);
  const [gender, SetGender] = useState('');
  const [filteredDoctor, updateFilterDoctor] = useState([]);
  const [relevance, SetRelevance] = useState('Relevance');
  const [doctorDetails, setDoctorDetails] = useState([]);
  const [search, setSearch] = useState('');
  const [suggestionList, setSuggestionList] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [limit, setLimit] = useState(6);
  const divRef = useRef();
  const searchType = localStorage.getItem('deptType');
  const searchName = localStorage.getItem('deptLabel');
  const [editinput, setEditInput] = useState(false);
  const [btnClick, setBtnClick] = useState(false);
  const [viewData, setViewData] = useState('');
  const [selectedSlot, setSelectedSlot] = useState(false);
  const [appId, setAppId] = useState('');
  const [addMemberClick, setAddMemberClick] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [selectedMember, setSelectedMember] = useState('');
  const [memberSuggestion, setMemberSuggestion] = useState([]);
  const [othersFormError, setOthersFormError] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [showMyInfo, setShowMyInfo] = useState(true);
  const [familyMember, setfamilyMember] = useState([]);
  const [imgStatus, setImgStatus] = useState({});
  const [type, setType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [docLoader, setDocLoader] = useState({});
  const [exceedModal, setExceedModal] = useState(false)
  const [docId, setDocId] = useState('')
  const [otpStatus, setOtpStatus] = useState(false);
  const [availableDatesLists, setAvailableDatesLists] = useState([]);
  const [isDocAvailable, setIsDocAvailable] = useState(false);
  const [isSingleDoctor,setIsSingleDoctor]=useState({singleDoc:false,isInitialLoad:true})

  //for selecting the first doctor if only one doctor present
  useEffect(()=>{
    DoctorsApi.getDoctorsList({
      search:'',
      limit:0,
      book_appointment:true,
      doc_with_dept:true,
      // status:'active',
    }).then(({response})=>{
      if(response?.doctors?.length === 1){
        const value = {
          label:`Dr. ${response?.doctors[0]?.name} ${response?.doctors[0]?.last_name}`,
          value:response?.doctors[0]?.doctor_id,
          type:'doctor',
          isDisabled:false
        }
        setIsSingleDoctor({...isSingleDoctor,singleDoc:true})
        handleSelect(value)
      }
    })  
  },[])

  //for selecting the first doctor if only one doctor present on the initialLoad
  useEffect(()=>{
    if(selectedValue?.value&&isSingleDoctor?.isInitialLoad,isSingleDoctor?.singleDoc){
      handleDoctorSearch()
      setIsSelect(true)
      setIsSingleDoctor({isInitialLoad:false,isSingleDoctor:false})
    }
  },[selectedValue])
  const handleVerifyOtp = () => {
    setVerifyOtp(true);
    setOpenPatientInfo(false);
    setAddMemberClick(false);
  };
  const closeOtpVerifyModal = () => {
    setVerifyOtp(false);
    setSelectedMember('');
    setNewMemberDetails({
      fName: '',
      lName: '',
      dob: '',
      age: '',
      bloodGroup: '',
      patientId: '',
      relation: { label: translate('relation.other', 'Other'), value: RELATIONS.other },
      gender: '',
      phno: '',
      mrn: '',
      country: defaultCountryCode?.toString(),
    });
    setSelectedTab(0);
    setShowMyInfo(true);
    setOtpData({
      len: 4,
      token: '',
      exp: '',
    });
  };
  const addFamilyMember = () => {
    setAddMemberClick(true);
    //  setBtnClick(true)
    setOpenPatientInfo(false);
    setOthersInfo({
      patientName: '',
      patientLastName: '',
      patientDob: '',
      patientMobileNo: '',
      patientEmail: '',
      patientReason: '',
    });
    setOthersFormError({
      patientNameError: false,
      patientMobileNoError: false,
      patientReasonError: false,
    });
    setChecked(false);
    setCheckBoxErr(false);
    setSelectedMember('');
    setSearchMember('');
    setNewMemberDetails({
      fName: '',
      lName: '',
      dob: '',
      age: '',
      bloodGroup: '',
      patientId: '',
      relation: { label: translate('relation.other', 'Other'), value: RELATIONS.other },
      gender: '',
      phno: '',
      isChecked: '',
      country: defaultCountryCode?.toString(),
    });
  };

  // patient info states
  const [myInfo, setmyInfo] = useState({
    myName: '',
    lastName: '',
    dob: '',
    gender: '',
    mobileNo: '',
    email: '',
    reason: '',
    mrn: ''
  });
  const [othersInfo, setOthersInfo] = useState({
    patientName: '',
    patientLastName: '',
    patientDob: '',
    patientMobileNo: '',
    patientEmail: '',
    patientReason: '',
  });
  const [newMemberDetails, setNewMemberDetails] = useState({
    fName: '',
    lName: '',
    dob: '',
    age: '',
    bloodGroup: '',
    patientId: '',
    relation: { label: translate('relation.other', 'Other'), value: RELATIONS.other },
    gender: '',
    phno: '',
    isChecked: '',
    country: defaultCountryCode?.toString(),
  });
  const [myError, setMyError] = useState({
    nameError: false,
    mobileNoError: false,
    reasonError: false,
  });
  // for tab press or not
  const [tabPress, setTabPress] = useState(false)

  const [checked, setChecked] = useState(false);
  const [checkBoxErr, setCheckBoxErr] = useState('');
  const [newdate, setNewDate] = useState('');
  const history = useHistory();
  const [searchMember, setSearchMember] = useState('');
  const [listLoading, setListLoading] = useState(false);
  const [deptData, setDeptData] = useState([])
  const [deptValue, setDeptValue] = useState()
  const [nearDateClick,setNearDateClick] = useState(false)
  const [otpData, setOtpData] = useState({
    len: 4,
    token: '',
    exp: '',
  });

  // storing search details in local storage
  useEffect(() => {
    if (location.state) {
      localStorage.setItem('deptType', location.state.type);
      localStorage.setItem('deptName', location.state.search);
      localStorage.setItem('deptLabel', location.state.label);
      localStorage.setItem('deptDate', location.state.date);
      const doc_Id = localStorage.getItem("availableDocId");
      if (doc_Id) {
        getNoSLots(doc_Id);
        setIsDocAvailable(true)
      }
    }
    return () => {
      if (history.location.pathname !== '/webConnect/bookAppointment') {
        localStorage.removeItem('deptType');
        localStorage.removeItem('deptName');
        localStorage.removeItem('deptLabel');
        localStorage.removeItem('deptDate');
      }
    };
  }, []);

  useEffect(() => {
    if (userDetails) {
      setmyInfo({
        myName: userDetails.name,
        lastName: userDetails.last_name,
        dob: userDetails.dob,
        gender: userDetails.gender,
        mobileNo: userDetails.phone,
        email: userDetails.email,
        mrn: patientDetails.mrn ?? '',
        reason: '',
      });
    }
  }, [userDetails]);

  useEffect(() => {
    if (searchType == 'doctor' && !deptValue) {
      setDeptValue(deptData[0])
    }
  }, [deptData])
  // leave check
  const leaveCheck = (leaves) => {
    let onLeave = false;
    if (leaves) {
      onLeave = leaves.some(
        (leave) => leave.leave_type === LEAVE_TYPES.fullDay
      );
    }
    return onLeave;
  };

  // disable pervious date
  const disabledDates = (current) => {
    return (
      current &&
      (current.isBefore(moment().subtract(1, 'day')) ||
        current.isAfter(
          moment().add(applicationData?.booking_window_max_days - 1, 'day')
        ))
    );
  };

  // get doctor details if doctor available in selected date
  const getFilterDoctorList = () => {
    if (localStorage?.getItem('deptDate')) {
      setDate(new Date(localStorage?.getItem('deptDate')));
    }
    if (date && date !== '') {
      const day = convertDateToDay(date);
      const currentTime = timeZoneDate();
      const doctor = doctorDetails.filter((item) => {
        if (item.op_schedule) {
          if (item?.op_schedule[day]) {
            item?.op_schedule[day].forEach((i) => {
              if (
                currentTime.toDateString() !== date.toDateString() ||
                (currentTime.toDateString() === date.toDateString() &&
                  currentTime.getTime() <
                  setTimeOnCurrentDate(i.end_time).getTime())
              ) {
                item.avaliable = true;
              }
            });
            const onLeave = leaveCheck(item.doctor_leaves);
            if (onLeave) {
              return false;
            }
            if (item?.avaliable) {
              return true;
            }
          } else {
            item.avaliable = false;
            return false;
          }
        } else if (item?.schedule_plans?.op_schedule) {
          if (item?.schedule_plans?.op_schedule[day]) {
            item.schedule_plans?.op_schedule[day].forEach((i) => {
              if (
                currentTime.toDateString() !== date.toDateString() ||
                (currentTime.toDateString() === date.toDateString() &&
                  currentTime.getTime() <
                  setTimeOnCurrentDate(i.end_time).getTime())
              ) {
                item.avaliable = true;
              }
            });
            const onLeave = leaveCheck(item.doctor_leaves);
            if (onLeave) {
              return false;
            }
            if (item.avaliable) {
              return true;
            }
          } else {
            item.avaliable = false;
            return false;
          }
        }
      });
      updateFilterDoctor(doctor);
      setImgStatus({});
    }
  };
  // call filter doctor details if any change in doctor details
  useEffect(() => {
    getFilterDoctorList();
  }, [doctorDetails]);
  // get doctor details

  // useEffect for gender click
  useEffect(() => {
    getDoctorsList();
  }, [gender, relevance]);

  const getDoctorsList = () => {
    setIsLoading(true);
    const searchType = localStorage.getItem('deptType');
    const searchName = localStorage.getItem('deptName');
    const seearchLabel = localStorage.getItem('deptLabel');
    const searchDate = localStorage.getItem('deptDate');
    if (selectedValue && date && date !== '') {
      if (selectedValue.type == 'department') {
        getDepatmentData(selectedValue.value, {
          schedule: 'include',
          status: 'active',
          schedule_date: formatDate(date),
          gender: toLower(gender),
          sort: toLower(
            relevance === 'Relevance' ? 'relevance' : '-experience'
          ),
        });
      } else {
        getDoctorLists(selectedValue.value, {
          schedule_date: formatDate(date),
        });
      }
    } else if (seearchLabel && searchType && searchDate) {
      setSelectedValue({
        label: seearchLabel,
        type: searchType,
        value: searchName,
      });
      if (searchType == 'department') {
        setSearch(seearchLabel);
        getDepatmentData(searchName, {
          schedule: 'include',
          status: 'active',
          schedule_date: formatDate(searchDate),
          gender: toLower(gender),
          sort: toLower(
            relevance === 'Relevance' ? 'relevance' : '-experience'
          ),
        });
      } else {
        getDoctorLists(searchName, {
          schedule_date: formatDate(searchDate),
        });
        setSearch(seearchLabel);
      }
    } else {
      setIsLoading(false);
    }
  };

  const getDepatmentData = (search, config) => {
    DepartmentsApi.GetDepartmentDetails(search, config).then((res) => {
      if (res.error) {
        setIsLoading(false);
        return;
      }
      if (res.response) {
        setDoctorDetails([...res.response.doctors]);
      }
      setIsLoading(false);
    });
  };

  const getDoctorLists = (search, config = {}) => {
    DoctorsApi.getDoctorDetails(search, config).then((res) => {
      if (res.error) {
        setIsLoading(false);
        return;
      }
      if (res.response) {
        const data = res.response;
        const { doctor_id, name, last_name, profile_img, experience, op_fees } =
          data.doctor || {};
        const { op_schedule } = data;
        const activeDept = res.response?.departments?.filter(
          (dept) => dept.dept_status === 'active'
        );
        const doctor = [
          {
            doctor_id,
            name,
            last_name,
            profile_img,
            departments: activeDept || [],
            op_schedule,
            doctor_leaves: data.doctor_leaves || [],
            experience,
            op_fees,
          },
        ];
        setDoctorDetails(doctor);
      }
      setIsLoading(false);
    });
  };

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      updateFilterModal(false);
      updateReferenceModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (openGuestInfo && btnClick) {
      setBtnClick(false);
    }
  }, [openGuestInfo]);

  const genderClick = () => {
    genderLabelRef.current.focus()
    updateFilterModal(!showFilterModal);
  };

  const referenceClick = () => {
    referenceRef.current.focus()
    updateReferenceModal(!showReferenceModal);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
    setIsSelect(false);
    setSelectedValue({});
  };

  const handleSelect = (value) => {
    setLocalStorageItem('availableDocId', value.value);
    if (!value.isDisabled) {
      setSearch(value.label);
      setDocLoader({})
      setIsSelect(true);
      setSuggestionList([]);
      setSelectedValue(value);
    }
  };
  const handleChangeDate = (value) => {
    if (
      selectedValue &&
      search !== '' &&
      (Object.keys(selectedValue).length || search === searchName)
    ) {
      setIsSelect(true);
    }
    if (value) {
      setDate(new Date(value));
    } else {
      setDate('');
    }
  };
  useEffect(() => {
    if (search === '' && suggestionList[0]?.isDisabled) {
      setSuggestionList([]);
    } else if (
      !isSelect &&
      ((search !== '' && searchName !== search) || editinput)
    ) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        if (isSelect) {
          setEditInput(true);
        }
        loadOptions(search);
      }, 300);
      apiTimeout = timeout;
      return () => {
        clearTimeout(apiTimeout);
      };
    }
  }, [search]);

  const loadOptions = async (inputValue) => {
    let fetchedOptions = [];
    if (inputValue?.trim()) {
      let id = new Date().getTime();
      setDocLoader((prev) => ({
        ...prev,
        [id]: true
      }));
      const config = {
        doc_with_dept: true,
        status: 'active',
        limit: 0
      }
      await network
        .get({ url: `/search/department-doctor/${inputValue}`, data: config })
        .then((response) => {
          setDocLoader((prev) => ({
            ...prev,
            [id]: false
          }));
          const { isError, result } = response;
          if (isError) {
            return;
          } else if (!result?.search_result) {
            fetchedOptions = [
              {
                value: '',
                label: `${intl.formatMessage({
                  id: 'common.noData',
                  defaultMessage: 'No data found',
                })}`,
                isDisabled: true,
              },
            ];
          } else {
            const options = result?.search_result.map((item) => ({
              label: `${item.type === 'doctor' ? `Dr. ${item.name}`:item.name}`,
              value: item.id,
              type: item.type,
            }));
            fetchedOptions = options;
          }
        })
        .catch(() => { });
    }
    setSuggestionList(fetchedOptions);
  };

  const handleDoctorSearch = () => {
    SetGender('')
    const doc_Id = localStorage.getItem("availableDocId");
    getDoctorsList();
    if (doc_Id) {
      getNoSLots(doc_Id);
      setIsDocAvailable(true)
    }
    if (selectedValue) {
      localStorage.setItem('deptType', selectedValue.type);
      localStorage.setItem('deptName', selectedValue.value);
      localStorage.setItem('deptLabel', selectedValue.label);
      localStorage.setItem('deptDate', new Date(date));
    }
    setIsSelect(false);
    setLimit(6);
    setShowSearchFilter(false);
    setSuggestionList([]);
  };

  const getNoSLots = (id) => {
    const config = {
      doctor_id: id,
      start_date: "",
      end_date: "",
    };
    AppointmentApi.getAvailableApptDates(config).then(({ error, response }) => {
      if (error) {
        return;
      }
      if (response) {
        const availableDates = response.slot_info.availabel_date;
        const formattedDatesArray = Object.keys(availableDates).map(
          (dateString) => {
            const dateObject = new Date(dateString);
            return formatDate(dateObject);
          }
        );
        setAvailableDatesLists(formattedDatesArray);
      }
    });
  }

  const handleResetSearch = () => {
    localStorage.removeItem('deptType');
    localStorage.removeItem('deptName');
    localStorage.removeItem('deptLabel');
    localStorage.removeItem('deptDate');
    setSelectedValue({});
    updateFilterDoctor([]);
    setSearch('');
    setDate('');
    SetGender('');
    SetRelevance('Relevance');
    setShowSearchFilter(false);
    setIsLoading(false);
  };

  const handleSlotBooking = (doctor) => {
    // if (!loggedIn) {
    //   keycloak.login({
    //     redirectUri:
    //       window.location.origin +
    //       `/#/webConnect/bookAppointment?loginType=patient&page=booking&docId=${doctor.doctor_id}`,
    //   });
    // } else {
      if (localStorage?.getItem('deptType')) {
        if (searchType == 'department') {
          setDeptValue({ label: localStorage?.getItem('deptLabel'), value: localStorage?.getItem('deptName') })
        }
      }
      setSuggestionList([]);
      setDocId(doctor.doctor_id)
      // setViewData(doctor);
      setBtnClick(!btnClick);
      setNewDate(date);
    // }
  };

  //  open confirm modal
  const conformHandle = () => {
    setOpenPatientInfo(false);
    setOpenGuestInfo(false);
    setOpen(true);
  };
  //  open patient info modal
  const openPatientInfoModal = () => {
    if (!loggedIn) {
      keycloak.login({
        redirectUri:
          window.location.origin +
          `/#/webConnect/bookAppointment?loginType=patient&page=booking&docId=${docId}&slot=${convertToBase64URI(selectedSlot)}&dept=${convertToBase64URI(deptValue)}&date=${convertToBase64URI(newdate)}`,
      });
    }else{
      if (userDetails?.name) {
        setOpenPatientInfo(true);
        setNearDateClick(false);
      } else {
        setOpenGuestInfo(true);
      }
    }
    setBtnClick(false);
  };
  // close modal
  const closeModal = () => {
    let a = true
    if (!dataSaved) {
      a = window.confirm("You have unsaved changes. Are you sure you want to leave this page ? Unsaved changes will be lost.")
    }
    if (a) {
      setBtnClick(true);
      setOpenPatientInfo(false);
      setAddMemberClick(false);
      setSelectedMember('');
      setSearchMember('');
      setOpen(false);
      setmyInfo({
        myName: userDetails.name,
        lastName: userDetails.last_name,
        dob: userDetails.dob,
        gender: userDetails.gender,
        mobileNo: userDetails.phone,
        email: userDetails.email,
        patientDetails: patientDetails.mrn ?? '',
        reason: '',
      });
      setMyError({ nameError: false, mobileNoError: false, reasonError: false });
      setSelectedSlot(false);
      setChecked(false);
      setCheckBoxErr(false);
      // setNewMemberDetails({
      //   fName: '',
      //   lName: '',
      //   dob: '',
      //   age: '',
      //   bloodGroup: '',
      //   patientId: '',
      //   relation: '',
      //   gender: '',
      //   phno: '',
      //   isChecked: '',
      //   country: defaultCountryCode?.toString(),
      // });
      setOthersInfo({
        patientName: '',
        patientLastName: '',
        patientDob: '',
        patientMobileNo: '',
        patientEmail: '',
        patientReason: '',
      });
      setOthersFormError({
        patientNameError: false,
        patientMobileNoError: false,
        patientReasonError: false,
      });
      setOpenGuestInfo(false);
      setSelectedTab(0);
      setShowMyInfo(true);
    }
  };
  const closeConfirmModal = () => {
    setOpen(false);
    setBtnClick(false);
    setmyInfo({
      myName: userDetails.name,
      lastName: userDetails.last_name,
      dob: userDetails.dob,
      gender: userDetails.gender,
      mobileNo: userDetails.phone,
      email: userDetails.email,
      mrn: patientDetails.mrn ?? '',
      reason: '',
    });
    setChecked(false);
    setSelectedTab(0);
    setShowMyInfo(true);
    history.push('/webConnect/appointments');
  };

  const guestModal = () => {
    setOpenGuestInfo(false);
    setBtnClick(true);
  };

  // handle limit on doctor listing
  const handleLimit = () => {
    setLimit(limit + 6);
  };

  const bookAnotherAppnmnt = () => {
    setOpen(false);
    setmyInfo({
      myName: userDetails.name,
      lastName: userDetails.last_name,
      dob: userDetails.dob,
      gender: userDetails.gender,
      mobileNo: userDetails.phone,
      email: userDetails.email,
      mrn: patientDetails.mrn ?? '',
      reason: '',
    });
    setOthersInfo({
      patientName: '',
      patientLastName: '',
      patientDob: '',
      patientMobileNo: '',
      patientEmail: '',
      patientReason: '',
    });
    setSelectedTab(0);
    resetSearchAppointment();
    setShowMyInfo(true);
    setChecked(false);
    history.push('/webConnect/appointments');
  };
  const getLinkedProfileList = () => {
    setListLoading(true);
    getLinkedProfile(getLinkedProfileSuccess, () => setListLoading(false));
  };
  const getLinkedProfileSuccess = (result) => {
    setListLoading(false);
    let { linked_profiles } = result;
    let { verified_profiles = [] } = linked_profiles;
    let members = [...verified_profiles];

    let finalArr = [];
    let sortedarr = members.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
    sortedarr.map((memb) => {
      if (memb?.profile_link_status && (!memb?.phone || (memb?.phone && memb.phone_verified)))
        finalArr.push({
          ...memb,
          name: memb?.name ? `${memb.name}` : '',
          patientId: memb.patient_id,
          id: memb.user_id,
          gender: memb.gender,
          relation: memb.relation,
          updated_Date: memb.modified_at,
          phone: memb?.phone ? `${memb?.phone}` : '',

          email: memb?.email,
          profile_link_status: memb.profile_link_status,
        });
    });
    setfamilyMember(finalArr);
  };

  const updateExceedModal = (data) => {
    setExceedModal(data);
    setBtnClick(false)
    setOpenPatientInfo(false);
    setAddMemberClick(false);
    setSelectedMember('');
    setSearchMember('');
    setOpen(false);
  }

  const getAvailableDate = () => {
    const slotDocId = getLocalStorageItem('availableDocId')
    setNearDateClick(true)
    setSuggestionList([]);
    setDocId(slotDocId)
    setBtnClick(!btnClick);
    setNewDate(date);
  }

  const activeDeptList = (data) => {
    return data?.filter((item) => item.dept_status === 'active');
  };


  return (
    <section className="find-doctors">
      <section className="bread-crumbs">
        <div className="wrapper links">
          <a className="previous" href="#/webconnect">
            {translate('patientPortal.home', 'Home')}{' '}
          </a>
          <span> {'>'} </span>
          <a className="bread-crumb-cursor"> {translate('patientPortal.searchResult', 'Search Results')} </a>
        </div>
      </section>
      <section
        id="common-filter"
        className={`common-filter ${showSearchFilter && 'opened'} `}
      >
        <button
          className="btn-close"
          onClick={() => setShowSearchFilter(false)}
        >
          <i className="bi bi-x"></i>
        </button>
        <div className="wrapper">
          <div className="filter-elements row">
            <div className="col-lg-4 field-container form-group">
              <label htmlFor="name" className="mandatory">
                {' '}
                {translate(
                  'patientPortal.docOrSpec',
                  'Doctor Name / Speciality'
                )}
              </label>
              <div className="icon-field name loading v2">
                {/* loading image tag */}
                <i className="bi bi-search icon"></i>
                <input
                  className="input w-100 text-capitalize"
                  type="search"
                  id="name"
                  placeholder={intl.formatMessage({
                    id: 'patientPortal.docOrSpec',
                    defaultMessage: 'Doctor Name / Speciality',
                  })}
                  value={search}
                  onChange={handleSearchChange}
                  onKeyDown={(e) => {
                    if (suggestionList.length && !suggestionList[0]?.isDisabled && e.key === "Tab" && !e.shiftKey) setTabPress(true)
                    else setTabPress(false)
                  }}
                  onBlur={() => {
                    setTimeout(() => { if (!tabPress) setSuggestionList([]) }, 400)
                  }}
                  ref={docNameRef}
                  autoComplete="off"
                />
                {search !== '' && !isSelect && Object.values(docLoader).includes(true) && (
                  <img
                    className="loading-img"
                    src={loadingImg}
                    alt={'loading image'}
                  />
                )}
                {!Object.values(docLoader).includes(true) && suggestionList.length && search !== '' && !isSelect ? (
                  <div
                    className={`customSearchList ${suggestionList[0]?.isDisabled ? 'disabled' : ''
                      }`}
                  >
                    <ul style={{ marginBottom: 1 }}>
                      {suggestionList.map((item, index) => (
                        <li
                          key={index}
                          className="suggestion-item"
                          onClick={() => handleSelect(item)}
                          tabIndex={suggestionList[0]?.isDisabled ? -1 : 0}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                              handleSelect(item)
                              docNameRef.current.focus()
                            }
                          }}
                        >
                          {item.label}
                          <span style={{ float: 'right' }}>
                            {DOCTOR_SPECIALITY[item.type]}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-4 field-container date-field">
              <div className="form-group">
                <label className="mandatory">
                  {intl.formatMessage({
                    id: 'patientPortal.whenQuestion',
                    defaultMessage: 'When?',
                  })}
                </label>
                <div className="icon-field date pe-3">
                  <AntDatepicker
                    onInputChange={handleChangeDate}
                    disabledDate={disabledDates}
                    value={date}
                    allowClearBtn={true}
                  />
                </div>
              </div>
              <div className="d-flex gap-3 btn-wrapper btn-margin">
                <button
                  id="search"
                  className="btn btn-secondary fit-content"
                  onClick={handleDoctorSearch}
                  disabled={!isSelect || (date === '' && isSelect)}
                >
                  {' '}
                  {intl.formatMessage({
                    id: 'patientPortal.search',
                    defaultMessage: 'Search',
                  })}
                </button>
                <button
                  id="reset"
                  className="btn btn-outlined-dark fit-content"
                  onClick={handleResetSearch}
                >
                  {translate('myAppointments.reset', 'Reset')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="filter-overlay"></div>
      {(!isLoading || gender !== "") ? (
        <section className={`result-and-sort ${filteredDoctor.length ? '' :'m-0' }`}>
          <div className="wrapper">
            {filteredDoctor.length ? (
              <h1 className="page-title">
                {filteredDoctor.length}{' '}
                {filteredDoctor?.length <= 1
                  ? intl.formatMessage({
                    id: 'patientPortal.doctor',
                    defaultMessage: 'Doctor',
                  })
                  : intl.formatMessage({
                    id: 'patientPortal.doctors',
                    defaultMessage: 'Doctors',
                  })}{' '}
                {intl.formatMessage({
                  id: 'patientPortal.found',
                  defaultMessage: 'found',
                })}
              </h1>
            ) : (
              <h1></h1>
            )}
            <div className="filter-icons">
             {filteredDoctor.length ? <button
                onClick={() => {
                  setGenderButton(!genderButton);
                }}
              >
                <i className="bi bi-filter" id="sortIcon"></i>
              </button>:null}
              <button onClick={() => setShowSearchFilter(true)}>
                <i className="bi bi-funnel"></i>
              </button>
            </div>
            {(searchType === 'department') || gender !== '' ? (
              <div
                className={`dr-container  ${genderButton && 'opened'}`}
                id="drContainer"
                style={{
                  alignItems: 'center',
                }}
              >
                <div className="dropdown gender" onClick={genderClick}>
                  <a
                    className="btn-transparent dropdown-toggle"
                    role="button"
                    onClick={genderClick}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ref={genderLabelRef}
                    tabIndex="0"
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) genderClick()
                    }}
                  >
                    {gender !== ''
                      ? `${intl.formatMessage({
                        id: `common.${toLower(gender)}`,
                        defaultMessage: `${gender}`,
                      })}`
                      : 'All'}
                  </a>
                  {showFilterModal ? (
                    <div className="pat-filterpop1" ref={divRef}>
                      <p
                        onClick={() => SetGender('')}
                        className="fs-13 pat-filterls"
                        tabIndex="0"
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            SetGender('')
                            genderClick()
                          }
                        }}
                      >
                        {intl.formatMessage({
                          id: 'common.all',
                          defaultMessage: 'All',
                        })}
                      </p>
                      <hr style={{ marginTop: 8 }}></hr>
                      <p
                        onClick={() => SetGender('Male')}
                        className="fs-13 pat-filterls"
                        tabIndex="0"
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            SetGender('Male')
                            genderClick()
                          }
                        }}
                      >
                        {intl.formatMessage({
                          id: 'common.male',
                          defaultMessage: 'Male',
                        })}
                      </p>
                      <hr style={{ marginTop: 8 }}></hr>
                      <p
                        onClick={() => SetGender('Female')}
                        className="fs-13 pat-filterls"
                        tabIndex="0"
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            genderClick()
                            SetGender('Female')
                          }
                        }}
                      >
                        {intl.formatMessage({
                          id: 'common.female',
                          defaultMessage: 'Female',
                        })}
                      </p>
                      <hr style={{ marginTop: 8 }}></hr>
                      <p
                        onClick={() => SetGender('Other')}
                        className="fs-13 pat-filterls"
                        tabIndex="0"
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            SetGender('Other')
                            genderClick()
                          }
                        }}
                      >
                        {intl.formatMessage({
                          id: 'common.other',
                          defaultMessage: 'Other',
                        })}
                      </p>
                    </div>
                  ) : null}
                </div>
                {/* <span className="label">Sort by : </span> */}
                <div className="dropdown relevance" onClick={referenceClick}>
                  <a
                    className="btn-transparent dropdown-toggle"
                    onClick={referenceClick}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    tabIndex="0"
                    ref={referenceRef}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault()
                        referenceClick()
                      }
                    }}
                  >
                    <span className="label">
                      {translate('patientPortal.sortBy', 'Sort by')} {' : '}{' '}
                    </span>
                    {intl.formatMessage({
                      id: `common.${toLower(relevance)}`,
                      defaultMessage: `${relevance}`,
                    })}
                  </a>

                  {showReferenceModal ? (
                    <div className="pat-filterpop2" ref={divRef}>
                      <p
                        onClick={() => SetRelevance('Relevance')}
                        className="fs-13 pat-filterls"
                        tabIndex="0"
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            SetRelevance('Relevance')
                            referenceClick()
                          }
                        }}
                      >
                        {intl.formatMessage({
                          id: 'patientPortal.relevance',
                          defaultMessage: 'Relevance',
                        })}
                      </p>
                      <hr style={{ marginTop: 8 }}></hr>
                      <p
                        onClick={() => SetRelevance('Experience')}
                        className="fs-13 pat-filterls"
                        tabIndex="0"
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            SetRelevance('Experience')
                            referenceClick()
                          }
                        }}
                      >
                        {intl.formatMessage({
                          id: 'patientPortal.experience',
                          defaultMessage: 'Experience',
                        })}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </section>
      ) : null}
      <section className="doctor-cards">
        <div className="wrapper">
          <div className="row">
            {filteredDoctor.length && !isLoading ? (
              filteredDoctor?.slice(0, limit)?.map(
                (i, index) =>
                  i.avaliable && (
                    <div key={index} className="col-xl-4 col-md-6">
                      <div className="single-doctor-card">
                        <Stack
                          // style={{ cursor: 'pointer' }}
                          direction="row"
                          spacing={2}
                        >
                          <CardMedia
                            className="doctor-booking-img"
                            style={{
                              borderRadius: '50%',
                              objectFit: 'cover',
                            }}
                            component="img"
                            image={i.profile_img ?? img}
                            onError={(e) => {
                              if (!imgStatus[`${index}`]) {
                                e.target.src = errorImg;
                                setImgStatus((prev) => ({
                                  ...prev,
                                  [`${index}`]: true,
                                }));
                              }
                            }}
                          />
                        </Stack>
                        <div>
                          <h6 className="name text-capitalize">
                            Dr. {removeDrPrefix(i.name + ' ' + i.last_name)}
                          </h6>
                          <div className="speciality text-capitalize">
                            {/* <p className="description"> */}
                              {
                                activeDeptList(
                                  i?.departments
                                )[0]?.dept_name
                              }
                              <span className="relative">
                              {activeDeptList(i?.departments)
                                .length >= 2 ? (
                                <span
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="true"
                                  className="pointer extra-details"
                                  style={{ paddingBottom: 'unset' }}
                                >
                                  , ...+
                                  {activeDeptList(
                                    i?.departments
                                  )?.length - 1}
                                </span>
                              ) : null}
                              <ul
                                className="dropdown-menu dropdown-menu-start custom-tooltip max-height top-aligned"
                                data-popper-placement="bottom-end"
                              >
                                <ul className='dr-overflow'>
                                {activeDeptList(i?.departments)
                                  .slice(1)
                                  .map((item, index) => (
                                    <li key={index}>
                                      <a
                                        className="dropdown-item small text-capitalize"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#editAccount"
                                        aria-controls="editAccount"
                                        href="#"
                                      >
                                        {item.dept_name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </ul>
                              </span>
                            {/* </p> */}
                            {i.experience
                              ? i.experience === 1
                                ? ` / ${i.experience} yr. exp.`
                                : ` / ${i.experience} yrs. exp.`
                              : ''}
                          </div>
                          <button
                            onClick={() => handleSlotBooking(i)}
                            className="booking-btn"
                          >
                            {intl.formatMessage({
                              id: 'patientPortal.bookAppointment',
                              defaultMessage: 'Book Appointment',
                            })}
                          </button>
                        </div>
                      </div>
                    </div>
                  )
              )
            ) : isLoading ? (
              <div className="loader-wrap mb-4 mt-4">
                <Loader />
              </div>
            ) : gender !== '' ? (
              <NoResultFound
                text={intl.formatMessage({
                  id: 'patientPortal.noDeptAvailab',
                  defaultMessage: 'No doctors found',
                })}
              />
            ) : searchType === 'doctor' ? (
              <NoResultFound
                text={ (availableDatesLists.length == 0 && !isDocAvailable)  ? intl.formatMessage({
                  id: 'patientPortal.noSlotAvailable',
                  defaultMessage: 'No slots are available on the selected date',
                }) : 
                intl.formatMessage({
                  id: 'patientPortal.noDoctorAvailable',
                  defaultMessage: 'No slots are available for the selected doctor.',
                })}
                docs="noDoc"
                getAvailableDate={getAvailableDate}
                availableDates={availableDatesLists}
                isDocAvailable={isDocAvailable}
              />
            ) : searchType === 'department' ? (
              <NoResultFound
                text={intl.formatMessage({
                  id: 'patientPortal.noDeptAvailable',
                  defaultMessage:
                    'No doctors available',
                })}
              />
            ) : (
              <NoResultFound
                text={intl.formatMessage({
                  id: 'patientPortal.noDataBookSlot',
                  defaultMessage:
                    'Need an appointment fast? Search doctors, departments, and book your visit in minutes.',
                })}
              />
            )}
          </div>
          {filteredDoctor.length > limit ? (
            <button onClick={handleLimit} className="more-btn d-block mx-auto">
              {translate('patientView.showMore', 'Show More')}
            </button>
          ) : (
            ''
          )}
        </div>
      </section>
      {openPatientInfoModal ?
        <BookingPop
          btnClick={btnClick}
          setBtnClick={setBtnClick}
          doctorData={viewData}
          date={date}
          setDate={setDate}
          conformhandle={conformHandle}
          openPatientInfoModal={openPatientInfoModal}
          openGuestInfo={openGuestInfo}
          openPatientInfo={openPatientInfo}
          selectedSlot={selectedSlot}
          setSelectedSlot={setSelectedSlot}
          newdate={newdate}
          setNewDate={setNewDate}
          applicationData={applicationData}
          doc={{ docId, setDocId }}
          setViewData={setViewData}
          deptData={deptData}
          setDeptData={setDeptData}
          deptValue={deptValue}
          setDeptValue={setDeptValue}
          nearDateClick={nearDateClick}
          setNearDateClick={setNearDateClick}
        /> : null
      }
      {
        exceedModal ?
          <Maxappointment
            openModal={exceedModal}
            handleClose={setExceedModal}
          /> : null
      }
      <div className="patient-info-modal">
        <Modal
          close={openPatientInfo}
          setClose={closeModal}
          title={translate('myAppointments.patientInfo', 'Patient Information')}
        >
          <PatientInfoModal
            myInfo={myInfo}
            openPatientInfo={openPatientInfo}
            setmyInfo={setmyInfo}
            othersInfo={othersInfo}
            setOthersInfo={setOthersInfo}
            myError={myError}
            setMyError={setMyError}
            doctorData={viewData}
            conformhandle={conformHandle}
            selectedSlot={selectedSlot}
            setAppId={setAppId}
            checked={checked}
            setChecked={setChecked}
            newdate={newdate}
            checkBoxErr={checkBoxErr}
            setCheckBoxErr={setCheckBoxErr}
            addFamilyMember={addFamilyMember}
            selectedMember={selectedMember}
            setSelectedMember={setSelectedMember}
            memberSuggestion={memberSuggestion}
            setMemberSuggestion={setMemberSuggestion}
            searchMember={searchMember}
            setSearchMember={setSearchMember}
            othersFormError={othersFormError}
            setOthersFormError={setOthersFormError}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            showMyInfo={showMyInfo}
            setShowMyInfo={setShowMyInfo}
            familyMember={familyMember}
            setfamilyMember={setfamilyMember}
            getLinkedProfileList={getLinkedProfileList}
            listLoading={listLoading}
            newMemberDetails={newMemberDetails}
            otpState={{ otpStatus, setOtpStatus }}
            updateExceedModal={updateExceedModal}
            deptValue={deptValue}
          />
        </Modal>
      </div>
      <Modal
        close={open}
        setClose={closeConfirmModal}
        modalClass="booking-success-modal"
        style={true}
      >
        <ConfirmBooking
          appId={appId}
          date={formatDate(date)}
          selectedSlot={selectedSlot}
          setOpen={setOpen}
          doctorData={viewData}
          patientInfo={myInfo}
          someOneInfo={othersInfo}
          showMyInfo={showMyInfo}
          bookAnotherAppnmnt={bookAnotherAppnmnt}
          newdate={newdate}
          deptValue={deptValue}
        />
      </Modal>
      <Modal
        close={openGuestInfo}
        setClose={guestModal}
        title={translate('myAppointments.continueGuest', 'Continue as Guest')}
        modalClass="guest-modal enter-guest-details-modal"
        modalBodyClass="pb-4 pt-3 mt-1 "
      >
        <GuestInfoModal
          date={date}
          doctorData={viewData}
          selectedSlot={selectedSlot}
          conformhandle={conformHandle}
          setAppId={setAppId}
          status={openGuestInfo}
        />
      </Modal>
      {addMemberClick && <Modal
        close={addMemberClick}
        setClose={closeModal}
        title={translate('myAppointments.patientInfo', 'Patient Information')}
        modalClass="link-profile-canvas patient-info-modal"
      >
        <AddNewMember
          dataSaved={dataSaved}
          setDataSaved={setDataSaved}
          addMemberClick={addMemberClick}
          setAddMemberClick={setAddMemberClick}
          setOpenPatientInfo={setOpenPatientInfo}
          newMemberDetails={newMemberDetails}
          setNewMemberDetails={setNewMemberDetails}
          handleVerifyOtp={handleVerifyOtp}
          otpData={otpData}
          setOtpData={setOtpData}
          getLinkedProfileList={getLinkedProfileList}
          setType={setType}
          defaultCountryCode={defaultCountryCode}
        />
      </Modal>}
      <Modal
        close={verifyOtp}
        setClose={closeOtpVerifyModal}
        title={translate('myAppointments.patientInfo', 'Patient Information')}
        modalClass=" enter-otp-modal otp-others"
      >
        <VerifyOtpModal
          verifyOtp={verifyOtp}
          setVerifyOtp={setVerifyOtp}
          setAddMemberClick={setAddMemberClick}
          setOpenPatientInfo={setOpenPatientInfo}
          newMemberDetails={newMemberDetails}
          otpData={otpData}
          setOtpData={setOtpData}
          type={type}
          otpState={{ otpStatus, setOtpStatus }}
        />
      </Modal>
    </section>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.LoginReducer.loginDetails,
  patientDetails: state.LoginReducer.patientDetails,
  loggedIn: state.LoginReducer.loggedIn,
  applicationData: state.SettingsReducer.applicationData,
  defaultCountryCode:
    state.SettingsReducer.subscriptionData.setting.defaultCountryCode,
});

const mapDispatchToProps = (dispatch) => ({
  getLinkedProfile: (callBack, errCallBack) =>
    dispatch(getlinkedProfile(callBack, errCallBack)),
  resetSearchAppointment: () => dispatch(resetSearchAppointment()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookAppointment);
