/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PopupWrapper from '../Common/PopupWrapeer';
import AddDepartment from './AddDepartment';
import ViewDepartment from './ViewDepartment';
import EditDepartment from './EditDepartment';
import { DepartmentsApi } from '../../Utils/Api/DepartmentApi';
import ConformPopup from '../Common/ConformPopup';
import Loader from '../Common/Loader';
import Pagination from '../Common/Pagination';
import { showToaster, ToasterTypes } from '../../Actions/toasterAction';
import CustomSwitch from '../Common/Switch';
import { DEPARTMENT_HEADERS } from '../../Constants/constant';
import HeaderFields from '../Common/HeaderFeilds';
import {
  clearDeptDataExceptFilter,
  cleraAllData,
  FetchDepartmentSuggestionList,
  FetchDoctorList,
  UpdateDepartmentStatus,
  updateSearchData,
  updatePerPage,
  updateCurrentPage,
  updateColumnSort,
} from '../../Actions/departmentAction';
import { Tooltip } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { compose } from 'redux';
import withPermissionCheck from '../../Utils/WithUtils/WithPermission';
import { DEPARTMENT } from '../../Constants/feature';
import { USER_TYPE } from '../../Constants/userTypes';
import TableColumnHr from 'Components/Common/TableColumnHr';
import { translate } from 'Language/Translate';
import usePrevious from 'Hooks/usePrevious';
import useDidMountEffect from 'Hooks/useDidMountEffect';
import { isEqual } from 'lodash';
import { CREATE, DELETE, UPDATE } from 'Constants/scopes';
import AppointmentConflictPopup from './ApptConflictPopup';
import {  convertToBase64URI } from 'Utils/Services/Authenticator';
import { useHistory } from 'react-router-dom';
import NoResultFound from 'Components/Common/NoResult';
import TableWrapper from 'Components/Common/TableWrpper';

let apiTimeout = null;

const deleteIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.1085 3.7371C11.0299 3.5311 8.93875 3.42499 6.85388 3.42499C5.61793 3.42499 4.38199 3.48741 3.14604 3.61225L1.87264 3.7371" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.30582 3.10625L5.44315 2.2875C5.54303 1.69375 5.61793 1.25 6.67285 1.25H8.3083C9.36322 1.25 9.44437 1.71875 9.538 2.29375L9.67533 3.10625" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.7665 5.71252L11.3608 12.0063C11.2921 12.9875 11.2359 13.75 9.49435 13.75H5.48689C3.74533 13.75 3.68915 12.9875 3.62049 12.0063L3.21475 5.71252" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.44814 10.3125H8.52677" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.93004 7.8125H9.05112" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const viewIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.50007 9.73757C6.26257 9.73757 5.26257 8.73757 5.26257 7.50007C5.26257 6.26257 6.26257 5.26257 7.50007 5.26257C8.73757 5.26257 9.73757 6.26257 9.73757 7.50007C9.73757 8.73757 8.73757 9.73757 7.50007 9.73757Z" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.1938 9.1187C13.7563 8.23745 13.7563 6.7562 13.1938 5.87495C11.7625 3.62495 9.70627 2.32495 7.50002 2.32495C5.29377 2.32495 3.23752 3.62495 1.80627 5.87495C1.24377 6.7562 1.24377 8.23745 1.80627 9.1187C3.23752 11.3687 5.29377 12.6687 7.50002 12.6687C9.70627 12.6687 11.7625 11.3687 13.1938 9.1187Z" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const editIcon = <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.72757 7.68029C3.53419 7.88654 3.34706 8.29279 3.30963 8.57404L3.07883 10.599C2.99774 11.3303 3.52172 11.8303 4.24531 11.7053L6.25388 11.3615C6.53458 11.3115 6.92757 11.1053 7.12094 10.8928L12.2422 5.46154C13.128 4.52404 13.5272 3.45529 12.1486 2.14904C10.7763 0.855287 9.73458 1.31154 8.84881 2.24904L3.72757 7.68029Z" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.99438 3.15625C8.26261 4.88125 9.65988 6.2 11.394 6.375" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.44946 13.75H13.6775" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const Departments = (props) => {
  const {
    displayToaster,
    // clearAll,
    getDoctorList,
    getDepartmentSuggestionList,
    editDepartmentStatus,
    clearAllExceptFilter,
    applySearchData,
    searchData,
    currentPage,
    SetCurrentPage,
    perPage,
    SetPerPage,
    columnSort,
    setColumnSort,
    ACCESS: permission,
    userType,
  } = props;

  const intl = useIntl();
  const history = useHistory();

  // Popup visibility handle states
  const [showPopup, updateShowPopup] = useState(false);
  const [showDepartmentPopup, updateShowDepartmentPopup] = useState(false);
  const [showEditDepartmentPopup, updateShowEditDepartmentPopup] =
    useState(false);
  const [isLoading, updateLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  //Department List
  const [DepartmentList, updateDepartmentList] = useState([]);

  // Single department details
  const [department_name, updateDepartment_name] = useState('');
  const [department_desc, updateDepartment_desc] = useState('');
  const [department_head, updatedepartment_head] = useState('');
  const [department_status, updatedepartment_status] = useState(true);
  const [showApptConflictPopup, setShowApptConflictPopup] = useState(false);
  const [focusArea,setFocusArea]=useState("")

  // Edit and View department details
  const [dataToEdit, updateDataToEdit] = useState('');
  const [showDepartment, updateshowDepartment] = useState('');
  const [doctorUpdate, setDoctorUpdate] = useState({});

  // To check which api to call edit (true) || add (false)
  const [EditStatus, updateEditStatus] = useState(false);

  // Delete deatils
  const [showConformPopup, updateShowConformPopup] = useState(false);
  const [delelteid, updateDeleteid] = useState('');

  // filter details
  const [searchValue, updateSearchValue] = useState('');
  const [isSelect, setIsSelect] = useState(false);
  const [selectedDepartStatus, SetSelectedDepartStatus] = useState([]);
  const [deptId, setDeptId] = useState('')

  // error exist check
  const [validationErr, updateValidationErr] = useState(false);

  // pagination handle
  // const [currentPage, SetCurrentPage] = useState(1);
  // const [perPage, SetPerPage] = useState(10);
  const [totalCount, SetTotalCount] = useState(null);

  // Dept filter suggestion list
  const [deptSuggestion, setDeptSuggestion] = useState([]);

  // search loader
  const [deptLoader,setDeptLoader] = useState({})

  const [conflictId, setConflictId] = useState('');

  const { add, remove } = doctorUpdate || {};
  const [showRemConformPopup, updateShowRemConformPopup] = useState(false);
  const [removeDocLists, setRemoveDocList] = useState({});
  const [nonDeptDoctors, setNonDeptDoctors] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [selectedDepartHead, SetSelectedDepartHead] = useState('');
  const [showConformationPopup, updateShowConformationPopup] = useState(false);
  const [removeDoc, setRemoveDoc] = useState({});

  // api call with 3sec delay
  useEffect(() => {
    if (!isSelect) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadOptions(searchValue);
      }, 300);
      apiTimeout = timeout;
    }
  }, [searchValue, isSelect]);

  // component did mount
  useEffect(() => {
    updateSearchValue(searchData?.searchValue ?? '');
    SetSelectedDepartStatus(searchData?.selectedDepartStatus ?? '');
    setDeptId(searchData?.deptId ?? " ")
    setIsSelect(true);

    fetchDepartmentList({
      search: searchData?.searchValue,
      status: searchData.selectedDepartStatus?.value,
      dept_id : searchData?.deptId ?? "",
    });
    if (permission(UPDATE)) {
      getDoctorList("");
      getDepartmentSuggestionList();
    }
    return () => clearAllExceptFilter();
  }, [permission(UPDATE)]);

  const prevSortVal = usePrevious(columnSort);

  useDidMountEffect(() => {
    if (!isEqual(prevSortVal, columnSort)) {
      fetchDepartmentList({
        search: searchData?.searchValue,
        status: searchData.selectedDepartStatus?.value,
      });
    }
  }, [columnSort]);

  const isApplied = (searchData?.searchValue?.trim() !== '' && searchData?.searchValue) ||
  (searchData?.selectedDepartStatus !== [] && searchData?.selectedDepartStatus?.value !== undefined)

  // Get department list
  const fetchDepartmentList = (
    { search, status, pageNo, perPageCount,dept_id } = {},
    isLoader = true
  ) => {
    if (isLoader) updateLoading(true);
    const sortKey = Object.keys(columnSort)[0];
    const config = {
      page: pageNo || currentPage,
      acl: userType === USER_TYPE.appointmentCordinator,
      limit: perPageCount || perPage,
      search:
        search || search === ''
          ? search || undefined
          : searchData?.searchValue
          ? searchData?.searchValue
          : undefined,
      status:
        status || status === ''
          ? status || undefined
          : searchData.selectedDepartStatus?.value
          ? searchData.selectedDepartStatus?.value
          : undefined,
      sort: sortKey
        ? columnSort[sortKey] === 'asc'
          ? sortKey
          : `-${sortKey}`
        : undefined,
        dept_id: dept_id  || dept_id === ""?
        dept_id:searchData?.deptId ? 
        searchData?.deptId:undefined
        ,
    };

    DepartmentsApi.FetchAllDepartmentList(config).then(
      ({ error, response }) => {
        if (error) {
          updateLoading(false);
          // displayToaster(error, ToasterTypes.Failed);
          return;
        }
        if (response) {
          updateDepartmentList(response?.departments);
          updateLoading(false);
          SetTotalCount(response?.page_details?.total_documents);
        } else {
          updateDepartmentList([]);
          // displayToaster(message, ToasterTypes.Failed);
          updateLoading(false);
        }
      }
    );
  };

  // handel deplartment filter select
  const onChangeSearch = (event) => {
    const { value } = event.target;
    updateSearchValue(value);
    setDeptId('');
    setIsSelect(false);
  };

  // handle filter apply
  const handleApplyClick = () => {
    let search_data = {
      searchValue,
      selectedDepartStatus,
      isSelect,
      deptId
    };
    applySearchData(search_data);
    if (
      (searchValue?.trim() !== '' && searchValue) ||
      (selectedDepartStatus !== [] && selectedDepartStatus.value !== undefined) ||
      isApplied
    ) {
      fetchDepartmentList({
        search: searchValue,
        dept_id:deptId,
        status: selectedDepartStatus?.value,
        pageNo: 1,
      });
      SetCurrentPage(1);
      setDeptSuggestion([]);
    }
  };

  //handle filter reset
  const handleReset = () => {
    applySearchData({});
    setDeptSuggestion([])
    setDeptId('')
    setDeptLoader({})
    setColumnSort({});
    updateSearchValue('');
    SetSelectedDepartStatus([]);
    SetCurrentPage(1);
    SetPerPage(25);
    if (
      searchValue ||
      selectedDepartStatus.length !== 0 ||
      currentPage !== 1 ||
      perPage !== 25 ||
      isApplied
    ) {
      fetchDepartmentList({
        search: '',
        status: '',
        pageNo: 1,
        perPageCount: 25,
        dept_id:''
      });
    }
  };



  // Add new department
  const addDepartmentList = async () => {
    // updateLoading(true);
    const config = {
      data: {
        dept_name: department_name,
        dept_head_id: department_head,
        dept_status: department_status ? 'active' : 'inactive',
        description: department_desc,
      },
    };
    setBtnLoading(true);
    DepartmentsApi.AddDepartmentList(config).then(
      ({ error, response, message }) => {
        setBtnLoading(false);
        if (error) {
          displayToaster(error, ToasterTypes.Failed);
          // updateLoading(false);
          return;
        }
        if (response) {
          displayToaster(message, ToasterTypes.Success);
          // updateLoading(false);
          fetchDepartmentList();
          // SetCurrentPage(1);
          // updateSearchValue('');
          // SetSelectedDepartStatus([]);
          handleClose();
          updateShowPopup(false);
        } else {
          displayToaster(message, ToasterTypes.Failed);
        }
      }
    );
  };
  // edit department
  const editDepartmentList = async () => {
    // updateLoading(true);
    const config = {
      data: {
        dept_name: department_name,
        dept_head_id: department_head,
        dept_status: department_status ? 'active' : 'inactive',
        description: department_desc,
        doctors: doctorUpdate,
      },
    };
    setBtnLoading(true);
    DepartmentsApi.EditDepartmentList(config, dataToEdit.dept_id).then(
      ({ error, response, message, code }) => {
        setBtnLoading(false);
        if (error) {
          // removing docter have any appointment to reschedule
          if (code === 409) {
            const encodedValue = convertToBase64URI(response)
            // const unique_id = uuid();
            setConflictId(`department:${encodedValue}`)
            // setLocalStorageItem(`rs-${unique_id}`, encodedValue);
            updateShowEditDepartmentPopup(false)
            setShowApptConflictPopup(true);
          } else {
            displayToaster(error, ToasterTypes.Failed);
          }
          // updateLoading(false);
          return;
        }
        if (response) {
          fetchDepartmentList();
          // updateLoading(false);
          displayToaster(message, ToasterTypes.Success);
          handleClose();
          updateShowEditDepartmentPopup(false);
          updateEditStatus(false);
        } else {
          displayToaster(message, ToasterTypes.Failed);
        }
      }
    );
  };

  // Delete department
  const deleteDepartmentList = async (deptId) => {
    // updateLoading(true);
    // updateShowConformPopup(false);
    setBtnLoading(true);
    DepartmentsApi.DeleteDepartmentList(deptId).then(({ error, message }) => {
      setBtnLoading(false);
      if (error) {
        updateLoading(false);
        displayToaster(error, ToasterTypes.Failed);
        return;
      }
      if (DepartmentList.length === 1 && currentPage > 1) {
        SetCurrentPage(currentPage - 1);
        fetchDepartmentList({ pageNo: currentPage - 1 });
      } else {
        fetchDepartmentList();
      }
      // updateLoading(false);
      updateShowConformPopup(false);
      displayToaster(message, ToasterTypes.Success);
    });
  };

  // Handle Add || Upadate department button click
  const handleSubmit = () => {
    if (department_name && department_name?.trim()?.length > 0) {
      if (EditStatus) {
        editDepartmentList();
      } else {
        addDepartmentList();
      }
    } else {
      updateValidationErr(true);
    }
  };

  // handle edit button click
  const onEdit = (data) => {
    updateShowEditDepartmentPopup(true);
    updateDataToEdit(data);
    updateDepartment_name(data.dept_name);
    updatedepartment_head(data?.dept_head?.dept_head_id);
    updatedepartment_status(data.dept_status === 'active');
    updateDepartment_desc(data.description);
    updateEditStatus(true);
  };

  // handle edit button click from view department popup
  const onEditFromView = (focuId="") => {
    if(focuId){
      setFocusArea(focuId)
    }
    updateShowDepartmentPopup(false);
    updateShowEditDepartmentPopup(true);
    updateDataToEdit(showDepartment);
    updateDepartment_name(showDepartment.dept_name);
    updatedepartment_head(showDepartment?.dept_head?.dept_head_id);
    updatedepartment_status(showDepartment.dept_status === 'active');
    updateDepartment_desc(showDepartment.description);
    updateEditStatus(true);
  };

  // handle delete
  const onDelete = (data) => {
    updateShowConformPopup(true);
    updateDeleteid(data);
  };

  // handle department status change
  const handleStatusChange = (value, id) => {
    const config = {
      data: {
        dept_status: value ? 'active' : 'inactive',
      },
    };
    editDepartmentStatus(config, id, fetchDepartmentList);
  };

  // handle view button click
  const onView = (data) => {
    updateShowDepartmentPopup(true);
    updateshowDepartment(data);
  };

  //handle modal close
  const handleClose = () => {
    updateDepartment_name('');
    updatedepartment_head('');
    updateDepartment_desc('');
    updatedepartment_status(true);
    updateValidationErr(false);
    setDoctorUpdate({});
  };

  // on pagination change
  const handleCurrentPageChange = (pageNo) => {
    SetCurrentPage(pageNo);
    fetchDepartmentList({ pageNo });
  };

  // handel perPage change
  const handlePerPageChange = (count) => {
    SetPerPage(count);
    SetCurrentPage(1);
    fetchDepartmentList({ pageNo: 1, perPageCount: count });
  };

  // load options to the dropdown on typing
  const loadOptions = async (inputValue) => {
    const fetchedOptions = [];
    if (inputValue?.trim()) {
      let id = new Date().getTime()
      setDeptLoader((prev)=>({
        ...prev,
        [id]:true
      }));
      await DepartmentsApi.FetchAllDepartmentList({
        search: inputValue,
        limit: 0,
      }).then(({ response }) => {
        setDeptLoader((prev)=>({
          ...prev,
          [id]:false
        }));
        if (response?.departments) {
          response.departments.forEach((department) => {
            // if (inputValue !== department.dept_name)
            fetchedOptions.push({
              label: department.dept_name,
              value: department.dept_id,
            });
          });
        } else {
          fetchedOptions.push({
              value: '',
              label: `${intl.formatMessage({
                id: 'common.noData',
                defaultMessage: 'No data found.',
              })}`,
              isDisabled: true,
          })
        }
      });
    }
    setDeptSuggestion(fetchedOptions);
  };

  // handle department name auto selection
  const handleDeptNameSelect = (value) => {
    updateSearchValue(value.label);
    setDeptId(value.value);
    setIsSelect(true);
    setDeptSuggestion([]);
  };
  const handleKeyPress = (e) => {
    if (e && e.key === 'Enter') {
      if (searchValue || selectedDepartStatus) {
        handleApplyClick();
      }
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [searchValue, selectedDepartStatus]);

  const onApptConflictConfirm = () => {
    setShowApptConflictPopup(false);
    history.push(`/admin/departments/reschedule/${conflictId}`);
  };

  const removeDocList = (event, doctor) => {
    const newnonDeptDoctors = [...nonDeptDoctors].map((doc) => ({
      ...doc,
      isAdded: doctor.value === doc.value ? false : doc.isAdded,
    }));
    setNonDeptDoctors(newnonDeptDoctors);
    const doctorsCopy = [...doctors];
    const docIndex = doctorsCopy.findIndex((x) => x.value === doctor.value)
    doctorsCopy.splice(docIndex, 1);
    setDoctors(doctorsCopy);

    const newDoctors = add ? [...add] : [];
    if (newDoctors.includes(doctor.value)) {
      const remove = newDoctors.findIndex((item) => item === doctor.value);
      if (remove > -1) {
        newDoctors.splice(remove, 1);
        setDoctorUpdate((prev) => ({
          ...prev,
          add: newDoctors,
        }));
      }
    } else {
      const removedDoctors = remove ? [...remove] : [];
      removedDoctors.push(doctor.value);
      setDoctorUpdate((prev) => ({
        ...prev,
        remove: removedDoctors,
      }));
    }
  }

  const docRemoveAction = (doctor, index) => {
    const newnonDeptDoctors = [...nonDeptDoctors].map((doc) => ({
      ...doc,
      isAdded: doctor.value === doc.value ? false : doc.isAdded,
    }));
    setNonDeptDoctors(newnonDeptDoctors);
    const doctorsCopy = [...doctors];
    doctorsCopy.splice(index, 1);
    setDoctors(doctorsCopy);
    const newDoctors = add ? [...add] : [];
    if (newDoctors.includes(doctor.value)) {
      const remove = newDoctors.findIndex((item) => item === doctor.value);
      if (remove > -1) {
        newDoctors.splice(remove, 1);
        setDoctorUpdate((prev) => ({
          ...prev,
          add: newDoctors,
        }));
      }
    } else {
      const removedDoctors = remove ? [...remove] : [];
      removedDoctors.push(doctor.value);
      setDoctorUpdate((prev) => ({
        ...prev,
        remove: removedDoctors,
      }));
    }
  }  
  // handle outside close of filter
  const handleOutsideClose = () => {
    updateSearchValue(searchData?.searchValue ?? '');
    setIsSelect(searchData?.isSelect);
  }

  return (
    <div className={`${showPopup ? 'add-dept-modal ':''}`}>
      {showPopup && (
        <PopupWrapper
          title={
            <FormattedMessage
              id="departments.addDepartment"
              defaultMessage="Add New Department"
            />
          }
          onClose={() => {
            updateShowPopup(false);
            handleClose();
          }}
          onSubmit={() => handleSubmit()}
          modalContent="modalContent modal_xs "
          isBtnLoading={btnLoading}
        >
          <AddDepartment
            updateDepartment_name={updateDepartment_name}
            updatedepartment_head={updatedepartment_head}
            updateDepartment_desc={updateDepartment_desc}
            updatedepartment_status={updatedepartment_status}
            department_status={department_status}
            department_name={department_name}
            validationErr={validationErr}
            updateValidationErr={updateValidationErr}
            intl={intl}
          />
        </PopupWrapper>
      )}
      {showDepartmentPopup && (
        <PopupWrapper
          title={
            <FormattedMessage
              id="departments.viewDepartment"
              defaultMessage="View Department"
            />
          }
          onClose={() => updateShowDepartmentPopup(false)}
          modalContent="modalContent modal_md"
          onSubmit={permission(UPDATE)&&userType !== USER_TYPE.appointmentCordinator && onEditFromView}
          cancelBtn={false}
          saveBtn={<FormattedMessage id="common.edit" defaultMessage="EDIT" />}
          autoFocus={true}
        >
          <ViewDepartment showDepartment={showDepartment} onSubmit={permission(UPDATE) && onEditFromView}/>
        </PopupWrapper>
      )}
      {showEditDepartmentPopup && (
        <PopupWrapper
          title={
            <FormattedMessage
              id="departments.editDepartment"
              defaultMessage="Edit Department"
            />
          }
          onClose={() => {
            updateShowEditDepartmentPopup(false);
            updateEditStatus(false);
            handleClose();
          }}
          onSubmit={handleSubmit}
          modalContent="modalContent modal_md"
          saveBtn={
            <FormattedMessage id="common.update" defaultMessage="UPDATE" />
          }
          isBtnLoading={btnLoading}
        >
          <EditDepartment
            focusArea={focusArea}
            dataToEdit={dataToEdit}
            updatedepartment_head={updatedepartment_head}
            updateDepartment_desc={updateDepartment_desc}
            updatedepartment_status={updatedepartment_status}
            department_name={department_name}
            department_head={department_head}
            department_desc={department_desc}
            department_status={department_status}
            validationErr={validationErr}
            doctorUpdate={doctorUpdate}
            setDoctorUpdate={setDoctorUpdate}
            intl={intl}
            updateShowRemConformPopup={updateShowRemConformPopup}
            setRemoveDocList={setRemoveDocList}
            removeDocList={removeDocList}
            nonDeptDoctors={nonDeptDoctors}
            setNonDeptDoctors={setNonDeptDoctors}
            doctors={doctors}
            setDoctors={setDoctors}
            add={add}
            remove={remove}
            selectedDepartHead={selectedDepartHead}
            SetSelectedDepartHead={SetSelectedDepartHead}
            updateShowConformPopup={updateShowConformationPopup}
            setRemoveDoc={setRemoveDoc}
            docRemoveAction={docRemoveAction}
          />
        </PopupWrapper>
      )}
       {showRemConformPopup && (
        <ConformPopup
          onClose={() => updateShowRemConformPopup(false)}
          onConfirm={() => {
            const {event ='',  doctor = ''} = removeDocLists
            removeDocList(event, doctor)
            updateShowRemConformPopup(false)
            SetSelectedDepartHead('')
            updatedepartment_head('');
          }}
          message={`${selectedDepartHead.label} ${intl.formatMessage({
            id: 'departments.deptHeadRemove',
            defaultMessage: 'is the head of this department, Are you sure you want to proceed?',
          })}`}
        />
      )}
      {showConformPopup && (
        <ConformPopup
          onClose={() => updateShowConformPopup(false)}
          onConfirm={() => {
            deleteDepartmentList(delelteid);
          }}
          message={translate(
            'common.doYouWantToDeleteDepartment',
            'Are you sure you want to delete this department?'
          )}
          isBtnLoading={btnLoading}
        />
      )}
      {showConformationPopup && (
        <ConformPopup
          onClose={() => updateShowConformationPopup(false)}
          onConfirm={() => {
            const { doctor = '', index = '' } = removeDoc
            docRemoveAction(doctor, index)
            updateShowConformationPopup(false)
            SetSelectedDepartHead('')
            updatedepartment_head('');
          }}
          message={`${selectedDepartHead.label} ${intl.formatMessage({
            id: 'departments.deptHeadRemove',
            defaultMessage: 'is the head of this department, Are you sure you want to proceed?',
          })}`}
        />
      )}
      <HeaderFields
        totalCount={totalCount}
        onApply={handleApplyClick}
        onReset={handleReset}
        //here
        onAddBtnClick={permission(CREATE)&&userType !== USER_TYPE.appointmentCordinator ? () => updateShowPopup(true) : ''}
        childBreakPt={11}
        btnBreakPt={1}
        applyBtnCls={'col-sm-2'}
        isApplied={isApplied}
        handleOutsideClose={handleOutsideClose}
      >
        <div className="col-md-12">
          <div className="form-group mb-0">
          <label htmlFor="">{intl.formatMessage({id: 'departments.deptNameId', defaultMessage: 'Department Name',})}</label>
            <div className={`search-input ${searchValue !== '' && 
            Object.values(deptLoader).includes(true)
            && !isSelect
            && 'loading'}`}>
            <input
              type="search"
              className="form-control down-arrow text-capitalize"
              onChange={(event) => onChangeSearch(event)}
              placeholder={intl.formatMessage({
                id: 'common.search',
                defaultMessage: 'Search',
              })}
              value={searchValue}
              maxLength="50"
              autoComplete="off"
              onBlur={() => setTimeout(() => setDeptSuggestion([]), 400)}
              onFocus={() => {
                if (searchValue) loadOptions(searchValue);
              }}
            />
              </div>
            {deptSuggestion.length
            && !isSelect
            && !Object.values(deptLoader).includes(true)
            ? (
              <div className="customSearchList">
                <ul style={{ marginBottom: 1 }}>
                  {deptSuggestion.map((dept, index) => (
                    <li
                      key={index}
                      className={`suggestion-item ${dept.isDisabled ? 'disabled' : ''}`}
                      onClick={() => !dept.isDisabled && handleDeptNameSelect(dept)}
                    >
                      {dept.label}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </HeaderFields>
      <TableWrapper data={DepartmentList}>
        <div className="list-user" style={{minHeight: isLoading ? '55vh' : '', overflow: !DepartmentList?.length ? 'hidden' : ''}}>
          {DepartmentList && DepartmentList.length && !isLoading ? (
            <table
              className={
                isLoading
                  ? 'table departments-calendar tableNodata v2'
                  : 'table departments-calendar v2'
              }
            >
              <thead>
                <tr>
                  {DEPARTMENT_HEADERS.map((header, index) => (
                    <TableColumnHr
                      item={header}
                      key={index}
                      columnSort={columnSort}
                      setColumnSort={setColumnSort}
                      sortEnable={DepartmentList.length > 1 ? true : false}
                    />
                  ))}
                </tr>
              </thead>
              <tbody>
                {DepartmentList.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{(currentPage - 1) * perPage + index + 1}</td>
                    <td className="text-capitalize">{item.dept_name}</td>
                    <td>
                      {item?.dept_head?.dept_head_id
                        ? `Dr. ${item?.dept_head?.dept_head_name}`
                        : '-'}
                    </td>
                    <td>
                      <CustomSwitch
                        disabled={!permission(UPDATE)||(userType === USER_TYPE.appointmentCordinator)}
                        checkedProps={item.dept_status === 'active'}
                        handleSwitchChange={(value) =>
                          handleStatusChange(value, item.dept_id)
                        }
                        key={item.dept_id}
                      />
                      {item.dept_status === 'active'
                          ? translate('common.active', 'Active')
                          : translate('common.inactive', 'Inactive')}
                    </td>
                    <td>
                      <div className="action-btns v3">
                        <Tooltip
                          title={
                            <FormattedMessage
                              id="departments.viewDepartment"
                              defaultMessage="View Department"
                            />
                          }
                          placement="top"
                        >
                          <span className='view-icon' 
                          tabIndex="0" 
                          onKeyDown={(e)=>{
                            if(e.key === "Enter" && !e.shiftKey) onView(item)
                          }}
                          onClick={() => onView(item)}>
                            {viewIcon}
                          </span>
                        </Tooltip>
                        {permission(UPDATE)&&userType !== USER_TYPE.appointmentCordinator && (
                          <Tooltip
                            title={
                              <FormattedMessage
                                id="departments.editDepartment"
                                defaultMessage="Edit Department"
                              />
                            }
                            placement="top"
                          >
                            <span className='edit-icon' 
                            tabIndex="0" 
                            onKeyDown={(e)=>{
                              if(e.key === "Enter" && !e.shiftKey) onEdit(item)
                            }}
                            onClick={() => onEdit(item)}>
                              {editIcon}
                            </span>
                          </Tooltip>
                        )}
                        {permission(DELETE)&&userType !== USER_TYPE.appointmentCordinator && (
                          <Tooltip
                            title={
                              <FormattedMessage
                                id="departments.deleteDepartment"
                                defaultMessage="Delete Department"
                              />
                            }
                            placement="left"
                          >
                            <span className='delete-icon' 
                            tabIndex="0" 
                            onKeyDown={(e)=>{
                              if(e.key === "Enter" && !e.shiftKey) onDelete(item.dept_id);
                            }}
                            onClick={() => onDelete(item.dept_id)}>
                              {deleteIcon}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : !isLoading ? (
              <div className='patient-portal'>
                <NoResultFound text={isApplied ? translate('common.noResult', 'No results found.') :
                  translate('departments.noDataDepts', 'No departments added')}
                  label={isApplied ? translate('common.noResultMsg', 'No results match the filter criteria Remove filter or clear all filters to show results') :
                  translate('departments.noDataDeptsMsg', `Looks like you haven't added any departments yet.`)}
                  removeMarginBottom removeMarginTop
                  addNew={permission(CREATE)&&userType !== USER_TYPE.appointmentCordinator && !isApplied ? () => updateShowPopup(true) : ''} />
              </div>
          ) : (
            <Loader />
          )}
          {showApptConflictPopup && (
            <AppointmentConflictPopup
              onClose={() => {
                setShowApptConflictPopup(false);
              }}
              onConfirm={onApptConflictConfirm}
            />
          )}
        </div>
      </TableWrapper>
      {!isLoading && DepartmentList && DepartmentList.length > 0 ? (
        <Pagination
          SetCurrentPage={handleCurrentPageChange}
          SetPerPage={handlePerPageChange}
          perPage={parseInt(perPage)}
          totalCount={totalCount}
          currentPage={currentPage}
        />
      ) : null}
    </div>
  );
};
const mapStateToProps = (state) => ({
  searchData: state.DepartmentReducer.searchData,
  perPage: state.DepartmentReducer.paginationData.perPage,
  currentPage: state.DepartmentReducer.paginationData.currentPage,
  columnSort: state.DepartmentReducer.columnSort,
  userType: state.LoginReducer.user_type,
});

const mapDispatchToProps = (dispatch) => ({
  displayToaster: (message, type) => dispatch(showToaster(message, type)),
  getDoctorList: (status) => dispatch(FetchDoctorList(status)),
  getDepartmentSuggestionList: () => dispatch(FetchDepartmentSuggestionList()),
  clearAll: () => dispatch(cleraAllData()),
  clearAllExceptFilter: () => dispatch(clearDeptDataExceptFilter()),
  editDepartmentStatus: (config, id, fetch) =>
    dispatch(UpdateDepartmentStatus(config, id, fetch)),
  applySearchData: (data) => dispatch(updateSearchData(data)),
  SetCurrentPage: (data) => dispatch(updateCurrentPage(data)),
  SetPerPage: (data) => dispatch(updatePerPage(data)),
  setColumnSort: (data) => dispatch(updateColumnSort(data)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissionCheck(DEPARTMENT)
)(Departments);
