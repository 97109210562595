import network from '../Utils/Api/Network';
import { showToaster, ToasterTypes } from './toasterAction';

export const RECEIVE_OVERRIDE_DETAILS = 'RECEIVE_OVERRIDE_DETAILS';
export const DOCTOR_BTN_LOADING = 'DOCTOR_BTN_LOADING';
export const OVERRIDE_SLOT_LOADING= 'OVERRIDE_SLOT_LOADING'


const receiveOverrideDetails = (payload) => ({
  type: RECEIVE_OVERRIDE_DETAILS,
  payload,
});

export const doctorBtnLoading = (payload) => ({
  type: DOCTOR_BTN_LOADING,
  payload
});

export const overrideSlotLoading = (payload) => ({
  type: OVERRIDE_SLOT_LOADING,
  payload
});


export const FetchOverrideDetails = (id) => {
  return (dispatch) => {
    dispatch(overrideSlotLoading(true))
    return network
      .get({
        url: `/doctor/${id}/schedule-override`,
        data: { limit: 0, sort: 'start_date' },
      })
      .then((response) => {
        dispatch(overrideSlotLoading(false))
        const { isError, result } = response;
        if (isError) {
          dispatch(receiveOverrideDetails({}));
        } else {
          dispatch(receiveOverrideDetails(result));
        }
      });
  };
};

export const addOverrideSlots = (
  doctorId,
  data,
  handleSuccess = () => { },
  index
) => {
  return (dispatch) => {
    dispatch(doctorBtnLoading(true))
    return network
      .post({ url: `/doctor/${doctorId}/schedule-override`, data })
      .then((response) => {
        dispatch(doctorBtnLoading(false))
        const { isError, message, result,code } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          if(code===210){
            dispatch(showToaster(`${message}. ${result.message}`, ToasterTypes.Info,5000));
          }else{
            dispatch(showToaster(message, ToasterTypes.Success));
          }
          handleSuccess(index, result);
        }
      });
  };
};

export const updateOverrideSlots = (doctorId, id, data, handleSuccess = () => { }) => {
  return (dispatch) => {
    dispatch(doctorBtnLoading(true))
    return network
      .put({ url: `/doctor/${doctorId}/schedule-override/${id}`, data })
      .then((response) => {
        dispatch(doctorBtnLoading(false))
        const { isError, message,code,result } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          handleSuccess()
          if(code===210){
            dispatch(showToaster(`${message}. ${result.message}`, ToasterTypes.Info,5000));
          }else{
          dispatch(showToaster(message, ToasterTypes.Success));
          }
        }
      });
  };
};

export const deleteOveride = (
  doctorId,
  id,
  handleDeleteSuccess = () => { },
  index
) => {
  return (dispatch) => {
    return network
      .delete({
        url: `/doctor/${doctorId}/schedule-override/${id}`,
      })
      .then((response) => {
        const { isError, message } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          dispatch(showToaster(message, ToasterTypes.Success));
          handleDeleteSuccess(index);
        }
      });
  };
};


export const updateSlots = (doctorId, data, handleSuccess = () => { }) => {
  return (dispatch) => {
    dispatch(doctorBtnLoading(true))
    return network
      .put({ url: `/doctor/${doctorId}/schedule-plan`, data })
      .then((response) => {
        dispatch(doctorBtnLoading(false))
        const { isError, message } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          handleSuccess()
          dispatch(showToaster(message, ToasterTypes.Success));
        }
      });
  };
};