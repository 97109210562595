/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useParams,useLocation } from 'react-router-dom';
import './style/addDoctor.css';
import { DoctorsApi } from '../../Utils/Api/DoctorsApi';
import { DepartmentsApi } from '../../Utils/Api/DepartmentApi';
import ConformPopup from '../Common/ConformPopup';
import svgIc from 'Assets/img/icons/placeholder-avatar.svg'
import Stack from '@mui/material/Stack';
// import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import Checkbox from '../Common/Checkbox';
import { CardMedia } from '@mui/material';
import { connect, useDispatch, useSelector } from 'react-redux';
import { showToaster, ToasterTypes } from '../../Actions/toasterAction';
import MultipleSelect from '../Common/MultipleSelect';
import Loader from '../Common/Loader';
import PhoneNumberInput from '../Common/PhoneInput';
import CropImage from '../Common/CropImage';
import { translate } from '../../Language/Translate';
import withPermissionCheck from '../../Utils/WithUtils/WithPermission';
import { DOCTOR } from '../../Constants/feature';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FetchUpdatedUserDetails } from 'Actions/usersAction';
import editProfile from 'Assets/img/icons/edit-profile-icon.svg'
import historyIcon from '../../Assets/img/icons/history-icon.svg';
import deleteIicon from 'Assets/img/icons/trash-circle.svg';
import {
  // dateInFormat2,
  // formatDate,
  // timeIn12HourfromDate,
  appendTime,
} from 'Utils/DateFormatter/Date';
import { CREATE, READ, READ_DETAILS, UPDATE } from 'Constants/scopes';
import { useIntl } from 'react-intl';
import { USER_TYPE } from 'Constants/userTypes';
import ButtonLoader from 'Components/Common/ButtonLoader';
import { compose } from 'redux';
import { formatDateto_D_M_Y } from 'Utils/DateFormatter/Date';
import { validateEmail, validateMobile } from 'Utils/Validation/validator';
import useNavigationWarning from 'Hooks/useNavigationWarning';
import { convertToBase64URI } from 'Utils/Services/Authenticator';
import AppointmentConflictPopup from 'Components/Doctors/ApptConflictPopup';
import AddDepartment from 'Components/Departments/AddDepartment';
import PopupWrapeer from 'Components/Common/PopupWrapeer';
import { FetchDepartmentSuggestionList } from 'Actions/departmentAction';
import { ReactComponent as CloseIcon } from 'Assets/img/icons/close.svg'
import 'react-phone-number-input/style.css'

const Gender = [
  { label: translate('common.male', 'Male'), value: 'male' },
  { label: translate('common.female', 'Female'), value: 'female' },
  { label: translate('common.other', 'Other'), value: 'other' },
];

const AddDoctor = (props) => {
  const { ACCESS, language_Code, userDetails, doctorDetails, displayToaster, getDepartmentSuggestionList } = props;
  const { Prompt, setIsSaved } = useNavigationWarning()
  const params = useParams();
  const location=useLocation()
  const { id: docId, userId } = params || {};
  const intl = useIntl();
  const focusRef = useRef()
  const history = useHistory();
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const [editStatus, setEditStatus] = useState(false);
  const [showConformPopup, updateShowConformPopup] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [docDepartmentList, setDocDepartmentList] = useState([]);
  const [docActive, setDocActive] = useState(true);
  const [appointmentType, setAppoinmentType] = useState(true);
  const [docFees, setDocFees] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [deptBtnLoading, setDeptBtnLoading] = useState(false)
  const [key, setKey] = useState(0);
  const [showCrop, setShowCrop] = useState(false);
  //for confirm remove dept
  const [selectedValue, setSelectedVal] = useState('');
  const [numberLength, setNumberLength] = useState(10);
  const [code,setCode]=useState('IN')
  const [showApptConflictPopup, setShowApptConflictPopup] = useState(false);

  const userType = useSelector((state) => state.LoginReducer.user_type);
  // get default country code
  const countryCode = useSelector(
    (state) => state.SettingsReducer.subscriptionData.setting.defaultCountryCode
  );

  // create useRef To focus crct area while happend validaton
  const nameError = useRef();
  const emailError = useRef();
  const phoneError = useRef();
  const deptError = useRef();
  const focusonEditref = useRef([]);
  let initialData = {
    docImage: '',
    docImgUpdate: false,
    docName: '',
    nameError: false,
    doclName: '',
    docGender: '',
    docQualification: '',
    docDesignation: '',
    docEmail: '',
    emailError: false,
    countrySelect: countryCode?.toString(),
    docPhone: '',
    phoneError: false,
    about: '',
    qualificationDetails: '',
    experience: 0,
    docLandLine: ''

  }
  const [commonData, setCommonData] = useState(initialData);
  const [commonDataDuplicate, setCommonDataDuplicate] = useState(initialData);
  const [errorState, setErrorState] = useState({});
  let [deptListResult, setDeptListResult] = useState([]);
  const [selectedDeptValue, setSelectDeptValue] = useState([]);
  const [conflictId, setConflictId] = useState('');

  // Single department details
  const [department_name, updateDepartment_name] = useState('');
  const [department_desc, updateDepartment_desc] = useState('');
  const [department_status, updatedepartment_status] = useState(true);
  const [showPopup, updateShowPopup] = useState(false);
  const [allDept, setAllDept] = useState([])
  const [showLandline, setShowLandline] = useState(false)


  // error exist check
  const [validationErr, updateValidationErr] = useState(false);

  const { user_id } = userDetails;
  const { doctor_id } = doctorDetails;

  const ref = useRef();
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (JSON.stringify(commonData) !== JSON.stringify(commonDataDuplicate)) {
      setIsSaved(false)
    }
    else {
      setIsSaved(true)
    }
  }, [commonData, commonDataDuplicate]);

  const handleClickOutside = (event) => {
    if (ref?.current && !ref?.current?.contains(event.target)) {
      setShowHistory(false); // Close on outside click
    }
  };

  const firstnameChange = (e) => {
    let errObj = {
      nameError: false,
    };
    errObj['nameError'] = '';
    setErrorState({ ...errorState, ...errObj });
    setCommonData({ ...commonData, docName: e.target.value });
  };

  const emailChange = (e) => {
    let errObj = {
      emailError: false,
    };
    errObj['emailError'] = '';
    errObj['invalidMail'] = '';
    setErrorState({ ...errorState, ...errObj });
    setCommonData({ ...commonData, docEmail: e.target.value });
  };

  const genderChange = (event) => {
    setCommonData({
      ...commonData,
      docGender: event.target.value,
    });
  };
  const handleSwtchChange = (val) => {
    setDocActive(val);
  };
  const handleTypeChange = (e) => {
    setAppoinmentType(e.target.checked);
  };
  const numberChange = (e) => {
    let errObj = {
      phoneError: false,
    };
    errObj['phoneError'] = '';
    errObj['invalidPhone'] = '';
    setErrorState({ ...errorState, ...errObj });
    const inputValue = e.target.value;
    // const regex = /^\+?([1-9]\d{0,2})\s*[-\.]?(?:\(?\d{3}\)?[-\.]?)?\d{3}\d{4}$/;
    const regex = /^\+?[0-9 ]*\.?[0-9 ]*$/;
    if (regex.test(inputValue)) {
      setCommonData({ ...commonData, docPhone: inputValue });
    }
  };

  const landLinenumberChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[()-?0-9]*$/;
    // const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(inputValue)) {
      setCommonData({ ...commonData, docLandLine: inputValue });
    }
  };

  const ExpChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(inputValue)) {
      setCommonData({ ...commonData, experience: inputValue });
    }
  };

  const feesChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(inputValue)) {
      setDocFees(inputValue);
    }
  };

  useEffect(() => {
    getDepartments();
    getDepartmentSuggestionList();
    if (docId) {
      viewDoctorDetails(docId);
      setEditStatus(true);
      if(location?.state?.focusInputref){
        let refName=location?.state?.focusInputref
        setTimeout(()=>{
          focusonEditref?.current[refName]?.focus()
        },[1000])
      }
    }
    if (location?.state?.isEditDepartment) {
      setSelectDeptValue([{value: location.state.value, label: location.state.label}])
    }
  }, []);

  const viewDoctorDetails = (id) => {
    setIsloading(true);
    let deptList = [];
    DoctorsApi.getDoctorDetails(id, { fee_history: true }).then((res) => {
      if (res.error) {
        return;
      } else {
        setIsloading(false);
        let data = res.response;
        let obj = {
          ...commonData,
          docName: data.doctor.name,
          docImage: data.doctor.profile_img,
          countrySelect: data.doctor.tele_country_code
            ? data.doctor.tele_country_code
            : countryCode?.toString(),
          doclName: data.doctor.last_name,
          // docQualification: data.doctor.qualification,
          experience: data.doctor.experience,
          docDesignation: data.doctor.designation,
          docEmail: data.doctor.email,
          docPhone: data.doctor.phone,
          about: data.doctor.about,
          qualificationDetails: data.doctor.qualification_details,
          docGender: data.doctor.gender,
          op_fee_history: data?.doctor_fee_history?.op_fee_history,
          docUserId: data.doctor.user_id,
          dob:data?.doctor?.dob,
          docLandLine: data.doctor?.alternate_phone
        }
        if(data.doctor?.alternate_phone){
          setShowLandline(true)
        }
        setCommonData(obj);
        setCommonDataDuplicate(obj)
        setDocFees(res.response.doctor.op_fees);
        setAppoinmentType(res.response.doctor.op_appointment);
        const list = res.response.departments?.map((val) => {
          deptList.push(val.dept_id);
          return { value: val.dept_id, label: val.dept_name };
        });
        setDeptListResult(deptList);
        setSelectDeptValue(list);
        if (res.response.doctor.doctor_status == 'active') {
          setDocActive(true);
        } else {
          setDocActive(false);
        }
      }
    });
  };

  const getDepartments = (setDefault = false) => {
    const data = {
      // status: 'active',
      limit: 0,
      sort: 'dept_name'
    };
    DepartmentsApi.FetchAllDepartmentList(data).then((res) => {
      if (res.error) {
        return;
      } else {
        const list = res.response.departments
        const activeDept = list?.filter((x) => x.dept_status === 'active')
        if(activeDept && Array.isArray(activeDept) && activeDept.length === 1 && setDefault) {
          setSelectDeptValue([{value: activeDept[0].dept_id, label: activeDept[0].dept_name}])
        }
        setDocDepartmentList(activeDept);
        setAllDept(list)
      }
    });
  };

  const addDoctorsList = async () => {
    let errObj = {
      nameError: false,
      emailError: false,
      phoneError: false,
      deptError: false,
      invalidMail: false,
      invalidPhone: false
    };
    // const phoneRegex =
    // /^\+?([1-9]\d{0,2})\s*[-\.]?(?:\(?\d{3}\)?[-\.]?)?\d{3}\d{4}$/;
    if (!commonData?.docName?.trim()) {
      errObj['nameError'] = true;
    } else {
      errObj['nameError'] = false;
    }
    if (selectedDeptValue.length == 0) {
      errObj['deptError'] = true;
    } else {
      errObj['deptError'] = false;
    }
    if (commonData.docEmail?.trim() == '') {
      errObj['emailError'] = true;
    } else {
      errObj['emailError'] = false;
    }
    if (commonData.docPhone ?.trim()== '') {
      errObj['phoneError'] = true
    } else if (!validateMobile(commonData.docPhone?.trim(),code)) {
      errObj['invalidPhone'] = true
    } else {
      errObj['phoneError'] = false;
      errObj['invalidPhone'] = false
    }

    if (commonData.docEmail?.trim() && !validateEmail(commonData.docEmail)) {
      errObj['invalidMail'] = true;
    } else {
      errObj['invalidMail'] = false;
    }

    setErrorState({ ...errorState, ...errObj });
    if (Object.values(errObj).includes(true)) {
      if (errObj.nameError) {
        nameError.current.scrollIntoView();
      } else if (errObj.deptError) {
        deptError.current.scrollIntoView();
      } else if (errObj.emailError || errObj.invalidMail) {
        emailError.current.scrollIntoView();
      } else if (errObj.phoneError || errObj.invalidPhone) {
        phoneError.current.scrollIntoView();
      }
    } else {
      let deptarr = [];
      selectedDeptValue?.map((val) => deptarr.push(val.value));

      const data = {
        data: {
          doctor: {
            email: commonData.docEmail,
            tele_country_code: commonData.countrySelect,
            phone: commonData.docPhone?.replace(/\s/g, ""),
            alternate_phone: commonData.docLandLine ? commonData.docLandLine?.replace(/\s/g, "") : '',
            address: '',
            profile_img: commonData.docImage,
            // dob: '1997-01-01',
            gender: commonData.docGender,
            name: commonData.docName,
            last_name: commonData.doclName,
            // qualification: commonData.docQualification,
            experience: commonData.experience && Number(commonData.experience),
            qualification_details: commonData.qualificationDetails,
            designation: commonData.docDesignation,
            doctor_status: docActive == true ? 'active' : 'inactive',
            about: commonData.about,
            online_appointment: false,
            op_appointment: appointmentType,
            op_fees: parseInt(docFees),
            tele_cunsultation_fee: 0,
          },
          departments: deptarr,
        },
      };
      setBtnLoading(true);
      setIsSaved(true)
      DoctorsApi.AddDoctorsList(data).then(({ error, displayMessage }) => {
        setBtnLoading(false);
        if (error) {
          dispatch(showToaster(error, ToasterTypes.Failed));
          return;
        } else {
          dispatch(showToaster(displayMessage, ToasterTypes.Success));
          history.push({
            pathname: '/admin/doctorslisting',
            state: {
              user_data: true,
            },
          });
          setEditStatus(false);
        }
      });
    }
  };

  const editdoctorList = async (id) => {
    let errObj = {
      nameError: false,
      emailError: false,
      phoneError: false,
      deptError: false,
      invalidMail: false,
      invalidPhone:false
    };
    // const phoneRegex =
    //   /^\+?([1-9]\d{0,2})\s*[-\.]?(?:\(?\d{3}\)?[-\.]?)?\d{3}\d{4}$/;
    if (!commonData.docName?.trim()) {
      errObj['nameError'] = true;
    } else {
      errObj['nameError'] = false;
    }
    // if (commonData.docEmail == '') {
    //   errObj['emailError'] = true;
    // } else {
    //   errObj['emailError'] = false;
    // }
    // if (commonData.docPhone == '') {
    //   errObj['phoneError'] = true;
    // } else if (!validateMobile(commonData.docPhone,numberLength)) {
    //   errObj['invalidPhone'] = true
    // } else {
    //   errObj['phoneError'] = false;
    //   errObj['invalidPhone'] = false
    // }
    if (selectedDeptValue.length == 0) {
      errObj['deptError'] = true;
    } else {
      errObj['deptError'] = false;
    }
    // if (commonData.docEmail && !validateEmail(commonData.docEmail)) {
    //   errObj['invalidMail'] = true;
    // } else {
    //   errObj['invalidMail'] = false;
    // }
    setErrorState({ ...errorState, ...errObj });
    if (Object.values(errObj).includes(true)) {
      if (errObj.nameError) {
        nameError.current.scrollIntoView();
      } else if (errObj.deptError) {
        deptError.current.scrollIntoView();
      }
      // else if (errObj.emailError || errObj.invalidMail) {
      //   emailError.current.scrollIntoView();
      // } else if (errObj.phoneError || errObj.invalidPhone) {
      //   phoneError.current.scrollIntoView();
      // }
    } else {
      let deptarr = [];
      selectedDeptValue?.map((val) => deptarr.push(val.value));
      const addedDept = deptarr.filter((val) => {
        return !deptListResult.includes(val);
      });
      const removedDept = deptListResult.filter((val) => {
        return !deptarr.includes(val);
      });

      const data = {
        data: {
          doctor: {
            name:commonData.docName,
            last_name: commonData.doclName,
            gender: commonData.docGender,
            designation: commonData.docDesignation,
            profile_img: commonData.docImage,
            profile_image_update: commonData.docImgUpdate,
            // qualification: commonData.docQualification,
            experience: commonData.experience
              ? Number(commonData.experience)
              : 0,
            qualification_details: commonData.qualificationDetails,
            about: commonData.about,
            op_fees: parseInt(docFees),
            tele_cunsultation_fee: 0,
            online_appointment: false,
            op_appointment: appointmentType,
            doctor_status: docActive == true ? 'active' : 'inactive',
            dob:commonData?.dob,
            alternate_phone: commonData.docLandLine ? commonData.docLandLine?.replace(/\s/g, "") : '',
            // dob: '1997-01-01',
          },
          departments: {
            add: [...addedDept],
            remove: [...removedDept],
          },
        },
      };
      setIsSaved(true)
      setBtnLoading(true);
      DoctorsApi.EditDoctorsList(data, id).then(({ error, response, message, code }) => {
        setBtnLoading(false);
        if (error) {
          if (code === 409) {
            const encodedValue = convertToBase64URI(response)
            setConflictId(`doctor:${encodedValue}`)
            setShowApptConflictPopup(true);
          } else {
            dispatch(showToaster(error, ToasterTypes.Failed));
          }
          return;
        } else {
          dispatch(showToaster(message, ToasterTypes.Success));

          if (userId === user_id) {
            dispatch(FetchUpdatedUserDetails());
          }
          if (ACCESS(READ) && userType !== USER_TYPE.doctor) {
            history.push({
              pathname: '/admin/doctorslisting',
              state: {
                user_data: true,
              },
            });
          } else {
            history.push({
              pathname: `/admin/doctorslisting/doctordetails/${doctor_id}/${user_id}`,
              state: {
                user_data: true,
              },
            });
          }
          setEditStatus(false);
        }
      });
    }
  };
  const handleKeyDown = (e)=>{
    const dropDownBtn = document.querySelector('.selected-flag')
    if(e.key === "Enter"){
      if(!dropDownBtn.classList.contains("open")){
        e.preventDefault()
        dropDownBtn.classList.toggle("open")
        dropDownBtn.click()
      }
      else
      {
        phoneError.current?.focus()
      }
    }
    if(e.key === "Tab" && dropDownBtn.classList.contains("open")){
      e.preventDefault()
      dropDownBtn.classList.contains("open")
      focusRef.current?.focus()
        dropDownBtn.click()
    }
  }

  const handleSubmit = () => {
    if (editStatus) {
      editdoctorList(docId);
    } else {
      addDoctorsList();
    }
  };

  // Add new department
  const addDepartmentList = async () => {
    // updateLoading(true);
    const config = {
      data: {
        dept_name: department_name,
        dept_head_id: '',
        dept_status: department_status ? 'active' : 'inactive',
        description: department_desc,
      },
    };
    setDeptBtnLoading(true);
    DepartmentsApi.AddDepartmentList(config).then(
      ({ error, response, message }) => {
        setDeptBtnLoading(false);
        if (error) {
          displayToaster(error, ToasterTypes.Failed);
          return;
        }
        if (response) {
          displayToaster(message, ToasterTypes.Success);
          getDepartments(true);
          handleClose();
          updateShowPopup(false);
        } else {
          displayToaster(message, ToasterTypes.Failed);
        }
      }
    );
  };

  // handle dept add
  const handleDeptSubmit = () => {
    if (department_name && department_name?.trim()?.length > 0) {
      addDepartmentList();
    } else {
      updateValidationErr(true);
    }
  }

  const uploadFile = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    setKey(key + 1);
    const file = event.target.files[0];
    const reader = new FileReader();
    const maxSizeInBytes = 5 * 1024 * 1024;

    if (
      file?.type !== 'image/jpeg' &&
      file?.type !== 'image/jpg' &&
      file?.type !== 'image/png' &&
      file
    ) {
      dispatch(
        showToaster(
          translate('settings.wrongfile', 'Unsupported file format'),
          ToasterTypes.Warning
        )
      );
    } else if (file?.size > maxSizeInBytes) {
      dispatch(
        showToaster(
          translate(
            'settings.verifySize',
            'File size exceeds the 5MB limit. Please select a smaller file.'
          ),
          ToasterTypes.Warning
        )
      );
    } else {
      reader.onload = () => {
        setCommonData({
          ...commonData,
          docImageCrop: reader.result,
          // docImgUpdate: true,
        });
        setShowCrop(true);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  // hanlde crop modal close
  const handleCropClose = () => {
    setCommonData({
      ...commonData,
      docImageCrop: '',
      // docImgUpdate: true,
    });
    setShowCrop(false);
  };

  const handleMobileNumberChange = (phone) => {
    setCommonData({ ...commonData, countrySelect: phone });
  };

  useEffect(() => {
    if (!commonData.countrySelect) {
      handleMobileNumberChange(countryCode?.toString());
    }
  }, [countryCode]);

  useEffect(() => {
    let errObj = {
      deptError: false,
    };
    if (selectedDeptValue.length != 0) {
      errObj['deptError'] = '';
      setErrorState({ ...errorState, ...errObj });
    }
  }, [selectedDeptValue]);

  // on feehistory view click
  const handleFeehistory = () => {
    setShowHistory(!showHistory);
  };

  // on fee history close click
  const handleHistoryClose = () => {
    setShowHistory(false);
  };

  const setData = (data) => {
    setCommonData((prev) => ({
      ...prev,
      docImage: data,
      docImgUpdate: true,
    }));
  };
  

  const headDepts = docDepartmentList?.filter(item => item?.dept_head?.dept_head_id === commonData?.docUserId)

  const handleDeptMultiSelect = (values, options, actionMeta) => {
    const { action, option, removedValues, removedValue } = actionMeta
    if (action === 'deselect-option' || action === 'remove-value' || action === 'clear') {
      setSelectedVal(values)
      if(action === 'clear'){
        const isExisted = headDepts.filter((el) => removedValues.some((x) => x.value === el.dept_id));
        if(isExisted.length){
          updateShowConformPopup(true)
          focusonEditref.current[`deptRef`]?.blur()
          return
        }
      }else{
        const removedVal = option || removedValue
        const isExisted = headDepts.find((item)=> item.dept_id === removedVal.value)
        if(isExisted){
          updateShowConformPopup(true)
          focusonEditref.current[`deptRef`]?.blur()
          return
        }
      }
    }
    setSelectDeptValue(values)
  }

  const onApptConflictConfirm = () => {
    setShowApptConflictPopup(false);
    history.push(`/admin/doctorslisting/reschedule/${conflictId}`);
  };

  const handleClose = () => {
    updateDepartment_name('');
    updateDepartment_desc('');
    updatedepartment_status(true);
    updateValidationErr(false);
    updateShowPopup(false);
  };


  return (
    <div className="add-dept-modal">
      {showPopup && (
        <PopupWrapeer
          title={translate("departments.addDepartment", "Add New Department")}
          onClose={() => {
            handleClose();
          }}
          onSubmit={() => handleDeptSubmit()}
          modalContent="modalContent modal_xs "
          isBtnLoading={deptBtnLoading}
        >
          <AddDepartment
            updateDepartment_name={updateDepartment_name}
            updateDepartment_desc={updateDepartment_desc}
            updatedepartment_status={updatedepartment_status}
            department_status={department_status}
            department_name={department_name}
            validationErr={validationErr}
            updateValidationErr={updateValidationErr}
            intl={intl}
            disableInactive
          />
        </PopupWrapeer>
      )}
      {showCrop && (
        <CropImage
          imgSrc={commonData.docImageCrop}
          setShowCrop={setShowCrop}
          setData={setData}
          onClose={handleCropClose}
          title={translate("common.crop", "Crop Image")}
        />
      )}
      {isLoading ? (
        <div
          className="list-user"
          style={{ minHeight: isLoading ? "55vh" : "" }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {ACCESS(READ) && userType !== USER_TYPE.doctor ? (
            <div className="admin-bread-crumbs mb-lg-2 fs-12">
              <div className="wrapper links">
                <a className="previous" href="#/admin/doctorslisting">
                  {translate("doctors.title", "Doctors")}
                </a>
                <span> {">"} </span>
                <a className="bread-crumb-cursor">
                  {editStatus
                    ? translate("doctors.editDoctor", "Edit Doctor")
                    : translate("doctors.addDoctor", "Add Doctor")}
                </a>
              </div>
            </div>
          ) : null}
          <div className="input-area doc-personal-information design-doctor add-doc">
            <div className="row">
              <div className="col-lg-3">
                <div className="left-card">
                  <div className="profile-image-container">
                    <div className="inner-wrapper">
                      <input
                        key={key}
                        type="file"
                        ref={hiddenFileInput}
                        accept="image/*"
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                      <Stack
                        style={{ cursor: "pointer", justifyContent: "center" }}
                        onClick={uploadFile}
                        direction="row"
                        spacing={2}
                      >
                        <div className="pic-delete-wrapper v2">
                          {commonData?.docImage && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setCommonData({
                                  ...commonData,
                                  docImage: "",
                                  docImgUpdate: true,
                                });
                              }}
                              className="btn-transparent"
                            >
                              <img src={deleteIicon} alt="" />
                            </button>
                          )}
                          {commonData.docImage ? (
                            <CardMedia
                              style={{
                                width: 90,
                                height: 90,
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                              component="img"
                              image={commonData.docImage}
                            />
                          ) : (
                            <img
                              src={svgIc}
                              style={{ width: 90, height: 90 }}
                            />
                          )}
                        </div>
                      </Stack>
                      <div
                        className="doc-addpicicn"
                        onClick={uploadFile}
                        style={{
                          left: 66,
                        }}
                      >
                        <img
                          src={editProfile}
                          style={{ width: 15, height: 15 }}
                        />
                      </div>
                      <p
                        className="fs-11"
                        style={{
                          marginTop: 10,
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        (PNG, JPG, JPEG )
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 mt-3 mt-lg-0">
                <div className="row">
                  <div className="col-md-5">
                    <div
                      className={
                        errorState.nameError ? "form-group error" : "form-group"
                      }
                    >
                      <label className="doc-labels">
                        {translate("common.firstName", "First Name")}
                        <span style={{ color: "red", height: 0 }}>*</span>
                      </label>
                      <div className="with-prefix">
                        <div className="prefix">Dr.</div>
                        <input
                          type="text"
                          maxLength={40}
                          className="form-control"
                          onChange={firstnameChange}
                          value={commonData.docName}
                          style={{ scrollMargin: "100px" }}
                          ref={nameError}
                          autoFocus
                        />
                      </div>
                      {errorState.nameError ? (
                        <p
                          className="mt-1 fs-11 fw-500"
                          style={{ color: "red", height: 0 }}
                        >
                          {translate(
                            "common.required",
                            "This field is required"
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <label className="doc-labels">
                        {translate("common.lastName", "Last Name")}
                      </label>
                      <input
                        name="lastname"
                        type="text"
                        maxLength={40}
                        className="form-control"
                        onChange={(e) =>
                          setCommonData({
                            ...commonData,
                            doclName: e.target.value,
                          })
                        }
                        value={commonData.doclName}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <label className="doc-labels">
                        {translate("doctors.gender", "Gender")}
                      </label>
                      <RadioGroup
                        row
                        value={commonData.docGender}
                        onChange={genderChange}
                      >
                        {Gender.map((x, index) => (
                          <FormControlLabel
                            key={index + 1}
                            value={x.value}
                            control={<Radio />}
                            label={x.label}
                          />
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-7">
                    <div className="form-group">
                      <div className="d-flex gap-2 justify-content-between">
                        <label className="doc-labels">
                          {translate(
                            "doctors.consultingfees",
                            "Consulting Fee"
                          )}
                        </label>

                        {editStatus && commonData?.op_fee_history?.length && (
                          <div className="popover-container">
                            <span
                              className="history-link"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={handleFeehistory}
                            >
                              <img src={historyIcon} alt="" />
                              {translate("doctors.feeHistory", "Fee History")}
                            </span>
                            <div
                              ref={ref}
                              className={`custom-popover ${
                                showHistory ? "show" : ""
                              }`}
                            >
                              <div className="popover-header">
                                <h6>
                                  {translate(
                                    "doctors.feeHistory",
                                    "Fee History"
                                  )}
                                </h6>
                                <button
                                  className="btn-close"
                                  onClick={handleHistoryClose}
                                ></button>
                              </div>
                              <div className="popover-body">
                                {commonData.op_fee_history.map((val) => {
                                  return (
                                    <>
                                      <div className="single-history">
                                        <span>
                                          {formatDateto_D_M_Y(
                                            new Date(val?.created_at),
                                            language_Code
                                          )}
                                          ,
                                          <small>
                                            {appendTime(
                                              new Date(val?.created_at),
                                              language_Code
                                            )}
                                          </small>
                                        </span>
                                        <span className="amount">
                                          ₹ {val.fee}
                                        </span>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="position-relative d-flex">
                        <input
                          type="text"
                          className="form-control h-100"
                          value={docFees}
                          onChange={feesChange}
                          maxLength={7}
                        />
                        <div
                          className="px-3 d-flex align-items-center position-absolute h-100 custom-field-btn"
                          style={{
                            backgroundColor: "#c8c8c8",
                            border: "1px solid #c8c8c8",
                            right: 0,
                          }}
                        >
                          <p className="fs-11" style={{ color: " #575757" }}>
                            {translate("doctors.rupees", "INR")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-2"></div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <label className="doc-labels">
                        {translate("doctors.experience", "Experience")}
                      </label>
                      <input
                        type="text"
                        maxLength="10"
                        className="form-control"
                        onChange={ExpChange}
                        value={commonData.experience}
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <label className="doc-labels">
                        {translate("doctors.designation", "Designation")}
                      </label>
                      <input
                        type="text"
                        maxLength={50}
                        className="form-control"
                        onChange={(e) =>
                          setCommonData({
                            ...commonData,
                            docDesignation: e.target.value,
                          })
                        }
                        value={commonData.docDesignation}
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group add-doc-multi-select">
                      <label
                        className="doc-labels"
                        style={{ scrollMargin: "100px" }}
                        ref={deptError}
                      >
                        {translate("doctors.department", "Department")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <MultipleSelect
                        reference={(el) =>
                          (focusonEditref.current[`deptRef`] = el)
                        }
                        className="add-doc-multi-select"
                        value={docDepartmentList
                          ?.sort((a, b) => {
                            if (selectedDeptValue.length) {
                              const indexA = selectedDeptValue?.findIndex(
                                (item) => item.value === a.dept_id
                              );
                              const indexB = selectedDeptValue?.findIndex(
                                (item) => item.value === b.dept_id
                              );
                              if (indexA !== -1 && indexB !== -1)
                                return indexA - indexB;
                              if (indexA !== -1) return -1;
                              if (indexB !== -1) return 1;
                            }
                            return 0;
                          })
                          ?.map((val) => ({
                            value: val.dept_id,
                            label: val.dept_name,
                          }))}
                        selectedDeptValue={selectedDeptValue}
                        setSelectDeptValue={(values, options, actionMeta) =>
                          handleDeptMultiSelect(values, options, actionMeta)
                        }
                        custom={errorState.deptError ? true : null}
                        isDisabled={
                          !ACCESS(READ) || userType === USER_TYPE.doctor
                        }
                        isClearable={
                          !(!ACCESS(READ) || userType === USER_TYPE.doctor)
                        }
                        noOptionsMessage={() => (
                          <div>
                            {!allDept?.length ? (
                              <p className="no-options-msg">
                                No departments added{" "}
                                <a
                                  className="no-options-link"
                                  onClick={() => updateShowPopup(true)}
                                >
                                  +Add New
                                </a>
                              </p>
                            ) : allDept?.length &&
                              !docDepartmentList?.length ? (
                              <p
                                className="no-options-msg"
                                style={{ display: "inline" }}
                              >
                                No active departments found, activate the
                                department or{" "}
                                <a
                                  className="no-options-link"
                                  onClick={() => updateShowPopup(true)}
                                >
                                  +Add New
                                </a>
                              </p>
                            ) : (
                              translate("common.noData", "No data found")
                            )}
                          </div>
                        )}
                      />
                      {errorState.deptError ? (
                        <p
                          className="mt-1 fs-11 fw-500"
                          style={{ color: "red", height: 0 }}
                        >
                          {translate(
                            "common.required",
                            "This field is required"
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-xl-5 col-md-7">
                    <div
                      className={
                        errorState.emailError || errorState.invalidMail
                          ? "form-group error"
                          : "form-group"
                      }
                    >
                      <label className="doc-labels">
                        {translate("doctors.emailaddress", "Email")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        disabled={editStatus}
                        type="text"
                        maxLength={50}
                        className="form-control"
                        onChange={emailChange}
                        value={commonData.docEmail}
                        ref={emailError}
                        style={{ scrollMargin: "100px" }}
                      />
                      {errorState.emailError ? (
                        <p
                          className="mt-1 fs-11 fw-500"
                          style={{ color: "red", height: 0 }}
                        >
                          {translate(
                            "common.required",
                            "This field is required"
                          )}
                        </p>
                      ) : null}
                      {errorState.invalidMail ? (
                        <p
                          className="mt-1 fs-11 fw-500"
                          style={{ color: "red", height: 0 }}
                        >
                          {translate("users.invalidMail", "Invalid Email id")}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <label
                        className="doc-labels"
                        style={{ justifyContent: "space-between" }}
                      >
                        <span>
                          {translate("common.phoneNumber", "Mobile")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                        {!showLandline && (
                          <span
                            className="add-new-btn"
                            onClick={() => {
                              setShowLandline(true);
                            }}
                          >
                            {" "}
                            +{translate("doctors.addLandline", "Add Landline")}
                          </span>
                        )}
                      </label>
                      <div className="d-flex gap-3 no-formgroup">
                        <div
                          style={{ width: 130 }}
                          onKeyDown={
                            !editStatus
                              ? (e) => {
                                  if (e.key === "Tab" && !e.shiftKey) {
                                    e.preventDefault();
                                    phoneError.current.focus();
                                  }
                                  handleKeyDown(e);
                                }
                              : () => {}
                          }
                        >
                          <PhoneNumberInput
                            editStatus={editStatus}
                            value={commonData.countrySelect}
                            onChange={handleMobileNumberChange}
                            validationErr={false}
                            numberLength={numberLength}
                            setNumberLength={setNumberLength}
                            focusRef={focusRef}
                            code={code}
                            setCode={setCode}
                          />
                        </div>

                        <div
                          className={
                            errorState.phoneError || errorState.invalidPhone
                              ? "form-group error w-100"
                              : "form-group w-100"
                          }
                        >
                          <input
                            type="text"
                            maxLength={numberLength}
                            disabled={editStatus}
                            className="form-control"
                            onChange={numberChange}
                            value={commonData.docPhone}
                            ref={phoneError}
                            style={{ scrollMargin: "100px" }}
                            onKeyDown={(e) => {
                              if (e.shiftKey && e.key === "Tab") {
                                e.preventDefault();
                                focusRef.current.focus();
                              }
                            }}
                          />
                          {errorState.phoneError ? (
                            <p
                              className="mt-1 fs-11 fw-500"
                              style={{ color: "red", height: 0 }}
                            >
                              {translate(
                                "common.required",
                                "This field is required"
                              )}
                            </p>
                          ) : null}
                          {errorState.invalidPhone ? (
                            <p
                              className="mt-1 fs-11 fw-500"
                              style={{ color: "red", height: 0 }}
                            >
                              {translate(
                                "users.invalidPhone",
                                "Invalid phone number"
                              )}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-xl-5"></div> */}

                  {showLandline ? (
                    <div className="col-xl-5 col-md-7">
                      <div className="form-group">
                        <label
                          className="doc-labels"
                          style={{ justifyContent: "space-between" }}
                        >
                          <span>
                            {translate("common.landline", "Landline Number")}
                          </span>
                          <span
                            className="add-new-btn close-btn"
                            onClick={() => {
                              setShowLandline(false);
                              setCommonData({ ...commonData, docLandLine: "" });
                            }}
                          >
                            {" "}
                            <CloseIcon />{" "}
                            {translate("doctors.remove", "Remove")}
                          </span>
                        </label>
                        <div className="d-flex gap-3 no-formgroup">
                          <div className="form-group w-100">
                            {/* <PhoneInput
                                defaultCountry="IN"
                                placeholder="+91 (484) 12-34-567"
                                initialValueFormat= 'national'
                                onChange={landLinenumberChange}
                                value={commonData?.docLandLine}
                                 /> */}

                            <input
                              type="text"
                              maxLength="20"
                              placeholder="+91 (484) 12-34-567"
                              className="form-control"
                              onChange={landLinenumberChange}
                              value={commonData?.docLandLine}
                              style={{ scrollMargin: "100px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-xl-5"></div>
                  )}

                  <div className="col-lg-3 col-4 xsm-100">
                    <div className="form-group design-appointment">
                      <label className="doc-labels">
                        {translate(
                          "doctors.appointmenttype",
                          "Appointment Type"
                        )}
                      </label>
                      <Checkbox
                        label={translate("doctors.op", "OP")}
                        handleChange={(e) => handleTypeChange(e)}
                        checked={appointmentType}
                        // disabled
                      />
                    </div>
                  </div>

                  <div className="col-xl-8 col-8 xsm-100">
                    <div className="form-group">
                      <label className="doc-labels mb-0">
                        {translate("common.status", "Status")}
                      </label>
                      <div className="switchWrap">
                        <RadioGroup
                          row
                          value={docActive ? "active" : "inactive"}
                          onChange={(e) =>
                            handleSwtchChange(e.target.value === "active")
                          }
                        >
                          <FormControlLabel
                            value="active"
                            control={<Radio />}
                            label={translate("common.active", "Active")}
                            disabled={
                              !ACCESS(READ) || userType === USER_TYPE.doctor
                            }
                          />
                          <FormControlLabel
                            value="inactive"
                            control={<Radio />}
                            label={translate("common.inactive", "Inactive")}
                            disabled={
                              !ACCESS(READ) || userType === USER_TYPE.doctor
                            }
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-md-10">
                    <div className="form-group">
                      <label className="doc-labels">
                        {translate("doctors.about", "About")}
                      </label>
                      <div className="text-area-wrap">
                        <textarea
                          rows="4"
                          maxLength="500"
                          className="form-control input"
                          placeholder={`${intl.formatMessage({
                            id: "doctors.entPersonalInformation",
                            defaultMessage: "Enter personal information",
                          })}`}
                          onChange={(e) =>
                            setCommonData({
                              ...commonData,
                              about: e.target.value,
                            })
                          }
                          value={commonData.about}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="form-group mb-0">
                      <label className="doc-labels">
                        {translate(
                          "doctors.qualificationdetails",
                          "Qualification Details"
                        )}
                      </label>
                      <div className="text-area-wrap">
                        <textarea
                          rows="4"
                          maxLength="500"
                          className="form-control input"
                          placeholder={`${intl.formatMessage({
                            id: "doctors.entAcademicInformation",
                            defaultMessage: "Enter academic information",
                          })}`}
                          onChange={(e) =>
                            setCommonData({
                              ...commonData,
                              qualificationDetails: e.target.value,
                            })
                          }
                          value={commonData.qualificationDetails}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="doc-addhr mt-4 mb-0"></hr>
              </div>
              <div className="mt-4 add-doc-footer-button-container">
                <div onClick={() => setEditStatus(false)}>
                  <Link
                    to={
                      ACCESS(READ) && userType !== USER_TYPE.doctor
                        ? "/admin/doctorslisting"
                        : ACCESS(READ_DETAILS)
                        ? `/admin/doctorslisting/doctordetails/${doctor_id}/${user_id}`
                        : "/"
                    }
                  >
                    <button
                      type="button"
                      className="btn btn-light px-4"
                      id="cancel"
                    >
                      {translate("common.cancel", "CANCEL")}
                    </button>
                  </Link>
                </div>
                <div
                  onClick={
                    btnLoading
                      ? () => {}
                      : () => {
                          ((ACCESS(UPDATE) && editStatus) ||
                            (ACCESS(CREATE) && !editStatus)) &&
                            handleSubmit();
                        }
                  }
                >
                  <button
                    type="button"
                    className="btn btn-primary px-4 loader-btn"
                    id="add"
                    disabled={
                      (!ACCESS(UPDATE) && editStatus) ||
                      (!ACCESS(CREATE) && !editStatus) ||
                      btnLoading
                    }
                  >
                    {btnLoading ? (
                      <ButtonLoader
                        text={
                          !editStatus
                            ? translate("common.save", "SAVE")
                            : translate("common.update", "UPDATE")
                        }
                      />
                    ) : !editStatus ? (
                      translate("common.save", "SAVE")
                    ) : (
                      translate("common.update", "UPDATE")
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {Prompt}

      {showConformPopup && (
        <ConformPopup
          onClose={() => updateShowConformPopup(false)}
          onConfirm={() => {
            setSelectDeptValue(selectedValue);
            updateShowConformPopup(false);
          }}
          message={`Dr. ${commonData.docName} ${
            commonData.doclName
          } ${intl.formatMessage({
            id: "departments.deptHeadRemove",
            defaultMessage:
              "is the head of this department, Are you sure you want to proceed?",
          })}`}
        />
      )}

      {showApptConflictPopup && (
        <AppointmentConflictPopup
          onClose={() => {
            setShowApptConflictPopup(false);
          }}
          onConfirm={onApptConflictConfirm}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  language_Code: state.SettingsReducer.locale,
  doctorDetails: state.LoginReducer.doctorDetails,
  userDetails: state.LoginReducer.loginDetails,
});

const mapDispatchToProps = (dispatch) => ({
  displayToaster: (message, type) => dispatch(showToaster(message, type)),
  getDepartmentSuggestionList: () => dispatch(FetchDepartmentSuggestionList()),
});

export default compose(
  withPermissionCheck(DOCTOR),
  connect(mapStateToProps, mapDispatchToProps)
)(AddDoctor);
