import React, { useEffect, useRef, useState } from 'react'
import otpLogo from '../../../Assets/img/icons/enter-otp-main-image.svg';
import { translate } from 'Language/Translate'
import correct from '../../../Assets/img/icons/correct.svg';
import wrong from '../../../Assets/img/icons/wrong.svg';
import { connect } from 'react-redux';
import { validateToken } from 'Actions/patientProfileAction';
import { useIntl } from 'react-intl';
import { timeZoneDate } from 'Utils/DateFormatter/Date';
import ButtonLoader from 'Components/Common/ButtonLoader';

function NewPatientOtp({ linkProfileDetails, verifyOtp, otpData, setShowOtp, getLinkedProfileList, onSubmitSuccess, getTokenForResendOtp, setModalShow, onEditClick, otpDetails,showOtpLoader,setIsLoading=()=>{}, resendLoader
}) {

    const intl = useIntl()
    // Resend Counter
    const [resend, setResend] = useState(0)
    const { otp_resend_limit, otp_limit_duration } = otpDetails

    const [otp, setOtp] = useState('');
    const [otpVerified, setOtpVerified] = useState('');
    const inputRefs = useRef([]);
    const [countdown, setCountdown] = useState(0)
    const [showCounter, setShowCounter] = useState(false)
    // resend state
    const [resendCounter, setResendCounter] = useState(0)
    const [showResend, setShowResend] = useState(false)

    useEffect(() => {
        let targetTime = timeZoneDate().getTime() + (otpData?.exp * 60 * 1000) + 1000
        let Interval = setInterval(() => remainingTime(targetTime, Interval), 1000);
        return () => { clearInterval(Interval) }
    }, [otpData])

    // for checking resend Disabled or Not
    useEffect(() => {
        const resendDetails = JSON.parse(localStorage.getItem("Resend"))
        if (resendDetails) {
            const { newPatientOtp } = resendDetails
            if (newPatientOtp) {
                let ResendInterval = setInterval(() => remainingResendTime(newPatientOtp, ResendInterval), 1000);
            }
        } else {
            localStorage.setItem("Resend", JSON.stringify({}))
        }
    }, [])

    let remainingTime = (targetTime, Interval) => {
        let currentTime = timeZoneDate().getTime()
        let leftSecond = Math.trunc((targetTime - currentTime) / 1000)
        setCountdown(leftSecond)
        if (leftSecond <= 0) {
            clearInterval(Interval)
            setShowCounter(false)
        }
        else if (!showCounter) {
            setShowCounter(true)
        }
        return leftSecond
    }


    // useEffect(() => {
    //   const timer = countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);
    //   return () => clearInterval(timer);
    // }, [countdown]);
    let remainingResendTime = (targetTime, Interval) => {
        let currentTime = timeZoneDate().getTime()
        let leftSecond = Math.trunc((targetTime - currentTime) / 1000)
        setResendCounter(leftSecond)
        if (leftSecond <= 0) {
            clearInterval(Interval)
            setShowResend(false)
            setResend(0)
            setShowCounter(false)
        }
        else if (!showResend) {
            setShowResend(true)
        }
        return leftSecond
    }

    const resendOtp = (e) => {
        if(!e.key || (e.key === 'Enter')){
            setOtpVerified('');
            setOtp(['', '', '', ''])
            inputRefs.current[0].focus()
            getTokenForResendOtp(linkProfileDetails)
            setResend(resend + 1)
            if ((resend + 1) >= otp_resend_limit) {
                let targetResendTime = timeZoneDate().getTime() + (otp_limit_duration * 60 * 1000) + 1000
                const resendlocal = JSON.parse(localStorage.getItem("Resend"))
                localStorage.setItem("Resend", JSON.stringify({ ...resendlocal, newpatient: targetResendTime }))
                let IntervalRemaining = setInterval(() => remainingResendTime(targetResendTime, IntervalRemaining), 1000);
            }
        }
    }
    const handlePaste = (e) => {
        e.preventDefault()
        e.stopPropagation()
        let val = e?.clipboardData?.getData('text').split("")
        if (val?.length) {
          val.push("", "", "", "")
          otp.reverse().map((item) => {
            if (item !== "") {
              val.unshift(item)
            }
          })
          inputRefs?.current?.[val.indexOf("") < 3 ? val.indexOf("") : 3].focus()
          setOtp(val.splice(0, 4))
        }
        setOtpVerified('')
      }
    // handle otp change
    const handleChange = (e, index) => {
        const value = e.target.value
          if (isNaN(value) || value.trim() == "" ||showOtpLoader) return;
          setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = value.slice(-1);
            return newOtp;
          })
          if (value !== '' && index < 3) {
            inputRefs.current[index + 1].focus();
          }
        setOtpVerified('')
      };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                inputRefs.current[index -1].focus();
            }
        }
       else if (e.key === 'Backspace' && !showOtpLoader) {
            setOtp((prevOtp) => {
                const newOtp = [...prevOtp];
                newOtp[index] = "";
                return newOtp;
            });
            if (index > 0) {
                inputRefs.current[index].focus();
            }
            setOtpVerified('')
        }
    };

    // handle otp verify
    const submitOtp = () => {
        if (otp.length && otp.join("").trim().length === 4) {
            setOtpVerified('')
            let payload = {
                data: {
                    "patient_query": linkProfileDetails.patientId,
                    // "patient_query": linkProfileDetails?.country+linkProfileDetails?.phno,
                    "otp_verification": {
                        "otp": otp.join(""),
                        "token": otpData?.token
                    }
                }
            }
            verifyOtp(payload, onVerifyOtpSuccess, onVerifyOtpError)
        }
        else {
            setOtpVerified(translate(
                'patientPortal.invalidOtp',
                'Please enter OTP'
            ));
        }
    };
    const onVerifyOtpSuccess = () => {
        setOtpVerified('success');
        onSubmitSuccess();
        getLinkedProfileList()
        setIsLoading(false)
    }
    const onVerifyOtpError = (message) => {
        setOtpVerified(message);
    }

    //new handle otp number edit 
    const handleEditClick = () => {
        setShowOtp(false);
        setOtpVerified('');
        //For redirecting to edit profile page
        setModalShow(false)
        onEditClick({ patient_id: linkProfileDetails.patient_id })
    };
    return (
      <div>
        <div className="otp-block enter-otp-modal mt-5">
          <img className="main-img" src={otpLogo} alt="" />
          <h6>
            {translate(
              "patientPortal.enterProfilePsw",
              "Please enter the One Time Password to verify your profile."
            )}
          </h6>
          <p>
            {translate("patientPortal.otpSent", "An OTP has been sent to")}{" "}
            <span className="fw-bold">
              +{linkProfileDetails?.country} {linkProfileDetails?.phno}{" "}
            </span>
            <a className="previous" onClick={handleEditClick}>
              {translate("patientPortal.edit", "Edit")}
            </a>
          </p>
          <div
            className={`otp-theme ${otpVerified === "success" && "success"} ${
              otpVerified && otpVerified !== "success" && "error"
            } pb-1 mb-5`}
          >
            <div className="otp-field" onPaste={(e) => handlePaste(e)}>
              {Array.from({ length: otpData.len }, (v, i) => i).map(
                (_, index) => (
                  <input
                    key={index}
                    type="text"
                    className="form-control otpinput"
                    // maxLength="1"
                    value={otp[index]}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(ref) => (inputRefs.current[index] = ref)}
                    readOnly={otpVerified === "success"}
                    onFocus={(e) => e.target.select}
                    autoFocus={index === 0}
                  />
                )
              )}
              {otpVerified === "success" ? (
                <img
                  className="status-icon correct icon-1"
                  src={correct}
                  alt=""
                />
              ) : null}
            </div>

            {resendCounter < 1 &&
              showCounter &&
              !(otpVerified === "success") && (
                <>
                  {translate("patientPortal.otpExpire", "OTP will expire in")}{" "}
                  {showCounter && countdown}{" "}
                  {translate("appointments.sec", "sec")}
                </>
              )}
            {resendCounter < 1 &&
              countdown === 0 &&
              resend < otp_resend_limit && (
                <>
                  {resendLoader ? (
                    <a id="resend" tabIndex="0" className="resend-otp loading">
                      {translate("patientPortal.resend", "Resend OTP")}
                    </a>
                  ) : (
                    <a
                      id="resend"
                      tabIndex="0"
                      onKeyDown={resendOtp}
                      className="resend-otp"
                      onClick={resendOtp}
                    >
                      {translate("patientPortal.resend", "Resend OTP")}
                    </a>
                  )}
                </>
              )}
            {resendCounter > 0 && (
              <span className="exceeded-msg">
                {intl.formatMessage({
                  id: "commom.otpLimitMsg",
                  defaultMessage:
                    "OTP send limit exceeded please try again after 5 min",
                })}
              </span>
            )}
            <div className="d-flex align-items-center justify-content-center gap-2">
              <img className="status-icon wrong icon-2" src={wrong} alt="" />
              {otpVerified === "success" && (
                <span className="success-msg">
                  {translate(
                    "patientPortal.otpVerified",
                    "OTP verified successfully!"
                  )}
                </span>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center gap-2">
              <img
                className="status-icon correct icon-2"
                src={correct}
                alt=""
              />
              {otpVerified && otpVerified !== "success" && (
                <span className="error-msg">{otpVerified}</span>
              )}
            </div>
          </div>

          <div className="footer-btn">
            <button
              id="save"
              className="btn btn-primary px-4 loader-btn"
              onClick={showOtpLoader ? () => {} : submitOtp}
            >
              {showOtpLoader ? (
                <ButtonLoader
                  text={translate("common.saveVerify", "Save & Verify")}
                />
              ) : (
                translate("common.saveVerify", "Save & Verify")
              )}
            </button>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = (state) => ({
    otpDetails: state.SettingsReducer.otpDetails,
    showOtpLoader: state.ProfileReducer.showOtpLoader,
});
const mapDispatchToProps = (dispatch) => ({
    verifyOtp: (data, callback, errCallBack) => dispatch(validateToken(data, callback, errCallBack))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewPatientOtp);