import React from 'react';
import Avatar from '@mui/material/Avatar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { translate } from '../../../Language/Translate';
import {
  appendTime,
  slashFormatinDDMMYYYY,
} from '../../../Utils/DateFormatter/Date';
import { CardMedia } from '@mui/material';
import { APPOINTMENT_STATUS_NAMES } from 'Constants/constant';
import { connect } from 'react-redux';

const Patientdata = (props) => {
  const { patientDetails, appointmentDetails, applicationData } = props;

  const {
    name = '',
    last_name = '',
    tele_country_code = '',
    phone = '',
    mrn,
    profile_img,
    patient_id
    // height,
    // weight,
    // heartBeat,
    // bp,
    // glucose_rate,
  } = patientDetails;

  const { appointment_id, appt_date, appt_start_time, dept_name, live_status, reason } =
    appointmentDetails;

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ width: '100%' }}>
            <div className="row">
              <div className="col-md-4 d-flex">
                {profile_img ? (
                  <CardMedia
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                    component="img"
                    image={profile_img}
                  />
                ) : (
                  <Avatar
                    sx={{ width: 40, height: 40 }}
                    src="/broken-image.jpg"
                  />
                )}
                <div className="ms-3">
                  <div className="d-flex align-items-center gap-3">
                  <p
                    className="card-patient-label"
                    style={{ marginTop: tele_country_code && phone ? '' : 10 }}
                  >{`${name} ${last_name}`}</p>
                  <span className='status badge'>
                    
                  </span>
                  </div>
                  {tele_country_code && phone ? (
                    <p className="mt-1 card-patient-value">
                      {`+${tele_country_code}${phone}`}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="col-md-4 ps-md-4">
                <div className="ml-2 ps-1 accordian-patient-id">
                  {
                    applicationData.use_system_generated_mrn ? (
                      <>
                        {translate('patientPortal.patient_id', 'Patient ID')}
                      </>
                    ) :
                      <>
                      <p className='appt-details-label'>
                      {translate('patientPortal.mrnHealthId', 'mrn / Health Id')}
                      </p>
                      </>
                  }
                  <span className='d-md-none'>:</span>
                  <p className="mt-md-1" style={{ fontSize: 12, fontWeight: 500 }}>
                    {applicationData.use_system_generated_mrn ? patient_id : mrn ? mrn : '-'}
                  </p>
                </div>
              </div>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className='hidden-contents'>
              <div className="row">
                <h6 className='accordian-heading'>Appointment Details</h6>
                <div className="col-md-4 mt-3">
                  <p className="appt-details-label">
                    {translate('patientView.apptId', 'Appointment ID')}
                  </p>
                  <p className="mt-1 appt-details-value">
                    {appointment_id}
                  </p>
                </div>
                <div className="col-md-4 mt-3">
                  <p className="appt-details-label">
                    {translate('patientView.apptDate', 'Appointment Date')}
                  </p>
                  <p className="mt-1 appt-details-value">
                    {slashFormatinDDMMYYYY(appt_date)}
                  </p>
                </div>
                <div className="col-md-4 mt-3">
                  <p className="appt-details-label">
                    {translate('patientView.timeSlot', 'Time Slot')}
                  </p>
                  <p className="mt-1 appt-details-value">
                    {appendTime(appt_start_time)}
                  </p>
                </div>
                <div className="col-md-4 mt-3">
                  <p className="appt-details-label">
                    {translate('patientView.department', 'Department')}
                  </p>
                  <p className="mt-1 appt-details-value text-capitalize">{dept_name}</p>
                </div>
                <div className="col-md-4 mt-3">
                  <p className="appt-details-label">
                    {translate('common.status', 'Status')}
                  </p>
                  <p className="mt-1 appt-details-value">
                    {APPOINTMENT_STATUS_NAMES[live_status]}
                  </p>
                </div>
              </div>
              {/* <div className="d-flex mt-3">
                <p style={{ fontWeight: 600, fontSize: 14 }}>
                  {translate('patientView.vitals', 'Vitals')}
                </p>
                <div
                  className="ms-2"
                  style={{
                    width: '100%',
                    height: 1,
                    backgroundColor: '#d3d3d3',
                    marginTop: 7,
                  }}
                ></div>
              </div>
              <div className="row mt-2">
                <div className="col-md-2 mt-2">
                  <div className="d-flex justify-content-center align-items-center fs-11 vitals-label">
                    {translate('patientView.weight', 'Weight')}
                    <br />
                    {weight ? weight : '-'}
                  </div>
                </div>
                <div className="col-md-2 mt-2">
                  <div className="d-flex justify-content-center align-items-center fs-11 vitals-label">
                    {translate('patientView.height', 'Height')}
                    <br />
                    {height ? height : '-'}
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div className="d-flex justify-content-center align-items-center fs-11 vitals-label">
                    {translate('patientView.heartBeat', 'Heart Beat')}
                    <br />
                    {heartBeat ? heartBeat : '-'}
                  </div>
                </div>
                <div className="col-md-2 mt-2">
                  <div className="d-flex justify-content-center align-items-center fs-11 vitals-label">
                    {translate('patientView.bp', 'BP')}
                    <br />
                    {bp ? bp : '-'}
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div className="d-flex justify-content-center align-items-center fs-11 vitals-label">
                    {translate('patientView.glucose', 'Glucose Rate')}
                    <br />
                    {glucose_rate ? glucose_rate : '-'}
                  </div>
                </div>
              </div> */}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div className="mt-4 d-flex">
        <p className="text-nowrap first-capital" style={{ fontWeight: 500, fontSize: 14 }}>
          {translate('patientView.reason', 'Reason For Appointment')}
        </p>
      </div>
      <div className="mt-2">
        <p className="fs-13">{reason}</p>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  applicationData: state.SettingsReducer.applicationData,
});

export default connect(mapStateToProps)(Patientdata);
