import React, { useEffect, useState } from 'react';
import './Style/settings.css';
import closeIcon from '../../Assets/img/icons/closes.svg';
import otpImg from '../../Assets/img/icons/enter-otp-main-image.svg';
import { translate } from '../../Language/Translate';
import OtpForm from '../Common/OtpForm';
import { useIntl } from 'react-intl';
import FocusTrap from 'focus-trap-react';
import ButtonLoader from '../../Components/Common/ButtonLoader';

const VerifyData = (props) => {
  const {
    onConfirm,
    onClose,
    otpData,
    countdown,
    setOtpData,
    handleResend,
    validateType,
    country,
    phone,
    email,
    resendCount,
    otp_resend_limit,
    counter,
    isVerifyLoading,
    resendLoader
  } = props;

  const [otpError, setOtpError] = useState(false);
  const intl = useIntl()
  // const [timer, setTimer] = useState(0)
  useEffect(() => {
    document.body.classList.add('modal-overflow');
    return () => document.body.classList.remove('modal-overflow');
  }, []);

  // handle OTP change
  const handleOTPChange = (value, index) => {
    setOtpData(({ otp, ...prev }) => {
      const newOtp = [...otp];
      newOtp[index] = value;
      if (newOtp?.join('')?.length === otpData?.len) {
        setOtpError(false);
      }
      return {
        ...prev,
        otp: newOtp,
      };
    });
  };

  // handle verify click
  const handleVerify = () => {
    if (
      Array.isArray(otpData?.otp) &&
      otpData?.otp.join("")?.length !== otpData?.len
    ) {
      setOtpError(true);
    } else {
      onConfirm();
    }
  };

  return (
    <>
      <FocusTrap
        focusTrapOptions={{
          clickOutsideDeactivates: true,
        }}
      >
        <div className="AppointmentModal d-flex">
          <div className="modalContent delete_popup modal_xs p-20">
            <div className="container">
              <div className="row">
                <div className="col-md-12" onClick={onClose}>
                  <img
                    className="postition-close-icon"
                    src={closeIcon}
                    alt="close"
                    style={{
                      float: "right",
                      width: 15,
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="modalContentarea-popup text-center">
              <div className="container">
                <div
                  className="col-12"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img src={otpImg} alt="img" />
                </div>
                <div className="row mt-2 justify-content-center">
                  <div
                    className="col-12"
                    style={{ marginTop: 10, paddingLeft: 25, paddingRight: 25 }}
                  >
                    <div className="col-12 mb-2">
                      <label
                        className="small-txt"
                        style={{ fontSize: 15, fontWeight: 600 }}
                      >
                        {validateType === "phone"
                          ? translate(
                              "settings.enterPhoneOtp",
                              "Please enter the One Time Password to verify your phone {phone}",
                              { phone: `+${country}${phone}` }
                            )
                          : translate(
                              "settings.enterEmailOtp",
                              "Please enter the One Time Password to verify your Email {email}",
                              { email }
                            )}
                      </label>
                    </div>
                    <OtpForm
                      otpData={otpData}
                      otp={otpData.otp || ""}
                      setOtp={handleOTPChange}
                      validatinErr={otpError}
                    />
                    {counter < 1 && countdown && countdown > 0 ? (
                      <div
                        style={{
                          textAlign: "center",
                          color: "green",
                          fontSize: 14,
                        }}
                      >
                        {translate(
                          "patientPortal.otpExpire",
                          "OTP will expire in"
                        )}{" "}
                        {countdown} {translate("appointments.sec", "sec")}
                      </div>
                    ) : null}
                    <div className="col-md-12 mt-3 mb-2">
                      {counter < 1 &&
                        resendCount < otp_resend_limit &&
                        countdown <= 0 && (
                          <p className="mt-2 text-left fs-13">
                            {translate(
                              "appointments.didntReceive",
                              "Didn't receive OTP ? "
                            )} 
                            {resendLoader ? (
                              <span className="forgotSignin loading" style={{marginLeft: 10}}>
                                {" "}
                                {translate(
                                  "appointments.resend",
                                  "Resend OTP"
                                )}{" "}
                              </span>
                            ) : (
                              <span
                                className="forgotSignin"
                                onClick={handleResend}
                              >
                                {" "}
                                {translate(
                                  "appointments.resend",
                                  "Resend OTP"
                                )}{" "}
                              </span>
                            )}
                          </p>
                        )}
                      {counter > 0 && (
                        <div className="exceeded-msg">
                          {intl.formatMessage({
                            id: "commom.otpLimitMsg",
                            defaultMessage:
                              "OTP send limit exceeded please try again after 5 min",
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <button
                    type="button"
                    className="btn btn-primary loader-btn"
                    id="save"
                    onClick={handleVerify}
                  >
                    {isVerifyLoading ? (
                      <ButtonLoader
                        text={translate("settings.verify", "Verify")}
                      />
                    ) : (
                      translate("settings.verify", "Verify")
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FocusTrap>
    </>
  );
};
export default VerifyData;
