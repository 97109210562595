import './Prescriptions.css';
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import network from 'Utils/Api/Network';
import {
  FetchPrescriptionsList,
  clearPrescriptiosList,
  addFilterPrescriptions,
} from 'Actions/prescriptions';
import img from 'Assets/img/patient-portal/User_Placeholder.png';
import errorImg from '../../../Assets/img/patient-portal/error_img_placeHolder.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { translate } from 'Language/Translate';
import { CardMedia, Tooltip } from '@mui/material';
import AntMultiDatePicker from '../Common/AntMultiDatePicker';
import {
  formatDate,
  dateinFormatDDMonthYYYY,
  timeIn12HourFormat,
} from 'Utils/DateFormatter/Date';
import '../Common/style/modal.css';
import '../Layout/style/forms.css';
import '../Common/style/filter.css';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';
// import useDidMountEffect from 'Hooks/useDidMountEffect';
import NoResultFound from '../Common/NoResultFound';
import { removeDrPrefix, truncateString } from 'Utils/StringFormatter/String';
import { getAllLinkedList } from 'Actions/medicalRecordAction';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import { saveAs } from 'file-saver';
import withPermissionCheck from 'Utils/WithUtils/WithPermission';
import { PATIENTRECORDS } from 'Constants/feature';
import { compose } from 'redux';
import { READ_RECORDS } from 'Constants/scopes';
import Loader from 'Components/Common/Loader';
import loadingImg from 'Assets/img/icons/search-loader.gif';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

let apiTimeout = null;
const Prescriptions = ({
  prescriptionsList,
  getPrescriptions,
  clearData,
  addFilter,
  filterOptions,
  getAllLinkedProfile,
  loginDetails = {},
  patientDetails = {},
  ACCESS,
  linkedProfileLoading,
  applicationData
}) => {
  const history = useHistory();
  const sortDrop1 = useRef()
  const sortDrop2 = useRef()
  // const imgStatus = useRef(true)
  const [imgStatus, setImgStatus] = useState({});
  const [showReferenceModal, updateReferenceModal] = useState(false);
  const [filtermodal, updateFilterModal] = useState(false);
  // const [showPopup, updateShowPopup] = useState(false)
  // to list suggestion while searching department or doctor name
  const [suggestionList, setSuggestionList] = useState([]);
  const [profileNames, setProfileNames] = useState([]);
  // const [patientsuggestionList, setPatientsuggestionList] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  // const [isProfileSelect, setIsProfileSelect] = useState(false);
  const [filterState, setFilterState] = useState({});
  // to set number of list (data)
  const [perLimit, setPerLimit] = useState(6);
  const [isLoading, setIsLoading] = useState(false);
  const [docLoader,setDocLoader] = useState({})

  // to set sort order
  const [descending,setDescending]=useState(false)

  const {
    searchName = '',
    deptOrDoc = '',
    dateRange = [null, null],
    sortBy = 'created_at',
  } = filterOptions;
  const {
    searchNameLoc = searchName,
    deptOrDocLoc = deptOrDoc,
    dateRangeLoc = dateRange,
    sortByLoc = sortBy,
  } = filterState;
  const ref = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = (event) => {
    if (ref?.current && !ref?.current?.contains(event.target)) {
      updateReferenceModal(false); // Close on outside click
    }
  };

  useEffect(() => {
    if (!isSelect) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadOptions(deptOrDocLoc);
      }, 300);
      apiTimeout = timeout;
    }
  }, [deptOrDocLoc, isSelect]);

  const userData = { ...loginDetails, ...patientDetails };
  let userName = userData?.last_name
    ? `${userData?.name} ${userData?.last_name}`
    : userData?.name
    ? userData?.name
    : `+${userData.tele_country_code}${userData.phone}`;
  let userOption = {
    label: userName,
    user_id: userData?.user_id,
    patient_id: userData?.patient_id,
    value: userData?.patient_id,
  };

  useEffect(() => {
    setIsLoading(true);
    getAllLinkedProfile(mapProfileData, () => {
      setIsLoading(false);
    });
    if (!filterOptions?.searchName?.value) {
      setFilterState((state) => ({
        ...state,
        // searchNameLoc: userOption,
        searchNameLoc: { label: translate('common.all', 'All'), value: '' }
      }));
    }
  }, [loginDetails]);

  const mapProfileData = ({ result }) => {
    // uncomment if default value should be selected here

    let arr = [{ label: translate('common.all', 'All'), value: '' }, userOption];
    if (result?.linked_profile_names?.length) {
      let list = result?.linked_profile_names;
      list.map((val) => {
        let userName = val?.name
          ? val?.name
          : `+${val?.tele_country_code}${val?.phone}`;
        arr.push({
          label: userName,
          user_id: val?.user_id,
          patient_id: val?.patient_id,
          value: val?.patient_id,
        });
      });
    }
    setProfileNames(arr);
    setIsLoading(false);
    // updateFilterByName(arr[0])
  };
  useEffect(() => {
    fetchPrescriptions();
    setPerLimit(6);
    return () => clearData();
  }, [filterOptions]);

  // sort by modal handling
  const referenceClick = () => {
    updateReferenceModal(!showReferenceModal);
  };
  const referenceCloseClick = (e) => {
    setDescending(!descending)
    setFilterState((state) => ({
      ...state,
      sortByLoc: e,
    }));
      addFilter({
        searchName: searchNameLoc,
        deptOrDoc: deptOrDocLoc,
        dateRange: dateRangeLoc,
        sortBy: e,
      });
    
    updateReferenceModal(!showReferenceModal);
  };

  const refCloseClick = (e) => {
    setDescending(!descending)
    setFilterState((state)=>({
      ...state,
      sortByLoc:e
    }))
      addFilter({
        searchName: searchNameLoc,
        deptOrDoc: deptOrDocLoc,
        dateRange: dateRangeLoc,
        sortBy: e,
      });
    updateReferenceModal(!showReferenceModal);
  };
  // on Search Handling
  const onChangeSearchHandle = (e) => {
    setIsSelect(false);
    const { name, value } = e.target;
    setFilterState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // on Handling while click Search Button
  const onHandleSearch = () => {
    setIsLoading(true)
    if (!isEqual(filterOptions, filterState)) {
      addFilter({
        searchName: searchNameLoc,
        deptOrDoc: deptOrDocLoc,
        dateRange: dateRangeLoc,
        sortBy: sortByLoc,
      });
    }
    updateFilterModal(false);
  };
  // clear All Input Field While Click Reset
  const onResetHandle = () => {
    setFilterState({ searchNameLoc: { label: translate('common.all', 'All'), value: '' } });
    if (!isEqual(filterOptions, filterState)) {
      addFilter({ searchNameLoc: { label: translate('common.all', 'All'), value: '' } });
    }
    updateFilterModal(false);
    setDocLoader({})
    setSuggestionList([])
    setDescending(false)
  };
  const onHandleDate = (e) => {
    if (e) {
      setFilterState((state) => ({
        ...state,
        dateRangeLoc: [formatDate(e[0]), formatDate(e[1])],
      }));
    } else {
      setFilterState((state) => ({
        ...state,
        dateRangeLoc: [null, null],
      }));
    }
  };

  // const handleDownload = () => {
  //     const element = document.getElementById('divToPrint');
  //     const opt = {
  //         margin: 0.5,
  //         filename: 'myfile.pdf',
  //         image: { type: 'jpeg', quality: 0.98 },
  //         html2canvas: { scale: 2 },
  //         jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  //     };
  //     // html2pdf().set(opt).from(element).save();
  // };

  // to show suggestion list while typing search department
  const loadOptions = async (inputValue) => {
    let fetchedOptions = [];
    if (inputValue?.trim()) {
      let id = new Date().getTime();
      setDocLoader((prev)=>({
        ...prev,
        [id]:true
      }));
      const config = {
        doc_with_dept: true,
        status: 'active',
        limit: 0
      }
      await network
        .get({ url: `/search/department-doctor/${inputValue}`, data: config })
        .then((response) => {
          setDocLoader((prev)=>({
            ...prev,
            [id]:false
          }));
          const { isError, result } = response;
          if (isError) {
            return;
          } else {
            const options = result?.search_result.map((item) => ({
              label: item.name,
              value: item.id,
              type: item.type,
            }));
            fetchedOptions = options;
          }
        })
        .catch(() => {});
    }
    setSuggestionList(fetchedOptions);
  };

  const selectDocOrDept = (data) => {
    setFilterState((state) => ({
      ...state,
      deptOrDocLoc: data,
    }));
    setSuggestionList([]);
    setDocLoader({})
    setIsSelect(true);
  };

  const activeDeptList = (data) => {
    return data?.filter((item) => item.dept_status === 'active');
  };

  // to fetch api for list appointment
  const fetchPrescriptions = ({ pageNo, perPageCount } = {}) => {
    setIsLoading(true)
    const config = {
      patient_id: searchName?.value === '' ? undefined : searchName?.value,
      dept_doc_search: deptOrDoc === '' ? undefined : removeDrPrefix(deptOrDoc),
      sort: descending? sortBy:'-' + sortBy,
      start_date: !dateRange[0] ? undefined : dateRange[0],
      end_date: !dateRange[1] ? undefined : dateRange[1],
      page: pageNo || 1,
      limit: perPageCount || 6,
    };
    getPrescriptions(config, setIsLoading);
    setImgStatus({});
  };

  // handle prescription download
  const handleDownload = (pdfData) => {
    saveAs(
      pdfData.file_link,
      `${pdfData.file_display_name}.${pdfData.record_file_type}`,
      {}
    );
  };

  const viewPrescription = (id) => {
    history.push(`/webConnect/prescriptions/${id?.prescription?.record_id}`);
  };

  //  to increment page number
  const lookingMoreHandle = () => {
    setPerLimit((state) => state + 6);
    fetchPrescriptions({
      perPageCount: perLimit + 6,
    });
  };

  return (
    <>
      <div className="prescriptions">
        <div className="bread-crumbs mb-lg-2">
          <div className="wrapper links">
            <a className="previous" href="#/webConnect">
              {translate('patientPortal.home', 'Home')}
            </a>
            <span> {' > '} </span>
            <a className="bread-crumb-cursor">{translate('patientPortal.prescriptions', 'Prescriptions')}</a>
          </div>
        </div>

        <div className="main-header">
          <div className="wrapper">
            <h1 className="main-title">
              {' '}
              {translate('patientPortal.prescriptions', 'Prescriptions')}
            </h1>
            <button
              className="open-filter-btn"
              onClick={() => {
                updateFilterModal(true);
              }}
            >
              <i className="bi bi-funnel"></i>
            </button>
          </div>
        </div>
        <div className="wrapper">
          <div
            id="common-filter"
            className={`common-filter-v3 ${filtermodal ? 'opened' : ''}`}
          >
            <button
              className="btn-close"
              onClick={() => updateFilterModal(false)}
            >
              <i className="bi bi-x"></i>
            </button>
            <div className="filter-outer-wrap">
              <div className="filter-elements">
                <div className="field-container form-group">
                  <label htmlFor="name">
                    {' '}
                    {translate(
                      'myAppointments.filterByName',
                      'Filter by patient Name'
                    )}
                  </label>

                  <div className="icon-field name">
                    <ReactSelectDropDown
                      id="filterbyname"
                      options={profileNames}
                      onInputChange={(item) => {
                        setFilterState((state) => ({
                          ...state,
                          searchNameLoc: item,
                        }));
                      }}
                      value={searchNameLoc}
                      isLoading={linkedProfileLoading}
                    />
                  </div>
                </div>
                <div className="field-container form-group">
                  <label htmlFor="name">
                    {translate('patientPortal.docSpec', 'Doctor / Specialtiy')}
                  </label>
                  <div className="icon-field name loading v2">
                    <i className="bi bi-search icon"></i>
                    <input
                      onChange={(e) => onChangeSearchHandle(e)}
                      value={deptOrDocLoc}
                      name="deptOrDocLoc"
                      className="input w-100"
                      type="search"
                      id="name"
                      placeholder="i.e, Dr. John Doe/Cardiology"
                      autoComplete="off"
                      onBlur={() =>
                        setTimeout(() => setSuggestionList([]), 400)
                      }
                      onFocus={() => {
                        if (deptOrDocLoc) loadOptions(deptOrDocLoc);
                      }}
                    />
                    {deptOrDocLoc !== ''&& !isSelect && Object.values(docLoader).includes(true) && (<img
                      className="loading-img"
                      src={loadingImg}
                      alt={'loading image'}
                    />)}
                    {deptOrDocLoc !== "" && suggestionList.length && !isSelect && !Object.values(docLoader).includes(true) ? (
                      <div className="customSearchList">
                        <ul style={{ marginBottom: 1 }}>
                          {suggestionList.map((dept, index) => (
                            <li
                              key={index}
                              className="suggestion-item"
                              onClick={() => selectDocOrDept(dept.label)}
                            >
                              {dept.type === "doctor"? `Dr. ${dept.label}`:dept.label }
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="field-container form-group icon-field date">
                  <label htmlFor="date">
                    {translate('patientPortal.durationQ', 'Duration?')}
                  </label>
                  <AntMultiDatePicker
                    className="dateRanger"
                    onchangeDate={onHandleDate}
                    start={dateRangeLoc[0]}
                    end={dateRangeLoc[1]}
                    sx={{ padding: '10px' }}
                  />
                  {/* </div> */}
                </div>
                <div className="d-flex gap-3 btn-wrapper btn-margin">
                  <button
                    onClick={() => onHandleSearch()}
                    id="search"
                    className="btn btn-secondary fit-content"
                  >
                    {translate('patientPortal.search', 'Search')}
                  </button>
                  <button
                    onClick={() => onResetHandle()}
                    id="reset"
                    className="btn btn-outlined-dark fit-content"
                  >
                    {translate('patientPortal.reset', 'Reset')}
                  </button>
                </div>
              </div>

              {prescriptionsList?.prescriptions &&
              prescriptionsList?.prescriptions.length ?
              <div className="dr-container v2" id="drContainer">
                <span className="label sortbyclr">
                  {translate('patientPortal.sortBy', 'Sort by')}:
                </span>
                <div
                tabIndex ="0"
                  onClick={referenceClick}
                  onKeyDown={(e)=>{
                    if(e.key === "Enter" && !e.shiftKey)
                    {
                      e.preventDefault()
                      referenceClick()
                    }
                    else if(showReferenceModal && e.key === "ArrowDown" && !e.shiftKey)
                    {
                        e.preventDefault()
                        sortDrop1.current.focus()
                    }
                  }}
                  className="d-flex ml-2 cursorpoint position-relative"
                >
                  <p className="fs-14 fw-500 mb-1">
                    {sortByLoc === 'created_at' ? 'Date' : 'Doctor Name'}
                  </p>
                  <KeyboardArrowDownIcon className="pat-doenarrow" />
                  {showReferenceModal ? (
                    <div className="pat-filterpop1" ref={ref}>
                      <p
                      ref={sortDrop1}
                      tabIndex="-1"
                        onClick={() => referenceCloseClick('created_at')}
                        onKeyDown={(e)=>{
                          if(e.key === "ArrowDown" && !e.shiftKey){
                            e.preventDefault()
                            e.stopPropagation()
                            sortDrop2.current.focus()
                          }
                          else if(e.key === "Enter" && !e.shiftKey){
                            e.preventDefault()
                            referenceCloseClick('created_at')
                          }
                          else if(e.key==="Tab"){
                            referenceClick()
                          }
                        }}
                        className="fs-13 
                                            pat-filterls"
                      >
                        {translate('common.date', 'Date')}
                        <span className='table-header-label' >
                            {
                              sortByLoc === 'created_at' &&

                              (
                                <span className={`table-header-icon-show ${sortByLoc === 'created_at' && 'active'}`}>
                                  {descending ?
                                   <Tooltip placement="top" title="Ascending">
                                   <ArrowUpward sx={{ fontSize: 15 }} />
                                   </Tooltip>
                                   :
                                 <Tooltip placement="top" title="Descending">
                                   <ArrowDownward sx={{ fontSize: 15 }} />
                                 </Tooltip>
                                  }
                                </span>
                              )
                            }
                          </span>
                      </p>
                      <hr className="mx-12"></hr>
                      <p
                      ref={sortDrop2}
                      tabIndex="-1"
                        onClick={() => refCloseClick('doctor_name')}
                        onKeyDown={(e)=>{
                          if(e.key === "ArrowUp" && !e.shiftKey){
                            e.preventDefault()
                            sortDrop1.current.focus()
                          }
                          else if(e.key === "Enter" && !e.shiftKey){
                            e.preventDefault()
                            refCloseClick('doctor_name')
                          }
                          else if(e.key==="Tab"){
                            referenceClick()
                          }
                        }}
                        className="fs-13 pat-filterls"
                      >
                        {translate('appointments.doctorName', 'Doctor name')}
                        <span className='table-header-label' >
                            {
                              sortByLoc === 'doctor_name' &&
                              (
                                <span className={`table-header-icon-show ${sortByLoc === 'doctor_name' && 'active'}`}>
                                  {descending ?
                                   <Tooltip placement="top" title="Ascending">
                                   <ArrowUpward sx={{ fontSize: 15 }} /> 
                                   </Tooltip>
                                 :
                                 <Tooltip placement="top" title="Descending">
                                   <ArrowDownward sx={{ fontSize: 15 }} />
                                 </Tooltip>          
                                  }
                                </span>
                              )
                            }
                          </span>
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {/* <div className="pat-divider"></div> */}
              </div>:null}
            </div>
          </div>
          <div className="filter-overlay"></div>
          <div className="scheduled-cards">
            <div className="row mb-4">
              {prescriptionsList?.prescriptions &&
              prescriptionsList?.prescriptions.length ? (
                prescriptionsList?.prescriptions?.map((item, index) => (
                  <div key={index} className="col-xl-4 col-lg-6">
                    <div className="single-card">
                      <div className="card-body">
                        <CardMedia
                          style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                          }}
                          component="img"
                          image={item?.patient_profile_img || img}
                          onError={(e) => {
                            if (!imgStatus[`${index}`]) {
                              e.target.src = errorImg;
                              setImgStatus((prev) => ({
                                ...prev,
                                [`${index}`]: true,
                              }));
                            }
                          }}
                        />
                        <div>
                          <div className="top">
                            <h6 className="name-2 text-capitalize">{item.patient_fullname}</h6>
                            <p className="doc-id">
                            {applicationData?.use_system_generated_mrn?translate("patientPortal.id", "ID"):translate("patientPortal.MRN", "MRN")} :{" "}
                              {applicationData?.use_system_generated_mrn?truncateString(item?.patient_id):item?.patient_mrn?item?.patient_mrn:"-"}
                            </p>
                          </div>
                          <div className="bottom">
                            <h6 className="doc-name text-capitalize">
                              Dr.{' '}
                              {`${item?.doctor_info?.name} ${item?.doctor_info?.last_name}`}
                            </h6>
                            <p className="description">
                              {
                                activeDeptList(
                                  item?.doctor_info?.departments
                                )[0]?.dept_name
                              }
                              <span className="relative">
                              {activeDeptList(item?.doctor_info?.departments)
                                .length >= 2 ? (
                                <span
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="true"
                                  className="pointer extra-details"
                                  style={{ paddingBottom: 'unset' }}
                                >
                                  , ...+
                                  {activeDeptList(
                                    item?.doctor_info?.departments
                                  )?.length - 1}
                                </span>
                              ) : null}
                              <ul
                                className="dropdown-menu dropdown-menu-start custom-tooltip max-height"
                                data-popper-placement="bottom-end"
                              >
                                <ul className='dr-overflow'>
                                {activeDeptList(item?.doctor_info?.departments)
                                  .slice(1)
                                  .map((item, index) => (
                                    <li key={index}>
                                      <a
                                        className="dropdown-item small text-capitalize"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#editAccount"
                                        aria-controls="editAccount"
                                        href="#"
                                      >
                                        {item.dept_name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </ul>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="date-container">
                          <div className="d-flex align-item-center gap-2">
                            <div className="icon">
                              <i className="bi bi-calendar4-week"></i>
                            </div>
                            <span>
                              {translate(
                                'prescriptionView.apntmnttDate',
                                'Appt. Date'
                              )}{' '}
                              :{' '}
                            </span>
                          </div>
                          <span className="fw-normal">
                            {dateinFormatDDMonthYYYY(
                              item?.appointment_info?.appt_date
                            )}{' '}
                            {timeIn12HourFormat(
                              item?.appointment_info?.appt_start_time
                            )}
                          </span>
                        </div>
                        <div className="action-btns">
                          {ACCESS(READ_RECORDS) && (
                            <Tooltip placement="top" title="View">
                            <button onClick={() => viewPrescription(item)}>
                              <i className="bi bi-eye"></i>
                            </button>
                            </Tooltip>
                          )}
                          {ACCESS(READ_RECORDS) && (
                            <Tooltip placement="top" title="Download">
                            <button
                              onClick={() => handleDownload(item?.prescription)}
                            >
                              <i className="bi bi-download"></i>
                            </button>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : isLoading ? (
                <div className="loader-wrap mb-4">
                  <Loader />
                </div>
              ) : (
                <NoResultFound />
              )}
              {prescriptionsList?.page_details?.total_documents > perLimit && (
                <div>
                  <button
                    onClick={() => lookingMoreHandle()}
                    className="more-btn d-block mx-auto"
                  >
                    {translate('patientView.showMore', 'Show More')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  prescriptionsList: state.PrescriptionsReducer.prescriptionsList,
  filterOptions: state.PrescriptionsReducer.filterOptions,
  loginDetails: state.LoginReducer.loginDetails,
  patientDetails: state.LoginReducer.patientDetails,
  linkedProfileLoading: state.MedicalRecordReducer.linkedProfileLoading,
  applicationData: state.SettingsReducer.applicationData,
});

const mapDispatchToProps = (dispatch) => ({
  getPrescriptions: (data,setIsLoading) => dispatch(FetchPrescriptionsList(data,setIsLoading)),
  clearData: () => dispatch(clearPrescriptiosList()),
  addFilter: (data) => dispatch(addFilterPrescriptions(data)),
  getAllLinkedProfile: (callBack, errCallBack) =>
    dispatch(getAllLinkedList(callBack, errCallBack)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissionCheck(PATIENTRECORDS)
)(Prescriptions);
