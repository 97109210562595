import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import '../../Assets/style/table.css';

function CustomCheckbox({
  checked = false,
  handleChange = () => { },
  label,
  color,
  disabled = false,
  name = 'checkbox'
}) {
  // const result = (label) => {
  //   const firstChar = label.charAt(0).toUpperCase();
  //   const remainingChars = label.slice(1);
  //   return `${firstChar}${remainingChars}`;
  // };

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              id="op"
              checked={checked}
              size="small"
              color={color}
              disabled={disabled}
              tabIndex={0}
              onChange={(e) => {
                handleChange(e);
              }}
              name={name}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  e.target.checked = !e.target.checked;
                  handleChange(e);
                }
              }}
            />
          }
          label={
            <Typography
              variant="h6"
              style={{ fontSize: 10, marginTop: 2, color: '#000000' }}
            >
              {label}
            </Typography>
          }
        />
      </FormGroup>
    </div>
  );
}

export default CustomCheckbox;
