import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { translate } from '../../../Language/Translate';
import { providerLogout } from '../../../Actions/loginAction';
import profileImg from '../../../Assets/img/place-holders/profile-placeholder.svg';
import {
  clearLocalStorage,
  setLocalStorageItem,
} from '../../../Utils/LocalStorage/local-storage';
import errorImg from '../../../Assets/img/patient-portal/error_img_placeHolder.png';
import { resetSearchAppointment } from 'Actions/appointmentAction';
import keycloak from 'Utils/keyCloak/keyCloak';
import { PORTAL_TYPES } from 'Constants/constant';
import { CardMedia } from '@mui/material';
import { updateToken } from 'Utils/Api/Network';
import { setViewMode } from 'Actions/patientProfileAction';
import { checkAppPermission } from 'Utils/Permissions/Permissions';


const ProfileData = ({ userDetails ,setViewMode,subscriptionFailed,subscriptionData}) => {
  const [username, setUserName] = useState('');
  const dispatch = useDispatch();
  const profileDropdownRef = useRef(null);
  const imgStatus = useRef(true);
  const [showProfile, setShowProfile] = useState(false);

  useEffect(() => {
    const listener = (event) => {
      if (
        !profileDropdownRef.current ||
        profileDropdownRef.current.contains(event.target) ||
        event.target.id === 'userProfileImg'
      ) {
        return;
      }
      if (event.button === 0) setShowProfile(false);
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [profileDropdownRef]);

  useEffect(() => {
    setUserName(userDetails.name);
    imgStatus.current = true;
  }, [userDetails]);
  // handle logout click
  const handleLogOut = async () => {
    clearLocalStorage();
    await keycloak.logout({
      redirectUri: window.location.origin + '/#/webConnect',
    });
    updateToken('');
    setLocalStorageItem('portalType', PORTAL_TYPES.patient);
    dispatch(providerLogout());
    dispatch(resetSearchAppointment());
    // window.location.href = '#webconnect';
    setShowProfile(false);
  };

  const handleProfile = () => {
    window.location.href = '#webconnect/profileSetting/profileInfo';
    setShowProfile(false);
    setViewMode(true)
  };
  // dropdown options
  const profileData = [
    checkAppPermission(subscriptionData) && !subscriptionFailed ?
    {
      label: translate('patientNav.profileSettings', 'Profile Settings'),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-person-gear"
          viewBox="0 0 16 16"
        >
          <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
        </svg>
      ),
      onClick: handleProfile,
    } : '',
    // userDetails?.name
    //   ? {
    //       label: translate('patientNav.changePassword', 'Change Password'),
    //       icon: <i className="bi bi-key"></i>,
    //       onClick: handleChangePsw,
    //     }
    //   : '',
    // {
    //   label: translate('patientNav.settings', 'Settings'),
    //   icon: <i className="bi bi-gear"></i>,
    //   onClick: () => {},
    // },
    {
      label: translate('patientNav.logout', 'Logout'),
      icon: <i className="bi bi-box-arrow-right"></i>,
      onClick: handleLogOut,
    },
  ];
  const menuItems=profileData
  .filter((i) => i !== '')

  return (
    <div className="nav-item dropdown user-details">
      <a
        className={`nav-link dropdown-toggle ${showProfile && 'show'}`}
        id="userProfileImg"
        onClick={() => setShowProfile(!showProfile)}
        style={{ cursor: 'pointer' }}
      >
        <div className="user-profile" id="userProfileImg">
          <CardMedia
            component="img"
            image={userDetails.profile_img || profileImg}
            className="avatar"
            id="userProfileImg"
            onError={(e) => {
              if (imgStatus.current) {
                e.target.src = errorImg;
                imgStatus.current = false;
              }
            }}
          />
          <span id="userProfileImg">
            {translate('common.hi', 'Hi')},{' '}
            {username ? username : translate('patientNav.guest', 'Guest')}
          </span>
        </div>
      </a>
      <ul
        className={`dropdown-menu dropdown-menu-end ${showProfile && 'show'}`}
        ref={profileDropdownRef}
      >
        {
         menuItems.map((item, index) => (
            <>
              {index === menuItems.length - 1 &&  menuItems.length !== 1 && (
                <hr className="dropdown-divider" />
              )}
              <li onClick={item.onClick} className="mb-0">
                <a
                  className={`dropdown-item ${
                    index === profileData.length - 1 && 'last-link'
                  }`}
                  style={{ cursor: 'pointer' }}
                >
                  {item.icon}
                  {item.label}
                </a>
              </li>
            </>
          ))}
      </ul>
      <div className="overlay"></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.LoginReducer.loginDetails,
  subscriptionFailed: state.SettingsReducer.subscriptionFailed,
  subscriptionData: state.SettingsReducer.subscriptionData,
});
const mapDispatchToProps = (dispatch) => ({
  setViewMode:(data)=> dispatch( setViewMode(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(ProfileData);
