import { setLocalStorageItem } from '../LocalStorage/local-storage';
import { encode, decode, encodeURI,  } from 'js-base64';

export const convertToBase64 = (data) => {
  try {
    const stringData = JSON.stringify(data);
    const base64 = encode(stringData);
    return base64;
  } catch (e) {
    return '';
  }
};

export const revertFromBase64 = (data) => {
  try {
    const dataToString = decode(data);
    const parsedData = JSON.parse(dataToString);
    return parsedData;
  } catch (e) {
    return '';
  }
};

export const convertToBase64URI = (data) => {
  try {
    const stringData = JSON.stringify(data);
    const base64URL = encodeURI(stringData);
    return base64URL;
  } catch (e) {
    return '';
  }
};


export const handleKeycloakLogin = (data, user_details) => {
  if (data) {
    Object.keys(data).forEach((key) => {
      setLocalStorageItem(key, data[key]);
    });
  }
  if (user_details) {
    const base64 = convertToBase64(user_details);
    setLocalStorageItem('user', base64);
  }
};
