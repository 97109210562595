import network from '../Utils/Api/Network';
import { showToaster, ToasterTypes } from './toasterAction';
export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS';
export const ADD_MEDICAL_RECORD_LOADING = 'ADD_MEDICAL_RECORD_LOADING';
export const CLEAR_ALL_MEDICAL_RECORD = 'CLEAR_ALL_MEDICAL_RECORD';
export const LINKED_PROFILE_LOADING = 'LINKED_PROFILE_LOADING';
export const SET_MEDICAL_RECORD_COLUMN_SORT = 'SET_MEDICAL_RECORD_COLUMN_SORT'


export const updateFilterOptions = (payload) => ({
  type: SET_FILTER_OPTIONS,
  payload,
});

const updateLoadingRecord = (payload) => ({
  type: ADD_MEDICAL_RECORD_LOADING,
  payload,
});

const linkedProfileLoading = (payload) => ({
  type: LINKED_PROFILE_LOADING,
  payload,
});


export const clearAllMedicalRecordData = () => ({
  type: CLEAR_ALL_MEDICAL_RECORD,
});

export const updateColumnSort = (payload) => ({
  type: SET_MEDICAL_RECORD_COLUMN_SORT,
  payload,
});

export const addMedicalRecords = (data, isModalOpen, getAllData, clearData) => {
  return (dispatch) => {
    dispatch(updateLoadingRecord(true));
    return network
      .post({ url: `/patient/${data.id}/medical-records`, data: data.payload })
      .then((response) => {
        dispatch(updateLoadingRecord(false));
        const { isError, message } = response;
        if (!isError && message) {
          getAllData();
          dispatch(showToaster(message, ToasterTypes.Success));
          isModalOpen(false);
          clearData({})
        } else {
          dispatch(showToaster(message, ToasterTypes.Failed));
        }
      });
  };
};

export const getAllLinkedList = (
  successCallBack = () => {},
  errCallBack = () => {}
) => {
  return (dispatch) => {
    dispatch(linkedProfileLoading(true))
    return network
      .get({ url: `/patient/link-profile-names` })
      .then((response) => {
        dispatch(linkedProfileLoading(false))
        const { isError, result, message } = response || {};
        if (isError) {
          errCallBack({ message, response });
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          successCallBack({ response, result, message });
        }
      });
  };
};
export const getMedicalRecords = (data, setTableData, setPageCount, setLoading=()=>{}) => {
  return (dispatch) => {
    setLoading(true)
    return network
      .get({ url: '/patient/medical-records', data })
      .then((response) => {
        const { isError, message, result } = response;
        if (!isError && result) {
          let arr = [];
          if (result?.records?.length) {
            result.records.forEach((val, ind) => {
              let otherFiles = val?.related_patient_record
                ? val?.related_patient_record
                : [];
              let allfiles = [val?.medical_record_info, ...otherFiles];
              let fileArr = [];
              allfiles.map((val) => {
                fileArr.push({
                  display_name: val.file_display_name,
                  filename: val.file_name,
                  link: val.file_link,
                  size: val.file_size,
                  extension:
                    val.file_name.split('.')[
                      val.file_name.split('.').length - 1
                    ],
                });
              });
              arr.push({
                id: val.medical_record_info.record_id,
                patientName: val.patient_info.patient_name,
                SlNo: ind + 1,
                FileName: val.medical_record_info.file_name,
                FileLink: val.medical_record_info.file_link,
                count: val?.count > 1 ? `+{val?.count-1} more` : '',
                uploadDate: val.created_date,
                remarks: val.medical_record_info?.remarks,
                files: fileArr,
              });
            });
            setPageCount(result.page_details.total_documents);
          }
          setTableData(arr);
          // dispatch(showToaster(message, ToasterTypes.Success));
          // isModalOpen(false)
        } else {
          dispatch(showToaster(message, ToasterTypes.Failed));
        }
        setLoading(false)
      });
  };
};
export const getMedicalRecordDetails = (id, setFormData,setLoader=()=>{}) => {
  return () => {
    return network
      .get({ url: `/patient/medical-records/${id}` })
      .then((response) => {
        const { isError, result } = response;
        if (!isError) {
          let otherFiles = result['medical-record']?.related_patient_record
            ? result['medical-record']?.related_patient_record
            : [];
          let allfiles = [
            result['medical-record']?.medical_record_info,
            ...otherFiles,
          ];
          let fileArr = [];
          allfiles.map((val) => {
            fileArr.push({
              display_name: val?.file_display_name,
              filename: val.file_name,
              link: val.file_link,
              size: val.file_size,
              extension:
                val.file_name.split('.')[val.file_name.split('.').length - 1],
            });
          });
          setFormData({
            patientName: result['medical-record']?.patient_info?.patient_name,
            patientId: result['medical-record']?.patient_info?.patient_id,
            mrn:result['medical-record']?.patient_info?.patient_mrn,
            remarks: result['medical-record']?.medical_record_info?.remarks,
            profileImg:
              result['medical-record']?.patient_info?.patient_profile_image,
            file: fileArr,
          });
        }
        setLoader(false)
      });
  };
};
export const deleteMedicalRecords = (
  id,
  handleDeleteSuccess = () => {}
) => {
  return (dispatch) => {
    dispatch(updateLoadingRecord(true))
    return network
      .delete({ url: `/patient/medical-records/${id}` })
      .then((response) => {
        const { isError, message } = response;
        if (!isError) {
          dispatch(showToaster(message, ToasterTypes.Success));
          handleDeleteSuccess()
          dispatch(updateLoadingRecord(false))
        } else {
          dispatch(showToaster(message, ToasterTypes.Failed));
          dispatch(updateLoadingRecord(false))
        }
      });
  };
};
