import { getSassBaseURL } from 'Utils/Api/ApiInstance';

export const mainLogoUrl = (subData) => {
  const subId = subData?.id;
  return `${getSassBaseURL()}/appnest/api/customer/__ui/logo/${subId}`;
};

export const faviconUrl = (subData, key) => {
  const subId = subData?.id;
  if (key) {
    return `${getSassBaseURL()}/appnest/api/customer/__ui/favicon/${subId}?${key}`;
  } else {
    return `${getSassBaseURL()}/appnest/api/customer/__ui/favicon/${subId}`;
  }
};

export const setfavicon = (subData) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = faviconUrl(subData,Math.random());
};
