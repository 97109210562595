import Network from './Network';

export const Fetch_Patient_Data =async (data) => {
    let response = null;
    let error = null;
      await Network
      .get({
        url: '/patients',
        data,
      })
        .then((res) => {
          const {  isError,result,message } = res || {};
          if(isError){
          error=message
          }else{
            response=result
          }
        });
        return { error, response };
  }