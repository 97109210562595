import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import network from '../../../Utils/Api/Network';
import ReactSelectDropDown from '../../Common/ReactSelectDropDown';
import AppointmentView from './AppointmentView';
import { translate } from '../../../Language/Translate';
import { APPOINTMENT_TABS } from '../../../Constants/constant';
import ReschedulePopup from './RescheduleAppointment';
import img from 'Assets/img/icons/doctor-placeholder-avatar.svg';
import errorImg from '../../../Assets/img/patient-portal/error_img_placeHolder.png';
import moment from 'moment';
import './style/appointments.css';
import AntMultiDatePicker from '../Common/AntMultiDatePicker';
import { CardMedia } from '@mui/material';
import '../Layout/style/forms.css';
import '../Common/style/filter.css';
import '../Common/style/tabs.css';
import '../Common/style/offcanvas.css';

import {
  appendTime,
  dateInFullMonthFormat,
  formatDate,
} from '../../../Utils/DateFormatter/Date';
import {
  clearAppointmentReducer,
  clearSearchAppointment,
  FetchAppointmentList,
  FetchDoctorSlots,
  UpdateAppointment,
  updateSearchAppointment,
} from '../../../Actions/appointmentAction';
import AppointmentsCancelModal from './AppointmentsCancelModal';
import { FetchAppointmentDetails } from '../../../Actions/prescriptions';
import { DoctorsApi } from '../../../Utils/Api/DoctorsApi';
import ConfirmReschedule from './ConfirmReschedule';
import PatientInfoModal from './PatientData';
import Modal from '../Common/Modal';
import AppointmentCancelledModal from './AppointmentCancelledModal';
import NoResultFound from '../Common/NoResultFound';
import { getAllLinkedList } from 'Actions/medicalRecordAction';
import { APPOINTMENT } from 'Constants/feature';
import { CREATE, READ_DETAILS, STATUS_UPDATE, UPDATE } from 'Constants/scopes';
import Loader from 'Components/Common/Loader';
import loadingImg from 'Assets/img/icons/search-loader.gif';
import { useIntl } from 'react-intl';
import ButtonLoader from 'Components/Common/ButtonLoader';
import { removeDrPrefix } from 'Utils/StringFormatter/String';

let apiTimeout = null;
const Appointments = (props) => {
  const {
    history,
    getAppointments,
    clearData,
    appointmentList,
    appointmentsViewData,
    getViewAppointment,
    doctorSlotData,
    getDoctorSlots,
    editAppointment,
    getAllLinkedProfile,
    loginDetails = {},
    patientDetails = {},
    updateSearchData,
    searchData,
    clearSearchData,
    permissions,
  } = props;
  // to handle tab change
  const [selectedTab, setSelectedTab] = useState();

  // to store search data
  const [isSelect, setIsSelect] = useState(false);
  const [search, setsearchWord] = useState();
  const [searchName, setSearchName] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  // const [optionSelectedData, setOptionSelectedData] = useState();
  const [cancelId, setCancelId] = useState();
  const [reason, setReason] = useState('');
  const [cancelData,setCancelData] = useState({})
  // handle reschedule
  const [confirmRescdule, setConfirmReschedule] = useState(false);
  const [reschedule, setReshedule] = useState(false);
  const [showPatient, setShowPatient] = useState(false);
  const [doctorData, setDoctorData] = useState({});
  const [date, setDate] = useState('');
  const [selectedSlot, setSelectedSlot] = useState({});
  const [open, setOpen] = useState(false);
  const [profileNames, setProfileNames] = useState([]);
  const [imgStatus, setImgStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSlotLoading, setIsSlotLoading] = useState(false);
  const [isViewLoading, setIsViewLoading] = useState(false);
  const [apptId, setApptId] = useState('');
  // to set option data
  // const [
  //   options,
  //   //setOptions
  // ] = useState([
  //   {
  //     value: 'not confirmed',
  //     label: 'not confirmed',
  //     id: 'not',
  //   },
  //   {
  //     value: 'confirmed',
  //     label: 'confirmed',
  //     id: 'yes',
  //   },
  // ]);
  // to set number of list (data)
  const [perLimit, setPerLimit] = useState(6);

  // to loader for show more button
  const [showMoreLoader,setShowMoreLoader] = useState(false)

  // to list suggestion while searching department or doctor name
  const [suggestionList, setSuggestionList] = useState([]);

  // to handle modal
  const [showFilter, setShowFilter] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [successCancel, setSuccessCancel] = useState(false);
  const [showData, setShowData] = useState(false);
  const [docLoader,setDocLoader] = useState({})
  const [deptValue,setDeptValue]=useState('')

  const intl = useIntl();
  // to fetch api for list appointment
  const fetchAppointments = ({
    search_Patient_Name: searchPatient,
    searchDoc_dept: searchDoc,
    appStatus,
    pageNo,
    perPageCount,
    start_Date,
    end_Date,
  } = {}) => {
    setIsLoading(true);
    const statusVar =  appStatus !== undefined
    ? appStatus === ''
      ? undefined
      : appStatus
    : selectedTab !== [] || selectedTab !== undefined
    ? selectedTab
    : ''
    const config = {
      patient_id:
        searchPatient !== undefined
          ? searchPatient === ''
            ? undefined
            : searchPatient
          : searchName?.patient_id,
      dept_doc_search:
        searchDoc !== undefined
          ? searchDoc === ''
            ? undefined
            : removeDrPrefix(searchDoc)
          : search === ''
          ? undefined
          : removeDrPrefix(search),
      start_date:
        start_Date !== undefined
          ? start_Date === ''
            ? undefined
            : start_Date
          : startDate === ''
          ? undefined
          : startDate,
      end_date:
        end_Date !== undefined
          ? end_Date === ''
            ? undefined
            : end_Date
          : endDate === ''
          ? undefined
          : endDate,
      status:statusVar,
      page: pageNo || 1,
      limit: perPageCount || 6,
      sort : statusVar == "upcoming" ? "appt_date,appt_start_time" : null
    };
    getAppointments(config, setIsLoading);
    setImgStatus({});
  };

  // handle home click
  const handleHomeClick = () => {
    history.push('/webConnect');
  };
  // search by name handle
  const searchByNameHandle = (e) => {
    setIsSelect(false);
    setsearchWord(e.target.value);
  };

  const selectDocOrDept = (data) => {
    setsearchWord(data);
    setSuggestionList([]);
    setIsSelect(true);
    setDocLoader({})
  };

  // search by doctor and specility handle
  const searchNameHandle = (e) => {
    setSearchName(e);
  };
  // handle book appointment
  const handleBookAppointment = () => {
    history.push('/webConnect/bookAppointment');
  };

  // to Handle Change Date
  const HandleChangeDate = (e) => {
    if (e) {
      setStartDate(formatDate(e[0]) ?? '');
      setEndDate(formatDate(e[1]) ?? '');
    } else {
      setStartDate('');
      setEndDate('');
    }
  };
  useEffect(()=>{
setShowMoreLoader(false)
  },[appointmentList])

  // const handleChangeOption = (option) => {
  //   setOptionSelectedData(option);
  // };

  // handle tab change
  const handleTabChange = (index) => {
    setPerLimit(6);
    // if (selectedTab !== index) {
    //   setOptionSelectedData('');
    // }
    setShowMoreLoader(false)
    setSelectedTab(index);
    setStartDate('');
    setEndDate('');
    updateSearchData({ selectedTab: index });
  };

  // handle Appointment View(modal)
  const handleAppointmentView = (data) => {
    if (selectedTab !== 2) {
      setIsViewLoading(true);
      setShowData(true);
      getViewAppointment(data.appointment_id, setIsViewLoading);
    }
  };

  // handle edit modal close
  const handleClose = () => {
    setShowData(false);
  };

  // to Handle open Modal Cancel appointment
  const handleOpenCancelAppt = (data={}) => {
    setCancelData(data)
    setCancelModal(true);
    setShowData(false);
  };

  // to Handle open Modal Reschedule appointment
  const handleOpenRescheduleAppt = () => {
    // setReshedule(true);
    setConfirmReschedule(true);
    setShowData(false);
  };

  // handle proceed rescdeule
  const handleProceedReschdule = () => {
    setIsSlotLoading(true);
    setReshedule(true);
    setConfirmReschedule(false);
    const config = {
      start_date: formatDate(
        appointmentsViewData?.appointment_details?.appt_date
      ),
      end_date: formatDate(
        appointmentsViewData?.appointment_details?.appt_date
      ),
    };
    getDoctorSlots(
      appointmentsViewData?.appointment_details?.doctor_id,
      config,
      setIsSlotLoading
    );
    DoctorsApi.getDoctorDetails(
      appointmentsViewData?.appointment_details?.doctor_id
    ).then((res) => {
      if (res.error) {
        return;
      }
      if (res.response) {
        const data = res.response;
        const docData = { ...data.doctor, departments: data.departments };
        setDoctorData(docData);
      }
    });
  };

  // handle stop reschedule proceeding
  const handleStopReschdule = () => {
    setConfirmReschedule(false);
    setShowData(true);
  };

  // to Handle Close Modal Cancel Appointment
  const handleCloseCancelAppt = () => {
    setCancelModal(false);
    setReason('');
  };
  // to Handle Open Success Cancel Modal Appointment
  const handleOpenSuccessCancelAppt = () => {
    setCancelModal(false);
    setSuccessCancel(true);
  };

  // to Handle Close Modal Cancel Success Appointment
  const handleCloseSuccessCancelAppt = () => {
    setSuccessCancel(false);
  };

  // search Handle
  const searchHandle = () => {
    setIsLoading(true);
    fetchAppointments();
    setShowMoreLoader(false)
    const data = { searchDept: search, searchName, startDate, endDate };
    updateSearchData(data);
    setShowFilter(false);
    setPerLimit(6)
  };

  // to increment page number
  const lookingMoreHandle = () => {
    setPerLimit((state) => state + 6);
    setShowMoreLoader(true)
    fetchAppointments({
      perPageCount: perLimit + 6,
    });
  };

  // clear all search data
  const resetHandle = () => {
    if (
      search !== '' ||
      searchName !== '' ||
      startDate !== '' ||
      endDate !== ''
    ) {
      setPerLimit(6);
      setShowMoreLoader(false)
      setDocLoader({})
      setsearchWord('');
      // setSearchName(userOption);
      setSearchName({ label: translate('common.all', 'All'), value: '' })
      setStartDate('');
      setEndDate('');
      fetchAppointments({
        search_Patient_Name: '',
        searchDoc_dept: '',
        appStatus: selectedTab,
        start_Date: '',
        end_Date: '',
      });
    }
    clearSearchData();
    setShowFilter(false);
  };

  // to show suggestion list while typing search department
  const loadOptions = async (inputValue) => {
    let fetchedOptions = [];
    if (inputValue?.trim()) {
      let id = new Date().getTime()
      setDocLoader((prev)=>({
        ...prev,
        [id]:true
      }));
      const config = {
        doc_with_dept: true,
        status: 'active',
        limit: 0
      }
      await network
        .get({ url: `/search/department-doctor/${inputValue}`, data: config })
        .then((response) => {
          setDocLoader((prev)=>({
            ...prev,
            [id]:false
          }));
          const { isError, result } = response;
          if (isError) {
            return;
          } else {
            const options = result?.search_result.map((item) => ({
              label: item.type === 'doctor' ? 'Dr.'+' '+ item.name:item.name,
              value: item.id,
              type: item.type,
            }));
            fetchedOptions = options;
          }
        })
        .catch(() => {});
    }
    setSuggestionList(fetchedOptions);
  };

  useEffect(() => {
    if (Object.keys(searchData).length) {
      setSearchName(searchData?.searchName);
      setsearchWord(searchData?.searchDept);
      setStartDate(searchData?.startDate);
      setEndDate(searchData?.endDate);
      setIsSelect(true);
    }
    setSelectedTab(searchData.selectedTab ?? 'upcoming');
    setShowMoreLoader(false)
  }, []);

  useEffect(() => {
    if (selectedTab) {
      fetchAppointments();
    }
    setPerLimit(6);
    setShowMoreLoader(false)
    return () => clearData();
  }, [selectedTab]);
  useEffect(() => {
    if (!isSelect) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadOptions(search);
      }, 300);
      apiTimeout = timeout;
    }
  }, [search, isSelect]);

  useEffect(() => {
    getAllLinkedProfile(mapProfileData);
    if (!searchData?.searchName) {
      // setSearchName(userOption);
      setSearchName({ label: translate('common.all', 'All'), value: '' });
    }
  }, [loginDetails]);

  const userData = { ...loginDetails, ...patientDetails };

  let userName = userData?.last_name
    ? `${userData?.name} ${userData?.last_name}`
    : userData?.name
    ? userData?.name
    : `+${userData.tele_country_code}${userData.phone}`;
  let userOption = {
    label: userName,
    user_id: userData?.user_id,
    patient_id: userData?.patient_id,
    value: userData?.patient_id,
  };

  const mapProfileData = ({ result }) => {
    // uncomment if default value should be selected here
    let arr = [{ label: translate('common.all', 'All'), value: '' }, userOption];
    if (result?.linked_profile_names?.length) {
      let list = result?.linked_profile_names;
      list.map((val) => {
        let userName = val?.name
          ? val?.name
          : `+${val?.tele_country_code}${val?.phone}`;
        arr.push({
          label: userName,
          user_id: val?.user_id,
          patient_id: val?.patient_id,
          value: val?.patient_id,
        });
      });
    }
    setProfileNames(arr);
    // updateFilterByName(arr[0])
  };

  const setCancel = () => {
    setCancelId(appointmentsViewData.appointment_details?.appointment_id);
  };

  const checkpermission = (feature, scope) => {
    const permission = permissions?.[feature];
    if (!permission || !permission?.length) {
      return false;
    } else {
      return permission.includes(scope);
    }
  };

  const disabledDates = (current, type) => {
    if (type === 'before') {
      return current.isBefore(moment().subtract(1, 'day'));
    } else {
      return current.isAfter(moment());
    }
  };

  return (
    <div className="my-appointments">
      <AppointmentCancelledModal
        open={successCancel}
        onClose={handleCloseSuccessCancelAppt}
        data={cancelData}
      />
      <AppointmentsCancelModal
        open={cancelModal}
        onClose={handleCloseCancelAppt}
        fetchAppointments={fetchAppointments}
        cancelId={cancelId}
        setCancel={setCancel}
        reason={reason}
        setReason={setReason}
        success={handleOpenSuccessCancelAppt}
      />
      <AppointmentView
        open={showData}
        onClose={handleClose}
        tabIndex={selectedTab}
        data={appointmentsViewData}
        onCancel={()=>
          checkpermission(APPOINTMENT, STATUS_UPDATE)
            ? handleOpenCancelAppt(appointmentsViewData?.appointment_details)
            : ''
        }
        onRescedule={
          checkpermission(APPOINTMENT, UPDATE) ? handleOpenRescheduleAppt : ''
        }
        isLoading={isViewLoading}
      />
      <ConfirmReschedule
        open={confirmRescdule}
        setConfirmReschedule={setConfirmReschedule}
        handleProceedReschdule={handleProceedReschdule}
        handleStopReschdule={handleStopReschdule}
      />
      <ReschedulePopup
        btnClick={reschedule}
        setBtnClick={setReshedule}
        appointmentsViewData={appointmentsViewData}
        doctorSlotData={doctorSlotData}
        getDoctorSlots={getDoctorSlots}
        doctorData={doctorData}
        fetchAppointments={fetchAppointments}
        editAppointment={editAppointment}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        setShowPatient={setShowPatient}
        date={date}
        setDate={setDate}
        open={open}
        setOpen={setOpen}
        apptId={apptId}
        isLoading={isSlotLoading}
        setIsLoading={setIsSlotLoading}
        deptValue={deptValue}
        setDeptValue={setDeptValue}
      />
      <div className="patient-info-modal">
        <Modal
          close={showPatient}
          setClose={setShowPatient}
          title={translate('myAppointments.patientInfo', 'Patient Information')}
        >
          <PatientInfoModal
            appointmentsViewData={appointmentsViewData}
            doctorData={doctorData}
            fetchAppointments={fetchAppointments}
            editAppointment={editAppointment}
            selectedSlot={selectedSlot}
            date={date}
            setShowPatient={setShowPatient}
            setOpen={setOpen}
            setApptId={setApptId}
            deptValue={deptValue}
            modalShow={showPatient}
          />
        </Modal>
      </div>

      <div className="bread-crumbs mb-lg-2">
        <div className="wrapper links">
          <a
            className="previous"
            onClick={handleHomeClick}
            style={{ cursor: 'pointer' }}
          >
            {translate('myAppointments.home', 'Home')}
          </a>
          <span> {'>'} </span>
          <a className="bread-crumb-cursor">
            {' '}
            {translate('myAppointments.myAppointments', 'My Appontments')}{' '}
          </a>
        </div>
      </div>
      <div className="main-header">
        <div className="wrapper">
          <h1 className="main-title appointment-title">
            {translate('myAppointments.myAppointments', 'My Appointments')}
          </h1>
          <button
            className="open-filter-btn"
            onClick={() => setShowFilter(true)}
          >
            <i className="bi bi-funnel"></i>
          </button>
          <div className="fake-mobile-footer">
            {checkpermission(APPOINTMENT, CREATE) && (
              <button
                className="btn btn-primary gap-2"
                onClick={handleBookAppointment}
                style={{ lineHeight: 'normal' }}
              >
                {translate(
                  'myAppointments.bookAnAppointment',
                  'Book an Appointment'
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="tabs">
        <div className="wrapper">
          <div className="tab-wrapper">
            <ul className="nav custom-tab" id="pills-tab">
              {APPOINTMENT_TABS.map((tab, index) => (
                <li className="nav-item" key={index + 1}>
                  <button
                    className={tab.value === selectedTab ? 'active' : ''}
                    id="upcoming"
                    onClick={() => handleTabChange(tab.value)}
                  >
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active">
              <div
                id="common-filter"
                className={`${
                  // selectedTab !== 'upcoming'
                  //   ? 'common-filter-v3'
                  // :
                  'common-filter-v3'
                } ${showFilter && 'opened'}`}
              >
                <button
                  className="btn-close"
                  onClick={() => setShowFilter(false)}
                >
                  <i className="bi bi-x"></i>
                </button>
                <div className="filter-elements">
                  <div className="field-container form-group">
                    <label htmlFor="name">
                      {translate(
                        'myAppointments.filterByName',
                        'Filter by Name'
                      )}
                    </label>
                    <div className="icon-field name">
                      <ReactSelectDropDown
                        id="filterbyname"
                        options={profileNames}
                        onInputChange={searchNameHandle}
                        value={searchName}
                      />
                      {/* <i className="bi bi-search icon"></i>
                      
                      <input
                        className="input w-100"
                        type="search"
                        id="name"
                        placeholder="i.e, John Doe"
                        autoComplete="off"
                        value={searchName}
                        onChange={searchNameHandle}
                      /> */}
                    </div>
                  </div>
                  <div className="field-container form-group">
                    <label htmlFor="name">
                      {translate(
                        'myAppointments.docSpec',
                        'Doctor / Specialtiy'
                      )}
                    </label>
                    <div className="icon-field name loading v2 ">
                      <i className="bi bi-search icon"></i>
                      <input
                        className="input w-100"
                        type="search"
                        id="name"
                        placeholder="i.e, Dr. John Doe/Cardiology"
                        autoComplete="off"
                        value={search}
                        onChange={searchByNameHandle}
                        onBlur={() =>
                          setTimeout(() => setSuggestionList([]), 400)
                        }
                        onFocus={() => {
                          if (search) loadOptions(search);
                        }}
                      />
                      {search !== '' && !isSelect && Object.values(docLoader).includes(true) && (
                        <img
                          className="loading-img"
                          src={loadingImg}
                          alt={'loading image'}
                        />
                      )}
                      {search !== "" && suggestionList.length && !isSelect && !Object.values(docLoader).includes(true) ? (
                        <div className="customSearchList">
                          <ul style={{ marginBottom: 1 }}>
                            {suggestionList.map((dept, index) => (
                              <li
                                key={index}
                                className="suggestion-item"
                                onClick={() => selectDocOrDept(dept.label)}
                              >
                             {dept.label}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="field-container form-group">
                    <label htmlFor="date">
                      {translate('myAppointments.duration', 'Duration')}?
                    </label>
                    <div className="icon-field date">
                      <i className="bi bi-calendar-week icon"></i>
                      <AntMultiDatePicker
                        start={startDate}
                        end={endDate}
                        sx={{
                          padding: '8px',
                        }}
                        onchangeDate={HandleChangeDate}
                        disabledDates={
                          selectedTab === 'upcoming'
                            ? (cur) => disabledDates(cur, 'before')
                            : selectedTab === 'previous'
                            ? (cur) => disabledDates(cur, 'after')
                            : () => {
                                return false;
                              }
                        }
                      />
                    </div>
                  </div>
                  {/* {selectedTab === 'upcoming' ? (
                    <div className="field-container form-group">
                      <label>
                        {translate(
                          'myAppointments.aptStatus',
                          'Appointment Status'
                        )}
                      </label>
                      <div className="icon-field select">
                        <ReactSelectDropDown
                          options={options}
                          value={optionSelectedData}
                          onInputChange={handleChangeOption}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )} */}

                  <div className="d-flex gap-3 btn-wrapper">
                    <button
                      id="search"
                      className="btn btn-secondary fit-content"
                      onClick={() => searchHandle()}
                    >
                      {translate('patientPortal.search', 'Search')}
                    </button>
                    <button
                      id="reset"
                      className="btn btn-outlined-dark fit-content"
                      onClick={() => resetHandle()}
                    >
                      {translate('myAppointments.reset', 'Reset')}
                    </button>
                  </div>
                </div>
              </div>
              <div className="filter-overlay"></div>
              <div className="scheduled-cards ">
                <div className="row">
                  {(!isLoading || showMoreLoader )&& appointmentList?.appointments ? (
                    appointmentList?.appointments.map((i, index) => (
                      <div key={index} className="col-md-6">
                        <div
                          className="single-card"
                          tabIndex="0"
                          onClick={
                            checkpermission(APPOINTMENT, READ_DETAILS)
                              ? () => handleAppointmentView(i)
                              : () => {}
                          }
                          onKeyDown={(e)=>{
                            if(e.key === "Enter"){
                              e.preventDefault()
                              handleAppointmentView(i)
                            }
                          }}
                        >
                          <CardMedia
                            style={{
                              borderRadius: '50%',
                              objectFit: 'cover',
                            }}
                            component="img"
                            image={i.doctor_profile_img ?? img}
                            onError={(e) => {
                              if (!imgStatus[`${index}`]) {
                                e.target.src = errorImg;
                                setImgStatus((prev) => ({
                                  ...prev,
                                  [`${index}`]: true,
                                }));
                              }
                            }}
                          />
                          <div className="contents">
                            <div>
                              <div className="top">
                                <h6 className="doc-name">
                                  Dr. {removeDrPrefix(i.doctor_name)}
                                </h6>
                                <p className="description text-capitalize">{i.dept_name}</p>
                              </div>
                              <div className="bottom">
                                <h6 className="name-2">{i.patient_name}</h6>
                                <p className="doc-id">
                                  ID : {i.appointment_id}
                                </p>
                              </div>
                            </div>
                            <div className="status-block">
                              <span
                                className={`badge-common ${
                                  i.live_status === 'upcoming' &&
                                  'badge-comfirmed'
                                } ${
                                  i.live_status === 'notattempted' &&
                                  'badge-absent'
                                } ${
                                  i.live_status === 'completed' &&
                                  'badge-completed'
                                } ${
                                  i.live_status === 'cancelled' &&
                                  'badge-cancelled'
                                } `}
                              >
                                <span className="round"></span>
                                <span>
                                  {i.live_status === 'upcoming'
                                    ? translate(
                                        'myAppointments.confirmed',
                                        'Confirmed'
                                      )
                                    : i.live_status === 'completed'
                                    ? translate(
                                        'myAppointments.completed',
                                        'Completed'
                                      )
                                    : i.live_status === 'notattempted'
                                    ? translate(
                                        'myAppointments.notAttempted',
                                        'Not Attempted'
                                      )
                                    : i.live_status === 'cancelled'
                                    ? translate(
                                        'myAppointments.cancelled',
                                        'Cancelled'
                                      )
                                    : ''}
                                </span>
                              </span>
                              <h6>
                                {dateInFullMonthFormat(i.appt_date, intl)}
                              </h6>
                              <span>{appendTime(i.appt_start_time)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : isLoading  && !showMoreLoader? (
                    <div className="loader-wrap mb-4">
                      <Loader />
                    </div>
                  ) : (
                    <NoResultFound removeTopMargin/>
                  )}
                </div>
                {(appointmentList?.page_details?.total_documents > perLimit || showMoreLoader) && (
                  <button
                    onClick={() => lookingMoreHandle()}
                    className={`more-btn d-block mx-auto ${showMoreLoader ? 'loading' :''}`}
                    disabled={showMoreLoader}
                  >
                    {showMoreLoader ?
                    <ButtonLoader text={translate(
                        'patientView.showMore',
                        'Show More'
                    )} />
                    :
                    translate('patientView.showMore', 'Show More')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appointmentList: state.AppointmentReducer.appointmentList,
  appointmentsViewData: state.AppointmentReducer.appointmentDetails,
  doctorSlotData: state.AppointmentReducer.doctorSlotData,
  loginDetails: state.LoginReducer.loginDetails,
  patientDetails: state.LoginReducer.patientDetails,
  searchData: state.AppointmentReducer.searchAppointment,
  permissions: state.LoginReducer.userPermissions.ResourceNameScope,
});

const mapDispatchToProps = (dispatch) => ({
  getAppointments: (data, setLoader) =>
    dispatch(FetchAppointmentList(data, setLoader)),
  getViewAppointment: (id, setLoader) =>
    dispatch(FetchAppointmentDetails(id, setLoader)),
  getDoctorSlots: (id, config, setLoader) =>
    dispatch(FetchDoctorSlots(id, config, setLoader)),
  editAppointment: (id, data, fetchAppointments, handleEditClose) =>
    dispatch(
      UpdateAppointment(id, data, fetchAppointments, handleEditClose, false)
    ),
  clearData: () => dispatch(clearAppointmentReducer()),
  getAllLinkedProfile: (callBack, errCallBack) =>
    dispatch(getAllLinkedList(callBack, errCallBack)),
  updateSearchData: (searchData) =>
    dispatch(updateSearchAppointment(searchData)),
  clearSearchData: () => dispatch(clearSearchAppointment()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
