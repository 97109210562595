import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Hearder';
import Toaster from '../Common/Toaster';
import './style/Theme.css';
import './style/common.css';
import './style/rtl.css';
import '../Common/style/buttons.css';
import '../Layout/style/typegraphy.css';
import './style/customization.css';
import '../Layout/style/developer.css';
import { useSelector } from 'react-redux';

const Layout = (props) => {
  const { children } = props;

  // add patient portal class name to the body
  useEffect(() => {
    document.body.classList.add('patient-portal');
    return () => document.body.classList.remove('patient-portal');
  }, []);

  // add 'page-transition' class to the body for 1s whenever the page changes to add transition effect
  useEffect(() => {
    document.body.classList.add('page-transition');
    setTimeout(()=> {document.body.classList.remove('page-transition')}, 1000)
  }, [children])

  const theme = useSelector((state) => state.SettingsReducer.theme)

  // add data theme to the body to set the color scheme
  useEffect(()=>{
    document.body.setAttribute("data-theme",theme)
  },[theme])

  return (
    <div className="patient-portal outer-wrapper">
      <div>
      <Header />
      <div
        className="main-content-area"
        style={{
          minHeight: 'calc(100vh - 174px)',
        }}
      >
        {children}
      </div>
      </div>
      <Footer />
      <Toaster />
    </div>
  );
};

export default React.memo(Layout);
