import React, { useEffect, useRef, useState } from 'react'

const TableWrapper = (props) => {
    const { children, data } = props;
    const tableref = useRef(null)
    const [isScrollable, setIsScrollable] = useState(false);

    const setScrollhandle = () => {
        setTimeout(() => {
          const element = tableref.current;
          if (element) {
            setIsScrollable(element?.scrollHeight > element?.clientHeight);
          }
        }, 0);
      };
    
      useEffect(() => {
        setScrollhandle();
      }, [data])

    return (
        <div className={`table-outer ${isScrollable ? 'has-scrolling' : ''}`}>
            <div className="scroll-faker"></div>
            {React.cloneElement(children, { ref: tableref })}
        </div>
    )
};

export default TableWrapper;