/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../Assets/style/table.css';
import {
  DOCTOR_APPOINTMENT_HEADERS,
  APPOINTMENT_STATUS_NAMES,
  APPOINTMENT_STATUS,
  APPOINTMENT_STATUS_OPTIONS_ALL,
} from '../../Constants/constant';
import HeaderFields from '../Common/HeaderFeilds';
import ReactSelectDropDown from '../Common/ReactSelectDropDown';
import Loader from '../Common/Loader';
import Pagination from '../Common/Pagination';
import {
  clearAppointmentReducer,
  clearDoctorAppointmentExceptFilter,
  FetchAppointmentList,
  startAppointment,
  updateColumnSort,
  updateCurrentPageDoctor,
  updateDoctorSearchData,
  updatePerPageDoctor,
} from '../../Actions/appointmentAction';
import {
  appendTime,
  dateinFormatDDMMMYYYY,
  formatDate,
  // formatDateForRoaster,
  formatDateto_D_WD_M_Y,
  timeZoneDate,
} from '../../Utils/DateFormatter/Date';
import { isEqual } from 'lodash';
import AntMultiDatePicker from 'Components/Patient_Portal/Common/AntMultiDatePicker';
import ConflictConform from './ConflictConfirm';
import { compose } from 'redux';
import withPermissionCheck from 'Utils/WithUtils/WithPermission';
import { APPOINTMENT } from 'Constants/feature';
import { READ_DETAILS } from 'Constants/scopes';
import { translate } from 'Language/Translate';
import telephone from 'Assets/img/icons/telephone.svg'
import envelope from 'Assets/img/icons/envelope.svg'
import TableColumnHr from 'Components/Common/TableColumnHr';
import usePrevious from 'Hooks/usePrevious';
import useDidMountEffect from 'Hooks/useDidMountEffect';
import NoResultFound from 'Components/Common/NoResult';
import TableWrapper from 'Components/Common/TableWrpper';

const startIcon = <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.2122 5.5C12.2122 5.15482 11.9323 4.875 11.5872 4.875L3.58716 4.875C3.24198 4.875 2.96216 5.15482 2.96216 5.5C2.96216 5.84518 3.24198 6.125 3.58716 6.125L11.5872 6.125C11.9323 6.125 12.2122 5.84518 12.2122 5.5Z" fill="#505968"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.67559 9.97036C8.93537 10.1977 9.33022 10.1713 9.55752 9.91157L13.0575 5.91157C13.2742 5.66394 13.2618 5.29072 13.0291 5.05806L9.0291 1.05806C8.78502 0.81398 8.38929 0.813981 8.14522 1.05806C7.90114 1.30214 7.90114 1.69786 8.14522 1.94194L11.7318 5.52848L8.6168 9.08844C8.3895 9.34821 8.41582 9.74306 8.67559 9.97036Z" fill="#505968"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.462158 10L0.462158 1.625C0.462158 1.27982 0.74198 1 1.08716 1C1.43234 1 1.71216 1.27982 1.71216 1.625L1.71216 10C1.71216 10.3452 1.43234 10.625 1.08716 10.625C0.74198 10.625 0.462158 10.3452 0.462158 10Z" fill="#505968"/>
</svg>

const moreIcon = <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.76221 8.5C1.76221 4.74494 4.83214 1.675 8.58721 1.675C12.3423 1.675 15.4122 4.74494 15.4122 8.5C15.4122 12.2551 12.3423 15.325 8.58721 15.325C4.83214 15.325 1.76221 12.2551 1.76221 8.5ZM8.58721 2.825C5.46727 2.825 2.91221 5.38007 2.91221 8.5C2.91221 11.6199 5.46727 14.175 8.58721 14.175C11.7071 14.175 14.2622 11.6199 14.2622 8.5C14.2622 5.38007 11.7071 2.825 8.58721 2.825Z" fill="#505968"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.51 8.5C10.51 8.18244 10.7674 7.925 11.085 7.925H11.0906C11.4082 7.925 11.6656 8.18244 11.6656 8.5C11.6656 8.81757 11.4082 9.075 11.0906 9.075H11.085C10.7674 9.075 10.51 8.81757 10.51 8.5Z" fill="#505968"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.00938 8.5C8.00938 8.18244 8.26682 7.925 8.58438 7.925H8.59C8.90756 7.925 9.165 8.18244 9.165 8.5C9.165 8.81757 8.90756 9.075 8.59 9.075H8.58438C8.26682 9.075 8.00938 8.81757 8.00938 8.5Z" fill="#505968"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.50877 8.5C5.50877 8.18244 5.76621 7.925 6.08377 7.925H6.08939C6.40695 7.925 6.66439 8.18244 6.66439 8.5C6.66439 8.81757 6.40695 9.075 6.08939 9.075H6.08377C5.76621 9.075 5.50877 8.81757 5.50877 8.5Z" fill="#505968"/>
<path opacity="0.01" fill-rule="evenodd" clip-rule="evenodd" d="M16.0872 1V16H1.08716V1H16.0872Z" stroke="#505968" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const viewIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.50007 9.73757C6.26257 9.73757 5.26257 8.73757 5.26257 7.50007C5.26257 6.26257 6.26257 5.26257 7.50007 5.26257C8.73757 5.26257 9.73757 6.26257 9.73757 7.50007C9.73757 8.73757 8.73757 9.73757 7.50007 9.73757Z" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.1938 9.1187C13.7563 8.23745 13.7563 6.7562 13.1938 5.87495C11.7625 3.62495 9.70627 2.32495 7.50002 2.32495C5.29377 2.32495 3.23752 3.62495 1.80627 5.87495C1.24377 6.7562 1.24377 8.23745 1.80627 9.1187C3.23752 11.3687 5.29377 12.6687 7.50002 12.6687C9.70627 12.6687 11.7625 11.3687 13.1938 9.1187Z" stroke="#505968" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


const status = {
  upcoming: 'upcoming',
  notattempted: 'not-attempted',
  cancelled: 'cancelled',
  label: 'Cancelled',
  completed: 'completed',
  inprogress: 'in-progress',
  empty: 'empty',
  waiting: 'waiting',
};

const Appointments = (props) => {
  const {
    getAppointments,
    // clearData,
    appointmentList,
    clearDataExceptFilter,
    searchData,
    applySearchData,
    perPage,
    // currentPage,
    SetPerPage,
    // SetCurrentPage,
    handleViewModeChange,
    startAppointment,
    ACCESS: permission,
    columnSort,
    setColumnSort,
  } = props;
  const intl = useIntl();
  const history = useHistory();

  // appointment list handle
  const [appointments, setAppointments] = useState([]);
  // filter details
  const [search, setSearch] = useState('');
  const [isLoading, updateLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedStatus, SetSelectedStatus] = useState();

  // pagination handle
  const [currentPage, SetCurrentPage] = useState(1);
  // const [perPage, SetPerPage] = useState(10);
  const [totalCount, SetTotalCount] = useState(null);
  // handle conflicting appointments
  const [showConflict, setShowConflict] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const isApplied = (
    (searchData?.search !== '' && searchData?.search) ||
    (searchData?.startDate !== '' && searchData?.startDate) ||
    (searchData?.endDate !== '' && searchData?.endDate) ||
    (searchData?.selectedStatus &&
      searchData?.selectedStatus !== [] &&
      searchData?.selectedStatus?.value !== undefined) ||
      searchData?.selectedStatus === null
  )

  // get appointmeentList
  const fetchAppointments = ({
    search: searchWord,
    appStatus,
    pageNo,
    perPageCount,
    start_Date,
    end_Date,
  } = {}) => {
    updateLoading(true);
    const sortKey = Object.keys(columnSort)[0];
    const config = {
      patient_search:
        searchWord || searchWord === ''
          ? searchWord || undefined
          : searchData?.search
          ? searchData?.search
          : undefined,
      start_date:
        start_Date || start_Date === ''
          ? start_Date || undefined
          : searchData?.startDate
          ? searchData?.startDate
          : undefined,
      end_date:
        end_Date || end_Date === ''
          ? end_Date || undefined
          : searchData?.endDate
          ? searchData?.endDate
          : undefined,
      status:
        appStatus || appStatus === ''
          ? appStatus || undefined
          : searchData?.selectedStatus?.value
          ? searchData?.selectedStatus?.value
          : undefined,
      page: pageNo || currentPage,
      limit: perPageCount || perPage,
      acl: true,
      sort: sortKey
        ? columnSort[sortKey] === 'asc'
          ? sortKey
          : `-${sortKey}`
        : undefined,
    };
    getAppointments(config,updateLoading);
  };

  useEffect(() => {
    setSearch(searchData?.search ?? '');
    setStartDate(searchData?.startDate ?? '');
    setEndDate(searchData?.endDate ?? '');
    SetSelectedStatus(searchData?.selectedStatus ?? '');
    fetchAppointments({
      search: searchData?.search,
      appStatus: searchData?.selectedStatus?.value
        ? searchData?.selectedStatus?.value
        : [],
      start_Date: searchData?.startDate,
      end_Date: searchData?.endDate,
    });
    return () => clearDataExceptFilter();
  }, []);

  const prevSortVal = usePrevious(columnSort);

  useDidMountEffect(() => {
    if (!isEqual(prevSortVal, columnSort)) {
      fetchAppointments({
        searchName: searchData?.searchPatient,
        search: searchData?.search,
        appStatus: searchData?.selectedStatus?.value,
        start_Date: searchData?.startDate,
        end_Date: searchData?.endDate,
      });
    }
  }, [columnSort]);

  // handle filter apply
  const handleApplyClick = () => {
    let search_data = {
      ...searchData,
      search,
      startDate,
      endDate,
      date: startDate ? new Date(startDate) : timeZoneDate(),
      selectedStatus,
    };
    setOpenFilter(false)
    applySearchData(search_data);
    if (
      (search !== '' && search) ||
      (startDate !== '' && startDate) ||
      (endDate !== '' && endDate) ||
      (selectedStatus &&
        selectedStatus !== [] &&
        selectedStatus.value !== undefined) ||
      selectedStatus === null ||
      isApplied
    ) {
      fetchAppointments({
        pageNo: 1,
        search: search,
        appStatus: selectedStatus === null ? '' : selectedStatus.value,
        start_Date: startDate,
        end_Date: endDate,
      });
      SetCurrentPage(1);
    }
  };

  const handleKeyPress = (e) => {
    if (e && e.key === 'Enter') {
      if (search || selectedStatus || startDate || endDate) {
        handleApplyClick();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [search, selectedStatus, startDate, endDate]);

  useEffect(() => {
    if (appointmentList?.appointments) {
      setAppointments(appointmentList.appointments);
      SetTotalCount(appointmentList?.page_details?.total_documents);
    } else {
      setAppointments([]);
      SetTotalCount(0);
    }
  }, [appointmentList]);

  //handle filter reset
  const handleReset = () => {
    applySearchData({
      ...searchData,
      search: '',
      startDate: '',
      endDate: '',
      selectedStatus: '',
      date: timeZoneDate(),
    });
    setOpenFilter(false)
    setColumnSort({});
    setStartDate('');
    setEndDate('');
    SetSelectedStatus('');
    setSearch('');
    SetPerPage(25);
    SetCurrentPage(1);
    if (
      search ||
      startDate ||
      endDate ||
      selectedStatus?.length !== 0 ||
      currentPage !== 1 ||
      perPage !== 25 ||
      isApplied
    ) {
      fetchAppointments({
        search: '',
        appStatus: '',
        start_Date: '',
        end_Date: '',
        pageNo: 1,
        perPageCount: 25,
      });
    }
  };

  // handle search value change
  const onChangeSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  // on pagination change
  const handleCurrentPageChange = (pageNo) => {
    SetCurrentPage(pageNo);
    fetchAppointments({ pageNo });
  };

  // handel perPage change
  const handlePerPageChange = (count) => {
    SetPerPage(count);
    SetCurrentPage(1);
    fetchAppointments({ pageNo: 1, perPageCount: count });
  };
  // to Handle Change Date
  const HandleChangeDate = (e) => {
    if (e) {
      setStartDate(formatDate(e[0]) ?? '');
      setEndDate(formatDate(e[1]) ?? '');
    } else {
      setStartDate('');
      setEndDate('');
    }
  };

  // handle start appointment by completing previous ongoing one
  const handleProceed = () => {
    let payload = { id: showConflict, auto_complete: true };
    startAppointment(payload, () => {
      history.push(`/admin/appointments/patientview/${showConflict}`);
      setShowConflict('');
    });
  };

  // hanlde cancel on conflict
  const hanldeCancel = () => {
    setShowConflict('');
  };

  // handle error when doctor try to start an appointment while another is inprogress
  const handleInprogressAppointment = (code, payload) => {
    if (code === 409) {
      setShowConflict(payload.id);
    }
  };

  // handle appointment start
  const handleAppointmnentStart = async (item) => {
    let payload = { id: item?.appointment_id };
    startAppointment(
      payload,
      () => {
        history.push(`/admin/appointments/patientview/${item.appointment_id}`);
      },
      handleInprogressAppointment
    );
  };

  // handle close of the popup
  const onClose = (id) => {
    const element = document.getElementById(`appointmentView-${id}`);
    const element1 = document.getElementById(`appointmentViewToggle-${id}`);
    element?.classList?.remove('show');
    element1?.classList?.remove('show');
  };

  // handle outside close of filter
  const handleOutsideClose = () => {
    setSearch(searchData?.search ?? '');
    setStartDate(searchData?.startDate ?? '');
    setEndDate(searchData?.endDate ?? '');
    SetSelectedStatus(searchData?.selectedStatus ?? '');
  }

  return (
    <>
      <div>
          <HeaderFields
            totalCount={totalCount}
            onApply={handleApplyClick}
            onReset={handleReset}
            childBreakPt={11}
            btnBreakPt={1}
            showFilter={openFilter}
            setShowFilter={setOpenFilter}
            handleViewModeChange={handleViewModeChange}
            isApplied={isApplied}
            handleOutsideClose={handleOutsideClose}
          >
            <div className="col-md-12 mb-md-3">
              <div className="form-group mb-0">
                <label htmlFor="">Patient</label>
                <input
                  type="text"
                  className="form-control down-arrow"
                  placeholder={intl.formatMessage({
                    id: 'common.search',
                    defaultMessage: 'Search',
                  })}
                  autoComplete="off"
                  value={search}
                  maxLength="40"
                  onChange={onChangeSearch}
                />
              </div>
            </div>
            <div className="col-md-6 pe-md-0">
              <div className="form-group mb-0">
              <label htmlFor="">Date</label>
                <AntMultiDatePicker
                  start={startDate}
                  end={endDate}
                  sx={{
                    padding: '8px',
                  }}
                  onchangeDate={HandleChangeDate}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-0">
              <label htmlFor="">{translate('common.status', 'Status')}</label>
                <ReactSelectDropDown
                  options={APPOINTMENT_STATUS_OPTIONS_ALL || []}
                  id="status"
                  // label={intl.formatMessage({
                  //   id: 'common.status',
                  //   defaultMessage: 'Status',
                  // })}
                  onInputChange={(item) => {
                    SetSelectedStatus(item);
                  }}
                  addValid_class={true}
                  required={true}
                  value={selectedStatus}
                  isClearable
                  isCasePlaceholder={true}
                />
              </div>
            </div>
          </HeaderFields>
        </div>
      <TableWrapper data={appointments}>
        <div className="list-user" style={{minHeight: isLoading ? '55vh' : '', overflow: !appointments?.length ? 'hidden' : ''}}>
          {appointments && appointments.length&&!isLoading ? (
            <table className="table departments-calendar appt-table">
              <thead>
                <tr>
                  {DOCTOR_APPOINTMENT_HEADERS.map((header, index) => (
                    <TableColumnHr
                      item={header}
                      key={index}
                      columnSort={columnSort}
                      setColumnSort={setColumnSort}
                      sortEnable={appointments.length > 1 ? true : false}
                    />
                  ))}
                </tr>
              </thead>
                <tbody>
                  {appointments.map((appointment, index) => (
                    <tr key={index + 1}>
                      <td>{appointment.appointment_id}</td>
                      <td className='text-capitalize'>{appointment.patient_name}</td>
                      <td className="text-capitalize">{appointment.dept_name}</td>
                      <td>{`${dateinFormatDDMMMYYYY(
                        appointment.appt_date
                      )}  ${appendTime(appointment.appt_start_time)}`}</td>
                      <td>{appointment?.schedule_type?.toUpperCase()}</td>
                      <td>
                        <div className="d-flex gap-1">
                        <div className="status-badge">
                        <span class={`badge-dot ${appointment.live_status}`}></span>
                        {APPOINTMENT_STATUS_NAMES[appointment.live_status]}
                        </div>
                        </div>
                      </td>
                      <td >
                      <div className='action-btns v2'>
                      {(appointment.live_status ===
                          APPOINTMENT_STATUS.upcoming ||
                          appointment.live_status ===
                            APPOINTMENT_STATUS.notAttempted ||
                          appointment.live_status ===
                            APPOINTMENT_STATUS.waiting) &&
                        formatDate(appointment.appt_date) ===
                        formatDate(timeZoneDate()) ? (
                          <Tooltip
                            title={intl.formatMessage({
                              id: 'appointments.startAppt',
                              defaultMessage: 'Start Appointment',
                            })}
                            placement="top"
                          >
                            <span className='start-icon cursor-pointer' onClick={() => handleAppointmnentStart(appointment)}>
                              {startIcon}
                            </span>
                          </Tooltip>
                        ) : (
                          <Link
                            to={{
                              pathname: `/admin/appointments/patientview/${appointment.appointment_id}`,
                            }}
                          >
                            {permission(READ_DETAILS) && (
                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'appointments.viewAppt',
                                  defaultMessage: 'View Appointment',
                                })}
                                placement="top"
                              >
                                <span className='view-icon '>
                                  {viewIcon}
                              </span>
                              </Tooltip>
                            )}
                          </Link>
                        )}
                        <span className="data-wrapper">
                        
                      <span className='more-icon cursor-pointer' data-bs-toggle='dropdown'
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                          id={`appointmentView-${appointment.appointment_id}`}>
                            <Tooltip
                            title={translate(
                              'patientView.apptDetails',
                              'Appointment Details'
                            )}
                            placement="left"
                        >
                          {moreIcon}
                        </Tooltip>
                        </span>
                        <ul
                              data-popper-placement="bottom-end"
                              className="dropdown-menu popupDoctorViewday custom-popover v2"
                              id={`appointmentViewToggle-${appointment.appointment_id}`}
                            >
                              <div className="popover-header mb-3">
                                <div className='header-id'>
                                  <h6>
                                    {translate(
                                      'patientView.apptDetails',
                                      'Appointment Details'
                                    )}
                                  </h6>
                                  <div className="d-flex gap-2 align-items-center">
                                    <span> <p className='id'>{appointment?.appointment_id}</p></span>
                                    <span className={`status ${status[appointment?.live_status]}`}>
                                      <div className="dot">•</div> 
                                      {
                                      APPOINTMENT_STATUS_NAMES[
                                        appointment.live_status
                                      ]
                                      }
                                    </span>
                                  </div>
                                </div>
                                <span className="d-flex align-items-center gap-1">
                                  {/*  No permission for docor to edit appointment
                                <button className="btn-transparent">
                                  <i className="bi bi-pencil"></i>
                                </button> */}
                                  <button
                                    className="btn-transparent"
                                    onClick={() =>
                                      onClose(appointment.appointment_id)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-x-lg"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                    </svg>
                                  </button>
                                </span>
                              </div>
                              <div className="popover-body">
                                <div className="icon-block mb-3">
                                  <i className="bi bi-person"></i>
                                  <div>
                                    <span>
                                      {translate(
                                        'appointments.patientName',
                                        'Patient Name'
                                      )}
                                    </span>
                                    <p className="mb-0 mt-2">
                                      {appointment.patient_name}
                                    </p>
                                  </div>
                                </div>
                                {appointment.patient_email &&
                                  <div className="icon-block mb-3 me-4 bordred-top-bottom">
                                    <img src={envelope} alt="" />
                                    <div className="mt-1">
                                      <span>{translate('common.emailId', 'Email Address')}</span>
                                      <p className="mb-0 mt-2 mail-text">{appointment.patient_email}</p>
                                    </div>
                                  </div>
                                }
                                <div className="items-set">
                                <div className="icon-block">
                                  <i className="bi bi-clock"></i>
                                  <div>
                                  <span>Date & Time</span>
                                    <p className="mb-0 mt-2">
                                      {formatDateto_D_WD_M_Y(
                                        new Date(appointment.appt_date),
                                        // language_Code
                                      )}
                                    </p>
                                    <p className="mb-0 mt-2">
                                      {appendTime(appointment.appt_start_time)} -{' '}
                                      {appendTime(appointment.appt_end_time)}
                                    </p>
                                  </div>
                                </div>
                                {appointment?.patient_tele_country_code &&
                                  <div className="icon-block mb-3">
                                    <img src={telephone} alt="" />
                                    <div className="mt-1">
                                      <span>{translate('users.phone', 'Phone Number')}</span>
                                      <p className="mb-0 mt-2">+{appointment?.patient_tele_country_code}{appointment.patient_phone}</p>
                                    </div>
                                  </div>
                                }
                              </div>
                              </div>
                              <div className="popover-footer">
                                <div className="icon-block">
                                  <i className="bi bi-justify-left"></i>
                                  <div>
                                    <span>
                                      {translate(
                                        'patientView.reason',
                                        'Reason For Appointment'
                                      )}
                                    </span>
                                    <p className="mb-0 mt-2 first-capital">{appointment.reason}</p>
                                  </div>
                                </div>
                              </div>
                            </ul>
                            <div className="overlay"></div>
                      </span>
                      </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
            </table>
          ) : (!isLoading?
              <div className='patient-portal'>
                <NoResultFound
                  text={isApplied ? translate('common.noResult', 'No results found.') :
                    translate("appointments.noDataAppointments", "No appointments added")}
                  label={isApplied ? translate('common.noResultMsg', 'No results match the filter criteria Remove filter or clear all filters to show results') :
                    translate('appointments.noDataAppointmentsMsg', `Looks like you haven't added any appointments yet.`)}
                  removeMarginBottom
                  removeMarginTop
                />
              </div>
            :<Loader/>
          )}
        </div>
      </TableWrapper>
      {!isLoading && appointments && appointments.length ? (
        <Pagination
          SetCurrentPage={handleCurrentPageChange}
          SetPerPage={handlePerPageChange}
          perPage={parseInt(perPage)}
          totalCount={totalCount}
          currentPage={currentPage}
        />
      ) : null}
      {showConflict ? (
        <ConflictConform onConfirm={handleProceed} onClose={hanldeCancel} />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  appointmentList: state.AppointmentReducer.appointmentList,
  searchData: state.AppointmentReducer.doctorSearchData,
  currentPage: state.AppointmentReducer.doctorPaginationData.currentPage,
  perPage: state.AppointmentReducer.doctorPaginationData.perPage,
  columnSort: state.AppointmentReducer.columnSort,
});

const mapDispatchToProps = (dispatch) => ({
  getAppointments: (data,setLoading) => dispatch(FetchAppointmentList(data,setLoading)),
  clearData: () => dispatch(clearAppointmentReducer()),
  clearDataExceptFilter: () => dispatch(clearDoctorAppointmentExceptFilter()),
  applySearchData: (data) => dispatch(updateDoctorSearchData(data)),
  SetCurrentPage: (data) => dispatch(updateCurrentPageDoctor(data)),
  SetPerPage: (data) => dispatch(updatePerPageDoctor(data)),
  startAppointment: (data, onSuccess, onError) =>
    dispatch(startAppointment(data, onSuccess, onError)),
  setColumnSort: (data) => dispatch(updateColumnSort(data)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissionCheck(APPOINTMENT)
)(Appointments);
