import { CLEAR_LINKED_SEARCH_NAME } from 'Actions/patientProfileAction';
import {
  RECEIVE_PRESCRIPTIONS_LIST,
  CLEAR_PRESCRIPTIONS_LIST,
  ADD_FILTER_PRESCRIPTIONS,
} from '../Actions/prescriptions';

const initialState = {
  prescriptionsList: {},
  filterOptions: {},
};

const PrescriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PRESCRIPTIONS_LIST: {
      return {
        ...state,
        prescriptionsList: action.payload,
      };
    }
    case ADD_FILTER_PRESCRIPTIONS: {
      return {
        ...state,
        filterOptions: action.payload,
      };
    }
    case CLEAR_PRESCRIPTIONS_LIST: {
      return {
        ...state,
        prescriptionsList: {},
      };
    }
    case CLEAR_LINKED_SEARCH_NAME: {
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          searchName:
            state?.filterOptions?.searchName?.value === action.payload
              ? {}
              : state?.filterOptions?.searchName,
        },
      };
    }

    default:
      return state;
  }
};

export default PrescriptionsReducer;
