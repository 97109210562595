import network from '../Utils/Api/Network';
import { profileBtnLoading as feedBackLoading } from './patientProfileAction';
import { showToaster, ToasterTypes } from './toasterAction';

export const fetchContactInformation = (setBranches = () => {}) => {
  return () => {
    return network.get({ url: '/branch' }).then((response) => {
      const { isError, result } = response;
      if (!isError && result.branches) {
        setBranches(result.branches);
      } else {
        setBranches([]);
      }
    });
  };
};

export const postFeedback = (data, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch(feedBackLoading(true))
    return network.post({ url: '/contact_us', data }).then((response) => {
      dispatch(feedBackLoading(false))
      const { isError, message } = response;
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        onSuccess();
        dispatch(showToaster(message, ToasterTypes.Success, 6000));
      }
    });
  };
};
