import { translate } from 'Language/Translate'
import React, { useEffect, useRef } from 'react'
import { appendTime,slashFormatinDDMMYYYY } from 'Utils/DateFormatter/Date'
import cancelIcon from '../../../Assets/img/icons/appointment-cancelled-successfully.svg'
const AppointmentCancelledModal = (props) => {
    const modalRef = useRef()
    const {
        open,
        onClose,
        data
    } = props
    const btnRef = useRef()

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                btnRef?.current?.focus()
                document.body.classList.add('modal-overflow');
            }, 100);
        } else {
            document.body.classList.remove('modal-overflow');
        }
        return () => document.body.classList.remove('modal-overflow');
    }, [open]);

    useEffect(() => {
        const listener = (event) => {
            if (!modalRef.current || modalRef.current.contains(event.target)) {
                return;
            }
            if (event.button === 0) onClose();
        };
        document.addEventListener('mousedown', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [modalRef, onClose]);

    return (
        <>
            <div className={`offcanvas-backdrop fade ${open && 'show'}`}></div>
            <div className={`offcanvas offcanvas-end custom-off-canvas cancel-successful-canvas ${open && 'show'}`}
                ref={modalRef}
                tabIndex="-1"
                id="cancelSuccess"
                aria-labelledby="viewScheduleLabel">
                <div className="offcanvas-header">
                    <h5>
                        {translate(
                            'cancelAppt.cancelappt',
                            'Cancel Appointment'
                        )}
                    </h5>
                    <button
                        type="button"
                        onClick={onClose}
                        className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="success-content">
                        <img className="d-block mx-auto" src={cancelIcon} alt="" />
                        <h6>{translate(
                            'cancelAppt.cancelSuccessfully',
                            'Appointment cancelled successfully!'
                        )}</h6>
                        <p>
                           {translate('cancelAppt.apptwithbookid','Appointment with booking id')} {data.appointment_id} {translate('cancelAppt.bookforDr','booked for Dr.')}{" "}{data.doctor_name} { translate('cancelAppt.cancelon','on')} {slashFormatinDDMMYYYY(data.appt_date)} { translate('cancelAppt.cancelat','at')} {appendTime(data.appt_start_time)} { translate('cancelAppt.cancelsuccessfully','has been cancelled successfully.')}
                        </p>
                        <button
                        onKeyDown={(e)=>{if(e.key === "Tab")e.preventDefault()}}
                        ref={btnRef}
                            onClick={onClose}
                            className="btn btn-secondary">{translate(
                                'cancelAppt.backToAppt',
                                'Back to listing.'
                            )}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppointmentCancelledModal
