import React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import '../../Assets/style/table.css';

const StyledSwitch = styled(Switch)(() => ({
  marginRight: '0px !important',
  width: 56,
  height: 36,
  padding: 8,

  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      left: 12,
    },
    '&:after': {
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 14,
    height: 14,
    margin: 2,
  },
  '& .MuiSwitch-switchBase:hover': {
    backgroundColor: 'transparent',
  },
  // '& .Mui-disabled:hover': {
  //   cursor: "not-allowed",
  // },
  // '& :hover': {
  //    cursor: 'pointer',
  // },
}));

const CustomSwitch = (props) => {
  const { checkedProps, handleSwitchChange, disabled=false } = props;

  const handleChange = (e) => {
    const { checked } = e.target;
    handleSwitchChange(checked);
  };

  return (
    <StyledSwitch
      checked={checkedProps}
      onChange={handleChange}
      disabled={disabled}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          e.target.checked = !e.target.checked;
          handleSwitchChange(e.target.checked);
          document.getElementById('op')?.focus();
        }
      }}
    />
  );
};

export default CustomSwitch;
