import { CLEAR_LINKED_SEARCH_NAME } from 'Actions/patientProfileAction';
import {
  SET_FILTER_OPTIONS,
  ADD_MEDICAL_RECORD_LOADING,
  CLEAR_ALL_MEDICAL_RECORD,
  LINKED_PROFILE_LOADING,
  SET_MEDICAL_RECORD_COLUMN_SORT
} from '../Actions/medicalRecordAction';

const initialState = {
  filterOptions: {},
  loadingAddRecord: false,
  linkedProfileLoading: false,
  columnSort:{},
};

const MedicalRecordReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_OPTIONS: {
      return {
        ...state,
        filterOptions: action.payload,
      };
    }
    case ADD_MEDICAL_RECORD_LOADING: {
      return {
        ...state,
        loadingAddRecord: action.payload,
      };
    }
    case LINKED_PROFILE_LOADING: {
      return {
        ...state,
        linkedProfileLoading: action.payload,
      };
    }
    case CLEAR_ALL_MEDICAL_RECORD: {
      return {
        ...initialState,
        filterOptions: state?.filterOptions,
        columnSort: state?.columnSort,
      };
    }
    case CLEAR_LINKED_SEARCH_NAME: {
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          searchName:
            state?.filterOptions?.searchName?.value === action.payload
              ? {}
              : state?.filterOptions?.searchName,
        },
      };
    }
    case SET_MEDICAL_RECORD_COLUMN_SORT: {
      return {
        ...state,
        columnSort: action.payload,
      };
    }
    default:
      return state;
  }
};

export default MedicalRecordReducer;
