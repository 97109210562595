import React, { useEffect, useRef, useState } from 'react';
// import ReactSelectDropDown from '../Common/ReactSelectDropDown';
import { connect } from 'react-redux';
import { translate } from '../../Language/Translate';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const AddDepartment = (props) => {
  const {
    department_status,
    department_name,
    updateDepartment_name,
    updateDepartment_desc,
    updatedepartment_status,
    validationErr,
    departmentSuggestionList,
    // doctorList,
    updateValidationErr,
    intl,
    disableInactive = false
  } = props;

  const [deptSuggestion, setDeptSuggestion] = useState([]);
  const [tabPress, setTabPress] = useState(false);
  const docNameRef = useRef();
  const deptUlRef = useRef();

  // handle department name selection
  const handleDepartmentNameChange = (value) => {
    docNameRef.current.focus()
    setDeptSuggestion([])
    updateDepartment_name(value);
    updateValidationErr(false);
  };

  // handle department name typing
  const handleDepartmentNameType = (event) => {
    const { value } = event.target;
    updateValidationErr(false);
    updateDepartment_name(value);
    const filterdList = departmentSuggestionList.filter(
      (item) => value?.trim() && item.value.toLowerCase().includes(value?.trim().toLowerCase())
    );
    setDeptSuggestion(filterdList);
  };

  //closing deptment suggestion on outside click
  const handleClickOutside = (event) => {
    if (deptSuggestion?.length && deptUlRef.current && !deptUlRef.current.contains(event.target)) {
     setDeptSuggestion([])
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deptSuggestion]);

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-12">
            <div className={validationErr ? 'form-group error' : 'form-group'}>
              <label htmlFor="User name">
                {translate('departments.deptName', 'Department Name')}
                <span className="sup"> *</span>
              </label>
              <input
                type="text"
                maxLength={50}
                ref={docNameRef}
                className="form-control down-arrow"
                onChange={handleDepartmentNameType}
                placeholder={intl.formatMessage({
                  id: 'departments.department',
                  defaultMessage: 'Department',
                })}
                value={department_name}
                autoComplete="off"
                onKeyDown={(e) => {
                  if (deptSuggestion.length && e.key === "Tab" && !e.shiftKey){ 
                    setTabPress(true)
                  }else if(deptSuggestion.length && e.key === "ArrowDown"  && !e.shiftKey) { //condition for option starting selectiong on arrow down                     
                    setTabPress(true)
                    let listItems = document.querySelectorAll('#department-suggestion-ul li')
                    setTimeout(()=>{ listItems[0].focus()},[])
                  }
                  else{ setTabPress(false)}
                }}
                onBlur={() =>  setTimeout(() =>{ if (!tabPress) setDeptSuggestion([]) }, 400)}
                // onKeyDown={(e)=>{
                //   if(e.key === "Tab" && e.shiftKey) e.preventDefault()
                // }}
                autoFocus
              />
              {deptSuggestion.length ? (
                <div className="customSearchList">
                  {/* keydown for option change on arrow up and down */}
                  <ul style={{ marginBottom: 1 }} id='department-suggestion-ul' ref={deptUlRef} onKeyDown={(e)=>{
                    if(e.key==="ArrowDown"){
                      let focusedItem=document.activeElement;
                      let listItems=document.querySelectorAll('#department-suggestion-ul li')
                      for(let i=0;i<listItems.length;i++){
                        if(listItems[i]===focusedItem&&i<listItems.length-1){
                          listItems[i+1].focus()
                          break;
                        }
                      }
                    }
                    if(e.key==="ArrowUp"){
                      let focusedItem=document.activeElement;
                      let listItems=document.querySelectorAll('#department-suggestion-ul li')
                      for(let i=0;i<listItems.length;i++){
                        if(listItems[i]===focusedItem && i>0){
                          listItems[i-1].focus()
                          break;
                        }
                      }
                    }
                  }}>
                    {deptSuggestion.map((dept, index) => (
                      <li
                        key={index}
                        className="suggestion-item"
                        onClick={() => handleDepartmentNameChange(dept.value)}
                        tabIndex={deptSuggestion.length ? 0 : -1}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            handleDepartmentNameChange(dept.value)
                            docNameRef.current.focus()
                            setDeptSuggestion([])
                          }
                        }}
                      >
                        {dept.label}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {validationErr && (
                <span style={{ color: 'red', fontSize: 11 }}>
                  {translate('common.required', 'This field is required')}
                </span>
              )}
            </div>
          </div>

          <div className="col-md-12">
            <div className={'form-group'}>
              <label htmlFor="User name">
                {translate(
                  'departments.deptDescription',
                  'Department Description'
                )}
              </label>
              <div className="text-area-wrap">
              <textarea
              tabIndex={0}
              onFocus={()=>{setDeptSuggestion([])}}
                type="text"
                className="form-control input"
                id="departmentname"
                rows="4"
                maxLength="200"
                autoComplete="off"
                placeholder='Enter Description'
                onChange={(e) => updateDepartment_desc(e.target.value)}
              />
            </div>  

            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-0">
              <label htmlFor="User name">
                {translate('common.status', 'Status')}
              </label>
              <div className="switchWrap">
                        <RadioGroup
                          row
                          value={department_status ? 'active' : 'inactive'}
                          onChange={(e) =>
                            updatedepartment_status(e.target.value === 'active')
                          }
                        >
                          <FormControlLabel
                            value="active"
                            control={<Radio />}
                            label={translate('common.active', 'Active')}
                          />
                          <FormControlLabel
                            value="inactive"
                            disabled={disableInactive}
                            control={<Radio />}
                            label={translate('common.inactive', 'Inactive')}
                          />
                        </RadioGroup>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <div className="border_btm"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  departmentSuggestionList: state.DepartmentReducer.departmentSuggestionList,
  doctorList: state.DepartmentReducer.doctorList,
});

export default connect(mapStateToProps, null)(AddDepartment);
