import React, { useEffect, useRef, useState } from 'react';
import '../../Assets/style/modal.css';
import CloseIcon from '@mui/icons-material/Close';
import { translate } from '../../Language/Translate';
import ButtonLoader from './ButtonLoader';
const PopupWrapeer = (props) => {
  const {
    modalContent,
    onClose,
    title,
    modalContentarea,
    children,
    hideBtn,
    onSubmit,
    saveBtn,
    cancelBtn = true,
    isBtnLoading = false,
    autoFocus=false,
    additionalClass = ''
  } = props;
  const [closeBtnFocus ,setCloseBtnFocus] = useState(false);
  const closeBtnRef = useRef(null);
  useEffect(() => {
    document.body.classList.add('modal-overflow');
    return () => document.body.classList.remove('modal-overflow');
  }, []);

  useEffect(()=>{
    const listener =(event)=>{
      if(event.key==="Tab" && !event.shiftKey && autoFocus){
        if(!closeBtnFocus){
          closeBtnRef?.current?.focus();
          setCloseBtnFocus(true)
        }
      }
      if(event.key==="Tab" && event.shiftKey && autoFocus && !closeBtnFocus){
        event.preventDefault()
      }
    }
    document.addEventListener('keydown', listener);
    return ()=> document.removeEventListener('keydown', listener);

  },[closeBtnFocus,closeBtnRef])

  return (
    <>
      <div className="AppointmentModal d-flex">
        <div className={`${modalContent ? modalContent : 'modalContent'} ${additionalClass}`}>
          <div className="modalHeader">
              <div className="row">
                <div className="col-md-12 d-flex align-items-center justify-content-between pb-3" >
                  <h4 className="border_btm popover-title">{title}</h4>
                  <CloseIcon className='close-icon'
                    style={{ float: 'right', cursor: 'pointer', color: '#75808E', position:'relative', left: 5 }}
                    tabIndex='0'
                    ref={closeBtnRef}
                    // className='postition-close-icon'
                    onClick={onClose}
                    onKeyDown={(e)=>{
                      if(e.key === "Tab" && e.shiftKey)e.preventDefault()
                      if(e.key === "Enter" && !e.shiftKey) onClose()
                    }}
                  
                  />
                </div>
                <div
                  className={
                    modalContentarea ? modalContentarea : 'modalContentarea negative-top'
                  }
                >
                  {children}
                </div>
                <div className="">
                    {!hideBtn ? (
                      <ul className="admin-modal-footer">
                        {cancelBtn && <li onClick={onClose} className='ms-0 d-block d-sm-inline'>
                          <button
                            type="button"
                            className="btn btn-light mb-0"
                            id="cancel"
                            onKeyDown={(e)=>{
                              if(e.key === "Tab" && !e.shiftKey && !onSubmit) e.preventDefault()
                            }}
                          >
                            {' '}
                            {translate('common.cancel', 'CANCEL')}{' '}
                          </button>
                        </li>}
                        {onSubmit && (
                          <li onClick={isBtnLoading ? () => { } : onSubmit} className='ms-0 d-block d-sm-inline ps-0'>
                            {' '}
                            <button
                              disabled={isBtnLoading}
                              type="button"
                              className="btn btn-primary mb-0 loader-btn"
                              id="save"
                              onKeyDown={(e)=>{
                                if(e.key === "Tab" && !e.shiftKey)
                                e.preventDefault()
                              }}
                            >
                              {' '}
                              {isBtnLoading ? <ButtonLoader text={saveBtn
                                ? saveBtn
                                : translate('common.save', 'SAVE')} />
                                : saveBtn
                                  ? saveBtn
                                  : translate('common.save', 'SAVE')}
                            </button>
                          </li>
                        )}
                      </ul>
                    ) : (
                      ''
                    )}
                  </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupWrapeer;
