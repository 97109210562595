export const generateCaptchText = () => {
  const captchaChars = 'ABCDEFGHJKLMNPQRSTUVWXYZadhnrt3456789';
  const captchaLength = 5;
  let captchaText = '';

  for (let i = 0; i < captchaLength; i++) {
    const randomIndex = Math.floor(Math.random() * captchaChars.length);
    captchaText += captchaChars.charAt(randomIndex);
  }

  return captchaText;
};

export const generateCaptchaImage = (captchaText) => {
  const captchaLength = captchaText.length;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const canvasWidth = 250;
  const canvasHeight = 40;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  // fill background with random dots
  for (let i = 0; i < 250; i++) {
    const x = Math.random() * canvasWidth;
    const y = Math.random() * canvasHeight;
    ctx.fillStyle = '#bbb';
    ctx.fillRect(x, y, 2, 2);
  }

  const fontSizeArray = [38, 33, 36, 34, 35, 33, 36, 32, 38, 31];

  // add each text in different font size
  for (let i = 0; i < captchaLength; i++) {
    const x = i * (canvasWidth / captchaLength - 10);
    const y = 30;
    const fontSize = fontSizeArray[i];

    ctx.font = `bold ${fontSize}px Shadows Into Light `;
    ctx.fillStyle = '#222';
    ctx.fillText(captchaText[i], x + 30, y);
  }

  const dataUrl = canvas.toDataURL('image/png');
  return dataUrl;
};
