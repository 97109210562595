import network from '../Utils/Api/Network';
// import React from 'react';
import { showToaster, ToasterTypes } from './toasterAction';
export const RECEIVE_SEARCH_DOCTOR_DETAILS = 'RECEIVE_SEARCH_DOCTOR_DETAILS';
export const RECEIVE_DOCTOR_DETAILS = 'RECEIVE_DOCTOR_DETAILS';
export const RECEIVE_DEPARTMENT_DATA = 'RECEIVE_DEPARTMENT_DATA';
export const CLEAR_SEARCH_DOCTOR_DATA = ' CLEAR_SEARCH_DOCTOR_DATA';
export const CLEAR_DOCTORS_DATA = 'CLEAR_DOCTORS_DATA';
export const RECEIVE_DOCTOR_SLOTS = 'RECEIVE_DOCTOR_SLOTS';
export const RECEIVE_PATIENTS_DATA = 'RECEIVE_PATIENTS_DATA';
export const CLEAR_PATIENT_DATA = 'CLEAR_PATIENT_DATA';
export const RECEIVE_APPOINTMENT_LIST = 'RECEIVE_APPOINTMENT_LIST';
export const RECEIVE_APPOINTMENT_LIST_ALL_DATES = 'RECEIVE_APPOINTMENT_LIST_ALL_DATES';
export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';
export const CLEAR_ALL_DATA_EXCEPT_FILTER =
  'CLEAR_ALL_DATA_EXCEPT_FILTER_APPOINTMENT';
export const CLEAR_DOCTOR_SLOTS = 'CLEAR_DOCTOR_SLOTS';
export const SET_DOCTOR_SEARCH_DATA = 'SET_DOCTOR_SEARCH_DATA';
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
export const CLEAR_DATA_EXCEPT_FILTER_DOCTOR =
  'CLEAR_DATA_EXCEPT_FILTER_DOCTOR';
export const SET_APPOINTMENT_PER_PAGE = 'SET_APPOINTMENT_PER_PAGE';
export const SET_APPOINTMENT_CURRENT_PAGE = 'SET_APPOINTMENT_CURRENT_PAGE';
export const SET_APPOINTMENT_PER_PAGE_DOCTOR =
  'SET_APPOINTMENT_PER_PAGE_DOCTOR';
export const SET_APPOINTMENT_CURRENT_PAGE_DOCTOR =
  'SET_APPOINTMENT_CURRENT_PAGE_DOCTOR';
export const RECEIVE_PATIENTS_DATA_LOADING = 'RECEIVE_PATIENTS_DATA_LOADING';
export const SET_APPOINTMENT_COLUMN_SORT = 'SET_APPOINTMENT_COLUMN_SORT';
export const UPDATE_EMAIL_ID = 'UPDATE_EMAIL_ID';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_SEARCH_APPOINTMENT = 'UPDATE_SEARCH_APPOINTMENT';
export const CLEAR_SEARCH_APPOINTMENT = 'CLEAR_SEARCH_APPOINTMENT';
export const RESET_SEARCH_APPOINTMENT = 'RESET_SEARCH_APPOINTMENT';
export const APPOINTMENT_BTN_LOADING = 'APPOINTMENT_BTN_LOADING';
const receiveDoctorDetails = (payload) => ({
  type: RECEIVE_DOCTOR_DETAILS,
  payload,
});

const receiveDepartmentData = (payload) => ({
  type: RECEIVE_DEPARTMENT_DATA,
  payload,
});

const receiveSlotsData = (payload) => ({
  type: RECEIVE_DOCTOR_SLOTS,
  payload,
});

const receivePatientData = (payload, isScroll) => ({
  type: RECEIVE_PATIENTS_DATA,
  payload,
  isScroll,
});

const receiveAppointmentList = (payload) => ({
  type: RECEIVE_APPOINTMENT_LIST,
  payload,
});

const receiveAppointmentListAllDates = (payload) => ({
  type: RECEIVE_APPOINTMENT_LIST_ALL_DATES,
  payload,
});

const updateEmailId = (payload) => ({
  type: UPDATE_EMAIL_ID,
  payload,
});

export const updateUserDetails = (payload) => ({
  type: UPDATE_USER_DETAILS,
  payload,
});

export const receivePatientDataLoading = (payload) => ({
  type: RECEIVE_PATIENTS_DATA_LOADING,
  payload
});

export const receiveSearchDoctorDetails = (payload) => ({
  type: RECEIVE_SEARCH_DOCTOR_DETAILS,
  payload,
});

export const clearDoctorDetails = () => ({
  type: CLEAR_DOCTORS_DATA,
});

export const clearSearchDoctorDetails = () => ({
  type: CLEAR_SEARCH_DOCTOR_DATA,
});

export const clearAppointmentReducer = () => ({
  type: CLEAR_ALL_DATA,
});

export const clearDoctorSlots = () => ({
  type: CLEAR_DOCTOR_SLOTS,
});
export const clearAppointmentExceptFilter = () => ({
  type: CLEAR_ALL_DATA_EXCEPT_FILTER,
});
export const clearDoctorAppointmentExceptFilter = () => ({
  type: CLEAR_DATA_EXCEPT_FILTER_DOCTOR,
});
export const updateSearchData = (payload) => ({
  type: SET_SEARCH_DATA,
  payload,
});
export const updateDoctorSearchData = (payload) => ({
  type: SET_DOCTOR_SEARCH_DATA,
  payload,
});
export const updateCurrentPage = (payload) => ({
  type: SET_APPOINTMENT_CURRENT_PAGE,
  payload,
});
export const updatePerPage = (payload) => ({
  type: SET_APPOINTMENT_PER_PAGE,
  payload,
});
export const updateCurrentPageDoctor = (payload) => ({
  type: SET_APPOINTMENT_CURRENT_PAGE_DOCTOR,
  payload,
});
export const updatePerPageDoctor = (payload) => ({
  type: SET_APPOINTMENT_PER_PAGE_DOCTOR,
  payload,
});
export const updateColumnSort = (payload) => ({
  type: SET_APPOINTMENT_COLUMN_SORT,
  payload,
});
export const updateSearchAppointment = (payload) => ({
  type: UPDATE_SEARCH_APPOINTMENT,
  payload,
});
export const clearSearchAppointment = () => ({
  type: CLEAR_SEARCH_APPOINTMENT,
});
export const resetSearchAppointment = () => ({
  type: RESET_SEARCH_APPOINTMENT,
});
export const appointmentBtnLoading = (payload) => ({
  type: APPOINTMENT_BTN_LOADING,
  payload,
});

export const getDoctorDetails = (id, data = {}, loader = () => {}) => {
  return (dispatch) => {
    return network.get({ url: `/doctor/${id}`, data }).then((response) => {
      const { isError, result, code } = response || {};
      if (isError) {
        dispatch(receiveDoctorDetails({code}));
        loader(false);
      } else {
        dispatch(receiveDoctorDetails(result));
        loader(false);
      }
    });
  };
};

export const FetchDepartmentData = (id, data, loader = () => {}) => {
  return (dispatch) => {
    return network
      .get({
        url: `/department/${id}`,
        data,
      })
      .then((response) => {
        const { result, isError } = response || {};
        if (!isError && result.department) {
          dispatch(receiveDepartmentData(result));
          loader(false);
        } else {
          dispatch(receiveDepartmentData({}));
          loader(false);
        }
      });
  };
};

export const FetchDoctorSlots = (id, data, setLoader = () => {},onSuccess=()=>{}) => {
  return (dispatch) => {
    dispatch(appointmentBtnLoading({ docSlots: true }));
    return network
      .get({
        url: `/appointment/doctor/${id}/slots`,
        data,
      })
      .then((response) => {
        onSuccess(response)
        dispatch(appointmentBtnLoading({ docSlots: false }));
        const { result, isError } = response || {};
        if (!isError && result) {
          dispatch(receiveSlotsData(result));
        } else {
          dispatch(receiveSlotsData({}));
        }
        setLoader(false);
      });
  };
};
export const startAppointment = (
  payload,
  onSuccess = () => {},
  onError = () => {}
) => {
  return (dispatch) => {
    dispatch(appointmentBtnLoading({ startAppt: true }));
    return network
      .put({
        url: `/appointment/${payload.id}/start?auto_complete=${payload.auto_complete}`,
      })
      .then((response) => {
        dispatch(appointmentBtnLoading({ startAppt: false }));
        const { result, isError, code } = response || {};
        if (!isError) {
          onSuccess(result);
        } else {
          onError(code, payload);
          dispatch(receiveSlotsData({}));
        }
      });
  };
};

export const FetchPatientsData = (data, setCount, isScroll = false) => {
  return (dispatch) => {
    let id = new Date().getTime();
    if (!isScroll) {
      dispatch(receivePatientDataLoading({ [id]: true }));
    }
    return network
    .get({
      url: '/patients',
      data,
    })
      .then((response) => {
        if (!isScroll) dispatch(receivePatientDataLoading({ [id]: false }));
        const { result, isError } = response || {};
        if (!isError && result?.patients) {
          dispatch(receivePatientData(result.patients, isScroll));
          setCount(result?.page_details?.total_pages);
        } else {
          dispatch(receivePatientData([], isScroll));
        }
      });
  };
};

export const AddAppointmentData = (
  data,
  handleSuccess = () => {},
  setAppId = () => {},
  modalMsg = ()=>{},
) => {
  return (dispatch) => {
    dispatch(appointmentBtnLoading({ addAppt: true }));
    return network.post({ url: '/appointment', data }).then((response) => {
      dispatch(appointmentBtnLoading({ addAppt: false }));
      const { isError, message, result, code } = response || {};
      if (isError) {
        setAppId(false);
        if (code == 426) {
          // dispatch(
          //   showToaster(
          //     <>
          //       <p>{message}</p>
          //       <a href={`${window.location.origin}/#/webconnect/contact-us`}>
          //         Click here to Contact US
          //       </a>
          //     </>,
          //     ToasterTypes.Failed
          //   )
          // );
          modalMsg(message)
        } else {
          dispatch(showToaster(message, ToasterTypes.Failed, 3000));
        }
      } else {
        handleSuccess();
        const { appointment } = result || {};
        const { appointment_id } = appointment || {};
        setAppId(appointment_id);
      }
    });
  };
};

export const updateEmail = (data, email) => {
  if (data.email === email) {
    return;
  } else {
    data.email = email;
    updateEmailId();
  }
};

export const ValidateMobileNumber = (
  data,
  mobileResponseHandler = () => {},
  isResend = false
) => {
  return (dispatch) => {
    if(!isResend) dispatch(appointmentBtnLoading({ validateMob: true }));
    return network.post({ url: '/patient/create', data }).then((response) => {
      dispatch(appointmentBtnLoading({ validateMob: false }));
      const { isError, message, result } = response || {};
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        dispatch(showToaster(message, ToasterTypes.Success));
        mobileResponseHandler(result);
      }
    });
  };
};

export const getPatientDetails = (
  id,
  callBack = () => {},
  errCallBack = () => {}
) => {
  return (dispatch) => {
    return network.get({ url: `/provider/user/${id}` }).then((response) => {
      const { isError, result } = response || {};
      dispatch(appointmentBtnLoading({ verifyOtpPatient: false }))
      if (isError) {
        errCallBack(response);
        // dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        callBack(result);
      }
    });
  };
};

export const VerifyToken = (id, data, otpResponseHandler = () => {}, keepLoading = false) => {
  return (dispatch) => {
    dispatch(appointmentBtnLoading({ verifyOtpPatient: true }))
    return network
      .post({ url: `/user/${id}/verify/phone`, data })
      .then((response) => {
        if (!keepLoading) dispatch(appointmentBtnLoading({ verifyOtpPatient: false }))
        const { isError, message } = response || {};
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          otpResponseHandler();
        }
      });
  };
};

export const FetchAppointmentList = (data, setLoader = () => {}) => {
  return (dispatch) => {
    dispatch(appointmentBtnLoading({fetchAppt:true}))
    return network.get({ url: '/appointments', data }).then((response) => {
      const { isError, result } = response;
      if (!isError && result?.appointments) {
        dispatch(receiveAppointmentList(result));
      } else {
        dispatch(receiveAppointmentList({}));
      }
      dispatch(appointmentBtnLoading({fetchAppt:false}))
      setLoader(false);

    });
  };
};

export const FetchAppointmentListALLDates = (data) => {
  return (dispatch) => {
    return network.get({ url: '/appointments', data }).then((response) => {
      const { isError, result } = response;
      if (!isError && result?.appointments) {
        dispatch(receiveAppointmentListAllDates(result));
      } else {
        dispatch(receiveAppointmentListAllDates({}));
      }
    });
  };
};

export const UpdateAppointment = (
  id,
  data,
  fetchAppointments,
  handleEditClose,
  show = true
) => {
  return (dispatch) => {
    dispatch(appointmentBtnLoading({ editAppt: true }));
    return network.put({ url: `/appointment/${id}`, data }).then((response) => {
      dispatch(appointmentBtnLoading({ editAppt: false }));
      const { isError, message, result } = response;
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        if (show) dispatch(showToaster(message, ToasterTypes.Success));
        const { appointment } = result || {};
        const { appointment_id } = appointment || {};
        fetchAppointments();
        handleEditClose(appointment_id);
      }
    });
  };
};

export const DeleteAppointment = (
  id,
  handleDeleteSuccess = () => {},
  updateLoading = () => {}
) => {
  return (dispatch) => {
    dispatch(appointmentBtnLoading({ delAppt: true }));
    return network.delete({ url: `/appointment/${id}` }).then((response) => {
      dispatch(appointmentBtnLoading({ delAppt: false }));
      const { isError, message } = response;
      updateLoading(false);
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        dispatch(showToaster(message, ToasterTypes.Success));
        handleDeleteSuccess();
      }
    });
  };
};

export const CancelAppointment = (
  id,
  data,
  fetchAppointments,
  onHandlecancelClose,
  troasterstatus = true
) => {
  return (dispatch) => {
    dispatch(appointmentBtnLoading({ cancelAppt: true }));
    return network
      .patch({ url: `/appointment/${id}/status`, data })
      .then((response) => {
        dispatch(appointmentBtnLoading({ cancelAppt: false }));
        const { isError, message } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          if (troasterstatus) {
            dispatch(showToaster(message, ToasterTypes.Success));
          }
          fetchAppointments();
          onHandlecancelClose();
        }
      });
  };
};

export const rescheduleAppointment = (id, data, handleSuccess = () => {}) => {
  return (dispatch) => {
    dispatch(appointmentBtnLoading({ rescheduleAppt: true }));
    return network.put({ url: `/appointment/${id}`, data }).then((response) => {
      dispatch(appointmentBtnLoading({ rescheduleAppt: false }));
      const { isError, message } = response;
      if (isError) {
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        dispatch(showToaster(message, ToasterTypes.Success));
        handleSuccess(response);
      }
    });
  };
};

export const CancelBulkAppointment = (data, successAction = () => {}) => {
  return (dispatch) => {
    dispatch(appointmentBtnLoading({ cancelBulkAppt: true }));
    return network
      .put({ url: `/appointment-cancel`, data })
      .then((response) => {
        dispatch(appointmentBtnLoading({ cancelBulkAppt: false }));
        const { isError, message } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          dispatch(showToaster(message, ToasterTypes.Success));
          successAction();
        }
      });
  };
};

export const NotifyPatients = (data, successAction = () => {}) => {
  return (dispatch) => {
    dispatch(appointmentBtnLoading({ notifyAppt: true }));
    return network
      .post({ url: `/appointment-notification`, data })
      .then((response) => {
        dispatch(appointmentBtnLoading({ notifyAppt: false }));
        const { isError, message } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          dispatch(showToaster(message, ToasterTypes.Success));
          successAction();
        }
      });
  };
};

export const paymentHandle = (id, data, success = () => {}) => {
  return (dispatch) => {
    dispatch(appointmentBtnLoading({ paymentAppt: true }));
    return network
      .put({
        url: `/appointment/${id}/payment`,
        data,
      })
      .then((res) => {
        dispatch(appointmentBtnLoading({ paymentAppt: false }));
        const { isError, message } = res;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          dispatch(showToaster(message, ToasterTypes.Success));
          success();
        }
      });
  };
};
