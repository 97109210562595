import { CLEAR_LINKED_SEARCH_NAME } from 'Actions/patientProfileAction';
import {
  CLEAR_ALL_DATA,
  CLEAR_DOCTORS_DATA,
  CLEAR_DOCTOR_SLOTS,
  CLEAR_PATIENT_DATA,
  CLEAR_SEARCH_DOCTOR_DATA,
  RECEIVE_SEARCH_DOCTOR_DETAILS,
  RECEIVE_APPOINTMENT_LIST,
  RECEIVE_DEPARTMENT_DATA,
  RECEIVE_DOCTOR_DETAILS,
  RECEIVE_DOCTOR_SLOTS,
  RECEIVE_PATIENTS_DATA,
  CLEAR_ALL_DATA_EXCEPT_FILTER,
  SET_SEARCH_DATA,
  SET_DOCTOR_SEARCH_DATA,
  CLEAR_DATA_EXCEPT_FILTER_DOCTOR,
  SET_APPOINTMENT_PER_PAGE,
  SET_APPOINTMENT_CURRENT_PAGE,
  SET_APPOINTMENT_PER_PAGE_DOCTOR,
  SET_APPOINTMENT_CURRENT_PAGE_DOCTOR,
  RECEIVE_PATIENTS_DATA_LOADING,
  SET_APPOINTMENT_COLUMN_SORT,
  UPDATE_SEARCH_APPOINTMENT,
  CLEAR_SEARCH_APPOINTMENT,
  RESET_SEARCH_APPOINTMENT,
  APPOINTMENT_BTN_LOADING,
  RECEIVE_APPOINTMENT_LIST_ALL_DATES
} from '../Actions/appointmentAction';
import {
  APPOINTMENT_PATIENT_DETAILS,
  RECEIVE_APPOINTMENT_LIST_STATUS,
} from '../Actions/prescriptions';

const initialState = {
  doctorDetails: {},
  selectedDepartmentData: {},
  doctorSlotData: {},
  patientsData: [],
  patientsDataLoading: false,
  patientsDataIsEmpty: false,
  appointmentList: {},
  searchDoctorDetails: {},
  appointmentDetails: {},
  appointmentViewList: {},
  searchData: {},
  doctorSearchData: {},
  searchAppointment: {},
  paginationData: {
    perPage: 25,
    currentPage: 1,
  },
  doctorPaginationData: {
    perPage: 25,
    currentPage: 1,
  },
  columnSort: {},
  isBtnLoading: {},
  allDatesAppointments:{}
};

const AppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_DOCTOR_DETAILS: {
      return {
        ...state,
        doctorDetails: action.payload,
      };
    }
    case RECEIVE_DEPARTMENT_DATA: {
      return {
        ...state,
        selectedDepartmentData: action.payload,
      };
    }
    case RECEIVE_DOCTOR_SLOTS: {
      return {
        ...state,
        doctorSlotData: action.payload,
      };
    }
    case RECEIVE_SEARCH_DOCTOR_DETAILS: {
      return {
        ...state,
        searchDoctorDetails: action.payload,
      };
    }
    case CLEAR_DOCTOR_SLOTS: {
      return {
        ...state,
        doctorSlotData: action.payload,
      };
    }
    case CLEAR_SEARCH_DOCTOR_DATA: {
      return {
        ...state,
        searchDoctorDetails: {},
      };
    }
    case CLEAR_DOCTORS_DATA: {
      return {
        ...state,
        doctorDetails: {},
        selectedDepartmentData: {},
      };
    }
    case RECEIVE_PATIENTS_DATA: {
      if (action.isScroll) {
        return {
          ...state,
          patientsData: [...state.patientsData, ...action.payload],
        };
      }
      return {
        ...state,
        patientsData: action.payload,
        patientsDataIsEmpty: action.payload?.length === 0 ? true : false,
      };
    }
    case RECEIVE_PATIENTS_DATA_LOADING: {
      return {
        ...state,
        patientsData: [],
        patientsDataLoading:Object.values(state.patientsDataLoading).includes(true)? {...state.patientsDataLoading,...action.payload}:{...action.payload},
      };
    }
    case RECEIVE_APPOINTMENT_LIST: {
      return {
        ...state,
        appointmentList: action.payload,
      };
    }
    case CLEAR_PATIENT_DATA: {
      return {
        ...state,
        patientsData: [],
      };
    }
    case CLEAR_ALL_DATA: {
      // return initialState;
      return {
        ...initialState,
        searchAppointment: state?.searchAppointment,
      };
    }
    case APPOINTMENT_PATIENT_DETAILS: {
      return {
        ...state,
        appointmentDetails: action.payload,
      };
    }
    case RECEIVE_APPOINTMENT_LIST_STATUS: {
      return {
        ...state,
        appointmentViewList: action.payload,
      };
    }
    case CLEAR_ALL_DATA_EXCEPT_FILTER: {
      return {
        ...initialState,
        searchData: state?.searchData,
        paginationData: state?.paginationData,
        columnSort: state?.columnSort,
      };
    }
    case CLEAR_DATA_EXCEPT_FILTER_DOCTOR: {
      return {
        ...initialState,
        doctorSearchData: state?.doctorSearchData,
        doctorPaginationData: state?.doctorPaginationData,
      };
    }
    case SET_SEARCH_DATA: {
      return {
        ...state,
        searchData: action.payload,
      };
    }
    case SET_DOCTOR_SEARCH_DATA: {
      return {
        ...state,
        doctorSearchData: action.payload,
      };
    }
    case SET_APPOINTMENT_PER_PAGE: {
      return {
        ...state,
        paginationData: {
          ...state.paginationData,
          perPage: action.payload,
        },
      };
    }
    case SET_APPOINTMENT_CURRENT_PAGE: {
      return {
        ...state,
        paginationData: {
          ...state.paginationData,
          currentPage: action.payload,
        },
      };
    }
    case SET_APPOINTMENT_PER_PAGE_DOCTOR: {
      return {
        ...state,
        doctorPaginationData: {
          ...state.doctorPaginationData,
          perPage: action.payload,
        },
      };
    }
    case SET_APPOINTMENT_CURRENT_PAGE_DOCTOR: {
      return {
        ...state,
        doctorPaginationData: {
          ...state.doctorPaginationData,
          currentPage: action.payload,
        },
      };
    }
    case SET_APPOINTMENT_COLUMN_SORT: {
      return {
        ...state,
        columnSort: action.payload,
      };
    }
    case UPDATE_SEARCH_APPOINTMENT: {
      return {
        ...state,
        searchAppointment: {
          ...state.searchAppointment,
          ...action.payload,
        },
      };
    }
    case CLEAR_SEARCH_APPOINTMENT: {
      return {
        ...state,
        searchAppointment: {
          selectedTab: state?.searchAppointment?.selectedTab,
        },
      };
    }
    case RESET_SEARCH_APPOINTMENT: {
      return initialState;
    }
    case APPOINTMENT_BTN_LOADING: {
      return {
        ...state,
        isBtnLoading: {
          ...state.isBtnLoading,
          ...action.payload,
        },
      };
    }
    case CLEAR_LINKED_SEARCH_NAME: {
      return {
        ...state,
        searchAppointment: {
          ...state.searchAppointment,
          searchName:
            state?.searchAppointment?.searchName?.value === action.payload
              ? ''
              : state?.searchAppointment?.searchName,
        },
      };
    }
    case RECEIVE_APPOINTMENT_LIST_ALL_DATES: {
      return {
        ...state,
        allDatesAppointments: action.payload
      };
    }
    default:
      return state;
  }
};

export default AppointmentReducer;
