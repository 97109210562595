import PhoneNumberInput from 'Components/Common/PhoneInput';
import { translate } from 'Language/Translate';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

const ContactDetails = (props) => {
  const {
    getLocations,
    contactReset,
    mainBranch,
    setMainBranch,
    otherBranches,
    setOtherBranches,
    contactError,
    setContactError,
    defaultCountryCode,
    inputRef,
    settingsType,
    primaryCode, 
    setPrimaryCode,
    secondaryCode,
    setSecondaryCode,
    setContactChanges,
    setChanges
  } = props;

  // tab navigation for country code
  const focusRef = useRef()
  const secondFocusRef = useRef()
  const phoneInputRef = useRef()
  const secondInputRef = useRef()
  const [primaryNoLength, setPrimaryNoLength] = useState(10);
  const [secondaryNoLength,setSecondaryNoLength]=useState(10)
  const [branchData, setBranchData] = useState([]);

  const initValue = {
    branch_id: '',
    client_id: '',
    branch_name: '',
    address: '',
    address_highlight: '',
    location: '',
    branch_type: 'hospital',
    is_main_branch: false,
    branch_status: 'active',
    tele_country_code_primary: defaultCountryCode?.toString(),
    phone_primary: '',
    email: '',
    tele_country_code_secondary: defaultCountryCode?.toString(),
    phone_secondary: '',
  };

  const {
    branch_name_Error,
    address_highlight_Error,
    address_Error,
    location_Error,
    email_Error,
    tele_country_code_primary_Error,
    phone_primary_Error,
    invalid_email_Error,
    invalid_phone_primary_Error,
    invalid_phone_secondary_Error,
    invalid_location_Error
    // subBranchError = [],
  } = contactError;

  const setBranchDetails = (data) => {
    setBranchData(data);

    const mainBranchData = data.find((item) => item.is_main_branch) || {};
    const mainBranchDetails = Object.keys(mainBranchData).length ? {...mainBranchData} : {...initValue}

    setMainBranch({
      ...mainBranch,
      ...mainBranchDetails,
      tele_country_code_primary:
        mainBranchData.tele_country_code_primary ||
        defaultCountryCode?.toString(),
      tele_country_code_secondary:
        mainBranchData.tele_country_code_secondary ||
        defaultCountryCode?.toString(),
    });
    const otherBarnches = data.filter(
      (item) => item.branch_id !== mainBranchData.branch_id
    );
    setOtherBranches(otherBarnches);
  };

  useEffect(() => {
    if (settingsType === 'contact') getLocations(setBranchDetails);
  }, [settingsType]);

  useEffect(() => {
    if (contactReset) {
      setBranchDetails(branchData);
    }
  }, [contactReset]);

  useEffect(() => {
    setChanges(false)
    let oldata = branchData.find((item) => item.is_main_branch)
    oldata = oldata ? oldata : initValue;
    oldata={
      ...oldata,
      tele_country_code_primary:
        oldata?.tele_country_code_primary ||
        defaultCountryCode?.toString(),
      tele_country_code_secondary:
        oldata?.tele_country_code_secondary ||
        defaultCountryCode?.toString(),
    }
    if (!_.isEqual(oldata, mainBranch)) {
      setContactChanges(false)
    } else {
      setContactChanges(true)
    }
  }, [mainBranch])

  // handle add button click
  // const handleAddLoc = () => {
  //   const branchesCopy = [...otherBranches];
  //   if (
  //     !branchesCopy.length ||
  //     (branchesCopy[0].branch_name &&
  //       branchesCopy[0].address_highlight &&
  //       branchesCopy[0].address &&
  //       branchesCopy[0].email &&
  //       branchesCopy[0].tele_country_code_primary &&
  //       branchesCopy[0].phone_primary)
  //   ) {
  //     branchesCopy.unshift(initValue);
  //     setOtherBranches(branchesCopy);
  //     const branchErrorCopy = [...subBranchError];
  //     branchErrorCopy.unshift({});
  //     setContactError((prev) => ({
  //       ...prev,
  //       subBranchError: branchErrorCopy,
  //     }));
  //   }
  // };

  // handle close
  // const handleClearLoc = (index) => {
  //   const branchesCopy = [...otherBranches];
  //   branchesCopy.splice(index, 1);
  //   const branchErrorCopy = [...subBranchError];
  //   branchErrorCopy.splice(index, 1);
  //   setContactError((prev) => ({
  //     ...prev,
  //     subBranchError: branchErrorCopy,
  //   }));
  //   setOtherBranches(branchesCopy);
  // };

  // handle main branch change
  const handleMainbranchChange = (event) => {
    const { name, value } = event.target;
    const regex = /^\+?[0-9 ]*\.?[0-9 ]*$/;
    if (name === 'phone_primary' || name === 'phone_secondary') {
      if (regex.test(value)) {
        setContactError((prev) => ({
          ...prev,
          [`${name}_Error`]: false,
          [`invalid_${name}_Error`]: false,
        }));
        setMainBranch((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setContactError((prev) => ({
        ...prev,
        [`${name}_Error`]: false,
        [`invalid_${name}_Error`]: false,
      }));
      setMainBranch((prev) => ({
        ...prev,
        [name]: value,
      }));
    }



  };

  // handle other branches change
  // const handleOtherBranchChange = (event, index) => {
  //   const { name, value } = event.target;
  //   const branchesCopy = [...otherBranches];
  //   branchesCopy[index][name] = value;
  //   const branchErrorCopy = [...subBranchError];
  //   if (branchErrorCopy[index]) {
  //     branchErrorCopy[index][`${name}_Error`] = false;
  //     branchErrorCopy[index][`invalid_${name}_Error`] = false;
  //   }

  //   setContactError((prev) => ({
  //     ...prev,
  //     subBranchError: branchErrorCopy,
  //   }));
  //   setOtherBranches(branchesCopy);
  // };

  // handle country change
  const handleCountryCode = (value, name, index) => {
    if (index) {
      const branchesCopy = [...otherBranches];
      branchesCopy[index][name] = value;
      setOtherBranches(branchesCopy);
    } else {
      setContactError((prev) => ({
        ...prev,
        tele_country_code_primary_Error: false,
      }));
      setMainBranch((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <div
        ref={(el) => (inputRef.current['mainBranch'] = el)}
        className="scrollMarginTop100"
      >
        <h6 className="section-title col-md-10">
          {/* {translate('settings.primaryLocation', 'Primary Location')} */}
          <span>{translate('settings.location', 'Location')}</span>  
        </h6>
      </div>
      <div className="row">
        <div className="col-md-4 mb-1">
          <div className="form-group">
            <label className="mandatory" htmlFor="brancName">
              {translate('settings.branchName', 'Branch name')}
            </label>
            <input
              type="text"
              className="form-control input"
              id="brancName"
              name="branch_name"
              maxLength={50}
              style={{
                borderColor: branch_name_Error ? 'red' : '',
              }}
              value={mainBranch.branch_name}
              onChange={handleMainbranchChange}
            />
            {branch_name_Error ? (
              <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                {translate('common.required', 'This field is required')}
              </p>
            ) : null}
          </div>
        </div>
        <div className="col-md-4 mb-1">
          <div className="form-group">
            <label className="mandatory" htmlFor="place">
              {translate('settings.place', 'Place')}
            </label>
            <input
              type="text"
              className="form-control input"
              id="place"
              name="address_highlight"
              style={{
                borderColor: address_highlight_Error ? 'red' : '',
              }}
              value={mainBranch.address_highlight}
              onChange={handleMainbranchChange}
              maxLength={100}
            />
            {address_highlight_Error ? (
              <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                {translate('common.required', 'This field is required')}
              </p>
            ) : null}
          </div>
        </div>
        <div className="col-md-8 mb-1">
          <div className="form-group">
            <label className="mandatory" htmlFor="address">
              {translate('settings.address', 'Address')}
            </label>
            <div className="text-area-wrap">
            <textarea
              className="form-control input"
              id="address"
              cols="30"
              rows="4"
              style={{
                borderColor: address_Error ? 'red' : '',
              }}
              name="address"
              value={mainBranch.address}
              onChange={handleMainbranchChange}
              maxLength="150"
            ></textarea>
                  </div>
            {address_Error ? (
              <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                {translate('common.required', 'This field is required')}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <h6 className="section-title col-md-10">
         <span>Contact Info</span> 
        </h6>
      {/* <div className="row">
      
       
       
      </div> */}
      <div className="row">
      <div className="col-md-4 mb-1">
          <div className="form-group">
            <label className="mandatory" htmlFor="primaryPhone">
              {translate('settings.primaryPhone', 'Primary Phone')}
            </label>
            <div className="d-flex">
              <div
                style={{ marginRight: 15 }}
                className={`${tele_country_code_primary_Error && 'error'}`}
                onKeyDown={(e) => {
                  if (e.key === "Tab" && !e.shiftKey)
                  {
                    e.preventDefault()
                    phoneInputRef.current.focus()
                  } 
                  else if(!e.shiftKey && e.key === "Enter")
                  {
                    e.preventDefault()
                    focusRef.current.focus()
                  } 
                }}
              >
                <PhoneNumberInput
                  value={mainBranch.tele_country_code_primary}
                  onChange={(value) =>
                    handleCountryCode(value, 'tele_country_code_primary')
                  }
                  validationErr={tele_country_code_primary_Error}
                  numberLength={primaryNoLength}
                  setNumberLength={setPrimaryNoLength}
                  code={primaryCode}
                  setCode={setPrimaryCode}
                  focusRef={focusRef}
                />
              </div>
              <div className='w-100'>
              <input
                type="text"
                className="form-control input"
                id="primaryPhone"
                name="phone_primary"
                style={{
                  borderColor:
                    phone_primary_Error || invalid_phone_primary_Error
                      ? 'red'
                      : '',
                }}
                value={mainBranch.phone_primary}
                maxLength={primaryNoLength}
                onChange={handleMainbranchChange}
                ref={phoneInputRef}
                onKeyDown={(e)=>{
                  if(e.shiftKey && e.key === "Tab"){
                    e.preventDefault()
                    focusRef.current.focus()
                  }
                }}
              />
            {phone_primary_Error || tele_country_code_primary_Error ? (
              <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                {translate('common.required', 'This field is required')}
              </p>
            ) : null}
            {invalid_phone_primary_Error ? (
              <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                {translate('users.invalidPhone', 'Invalid Phone Number')}
              </p>
            ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-1">
          <div className="form-group ">
            <label htmlFor="secondaryPhone">
              {translate('settings.secPhone', 'Secondary Phone')}
            </label>
            <div className="d-flex">
              <div
                style={{ marginRight: 15 }}
                // className={`${countryError && 'error'}`}
                onKeyDown={(e) => {
                  if (e.key === "Tab" && !e.shiftKey)
                  {
                    e.preventDefault()
                    secondInputRef.current.focus()
                  } 
                  else if(!e.shiftKey && e.key === "Enter")
                  {
                    e.preventDefault()
                    secondFocusRef.current.focus()
                  } 
                }}
              >
                <PhoneNumberInput
                  value={mainBranch.tele_country_code_secondary}
                  onChange={(value) =>
                    handleCountryCode(value, 'tele_country_code_secondary')
                  }
                  // validationErr={countryError}
                  numberLength={secondaryNoLength}
                  setNumberLength={setSecondaryNoLength}
                  code={secondaryCode}
                  setCode={setSecondaryCode}
                  focusRef={secondFocusRef}
                />
              </div>
              <div className='w-100'>
              <input
                type="text"
                className="form-control input"
                id="secondaryPhone"
                name="phone_secondary"
                maxLength={secondaryNoLength}
                style={{
                  borderColor: invalid_phone_secondary_Error ? 'red' : '',
                }}
                value={mainBranch.phone_secondary}
                onChange={handleMainbranchChange}
                ref={secondInputRef}
                onKeyDown={(e)=>{
                  if(e.shiftKey && e.key === "Tab"){
                    e.preventDefault()
                    secondFocusRef.current.focus()
                  }
                }}
              />
              {invalid_phone_secondary_Error ? (
              <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                {translate('users.invalidPhone', 'Invalid Phone Number')}
              </p>
            ) : null}
            </div>
            </div>
          </div>
        </div>
            <div></div>
        <div className="col-md-4 mb-1">
          <div className="form-group">
            <label className="mandatory" htmlFor="locationLink">
              {translate('settings.locLink', 'Location link')}
            </label>
            <input
              type="text"
              className="form-control input"
              id="locationLink"
              name="location"
              style={{
                borderColor: location_Error||invalid_location_Error ? 'red' : '',
              }}
              value={mainBranch.location}
              maxLength={500}
              placeholder={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d251371.23967502583!2d76.16915800661481!3d10.125615623297495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080b96e61c87ef%3A0xcc3eb0fca6b3880d!2sFeathersoft%20Info%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1722504290148!5m2!1sen!2sin"}
              onChange={handleMainbranchChange}
            />
            {location_Error ? (
              <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                {translate('common.required', 'This field is required')}
              </p>
            ) : null}
             {invalid_location_Error ? (
              <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                {translate('users.invalidLocationLink', 'Invalid Location Link')}
              </p>
            ) : null}
          </div>
        </div>
        <div className="col-md-4 mb-1">
          <div className="form-group">
            <label className="mandatory" htmlFor="email">
              {translate('settings.email', 'Email')}
            </label>
            <input
              type="text"
              className="form-control input"
              id="email"
              name="email"
              style={{
                borderColor: email_Error || invalid_email_Error ? 'red' : '',
              }}
              value={mainBranch.email}
              maxLength={50}
              onChange={handleMainbranchChange}
            />
            {email_Error ? (
              <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                {translate('common.required', 'This field is required')}
              </p>
            ) : null}
            {invalid_email_Error ? (
              <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                {translate('users.invalidMail', 'Invalid Email id')}
              </p>
            ) : null}
          </div>
        </div>
      </div>

      {/* <div className="row mt-4">
        <div className="col-md-10">
          <div className="d-flex align-items-center gap-2 justify-content-between mb-2">
            <h6 className="section-title mb-0">
              {translate('settings.otherLocation', 'Other Location')}
            </h6>
            <button className="btn-transparent add-btn" onClick={handleAddLoc}>
              +{translate('settings.add', 'Add')}
            </button>
          </div>
        </div>
      </div> */}
      {/* {otherBranches.map((branch, index) => (
        <>
          <div
            className="row row-wrap scrollMarginTop100"
            ref={(el) => (inputRef.current[`otherBranches${index}`] = el)}
          >
            <div className="col-md-4 mb-1">
              <div className="form-group">
                <label className="mandatory" htmlFor="brancName">
                  {translate('settings.branchName', 'Branch name')}
                </label>
                <input
                  type="text"
                  className="form-control input"
                  id="brancName"
                  name="branch_name"
                  style={{
                    borderColor: subBranchError[index]?.branch_name_Error
                      ? 'red'
                      : '',
                  }}
                  value={branch.branch_name}
                  onChange={(e) => handleOtherBranchChange(e, index)}
                />
                {subBranchError[index]?.branch_name_Error ? (
                  <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                    {translate('common.required', 'This field is required')}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col-md-4 mb-1">
              <div className="form-group">
                <label className="mandatory" htmlFor="place">
                  {translate('settings.place', 'Place')}
                </label>
                <input
                  type="text"
                  className="form-control input"
                  id="place"
                  name="address_highlight"
                  style={{
                    borderColor: subBranchError[index]?.address_highlight_Error
                      ? 'red'
                      : '',
                  }}
                  value={branch.address_highlight}
                  onChange={(e) => handleOtherBranchChange(e, index)}
                />
                {subBranchError[index]?.address_highlight_Error ? (
                  <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                    {translate('common.required', 'This field is required')}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col-md-2 close-btn-wrap">
              <button
                className="btn-transparent btn-close btn-sm"
                onClick={() => handleClearLoc(index)}
              ></button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 mb-1">
              <div className="form-group">
                <label className="mandatory" htmlFor="address">
                  {translate('settings.address', 'Address')}
                </label>
                <textarea
                  className="form-control input"
                  id="address"
                  cols="30"
                  rows="4"
                  name="address"
                  style={{
                    borderColor: subBranchError[index]?.address_Error
                      ? 'red'
                      : '',
                  }}
                  value={branch.address}
                  onChange={(e) => handleOtherBranchChange(e, index)}
                ></textarea>
                {subBranchError[index]?.address_Error ? (
                  <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                    {translate('common.required', 'This field is required')}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row last-row">
            <div className="col-md-4 mb-1">
              <div className="form-group">
                <label className="mandatory" htmlFor="primaryPhone">
                  {translate('settings.primaryPhone', 'Primary Phone')}
                </label>
                <div className="d-flex">
                  <div
                    style={{ width: 150, marginRight: 15 }}
                    className={`${
                      subBranchError[index]?.tele_country_code_primary_Error &&
                      'error'
                    }`}
                  >
                    <PhoneNumberInput
                      value={branch.tele_country_code_primary}
                      onChange={(value) =>
                        handleCountryCode(
                          value,
                          'tele_country_code_primary',
                          index
                        )
                      }
                      validationErr={
                        subBranchError[index]?.tele_country_code_primary_Error
                      }
                    />
                  </div>
                  <input
                    type="text"
                    className="form-control input"
                    id="primaryPhone"
                    name="phone_primary"
                    value={branch.phone_primary}
                    style={{
                      borderColor:
                        subBranchError[index]?.phone_primary_Error ||
                        subBranchError[index]?.invalid_phone_secondary_Error
                          ? 'red'
                          : '',
                    }}
                    onChange={(e) => handleOtherBranchChange(e, index)}
                  />
                </div>
                {subBranchError[index]?.phone_primary_Error ||
                subBranchError[index]?.tele_country_code_primary_Error ? (
                  <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                    {translate('common.required', 'This field is required')}
                  </p>
                ) : null}
                {subBranchError[index]?.invalid_phone_secondary_Error ? (
                  <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                    {translate('users.invalidPhone', 'Invalid Phone Number')}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col-md-4 mb-1">
              <div className="form-group">
                <label className="mandatory" htmlFor="email">
                  {translate('settings.email', 'Email')}
                </label>
                <input
                  type="text"
                  className="form-control input"
                  id="email"
                  name="email"
                  style={{
                    borderColor:
                      subBranchError[index]?.email_Error ||
                      subBranchError[index]?.invalid_email_Error
                        ? 'red'
                        : '',
                  }}
                  value={branch.email}
                  onChange={(e) => handleOtherBranchChange(e, index)}
                />
                {subBranchError[index]?.email_Error ? (
                  <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                    {translate('common.required', 'This field is required')}
                  </p>
                ) : null}
                {subBranchError[index]?.invalid_email_Error ? (
                  <p className="mt-1 fs-10 fw-500" style={{ color: 'red' }}>
                    {translate('users.invalidMail', 'Invalid Email id')}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ))} */}
    </>
  );
};

export default ContactDetails;
