import React from 'react';
import { Route } from 'react-router-dom';
import Appointments from './AppointmentsRoster';
// import Appointments from './Appointments';]
import PatientView from './Prescription/PatientView';

function DoctorPortal(props) {
  const { match } = props;
  return (
    <>
      <Route exact path={match.path} component={Appointments} />
      <Route
        exact
        path={`${match.path}/patientview/:id`}
        component={PatientView}
      />
    </>
  );
}

export default DoctorPortal;
