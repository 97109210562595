/* eslint-disable no-unused-vars */
import {
  appointmentBtnLoading,
  CancelBulkAppointment,
  FetchAppointmentList,
  NotifyPatients,
  FetchAppointmentListALLDates,
} from "Actions/appointmentAction";
import { updateLeaveStatus } from "../../../Actions/LeaveActions";
import ReactSelectDropDown from "Components/Common/ReactSelectDropDown";
import { translate } from "Language/Translate";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { LeavesApi } from "Utils/Api/LeavesApi";
import {
  dateinFormatDDMMMYYYY,
  dateinFormatDDddddMMMM,
  formatDateInDDMMYYYY,
  formatDate,
  timeZoneDate,
} from "Utils/DateFormatter/Date";
import "./reschedule.css";
import AppointmentItem from "./AppointmentItem";
import { DepartmentsApi } from "Utils/Api/DepartmentApi";
import NotifyConfirmPopup from "./NotifyConfirmPopup";
import CancelConfirmPopup from "./CancelConfirmPopup";
import Nodata from "Components/Common/Nodata";
import Loader from "Components/Common/Loader";
import { revertFromBase64 } from "Utils/Services/Authenticator";
import { isEmpty } from "lodash";
import { AppointmentApi } from "Utils/Api/AppointmentApi";
import AntDatepicker from "Components/Common/AntdDatePicker";
import moment from "moment";
import NoResultFound from "Components/Common/NoResult";
import useDidMountEffect from "Hooks/useDidMountEffect";
import { LEAVE_STATUS } from "Constants/constant";

const RescheduleHeader = ({ refernceScn, dates, docOrDept }) => {
  const { start_date, end_date } = dates();
  return (
    <>
      <div className="admin-bread-crumbs mb-lg-2 fs-12">
        <div className="wrapper links">
          {refernceScn === "leave" ? (
            <a className="previous" href="#/admin/leave-approval">
              {translate("leaveApproval.title2", "Leaves")}
            </a>
          ) : refernceScn === "department" ? (
            <a className="previous" href="#/admin/departments">
              {translate("departments.title", "Departments")}
            </a>
          ) : refernceScn === "doctor" ? (
            <a className="previous" href="#/admin/doctorslisting">
              {translate("doctors.title", "Doctors")}
            </a>
          ) : null}
          <span> {">"} </span>
          <a className="bread-crumb-cursor">
            {translate(
              "appointments.rescheduleAppts",
              "Reschedule Appointments"
            )}
          </a>
        </div>
      </div>
      <div className="detail-card">
        <div>
          <span className="label">
            {refernceScn === "leave"
              ? translate("appointments.bookedWith", "Booked with")
              : refernceScn === "department"
              ? translate("appointments.deptSelected", "Department Selected")
              : translate("appointments.docSelected", "Doctor Selected")}
          </span>
          <p className="value text-capitalize">{docOrDept()?.label}</p>
        </div>
        <div>
          <span className="label">
            {refernceScn === "leave"
              ? translate("appointments.leaveDates", "Leave Date(s)")
              : translate(
                  "appointments.apntDateConflicted",
                  "Appointment Date(s) Conflicted"
                )}
          </span>
          <p className="value">
            {start_date !== end_date
              ? `${dateinFormatDDMMMYYYY(
                  start_date
                )} to ${dateinFormatDDMMMYYYY(end_date)}`
              : dateinFormatDDMMMYYYY(start_date)}
          </p>
        </div>
      </div>
    </>
  );
};

export const RescheduleAppt = ({
  getAppointments,
  appointmentList,
  cancelAppointment,
  notifyPatients,
  isBtnLoading,
  getAppointmentsAllDates,
  allDatesAppointments,
  editLeaveStatus,
}) => {
  const [appointments, setAppointments] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [availableDocs, setAvailableDocs] = useState([]);
  const [selectedAppt, setSelectedAppt] = useState([]);
  const [update, setUpdate] = useState(false);
  const [notifyPopup, setNotifyPopup] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [refernceScn, setRefernceScn] = useState("");
  const [detailId, setDetailId] = useState("");
  const [availableFilters, setAvailableFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({
    filterChanged: false
  })
  const [commonDetails, setCommonDetails] = useState({})
  const [isLoading, setIsLoading] = useState({})
  const [showMore,setShowMore]=useState(true)

  const {
    cancelBulkAppt: cancelBulkApptLoading = false,
    notifyAppt: notifyApptLoading = false,
    fetchAppt: apptLoading = false,
  } = isBtnLoading;
  const {
    availableDates = [],
    availableDoctors = [],
    availableDepartments = [],
  } = availableFilters;
  const { selectedDate, selectedDoctor, selectedDept, filterChanged } =
    selectedFilters;
  const { deptLoading, apptInfoLoading } = isLoading;

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const params = useParams();
  const { id } = params || {};

  useEffect(() => {
    if (id) {
      const [referenceId, valId] = id.split(":");
      setRefernceScn(referenceId);
      setDetailId(valId);
    }
  }, [id]);

  const { start_date, end_date, start_time, end_time, doctor_id, dept_id } =
    commonDetails || {};

  useDidMountEffect(() => {
    getAllAppointmentInfo({
      start_date: start_date || formatDate(timeZoneDate()),
      end_date,
      start_time,
      end_time,
      doctor_id,
      dept_id,
      details: true,
      leave_conflict: refernceScn === "leave" ? true : undefined,
      status:
        refernceScn !== "leave" ? "upcoming,waiting,inprogress" : undefined,
    });
  }, [update, start_date, end_date, start_time, end_time, doctor_id, dept_id]);

  const getAllDateAppointments = () => {
    getAppointmentsAllDates({
      start_date: start_date,
      end_date: end_date,
      doctor_id: selectedDoctor,
      leave_conflict: true,
    });
  };

  useEffect(() => {
    if (allDatesAppointments?.appointments?.length) return;
    const config = {
      data: {
        leave_status: LEAVE_STATUS.approved,
      },
    };
    editLeaveStatus(
      config,
      detailId,
      () => {},
      () => {},
      {
        successLeave: () => {
          history.push("/admin/leave-approval");
        },
      }
    );
  }, [allDatesAppointments]);

  useDidMountEffect(() => {
    if (selectedDate || selectedDoctor || selectedDept) {
      getAppointments({
        start_date: selectedDate,
        end_date: selectedDate,
        start_time,
        end_time,
        doctor_id: selectedDoctor,
        dept_id: refernceScn !== "leave" ? selectedDept : undefined,
        leave_conflict: refernceScn === "leave" ? true : undefined,
        status:
          refernceScn !== "leave" ? "upcoming,waiting,inprogress" : undefined,
        limit: 0,
      });
      setSelectedAppt([]);
    }
    // }, [selectedDate, selectedDoctor, selectedDept])
  }, [filterChanged])

  useEffect(()=>{
    setShowMore(true)
  },[selectedDate])


  useEffect(() => {
    dispatch({ type: "RECEIVE_APPOINTMENT_LIST", action: {} });
    dispatch(appointmentBtnLoading({ fetchAppt: true }));
    if (refernceScn === "leave") {
      getLeaveDetails(detailId);
    } else {
      const { doctor_ids, department_ids } = revertFromBase64(detailId);
      setCommonDetails((prev) => ({
        ...prev,
        doctor_id: doctor_ids?.toString(),
        dept_id: department_ids?.toString(),
      }));
    }
  }, [detailId]);

  useEffect(() => {
    if (appointmentList?.appointments) {
      getAllDeptList();
      setAppointments(appointmentList.appointments);
    } else {
      setAppointments([]);
    }
  }, [appointmentList]);

  const getAllAppointmentInfo = (options) => {
    setIsLoading((prev) => ({
      ...prev,
      apptInfoLoading: true,
    }));
    AppointmentApi.getAllApntInfo(options).then((res) => {
      setIsLoading((prev) => ({
        ...prev,
        apptInfoLoading: false,
      }));
      const { error, response } = res;
      if (error) {
        return;
      } else {
        const {
          appointment_details = [],
          departments = [],
          doctors = [],
          total_appt_count,
        } = response;
        setAvailableFilters({
          availableDates: appointment_details,
          availableDoctors: doctors,
          availableDepartments: departments,
        });
        setSelectedFilters((prev) => ({
          ...prev,
          selectedDate: appointment_details?.find(
            (item) => item.appt_date === prev.selectedDate
          )
            ? prev.selectedDate
            : appointment_details?.[0]?.appt_date,
          selectedDoctor: doctors?.find(
            (item) => item.id === prev.selectedDoctor
          )
            ? prev.selectedDoctor
            : doctors?.[0].id,
          selectedDept: departments?.find(
            (item) => item.id === prev.selectedDept
          )
            ? prev.selectedDept
            : departments?.[0].id,
          filterChanged: !prev.filterChanged,
        }));
        if (!total_appt_count) {
          dispatch(appointmentBtnLoading({ fetchAppt: false }));
          dispatch({ type: "RECEIVE_APPOINTMENT_LIST", action: {} });
        }
      }
    });
  };

  const getLeaveDetails = (leaveId) => {
    LeavesApi.getLeaveDetails({ leave_id: leaveId }).then((res) => {
      const { error, response } = res;
      if (error) {
        return;
      } else {
        const { doctors_leaves } = response;
        if (doctors_leaves.length) {
          const { start_date, end_date, start_time, end_time, doctor_id } =
            doctors_leaves[0];
          setCommonDetails((prev) => ({
            ...prev,
            start_date,
            end_date,
            start_time,
            end_time,
            doctor_id,
          }));
        }
      }
    });
  };

  const getAllDeptList = () => {
    setIsLoading((prev) => ({
      ...prev,
      deptLoading: true,
    }));
    const config = {
      doctor_id: "",
      start_date: "",
      end_date: "",
    };
    AppointmentApi.getAvailableApptDates(config).then(({ error, response }) => {
      setIsLoading((prev) => ({
        ...prev,
        deptLoading: false,
      }));
      if (error) {
        return;
      }
      if (response) {
        setDeptList(response?.available_departments);
        setAvailableDocs(response?.slot_info?.doctors);
      } else {
        setDeptList([]);
      }
    });
  };

  // const getAllDocList = () => {
  //   setDocLoading(true);
  //   const config = {
  //     limit: 0,
  //     status: 'active',
  //   };
  //   DoctorsApi.getDoctorsList(config).then((res) => {
  //     setDocLoading(false);
  //     if (res.error) {
  //       return;
  //     }
  //     if (res.response) {
  //       setDocList(res.response?.doctors);
  //     } else {
  //       setDocList([]);
  //     }
  //   });
  // };

  // const getDatesBetween = (start, end) => {
  //   const startDate = new Date(start)
  //   const endDate = new Date(end)
  //   for (var arr = [], dt = startDate; dt <= endDate; dt.setDate(dt.getDate() + 1)) {
  //     arr.push(new Date(dt).toISOString().slice(0, 10));
  //   }
  //   return arr;
  // };

  const handleSelect = (e, id, type) => {
    const { checked } = e.target;
    let checkedItems = [...selectedAppt];
    if (type === "all") {
      if (checked) {
        checkedItems = appointments.map((item) => item.appointment_id);
      } else {
        checkedItems = [];
      }
    } else {
      const index = checkedItems.indexOf(id);
      if (checked && index === -1) {
        checkedItems.push(id);
      } else {
        checkedItems.splice(index, 1);
      }
    }
    setSelectedAppt(checkedItems);
  };

  const handleNotfiy = () => {
    setNotifyPopup(true);
  };

  const handleCancelPopup = () => {
    setCancelPopup(true);
  };

  const handleCancel = () => {
    setCancelPopup(false);
    setNotifyPopup(false);
  };

  const handleCancelConfirm = () => {
    const config = {
      data: {
        appointments: selectedAppt,
        cancelation_reason: refernceScn
          ? "doctor leave conflict"
          : "doctor remove from department",
      },
    };
    cancelAppointment(config, () => {
      setSelectedAppt([]);
      setUpdate((prev) => !prev);
      setCancelPopup(false);
      getCancelAppintmentList();
    });
  };

  const getCancelAppintmentList = () => {
    const config = {
      data: {
        leave_status: LEAVE_STATUS.approved,
      },
    };
    editLeaveStatus(
      config,
      detailId,
      () => {},
      () => {},
      {
        successLeave: () => {
          history.push("/admin/leave-approval");
        },
      }
    );
  };

  const handleNotify = () => {
    const config = {
      data: {
        appointments: selectedAppt,
        notification_type: refernceScn
          ? "appointment_leave_conflict"
          : "doctor remove from department",
      },
    };
    notifyPatients(config, () => {
      setSelectedAppt([]);
      setUpdate((prev) => !prev);
      setNotifyPopup(false);
    });
  };

  const docOrDept = () => {
    let isExist;
    if (refernceScn === "department") {
      isExist = availableDepartments?.find((item) => item.id === selectedDept);
    } else {
      isExist = availableDoctors?.find((item) => item.id === selectedDoctor);
    }
    return isExist ? { label: isExist.name, value: isExist.id } : "";
  };

  const conflictDateRange = () => {
    if (refernceScn === "leave") {
      return { start_date, end_date };
    } else {
      return {
        start_date: availableDates?.[0]?.appt_date,
        end_date: availableDates?.at(-1)?.appt_date,
      };
    }
  };

  const selectedDocOrDept = () => {
    let isExist;
    if (refernceScn === "department" || refernceScn === "leave") {
      isExist = availableDoctors?.find((item) => item.id === selectedDoctor);
    } else {
      isExist = availableDepartments?.find((item) => item.id === selectedDept);
    }
    return isExist ? { label: isExist.name, value: isExist.id } : "";
  };

  useEffect(()=>{
    setShowMore(true)
  },[selectedDate])

  return (
    <div className="content reschedule">
      <RescheduleHeader
        refernceScn={refernceScn}
        dates={conflictDateRange}
        docOrDept={docOrDept}
      />
      <div className="row mini-filter justify-content-md-between">
        {refernceScn !== "leave" && (
          <div className="form-group mb-0 col-md-3">
            <label htmlFor="conflictDate">
              {refernceScn === "department" || refernceScn === "leave"
                ? translate("appointments.doctors", "Doctor(s)")
                : translate("appointments.departments", "Departments(s)")}{" "}
            </label>
            <ReactSelectDropDown
              label={
                refernceScn === "department" || refernceScn === "leave"
                  ? translate("appointments.doctors", "Doctor(s)")
                  : translate("appointments.departments", "Departments(s)")
              }
              options={
                refernceScn === "department" || refernceScn === "leave"
                  ? availableDoctors?.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))
                  : availableDepartments?.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))
              }
              onInputChange={(val) => {
                if (refernceScn === "department" || refernceScn === "leave") {
                  setSelectedFilters((prev) => ({
                    ...prev,
                    selectedDoctor: val.value,
                    filterChanged: !prev.filterChanged,
                  }));
                } else {
                  setSelectedFilters((prev) => ({
                    ...prev,
                    selectedDept: val.value,
                    filterChanged: !prev.filterChanged,
                  }));
                }
              }}
              value={apptInfoLoading ? "" : selectedDocOrDept()}
              emptyClass
              isLoading={apptInfoLoading}
              isDisabled={apptInfoLoading || refernceScn === "leave"}
            />
          </div>
        )}
        <div className="form-group mb-0 col-md-3">
          <label htmlFor="conflictDate">
            {refernceScn === "leave"
              ? translate(
                  "appointments.leaveDateConflict",
                  "Leave Date(s) Conflicted"
                )
              : translate("appointments.selectDate", "Select Date")}{" "}
          </label>
          <AntDatepicker
            onInputChange={(date) => {
              setSelectedFilters((prev) => ({
                ...prev,
                selectedDate: formatDate(date),
                filterChanged: !prev.filterChanged,
              }));
            }}
            value={selectedDate}
            placeholder={intl.formatMessage({
              id: "common.from",
              defaultMessage: "From",
            })}
            className="form-control"
            disabledDate={(current) => {
              return !availableDates?.find((item) =>
                current.isSame(moment(item.appt_date))
              );
            }}
            isAdmin
          />
        </div>
        <div className="col-md-6 mini-filter-btn-wrap">
          {appointments.length && selectedAppt?.length ? (
            <>
              <button onClick={handleNotfiy} className="btn btn-secondary px-4">
                <span className="px-2">
                  {translate("appointments.notify", "Notify")}
                </span>
              </button>
              <button
                className="btn btn btn-outlined-danger"
                onClick={handleCancelPopup}
              >
                {translate("appointments.cancelAppts", "Cancel Appointment(s)")}
              </button>
            </>
          ) : null}
        </div>
      </div>
      <div className="list-view mb-1">
            <div className='table-view'>
            <div className="d-flex single-row align-items-center mb-0 pb-1">
          <div className="row">
            <div className="col-6 ps-0">
              {/* <span className="selected-date">{`${intl.formatMessage({
                id: 'appointments.dateSelected',
                defaultMessage: 'Date Selected',
              })} (${dateinFormatDDddddMMMM(selectedDate)})`}</span> */}
                <span className="selected-date">
                  {translate(
                    "appointments.currentAppointments",
                    "Current Appointments"
                  )}
                </span>
              </div>
              <div className="col-6 ps-4">
                <span className="selected-date ps-3 ms-1">
                  {translate("appointments.rescheduleTo", "Reschedule to")}
                </span>
              </div>
            </div>
          </div>
          {apptLoading ? (
            <div
              className="list-user"
              style={{ minHeight: apptLoading ? "55vh" : "" }}
            >
              <Loader disableScroll={false} />
            </div>
          ) : appointments.length > 0 ? (
            <>
              <div className="d-flex single-row bb-none heading">
                <div className="checkbox-container" style={{ top: "3px" }}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="selectAll"
                    onClick={(e) => handleSelect(e, null, "all")}
                    checked={
                      selectedAppt?.length > 0 &&
                      selectedAppt?.length === appointments?.length
                    }
                  />
                </div>
                <div className="row headings">
                  <div className="col-3 ps-0">
                    <h6>
                      {translate("appointments.slotDetails", "Slot details")}
                    </h6>
                  </div>
                  <div className="col-3">
                    <h6>{translate("appointments.bookedFor", "Booked for")}</h6>
                  </div>
                  <div className="col-3">
                    <h6>
                      {translate("appointments.department", "Department")}
                    </h6>
                  </div>
                  <div className="col-3">
                    <h6>{translate("leaveApproval.doctor", "Doctor")}</h6>
                  </div>
                </div>
              </div>
              <>
                {appointments?.map((item,ind) => (
              ( ( ind<5 && showMore) || !showMore) &&
                  <AppointmentItem
                    key={item.appointment_id}
                    item={item}
                    deptList={deptList}
                    deptLoading={deptLoading}
                    handleSelect={handleSelect}
                    selectedAppt={selectedAppt}
                    setUpdate={setUpdate}
                    getAllDateAppointments={getAllDateAppointments}
                    availableDocs={availableDocs}
                  />

                  ))}
              {appointments?.length>5 &&showMore&&<button className="admin-more-btn btn btn-outlined" onClick={()=>{setShowMore(false)}}>{translate('patientView.showMore', 'Show More')}</button>}
                  </>
            </>
          ) : (
            <div className="patient-portal" style={{ marginBottom: 10 }}>
              <NoResultFound
                text={translate("common.noData", "No data found")}
              />
            </div>
          )}
        </div>
      </div>
      <div className="back-to-btn">
        <button className="btn btn-outlined" onClick={() => {
          if (refernceScn === 'leave') {
            history.push(`/admin/leave-approval`)
          } else if (refernceScn === 'department') {
            history.push(`/admin/departments`)
          } else {
            history.push(`/admin/doctorslisting`)
          }
        }}>Back to Listing</button>
      </div>
      {notifyPopup && (
        <NotifyConfirmPopup
          isBtnLoading={notifyApptLoading}
          onClose={handleCancel}
          onConfirm={handleNotify}
        />
      )}
      {cancelPopup && (
        <CancelConfirmPopup
          isBtnLoading={cancelBulkApptLoading}
          onClose={handleCancel}
          onConfirm={handleCancelConfirm}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  appointmentList: state.AppointmentReducer.appointmentList,
  isBtnLoading: state.AppointmentReducer.isBtnLoading,
  allDatesAppointments: state.AppointmentReducer.allDatesAppointments,
});

const mapDispatchToProps = {
  getAppointments: (data, isLoader) => FetchAppointmentList(data, isLoader),
  cancelAppointment: (data, successAction) =>
    CancelBulkAppointment(data, successAction),
  notifyPatients: (data, successAction) => NotifyPatients(data, successAction),
  getAppointmentsAllDates: (data) => FetchAppointmentListALLDates(data),
  editLeaveStatus: (data, leaveId, fetchList, loader, action) =>
    updateLeaveStatus(data, leaveId, fetchList, loader, action),
};

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleAppt);
