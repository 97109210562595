import useDocumentTitle from 'Hooks/useDocumentTitle';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { setfavicon } from './logoGenerator';

function BrandingWrapper({ children, subscriptionData, faviconChange }) {
  const { branding } = subscriptionData
  useDocumentTitle(branding?.brandName)

  useEffect(() => {
    setfavicon(subscriptionData);
  }, [faviconChange])

  return (
    <>
      {children}
    </>
  )
}

const mapStateToProps = (state) => ({
  subscriptionData: state.SettingsReducer.subscriptionData,
  faviconChange: state.SettingsReducer.faviconChange
});

export default connect(mapStateToProps)(BrandingWrapper);