import React, { useEffect, useState } from 'react'
import {Prompt} from "react-router-dom"

export default function useNavigationWarning(message="You have unsaved changes. Are you sure you want to leave this page ? Unsaved changes will be lost.") {
    //once data saved or when no prompt condition satisified make this true
    const [isSaved,setIsSaved]=useState(false)
useEffect(()=>{
    window.onbeforeunload= !isSaved && (()=>message)
    return()=>{
        window.onbeforeunload=null
    }
},[isSaved])

const  routerPrompt =<Prompt when={!isSaved} message={message}/>
return {Prompt:routerPrompt,setIsSaved:(val=false)=>setIsSaved(val)} 
}
