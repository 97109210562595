import React, { useEffect, useState } from 'react'
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown'
import CloseIcon from '@mui/icons-material/Close';
import { translate } from '../../Language/Translate'
import { formatDate, timeZoneDate } from 'Utils/DateFormatter/Date';
import { PAID, TRANSACTION_TYPE_OPTIONS } from 'Constants/constant';
import ButtonLoader from 'Components/Common/ButtonLoader';
import AntDatepicker from 'Components/Common/AntdDatePicker';
import { useIntl } from 'react-intl';
import  FocusTrap  from 'focus-trap-react';
function PaymentInfoPopUp({ openPaymentInfo, onClose, paymentAppt, paymentHandle, fetchAppointments, isBtnLoading }) {
  const intl = useIntl();

  const [paymentInfo, setPaymentInfo] = useState({
    transactionType: '',
    amount: '',
    date: timeZoneDate(),
    refId: '',
    additionalInfo: ''
  })
  const [editstatus, setEditStatus] = useState(true)
  //   "payment_details": {
  //     "payment_id":"",
  //   "transaction_method": "",
  //   "amount": 0,
  //   "payment_date": "",
  //   "transaction_id": ""
  // }

  useEffect(() => {
    const { payment_details, doctor_op_fees = '' } = paymentAppt
    if (payment_details?.payment_status === PAID) {
      setEditStatus(false)
    }
    setPaymentInfo({
      transactionType: payment_details?.transaction_method
        ? payment_details?.transaction_method
        : '',
      amount: payment_details?.amount || doctor_op_fees,
      date: payment_details?.payment_date ? payment_details?.payment_date : timeZoneDate(),
      refId: payment_details?.transaction_id ? payment_details?.transaction_id : '',
      additionalInfo: payment_details?.additional_info ? payment_details?.additional_info : ''
    })
    setSelectedType(payment_details?.transaction_method
      ? {
        label: translate(`appointments.${payment_details?.transaction_method}`, `${payment_details?.transaction_method}`),
        value: `${TRANSACTION_TYPE_OPTIONS.payment_details?.transaction_method}`
      }
      : '')
  }, [paymentAppt])

  const { transactionType, amount, date, refId, additionalInfo } = paymentInfo
  const [selectedType, setSelectedType] = useState([])
  const [fieldError, setFieldError] = useState({ transactionTypeErr: '', amountErr: '', dateError: '' ,amountZeroErr:''})
  const { transactionTypeErr, amountErr, dateError,amountZeroErr } = fieldError

  // handle input change
  const handleInputChange = (event) => {
    const { value, name } = event.target
    setPaymentInfo((prev) => ({ ...prev, [name]: value }))
    if (name === 'amount') {
      setFieldError({
        ...fieldError,
        amountErr: false
      })
    }
  }
  // handle amount change
const handleAmountChange=(e)=>{
  const inputValue = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      setPaymentInfo({
        ...paymentInfo,
        amount:inputValue
      })
    }
    if (inputValue) {
      setFieldError((prev) => ({
        ...prev,
        amountErr: false,
      }))
    }
    if(inputValue > 0){
      setFieldError((prev)=>({
        ...prev,
        amountZeroErr:false
      }))
    }
}
  const handleDropdownChange = (value, item) => {
    setPaymentInfo((prev) => ({ ...prev, [item]: value?.value || '' }));
    setSelectedType(value)
    setFieldError({
      ...fieldError,
      transactionTypeErr: false
    })
  }
  const handleConfirm = () => {
    if (
      !paymentInfo.amount || paymentInfo?.amount <= 0 || !paymentInfo.date || !paymentInfo.transactionType
    ) {
      setFieldError((prev) => ({
        ...prev,
        transactionTypeErr: !paymentInfo.transactionType,
        amountErr: !paymentInfo.amount,
        dateError: !paymentInfo.date,
        amountZeroErr:!paymentInfo.amount  <= 0,
      }))
    }
    else {
      const config = {
        data: {
          transaction_method: transactionType,
          amount: parseInt(amount),
          payment_date: formatDate(date),
          transaction_id: refId,
          additional_info: additionalInfo,
        }
      }
      paymentHandle(paymentAppt.appointment_id, config, ()=>{ 
        fetchAppointments() 
        onClose()
      })
    }
  }

  const onDateChange = ((date) => {
    setPaymentInfo((prev) => ({
      ...prev,
      date: formatDate(date)
    }))
    setFieldError({
      ...fieldError,
      dateError: false
    })
  })
  return (
    <>
    <FocusTrap
     focusTrapOptions={{
      clickOutsideDeactivates:true
    }}
    >
      <div className="AppointmentModal d-flex">
        <div
          className={`modalContent appointmentConfirm_popup modal_xs ${openPaymentInfo ? 'add-patient-info-popup v2 v3' : ''
            }`}
        >
          <div className="container-main">
            <div className="row mb-3">
              <div className="col-md-12">
                <h4>
                  {translate('common.paymentInfo', 'Payment Information')}
                  <CloseIcon className='close-icon ms-1'
                    style={{ float: 'right', cursor: 'pointer', color: '#75808E',position: 'relative', left: 3 }}
                    onClick={onClose}
                  />
                </h4>
              </div>
            </div>

            <div className="mt-3"></div>
            <div className="row bordered-bottom">
              <div className="col-md-12">
                <div className={`form-group first ${transactionTypeErr && 'err-input'} `}>
                  <label>
                    {translate('common.transactionMethod', 'Transaction Method')}
                    <span className="sup">*</span>
                  </label>
                  <ReactSelectDropDown
                    options={TRANSACTION_TYPE_OPTIONS}
                    value={selectedType}
                    onInputChange={(value) => handleDropdownChange(value, 'transactionType')}
                    validationErr={transactionTypeErr}
                    isDisabled={!editstatus}

                  />
                  {transactionTypeErr && (<p
                    className="fs-10 fw-500"
                    style={{ color: 'red' }}
                  >
                    {translate(
                      'common.required',
                      'This field is required'
                    )}
                  </p>)}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {translate('common.amount', 'Amount')}
                    <span className="sup">*</span>
                  </label>
                  <input
                    className={`form-control myInput ${(amountErr|| amountZeroErr) && 'input-err'}`}
                    id="amount"
                    type='text'
                    value={amount}
                    onChange={handleAmountChange}
                    autoComplete="off"
                    name="amount"
                    maxLength="8"
                    disabled={!editstatus}
                  />
                  {amountErr && <p
                    className="fs-10 fw-500"
                    style={{ color: 'red' }}
                  >
                    {translate(
                      'common.required',
                      'This field is required'
                    )}
                  </p>}
                  {amountZeroErr && <p
                    className="fs-10 fw-500"
                    style={{ color: 'red' }}
                  >
                    {translate(
                      'common.requiredValid',
                      'Please enter valid amount'
                    )}
                  </p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {translate('common.date', 'Date')}
                    <span className="sup">*</span>
                  </label>
                  <div className="row">
                    <div className={`col-md-12 ${dateError && 'validation-error'}`} >
                      <AntDatepicker
                        onInputChange={onDateChange}
                        value={date}
                        placeholder={intl.formatMessage({
                          id: 'common.dob',
                          defaultMessage: 'Date',
                        })}
                        className="form-control"
                        disabled={!editstatus}
                        isAdmin
                      />
                      {dateError && <p
                        className="fs-10 fw-500"
                        style={{ color: 'red' }}
                      >
                        {translate(
                          'common.required',
                          'This field is required'
                        )}
                      </p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 form-group">
                <label>
                  {translate('common.refId', 'Reference ID')}
                </label>
                <input className="form-control"
                  name="refId"
                  value={refId}
                  maxLength="50"
                  onChange={handleInputChange}
                  disabled={!editstatus}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 form-group">
                <label>
                  {translate('common.additionalInfo', 'Additional Info')}
                </label>
              <div className="text-area-wrap">
              <textarea
                  className="form-control input"
                  style={{
                    marginBottom: 20,
                    height: 'auto !imp',
                    resize: 'none',
                  }}
                  name="additionalInfo"
                  maxLength="250"
                  value={additionalInfo}
                  onChange={handleInputChange}
                  disabled={!editstatus}
                />
              </div>
              </div>
            </div>


            {
              editstatus ?
                (
                  <div className="col-md-12">
                    <ul className="modalBtm mt-0 gap-3 d-flex justify-content-end admin-modal-footer">
                      <li>
                        <button
                          type="button"
                          className="btn btn-light mb-0"
                          id="cancel"
                          onClick={onClose}
                        >
                          {translate('common.cancel', 'CANCEL')}{' '}
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="btn btn-primary mb-0 loader-btn" 
                          id="save"
                          onClick={handleConfirm}
                          disabled={isBtnLoading}
                        >
                          {isBtnLoading ? <ButtonLoader text={translate('common.confirm', 'CONFIRM')
                          } /> :
                            translate('common.confirm', 'CONFIRM')
                          }
                        </button>
                      </li>
                    </ul>
                  </div>
                )
                : null
            }
          </div>
        </div>
      </div>
    </FocusTrap>
    </>
  )
}

export default PaymentInfoPopUp