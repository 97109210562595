import React, { useState } from 'react';
import { translate } from '../../../Language/Translate';
import { formatDate } from '../../../Utils/DateFormatter/Date';
import ReactSelectDropDown from '../../Common/ReactSelectDropDown';
import AntMultiDatePicker from '../Common/AntMultiDatePicker';
import noResult_T1 from '../../../Assets/img/icons/theme-1/no-results-found.svg';
import noResult_T2 from '../../../Assets/img/icons/theme-2/no-results-found.svg';
import './Invoice.css';
import { useSelector } from 'react-redux';

const Invoice = () => {
  const [filterModal, setFilterModal] = useState(false);
  const data = [];
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const theme = useSelector(state => state?.SettingsReducer?.theme)

  const dateChangeHandle = (e) => {
    setStartDate(formatDate(e[0]));
    setEndDate(formatDate(e[1]));
  };

  return (
    <>
      <div>
        <div className="bread-crumbs mb-lg-2">
          <div className="wrapper links">
            <a className="previous" href="#/webconnect">
              {translate('patientPortal.home', 'Home')}
            </a>
            <span> {'>'} </span>
            <a className="bread-crumb-cursor"> {translate('invoice.invoices', 'Invoices')} </a>
          </div>
        </div>
        <div className="main-header">
          <div className="wrapper">
            <h1 className="main-title">
              {translate('invoice.invoices', 'Invoices')}
            </h1>
            <button
              className="open-filter-btn"
              onClick={() => setFilterModal(true)}
            >
              <i className="bi bi-funnel"></i>
            </button>
          </div>
        </div>
        <div className="invoices">
          <div className="wrapper">
            <div
              id="common-filter"
              className={`common-filter ${filterModal ? 'opened' : ''}`}
            >
              <button
                className="btn-close"
                onClick={() => setFilterModal(false)}
              >
                <i className="bi bi-x"></i>
              </button>
              <div className="filter-elements row">
                <div className="col-lg-3 field-container form-group">
                  <label>
                    {translate('invoice.filterName', 'Filter by Name')}
                  </label>
                  <div className="select">
                    <ReactSelectDropDown />
                  </div>
                </div>
                <div className="col-lg-6 field-container date-field icon-field date">
                  <div className="form-group pe-sm-3">
                    <label>{translate('invoice.duration', 'Duration')}</label>
                    <div>
                      <AntMultiDatePicker
                        sx={{
                          padding: '9px',
                        }}
                        onchangeDate={dateChangeHandle}
                        start={startDate}
                        end={endDate}
                      />
                    </div>
                  </div>
                  <div className="d-flex gap-3 btn-wrapper">
                    <button className="btn btn-secondary fit-content">
                      {translate('invoice.search', 'Search')}
                    </button>
                    <button className="btn btn-outlined-dark fit-content">
                      {translate('invoice.reset', 'Reset')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-overlay"></div>

            <div className="cards-container">
              {[0, 1, 2, 3, 4, 5].map((_, index) => (
                <div key={index} className="invoice-card card">
                  <div className="left-content col-lg-8">
                    <div className="icon-and-name">
                      <div className="icon-container">
                        <i className="bi bi-file-earmark-text"></i>
                      </div>
                      <div className="name-and-id">
                        <h6>AVAC1862</h6>
                        <h5>Allen Tom Varghese</h5>
                      </div>
                    </div>
                    <div className="date">
                      <span>Created on</span>
                      <p>17/08/2023, 4:30 PM</p>
                    </div>
                    <div className="remarks">
                      <span>Remarks</span>
                      <p>The headaches are located in a band like dist…</p>
                    </div>
                  </div>
                  <div className="right-content col-lg-4">
                    <div>
                      <h5 className="rate">
                        <span>₹</span> 2,000.00
                      </h5>
                    </div>
                    <button className="btn invoice-download-btn">
                      {translate('invoice.download', 'Download')}
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <button className="more-btn d-block mx-auto">
              {translate('patientView.showMore', 'Show More')}
            </button>
          </div>
          {data.length && (
            <div className="wrapper">
              <div className="empty mt-3 mb-5">
                <div className="no-results w-100">
                  <img className="no-results-img" src={
                    theme === "theme-2" ?
                      noResult_T2
                      : noResult_T1} alt="" />
                  <h6>No results found.</h6>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Invoice;
