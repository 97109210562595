import { USER_TYPE } from '../../Constants/userTypes';

export const getTopPriorityRole = (userRoles) => {
  const roleMap = {
    [USER_TYPE.subscriptionOwner]: 4,
    [USER_TYPE.admin]: 3, // Infinity represents the highest priority
    [USER_TYPE.appointmentCordinator]: 2,
    [USER_TYPE.doctor]: 1,
    [USER_TYPE.patient]: -1,
  };

  return userRoles.reduce((topRole, currentRole) => {
    return roleMap[currentRole] > roleMap[topRole] ? currentRole : topRole;
  });
};

export const sortRolesByPriority = (userRoles) => {
  // Define the priority order for roles
  const priorityOrder = [
    USER_TYPE.subscriptionOwner,
    USER_TYPE.admin,
    USER_TYPE.appointmentCordinator,
    USER_TYPE.doctor,
    USER_TYPE.patient,
  ];

  // Filter roles based on priority and maintain their original order
  const filteredRoles = userRoles.filter((role) =>
    priorityOrder.includes(role)
  );

  // Sort the filtered roles based on the priority order
  return filteredRoles.sort((roleA, roleB) => {
    const roleAIndex = priorityOrder.indexOf(roleA);
    const roleBIndex = priorityOrder.indexOf(roleB);
    return roleAIndex - roleBIndex; // Lower index (higher priority) comes first
  });
};

const activeStatus = ['ACTIVE', 'TRIALING']

// check whether the client hans an active subscritpion
export const checkAppPermission = (subscriptionData) => {
  if (
      subscriptionData.subscription_status &&
      activeStatus.includes(subscriptionData.subscription_status)
    ) {
      return true;
    }
  return false;
};

export const UNAUTHORIZED_CODES = {
  RCodeUnauthorized: {
    Code: "UNA0001",
    Details: "The user is not authorized to access the content.",
  },
  RCodeTokenNotFound: {
      Code: "UNA0002",
      Details:    "Missing authentication token in the api request.",
  },
  RCodeInvalidToken: {
      Code: "UNA0003",
      Details: "The given authentication token is invalid.",
  },
  RCodeTokenExpired: {
      Code: "UNA0004",
      Details: "The given authentication token is expired.",
  },
  RCodeRoleNotFound: {
      Code: "UNA0005",
      Details: "Missing user role in the api request.",
  },
  RCodePermissionDenied: {
      Code: "UNA0006",
      Details: "Missing user permission to access this content.",
  },
  RCodeRolePermissionDenied: {
      Code: "UNA0007",
      Details: "Provided user role dosen't have permission to access this content.",
  },
  RCodeUserActionPermissionDenied: {
      Code: "UNA0008",
      Details: "Requested user doesn't have permission to do this action for the user.",
  },
}

export const isUnauthorizedAction = (code) => {
  return (
      code === UNAUTHORIZED_CODES.RCodeRoleNotFound.Code ||
      code === UNAUTHORIZED_CODES.RCodePermissionDenied.Code ||
      code === UNAUTHORIZED_CODES.RCodeRolePermissionDenied.Code ||
      code === UNAUTHORIZED_CODES.RCodeUserActionPermissionDenied.Code
    )
}
