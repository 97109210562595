import { LOCALES } from '../Locales';

const message = {
  [LOCALES.JAPANESE]: {
    login: {
      title: 'クライアント管理ポータル',
    },
  },
};
export default message;
