import React from 'react';
import Error404 from '../../Assets/img/icons/404.png';
import '../../Assets/style/NoData.css';
import { translate } from '../../Language/Translate';

const InvalidToken = () => {
  return (
    <div className="page-not-found">
      <img src={Error404} alt="404" />
      <h4>
        {translate(
          'common.tryAgain',
          'Unable to process the request. Please try again'
        )}
        !
      </h4>
    </div>
  );
};

export default InvalidToken;
