import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../Language/Translate';
import { CancelAppointment } from '../../../Actions/appointmentAction';
import ButtonLoader from 'Components/Common/ButtonLoader';

const AppointmentsCancelModal = (props) => {
  const {
    open,
    onClose,
    onCancelAppointment,
    fetchAppointments,
    setCancel,
    cancelId,
    reason,
    setReason,
    success,
    isBtnLoading,
  } = props;
  const { cancelAppt: cancelApptLoading = false } = isBtnLoading;
  const inputRef = useRef();
  const backBtnRef = useRef();

  const modalRef = useRef();
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        document.body.classList.add('modal-overflow');
        setTimeout(()=>{
          inputRef?.current?.focus()
        },100)
      }, 0);
    } else {
      document.body.classList.remove('modal-overflow');
    }
    return () => document.body.classList.remove('modal-overflow');
  }, [open]);
  useEffect(() => {
    setCancel();
    const listener = (event) => {
      if (!modalRef.current || modalRef.current.contains(event.target)) {
        return;
      }
      if (event.button === 0) onClose();
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [modalRef, onClose]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setReason(value);
  };
  //handle cancel click
  const handleCancel = () => {
    const config = {
      data: {
        appt_status: 'canceled',
        cancelation_reason: reason,
      },
    };
    onCancelAppointment(cancelId, config, fetchAppointments, success, false);
  };
  return (
    <>
      <div className={`offcanvas-backdrop fade ${open && 'show'}`}></div>

      <div
        className={`offcanvas offcanvas-end custom-off-canvas cancel-appointment-canvas ${
          open && 'show'
        }`}
        ref={modalRef}
      >
        <div className="offcanvas-header">
          <h5>{translate('cancelAppt.cancelappt', 'Cancel Appointment')}</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            tabIndex="0"
            onKeyDown={(e)=>{
              if(e.key === "Tab" && e.shiftKey) e.preventDefault()
            }}
            onClick={onClose}
          ></button>
        </div>
        <div className="offcanvas-body">
          <h6>
            {translate(
              'cancelAppt.areYouSure',
              'Are you sure, you want to cancel this appointment?'
            )}
          </h6>
          <p>
            {translate(
              'cancelAppt.pleaseEnter',
              'If so, please enter a reason for cancelling your appointment.'
            )}
          </p>
          <div className="form-group">
            <label htmlFor="reason">
              {translate('cancelAppt.reason', 'Reason for Cancellation')} {'('}
              {translate('common.optional', 'Optional')}
              {')'}
            </label>
            <div className="text-area-wrap">
            <textarea
              className="input form-control text-area"
              name="reason"
              id="reason"
              cols="30"
              maxLength="250"
              rows=""
              placeholder="eg: Unable to reach"
              value={reason}
              ref={inputRef}
              onChange={handleInputChange}
            ></textarea>
            </div>
          
          </div>
          <div className="footer-btn-wrap gap-3 pt-2 mt-3">
            <button
              className={`btn btn-outlined-dark px-4 ${cancelApptLoading && 'loader-btn'}`}
              onClick={() => handleCancel()}
              aria-controls="cancelSuccess"
              disabled={cancelApptLoading}
            >
              {cancelApptLoading ? (
                <ButtonLoader
                  text={translate(
                    'cancelAppt.yesCancelAppt',
                    'Yes, Cancel my Appointment'
                  )}
                />
              ) : (
                translate(
                  'cancelAppt.yesCancelAppt',
                  'Yes, Cancel my Appointment'
                )
              )}
            </button>
            <button className="btn btn-secondary" 
            ref={backBtnRef}
            onKeyDown={(e)=>{
              if(e.key === "Tab" && !e.shiftKey){
                e.preventDefault()
                inputRef.current.focus()
              }
            }}
            onClick={() => onClose()}>
              {translate('cancelAppt.noCancelAppt', 'No, Back to listing')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isBtnLoading: state.AppointmentReducer.isBtnLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onCancelAppointment: (
    id,
    data,
    fetchAppointments,
    onHandleCancelClose,
    troasterstatus
  ) =>
    dispatch(
      CancelAppointment(
        id,
        data,
        fetchAppointments,
        onHandleCancelClose,
        troasterstatus
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentsCancelModal);
