// reducers/index.js
import { combineReducers } from 'redux';
import ToasterReducer from './toasterReducer';
import DepartmentReducer from './departmentReducer';
import LeaveReducer from './leaveReducer';
import AppointmentReducer from './appointmentReducer';
import SettingsReducer from './settingsReducer';
import LoginReducer from './loginReducer';
import UsersReducer from './usersReducer';
import MedicalRecordReducer from './medicalRecordReducer';
import PrescriptionsReducer from './prescriptionsReducer'
import doctorListingReducer from './doctorListingReducer'
import ProfileReducer from './profileReducer'

const rootReducer = combineReducers({
  ToasterReducer,
  DepartmentReducer,
  LeaveReducer,
  AppointmentReducer,
  SettingsReducer,
  LoginReducer,
  UsersReducer,
  MedicalRecordReducer,
  PrescriptionsReducer,
  doctorListingReducer,
  ProfileReducer
});

export default rootReducer;
