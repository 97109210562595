import React, { useEffect, useRef, useState } from 'react';
import PhoneNumberInput from '../../Common/PhoneInput';
import './BookAppointment.css';
import lock from 'Assets/img/icons/lock-circle.svg';
import { translate } from '../../../Language/Translate';
import { formatDate, timeZoneDate } from '../../../Utils/DateFormatter/Date';
import {
  AddAppointmentData,
  updateEmail,
} from '../../../Actions/appointmentAction';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { convertToBase64 } from 'Utils/Services/Authenticator';
import { setLocalStorageItem } from 'Utils/LocalStorage/local-storage';
import { getProfileDetails } from 'Actions/patientProfileAction';
import { PAYMENT_TYPE, RELATIONS_LABEL } from 'Constants/constant';
import { validateEmail } from 'Utils/Validation/validator';
import ButtonLoader from 'Components/Common/ButtonLoader';
import loadingImg from '../../../Assets/img/icons/search-loader.gif';

function PatientInfoModal({
  myInfo,
  setmyInfo,
  othersInfo,
  setOthersInfo,
  myError,
  setMyError,
  conformhandle,
  selectedSlot,
  doctorData,
  addAppointment,
  userDetails,
  patientDetails,
  setAppId,
  checked,
  setChecked,
  newdate,
  checkBoxErr,
  setCheckBoxErr,
  addFamilyMember,
  selectedMember,
  setSelectedMember,
  memberSuggestion,
  setMemberSuggestion,
  searchMember,
  setSearchMember,
  othersFormError,
  setOthersFormError,
  selectedTab,
  setSelectedTab,
  showMyInfo,
  setShowMyInfo,
  familyMember,
  getLinkedProfileList,
  getPatientDetails,
  openPatientInfo,
  loggedIn,
  defaultCountryCode,
  applicationData,
  isBtnLoading,
  listLoading,
  newMemberDetails,
  otpState,
  updateExceedModal,
  deptValue
}) {
  const [numberLength, setNumberLength] = useState(10); 
  const [code ,setCode]=useState('IN')
  // const history = useHistory();
  const tabRef = useRef(null)
  const firstField = useRef([])
  const nameRef = useRef()
  const emailRef = useRef()
  const reasonRef = useRef()
  const someoneEmailRef =useRef()
  const someoneReasonRef = useRef()
  const mrnRef = useRef()
  const someOnemrnRef = useRef()
  const patientNameRef = useRef()
  const { nameError, mobileNoError, reasonError } = myError;
  const { patientNameError, patientReasonError } = othersFormError;
  const { myName, lastName, dob, gender, mobileNo, email, reason, mrn } = myInfo;
  const { fName, lName, patientId = '', phno = '', dob: dobLinkedPatient, blood_group, gender: genderLinkedPatient, relation, country, age, user_id } = newMemberDetails
  const { patientName, patientEmail, patientReason } = othersInfo;
  const [focusInput, setFocusInput] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [patientData, setPatientData] = useState({
    patient_id: '',
    blood_group: '',
  });
  const [emailFormatErr, setEmailFormatErr] = useState(false);
  const { addAppt: addApptLoading = false } = isBtnLoading;
  const { otpStatus } = otpState

  const nodata = [
    {
      name: translate('patientPortal.noMember', 'No members found'),
      disabled: true,
    },
  ];

  useEffect(() => {
    setPatientData({
      patient_id: patientDetails?.patient_id,
      blood_group: patientDetails?.blood_group,
    });
  }, [patientDetails]);

  useEffect(() => {
    if (patientId) {
      setSearchMember(`${fName} ${lName}`)
      setSelectedMember({
        name: fName,
        last_name: lName,
        patientId: patientId,
        patient_id: patientId,
        phone: phno,
        dob: dobLinkedPatient,
        blood_group: blood_group,
        gender: genderLinkedPatient,
        mrn: '',
        newMrn: ''
      });

      const relationObj = RELATIONS_LABEL.find(
        (rel) => rel.value === relation
      );
      let currentValues = {
        patientName: fName,
        relation: relationObj,
        phno: phno,
        country: country
          ? country
          : othersInfo.country,
        gender: genderLinkedPatient,
        patientLastName: lName,
        patient_id: patientId,
        dob: dobLinkedPatient ? dobLinkedPatient : null,
        age: age,
        user_id: user_id,
        patientReason: '',
        // patientEmail: patient_details?.email,
        blood_group: blood_group
          ? {
            value: blood_group,
            label: blood_group,
          }
          : null,
      };
      setOthersInfo(currentValues);
    }
    if ((phno && !otpStatus)) {
      setSearchMember('')
      setSelectedMember({
        name: '',
        last_name: '',
        patientId: '',
        patient_id: '',
        phone: '',
        dob: '',
        blood_group: '',
        gender: '',
        mrn: '',
        newMrn: ''
      });
    }
  }, [patientId, otpStatus]);


  const tabLabel = [
    {
      label: translate('patientPortal.forMySelf', 'for Myself'),
    },
    {
      label: translate('patientPortal.forSomeOne', 'for Someone else'),
    },
  ];

  useEffect(() => {
    if (!openPatientInfo) {
      setFocusInput(false);
    }
  }, [openPatientInfo]);

  useEffect(() => {
    if (loggedIn) {
      getLinkedProfileList();
    }
  }, [openPatientInfo]);

  useEffect(() => {
    setMemberSuggestion(familyMember?.length ? familyMember : nodata);
  }, [familyMember]);

  // function to select a patient name from patient suggestion list
  const handleSelectMember = (i) => {
    if (!i.disabled) {
      setEditStatus(true);
      setSelectedMember(i);
      setMemberSuggestion([]);
      setFocusInput(false);
      setSearchMember(i.name + ' ' + i.last_name);
      getPatientDetails(i.patient_id, onGetPatientDetailsSuccess);
      setOthersFormError({
        ...othersFormError,
        patientNameError: false,
      });
    }
  };
  const getAge = (birthDate) =>
    Math.floor((timeZoneDate() - new Date(birthDate).getTime()) / 3.15576e10);

  // handle select patient name success
  const onGetPatientDetailsSuccess = (result) => {
    let { patient_details, profile_link_details } = result;
    const relationObj = RELATIONS_LABEL.find(
      (rel) => rel.value === profile_link_details?.relation
    );
    let currentValues = {
      patientName: patient_details?.name,
      relation: relationObj,
      phno: patient_details?.phone,
      country: patient_details?.tele_country_code
        ? patient_details?.tele_country_code
        : othersInfo.country,
      gender: patient_details?.gender,
      patientLastName: patient_details?.last_name,
      patient_id: patient_details?.patient_id,
      dob: patient_details?.dob ? patient_details?.dob : null,
      age: patient_details?.dob
        ? `${getAge(formatDate(patient_details?.dob))}`
        : '',
      user_id: patient_details?.user_id,
      // patientReason: patientReason.length? patientReason:'',
      patientEmail: patient_details?.email,
      blood_group: patient_details?.blood_group
        ? {
            value: patient_details?.blood_group,
            label: patient_details?.blood_group,
          }
        : null,
    };
    setOthersInfo((prev) => ({... prev, ...currentValues}));
  };
  // handle input change for mySelf
  const handleInputChange = (event) => {
    const { value, name } = event.target;
    setmyInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'myName') {
      setMyError((prev) => ({
        ...prev,
        nameError: false,
      }));
    }
    if (name === 'mobileNo') {
      setMyError((prev) => ({
        ...prev,
        mobileNoError: false,
      }));
    }
    if (name === 'reason') {
      setMyError((prev) => ({
        ...prev,
        reasonError: false,
      }));
    }
    if (name === 'email') {
      if (validateEmail(value) || value === '') {
        setEmailFormatErr(false);
      }
    }
  };

  // handle input change for someone else
  const handleOtherInputChange = (event) => {
    const { value, name } = event.target;
    setOthersInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'patientName') {
      setOthersFormError((prev) => ({
        ...prev,
        patientNameError: false,
      }));
    }
    if (name === 'patientReason') {
      setOthersFormError((prev) => ({
        ...prev,
        patientReasonError: false,
      }));
    }
    if (!searchMember) {
      setSelectedMember('');
    }
    if (name === 'patientEmail') {
      setEditStatus(false);
      if (validateEmail(value) || value === '') {
        setEmailFormatErr(false);
      }
    }
  };

  const handleOtherMrnChange = (e) => {
    const { value } = e.target;
    setSelectedMember((prev) => ({
      ...prev,
      newMrn: value,
    }));
  }

  //  handle book for my self success
  const handleSuccess = () => {
    updateEmail(userDetails, email);
    conformhandle();
    setMyError({
      nameError: false,
      mobileNoError: false,
      reasonError: false,
    });
    const base64 = convertToBase64(userDetails);
    setLocalStorageItem('user', base64);
  };

  // handle book for someone else success
  const handleOthersSuccess = () => {
    conformhandle();
    setMyError({
      patientNameError: false,
      patientReasonError: false,
    });
    setSelectedMember('');
    setSearchMember('');
    setEmailFormatErr(false);
  };

  // handle pay at clinic check
  const handlePayAtClinic = () => {
    if (showMyInfo) {
      let isValid = true;
      if (!myName || !mobileNo || !reason.trim().length || !checked) {
        isValid = false;
        setMyError((prev) => ({
          ...prev,
          nameError: !myName,
          mobileNoError: !mobileNo,
          reasonError: !reason.trim().length,
        }));
        if (!checked) {
          setCheckBoxErr(true);
        }
      }
      if (email) {
        if (!validateEmail(email)) {
          isValid = false;
          setEmailFormatErr(true);
        }
      }
      if (isValid) {
        const config = {
          data: {
            doctor_id: doctorData.doctor_id,
            dept_name: deptValue?.label,
            dept_id:deptValue?.value,
            schedule_type: 'op',
            appt_date: formatDate(newdate),
            appt_start_time: selectedSlot.start_time,
            appt_end_time: selectedSlot.end_time,
            appt_slot_session: selectedSlot.session,
            reason: reason,
            patient_details: {
              patient_id: patientData.patient_id,
              name: myName,
              last_name: lastName,
              blood_group: patientData.blood_group,
              email: email,
              dob: dob,
              gender: gender,
              mrn
            },
          },
        };
        addAppointment(config, handleSuccess, setAppId,updateExceedModal);
      }
    } else {
      let isValid = true;
      if (!patientName || !patientReason.trim().length || !checked || !selectedMember?.name) {
        isValid = false;
        setOthersFormError({
          ...othersFormError,
          patientNameError: !selectedMember.name,
          patientReasonError: !patientReason.trim().length,
        });
        if (!checked) {
          setCheckBoxErr(true);
        }
        if (!selectedMember?.name) {
          tabRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }
      if (patientEmail) {
        if (!validateEmail(patientEmail)) {
          isValid = false;
          setEmailFormatErr(true);
        }
      }
      if (isValid) {
        const config = {
          data: {
            doctor_id: doctorData.doctor_id,
            dept_name: deptValue?.label,
            dept_id: deptValue?.value,
            schedule_type: 'op',
            appt_date: formatDate(newdate),
            appt_start_time: selectedSlot.start_time,
            appt_end_time: selectedSlot.end_time,
            appt_slot_session: selectedSlot?.session,
            reason: patientReason,
            patient_details: {
              patient_id: selectedMember.patient_id,
              name: selectedMember.name,
              last_name: selectedMember.last_name
                ? selectedMember.last_name
                : '',
              blood_group: selectedMember.blood_group
                ? selectedMember.blood_group
                : '',
              email: patientEmail,
              dob: selectedMember.dob,
              gender: selectedMember.gender ? selectedMember.gender : '',
              mrn: selectedMember.mrn ? selectedMember.mrn : selectedMember.newMrn ?? '',
            },
          },
        };
        addAppointment(config, handleOthersSuccess, setAppId,updateExceedModal);
      }
    }
  };

  // handle tab switch
  const handleTabChange = (index) => {
    setSelectedTab(index);
    setFocusInput(false);
    setmyInfo({
      myName: myName,
      lastName: lastName,
      dob: dob,
      gender: gender,
      mobileNo: mobileNo,
      email: email,
      mrn: mrn,
      reason: '',
    });
    setOthersInfo({
      patientName: '',
      patientLastName: '',
      patientDob: '',
      patientMobileNo: '',
      patientEmail: '',
      patientReason: '',
    });
    setMyError({
      nameError: false,
      mobileNoError: false,
      reasonError: false,
    });
    setOthersFormError({
      patientNameError: false,
      patientReasonError: false,
    });
    setChecked(false);
    setCheckBoxErr(false);
    setSelectedMember('');
    setSearchMember('');
    setEmailFormatErr(false);
  };

  // handle for myself click
  const myInfoClick = () => {
    setShowMyInfo(true);
  };

  // handle for someone else click
  const someOneElseInfoClick = () => {
    setShowMyInfo(false);
    const token = localStorage.getItem('access_token');
    if (loggedIn && token && !familyMember.length) {
      getLinkedProfileList();
    }
  };

  // handle condtion checkbox click
  const handleCheck = () => {
    setChecked(!checked);
    setCheckBoxErr(false);
  };

  // handle terms of use click
  const handleTermsOfUse = (e) => {
    e.preventDefault();
    window.open(`${window.location.origin}/#/webConnect/terms-of-use`, "_blank")
    // history.push('32eeedc   v');
  };
  // handle linked patient name search
  const handleSearchChange = (event) => {
    const { value } = event.target;
    const filteredMember = familyMember.filter((member) => ((member.name + " " + member.last_name).toLowerCase().includes(value.toLowerCase().trim()))
    );
    setMemberSuggestion(filteredMember?.length ? filteredMember : nodata);
    setSearchMember(value);
    setSelectedMember({});
    setOthersFormError({
      ...othersFormError,
      patientNameError: false,
      patientReasonError: false,
    });
  };

  function capitalize(s) {
    return s && s.length ? s[0].toUpperCase() + s.slice(1) : '-';
  }

  return (
    <>
      <div className="patient-info-section small-modal">
        <div className="form-group"  ref={tabRef}>
          <span className="label">
            {translate(
              'patientPortal.inClinicAppointment',
              'This in-clinic appointment is'
            )}
          </span>
        </div>
        <ul
          className="nav nav-pills mb-3 custom-tab-v2"
          id="pills-tab"
          role="tablist"
        >
          {tabLabel.map((tab, index) => (
            <li
              className="nav-item"
              key={index + 1}
              onClick={index == 0 ? myInfoClick : someOneElseInfoClick}
            >
              <button
                className={`btn nav-link ${
                  index === selectedTab ? 'active' : ''
                }`}
                onClick={() => handleTabChange(index)}
                onKeyDown={(e) => {
                  if (index === 0 && e.key === "Tab" && e.shiftKey) e.preventDefault()
                  if (index === 1 && selectedTab === 0 && e.key === "Tab" && !e.shiftKey) {
                    e.preventDefault()
                    if (userDetails && !userDetails.name) {
                      nameRef?.current?.focus()
                    }
                    else if (userDetails && !userDetails.email) {
                      emailRef?.current?.focus()
                    }
                    else if (selectedTab === 1 && !selectedMember.mrn){
                      someOnemrnRef?.current?.focus()
                    }   
                    else if(selectedTab === 0 && patientDetails && !patientDetails.mrn){
                      mrnRef?.current?.focus()
                    }             
                    else{
                      reasonRef?.current?.focus()

                    }
                  }
                }}
                id="pills-home-tab"
                type="button"
                role="tab"
                ref={(ref) => (firstField.current[index] = ref)}
              >
                {tab.label}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {showMyInfo ? (
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabIndex="-1"
            >
              <div>
                <div className="form-group mb-4">
                  <label htmlFor="name" className="mandatory d-flex">
                    {translate('common.name', 'Name')}
                  </label>
                  <input
                    id="name"
                    type="text"
                    className={`input w-100 ${nameError ? 'input-err' : ''}`}
                    placeholder="i.e, John Doe"
                    value={myName + ' ' + lastName}
                    name="myName"
                    maxLength="40"
                    disabled={userDetails && !!userDetails.name}
                    ref={nameRef}
                    onChange={handleInputChange}
                  />

                  {nameError ? (
                    <span className="err">
                      {translate('common.required', 'This field is required.')}
                    </span>
                  ) : null}
                </div>
                <div className='row'>
                  <div className={applicationData?.use_system_generated_mrn ? "col-md-12" : "col-md-8"}>
                  <div className="form-group mb-4">
                    <label htmlFor="phno" className="lable mandatory">
                      {translate('patientPortal.mobileNo', 'Mobile Number')}
                    </label>
                    <div className="phone-field gap-3"
                    tabIndex="-1"
                    >
                      <div className="dropdown country-code-picker">
                        <PhoneNumberInput
                          editStatus={
                            userDetails && userDetails.tele_country_code
                          }
                          value={
                            userDetails?.tele_country_code ??
                            defaultCountryCode.toString()
                          }
                          numberLength={numberLength}
                        setNumberLength={setNumberLength}
                        code={code}
                        setCode={setCode}
                        />
                      </div>
                      <input
                        type="tel"
                        id="phno"
                        placeholder="i.e, 98950 98950"
                        className={`form-control grey input  ${mobileNoError ? 'input-err' : ''
                      }`}
                        value={mobileNo}
                        maxLength="16"
                        disabled={userDetails && !!userDetails.phone}
                        name="mobileNo"
                        onChange={handleInputChange}
                      />
                      <img className='lock' src={lock} />
                    </div>
                    {mobileNoError ? (
                      <span className="err">
                        {translate('common.required', 'This field is required.')}
                      </span>
                    ) : null}
                  </div>
                  </div>
                  {applicationData?.use_system_generated_mrn ? (
                    ''
                  ) : (
                  <div className="col-md-4">
                  <div className="form-group mb-4">
                    <label htmlFor="phno" className="lable">
                      {translate('appointments.patientMRN', 'Patient MRN')}
                    </label>
                      <input
                        type="text"
                        id="phno"
                        placeholder="i.e, TC90025"
                        className={`form-control input`}
                        value={mrn}
                        maxLength="16"
                        disabled={patientDetails && !!patientDetails.mrn}
                        name="mrn"
                        onChange={handleInputChange}
                        ref={mrnRef}
                        onKeyDown={(e)=>{
                          if(e.key === "Tab" && e.shiftKey){
                            e.preventDefault()
                            firstField.current[1].focus()
                          }
                        }}
                      />
                  </div>
                  </div>)}
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="email">
                    {translate('patientPortal.email', 'Email Id')}
                  </label>
                  <input
                    id="email"
                    type="email"
                    className={`input w-100 ${emailFormatErr ? 'input-err' : ''
                      }`}
                    placeholder="i.e, johnoe@gmail.com"
                    value={email}
                    maxLength="50"
                    disabled={userDetails && !!userDetails.email}
                    name="email"
                    onChange={handleInputChange}
                    ref={emailRef}
                    onKeyDown={
                      (e) => {
                        if (e.key === "Tab" && e.shiftKey) {
                          e.preventDefault()
                          if (!userDetails.name) {
                            nameRef.current.focus()
                          }
                          else {
                            firstField.current[1].focus()
                          }
                        }
                      }
                    }
                  />
                  {emailFormatErr ? (
                    <span className="err">
                      {translate('users.invalidMail', 'Invalid Email id')}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="reason" className="mandatory">
                    {translate(
                      'myAppointments.reason',
                      'Reason for Appointment'
                    )}
                  </label>
                  <div className="text-area-wrap">
                  <textarea
                    id="reason"
                    cols="30"
                    rows="3"
                    placeholder="e.g. General Check-up"
                    className={`input form-control  ${reasonError ? 'input-err' : ''
                      }`}
                    value={reason}
                    maxLength="200"
                    name="reason"
                    onChange={handleInputChange}
                    ref={reasonRef}
                    onKeyDown={(e) => {
                      if(e.key === "Tab" && e.shiftKey){
                        e.preventDefault()
                        if (!userDetails.email) {
                          emailRef.current.focus()
                        }
                        else if (patientDetails && !patientDetails.mrn) mrnRef.current.focus()
                        else if (!userDetails.name) {
                          nameRef.current.focus()
                        }
                        else {
                          firstField.current[1].focus()
                        }
                      }
                    }}
                  ></textarea>
                  </div>
                  {reasonError ? (
                    <span className="err">
                      {translate('common.required', 'This field is required.')}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div
              className=""
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabIndex="-1"
            >
              <div>
                <div className="form-group mb-4">
                  <div className="d-flex align-items-center gap-2 justify-content-between">
                    <label htmlFor="patientname" className="mandatory">
                      {translate('patientPortal.patientName', 'Patient’s Name')}
                    </label>
                    <a
                      role="button"
                      className="small color-primary td-none fw-semibold d-none d-sm-block"
                      data-bs-toggle="modal"
                      data-bs-target="#addFamilyMember"
                      data-bs-dismiss="modal"
                      onClick={addFamilyMember}
                      onKeyDown={(e)=>{
                        if(e.key === "Enter" && !e.shiftKey){
                          addFamilyMember()
                        }
                      }}
                      tabIndex={0}
                    >
                      {translate(
                        'patientPortal.addMember',
                        '+ Add New Member'
                      )}
                    </a>
                    <a
                      role="button"
                      className="small color-primary td-none fw-semibold d-block d-sm-none"
                      data-bs-toggle="modal"
                      data-bs-target="#addFamilyMember"
                      data-bs-dismiss="modal"
                      onClick={addFamilyMember}
                    >
                      {translate('patientPortal.add', '+ Add')}
                    </a>
                  </div>
                  <div className="custom-search-dr">
                    <div className="inner-wrapper">
                      <div
                        className="input-dr loading v2"
                        id="dropdownMenu2"
                        // data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <input
                          id="patient-name"
                          type="text"
                          className={`input w-100 ${patientNameError ? 'input-err' : ''
                            }`}
                          placeholder="i.e, John Doe"
                          name="patientName"
                          autoComplete="off"
                          value={searchMember}
                          onChange={handleSearchChange}
                          ref={patientNameRef}
                          onFocus={() => {
                            setMemberSuggestion(
                              familyMember?.length ? familyMember : nodata
                            );
                            setFocusInput(true);
                          }}
                          onBlur={() =>
                            setTimeout(() => setMemberSuggestion([]), 200)
                          }
                          onKeyDown={
                            (e)=>{
                              if(e.key === "Tab" && !e.shiftKey)
                              {
                                e.preventDefault()
                                if(!selectedMember.mrn){
                                  someOnemrnRef.current.focus()
                                }
                                else if(!patientEmail){
                                 someoneEmailRef.current.focus()
                               }
                               else{
                                someoneReasonRef.current.focus()
                               }
                              }
                            }
                          }
                        />
                        {listLoading ? (
                          <img
                            className="loading-img"
                            src={loadingImg}
                            alt={'loading image'}
                          />
                        ) : null}
                        <i
                          className="bi bi-chevron-down"
                          onClick={() => {
                            setMemberSuggestion(
                              familyMember?.length ? familyMember : nodata
                            );
                            setFocusInput(!focusInput);
                          }}
                        ></i>
                      </div>
                      {memberSuggestion.length ? (
                        <ul
                          className={`dropdown-menu ${memberSuggestion.length && focusInput ? 'show' : ''
                            }`}
                          aria-labelledby="dropdownMenu2"
                        >
                          <div className="list">
                            {memberSuggestion.map((member) => (
                              <>
                                <li
                                  onClick={() => handleSelectMember(member)}
                                  className='text-capitalize'
                                  style={{
                                    cursor: member.disabled ? 'default' : '',
                                  }}
                                >
                                  <span className={member.disabled?'disabled' : ''}>
                                    {member.name} {member.last_name}
                                  </span>{' '}
                                  {!member.disabled && (
                                    <span className="relation">
                                      {capitalize(member.relation)}
                                    </span>
                                  )}
                                </li>
                              </>
                            ))}
                          </div>
                          {/* <div className="fixed-link">
                          <a data-bs-toggle="modal" data-bs-target="#addFamilyMember" data-bs-dismiss="modal" onClick={addFamilyMember}>{translate('patientPortal.addFamilyMember', '+ Add Family Member')}</a>
                        </div> */}
                        </ul>
                      ) : null}
                      {patientNameError ? (
                        <span className="err">
                          {translate(
                            'common.nameError',
                            'Please select from the list or Add family member!'
                          )}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="phno" className="lable mandatory">
                    {translate('patientPortal.myMobileNo', 'My Mobile number')}
                  </label>
                  <div className="phone-field gap-3">
                    <div className="dropdown country-code-picker">
                      <PhoneNumberInput
                        editStatus={
                          userDetails && userDetails.tele_country_code
                        }
                        value={
                          userDetails?.tele_country_code ??
                          defaultCountryCode.toString()
                        }
                        numberLength={numberLength}
                        setNumberLength={setNumberLength}
                        code={code}
                        setCode={setCode}
                      />
                    </div>
                    <input
                      disabled
                      type="tel"
                      id="phno"
                      placeholder="i.e, 98950 98950"
                      className="form-control grey input"
                      value={mobileNo}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className={applicationData?.use_system_generated_mrn ? 'col-md-12' : 'col-md-8' }>
                    <div className="form-group mb-4">
                      <label htmlFor="patientphno" className="lable ">
                        {translate(
                          'patientPortal.patientMoblNo',
                          'Patient’s Phone Number'
                        )}
                      </label>
                      <div className="phone-field gap-3">
                        <div className="dropdown country-code-picker">
                          <PhoneNumberInput
                            editStatus={true}
                            value={
                              selectedMember?.tele_country_code ||
                              userDetails?.tele_country_code ||
                              defaultCountryCode.toString()
                            }
                            numberLength={numberLength}
                            setNumberLength={setNumberLength}
                            code={code}
                            setCode={setCode}
                          />
                        </div>
                        <input
                          type="tel"
                          id="patientphno"
                          // placeholder="i.e, 98950 98950"
                          className="form-control grey input"
                          disabled
                          value={selectedMember ? selectedMember.phone || mobileNo : ''}
                          name="patientMobileNo"
                        />
                      </div>
                    </div>
                  </div>
                  {applicationData?.use_system_generated_mrn ? (
                    ''
                  ) : (
                  <div className="col-md-4">
                  <div className="form-group mb-4">
                    <label htmlFor="phno" className="lable">
                      {translate('appointments.patientMRN', 'Patient MRN')}
                    </label>
                      <input
                        type="text"
                        id="phno"
                        placeholder="i.e, TC90025"
                        className={`form-control input`}
                        value={selectedMember.newMrn || selectedMember.mrn || ''}
                        maxLength="16"
                        disabled={selectedMember.mrn}
                        name="mrn"
                        onChange={handleOtherMrnChange}
                        ref={someOnemrnRef}
                        onKeyDown={(e)=>{
                          if(e.key === "Tab" && e.shiftKey){
                            e.preventDefault()
                            patientNameRef.current.focus()
                          }
                        }}
                      />
                  </div>
                  </div>)}
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="patientemail">
                    {translate(
                      'patientPortal.patientEmail',
                      'Patient’s Email Id'
                    )}
                  </label>
                  <input
                    id="patientemail"
                    type="email"
                    className={`input w-100 ${emailFormatErr ? 'input-err' : ''
                      }`}
                    placeholder="i.e, johnoe@gmail.com"
                    onChange={handleOtherInputChange}
                    value={patientEmail ? patientEmail : ''}
                    name="patientEmail"
                    maxLength="50"
                    disabled={editStatus && patientEmail}
                    ref={someoneEmailRef}
                      onKeyDown={
                        (e)=>{
                          if(e.key === "Tab" && e.shiftKey){
                            e.preventDefault()
                            if(!selectedMember.mrn){
someOnemrnRef.current.focus()
                            }
                            else
                            {
                              patientNameRef.current.focus()
                            }
                          }
                        }
                      }
                  />
                  {emailFormatErr ? (
                    <span className="err">
                      {translate('users.invalidMail', 'Invalid Email id')}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="patientreason" className="mandatory">
                    {translate(
                      'myAppointments.reason',
                      'Reason for Appointment'
                    )}
                  </label>
                  <div className="text-area-wrap">
                  <textarea
                    id="patientreason"
                    cols="30"
                    rows="3"
                    placeholder="e.g. General Check-up"
                    className={`input form-control  ${patientReasonError ? 'input-err' : ''
                      }`}
                    onChange={handleOtherInputChange}
                    value={patientReason}
                    name="patientReason"
                    maxLength="200"
                    ref={someoneReasonRef}
                    onKeyDown={
                      (e)=>{
                        if(e.key === "Tab"  && e.shiftKey && patientEmail){
                          e.preventDefault()
                          someoneEmailRef.current.focus()
                        }
                      }
                    }
                  ></textarea>
                  </div>
                  {patientReasonError ? (
                    <span className="err">
                      {translate('common.required', 'This field is required.')}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="form-group mb-4">
          <div className="d-flex align-items-center gap-2 otp">
            <input
              className={`form-check-input ${checkBoxErr ? 'cbox-err' : ''}`}
              type="checkbox"
              id="terms"
              checked={checked}
              onClick={handleCheck}
              onKeyDown={(e)=>{
                if(e.key === "Enter" && !e.shiftKey) handleCheck()
              }}
            />
            <label className="fw-medium mt-2 pb-0 mandatory">
              {translate(
                'patientPortal.agreed',
                'I have read and agreed to the'
              )}{' '}
              <a onClick={handleTermsOfUse} className="color-primary fw-600">
                {translate('patientPortal.terms', 'Terms of Use')}
              </a>
            </label>
          </div>
          {checkBoxErr && (
            <span className="err checkbox-err">
              {translate(
                'checkboxReq.required',
                'Please allow consent before proceeding'
              )}
            </span>
          )}
        </div>

        <div className="d-flex align-items-center gap-3 footer-btns">
          {applicationData?.payment_type === PAYMENT_TYPE.online ? (
            <button
              onKeyDown={(e) => {
                if (e.key === "Tab" && !e.shiftKey) {
                  e.preventDefault()
                  firstField.current[0].focus()
                }
              }}
              className="btn btn-outlined-dark w-100">
              {translate('patientPortal.payOnline', 'Pay Online')}
            </button>
          ) : applicationData?.payment_type === PAYMENT_TYPE.clinic ? (
            <button
              className="btn btn-primary
                     w-100 loader-btn"
              onClick={handlePayAtClinic}
              disabled={addApptLoading}
              onKeyDown={(e) => {
                if (e.key === "Tab" && !e.shiftKey) {
                  e.preventDefault()
                  firstField.current[0].focus()
                }
              }}
            >
              {addApptLoading ? (
                <ButtonLoader
                  text={translate('patientPortal.payAtClinic', 'Pay at Clinic')}
                />
              ) : (
                translate('patientPortal.payAtClinic', 'Pay at Clinic')
              )}
            </button>
          ) : applicationData?.payment_type === PAYMENT_TYPE.both ? (
            <>
              <button className="btn btn-outlined-dark w-100">
                {translate('patientPortal.payOnline', 'Pay Online')}
              </button>
              <button
                className="btn btn-primary
                     w-100 loader-btn"
                onClick={handlePayAtClinic}
                disabled={addApptLoading}
                onKeyDown={(e) => {
                  if (e.key === "Tab" && !e.shiftKey) {
                    e.preventDefault()
                    firstField.current[0].focus()
                  }
                }}
              >
                {addApptLoading ? (
                  <ButtonLoader
                    text={translate(
                      'patientPortal.payAtClinic',
                      'Pay at Clinic'
                    )}
                  />
                ) : (
                  translate('patientPortal.payAtClinic', 'Pay at Clinic')
                )}
              </button>
            </>
          ) : null}
          {/* <button className="btn btn-outlined-dark w-100">{translate('patientPortal.payOnline', 'Pay Online')}</button>
          <button
            className="btn btn-primary
                     w-100"
            onClick={handlePayAtClinic}
          >
            {translate('patientPortal.payAtClinic', 'Pay at Clinic')}
          </button> */}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDetails: state.LoginReducer.loginDetails,
  loggedIn: state.LoginReducer.loggedIn,
  patientDetails: state.LoginReducer.patientDetails,
  defaultCountryCode:
    state.SettingsReducer.subscriptionData.setting.defaultCountryCode,
  applicationData: state.SettingsReducer.applicationData,
  isBtnLoading: state.AppointmentReducer.isBtnLoading,
});

const mapDispatchToProps = (dispatch) => ({
  addAppointment: (data, handleSuccess, setAppId, updateExceedModal) =>
    dispatch(AddAppointmentData(data, handleSuccess, setAppId, updateExceedModal)),
  getPatientDetails: (id, callBack, errCallBack) =>
    dispatch(getProfileDetails(id, callBack, errCallBack)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PatientInfoModal);
