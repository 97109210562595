import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Avatar, CardMedia } from '@mui/material';
import { Stack } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import PhoneNumberInput from '../../Common/PhoneInput';
import ReactSelectDropDown from '../../Common/ReactSelectDropDown';
import Checkbox from '../../Common/Checkbox';
// import CustomSwitch from '../../Common/Switch';
import ContactDetails from './ContactDetails';
import '../Style/admin-settings.css';
import { LANGUAGE_LABELS, LOCALES } from '../../../Language/Locales';
import { translate } from '../../../Language/Translate';
import { Tooltip } from '@mui/material';
import {
  changeFavicon,
  changeLogo,
  fetchUserProfileDetails,
  setSubscriptionData,
  updateClientDetails,
  updateLocations,
  updateReminderDetails,
  updateSystemSettings,
} from 'Actions/settingsAction';
//here
import withPermissionCheck from 'Utils/WithUtils/WithPermission';
import { CLIENT } from 'Constants/feature';
import { validateEmail, validateMobile, validateLocationLink } from 'Utils/Validation/validator';
import { ID_OPTIONS, PAYMENT_METHODS } from 'Constants/constant';
import { fetchContactInformation } from 'Actions/contactAction';
import { showToaster, ToasterTypes } from 'Actions/toasterAction';
import ButtonLoader from 'Components/Common/ButtonLoader';
import { clientCode, getSassBaseURL } from 'Utils/Api/ApiInstance';
import ReminderSettings from './ReminderSettings';
import _ from 'lodash';
import InfoIcon from '../../../Assets/img/icons/info-icon-black.svg'

const themeOptions = [
  { label: translate('settings.greenTheme', 'Green theme'), value: 'theme-1' },
  { label: translate('settings.blueTheme', 'Blue theme'), value: 'theme-2' },
];

const tabs = [
  { id: 'systemSettings', label: translate('settings.generalSettings', 'General Settings'), type: 'system' },
  { id: 'appointmentSettings', label: translate('settings.apptSettings', 'Appointment Settings'), type: 'appointment' },
  { id: 'reminderSettings', label: translate('settings.reminderSettings', 'Appointment Reminder'), type: 'reminder' },
  { id: 'emailSettings', label: translate('settings.emailSettings', 'Email Settings'), type: 'email' },
  { id: 'contactSettings', label: translate('settings.contactSettings', 'Contact Settings'), type: 'contact' },
];

const convertToMinutes = (value, unit) => {
  switch (unit.toLowerCase()) {
    case "minute":
      return value;
    case "hour":
      return value * 60;
    case "day":
      return value * 60 * 24;
    case "week":
      return value * 60 * 24 * 7;
    default:
      throw new Error("Invalid unit provided. Use 'hour', 'day', or 'week'.");
  }
}


const duplicatedRow = (arr) => {
  return arr.map(item => {
    if (duplicateCheck(arr, item).length > 1) {
      return {
        ...item,
        errors: {
          ...item.errors,
          duplicatedEntry: true,
          required: false
        }
      }
    } else if (item?.values['notification_channel'] &&
      item?.values['freequency_unit'] &&
      item?.values['freequency_value']) {
      return {
        ...item,
        errors: {
          ...item.errors,
          duplicatedEntry: false,
          required: false
        }
      }
    } else {
      return {
        ...item,
        errors: {
          ...item.errors,
          duplicatedEntry: false
        }
      }
    }
  })
}

const duplicateCheck = (arr, item) => {
  return arr?.filter(x => {
    if ((x?.['values']?.['notification_channel'] &&
      x?.['values']?.['freequency_value'] &&
      x?.['values']?.['freequency_unit'] && item?.['values']?.['notification_channel'] &&
      item?.['values']?.['freequency_value'] &&
      item?.['values']?.['freequency_unit']) && (item?.['values']?.['notification_channel'] === x?.['values']?.['notification_channel'] ||
        item?.['values']?.['notification_channel'] === 'all' ||
        x?.['values']?.['notification_channel'] === 'all')) {
      if (convertToMinutes(item?.['values']?.['freequency_value'], item?.['values']?.['freequency_unit']) ===
        convertToMinutes(x?.['values']?.['freequency_value'], x?.['values']?.['freequency_unit'])) {
        return true
      } return false
    } return false
  })
}

const emptyFieldCheck = (arr, addClick = false) => {
  return arr.map((item) => {
    const { values } = item
    if (arr.length === 1 && !addClick) {
      if (item.errors.required) {
        return {
          ...item,
          errors: {
            ...item.errors,
            notification_channel: !values?.['notification_channel'],
            freequency_unit: !values?.['freequency_unit'],
            freequency_value: !values?.['freequency_value'],
            required: (!values['notification_channel'] || !values['freequency_unit'] || !values['freequency_value'])
          }
        }
      } else if (values?.['notification_channel'] || values?.['freequency_unit'] || values?.['freequency_value']) {
        return {
          ...item,
          errors: {
            ...item.errors,
            notification_channel: !values?.['notification_channel'],
            freequency_unit: !values?.['freequency_unit'],
            freequency_value: !values?.['freequency_value'],
            required: (!values['notification_channel'] || !values['freequency_unit'] || !values['freequency_value'])
          }
        }
      } else {
        return {
          ...item,
          errors: {
            ...item.errors,
            notification_channel: false,
            freequency_unit: false,
            freequency_value: false,
            required: false
          }
        }
      }
    }
    return {
      ...item,
      errors: {
        ...item.errors,
        notification_channel: !values?.['notification_channel'],
        freequency_unit: !values?.['freequency_unit'],
        freequency_value: !values?.['freequency_value'],
        required: (!values?.['notification_channel'] || !values?.['freequency_unit'] || !values?.['freequency_value'])
      }
    }
  })
}

const SystemSettings = (props) => {
  const {
    profileDetails = {},
    editSystemData,
    getProfileDetails,
    getLocations,
    editLocations,
    defaultCountryCode,
    subscriptionData,
    editAppnestData,
    editSubscriptionData,
    displayToaster,
    updateLogo,
    isBtnLoading,
    updateFavicon,
    editReminders
  } = props;

  const hiddenFileInput = useRef(null);
  const hiddenFileInputFav = useRef(null);

  const inputRef = useRef({});
  const inputRef2 = useRef([]);

  // tab navigation 
  const focusRef = useRef()
  const radioBtnRef = useRef({})


  const [settingsType, setSettingsType] = useState('system');
  const [languageOptions, setLanguageOptions] = useState([]);
  const [language, setLanguage] = useState('');
  const [theme, setTheme] = useState('');

  const [logo, setLogo] = useState('');
  const [logoLink, setLogoLink] = useState('');
  const [favicon, setFavicon] = useState('');
  const [faviconLink, setFaviconLink] = useState('');

  const [clientData, setClientData] = useState({
    country: '',
    replyMail: '',
    otpLength: '',
    apptRescheduleLimit: '',
    // otpEnable: false,
    maxAfter: '',
    minBefore: '',
    paymode: '',
    idType: '',
    feedbackmail: '',
    enquiryMail: '',
    complaintMail: '',
    supportMail: '',
    brandName: '',
    allowSubsequentSlot: false
  });

  const [error, setError] = useState({
    rescheduleLimitError: false,
    maxAfterError: false,
    minBeforeError: false,
    replaymailError: false,
    supportMailError: false,
    brandNameError: false,
    invalidReplay: false,
    invalidFeedback: false,
    invalidComplaint: false,
    invalidEnquiry: false,
    invalidSupport: false,
  });

  // contact details errors
  const [contactError, setContactError] = useState({});
  const [contactReset, setContactReset] = useState(0);
  const [resetCount, setResetCount] = useState(0);
  const [favKey, setFavKey] = useState(0);
  const [key, setKey] = useState(0);

  const initValue = {
    branch_id: '',
    client_id: '',
    branch_name: '',
    address: '',
    address_highlight: '',
    location: '',
    branch_type: 'hospital',
    is_main_branch: false,
    branch_status: 'active',
    tele_country_code_primary: defaultCountryCode?.toString(),
    phone_primary: '',
    email: '',
    tele_country_code_secondary: defaultCountryCode?.toString(),
    phone_secondary: '',
  };

  // contact details
  const [mainBranch, setMainBranch] = useState(initValue);
  const [otherBranches, setOtherBranches] = useState([]);
  const [numberLength, setNumberLength] = useState(10);
  const [activeTab, setActiveTab] = useState('system');
  const [code, setCode] = useState('IN')
  const [primaryCode, setPrimaryCode] = useState("");
  const [secondaryCode, setSecondaryCode] = useState("")

  const {
    country,
    replyMail,
    apptRescheduleLimit,
    // otpEnable,
    maxAfter,
    minBefore,
    paymode,
    idType,
    feedbackmail,
    enquiryMail,
    complaintMail,
    countryName,
    supportMail,
    brandName,
    allowSubsequentSlot
  } = clientData;

  const {
    rescheduleLimitError,
    maxAfterError,
    minBeforeError,
    replaymailError,
    invalidReplay,
    invalidFeedback,
    invalidComplaint,
    invalidEnquiry,
    invalidSupport,
    supportMailError,
    brandNameError,
  } = error;

  const [reminderValues, setReminderValues] = useState([{}])
  // set selected default country name on page load
  useEffect(() => {
    if (country) {
      setTimeout(() => {
        let countryClass = document.getElementsByClassName('systemSettings');
        let childDiv = null;
        countryClass.forEach((val) => {
          if (val.children.length) {
            val.children.forEach((child) => {
              if (child.className.includes('flag-dropdown')) {
                childDiv = child;
                return;
              }
            });
          }
        });
        let _countryName = childDiv?.children[0]?.title?.split(':')[0];
        setClientData((prev) => ({
          ...prev,
          countryName: _countryName,
        }));
      }, [50]);
    }
  }, [country]);

  let org = window.location.host;
  if (org.includes('localhost')) org = `${clientCode}.telmed.d.feathersoft.org`;

  // get client details from core app
  const getAppnestConfiguration = () => {
    fetch(
      `${getSassBaseURL()}/appnest/api/customer/subscription-details/${org}`
    )
      .then((res) => res.json())
      .then((res) => {
        const { data, success } = res || {};
        if (success) {
          editSubscriptionData({
            ...subscriptionData,
            setting: { ...data.setting },
            branding: { ...data.branding },
          });
          if (logo) {
            updateLogo();
          }
          if (favicon) {
            updateFavicon();
          }
        }
      })
      .catch(() => { });
  };

  // initial data load
  useEffect(() => {
    getProfileDetails();
    getAppnestConfiguration();
  }, []);
  const [changes, setChanges] = useState(true)
  const [contactChanges, setContactChanges] = useState(true)
  const [reminderChanges, setReminderChanges] = useState(true)
  const [oldFavicon, setOldFavicon] = useState('')
  const [oldLogo, setOldLogo] = useState('')

  const { client_details, whitelabel_details } =
    profileDetails.client_settings || {};
  const {
    appt_reschedule_limit,
    otp_length,
    booking_window_max_days,
    booking_window_min_hours,
    feedback_email,
    enquiry_email,
    complaint_email,
    payment_type,
    use_system_generated_mrn,
    subsequent_appt_booking
  } = client_details || {};
  const {
    appt_reschedule_limit: appt_reschedule_limit_white,
    otp_length: otp_length_white,
    booking_window_max_days: booking_window_max_days_white,
    booking_window_min_hours: booking_window_min_hours_white,
    feedback_email: feedback_email_white,
    enquiry_email: enquiry_email_white,
    complaint_email: complaint_email_white,
  } = whitelabel_details || {};
  const { setting: clientSetting, id, branding } = subscriptionData || {};
  const {
    defaultCountryCode: clientDefaultCountryCode,
    defaultLanguage,
    languageSelections = [],
    replayTo,
    supportEmailAddress,
  } = clientSetting || {};

  const { brandName: clientBrandName, themeName: applicationTheme, } = branding || {};

  useEffect(() => {
    if (profileDetails.client_settings) {

      // set client details
      setClientData((prev) => ({
        ...prev,
        otpLength: otp_length || otp_length_white,
        apptRescheduleLimit:
          appt_reschedule_limit || appt_reschedule_limit_white,
        // otpEnable: enable_otp_verification,
        maxAfter: booking_window_max_days || booking_window_max_days_white,
        minBefore: booking_window_min_hours || booking_window_min_hours_white,
        feedbackmail: feedback_email || feedback_email_white || '',
        enquiryMail: enquiry_email || enquiry_email_white || '',
        complaintMail: complaint_email || complaint_email_white || '',
        paymode: payment_type,
        idType: use_system_generated_mrn,
        allowSubsequentSlot: subsequent_appt_booking
      }));
    }
  }, [profileDetails, settingsType, resetCount]);

  useEffect(() => {
    if (subscriptionData.setting && subscriptionData.branding) {


      // FIX-ME avoid this when saas gives the same value as ours
      //  {
      // set language selction options
      const languages = eval(languageSelections)
        .filter((item) => !!LANGUAGE_LABELS[item])
        .map((item) => ({
          label: LANGUAGE_LABELS[item],
          value: item,
        }));

      if (!languages.length)
        languages.push({
          label: LANGUAGE_LABELS[LOCALES.ENGLISH],
          value: LOCALES.ENGLISH,
        });

      setLanguageOptions(languages);
      // }

      // set selected language
      let selectedLanguage = {
        label: LANGUAGE_LABELS[defaultLanguage],
        value: defaultLanguage,
      };
      if (!selectedLanguage.label) {
        selectedLanguage = {
          label: LANGUAGE_LABELS[LOCALES.ENGLISH],
          value: LOCALES.ENGLISH,
        };
      }
      setLanguage(selectedLanguage);

      // set selected theme
      setTheme(
        themeOptions.find((item) => item.value === applicationTheme) ||
        themeOptions[0]
      );

      // set logo
      // !logo &&
      setLogoLink(
        `${getSassBaseURL()}/appnest/api/customer/__ui/logo/${id}?test=${resetCount}`
      );
      setOldLogo(`${getSassBaseURL()}/appnest/api/customer/__ui/logo/${id}?test=${resetCount}`)

      // set faviocon
      // !favicon &&
      setFaviconLink(
        `${getSassBaseURL()}/appnest/api/customer/__ui/favicon/${id}?test=${resetCount}`
      );
      setOldFavicon(`${getSassBaseURL()}/appnest/api/customer/__ui/favicon/${id}?test=${resetCount}`)
      // set country code and replay mail
      setClientData((prev) => ({
        ...prev,
        country: clientDefaultCountryCode?.toString(),
        replyMail: replayTo,
        brandName: clientBrandName,
        supportMail: supportEmailAddress,
      }));
    }
  }, [subscriptionData, settingsType, resetCount]);

  // handle language selection
  const handleLanguageChange = (value) => {
    setLanguage(value);
  };

  // handle theme change
  const handleThemeChange = (value) => {
    setTheme(value);
  };

  // handle save click
  const handleSettingsSave = () => {
    let isValid = true;
    if (
      !parseInt(apptRescheduleLimit) ||
      !parseInt(maxAfter) ||
      (!parseInt(minBefore) && parseInt(minBefore) !== 0)
    ) {
      isValid = false;
      setError((prev) => ({
        ...prev,
        rescheduleLimitError: !parseInt(apptRescheduleLimit),
        maxAfterError: !parseInt(maxAfter),
        minBeforeError: (!parseInt(minBefore) && parseInt(minBefore) !== 0),
      }));
    }

    if (feedbackmail && !validateEmail(feedbackmail)) {
      isValid = false;
      setError((prev) => ({
        ...prev,
        invalidFeedback: true,
      }));
    }

    if (enquiryMail && !validateEmail(enquiryMail)) {
      isValid = false;
      setError((prev) => ({
        ...prev,
        invalidEnquiry: true,
      }));
    }

    if (complaintMail && !validateEmail(complaintMail)) {
      isValid = false;
      setError((prev) => ({
        ...prev,
        invalidComplaint: true,
      }));
    }

    if (isValid) {
      const config = {
        data: {
          // reply_email: replyMail,
          appt_reschedule_limit: parseInt(apptRescheduleLimit),
          booking_window_max_days: parseInt(maxAfter),
          booking_window_min_hours: parseInt(minBefore),
          enquiry_email: enquiryMail,
          complaint_email: complaintMail,
          feedback_email: feedbackmail,
          use_system_generated_mrn: idType,
          payment_type: paymode,
          subsequent_appt_booking:allowSubsequentSlot
        },
      };
      editSystemData({payload:config,type:activeTab}, () => {});
    }
  };

  // handel image browse open
  const hanldeDesktopBrowse = () => {
    hiddenFileInput.current.click();
  };

  // handle key down for country code
  const handleKeyDown = (e)=>{
    const dropDownBtn = document.querySelector('.selected-flag')
    if(e.key === "Enter"){
      if(!dropDownBtn.classList.contains("open")){
        e.preventDefault()
        dropDownBtn.classList.toggle("open")
        dropDownBtn.click()
      }
      else
      {
        radioBtnRef.current[idType]?.focus()
      }
    }
    if(e.key === "Tab" && dropDownBtn.classList.contains("open")){
      e.preventDefault()
      dropDownBtn.classList.contains("open")
      focusRef.current?.focus()
        dropDownBtn.click()
    }
  }

  // handle logo image selection
  const handleImageSelection = (event) => {
    setKey(key + 1);
    const file = event.target.files[0];
    const reader = new FileReader();
    const maxSizeInBytes = 1 * 1024 * 1024;
    if (
      file?.type !== 'image/jpeg' &&
      file?.type !== 'image/jpg' &&
      file?.type !== 'image/png' &&
      file !== undefined
    ) {
      displayToaster(
        translate('settings.wrongfile', 'Unsupported file format'),
        ToasterTypes.Warning
      );
    } else if (file?.size > maxSizeInBytes) {
      displayToaster(
        translate(
          'settings.verifySizeValue',
          'File size exceeds the {value}MB limit. Please select a smaller file.',
          { value: 1 }
        ),
        ToasterTypes.Warning
      );
    } else {
      setLogo(file);
      reader.onload = () => {
        setLogoLink(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  // handel image browse open faviocon
  const hanldeDesktopBrowseFav = () => {
    hiddenFileInputFav.current.click();
  };

  // handle logo image selection
  const handleImageSelectionFav = (event) => {
    setFavKey(favKey + 1);
    const file = event.target.files[0];
    const reader = new FileReader();
    const maxSizeInBytes = 1 * 1024 * 1024;
    if (
      file?.type !== 'image/jpeg' &&
      file?.type !== 'image/jpg' &&
      file?.type !== 'image/png' &&
      file !== undefined
    ) {
      displayToaster(
        translate('settings.wrongfile', 'Unsupported file format'),
        ToasterTypes.Warning
      );
    } else if (file?.size > maxSizeInBytes) {
      displayToaster(
        translate(
          'settings.verifySizeValue',
          'File size exceeds the {value}MB limit. Please select a smaller file.',
          { value: 1 }
        ),
        ToasterTypes.Warning
      );
    } else {
      setFavicon(file);
      reader.onload = () => {
        setFaviconLink(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  // handle input change
  const handleInputChange = (e) => {
    const { name, value, maxLength } = e.target;
    const regex = /^[1-9]*[0-9]*$/;
    const numNames = ['apptRescheduleLimit', 'minBefore', 'maxAfter'];
    const avoidZeroNum = ['apptRescheduleLimit', 'maxAfter'];
    if (
      (regex.test(value) && numNames.includes(name)) ||
      !numNames.includes(name)
    ) {
      if ((value !== '0' || avoidZeroNum.includes(name)) || !avoidZeroNum.includes(name)) {
        if (numNames.includes(name) && maxLength >= value.length || !numNames.includes(name)) {
          setClientData((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      }
      if (name === 'apptRescheduleLimit') {
        setError((prev) => ({
          ...prev,
          rescheduleLimitError: false,
        }));
      } else if (name === 'minBefore') {
        setError((prev) => ({
          ...prev,
          minBeforeError: false,
        }));
      } else if (name === 'maxAfter') {
        setError((prev) => ({
          ...prev,
          maxAfterError: false,
        }));
      } else if (name === 'brandName') {
        setError((prev) => ({
          ...prev,
          brandNameError: false,
        }));
      } else if (name === 'replyMail') {
        setError((prev) => ({
          ...prev,
          replaymailError: false,
          invalidReplay: false,
        }));
      } else if (name === 'supportMail') {
        setError((prev) => ({
          ...prev,
          supportMailError: false,
          invalidSupport: false,
        }));
      } else if (name === 'feedbackmail') {
        setError((prev) => ({
          ...prev,
          invalidFeedback: false,
        }));
      } else if (name === 'complaintMail') {
        setError((prev) => ({
          ...prev,
          invalidComplaint: false,
        }));
      } else if (name === 'enquiryMail') {
        setError((prev) => ({
          ...prev,
          invalidEnquiry: false,
        }));
      }
    }
  };

  // handle country code channge
  const handleMobileNumberChange = (phone, d) => {
    setClientData((prev) => ({
      ...prev,
      country: phone,
      countryName: d.name,
    }));
  };

  // handle switch chnage
  // const handleSwitchChange = (value) => {
  //   setClientData((prev) => ({
  //     ...prev,
  //     otpEnable: value,
  //   }));
  // };

  const handleScroll = (field) => {
    inputRef.current[field].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };
  // handle locations save
  const handleLocationsSave = () => {
    let isValid = true;
    if (
      !mainBranch.branch_name.trim().length ||
      !mainBranch.address_highlight.trim().length ||
      !mainBranch.address.trim().length ||
      !mainBranch.location.trim().length ||
      !mainBranch.email ||
      !mainBranch.tele_country_code_primary ||
      !mainBranch.phone_primary
    ) {
      handleScroll('mainBranch');
      isValid = false;
      setContactError((prev) => ({
        ...prev,
        branch_name_Error: !mainBranch.branch_name.trim().length,
        address_highlight_Error: !mainBranch.address_highlight.trim().length,
        address_Error: !mainBranch.address.trim().length,
        location_Error: !mainBranch.location.trim().length,
        email_Error: !mainBranch.email,
        tele_country_code_primary_Error: !mainBranch.tele_country_code_primary,
        phone_primary_Error: !mainBranch.phone_primary,
      }));
    }
    if (mainBranch.email && !validateEmail(mainBranch.email)) {
      handleScroll('mainBranch');
      isValid = false;
      setContactError((prev) => ({
        ...prev,
        invalid_email_Error: true,
      }));
    }
    if (mainBranch.phone_primary && !validateMobile(mainBranch.phone_primary, primaryCode)) {
      handleScroll('mainBranch');
      isValid = false;
      setContactError((prev) => ({
        ...prev,
        invalid_phone_primary_Error: true,
      }));
    }
    if (
      mainBranch.phone_secondary &&
      !validateMobile(mainBranch.phone_secondary, secondaryCode)
    ) {
      handleScroll('mainBranch');
      isValid = false;
      setContactError((prev) => ({
        ...prev,
        invalid_phone_secondary_Error: true,
      }));
    }
    if (
      mainBranch.location &&
      !validateLocationLink(mainBranch.location)
    ) {
      handleScroll('mainBranch');
      isValid = false;
      setContactError((prev) => ({
        ...prev,
        invalid_location_Error: true,
      }));
    }
    // if (otherBranches.length) {
    //   otherBranches.forEach((branch, index) => {
    //     if (
    //       !branch.branch_name ||
    //       !branch.address_highlight ||
    //       !branch.address ||
    //       !branch.email ||
    //       !branch.tele_country_code_primary ||
    //       !branch.phone_primary
    //     ) {
    //       handleScroll(`otherBranches${index}`);
    //       isValid = false;
    //       setContactError((prev) => {
    //         const newErrors = [...(prev.subBranchError || [])];
    //         newErrors[index] = {
    //           branch_name_Error: !branch.branch_name,
    //           address_highlight_Error: !branch.address_highlight,
    //           address_Error: !branch.address,
    //           email_Error: !branch.email,
    //           tele_country_code_primary_Error:
    //             !branch.tele_country_code_primary,
    //           phone_primary_Error: !branch.phone_primary,
    //         };
    //         return {
    //           ...prev,
    //           subBranchError: newErrors,
    //         };
    //       });
    //     }
    //     if (branch.email && !validateEmail(branch.email)) {
    //       handleScroll(`otherBranches${index}`);
    //       isValid = false;
    //       setContactError((prev) => {
    //         const newErrors = [...(prev.subBranchError || [])];
    //         newErrors[index] = {
    //           ...(newErrors[index] || {}),
    //           invalid_email_Error: true,
    //         };
    //         return {
    //           ...prev,
    //           subBranchError: newErrors,
    //         };
    //       });
    //     }
    //     if (branch.phone_primary && !validateMobile(branch.phone_primary)) {
    //       handleScroll(`otherBranches${index}`);
    //       isValid = false;
    //       setContactError((prev) => {
    //         const newErrors = [...(prev.subBranchError || [])];
    //         newErrors[index] = {
    //           ...(newErrors[index] || {}),
    //           invalid_phone_primary_Error: true,
    //         };
    //         return {
    //           ...prev,
    //           subBranchError: newErrors,
    //         };
    //       });
    //     }
    //   });
    // }
    if (isValid) {
      const locationsData = {
        data: [
          {
            ...mainBranch,
            phone_primary: mainBranch.phone_primary?.replace(/\s/g, ""),
            phone_secondary: mainBranch.phone_secondary?.replace(/\s/g, ""),
            tele_country_code_secondary: mainBranch.phone_secondary
              ? mainBranch.tele_country_code_secondary
              : '',
          },
          // ...otherBranches,
        ],
      };
      editLocations(locationsData, () => { });
    }
  };
  // handle system settings save
  const handleSystemSettings = () => {
    let isValid = true;
    if (!replyMail || !supportMail || !brandName.trim().length) {
      isValid = false;
      setError((prev) => ({
        ...prev,
        replaymailError: !replyMail,
        supportMailError: !supportMail,
        brandNameError: !brandName.trim().length,
      }));
    }
    // check for vaild email format
    if (replyMail && !validateEmail(replyMail)) {
      isValid = false;
      setError((prev) => ({
        ...prev,
        invalidReplay: true,
      }));
    }
    if (supportMail && !validateEmail(supportMail)) {
      isValid = false;
      setError((prev) => ({
        ...prev,
        invalidSupport: true,
      }));
    }
    if (isValid) {
      const formData = new FormData();
      logo ? formData.set('brandLogo', logo) : '';
      formData.append('brandBg', '');
      favicon ? formData.append('favicon', favicon) : '';
      formData.append(
        'branding',
        JSON.stringify({
          ...subscriptionData.branding,
          brandName,
          themeName: theme?.value || themeOptions[0],
        })
      );
      formData.append(
        'subscriberSettings',
        JSON.stringify({
          ...subscriptionData.setting,
          replayTo: replyMail,
          defaultLanguage: language.value,
          defaultCountryCode: country,
          applicationTheme: theme.value,
          supportEmailAddress: supportMail,
          customDomainName: subscriptionData?.setting?.customDomainName || null
        })
      );
      handleSettingsSave();
      editAppnestData(
        subscriptionData.customer_id,
        subscriptionData.id,
        formData,
        () => getAppnestConfiguration()
      );
    }
  };


  const validateReminder = () => {
    let isError
    const updateList = emptyFieldCheck(duplicatedRow(reminderValues))
    isError = updateList.some(item => item?.errors?.required || item?.errors?.duplicatedEntry)
    setReminderValues(updateList)
    return isError
  }

  const handleReminderSave = () => {
    if (!validateReminder()) {
      let values = reminderValues.map(item => item.values)
      if (values.length === 1 && !Object.values(values[0]).some(item => item)) {
        editReminders({ data: [] })
      } else {
        editReminders({ data: values })
      }
    }
  }

  // handle save
  const handleSave = () => {
    if (settingsType === 'system') {
      handleSystemSettings();
    } else if (settingsType === 'appointment' || settingsType === 'email') {
      handleSettingsSave();
    } else if (settingsType === 'reminder') {
      handleReminderSave();
    } else {
      handleLocationsSave();
    }
  };

  // handle reset
  const handleReset = () => {
    if (
      settingsType === 'system' ||
      settingsType === 'appointment' ||
      settingsType === 'email' ||
      settingsType === 'reminder'
    ) {
      setError({
        rescheduleLimitError: false,
        maxAfterError: false,
        minBeforeError: false,
        replaymailError: false,
        supportMailError: false,
        brandNameError: false,
        invalidSupport: false,
        invalidReplay: false,
        invalidFeedback: false,
        invalidComplaint: false,
        invalidEnquiry: false,
      });
      setResetCount(resetCount + 1);
    } else {
      setContactError({});
      setContactReset(contactReset + 1);
    }
  };

  //payment method change
  const payMethodChange = (event) => {
    setClientData((prev) => ({
      ...prev,
      paymode: event.target.value,
    }));
  };

    //payment method change
    const allowSubsequentSlotChange = (event) => {
      setClientData((prev) => ({
        ...prev,
        allowSubsequentSlot: event.target.checked,
      }));
    };

  const idTypeChange = (event) => {
    setClientData((prev) => ({
      ...prev,
      idType: event.target.value === 'true' ? true : false,
    }));
  };

  // handle tab change
  const handletabchange = (type) => {
    setActiveTab(type);
    setSettingsType(type);
    setChanges(false)
    setError({
      rescheduleLimitError: false,
      maxAfterError: false,
      minBeforeError: false,
      replaymailError: false,
      supportMailError: false,
      brandNameError: false,
      invalidSupport: false,
      invalidReplay: false,
      invalidFeedback: false,
      invalidComplaint: false,
      invalidEnquiry: false,
    });
    setContactError({});
    if (type === 'contact') setContactReset(contactReset + 1);
  };

  // left and right arrows function responsive
  const handleLeftClick = () => {
    const currentIndex = tabs.findIndex((tab) => tab.type === activeTab);
    if (currentIndex > 0) {
      inputRef2.current[currentIndex - 1].click()
      handletabchange(tabs[currentIndex - 1].type);
    }
    const tabContainer = document.getElementById('pills-tab');
    tabContainer.scrollLeft -= 150;
  }

  const handleRightClick = () => {
    const currentIndex = tabs.findIndex((tab) => tab.type === activeTab);
    if (currentIndex < (tabs.length - 1)) {
      inputRef2.current[currentIndex + 1].click()
      handletabchange(tabs[currentIndex + 1].type);
    }
    const tabContainer = document.getElementById('pills-tab');
    tabContainer.scrollLeft += 150;
  }

  const newData = {
    ...clientData,
    language: language.value,
    theme,
    faviconLink,
    logoLink,
    apptRescheduleLimit: Number(apptRescheduleLimit),
    minBefore: Number(minBefore),
    maxAfter: Number(maxAfter)
  }
  const oldData = {
    ...clientData,
    language: defaultLanguage,
    theme: themeOptions.find((item) => item.value === applicationTheme) || themeOptions[0],
    brandName: clientBrandName,
    country: clientDefaultCountryCode,
    idType: use_system_generated_mrn,
    faviconLink: oldFavicon,
    replyMail: replayTo,
    supportMail: supportEmailAddress,
    logoLink: oldLogo,
    apptRescheduleLimit: appt_reschedule_limit || appt_reschedule_limit_white,
    minBefore: booking_window_min_hours || booking_window_min_hours_white,
    maxAfter: booking_window_max_days || booking_window_max_days_white,
    paymode: payment_type,
    allowSubsequentSlot: subsequent_appt_booking,
    feedbackmail: feedback_email
      || feedback_email_white || '',
    enquiryMail: enquiry_email || enquiry_email_white || '',
    complaintMail: complaint_email || complaint_email_white || '',
  }

  useEffect(() => {
    setReminderChanges(false)
    setContactChanges(false)
    if (!_.isEqual(oldData, newData)) {
      setChanges(false)
    } else {
      setChanges(true)
    }
  }, [clientData, language, theme, faviconLink, logoLink])

  return (
    <>
      <div className="content settings-page tab-design settings-design">
        <div className="tab-wrapper">
          <div className="tab-body">
            <div className="tab-nav-buttons">

              <button className="btn-transparent move-left"
                onClick={handleLeftClick}>
                {activeTab === tabs[0].type ? null :
                  <i className="bi bi-chevron-left" style={(activeTab === tabs[0].type) ? { display: 'none' } : {}}></i>}
              </button>
              <button className="btn-transparent move-right"
                onClick={handleRightClick} >
                {activeTab === tabs[tabs.length - 1].type ? null :
                  <i className="bi bi-chevron-right"></i>}
              </button>
            </div>
            <ul
              className="nav mb-2 custom-tab arrow-scroll v2"
              id="pills-tab"
              role="tablist"
            >
              {tabs.map((tab, index) => (
                <li className="nav-item" role="presentation" key={tab.type}>
                  <button
                    className={activeTab === tab.type ? 'active' : ''}
                    id={tab.id}
                    data-bs-toggle="pill"
                    data-bs-target={`#${tab.id}-tab`}
                    type="button"
                    role="tab"
                    ref={(ref) => (inputRef2.current[index] = ref)}
                    onClick={() => handletabchange(tab.type)}
                  >
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="systemSettings-tab"
                role="tabpanel"
                aria-labelledby="pills-systemSettings"
              >
                <div className="row mt-3">
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label htmlFor="User name">
                        <FormattedMessage
                          id="settings.setLanguage"
                          defaultMessage="Select Preffered Language"
                        />
                      </label>
                      <ReactSelectDropDown
                        value={language}
                        options={languageOptions}
                        onInputChange={handleLanguageChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label htmlFor="User name">
                        <FormattedMessage
                          id="settings.setTheme"
                          defaultMessage="Select Patient Portal Theme"
                        />
                      </label>
                      <ReactSelectDropDown
                        value={theme}
                        options={themeOptions}
                        onInputChange={handleThemeChange}
                      />
                    </div>
                  </div>
                  {/* <div className="col-10"></div> */}
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label className="mandatory" htmlFor="replyAddress">
                        {translate('settings.organizationname',  'Set organization name')}
                      </label>
                      <input
                        type="text"
                        className="form-control input"
                        id="replyAddress"
                        value={brandName}
                        style={{
                          borderColor: brandNameError ? 'red' : '',
                        }}
                        name="brandName"
                        onChange={handleInputChange}
                        maxLength={50}
                        onKeyDown={(e)=>{
                          if(e.key === "Tab" && !e.shiftKey){
                            e.preventDefault()
                            focusRef.current.focus()
                          }
                        }}
                      />
                      {brandNameError ? (
                        <p
                          className="mt-1 fs-11 fw-500"
                          style={{ color: 'red' }}
                        >
                          {translate(
                            'common.required',
                            'This field is required'
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label htmlFor="User name">
                        <FormattedMessage
                          id="settings.setCountry"
                          defaultMessage="Select default country code"
                        />
                      </label>
                      <div className="position-relative code-selector"
                       onKeyDown={(e) => {
                        if (e.key === "Tab" && !e.shiftKey)
                        {
                          e.preventDefault()
                          radioBtnRef.current[idType].focus()
                        } 
                        handleKeyDown(e)
                      }}
                      >
                        <PhoneNumberInput
                          value={country}
                          onChange={handleMobileNumberChange}
                          // onFocus={(e) => e.target.blur()}
                          containerClass="systemSettings"
                          numberLength={numberLength}
                          setNumberLength={setNumberLength}
                          code={code}
                          setCode={setCode}
                          focusRef={focusRef}
                        />
                        <input
                          className="countryNameInside text-truncate"
                          disabled
                          value={countryName}
                          title={countryName}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-12">
                    <div className="form-group">
                      <label htmlFor="healthcare setting">
                        {translate(
                          'settings.healthCareIdSetting',
                          'Healthcare ID settings'
                        )}
                      </label>
                      <RadioGroup row value={idType} onChange={idTypeChange}
                      onKeyDown={(e)=>{
                        if(e.key === "Tab" && e.shiftKey){
                          e.preventDefault()
                          focusRef.current.focus()
                        }
                      }}
                      >
                        {ID_OPTIONS.map((x, index) => (
                          <FormControlLabel
                            key={index + 1}
                            value={x.value}
                            control={<Radio />}
                            label={x.label}
                            inputRef={(ref)=>(radioBtnRef.current[x.value] = ref)}
                          />
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label className="mandatory" htmlFor="replyAddress">
                        {translate(
                          'settings.replayMail',
                          'User reply email address'
                        )}
                      </label>
                      <input
                        type="text"
                        className="form-control input"
                        id="replyAddress"
                        value={replyMail}
                        style={{
                          borderColor:
                            replaymailError || invalidReplay ? 'red' : '',
                        }}
                        name="replyMail"
                        onChange={handleInputChange}
                        maxLength={50}
                      />
                      {replaymailError ? (
                        <p
                          className="mt-1 fs-11 fw-500"
                          style={{ color: 'red' }}
                        >
                          {translate(
                            'common.required',
                            'This field is required'
                          )}
                        </p>
                      ) : null}
                      {invalidReplay ? (
                        <p
                          className="mt-1 fs-11 fw-500"
                          style={{ color: 'red' }}
                        >
                          {translate('users.invalidMail', 'Invalid email id')}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label className="mandatory" htmlFor="replyAddress">
                        {translate(
                          'settings.supportMail',
                          'Support email address'
                        )}
                      </label>
                      <input
                        type="text"
                        className="form-control input"
                        id="supportMail"
                        value={supportMail}
                        style={{
                          borderColor:
                            supportMailError || invalidSupport ? 'red' : '',
                        }}
                        name="supportMail"
                        onChange={handleInputChange}
                        maxLength={50}
                      />
                      {supportMailError ? (
                        <p
                          className="mt-1 fs-11 fw-500"
                          style={{ color: 'red' }}
                        >
                          {translate(
                            'common.required',
                            'This field is required'
                          )}
                        </p>
                      ) : null}
                      {invalidSupport ? (
                        <p
                          className="mt-1 fs-11 fw-500"
                          style={{ color: 'red' }}
                        >
                          {translate('users.invalidMail', 'Invalid email id')}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="col-xl-5">
                    <div className="form-group">
                      <label htmlFor="User name">
                        <FormattedMessage
                          id="settings.setOtp"
                          defaultMessage="Enable OTP verification"
                        />
                      </label>
                      <div>
                        <CustomSwitch
                          checkedProps={otpEnable}
                          handleSwitchChange={handleSwitchChange}
                        />
                      </div>
                    </div>
                  </div> */}

                  <div className="col-lg-6 col-md-6 mt-2 mb-4">
                    <div
                      className="card"
                      style={{ cursor: 'pointer', padding: 12 }}
                      onClick={hanldeDesktopBrowseFav}
                    >
                      <input
                        key={favKey}
                        type="file"
                        accept="image/*"
                        onChange={handleImageSelectionFav}
                        ref={hiddenFileInputFav}
                        style={{ display: 'none' }}
                      />
                      <Stack direction="row" spacing={2}>
                       <div className="img-holder">
                       {faviconLink ? (
                          <CardMedia
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: '50%',
                              objectFit: 'cover',
                            }}
                            component="img"
                            image={faviconLink}
                          />
                        ) : (
                          <Avatar
                            sx={{ width: 50, height: 50 }}
                            src="/broken-image.jpg"
                          />
                        )}
                       </div>
                        <span className="card-text"
                          style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            fontSize: 13,
                          }}
                        >
                          <div className="info-icon-container">
                          <FormattedMessage
                            id="settings.changeFavicon"
                            defaultMessage="Change favicon (PNG, JPG, JPEG)"
                          />
                           <Tooltip
                            placement="top"
                            enterTouchDelay={0}
                            leaveTouchDelay={3000}
                            title={translate(
                              "settings.favicondesc",
                              "A Favicon is a small, 16x16 pixel icon used on web browsers to represent a website or a web page."
                            )}
                          >
                            <img className='info-icon' src={InfoIcon} />
                          </Tooltip>
                          </div>
                          <p>
                            {translate(
                              'settings.maxSizeValue',
                              '(Maximum file upload size : {value}MB)',
                              { value: 1 }
                            )}
                                                     
                          </p>
                        </span>
                      </Stack>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 mt-2 mb-4">
                    <div
                      className="card"
                      style={{ cursor: 'pointer', padding: 12 }}
                      onClick={hanldeDesktopBrowse}
                    >
                      <input
                        key={key}
                        type="file"
                        accept="image/*"
                        onChange={handleImageSelection}
                        ref={hiddenFileInput}
                        style={{ display: 'none' }}
                      />
                      <Stack direction="row" spacing={2}>
                      <div className="img-holder">
                      {logoLink ? (
                          <CardMedia
                            style={{
                              width: 'auto',
                              maxWidth: 120,
                              height: 50,
                              // borderRadius: '50%',
                              objectFit: 'contain',
                            }}
                            component="img"
                            image={logoLink}
                          />
                        ) : (
                          <Avatar
                            sx={{ width: 50, height: 50 }}
                            src="/broken-image.jpg"
                          />
                        )}
                      </div>
                        <span className="card-text"
                          style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            fontSize: 13,
                          }}
                        >
                          <FormattedMessage
                            id="settings.changeLogo"
                            defaultMessage="Change  system logo (PNG, JPG, JPEG)"
                          />
                          <p>
                            {translate(
                              'settings.maxSizeValue',
                              '(Maximum file upload size : {value}MB)',
                              { value: 1 }
                            )}
                          </p>
                        </span>
                      </Stack>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="appointmentSettings-tab"
                role="tabpanel"
                aria-labelledby="pills-appointmentSettings"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="mandatory" htmlFor="rescheduleLimit">
                        {translate(
                          'settings.maxReschedule',
                          'Maximum number of times that an appointment can be rescheduled'
                        )}
                      </label>
                      <input
                        type="number"
                        className="form-control input input-number"
                        id="rescheduleLimit"
                        name="apptRescheduleLimit"
                        style={{
                          borderColor: rescheduleLimitError ? 'red' : '',
                        }}
                        value={apptRescheduleLimit}
                        onChange={handleInputChange}
                        maxLength={2}
                      />
                      {rescheduleLimitError ? (
                        <p
                          className="mt-1 fs-11 fw-500"
                          style={{ color: 'red' }}
                        >
                          {translate(
                            'common.required',
                            'This field is required'
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="mandatory" htmlFor="appointmentLimit">
                        {translate(
                          'settings.maxAdvance',
                          'Maximum time in advance that an appointment can be booked (in days)'
                        )}
                      </label>
                      <input
                        type="number"
                        className="form-control input input-number"
                        id="appointmentLimit"
                        name="maxAfter"
                        style={{
                          borderColor: maxAfterError ? 'red' : '',
                        }}
                        value={maxAfter}
                        onChange={handleInputChange}
                        maxLength={3}
                      />
                      {maxAfterError ? (
                        <p
                          className="mt-1 fs-11 fw-500"
                          style={{ color: 'red' }}
                        >
                          {translate(
                            'common.required',
                            'This field is required'
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="mandatory" htmlFor="timeStart">
                        {translate(
                          'settings.minBefore',
                          'Minimum time before appointment start that it can be booked (in hrs)'
                        )}
                      </label>
                      <input
                        type="number"
                        className="form-control input input-number"
                        id="timeStart"
                        name="minBefore"
                        style={{
                          borderColor: minBeforeError ? 'red' : '',
                        }}
                        value={minBefore}
                        onChange={handleInputChange}
                        maxLength={2}
                      />
                      {minBeforeError ? (
                        <p
                          className="mt-1 fs-11 fw-500"
                          style={{ color: 'red' }}
                        >
                          {translate(
                            'common.required',
                            'This field is required'
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 radio-btns">
                    <div className="form-group mb-0">
                      <label className="mandatory" htmlFor="paymentType">
                        {translate('settings.paymentType', 'Payment type')}
                      </label>
                      <RadioGroup
                        row
                        value={paymode}
                        onChange={payMethodChange}
                      >
                        {PAYMENT_METHODS.map((x, index) => (
                          <FormControlLabel
                            key={index + 1}
                            value={x.value}
                            control={<Radio />}
                            label={x.label}
                            disabled={x.value!=='pay_at_clinic'}
                          />
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-0">
                        <label htmlFor="allowSubsequentSlots">
                          {translate('settings.allowSubsequentSlots', 'Allow filling subsequent slots')}
                        </label>
                        <div className="check-label">
                          <Checkbox
                            label={translate('settings.allow', 'Allow')}
                            handleChange={(e) => allowSubsequentSlotChange(e)}
                            checked={allowSubsequentSlot}
                          // disabled
                          />
                        </div>
                      </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="emailSettings-tab"
                role="tabpanel"
                aria-labelledby="pills-emailSettings"
              >
                <div className="row">
                  <div className="col-lg-4 mb-2">
                    <div className="form-group">
                      <label htmlFor="feedbackAddress">
                        {translate(
                          'settings.feedbackMail',
                          'Feedback email address'
                        )}
                      </label>
                      <input
                        type="email"
                        className="form-control input"
                        autoComplete="new-password"
                        id="feedbackAddress"
                        value={feedbackmail}
                        style={{
                          borderColor: invalidFeedback ? 'red' : '',
                        }}
                        name="feedbackmail"
                        onChange={handleInputChange}
                        maxLength={50}
                        placeholder='feedback@hospitalcare.com'
                      />
                      {invalidFeedback ? (
                        <p
                          className="fs-11 fw-500"
                          style={{ color: 'red', height: 0 }}
                        >
                          {translate('users.invalidMail', 'Invalid email id')}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-4 mb-2">
                    <div className="form-group">
                      <label htmlFor="enquiryAddress">
                        {translate(
                          'settings.enquiryMail',
                          'Enquiry email address'
                        )}
                      </label>
                      <input
                        type="email"
                        className="form-control input"
                        autoComplete="new-password"
                        id="enquiryAddress"
                        value={enquiryMail}
                        style={{
                          borderColor: invalidEnquiry ? 'red' : '',
                        }}
                        name="enquiryMail"
                        onChange={handleInputChange}
                        maxLength={50}
                        placeholder='enquiry@hospitalcare.com'
                      />
                      {invalidEnquiry ? (
                        <p
                          className="fs-11 fw-500"
                          style={{ color: 'red', height: 0 }}
                        >
                          {translate('users.invalidMail', 'Invalid email id')}
                        </p>
                      ) : null}
                    </div>
                  </div>


                </div>
                <div className="row">
                  <div className="col-lg-4 mb-2">
                    <div className="form-group">
                      <label htmlFor="complainAddress">
                        {translate(
                          'settings.complaintMail',
                          'Complaint email address'
                        )}
                      </label>
                      <input
                        type="email"
                        className="form-control input"
                        autoComplete="new-password"
                        id="complainAddress"
                        value={complaintMail}
                        style={{
                          borderColor: invalidComplaint ? 'red' : '',
                        }}
                        name="complaintMail"
                        onChange={handleInputChange}
                        maxLength={50}
                        placeholder='complaints@hospitalcare.com'
                      />
                      {invalidComplaint ? (
                        <p
                          className="fs-11 fw-500"
                          style={{ color: 'red', height: 0 }}
                        >
                          {translate('users.invalidMail', 'Invalid email id')}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

              </div>
              <div
                className="tab-pane fade"
                id="reminderSettings-tab"
                role="tabpanel"
                aria-labelledby="pills-reminderSettings"
              >
                <ReminderSettings reminderDetails={{
                  reminderValues,
                  setReminderValues,
                  duplicatedRow,
                  emptyFieldCheck,
                  resetCount
                }}
                  setReminderChanges={setReminderChanges}
                  reminderChanges={reminderChanges}
                  setChanges={setChanges}
                />
              </div>

              <div
                className="tab-pane fade"
                id="contactSettings-tab"
                role="tabpanel"
                aria-labelledby="pills-contactSettings"
              >
                <ContactDetails
                  inputRef={inputRef}
                  getLocations={getLocations}
                  contactReset={contactReset}
                  mainBranch={mainBranch}
                  setMainBranch={setMainBranch}
                  otherBranches={otherBranches}
                  setOtherBranches={setOtherBranches}
                  contactError={contactError}
                  setContactError={setContactError}
                  defaultCountryCode={defaultCountryCode}
                  settingsType={settingsType}
                  primaryCode={primaryCode}
                  setPrimaryCode={setPrimaryCode}
                  secondaryCode={secondaryCode}
                  setSecondaryCode={setSecondaryCode}
                  setContactChanges={setContactChanges}
                  contactChanges={contactChanges}
                  setChanges={setChanges}
                />
              </div>
            </div>
          </div>

          <div className="common-tab-footer">
            <div className="button-wrapper">
              <button className="btn btn-outlined" onClick={handleReset}>
                <FormattedMessage id="common.reset" defaultMessage="RESET" />
              </button>
              <button
                disabled={isBtnLoading || changes || contactChanges || reminderChanges}
                className="btn btn-primary loader-btn"
                onClick={handleSave}
              >
                {isBtnLoading ? (
                  <ButtonLoader text={translate('common.save', 'Save')} />
                ) : (
                  <FormattedMessage id="common.save" defaultMessage="Save" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  profileDetails: state.SettingsReducer.profileDetails,
  defaultCountryCode:
    state.SettingsReducer.subscriptionData.setting.defaultCountryCode,
  subscriptionData: state.SettingsReducer.subscriptionData,
  isBtnLoading: state.SettingsReducer.isBtnLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getProfileDetails: () => dispatch(fetchUserProfileDetails()),
  editSystemData: (data, successAction) =>
    dispatch(updateClientDetails(data, successAction)),
  getLocations: (setData) => dispatch(fetchContactInformation(setData)),
  editLocations: (data, successAction) =>
    dispatch(updateLocations(data, successAction)),
  editSubscriptionData: (data) => dispatch(setSubscriptionData(data)),
  updateLogo: () => dispatch(changeLogo()),
  updateFavicon: () => dispatch(changeFavicon()),
  editAppnestData: (clientId, subscriptionId, data, getAppnestConfiguration) =>
    dispatch(
      updateSystemSettings(
        clientId,
        subscriptionId,
        data,
        getAppnestConfiguration
      )
    ),
  displayToaster: (message, type) => dispatch(showToaster(message, type)),
  editReminders: (data, successAction) =>
    dispatch(updateReminderDetails(data, successAction))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissionCheck(CLIENT)
)(SystemSettings);
