import Keycloak from 'keycloak-js';
import { getSassBaseURL } from 'Utils/Api/ApiInstance';

// FIX_ME
// let keycloakUrl = getSassBaseURL() + '/auth';
let keycloakUrl = window.location.origin + '/auth';

if (keycloakUrl.includes('localhost')) {
  keycloakUrl = getSassBaseURL() + '/auth';
}

console.log("Environment--", process.env.REACT_APP_ENV)

const KEYCLOAK_CONFIGURATION = {
  url: keycloakUrl,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
};

const keycloak = new Keycloak(KEYCLOAK_CONFIGURATION);

export default keycloak;
