import React, { useEffect, useRef, useState } from 'react';
import { translate } from '../../Language/Translate';
import { useIntl } from 'react-intl';
import ViewSwitch from './ViewSwitch';

const HeaderFields = (props) => {
  const {
    onReset,
    onApply,
    onAddBtnClick,
    children,
    applyBtnDisable = false,
    handleViewModeChange,
    isApplied = false,
    totalCount,
    handleOutsideClose = () => {}
  } = props;
  const intl = useIntl();
  const divRef = useRef(null)

  const [openFilter, setOpenFilter] = useState(false);

  const handleClickOutside = (event) => {
    const isAntd = Array.from(event.target.classList).some((className) => className.includes('ant-picker'))
    if (divRef.current && !divRef.current.contains(event.target) && !isAntd && openFilter) {
      handleOutsideClose();
      setOpenFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openFilter]);

  const handleClose = () => {
    setOpenFilter(false);
  }

  const handleApply = () => {
    handleClose()
    onApply();
  }

  const handleReset = () => {
    handleClose()
    onReset();
  }

  return (
    <div>
      {totalCount ? <div className='results-found'>{totalCount} result(s) found</div> : null}
      <div className="topFilter align-right">
        <div
          className="admin-filter"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleClose()
            }
          }}
        >
            <div className="filter-section v2" ref={divRef}>
                <button className={`filter-btn ${isApplied ? 'applied' : ''}`} onClick={()=> {setOpenFilter(!openFilter); if(openFilter) handleOutsideClose()}}>
                <i className="bi bi-funnel"></i>
                </button>
                <div className={` ${openFilter ? 'open-filter listings-fiter-container' : 'listings-fiter-container'}`}>
                  <div className="filter-body">
                    <div className="row fg-1">
                      {children}
                    </div>
                    </div>
                    <div className="filter-footer back-to-btn">
                        <ul className="btnGroupright">
                          <li
                              onClick={handleReset}
                              id="onReset"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onReset();
                                  e.target.blur();
                                }
                              }}
                            >
                              <button
                                tabIndex={'-1'}
                                type="button"
                                id="reset"
                                className="btn btn-outlined w-100"
                              >
                                {intl.formatMessage({
                                  id: 'reset.resetTooltip',
                                  defaultMessage: 'reset',
                                })}
                              </button>
                            </li>
                          <li onClick={handleApply} id="onApply" tabIndex="0">
                            <button
                              disabled={applyBtnDisable}
                              tabIndex={'-1'}
                              type="button"
                              className="btn btn-secondary w-100"
                              id="apply"
                            >
                              {' '}
                              {translate('common.apply', 'Apply')}{' '}
                            </button>
                          </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {handleViewModeChange ? <div className="appointmentView">
            <ViewSwitch
              active="list"
              handleViewModeChange={handleViewModeChange}
            />
        </div> : null}
        {onAddBtnClick ? (
          <div
            className="right-add-btn filter-btn-container"
          >
            <ul className="btnGrpupleft">
              <li
                id="onAdd"
                tabIndex="0"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    onAddBtnClick();
                    e.target.blur();
                  }
                }}
              >
                <div className="btn-adWrap" onClick={onAddBtnClick}>
                  <button type="button" className="btn btn-primary" id="add">
                    {' '}
                    <span className="plus"></span>
                    <span className="ps-2">
                      {translate('common.add', 'ADD')}{' '}
                    </span>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default HeaderFields;
