import React, { useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import 'Assets/style/own-view-day.css';
import 'Assets/style/own-view-week.css';
import Calendar from './Calendar';
import network from 'Utils/Api/Network';
import {
  appendTime,
  dayMonthAndYear,
  formatDate,
  isDateBefore,
  timeZoneDate,
} from 'Utils/DateFormatter/Date';
import DayRoster from './DayRoster';
import WeekRoster from './WeekRoster';
import { translate } from 'Language/Translate';
import { APPOINTMENT_STATUS_OPTIONS_ALL } from 'Constants/constant';
import ReactSelectDropDown from 'Components/Common/ReactSelectDropDown';
import ViewSwitch from 'Components/Common/ViewSwitch';
import AppointmentsTableView from './Appointments';
import {
  clearAppointmentReducer,
  receiveSearchDoctorDetails,
  updateSearchData,
} from 'Actions/appointmentAction';
import { connect, useDispatch } from 'react-redux';
import { DoctorsApi } from 'Utils/Api/DoctorsApi';
import { useIntl } from 'react-intl';
import { USER_TYPE } from 'Constants/userTypes';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import exportIcon from '../../../src/Assets/img/icons/export-icon.svg'
import { removeDrPrefix } from 'Utils/StringFormatter/String';
import { getLocalStorageItem } from 'Utils/LocalStorage/local-storage';

let apiTimeout = null;
const options = [
  { label: translate('common.week', 'Week'), value: 'week' },
  { label: translate('common.day', 'Day'), value: 'day' },
  { label: translate('common.today', 'Today'), value: 'today' },
];

const AppointmentsRoster = (props) => {
  const { applySearchData, searchData, clearData, language_Code, userType } =
  props;
  //  for loader ui
  const dispatch = useDispatch();
  const [isLoader, updateLoader] = useState(false);
  const history = useHistory();
  const [view, setView] = useState(searchData.view ? searchData.view : 'week');
  const [dateChange, setDateChange] = useState(timeZoneDate());
  const [activeDateChange, setActiveDateChange] = useState(timeZoneDate());
  const [date, setDate] = useState(timeZoneDate());
  const [activeDate, setActiveDate] = useState(timeZoneDate());
  const [patientName, setPatientName] = useState('');
  const [isSelect, setIsSelect] = useState(false);
  const [isPatientSelect, setPatientIsSelect] = useState(false);
  const [patientSuggestion, setPatientSuggestion] = useState([]);
  const [applyCounter, setApplyCounter] = useState(0);
  const [deptDocSuggestionList, setDeptDocSuggestionList] = useState([]);
  const [selectedDoc, setSelecteDoc] = useState({});
  const [deptListId, setDeptListId] = useState('');
  const [searchDoc, setSearchDoc] = useState('');
  const [search, setSearch] = useState('');
  const [selectedDeptDoc, setSelectedDeptDoc] = useState();
  const [status, setStatus] = useState('');
  const [viewType, setViewType] = useState(
    searchData.view === 'day' ? options[1] : options[0]
  );
  // btn disable
  // const [disablebtn, setDisableBtn] = useState(false);
  const [viewRoster, setViewRoster] = useState(
    searchData.viewType === 'list' ? false : true
  );
  const [docDetails, setDocDetail] = useState({});
  const [allDocList, setAllDocList] = useState([]);

  const [opTime, setOpTime] = useState();
  const [searchPatientLoading, setSearchPatientLoading] = useState({});
  const [searchDocDeptLoading, setSearchDocDeptLoading] = useState({});
  const [showFilter, setShowFilter] = useState(false)
  const intl = useIntl();

  const divRef = useRef(null);
  const userRoleType = getLocalStorageItem('user_type')

  // handle outside close of filter
  const handleOutsideClose = () => {
    setStatus(searchData.selectedStatus || '');
    setDate(searchData.date || timeZoneDate());
    setActiveDate(searchData.date || timeZoneDate());
    setDateChange(searchData.date || timeZoneDate());
    setActiveDateChange(searchData.date || timeZoneDate());
    setPatientName(searchData.searchPatient || '')
    setPatientIsSelect(true)
  }

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)&& showFilter) {
      setShowFilter(false);
      handleOutsideClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFilter]);

  // preset the previous filter
  useEffect(() => {
    setStatus(searchData.selectedStatus || '');
    setDate(searchData.date || timeZoneDate());
    setActiveDate(searchData.date || timeZoneDate());
    setDateChange(searchData.date || timeZoneDate());
    setActiveDateChange(searchData.date || timeZoneDate());
    setPatientName(searchData.searchPatient || '');
    setPatientIsSelect(searchData?.isPatientSelect || !!searchData.searchPatient)
    setIsSelect(searchData?.isSelect);
    setDeptListId('');
    setSelectedDeptDoc('');
    setSearchDoc('');
    setDeptDocSuggestionList([]);
    if (viewRoster) {
      setApplyCounter(applyCounter + 1);
      setSelecteDoc(searchData.selectedDoc || '');
    } else {
      setApplyCounter(0);
    }
  }, [viewRoster]);

  //   api call with 3sec delay patient
  useEffect(() => {
    if (!isPatientSelect) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadOptionsPatient(patientName);
      }, 300);
      apiTimeout = timeout;
    }
  }, [patientName, isPatientSelect]);

  const getCombinedOpTiming = (data) => {
    setOpTime('');
    const config = {
      start_date: formatDate(date),
      end_date: formatDate(date)
    }
    network.get({ url: `/appointment/doctor/${data}/slots`, data: config }).then((res) => {
      const { isError, result } = res || {};
      if (isError) {
        setOpTime('Doctor not available');
      } else {
        const sessions =
          result?.op_schedule_details?.[0]?.doctor_schedule_details || [];
        // Initialize variables to track earliest start and latest end time
        let earliestStart = null;
        let latestEnd = null;

        // Loop through each session
        for (const session of sessions) {
          // Check if the schedule type is "op"
          if (session.schedule_type === 'op') {
            // Update earliest start time if it's earlier than the current session's start
            if (!earliestStart || session.start_time < earliestStart) {
              earliestStart = session.start_time;
            }

            // Update latest end time if it's later than the current session's end
            if (!latestEnd || session.end_time > latestEnd) {
              latestEnd = session.end_time;
            }
          }
        }

        // If no op schedules are found, return null
        if (!earliestStart || !latestEnd) {
          setOpTime('Doctor not available');
        } else {
          // Return an object with combined start and end time
          setOpTime(
            `${appendTime(earliestStart, language_Code)} - ${appendTime(
              latestEnd,
              language_Code
            )}`
          );
        }
      }
    });
  };

  useEffect(() => {
    if (selectedDoc.value) {
      // getCombinedOpTiming(selectedDoc.value);
      applySearchData({ ...searchData, search: selectedDoc.label, selectedDoc });
      setApplyCounter(applyCounter + 1)
    }
  }, [selectedDoc]);

  useEffect(() => {
    // set OP timing based on the selected date and doctor
    if (selectedDoc.value) {
      getCombinedOpTiming(selectedDoc.value);
    }
  }, [selectedDoc, date]);

  useEffect(() => {
    viewDoctorDetails();
  }, [selectedDeptDoc, userRoleType]);

  // handle view dropdown change
  const handleViewChange = (value) => {
    setViewType(value);
    if (value.value === 'today') {
      //activeDate state is used to change the view from another month to selected month view
      setActiveDate(timeZoneDate());
      setDate(timeZoneDate());
      setActiveDateChange(timeZoneDate());
      setDateChange(timeZoneDate());
      setApplyCounter(applyCounter + 1)
    } else {
      setView(value.value);
      applySearchData({ ...searchData, view: value.value });
    }
  };

  // handle week change
  const handleWeekChange = (direction) => {
    // setViewType(options[1]);
    if (direction === 'previous') {
      const lastWeekDate = view === 'week' ? new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000) : new Date(date.getTime() - 24 * 60 * 60 * 1000);
      setDate(lastWeekDate);
      setActiveDate(lastWeekDate);
      setDateChange(lastWeekDate);
      setActiveDateChange(lastWeekDate);
      applySearchData({ ...searchData, date: lastWeekDate });
    } else {
      const nextWeekDate = view === 'week' ? new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000) : new Date(date.getTime() + 24 * 60 * 60 * 1000) ;
      setDate(nextWeekDate);
      setActiveDate(nextWeekDate);
      setDateChange(nextWeekDate);
      setActiveDateChange(nextWeekDate);
      applySearchData({ ...searchData, date: nextWeekDate });
    }
    setApplyCounter(applyCounter + 1);
  };

  // handle date change
  const onDateChange = (e) => {
    setViewType(options.find((x) => x.value === view));
    setDateChange(e);
    // setDisableBtn(true);
    applySearchData({ ...searchData, date: e });
    const element = document.getElementById('rosterWeekViewCalender');
    const element1 = document.getElementById('rosterWeekViewCalenderToggle');
    element?.classList?.remove('show');
    element1?.classList?.remove('show');
  };

  // handle status dropdown change
  const handleStatusChange = (value) => {
    setStatus(value);
    // setDisableBtn(true);
  };

  // handle search value change patient
  const onChangePatientSearch = (event) => {
    const { value } = event.target;
    setPatientIsSelect(false);
    setPatientName(value);
    if (value.trim().length) {
      // setDisableBtn(true);
    } else {
      // setDisableBtn(false);
    }
  };
  // handle search value change
  const onDeptChangeSearch = (event) => {
    const { value } = event.target;
    if (!value) {
      setSelectedDeptDoc('');
      setDeptListId('');
    }
    setIsSelect(false);
    setSearch(value);
    setSearchDoc(value);
  };
  // handle dept change CLICK
  const handleDeptChange = (item) => {
    setSelectedDeptDoc(item.value);
    setSearchDoc(item.value);
    setDeptDocSuggestionList([]);
    setSearchDocDeptLoading([])
    setIsSelect(true);
    if (item.type === 'department') {
      setDeptListId(item.id);
    } else {
      setDeptListId('');
    }
  };

  const viewDoctorDetails = () => {
    setDocDetail({})
    if (!isLoader) updateLoader(true);
    let config = {};
    if (deptListId) {
      config = {
        // status: 'active',
        dept_id: deptListId,
        limit: 0,
      };
    } else {
      config = {
        // status: 'active',
        search: removeDrPrefix(selectedDeptDoc) ?? '',
        limit: 0,
        acl: userType === USER_TYPE.appointmentCordinator,
        sort: 'name'
      };
    }
    DoctorsApi.getDoctorsList(config).then((res) => {
      if (res.error) {
        updateLoader(false);
        return;
      } else {
        setDocDetail(res.response.doctors);
        if (!deptListId && res.response.doctors) {
          const docList = res.response.doctors.map((doc) => ({
            label: `${doc.name} ${doc.last_name}`,
            value: doc.doctor_id,
            departments: doc.departments
              ?.map((department) => department.dept_name)
              ?.join(','),
          }));
          setAllDocList(docList);
        }
        if (res.response?.doctors?.length) {
          if (!searchData?.selectedDoc?.value || selectedDeptDoc) {
            setSelecteDoc({
              label:
                res?.response.doctors[0]?.name +
                ' ' +
                res?.response.doctors[0]?.last_name,
              value: res?.response.doctors[0]?.doctor_id ?? '',
            });
          }
          updateLoader(false);
        } else {
          setSelecteDoc({});
          clearData();
          updateLoader(false);
        }
      }
    });
  };

  // load options to the dropdown on typing patient
  const loadOptionsPatient = async (inputValue) => {
    let fetchedOptions = [];
    if (inputValue?.trim()) {
      let id = new Date().getTime()
      setSearchPatientLoading((prev) => ({
        ...prev,
        [id]: true
      }));
      await network
        .get({ url: `/patients/search/patient-name/${inputValue}` })
        .then((response) => {
          setSearchPatientLoading((prev) => ({
            ...prev,
            [id]: false
          }));
          const { isError, result } = response;
          if (isError) {
            return;
          } else {
            let options = result?.patients.map((item) => ({
              label: item.name,
              value: item.name,
              // type: item.type,
            }));
            if (!options || !options?.length) {
              options = [{
                  value: '',
                  label: `${intl.formatMessage({
                    id: 'common.noData',
                    defaultMessage: 'No data found.',
                  })}`,
                  isDisabled: true,
              }]
            }
            fetchedOptions = options;
          }
        })
        .catch(() => {});
    }
    setPatientSuggestion(fetchedOptions);
  };

  // api call delay 3s for department and doctor
  useEffect(() => {
    if (!isSelect) {
      if (apiTimeout) clearTimeout(apiTimeout);
      const timeout = setTimeout(() => {
        loadOptions(search);
      }, 300);
      apiTimeout = timeout;
    }
  }, [search, isSelect]);

  // create suggestion list while search department
  const loadOptions = async (inputValue) => {
    let fetchedOptions = [];
    if (inputValue?.trim()) {
      let id = new Date().getTime()
      setSearchDocDeptLoading((prev) => ({
        ...prev,
        [id]: true
      }));
      const config = {
        doc_with_dept: false,
        status: 'active,inactive',
        limit: 0
      }
      await network
        .get({ url: `/search/department-doctor/${inputValue}`, data: config })
        .then((response) => {
          setSearchDocDeptLoading((prev) => ({
            ...prev,
            [id]: false
          }));
          const { isError, result } = response;
          if (isError) {
            return;
          } else {
            let options = []
            result?.search_result?.map((item) => {
              // handled doctor search in appointment screen with dr. initial from UI itself as it is not saved in the db
              options.push({
                label: item.type === 'doctor' ? 'Dr.'+' ' +item.name : item.name ,
                value: item.type === 'doctor' ? 'Dr.'+' ' +item.name : item.name ,
                type: item.type,
                id: item.id,
              })
            });
            if (!options || !options?.length) {
              options.push({
                  value: '',
                  label: `${intl.formatMessage({
                    id: 'common.noData',
                    defaultMessage: 'No data found.',
                  })}`,
                  isDisabled: true,
              })
            }
            fetchedOptions = options;
          }
        })
        .catch(() => {});
    }
    setDeptDocSuggestionList(fetchedOptions);
  };

  // handle search name selection fron list patient
  const handleSearchPatientSelect = (value) => {
    setPatientName(value);
    setPatientSuggestion([]);
    setPatientIsSelect(true);
  };

  // handle apply
  const handleApplyClick = () => {
    setApplyCounter(applyCounter + 1);
    setShowFilter(false);
    setSearchPatientLoading({})
    // setDisableBtn(false);
    setDate(dateChange);
    setActiveDate(activeDateChange)
    applySearchData({
      ...searchData,
      searchPatient: patientName,
      selectedStatus: status || '',
      isPatientSelect,
      isSelect
    });
  };
  // handle reset
  const handleReset = () => {
    setPatientName('');
    setShowFilter(false);
    setPatientSuggestion([]);
    setSearchPatientLoading({})
    setSearchDocDeptLoading([])
    setDeptListId('');
    // setSelecteDoc('')
    setSearchDoc('');
    setSelectedDeptDoc('');
    setPatientIsSelect(false);
    setApplyCounter(applyCounter + 1);
    setStatus('');
    // setDisableBtn(false);
    setDate(timeZoneDate());
    setActiveDate(timeZoneDate());
    setDateChange(timeZoneDate());
    setActiveDateChange(timeZoneDate());
    applySearchData({
      ...searchData,
      date:timeZoneDate(),
      searchPatient: '',
      selectedStatus: '',
      selecteDoc: {},
    });
  };

  // add appointment page handle
  const handleAddButton = () => {
    // clearSearchDetails();
    const apptDate = isDateBefore(date, timeZoneDate()) ? timeZoneDate() : date; 
    const data = {
     ...selectedDoc,
      type: 'doctor',
    };
    if(data?.value){
      dispatch(receiveSearchDoctorDetails({ date: apptDate, data }));
    }
    history.push('/admin/appointments/addAppointment');
  };

  // handle viewMode change
  const handleViewModeChange = (mode) => {
    applySearchData({ ...searchData, viewType: mode });
    if (mode === 'list') {
      setViewRoster(false);
    } else {
      setViewRoster(true);
    }
  };

  const handleDoctorChange = (value) => {
    setSelecteDoc(value);
  };

  const CustomOption = (props) => {
    const departments = props.data.departments.split(',');
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{props.label}</span>
          <span title={props.data.departments}>
            {departments[0]}
            {departments.length > 2 && `,...+${departments.length - 1}`}
          </span>
        </div>
      </components.Option>
    );
  };

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
    if(showFilter) handleOutsideClose();
    else setPatientIsSelect(true);
  }

  const handleWeekDayClick = (value) => {
    setDate(value);
    setActiveDate(value);
    setDateChange(value);
    setActiveDateChange(value);
  } 

  return (
    <>
      {viewRoster ? (
        <section className="d-flex w-100">
          <div
            className={`admin-roster content ${view === 'day' ? 'own-view-day' : 'own-view-week'
              } w-100`}
          >
            <div className="own-view-week">
            <div className={`view-filter`}>
                <div className='d-flex align-items-center gap-2 justify-content-between fg-1'>
                <div className="d-flex align-items-center gap-2">
                  <div className="navigation me-1">
                    <Tooltip
                      title={view === 'week' ? translate(
                        'appointments.goPrevious',
                        'Go to previous week'
                      ) :
                      translate(
                        'appointments.goPreviousDay',
                        'Go to previous day'
                      )}
                    >
                      <button
                        className="previous"
                        onClick={() => handleWeekChange('previous')}
                      >
                        <i className="bi bi-chevron-left"></i>
                      </button>
                    </Tooltip>
                    <Tooltip
                      title={ view === 'week' ? translate(
                        'appointments.goNext',
                        'Go to next week'
                      ):
                      translate(
                        'appointments.goNextDay',
                        'Go to next day'
                      )}
                    >
                      <button
                        className="next"
                        onClick={() => handleWeekChange('next')}
                      >
                        <i className="bi bi-chevron-right"></i>
                      </button>
                    </Tooltip>
                  </div>
                  
              </div>

              <div className="filter-section" ref={divRef}>
                <button className='filter-btn applied' onClick={handleFilterClick}>
                <i className="bi bi-funnel"></i>
                </button>
                <div className={` ${showFilter ? 'open-filter listings-fiter-container' : 'listings-fiter-container'}`}>

                    <div className="filter-body topFilter">
                      
                      <div className="row">
                        <div className="col-md-3 pe-md-0">
                        <div className="form-group mb-0">
                          <label htmlFor="">Date</label>
                        <div
                        name=""
                        id="rosterWeekViewCalenderToggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className="form-select date-dr"
                        data-bs-auto-close="outside"
                      >
                        {dayMonthAndYear(dateChange, language_Code)}
                      </div>
                      <div
                        className="calander-area dropdown-menu"
                        id="rosterWeekViewCalender"
                      >
                        <Calendar
                          language={language_Code}
                          value={dateChange}
                          onDateChange={onDateChange}
                          activeDate={activeDateChange}
                          setActiveDate={setActiveDateChange}
                        />
                      </div>
                        </div>
                        </div>
                       <div className="col-md-5 pe-md-0">
                       <div className="form-group mb-0">
                        <label htmlFor="">Patient</label>
                        <div
                          className={`search-input ${Object.values(searchPatientLoading).includes(true) && !isPatientSelect ? 'loading' : ''
                            }`}
                        >
                          <input
                            type="search"
                            placeholder={intl.formatMessage({
                              id: 'common.searchpatient',
                              defaultMessage: 'Search Patient',
                            })}
                            value={patientName}
                            // className='form-control down-arrow'
                            maxLength="40"
                            onChange={onChangePatientSearch}
                            autoComplete='off'
                            // onBlur={() =>
                            //   setTimeout(() => setPatientSuggestion([]), 400)
                            // }
                            onFocus={() => {
                              if (patientName) loadOptionsPatient(patientName);
                            }}
                          />

                          {/* <i className="bi bi-search" /> */}
                          {patientName && patientSuggestion.length && !isPatientSelect
                            && !Object.values(searchPatientLoading).includes(true) ? (
                            <div
                              className="customSearchList"
                            >
                              <ul style={{ marginBottom: 1 }}>
                                {patientSuggestion.map((item, index) => (
                                  <li
                                    key={index}
                                    className={`suggestion-item ${item.isDisabled ? 'disabled' : ''}`}
                                    onClick={() =>
                                      !item.isDisabled && handleSearchPatientSelect(item.value)
                                    }
                                  >
                                    {item.label}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                        </div>
                       </div>
                       </div>
                       <div className="col-md-4">
                       <div className="form-group mb-0">
                        <label htmlFor="">Status</label>
                        <ReactSelectDropDown
                          options={APPOINTMENT_STATUS_OPTIONS_ALL}
                          className="form-select"
                          onInputChange={handleStatusChange}
                          value={status}
                          label={translate('common.status', 'Status')}
                          isClearable={true}
                        />
                        </div>
                       </div>
                      </div>
                    </div>
                    <div className="filter-footer back-to-btn">
                      <button
                        id="reset"
                        className="btn btn-outlined"
                        onClick={handleReset}
                      >
                        {translate('common.reset', 'RESET')}
                      </button>
                      <button
                        id="apply"
                        className="btn btn-secondary"
                        onClick={handleApplyClick}
                        // disabled={!disablebtn}
                      >
                        {translate('common.apply', 'Apply')}
                      </button>
                    </div>
                  </div>
                </div>
                </div>


              <div className="right-filter-section">

                <div className='day-dr'>
                <ReactSelectDropDown
                  options={options}
                  onInputChange={handleViewChange}
                  className="form-select"
                  value={viewType}
                />
                </div>

                {/* Excel export is not taken in this phase */}
                {/* <div className="dropdown icon-dr">
                <button
                  className="form-select"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={exportIcon} className="pe-1 mb-1" alt="" />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Export as Excel
                    </a>
                  </li>
                </ul>
              </div> */}

                <button className='btn form-control input button-icon d-none'>
                <img src={exportIcon} alt="" />
                  Export
                </button>
                <div className="button-wrap">
                  <ViewSwitch
                    active="menu"
                    handleViewModeChange={handleViewModeChange}
                  />
                </div>
              </div>
            </div>
            </div>
            {view == 'day' ? (
              <DayRoster
                date={date}
                setDate={setDate}
                activeDate={activeDate}
                setActiveDate={setActiveDate}
                patientName={patientName}
                applyCounter={applyCounter}
                selectedDoc={selectedDoc}
                setSelecteDoc={setSelecteDoc}
                searchDoc={searchDoc}
                onDeptChangeSearch={onDeptChangeSearch}
                deptDocSuggestionList={deptDocSuggestionList}
                setDeptDocSuggestionList={setDeptDocSuggestionList}
                handleDeptChange={handleDeptChange}
                setStatus={setStatus}
                status={status}
                handleAddButton={handleAddButton}
                applySearchData={applySearchData}
                searchData={searchData}
                docDetails={docDetails}
                opTime={opTime}
                language_Code={language_Code}
                isLoader={isLoader}
                searchDocDeptLoading={searchDocDeptLoading}
                allDocList={allDocList}
                handleDoctorChange={handleDoctorChange}
                CustomOption={CustomOption}
                isSelect={isSelect}
                patient={patientName}
                stat={status}
                selectedDeptDoc={selectedDeptDoc}
              />
            ) : (
              <WeekRoster
                date={date}
                handleWeekDayClick={handleWeekDayClick}
                patientName={patientName}
                applyCounter={applyCounter}
                deptDocSuggestionList={deptDocSuggestionList}
                setDeptDocSuggestionList={setDeptDocSuggestionList}
                setSearch={setSearch}
                onDeptChangeSearch={onDeptChangeSearch}
                handleDeptChange={handleDeptChange}
                selectedDoc={selectedDoc}
                setSelecteDoc={setSelecteDoc}
                searchDoc={searchDoc}
                handleAddButton={handleAddButton}
                status={status}
                applySearchData={applySearchData}
                searchData={searchData}
                docDetails={docDetails}
                opTime={opTime}
                language_Code={language_Code}
                isloader={isLoader}
                searchDocDeptLoading={searchDocDeptLoading}
                allDocList={allDocList}
                handleDoctorChange={handleDoctorChange}
                CustomOption={CustomOption}
                isSelect={isSelect}
                selectedDeptDoc={selectedDeptDoc}
              />
            )}
          </div>
        </section>
      ) : (
        <AppointmentsTableView handleViewModeChange={handleViewModeChange} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  searchData: state.AppointmentReducer.searchData,
  language_Code: state.SettingsReducer.locale,
  userType: state.LoginReducer.user_type,
});

const mapDispatchToProps = (dispatch) => ({
  applySearchData: (data) => dispatch(updateSearchData(data)),
  clearData: () => dispatch(clearAppointmentReducer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsRoster);
