import network from 'Utils/Api/Network';
import { ToasterTypes, showToaster } from './toasterAction';
export const PROFILE_BTN_LOADING = 'PROFILE_BTN_LOADING';
export const CLEAR_LINKED_SEARCH_NAME = 'CLEAR_LINKED_SEARCH_NAME';
export const OTP_LOADER='OTP_LOADER'
export const VIEW_MODE='VIEW_MODE'

export const profileBtnLoading = (payload) => ({
  type: PROFILE_BTN_LOADING,
  payload,
});

export const showOtpLoder=(payload) => ({
  type: OTP_LOADER,
  payload,
});

export const setViewMode = (payload) => ({
  type: VIEW_MODE,
  payload
})

export const getlinkedProfile = (
  callBack = () => {},
  errCallBack = () => {}
) => {
  return (dispatch) => {
    return network.get({ url: `/patient/linked-profiles` }).then((response) => {
      const { isError, result, message } = response || {};
      if (isError) {
        errCallBack(response);
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        callBack(result);
      }
    });
  };
};
export const getProfileDetails = (
  id,
  callBack = () => {},
  errCallBack = () => {}
) => {
  return (dispatch) => {
    return network.get({ url: `/patient/${id}` }).then((response) => {
      const { isError, result, message } = response || {};
      if (isError) {
        errCallBack(response);
        dispatch(showToaster(message, ToasterTypes.Failed));
      } else {
        callBack(result);
      }
    });
  };
};

export const linkNewProfile = (data = {}, callBack) => {
  return (dispatch) => {
    dispatch(profileBtnLoading(true));
    return network
      .post({ url: `/patient/link-profile/signup`, data })
      .then((response) => {
        dispatch(profileBtnLoading(false));
        const { isError, message, result } = response;
        if (isError) {
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          callBack({ result, response });
          // dispatch(showToaster(message, ToasterTypes.Success));
        }
      });
  };
};
export const linkExistingProfile = (
  data = {},
  callBack = () => {},
  errCallBack = () => {}
) => {
  return (dispatch) => {
    dispatch(profileBtnLoading(true));
    return network
      .post({ url: `/patient/link-profile/validate`, data,params:  data?.data?.params })
      .then((response) => {
        const { isError, message, result } = response;
        if (isError) {
          errCallBack(message);
          dispatch(showToaster(message, ToasterTypes.Failed));
          dispatch(profileBtnLoading(false));
        } else {
          result.payload = data;
          callBack(result);
          dispatch(profileBtnLoading(false));
          // dispatch(showToaster(message, ToasterTypes.Success));
        }
      });
  };
};

export const updateLinkedProfile = (
  data = {},
  callBack = () => {},
  errCallBack = () => {},
  resentOtp = false
) => {
  //  debugger
  return (dispatch) => {
    if (!resentOtp) {
      dispatch(profileBtnLoading(true));
    }
    return network
      .put({ url: `/patient/link-profile/${data.id}`, data: data.payload })
      .then((response) => {
        if (!resentOtp) {
          dispatch(profileBtnLoading(false));
        }
        const { isError, message, result } = response;
        if (isError) {
          errCallBack();
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          callBack({ result, response });
          if (response.code !== 202) {
            dispatch(showToaster(message, ToasterTypes.Success));
          }
        }
      });
  };
};

export const verifyOtpPhoneUpdate = (
  data = {},
  callBack = () => {},
  errCallBack = () => {}
) => {
  return (dispatch) => {
    dispatch(showOtpLoder(true))
    return network
      .put({
        url: `/user/${data.id}/update/user-identifier/phone/verify`,
        data: data.payload,
      })
      .then((response) => {
        const { isError, message } = response;
        dispatch(showOtpLoder(false))
        if (isError) {
          errCallBack(message);
          // dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          callBack();
          dispatch(showToaster(message, ToasterTypes.Success));
        }
      });
  };
};

export const validateToken = (
  data = {},
  callBack = () => {},
  errCallBack = () => {}
) => {
  return (dispatch) => {
    dispatch(showOtpLoder(true))
    return network
      .post({ url: `/patient/link-profile/verify`, data,params:data?.data?.params })
      .then((response) => {
        const { isError, message } = response;
        dispatch(showOtpLoder(false))
        if (isError) {
          errCallBack(message);
          // dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          callBack();
          dispatch(showToaster(message, ToasterTypes.Success));
        }
      });
  };
};

const clearSearchName = (payload) => ({
  type: CLEAR_LINKED_SEARCH_NAME,
  payload,
});

export const deLinkProfile = (
  data,
  callBack = () => {},
  errCallBack = () => {},
  patientId
) => {
  return (dispatch) => {
    dispatch(profileBtnLoading(true));
    dispatch(clearSearchName(patientId));
    return network
      .patch({
        url: `/patient/link-profile/${data.id}/de-link`,
        data: data.payload,
      })
      .then((response) => {
        dispatch(profileBtnLoading(false));
        const { isError, message } = response;
        if (isError) {
          errCallBack();
          dispatch(showToaster(message, ToasterTypes.Failed));
        } else {
          callBack();
          dispatch(showToaster(message, ToasterTypes.Success));
        }
      });
  };
};
